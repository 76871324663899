import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';

@Component({
  selector: 'he-summary-value-assesment',
  templateUrl: './summary-value-assesment.component.html',
  styleUrls: ['./summary-value-assesment.component.scss'],
})
export class SummaryValueAssesmentComponent implements OnDestroy {
  @Input() public analoguesData:any[]=[];
  @Input() public SummaryFormattedData:any[]=[];
  public percentages: string[] = [
    '0%',
    '10%',
    '20%',
    '30%',
    '40%',
    '50%',
    '60%',
    '70%',
    '80%',
    '90%',
    '100%',
  ];

  private unsubscriber$ = new Subject<void>();
  constructor(
    public countryFlagService: CountryFlagService
  ) {}


  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
