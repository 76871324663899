import { asyncScheduler, Observable, of } from 'rxjs';
import { catchError, map, startWith, subscribeOn } from 'rxjs/operators';
import { Assert } from '../assert';
import { Gateway } from '../gateway';
import { IrpMapInput, ReimbursementStatusOutputMessage as IrpMapOutputMessage } from './interfaces';

export class IrpMap {
  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${gateway} has no "transfer" method`);
  }

  static create(gateway: Gateway): IrpMap {
    return new IrpMap(gateway);
  }

  run(event: IrpMapInput): Observable<IrpMapOutputMessage> {
    return this.gateway.transfer(event.payload).pipe(
      map((response) => {
        return {
          type: 'done',
          payload: response
        };
      }),
      catchError((err) => {
        return of({
          type: 'fail',
          error: true,
          payload: err
        });
      }),
      startWith({
        type: 'pending',
        payload: undefined
      }),
      subscribeOn(asyncScheduler)
    );
  }
}
