import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoChartDirective } from './info-chart.directive';
import { InfoDescriptionDirective } from './info-description.directive';
import { InfoTableDirective } from './info-table.directive';
import { InfoTitleDirective } from './info-title.directive';
import { InfoWidgetComponent } from './info-widget.component';
import { InfoPanelDirective } from './info-panel.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { IconModule } from '../helios-icons';
import { ExportCsvButtonComponent } from './export-csv-button/export-csv-button.component';
import { InfoActionsDirective } from './info-actions.directive';
import { ExportImageButtonComponent } from './export-image-button/export-image-button.component';
import { InfoGroupByDirective } from './info-group-by.directive';
import { InfoButtonDirective } from './info-button.directive';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    InfoChartDirective,
    InfoDescriptionDirective,
    InfoTableDirective,
    InfoTitleDirective,
    InfoPanelDirective,
    InfoWidgetComponent,
    InfoActionsDirective,
    InfoWidgetComponent,
    ExportImageButtonComponent,
    ExportCsvButtonComponent,
    InfoGroupByDirective,
    InfoButtonDirective
  ],
  exports: [
    InfoChartDirective,
    InfoDescriptionDirective,
    InfoTableDirective,
    InfoTitleDirective,
    InfoPanelDirective,
    InfoWidgetComponent,
    InfoActionsDirective,
    ExportImageButtonComponent,
    InfoActionsDirective,
    InfoGroupByDirective,
    InfoButtonDirective,
    ExportCsvButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatTabsModule,
    MatTooltipModule,
    IconModule
  ]
})
export class InfoWidgetModule {
}
