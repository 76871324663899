import { FetchReimbursementHtaPipe } from './../reimbursement-hta/pipes/fetch-reimbursement-hta.pipe';
import { ExportCsvObject } from './../../top-nav/export-csv.object.service';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable, PipeTransform } from '@angular/core';
import { FiltersValueService } from '../filters-value.service';
import { FilterItemValueInterface } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class HtaCacheService {
  htaStore: Map<string, any> = new Map<string, any>()
  initialized = false;
  brands: FilterItemValueInterface[] = []
  countries: FilterItemValueInterface[] = []
  filtersValueService: FiltersValueService | undefined;
  destroying$: Subject<void> = new Subject<void>();

  selectionChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  selection: FilterItemValueInterface | undefined
  getSelection = () => this.selection
  selection$ = this.selectionChanged.pipe(map(() => this.getSelection()));

  exportCsvObject: ExportCsvObject = new ExportCsvObject()
  exportCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  exportPrepared$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  transformPipe: PipeTransform | undefined;

  constructor() {
  }

  resetHtaCache() {
    this.htaStore.clear();
    this.selection = undefined
    this.exportCompleted$.next(true)
    this.exportPrepared$.next(true)
  }

  initHtaCache(filtersValueService: FiltersValueService, destroying$: Subject<void>, transformPipe: PipeTransform) {
    if (this.initialized)
      return

    this.filtersValueService = filtersValueService;
    this.destroying$ = destroying$;
    this.transformPipe = transformPipe;

    filtersValueService?.filterValues$.pipe(
      takeUntil(destroying$)
    ).subscribe(() => {
      if(this.initialized)
        this.resetHtaCache()
      this.initialized = true
    }, undefined, () => {
      this.initialized = false;
      this.resetHtaCache()
    })

    filtersValueService.brandValues$.pipe(
      takeUntil(destroying$)
    ).subscribe((brands: FilterItemValueInterface[]) => {
      this.setBrands(brands);
    })

    filtersValueService.countryValues$.pipe(
      takeUntil(destroying$)
    ).subscribe((brands: FilterItemValueInterface[]) => {
      this.setCountries(brands);
    })
  }

  private setBrands(brands: FilterItemValueInterface[]) {
    this.brands = brands;
  }

  private setCountries(countries: FilterItemValueInterface[]) {
    this.countries = countries;
    if (this.filtersValueService && this.brands.length > this.filtersValueService.maxBrandsLimit) {
      this.selection = countries[0];
    }
    else {
      this.resetHtaCache();
    }
  }

  public select(item: FilterItemValueInterface | undefined) {
    this.selection = item
    this.selectionChanged.next(!this.selectionChanged.value)
  }

  public addHtaCache(brand: string, country: string, response: any) {
    this.htaStore.set(this.getCacheKey(brand, country), response)
  }

  public getHtaCache(brand: string | null = null, country: string | null = null) {
    if(brand && country)
      return this.htaStore.get(this.getCacheKey(brand, country))

    var cacheKeys: string[] = []
    if(brand)
      cacheKeys = this.countries.map(item => this.getCacheKey(brand, item.name))
    else if(country)
      cacheKeys = this.brands.map(item => this.getCacheKey(item.name, country))
    else
      this.brands.forEach(brand =>
        this.countries.forEach(country => cacheKeys.push(this.getCacheKey(brand.name, country.name))))

    const keys = [...this.htaStore.keys()]
    if(!cacheKeys.every(key => keys.indexOf(key) !== -1))
      return null

    var cachedResults = cacheKeys.map(key => this.htaStore.get(key))
    if(cachedResults.length < 1)
      return null

    if(cachedResults.every(result => result.type === 'fail'))
      return {
        type: 'fail',
        dataset: [],
        error: ''
      }

    var rows: any[] = []
    cachedResults.forEach(result => {
      (result.dataset as any[]).forEach(row => rows.push(row))
    });

    return {
      type: 'done',
      dataset: rows,
      error: ''
    }
  }

  private getCacheKey(brand: string, country: string): string {
    return `${brand}_${country}`;
  }

  public exportCsv() {
    if(!this.initialized)
      return

    const keys = [...this.htaStore.keys()]
    var missingBrands: string[] = []
    this.brands.forEach(brand => {
      var cacheKeys = this.countries.map(country => this.getCacheKey(brand.name, country.name))
      if(!cacheKeys.every(key => keys.indexOf(key) !== -1))
        missingBrands.push(brand.name)
    });

    this.exportCompleted$.next(false);
    this.exportPrepared$.next(false);

    if(missingBrands.length === 0)
    {
      this.exportPrepared$.next(true);
      return this.forceRedraw()
    }

    (this.transformPipe as FetchReimbursementHtaPipe).transform(this.filtersValueService?.filterValues$.value, 'brand', false, missingBrands).pipe(
      takeUntil(this.destroying$), finalize(() => this.exportPrepared$.next(true))
    ).subscribe((result) => {
      if(result && result.type !== 'pending')
      {
        this.forceRedraw()
      }
    });
  }

  public exportCacheToCsv() {
    this.exportCsvObject.exportToCsv();
    this.exportCompleted$.next(true);
    this.forceRedraw();
  }

  private forceRedraw() {
    this.select(this.selection);
  }
}
