<div class="card-wrapper" style="border-width: 0px">
  <div class="inline-centered-items header-row">
    <div>
      <div class="card-wrapper">
        <mat-card>
          <mat-card-header class="inline-centered-items">
            <div class="label">ASSET NAME</div>
          </mat-card-header>
          <mat-card-content>
            <div class="value" style="text-transform: none !important ">
              {{ assetDataName }}
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="card-wrapper">
        <mat-card>
          <mat-card-header class="inline-centered-items">
            <div class="label">Country</div>
          </mat-card-header>
          <mat-card-content>
            <div class="value">
              <img
                *ngIf="countryName !== ''"
                class="flag"
                src="{{ 'assets/flags/small/' + flagId + '.svg' }}"
                width="16px"
              />
              {{ countryName }}
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="card-wrapper">
        <mat-card>
          <mat-card-header class="inline-centered-items">
            <div class="label">{{prieceLabel}}</div>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngIf="prieceLabel === 'Price Analysis'">
              <div class="value" *ngIf="predictedPrice >0">
                {{
                  predictedPrice
                    | currency : selectedCurrency : "symbol-narrow" : "1.0-2"
                }}
              </div>
                       <div class="value" *ngIf="predictedPrice <= 0">
                        NA
              </div>
            </ng-container>
            <ng-container *ngIf="prieceLabel === 'Price Corridor'">
              <div class="value" >
                {{
                  minpredictedPrice.price
                    | currency : selectedCurrency : "symbol-narrow" : "1.0-2"
                }}
               <span style="text-transform: lowercase;">to</span> 
                {{
                 maxpredictedPrice.price
                  | currency : selectedCurrency : "symbol-narrow" : "1.0-2"
                }}
              </div>
            </ng-container>
            <ng-container *ngIf="prieceLabel === 'Price Benchmark'">
              <div class="value" *ngIf="maxpredictedPrice.price>0">
                {{
                  maxpredictedPrice.price
                    | currency : selectedCurrency : "symbol-narrow" : "1.0-2"
                }}
              </div>
              <div class="value" *ngIf="maxpredictedPrice.price<=0">
               NA
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="card-wrapper">
        <mat-card>
          <mat-card-header class="inline-centered-items">
            <div class="label">Value Score</div>
          </mat-card-header>
          <mat-card-content>
            <div class="value">
              <div class="value-score">
                <span class="value-score">{{
                  (assetData?.totalValueScore ? assetData?.totalValueScore : 0)
                    | number : "1.0-2"
                }}</span>
                <span class="value-score-total"
                  >/{{
                    totalValueScore === null
                      ? 0
                      : (totalValueScore | number : "1.0-0")
                  }}</span
                >
              </div>
              <div>
                <mat-progress-bar  [value]="valueScore" >
                </mat-progress-bar>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<div class="card-wrapper">
  <mat-card style="padding-top: 5px; padding-bottom: 0px">
    <mat-card-content>
      <ng-container *ngIf="this.sharedService.isLoading$.value">
        <he-loading-spinner
        [diameter]="70"
        [height]="'350px'"
      ></he-loading-spinner>
      </ng-container>
      <div [ngStyle]="{'display':this.sharedService.isLoading$.value?'none':''}">
        <he-project-forecast-value-price
        [country]="countryName"
        [infoData]="infoData"
        [assetData]="assetData"
        [chartData]="chartData"
        [trendLine]="trendLine"
        [selectedRoute]="selectedRoute"
        [defaultBrands]="defaultBrands"
        [minValueScore]="minValueScore"
        [totalValueScore]="totalValueScore"
        [maxpredictedPrice]="maxpredictedPrice"
        [minpredictedPrice]="minpredictedPrice"
        [selectedCurrency]="selectedCurrency"
        [selectedPriceType]="currentPriceType"
        [selectedCurrencySymbol]="selectedCurrencySymbol"
      >
      </he-project-forecast-value-price>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="card-wrapper">
  <mat-card style="padding-top: 5px; padding-bottom: 0px">
    <mat-card-content>
      <he-project-forecast-value-assessment
        (chartInstance)="saveInstance($event)"
        [chartNameInput]="chartName"
      ></he-project-forecast-value-assessment>
    </mat-card-content>
  </mat-card>
</div>
