import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserProfile } from 'projects/helios-gui/src/app/profile/menu/user.profile';
import { Capitalise } from '../../../horizon-project/utility-functions/StringFormatter';
import { PlatformUserAuthorizationDataService } from 'projects/helios-gui/src/app/platform-user-authorization/platform-user-authorization-data.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'he-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() iteam = false;
  @Input() platformPermission: any[] = [
    { platform: 'Pulse' },
    { platform: 'Horizon' },
  ];
  constructor(
    private currentProfile: UserProfile,
    private platformUserAuthService: PlatformUserAuthorizationDataService,
    private $gaService: GoogleAnalyticsService,
  ) {}

  ngOnInit() {    
    
    const userProperties = {
      username:  this.currentProfile.getUserName(),
      email:  this.currentProfile.getEmail(),
      domain:'undefined'
    };

    try {
      userProperties.domain = userProperties.email.split('@')[1].split('.')[0]
    } catch { }

    this.$gaService.set('user_properties', userProperties);
  }

  getProfileName(): string {
    return Capitalise(this.currentProfile.getUserName());
  }

  logout(): void {
    const authCode =
      this.platformUserAuthService.getPlatformUserAuthCodeFromSession();

    if (authCode) {
      this.platformUserAuthService
        .deleteAuthorizationCode(authCode)
        .then((data) => {
          //console.log(data);
          this.platformUserAuthService.removePlatformUserAuthCodeFromSession();
          this.currentProfile.logout();
        })
        .catch((err) => {
          this.currentProfile.logout();
        });
    } else {
      this.currentProfile.logout();
    }
  }
}
