import { Injectable } from '@angular/core';
import { FilterItemValueInterface } from './interfaces';

@Injectable()
export class ExportImage {

  chartApis: ChartApi[] = [];

  registerApi(apiItem: any, itemName: string, brands: FilterItemValueInterface[]): void {
    this.chartApis.push({
      apiItem,
      name: this.getImageName(itemName, brands),
    });
  }

  unregister(apiItem: any): void {
    const target = apiItem ? apiItem : {};
    this.chartApis = this.chartApis.filter((el) => {
      return el.apiItem.id !== target.id;
    });
  }

  saveImage(): void {
    this.chartApis.forEach((el) => {
      const base64 = el.apiItem.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff'
      });
      const a = document.createElement('a');
      a.href = base64;
      a.download = el.name;
      a.click();
    });
  }

  private getImageName(chartName: string, brandNames: FilterItemValueInterface[]): string {
    const brand = brandNames.length > 1 ? brandNames.length : brandNames.map((el) => (el.name));
    return `${ chartName.replace(/\s/g, '_').toLowerCase() }_${ brand }.png`;
  }
}

interface ChartApi {
  apiItem: any;
  name: string;
}
