import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CotCalculatorService } from '../services/cot-calculator.service';
import { Subject, takeUntil } from 'rxjs';
import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellEditorParams, ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'he-cot-grid-input',
  templateUrl: './cot-grid-input.component.html',
  styleUrls: ['./cot-grid-input.component.scss'],
})
export class CotGridInputComponent implements ICellEditorAngularComp,ICellRendererAngularComp{
  @ViewChild('i') textInput: any;
  public params!: ICellEditorParams;
  public dataSource: any[] = [];
  public readonly: boolean = false;
  public cancelBeforeStart: boolean = false;
  public control = new FormControl('');
  private unsubscriber$ = new Subject<void>();
  
  constructor(private cotCalculatorService: CotCalculatorService) {}
  agInit(params: any): void {
    this.cotCalculatorService.castdataSource
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        let dataSource = res.filter((res) => res.edited === true);
        if (dataSource.length > 49) {
          this.readonly = true;
        } else {
          this.readonly = false;
        }
      });
    this.params = params;
    this.control.patchValue(params.value.currentValue);
    this.control.valueChanges.subscribe((res) => {
      this.params.value.currentValue = res;
      this.checkEditedValue();
    });
  }

  afterGuiAttached() {
    this.textInput.nativeElement.focus();
  }


  getValue():any {
    return  this.params.value;
  }
  refresh(params: ICellRendererParams): boolean {
    return false
  }
 
  public checkEditedValue(): void {
    let editList = [
      'packDescription',
      'regulatoryIndication',
      'duration',
      'averageBodySurfaceArea',
      'averageBodyWeight',
      'maintainanceDose',
      'pricePerMg',
      'pricePerUnit',
      'loadingDose',
      'pricePerUnit',
      'loadingDoseFrequency',
      'loadingDoseDuration',
      'maintainanceDose',
      'maintainanceDoseFreaquency',
      'maintainanceDoseDuration',
      'averageBodyWeight',
      'averageBodySurfaceArea',
    ];
    let editLength = 0;
    editList.forEach((res) => {
      if (
        (this.params.data[res].defaultValue?.id ? this.params.data[res].defaultValue?.id : this.params.data[res].defaultValue) !=
        (this.params.data[res].currentValue?.id ? this.params.data[res].currentValue?.id : this.params.data[res].currentValue)

      ) {
        editLength++
      }

    });
    if(editLength >0){
      this.params.data.edited = true;
      this.params.data.reEdited = true;
      this.cotCalculatorService.countryBrandata.next(this.params.data);
    }else{
      this.params.data.edited = false;
      this.params.data.reEdited = false;
      this.cotCalculatorService.countryBrandata.next(this.params.data);
    }
  }
  preventE(event:any) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
