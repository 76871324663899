import { Component, EventEmitter, Output } from '@angular/core';
import { DialogText } from 'projects/helios-gui/src/app/common';

@Component({
  selector: 'he-country-filter-reset-dialog',
  templateUrl: './country-filter-reset-dialog.component.html',
  styleUrls: ['./country-filter-reset-dialog.component.scss']
})
export class CountryFilterResetDialogComponent {

  @Output() confirm = new EventEmitter();
  @Output() reject = new EventEmitter();

  constructor() { }

  onReject(): void {
    this.reject.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  get confirmationText() {
    return DialogText.CountryFilterClearMessage;
  }

}
