import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommandComponent } from './command.component';

@NgModule({
  declarations: [CommandComponent],
  exports: [CommandComponent],
  imports: [
    CommonModule
  ]
})
export class CommandModule { }
