<mat-chip-set class="he-chip-list">
  <mat-chip matTooltip="{{ disabled ? disabledTooltip : null }}" matTooltipClass="he-tooltip" [attr.disabled]="disabled"
    class="he-chip" [class.he-chip-void]="selectedItem.status === 'void' || selectedItem.status === false"
    [class.he-chip-selected]="selectedChipIndex === i" *ngFor="let selectedItem of selectedItems; let i = index"
    (removed)="remove(selectedItem)" (click)="changeStatus(selectedItem,i)">
    <div *ngIf="selectedItem.name!=='Exclude'; else else_content">
      {{selectedItem.name ? selectedItem.name : selectedItem}}
    </div>
    <ng-template #else_content>
      <abbr title="Disable to include discontinued products" *ngIf="selectedItem.status">
        {{selectedItem.name ? selectedItem.name : selectedItem}}
      </abbr>
      <abbr *ngIf="!selectedItem.status">
        {{selectedItem.name ? selectedItem.name : selectedItem}}
      </abbr>
    </ng-template>

    <he-icon *ngIf="hasRemoveIcon" matChipRemove title="Remove" [id]="'suggested-symbol-x'"></he-icon>
  </mat-chip>
</mat-chip-set>