import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorridorGridComponent } from './corridor-grid.component';
import { GridCommonModule } from '../../grid-common';
import { SelectModule } from '../../select/select.module';


@NgModule({
  declarations: [CorridorGridComponent],
  imports: [
    CommonModule,
    GridCommonModule,
    SelectModule,
  ],
  exports: [CorridorGridComponent]
})
export class CorridorGridModule {
}
