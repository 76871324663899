import { Component, OnInit } from '@angular/core';
import { CachedAccessPermissions } from '../../core/access-permissions';
import { AccessBarrierService } from '../access-barrier';

@Component({
  selector: 'he-horizon',
  templateUrl: './horizon.component.html',
  styleUrls: ['./horizon.component.scss'],
  providers: [
    { provide: AccessBarrierService, useExisting: CachedAccessPermissions }
  ]
})
export class HorizonComponent {
  accessBarrierService: AccessBarrierService;
  PROJECTS = 'projects';
  constructor(accessPermissions: CachedAccessPermissions) {
    // enable typecheck
    this.accessBarrierService = accessPermissions;
  }

}
