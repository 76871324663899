import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScopeDetailType } from '../../core/scopes-details';

@Injectable()
export class ScopesDetailsApi {

  constructor(private httpClient: HttpClient) {
  }

  transfer(userScopeDetailTypes: ScopeDetailType[]): Observable<string> {
    const query = userScopeDetailTypes.map(s => 'scopeTypes=' + s).join('&');

    return this.httpClient.get<string>(`/userconfig/GetScopesDetails?${query}`);
  }
}
