import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'he-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuPanelComponent {
}
