import { Component, EventEmitter, Output } from '@angular/core';
import { DialogText } from 'projects/helios-gui/src/app/common';

@Component({
  selector: 'he-brand-filter-reset-dialog',
  templateUrl: './brand-filter-reset-dialog.component.html',
  styleUrls: ['./brand-filter-reset-dialog.component.scss']
})
export class BrandFilterResetDialogComponent {

  @Output() confirm = new EventEmitter();
  @Output() reject = new EventEmitter();

  constructor() { }

  onReject(): void {
    this.reject.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  get confirmationText() {
    return DialogText.BrandFilterClearMessage;
  }

}
