import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'he-scenario-warning',
  templateUrl: './scenario-warning.component.html',
  styleUrls: ['./scenario-warning.component.scss']
})
export class ScenarioWarningComponent implements OnInit {
  public message:string=''
  constructor(public dialogRef: MatDialogRef<ScenarioWarningComponent>,
    @Inject(MAT_DIALOG_DATA)public data:any) { }

  ngOnInit(): void {
    this.message=this.data.message
  }
  public onClick(state:boolean):void{
      this.dialogRef.close(state)
  }
}
