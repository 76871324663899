<div class="slider-comp-container">
    <div class="name-value-container">
        <div>
            {{SliderTitle}}
        </div>
        <div class="value-section">
            {{sliderValue| number : '1.'+DecimalDigit+'-'+DecimalDigit}} {{SliderUnit}}
        </div>
    </div>
    <div class="container">
        <div><button mat-icon-button class="mat-button-gray" (click)="DecreseSliderValue()">
             -
          </button></div>
        <div>
            <mat-slider class="pls-mat-slider" [min]="SliderMinValue" [step]="SliderStep" [max]="SliderMaxValue"  (change)="GetChangeData($event)">
                <input [value]="sliderValue" class="pls-mat-slider-knob" onclick="this.blur()"   matSliderThumb #slider>
            </mat-slider>
        </div>
        <div><button mat-icon-button class="mat-button-gray" (click)="IncreseSliderValue()">
            +
          </button></div>
    </div>
</div>
