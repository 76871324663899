export function dmy_ymd(data: string): string {
  if(!data)
  return "NA";
  // 25-6-2012  
  // 25/6/2012 -- handling / also
  let seprator='';
  if(data.includes("-")){
    seprator="-";
  }
  else if(data.includes("/")){
    seprator="/";
  }  
  const saniData = data.trim().split(seprator);
  if (saniData.length > 3 || saniData.length < 2) {
    return 'plese give valid input';
  }
  return `${saniData[2]}-${saniData[1]}-${saniData[0]}`;
}

export function dmy_ymd_slash(data: string): string {
  if(!data)
  return "NA";
  // 25-6-2012
  const saniData = data.trim().split('-');
  if (saniData.length > 3 || saniData.length < 2) {
    return 'plese give valid input';
  }
  return `${saniData[0]}/${saniData[1]}/${saniData[2]}`;
}

export function ToReadableDate(data: string): string {
  // 25-6-2012
  if (data) {
    const date = dmy_ymd(data.trim());
    const dateobj = new Date(date);
    const temp = dateobj.toDateString().slice(4);
    const returnDate = temp.slice(0, 6) + ',' + temp.slice(6);
    return returnDate;
  }
  return data;
}
