import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValueForecastInput } from '../../../horizon-project/models/ValueForecastInput';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ForecastService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  constructor(private http: HttpClient) {}

  getForecastData(requestData: ValueForecastInput): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.projectAPIUri}/ValuePricing/PredictPrice`,
          requestData
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }
  getComparatorForecastData(requestData: ValueForecastInput): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.projectAPIUri}/ValuePricing/PredictPriceForComparator`,
          requestData
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  getNewForecastData(requestData: ValueForecastInput): Observable<any>  {
    const urlString = new URL(
      `${this.projectAPIUri}/ValuePricing/PredictPrice`
    );
    return this.http.post<any>(urlString.href, requestData);
  }
}
