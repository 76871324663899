import { Injectable } from '@angular/core';
import { UserPreferenceTypes, UserPreferences } from 'projects/helios-gui/src/core/user-preferences';
import { Subject, takeUntil, BehaviorSubject } from 'rxjs';
import { AddUserPreference } from 'projects/helios-gui/src/core/add-user-preference';
import { FilterPresetCollection, FilterPreset } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class FilterPresetService {
  private destroying$: Subject<void> = new Subject<void>();
  public userPreferenceType = UserPreferenceTypes.FilterPreset;
  public filterPresets: BehaviorSubject<FilterPresetCollection[]> = new BehaviorSubject<FilterPresetCollection[]>([]);
  public userPreferencesRetrieved: boolean = false;

  constructor(
    private userPreferences: UserPreferences,
    private addUserPreference: AddUserPreference,
  ) { }

  initService(destroying$: Subject<void>) {
    this.userPreferencesRetrieved = false
    this.filterPresets.next([])
    this.destroying$ = destroying$;
    this.subscribeUserPreferences()   
  }

  savePreset(presetKeyName: string, presets: FilterPreset[]) {

    let filterPresets = this.filterPresets.value.filter(f => f.type !== presetKeyName)
    filterPresets.push({ type: presetKeyName, presets })
    this.filterPresets.next(filterPresets)

    const preferences = {
      type: `${this.userPreferenceType}_${presetKeyName}`,
      name: JSON.stringify(presets)
    }

    this.addUserPreference.run({
      payload: [preferences]
    }).pipe(takeUntil(this.destroying$)).subscribe()
  }

  subscribeUserPreferences(): void {
    this.userPreferences.run({
      payload: [
        // ...Object.values(FilterPresetTypes).map(p => `${this.userPreferenceType}_${p}`)
      ]
    }).pipe(
      takeUntil(this.destroying$)
    ).subscribe((response) => {
      if (response.type != "pending") {
        if (!response.error && response.payload) {
          this.userPreferencesRetrieved = true
          this.filterPresets.next((response.payload as any[])?.map((x: any) => {
            const userPreference = x.userPreference
            return {
              type: userPreference.type.split('_')[1],
              presets: (userPreference && userPreference.preference) ? JSON.parse(userPreference.preference) : []
            }

          }) ?? [])
        }
      }
    });
  }
}
