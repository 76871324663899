import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessBarrierComponent } from './access-barrier.component';
import { FetchPipe } from './fetch.pipe';

import { LoadingSpinnerModule } from '../../uikit/loading-spinner';
import { AllowDirective } from './allow.directive';
import { DenyDirective } from './deny.directive';

@NgModule({
  declarations: [AccessBarrierComponent, FetchPipe, AllowDirective, DenyDirective],
  exports: [AccessBarrierComponent, AllowDirective, DenyDirective],
  imports: [
    CommonModule,
    LoadingSpinnerModule
  ]
})
export class AccessBarrierModule {
}
