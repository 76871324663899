import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

interface MessageRef {
  payload: unknown;
}

interface Message {
  error?: boolean;
  payload: any;
  type: string;
}

export abstract class ConfigProvider {
  abstract run(input: MessageRef): Observable<Message>;
}

@Pipe({
  name: 'contextConfig'
})
export class ConfigPipe implements PipeTransform {
  constructor(private provider: ConfigProvider) {
  }

  transform(ref: unknown): Observable<Message> {
    return !ref ? EMPTY : this.provider.run({
      payload: ref
    });
  }

}
