import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './filters.component';
import { InputFieldRangeModule } from '../input-field-range';
import { FilterByCountryModule } from './filter-by-country';
import { FiltersValueService } from './filters-value.service';
import { DynamicFiltersModule } from './dynamic-filters';

@NgModule({
  declarations: [FiltersComponent],
  exports: [FiltersComponent],
  imports: [
    CommonModule,
    FilterByCountryModule,
    DynamicFiltersModule,
    InputFieldRangeModule
  ],
  providers: [
    FiltersValueService
  ]
})
export class FiltersModule {
}
