import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FeatureFlagService } from '../services/feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate {

  constructor(private router: Router, private featureFlagService: FeatureFlagService){}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      const {data: {feature}} = route;
      let featureIsActive = false;
      return await this.featureFlagService.getFeatureFlagData().then((featureFlagData) => {
        const selectionValid = featureFlagData.find(x => x.name === feature.name && x.page === feature.page)?.isActive;
        if (selectionValid) {
        featureIsActive = selectionValid;
        }
        if (featureIsActive){
          return true;
        }
        else{
          this.router.navigateByUrl('/');
          return false;
        }
      });
  }

}
