import { Injectable } from '@angular/core';
import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { UserProfile } from '../profile/menu/user.profile';

@Injectable()
export class AppInsightsService {
  appInsights: ApplicationInsights | undefined;
  pageViewLogging: boolean;
  dependencyLogging: boolean;

  constructor(private currentProfile: UserProfile) {
    this.pageViewLogging = (environment.appInsights.pageViewLogging === "true");
    this.dependencyLogging = (environment.appInsights.dependencyLogging === "true");

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: this.pageViewLogging,
        addRequestContext: (requestContext): any => {

        },
      }
    });
    this.appInsights.loadAppInsights();
    this.addAppInsightDependencyForCustomProperties();
  }

  addAppInsightDependencyForCustomProperties() {
    if (this.appInsights === undefined) {
      return;
    }
    this.appInsights.addDependencyInitializer((details) => {
      if (details.item.type === "Fetch") {
        // To stop any event from being reported you can
        return false;
      }

      if (!this.dependencyLogging) {
        if (details.item.type === "Ajax") {
          // To stop any event from being reported you can
          return false;
        }
      }

      details.context = this.getProperties(details.context);
      details.item.properties = this.getProperties(details.item.properties);
      return true;
    });
    this.appInsights.setAuthenticatedUserContext(this.currentProfile.getEmail());
  }

  logPageView(name: string, url?: string, properties?: any): any {
    if (this.appInsights === undefined) {
      return;
    }
    if (!this.pageViewLogging) {
      return;
    }
    this.appInsights.trackPageView({
      name,
      uri: url,
      properties: this.getProperties(properties),
    });
  }

  logEvent(name: string, properties?: any): any {
    if (this.appInsights === undefined) {
      return;
    }
    this.appInsights.trackEvent({ name }, this.getProperties(properties));
  }

  logMetric(name: string, average: number, properties?: any): any {
    if (this.appInsights === undefined) {
      return;
    }
    this.appInsights.trackMetric({ name, average }, this.getProperties(properties));
  }

  logException(exception: Error, severityLevel?: number, properties?: any): any {
    if (this.appInsights === undefined) {
      return;
    }
    this.appInsights.trackException({ exception, severityLevel }, this.getProperties(properties));
  }

  logTrace(message: string, properties?: any): any {
    if (this.appInsights === undefined) {
      return;
    }
    this.appInsights.trackTrace({ message }, this.getProperties(properties));
  }

  getProperties(properties?: any): any {
    const isHorizon = (location.pathname.indexOf('/horizon') >= 0);

    return Object.assign({}, properties, {
      "AppName": (isHorizon ? "pulse/horizonfe" : "pulsefe"),
      "User": this.currentProfile.getEmail(),
    });
  }
}