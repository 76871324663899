import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'he-link-cell',
  templateUrl: './link-cell.component.html',
  styleUrls: ['./link-cell.component.scss']
})
export class LinkCellComponent implements ICellRendererAngularComp {

  refresh(): boolean {
    return false;
  }
  constructor(private sanitizer:DomSanitizer){}
  public showUrl = false;
  public urls:string[] = [];
  public text = '';

  agInit(params: ICellRendererParams): void {
    if (params.value) {
      const text = params.value;
      try {
        const url = new URL(text);
        this.urls = params.value.split(',');
        this.text = url.href;
        this.showUrl = true;
      } catch (e) {
        this.showUrl = false;
      }
    }
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
}
}
