import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../../service/shared.service';

@Directive({
  selector: '[heGridFreezeHeader]'
})
export class GridFreezeHeaderDirective implements OnInit, OnDestroy {

  resizedFinished: NodeJS.Timeout | undefined;
  private readonly destroying$: Subject<void> = new Subject<void>();

  constructor(
    private el: ElementRef,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.subscribeViewportScroll();
    this.subscribeWindowResize();
    this.subscribeEastPanelVisibility();
  }

  subscribeViewportScroll(): void {
    this.sharedService.datastoreMiddleViewportScroll$.pipe(
      takeUntil(this.destroying$)
    ).subscribe(($event: Event | undefined) => {
      if (!$event) {
        return;
      }

      this.onScroll($event);
    });
  }

  subscribeEastPanelVisibility(): void {
    this.sharedService.datastoreEastPanelVisibility$.pipe(
      takeUntil(this.destroying$)
    ).subscribe((visible: boolean) => {
      this.onResize();
    });
  }

  subscribeWindowResize(): void {
    fromEvent(window, 'resize').pipe(
      takeUntil(this.destroying$)
    ).subscribe(($event: Event) => {
      this.onResize();
    });
  }

  onResize(): void {
    if (this.resizedFinished) {
      clearTimeout(this.resizedFinished);
    }

    this.resizedFinished = setTimeout(() => {
      this.onScroll();
    }, 550);
  }

  onScroll($event?: any): void {
    const nav = this.el.nativeElement.querySelector('[ref="headerRoot"]') as HTMLElement;
    const body = this.el.nativeElement.querySelector('[ref="eBodyViewport"]') as HTMLElement;
    const viewport = ($event ? $event.srcElement : document.querySelector('.he-viewport-middle-slot')) as HTMLElement;

    if (!nav || !body || !viewport) {
      return;
    }

    const navRect = nav.getBoundingClientRect();
    const bodyRect = body.getBoundingClientRect();
    const viewportRect = viewport.getBoundingClientRect();

    const topReached = bodyRect.top - navRect.height - viewportRect.top <= 0;
    const bottomReached = bodyRect.top + bodyRect.height - navRect.height - viewportRect.top < 0;
    if (topReached && !bottomReached) {
      nav.style.position = 'fixed';
      nav.style.top = viewportRect.top + 'px';
      nav.style.zIndex = '2';
      nav.style.width = bodyRect.width + 'px';
      body.style.marginTop = navRect.height + 'px';
    } else {
      nav.style.position = 'relative';
      nav.style.top = '';
      nav.style.zIndex = '';
      nav.style.width = '';
      body.style.marginTop = '';
    }
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
