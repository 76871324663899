import { Host, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';

@Injectable()
export class UserProfile {
  constructor(@Host() private authService: MsalService) {
  }

  private getAccount(): AccountInfo {
    return this.authService.instance.getAllAccounts()[0];
  }

  getUserName(): string {
    const acc = this.getAccount();
    return acc?.name || '';
  }

  getEmail(): string {
    const acc = this.getAccount();
    return acc?.username || '';
  }

  logout(): void {
    this.authService.logout();
  }
}
