import { Observable } from 'rxjs';
import { PackPriceOutputMessage } from '../../../core/pack-price';

export interface MessageRef {
  payload: unknown;
}


export abstract class PackPriceProvider {
  abstract run(payload: MessageRef): Observable<PackPriceOutputMessage>;
}
