import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NormalizedStylesComponent } from './normalized-styles.component';

@NgModule({
  declarations: [NormalizedStylesComponent],
  exports: [NormalizedStylesComponent],
  imports: [
    CommonModule
  ]
})
export class NormalizedStylesModule { }
