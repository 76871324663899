import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoButtonComponent } from './info-button.component';
import { IconModule } from '../../helios-icons';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [InfoButtonComponent],
  exports: [InfoButtonComponent],
  imports: [
    CommonModule,
    IconModule,
    MatMenuModule
  ]
})
export class InfoButtonModule {
}
