import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoTComparisonInput, CoTComparisonOutput } from '../../core/cot-comparison/interfaces';

@Injectable()
export class CoTComparisonApi {
  constructor(private httpClient: HttpClient) {
  }

  compare(query: CoTComparisonInput): Observable<CoTComparisonOutput> {
    return this.httpClient.post<any>('/cot/comparison', query);
  }
}
