import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PackPriceApi {
  constructor(private httpClient: HttpClient) {
  }

  transfer(req: unknown): Observable<unknown> {
    return this.httpClient.post<unknown>('/pack/price', req);
  }
}
