import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/helios-gui/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataRefreshService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  constructor(private http: HttpClient) {}

  getAllProjectPriceUpdates() {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(
          `${this.projectAPIUri}/DataRefresh/GetAllProjectPriceUpdates`
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  getProjectPriceUpdate(projectId: string) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(
          `${this.projectAPIUri}/DataRefresh/GetProjectPriceUpdate?projectId=${projectId}`
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  updateProjectPrice(projectId: string) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(
          `${this.projectAPIUri}/DataRefresh/UpdateProjectPrice?projectId=${projectId}`
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }
}
