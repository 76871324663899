import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2cPolicies.clientId,
      authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [environment.b2cPolicies.authorityDomain],
      navigateToLoginRequestUrl: true,
      redirectUri: window.location.origin + '/msad',
      postLogoutRedirectUri: window.location.origin + '/home'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}
