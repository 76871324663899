
<div class="card-he tab" *ngFor="let data of valueSummaryData ; let i = index">
  <div class="card-wrapper value-summary-table">
    <mat-card
      class="example-card"
    >
      <mat-card-header class="header-row">
        <div (click)="toggleAccordian(i)">
          <div class="heading value-summary-heading">
            <img
            style="float: left;margin-right: 10px;"
            src="assets/flags/small/{{data?.flagId }}.svg"
            width="22px"
          />
            <span class="heading-title">{{data.countryName}} : Value Summary</span>
            <mat-icon [ngClass]="activeAccordion.includes(i) ? 'opened' : 'closed'"
            >play_arrow</mat-icon
          >
          </div>
          <div class="record-count">
            <span>{{ data?.valueSummary?.length }} found</span>
          </div>
        </div>
        
        <div class="download-div-value-scoring" ><a (click)="downloadFile(data.countryName)"
          ><img
            src="/assets/dwonload-purple.svg" class="dwonload-icon"
            alt="Export Data"
            href="#"
        /></a></div>
      </mat-card-header>
      <div style="padding: 0px 16px;">      
      <mat-card-content class="value-summary-card" [ngClass]="activeAccordion.includes(i) ? 'visible':'hidden'" >  
        <mat-table mat-table
        [dataSource]="data?.valueSummary"
        class="value-summary-table"
        >
        
        <ng-container *ngFor="let col of valueSummaryHeaderData" [matColumnDef]="col.key" >
          <mat-header-cell mat-header-cell *matHeaderCellDef [ngClass]="col.key === 'Restrictions' ? 'longWidth' :  col.key === 'REVALMED-SNS&nbsp;(GC) CONSIDERATION' ? 'longerWidth' : 'shortWidth'">
            {{col.value}}
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element" [ngClass]="col.key === 'Restrictions' ? 'longWidth' :  col.key === 'REVALMED-SNS&nbsp;(GC) CONSIDERATION' ? 'longerWidth' : 'shortWidth'">
            {{element[col.key]}}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="keys"></mat-header-row>
        <mat-row
          [ngClass]="{ highlight: row?.highlight === true }"
          *matRowDef="let row; columns: keys;"
        ></mat-row>
        </mat-table>  
      </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>


