import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[heOnlyNumber]'
})
export class OnlyNumberDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
      const allowedKeys = ['e', '+', '-'];
      if (allowedKeys.includes(event.key)) {
          event.preventDefault();
      }
  }
}
