import { PriceColorCodes } from '../../../app/common/constants';

const pallate = {
  Blue: { color: '#46a2e1' },
  Green: { color: '#7eb488' },
  Yellow: { color: '#dc9725' },
  Gray: { color: '#354052' },
};

export function colorRender(params: any): any {
  let colorCode = '';
  switch (params.column.colId) {
    case 'exFactory':
    case 'exFactoryPrice':
    case 'exfactory':
    case 'exfactoryPrice':
      colorCode = params.data.exFactoryColorCode ?? params.data.exfactoryColorCode
      break;

    case 'hospital':
    case 'hospitalPrice':
      colorCode = params.data.hospitalColorCode;
      break;

    case 'ppExVat':
      colorCode = params.data.ppExVatColorCode;
      break;
      
    case 'ppIncVat':
      colorCode = params.data.ppIncVatColorCode;
      break;

    case 'ppp':
      colorCode = params.data.pppColorCode;
      break;
  }
  
  switch (colorCode) {
    case PriceColorCodes.Published:
      return pallate.Blue;
    case PriceColorCodes.Calculated:
      return pallate.Green;
    case PriceColorCodes.Estimated:
      return pallate.Yellow;
    default:
      return pallate.Gray;
  }
}
