import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterCotLevelComponent } from './filter-cot-level.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterCotLevelComponent],
  exports: [FilterCotLevelComponent],
  imports: [
    CommonModule,
    SelectModule
  ]
})
export class FilterCotLevelModule { }
