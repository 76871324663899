<div class="new-project-card" *heFeatureFlag="{
      Name: 'add-collaborator-form',
      Page: 'create-new-project'
    }">    
    <mat-card class="example-card">        
        <mat-card-header>
            <div class="heading">
                <!-- <img src="assets/lock.svg" class="project-icon" style="margin-top: -3px;"/> -->
                <!-- <img src="assets/lock.svg" class="project-icon" style="margin-top: -3px;"/> -->
                <span class="heading-title">Collaborators</span>                
            </div>            
        </mat-card-header>             
        <mat-card-content>  
            <div class="complete-div" *ngIf="collaboratorValid">
                <span class="complete">Complete</span>
              </div>             
            <div>
                <div class="input-container">
                    <div class="input-div" style="width: 30%">
                        <mat-form-field appearance="fill" style="width: 100%;" class="mat-form-field search-collab-field" id="search-collab-field"  [ngClass]="{ 'disable-Input': editProjectId !== '' }">
                            <mat-label></mat-label>
                            <mat-icon matPrefix class="collab-search-icons">search</mat-icon>
                            <input matInput [matAutocomplete]="autoCollaborator" [formControl]="CollaboratorsControl"
                                [value]="(CollaboratorsControl.value)?(CollaboratorsControl.value):''"   [readonly]="editProjectId !== ''"/>
                            <mat-autocomplete #autoCollaborator="matAutocomplete">
                                <mat-option *ngFor="let option of filteredCollaborators | async" [value]="option.email">
                                    {{ option.email }}({{option.fullName}})
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>Collaborator is Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="input-div" style="margin-left: 20px">
                        <button mat-button [disabled]="(CollaboratorsControl.value === '') || editProjectId !== ''" (click)="UpdateCollaborator()"
                            class="horizon-mat-button-filled" id="add-collab-btn"
                            [ngClass]="(CollaboratorsControl.value === '')|| editProjectId !== ''? 'disabled' : 'notDisabled'">
                            Add Collaborator
                        </button>
                    </div>
                </div>
                <div class="table-div">
                    <div class="example-container mat-elevation-z8">
                        <mat-table #table matSort [dataSource]="selectedCollaborator" id="collaborators-table">
                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="heading-color" >
                                    Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="bold-text">
                                    <mat-icon mat-card-avatar class="he-ava-btn he-btn-themed"
                                        aria-label="Project collaborators" mat-mini-fab>
                                        <span class="he-ava-text">{{
                                            element.fullName | nameInitials | uppercase
                                            }}</span>
                                    </mat-icon>
                                    {{ element.fullName }}
                                </mat-cell>
                            </ng-container>

                            <!-- Email Column -->
                            <ng-container matColumnDef="email">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="heading-color">
                                    Email
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ element.email }}
                                </mat-cell>
                            </ng-container>

                            <!-- Access Level Column -->
                            <ng-container matColumnDef="accessLevel">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="heading-color">
                                    Access Level
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <!-- {{ element.accessLevel }} -->
                                    <mat-form-field appearance="fill" [ngClass]="{ 'disable-Input': editProjectId !== '' }" class="mat-form-field accessLevelBox">
                                        <mat-select [(value)]="element.accessLevel" class="accessLevelOption" matNativeControl required
                                        (selectionChange)="UpdateCollaboratorInService()" [disabled]="editProjectId !== ''">
                                          <mat-option *ngFor="let accessLevel of accessLevels" [value]="accessLevel.id" class="accessLevelOption">{{accessLevel.name}}</mat-option>                                          
                                        </mat-select>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index">
                                  <button class="removeCell" *ngIf="editProjectId === ''" [disabled]="editProjectId !== ''" (click)="RemoveCollaborator(i)">
                                    Remove
                                  </button>
                                </mat-cell>
                              </ng-container>

                            <mat-header-row *matHeaderRowDef="collaboratorColumns"></mat-header-row>
                            <mat-row *matRowDef="
                                let row;
                                columns: collaboratorColumns;
                                let i = index
                            "></mat-row>
                        </mat-table>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>