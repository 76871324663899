<button class="he-ava-btn he-btn-themed" aria-label="Profile menu" mat-mini-fab [matMenuTriggerFor]="menu">
  <span class="he-ava-text">{{ getProfileName() | initials | uppercase }}</span>
</button>

<mat-menu #menu="matMenu" class="no-min-height he-menu-panel">
  <button mat-menu-item class="he-btn-menu" (click)="logout()">
    <he-icon id="log-out"></he-icon>
    <span>Logout</span>
  </button>
</mat-menu>
