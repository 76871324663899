import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { AppInsightsService } from '../../app/app-insights/app-insights-logging.service';
// import { ErrorDialogService } from '../../shared/errors/error-dialog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        // private errorDialogService: ErrorDialogService,
        private zone: NgZone,
        private appInsightsService: AppInsightsService,
    ) { }

    handleError(error: any) {
        // console.error('Error from global error handler', error);
        // // Check if it's an error from an HTTP response
        // if (!(error instanceof HttpErrorResponse)   ) {
        //     error = error.rejection; // get the error object
        // }
        this.zone.run(() => {
            // var err=Error(){
            // }
            this.appInsightsService.logException(error, 1);
            // this.errorDialogService.openDialog(
            //     error?.message || 'Undefined client error'
            // )
        });
        // console.error('Error from global error handler logged', error);
    }
}