import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService } from '@azure/msal-angular';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALInstanceFactory } from './msal-instance-factory';
import { MSALInterceptorConfigFactory, } from './msal-interceptor-config-factory';
import { MSALGuardConfigFactory } from './msal-guard-config-factory';
import { LoginComponent } from './login/login.component';
import { AccessPermissionsApi } from '../../helios-api/access-permissions';
import { AccessPermissions, CachedAccessPermissions } from '../../core/access-permissions';
import { PlatformAccessApi } from '../../helios-api/platform-access/platform-access.api';
import { PlatformAccess } from '../../core/platform-access';

@NgModule({
  declarations: [
    LoginComponent
  ],
  exports: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MsalModule
  ],
  providers: [
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    PlatformAccessApi,
    {provide: PlatformAccess, useFactory: PlatformAccess.create, deps: [PlatformAccessApi]},
    AccessPermissionsApi,
    {provide: AccessPermissions, useFactory: AccessPermissions.create, deps: [AccessPermissionsApi]},
    {provide: CachedAccessPermissions, useFactory: CachedAccessPermissions.create, deps: [AccessPermissions]}
  ]
})
export class AuthModule {}
