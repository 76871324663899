import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterAutocompleteWithChipsComponent } from './filter-autocomplete-with-chips.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { HighlightTextModule } from '../highlight-text';
import { IconModule } from '../helios-icons';
import { ChipsListModule } from '../chips-list';
import { FilterAutocompleteModule } from '../filter-autocomplete';

@NgModule({
  declarations: [FilterAutocompleteWithChipsComponent],
  exports: [FilterAutocompleteWithChipsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    HighlightTextModule,
    IconModule,
    ChipsListModule,
    FilterAutocompleteModule,
  ]
})
export class FilterAutocompleteWithChipsModule {
}
