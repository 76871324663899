import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { FilterItemValueInterface } from '../../app/datastore/interfaces';
import { FilterAutocompleteDescriptionInterface, FilteredGroupItemsInterface } from '../filter-autocomplete-with-chips/interfaces';
import { SharedService } from '../service/shared.service';
@Component({
  selector: 'he-filter-autocomplete',
  templateUrl: './filter-autocomplete.component.html',
  styleUrls: ['./filter-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterAutocompleteComponent implements OnInit, OnDestroy {
  filterControl: FormControl = new FormControl('');
  @Input() descriptions: FilterAutocompleteDescriptionInterface | undefined;
  @Input() filteredItems$: Observable<any[]> | undefined;
  @Input() filteredGroupItems$: Observable<FilteredGroupItemsInterface[]> | undefined;
  @Input() loading: boolean | undefined;
  @Input() hasClearIcon = false;
  @Input() showErrorIfEmpty = true;
  @Input() isGroupView!: boolean;
  @Input() hasSelectedItemText!: boolean;
  @Input() isLinkAvailable: boolean | undefined;
  @Input() disabled: boolean | null = null;
  @Input() disabledTooltip: string | undefined;
  @Input() showListOnEmptyText: boolean = false;
  @Output() selectedItemChanges: EventEmitter<FilterItemValueInterface> = new EventEmitter();
  @Output() callFilterFunction: EventEmitter<string> = new EventEmitter();
  @Output() clearValue: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autocompleteTrigger!: MatAutocompleteTrigger;
  private isUserSelectEvent = false;
  private readonly destroying$: Subject<void> = new Subject<void>();

  private selectedItemsValue: FilterItemValueInterface[] = [];
  private selectedItemsValueBackUp: FilterItemValueInterface[] = [];
  private isShowErrorMessage: boolean = true;
  public isTextChanged: boolean = false;

  @Input() public set selectedItems(inValue: FilterItemValueInterface[]) {
    this.selectedItemsValue = inValue;
    this.setSelectedItemText(true);
  }
  public get selectedItems(): FilterItemValueInterface[] {
    return this.selectedItemsValue;
  }

  constructor(public sharedService: SharedService) {
  }

  get isClearButtonVisible(): boolean {
    return this.autocompleteTrigger && !this.autocompleteTrigger.panelOpen && this.hasClearIcon && this.filterControlValueLength > 0;
  }

  get isErrorIfEmptyVisible(): boolean {
    return  this.isShowErrorMessage && this.showErrorIfEmpty && !this.disabled && !this.selectedItems.length && !this.sharedService.IsBrandPrefSelectedInMainSearch$.value ;
  }

  ngOnInit(): void {
    this.setSelectedItemText(true);
    this.filterControl.valueChanges.pipe(
      takeUntil(this.destroying$),
      filter((value) => !!value && (typeof value === 'string')),
      switchMap((value: string) => {
        this.callFilterFunction.emit(value);
        return [];
      }),
    ).subscribe();

    if (this.descriptions?.title.toLowerCase() === "indications") {
      this.sharedService?.gridModeValue$.subscribe((mode: string) => {
        this.isShowErrorMessage = (mode === "chart");
      });

      let girdModeSub$ = this.sharedService?.gridModeValue$.subscribe((mode: string) => {
        if (this.sharedService.isInsightCompanySearchTableMode) {
          this.selectedItemsValueBackUp = this.selectedItemsValue;
          this.filterControl.patchValue('');
        }
        else if (this.sharedService.isInsightCompanySearchChartMode && this.selectedItemsValueBackUp.length) {
          if (this.selectedItemsValue.length == 0) {
            this.filterControl.patchValue(this.selectedItemsValueBackUp[0].name);
          }
          this.selectedItemsValueBackUp = [];
          girdModeSub$.unsubscribe();
        }
      });
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.isUserSelectEvent = true;
    this.sharedService.showBrandPrefSaveIcon$.next(true);
    this.selectedItemChanges.emit(event.option.value);
    this.filterControl.patchValue(this.hasSelectedItemText ? event.option.value.name : '');
    this.autocompleteTrigger.closePanel();
    this.isUserSelectEvent = false;
  }

  onBlur(): void {
    this.isTextChanged = false;
    if (this.autocompleteTrigger && !this.autocompleteTrigger.panelOpen && this.filterControlValueLength < 2) {
      this.setSelectedItemText();
    }
  }

  onFocus(): void {
    this.isTextChanged = false;
    if (this.autocompleteTrigger && this.showListOnEmptyText && this.filterControlValueLength === 0) {
      this.doClearAndCallFilterFunction();
    }
  }

  onInput(): void {
    this.isTextChanged = true;
    if (this.autocompleteTrigger && this.showListOnEmptyText && this.filterControlValueLength === 0) {
      this.doClearAndCallFilterFunction();
    }
  }

  setSelectedItemText(isOnInit: boolean = false): void {
    if (this.hasSelectedItemText && this.selectedItems.length && !this.isUserSelectEvent) {
      if (this.showListOnEmptyText && this.filterControlValueLength === 0 && !isOnInit && this.isTextChanged) {
        this.doClearAndCallFilterFunction();
        return;
      }

      this.filterControl.patchValue(this.selectedItems[0].name);
    }
  }

  onClear(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.filterControlValueLength > 0) {
      this.filterControl.patchValue('');
      this.clearValue.emit(true);
    }
  }

  gridView(): void {
    this.sharedService.updateGridToggle();
  }

  get filterControlValueLength(): number {
    return this.filterControl.value.trim().length;
  }

  doClearAndCallFilterFunction() {
    const value: string = '';
    this.filterControl.patchValue(value);
    this.callFilterFunction.emit(value);
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
