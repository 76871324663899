import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryFilterResetDialogComponent } from './country-filter-reset-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { DialogModule } from '../dialog';



@NgModule({
  declarations: [
    CountryFilterResetDialogComponent
  ],
  exports: [
    CountryFilterResetDialogComponent
  ],
  imports: [
    CommonModule,    
    DialogModule,
    MatButtonModule
  ]
})
export class CountryFilterResetDialogModule { }
