import { Gateway } from '../gateway';
import { Assert } from '../assert';
import { asyncScheduler, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';

export interface Message {
    type: string;
    dataset: unknown;
    error?: boolean;
}

export class ReimbursementComparator {
    static create(gateway: Gateway): ReimbursementComparator {
        return new ReimbursementComparator(gateway);
    }
    constructor(private gateway: Gateway) {
        Assert.hasMethod(gateway, 'transfer', `Injected gateway ${gateway} has no "transfer" method`);
    }

    run(event: any): Observable<Message> {
        return of(event).pipe(
            mergeMap(() => {
                return this.gateway.transfer(event).pipe(
                    map((response) => {
                        return {
                            type: 'done',
                            dataset: response
                        };
                    }),
                    catchError((err) => {
                        return of({
                            type: 'fail',
                            error: true,
                            dataset: err
                        });
                    }),
                    startWith({
                        type: 'pending',
                        dataset: undefined
                    }),
                    subscribeOn(asyncScheduler)
                );
            })
        );
    }
}
