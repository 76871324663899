import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { FilterItemValueInterface } from '../../uikit/filters';
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}
@Injectable({
  providedIn: 'root',
})
export class ExportCsvObject {
  private rows: object[] | undefined;
  private headers: string[] | undefined;
  private fileName = '';

  registerHeaders(headers: string[]): void {
    this.headers = headers;
  }

  registerDataset(rows: any): void {
    this.rows = rows;
  }

  registerName(
    chartName: string,
    brandNames?: FilterItemValueInterface[]
  ): void {
    this.fileName = brandNames
      ? this.getCsvName(chartName, brandNames)
      : chartName;
  }

  clear(): void {
    this.rows = undefined;
    this.headers = undefined;
    this.fileName = '';
  }

  csvContent: string = '';

  exportToCsv(signpostingVal?: string): void {
    if (
      !this.rows ||
      !this.rows.length ||
      !this.headers ||
      !this.headers.length
    ) {
      return;
    }

    const separator = ',';
    const keys = Object.keys(this.rows[0]);
    this.csvContent =
      this.headers.map((h) => this.cellToString(h)).join(separator) +
      '\n' +
      this.rows
        .map((row) => {
          const rowObj: { [key: string]: any } = row;
          return keys
            .map((k) => {
              const cell =
                rowObj[k] === null || rowObj[k] === undefined ? '' : rowObj[k];
              const cellString = this.cellToString(cell);
              return cellString;
            })
            .join(separator);
        })
        .join('\n');

    if (signpostingVal != null && signpostingVal !== '') {
      //   // Add an empty row
      this.csvContent += '\n';
      const unwrappedText = signpostingVal.replace(/(\r\n|\n|\r)/gm, ''); // Remove line breaks
      this.csvContent += '\r\n' + '"' + unwrappedText.replace(/"/g, '""') + '"';
    }
    const blob = new Blob(['\ufeff', this.csvContent], {
      type: 'text/csv;charset=UTF-8',
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, this.fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', this.fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportToXlxs(): void {
    if (
      !this.rows ||
      !this.rows.length ||
      !this.headers ||
      !this.headers.length
    ) {
      return;
    }
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.fileName);
    const keys = Object.keys(this.rows[0]);
    worksheet.addRow(this.headers);
    let rowsData = this.rows.map((row) => {
      const rowObj: { [key: string]: any } = row;
      return keys.map((k) => {
        const cell =
          rowObj[k] === null || rowObj[k] === undefined ? '' : rowObj[k];
        const cellString = this.cellToStringXlxs(cell);
        return cellString;
      });
    });
    const legends = [
      [],
      ['GREEN', 'Formal reference'],
      ['GRAY', 'Informal reference'],
      ['PURPLE', 'Not applicable'],
    ];

    const iRPNote = [
      ['Please note that, where more than one IRP rule set is in place in a given country, the information in the matrix is based on the standard IRP rules used for setting list prices for the majority of pharmaceutical products.']
    ];

    rowsData.forEach((record: any) => {
      worksheet.addRow(Object.values(record));
    });
    legends.forEach((record: any) => {
      worksheet.addRow(Object.values(record));
    });;
    iRPNote.forEach((record: any) => {
      worksheet.addRow('');
      worksheet.addRow(Object.values(record));
    });;
    //additional formating might be needed
    // worksheet.addConditionalFormatting({
    //   ref: 'A1:ER110',
    //   rules: [
    //     {
    //       priority: 1,
    //       type: 'containsText',
    //       operator: 'containsText',
    //       text: 'GREEN',
    //       style: {
    //         fill: {
    //           type: 'pattern',
    //           pattern: 'solid',
    //           bgColor: { argb: '4CA9B5' },
    //           fgColor: { argb: '4CA9B5' },
    //         },
    //       },
    //     }
    //   ],
    // });
    worksheet.eachRow(function (row) {
      row.eachCell(function (cell) {
        switch (cell.value) {
          case 'GREEN':
            cell.value = '';
            cell.style.fill = {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: '4CA9B5' },
              fgColor: { argb: '4CA9B5' },
            };
            break;
          case 'PURPLE':
            cell.value = '';
            cell.style.fill = {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: '4E4691' },
              fgColor: { argb: '4E4691' },
            };
            break;
          case 'GRAY':
            cell.value = '';
            cell.style.fill = {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: '92a1b9' },
              fgColor: { argb: '92a1b9' },
            };
            break;
          default:
            cell.style.fill = {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'ffffff' },
              fgColor: { argb: 'ffffff' },
            };
            break;
        }
      });
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, this.fileName);
    });
  }
  private cellToStringXlxs(cell: any): any {
    const isDate = cell instanceof Date;
    const isNumber = cell instanceof Number;
    let cellStr = isDate
      ? formatDate(cell, 'dd/MM/yyyy', 'en_GB')
      : isNumber
      ? cell
      : cell;
    cellStr =
      cellStr === '*'
        ? 'PURPLE'
        : cellStr === 'F'
        ? 'GREEN'
        : cellStr === 'I'
        ? 'GRAY'
        : cellStr;
    return cellStr;
  }
  private cellToString(cell: any): string {
    const isDate = cell instanceof Date;
    const isNumber = cell instanceof Number;
    let cellStr = isDate
      ? formatDate(cell, 'dd/MM/yyyy', 'en_GB')
      : isNumber
      ? cell.toString()
      : cell.toString().replace(/"/g, '""');
    if (!isNumber && !isDate) {
      // wrap all the text values with "" but not numbers or dates
      cellStr = `"${cellStr}"`;
    }
    return cellStr;
  }

  private getCsvName(
    chartName: string,
    brandNames: FilterItemValueInterface[]
  ): string {
    const brand =
      brandNames.length > 1
        ? brandNames.length
        : brandNames.map((el) => el.name);
    return `${chartName.replace(/\s/g, '_').toLowerCase()}_${brand}.csv`;
  }
}
