import { EMPTY, Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class DatastoreContext {
  public ref$: Observable<unknown> = EMPTY;
  public details$: Observable<unknown> = EMPTY;

  config: any[] = [];

  saveReference(ref: unknown): void {
    this.ref$ = of(ref).pipe(shareReplay({bufferSize: 1, refCount: true}));
    this.details$ = EMPTY;
  }

  saveDetails(details: unknown): void {
    this.details$ = of(details).pipe(shareReplay({bufferSize: 1, refCount: true}));
  }
}
