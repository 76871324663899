import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldComponent } from './input-field.component';

@NgModule({
  declarations: [InputFieldComponent],
  exports: [InputFieldComponent],
  imports: [
    CommonModule
  ]
})
export class InputFieldModule {
}
