import { Injectable } from '@angular/core';
import { Observable, asyncScheduler, catchError, map, mergeMap, of, startWith, subscribeOn } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomColumnListOutput } from './interfaces';

@Injectable()

export class GridViewCustomAPIService {
  constructor(private httpClient: HttpClient) { }

  getGridViewCustomDetails(req: string): Observable<CustomColumnListOutput> {
    let request = new HttpParams().set('gridViewType', req)
    return this.httpClient.get<CustomColumnListOutput>('/IndicationCoT/selectedColumns', { params: request }).pipe(
      map((response: CustomColumnListOutput) => {
        return {
          type: 'done',
          unSelectedColumns: response.unSelectedColumns,
          selectedColumns: response.selectedColumns,
          gridViewType: response.gridViewType,
        } as CustomColumnListOutput;
      }),
      catchError((err: any) => {
        return of({
          type: 'fail',
          error: true,
          unSelectedColumns: '',
          selectedColumns: '',
          gridViewType: '',
        } as CustomColumnListOutput);
      }),
      startWith({
        type: 'pending',
        unSelectedColumns: '',
        selectedColumns: '',
        gridViewType: '',
      } as CustomColumnListOutput),
      subscribeOn(asyncScheduler)
    );
  }

}