import { Component, ContentChildren, QueryList } from '@angular/core';
import { AllowDirective } from './allow.directive';
import { DenyDirective } from './deny.directive';

@Component({
  selector: 'he-access-barrier, he-restricted-zone',
  templateUrl: './access-barrier.component.html',
  styleUrls: ['./access-barrier.component.scss']
})
export class AccessBarrierComponent {
  @ContentChildren(AllowDirective) allowed!: QueryList<AllowDirective>;
  @ContentChildren(DenyDirective) denied!: QueryList<DenyDirective>;

  isAllowed(code: string, allowedRules: unknown): boolean {
    if(code.toLowerCase() === 'allowed')
      return true;

    const rules = allowedRules as {code: string}[];
    return !!rules.find((rule) => rule.code.toLocaleLowerCase() === code.toLocaleLowerCase());
  }
}
