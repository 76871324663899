import { Observable } from 'rxjs';
import { FormulationAndPackDetailsOutputMessage } from '../../../core/formulation-and-pack-details';

export interface MessageRef {
  payload: unknown;
}

export abstract class FormulationAndPackDetailsProvider {
  abstract run(input: MessageRef): Observable<FormulationAndPackDetailsOutputMessage>;
}
