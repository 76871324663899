import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { FiltersValueService } from '../../filters-value.service';

@Injectable()
export class CotCalculatorService {
  readonly projectAPIUri: string = environment.gatewayApiEndpoint;

  public countryBrandata = new BehaviorSubject<any>(null);
  public castCountryBrandata = this.countryBrandata.asObservable();

  public dataSource = new BehaviorSubject<any[]>([]);
  public castdataSource = this.dataSource.asObservable();

  constructor(private httpClient: HttpClient,private filtersValueService:FiltersValueService) {}

  public getCotDetails(requestData: any): Observable<any> {
    const urlString = new URL(
      `${this.projectAPIUri}/cot/GetCoTCalculatorDetails`
    );
    return this.httpClient.post<any>(urlString.href, requestData);
  }

}
