import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HorizonProjectService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;


  constructor(private http: HttpClient,private route:ActivatedRoute) {
    
  }

  async GetSelectedProjectId(){
    let selectedProjectId="";
    await this.route.queryParams.pipe(take(1)).subscribe((f) => {
      selectedProjectId=f.project;
    });
    return selectedProjectId;
  }

  getProjectList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.projectAPIUri + '/Projects/Projects').subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  getProjectListOb(): Observable<any[]> {
    return this.http.get<any>(this.projectAPIUri + '/Projects/Projects');
  }
}
