<div *ngIf="!showSection">
  <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
</div>
<div *ngIf="showSection" class="table-responsive">
  <table mat-table [dataSource]="dataSource" matTableExporter
  #exporter="matTableExporter">
    <!-- Branded INN Name Column -->
    <ng-container matColumnDef="brandedInnName" sticky>
      <th
        mat-header-cell
        *matHeaderCellDef
        style="padding-left: 0px"
        class="brandedInnName"
      >
        Brand Name
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        style="padding-left: 0px"
        class="brandedInnNameRow"
      >
        {{ element.brandedInnName }}
      </td>
    </ng-container>

    <!-- Initial Launch Date Column -->
    <ng-container matColumnDef="initialLaunchDate">
      <th mat-header-cell *matHeaderCellDef class="colGapLeft">
        Initial Launch Date
      </th>
      <td mat-cell *matCellDef="let element" class="rowGapLeftNone">
        {{ element.initialLaunchDate }}
      </td>
    </ng-container>

    <!-- Indication Column -->
    <ng-container matColumnDef="indication" sticky>
      <th mat-header-cell *matHeaderCellDef>Indication</th>
      <td mat-cell *matCellDef="let element" class="rowGapLeftNone">
        {{ element.indication }}
      </td>
    </ng-container>

    <!-- Macenism of Action Column -->
    <ng-container matColumnDef="macenismOfAction">
      <th
        mat-header-cell
        *matHeaderCellDef
        style="flex-grow: 2"
        class="colGapLeft"
      >
        Mechanism of Action
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        style="flex-grow: 2"
        class="rowGapLeftNone"
        [matTooltip]="element.macenismOfAction"
        matTooltipClass="horizon-tooltip"
      >
      <div class="horizon-text-ellipsis">{{ element.macenismOfAction }}</div>        
      </td>
    </ng-container>

    <ng-container *ngFor="let country of countries" [matColumnDef]="country">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="country-column country-header"
      >
        <he-country-cell [country]="country"></he-country-cell>
      </th>
      <td
        mat-cell
        class="country-column"
        *matCellDef="let element"
        [class.na]="element[country] === 'NA'"
      >
        {{
          element[country] === null
            ? "NA"
            :element[country]
        }}
      </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      [ngClass]="{ highlight: row?.highlight === true }"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </table>
</div>
