import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsListComponent } from './chips-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { IconModule } from '../helios-icons';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ChipsListComponent],
  exports: [ChipsListComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    IconModule,
    MatTooltipModule
  ]
})
export class ChipsListModule {
}
