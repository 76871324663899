<he-dialog (terminate)="onReject()">
  <div class="text-center">
      <p class="he-secondary-text p-4">{{ text }}</p>
      <mat-form-field *ngIf="showInput" appearance="outline" placeholder="" class="he-form-field">
        <label>
          <input type="text"
                 placeholder=""
                 matInput
                 [value]="inputText"
                 (keyup)="onKey($event)">
        </label>
      </mat-form-field>
      <ng-content></ng-content>
      <div>
          <div class="p-2 d-inline-block">
              <button class="he-theme-button he-lg-button" mat-button (click)="onConfirm()">
                  <span class="font-weight-bold">{{ confirmButtonText }}</span>
              </button>
          </div>
          <div class="p-2 d-inline-block">
              <button class="he-standard-button he-lg-button" mat-button (click)="onReject()">
                  <span class="font-weight-bold">{{ rejectButtonText }}</span>
              </button>
          </div>
      </div>
  </div>
</he-dialog>
