export enum colorCodeEnum {


 

  "#0070C0",
  "#A32CC4",
  "#EF6787",
  "#3CB244",
  "#44D8CA",
  "#FFC30B",
  "#F5761A",
  "#FF0000",

  "#0A1172",
  "#540052",
  "#9E005A",
  "#234F1E",
  "#005A58",
  "#826300",
  "#5E1914",
  "#8E3900",

  "#ADDFF5",
  "#D8BEEC",
  "#FFC9E1",
  "#CBF2B9",
  "#ABFFE3",
  "#FDEE87",
  "#FF7D7D",
  "#FFCBA4",

  "#4682B4",
  "#B766D5",
  "#F99DBC",
  "#74B72E",
  "#61E1B0",
  "#FCE205",
  "#FD3903",
  "#FF8C00",

  "#1135A7",
  "#81007F",
  "#FF0090",
  "#0B6623",
  "#02829C",
  "#C29200",
  "#960019",
  "#CC5500",

  "#79BAEC",
  "#E39FF6",
  "#EBBEC3",
  "#99FA99",
  "#A1D4D3",
  "#EFFD5F",
  "#D92638",
  "#FF9966",

  "#6694F6",
  "#D473D3",
  "#DA98A4",
  "#AEF359",
  "#95E9E1",
  "#FDE64B",
  "#FE5948",
  "#FFB347",

  "#0041C2",
  "#7030A0",
  "#FE5BAC",
  "#028A0F",
  "#02CED2",
  "#F6AC00",
  "#D40000",
  "#EE5511",
}
export enum colorCodeEnumAnalgoue{
  "#FF0000",
  "#F5761A",
  "#FFC30B",
  "#3CB244",
  "#44D8CA",
  "#0070C0",
  "#A32CC4",
  "#EF6787",
  "#5E1914",
  "#8E3900",
  "#826300",
  "#234F1E",
  "#005A58",
  "#0A1172",
  "#540052",
  "#9E005A",
  "#FF7D7D",
  "#FFCBA4",
  "#FDEE87",
  "#CBF2B9",
  "#ABFFE3",
  "#ADDFF5",
  "#D8BEEC",
  "#FFC9E1",
  "#FD3903",
  "#FF8C00",
  "#FCE205",
  "#74B72E",
  "#61E1B0",
  "#4682B4",
  "#B766D5",
  "#F99DBC",
  "#960019",
  "#CC5500",
  "#C29200",
  "#0B6623",
  "#02829C",
  "#1135A7",
  "#81007F",
  "#FF0090",
  "#D92638",
  "#FF9966",
  "#EFFD5F",
  "#99FA99",
  "#A1D4D3",
  "#79BAEC",
  "#E39FF6",
  "#EBBEC3",
  "#FE5948",
  "#FFB347",
  "#FDE64B",
  "#AEF359",
  "#95E9E1",
  "#6694F6",
  "#D473D3",
  "#DA98A4",
  "#D40000",
  "#EE5511",
  "#F6AC00",
  "#028A0F",
  "#02CED2",
  "#0041C2",
  "#7030A0",
  "#FE5BAC",
}
