import { asyncScheduler, Observable, of } from 'rxjs';
import { catchError, map, startWith, subscribeOn } from 'rxjs/operators';
import { Assert } from '../assert';
import { Gateway } from './gateway';
import { MessageRef, Message } from '../message';

export class UpdateProject {
  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${gateway} has no "transfer" method`);
  }

  static create(gateway: Gateway): UpdateProject {
    return new UpdateProject(gateway);
  }

  run(event: MessageRef): Observable<Message> {
    const emptyProject = {content: {}};
    const payload = Object.assign(emptyProject, event.payload);
    const serialized = Object.assign({}, payload, {content: JSON.stringify(payload.content)});

    return this.gateway.transfer(serialized).pipe(
      map((response) => {
        return {
          type: 'done',
          payload: Object.assign({}, event.payload, {id: response})
        };
      }),
      catchError((err) => {
        return of({
          type: 'fail',
          error: true,
          payload: err
        });
      }),
      startWith({
        type: 'pending',
        payload: undefined
      }),
      subscribeOn(asyncScheduler)
    );
  }
}
