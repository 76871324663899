import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StickyHeaderComponent } from './components/sticky-header/sticky-header.component';
import { CountryCellComponent } from './components/country-cell/country-cell.component';
import { IconModule } from 'projects/helios-gui/src/uikit/helios-icons';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { ExportImageService } from './services/export-image.service';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { WarningComponent } from './components/warning/warning.component';
import { HelpMenuModule } from '../../help-menu/help-menu.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BlockDecimalDirective } from '../modules/project/directives/bloack-decimal.directive';
import { OnlyNumberDirective } from '../modules/project/directives/only-number.directive';
import { UpdateFrameworkComponent } from './components/update-framework/update-framework.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    StickyHeaderComponent,
    CountryCellComponent,
    NameInitialsPipe,
    FeatureFlagDirective,
    WarningComponent,
    OnlyNumberDirective,
    BlockDecimalDirective,
    UpdateFrameworkComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    FlexLayoutModule,
    MatMenuModule,
    MatListModule,
    RouterModule,
    IconModule,
    MatDialogModule,
    HelpMenuModule,
    MatTooltipModule

    // HighchartsChartModule
  ],
  exports: [
    HeaderComponent,
    StickyHeaderComponent,
    FooterComponent,
    SidebarComponent,
    CountryCellComponent,
    NameInitialsPipe,
    FeatureFlagDirective,
    OnlyNumberDirective,
    BlockDecimalDirective
  ]
})
export class SharedModule { }
