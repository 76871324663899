import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HorizonState } from '../models/state.model';
import {
  HorizonActionTypes,
  LoadProjects,
  LoadProjectsSuccess,
} from '../actions/horizon.action';
import { map, mergeMap, withLatestFrom } from 'rxjs';
import { HorizonProjectService } from '../../../horizon-project/horizon-project.service';
import { GlobalSpinnerService } from 'projects/helios-gui/src/uikit/loading-spinner/global-spinner.service';

@Injectable()
export class HorizonEffectsService {
  constructor(
    private actions$: Actions,
    private horizonStore: Store<HorizonState>,
    private projectService: HorizonProjectService,
    private globalSpinnerService: GlobalSpinnerService,
  ) {}

  loadProjectList$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadProjects>(HorizonActionTypes.LoadProjects),
      map((action: LoadProjects) => action),
      mergeMap(() => {
        return this.projectService.getProjectListOb().pipe(
          map((response: any) => {
            let peojects: any;
            peojects = new LoadProjectsSuccess(response);
            this.globalSpinnerService.loadingSpinner(false);
            return peojects;
          })
        );
      })
    ) }
  );
}
