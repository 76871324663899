import { Component } from '@angular/core';

@Component({
  selector: 'he-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent {

  gotoFaq(redirectURL: string) {
    window.open(redirectURL, "_blank");
  }

  gotoKnowledgeBase(redirectURL: string) {
    window.open(redirectURL, "_blank");
  }
}
