<div class="card-he tab">
  <div class="card-wrapper main-grid-wrapper value-score-table">
    <mat-card
      class="example-card"
      [ngClass]="{
        'example-card-extra': selectedIndex > 0 && extraColumns.length > 0
      }"
    >
      <mat-card-header class="header-row">
        <div>
          <div class="heading value-scoring-heading">
            <span class="heading-title">VALUE SCORING</span>
            <!-- <mat-icon>info_outline</mat-icon> -->
          </div>
          <div class="record-count">
            <span>{{ mainGridRecordCount }} found</span>
          </div>
        </div>
        <!-- <div class="options-radio-group">
          <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button
              class="options-radio-button"
              *ngFor="let option of filterOptions; let i = index"
              [value]="i"
            >
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div> -->
        
        <div class="download-div-value-scoring" ><a (click)="downloadFile()"
          ><img
            src="/assets/dwonload-purple.svg" class="dwonload-icon"
            alt="Export Data"
            href="#"
        /></a></div>
      </mat-card-header>
      <div *ngIf="!showSection">
        <he-loading-spinner
          [diameter]="70"
          [height]="'350px'"
        ></he-loading-spinner>
      </div>
      <div *ngIf="showSection">      
      <mat-card-content style="padding:0 16px !important" class="socring-table">      
        <he-project-value-scoring-main-grid
          [chartData]="chartData"
          [extraColumns]="extraColumns"
          [filteredDatacolumn]="filteredDatacolumn"
          [selectedCountry]="selectedCountry"
          [colorIndexList]="colorIndexList"
          [selectedOption]="selectedOption"
          (extraColumnsEvent)="mainGridSelectedIndexChanged($event)"
        ></he-project-value-scoring-main-grid>
      </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
<!-- this is the COT graph -->
<!-- <div class="card-he tab" #dataToExport>
  <div class="card-wrapper total-value-scoring-table">
    <mat-card class="example-card" style="background: white;">
      <mat-card-header class="inline-centered-items" style="background: white;">
        <div>
          <div class="heading value-scoring-heading">
            <span class="heading-title">TOTAL VALUE SCORING BY COUNTRIES</span>
          </div>
          <div class="record-count">
            <span>{{ subGridRecordCount }} found</span>
          </div>
        </div>
        <div><a class="download-div" (click)="dwonloadFileCtry()"
          ><img
            src="/assets/dwonload-purple.svg" class="dwonload-icon"
            alt="Export Data"
            href="#"
        /></a></div>
       </mat-card-header>
       <mat-card-header class="button-centered-align" style="background: white;">
        <mat-button-toggle-group [value]="viewMode" name="fontStyle" aria-label="Font Style" (change)="changeView($event.value)">
          <mat-button-toggle value="Percentage">Percentage</mat-button-toggle>
          <mat-button-toggle value="AbsoluteValue">Absolute Value</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-card-header>
      <mat-card-content style="background: white;">
        <he-project-value-scoring-chart
            (chartInstance)="saveInstance($event)"
            [viewMode]="viewMode"
        ></he-project-value-scoring-chart>
      </mat-card-content>
    </mat-card>
    <div id="download">
      <img #canvas>
      <a #downloadLink></a>
    </div>
  </div>
</div>
<div class="footer-comment">
  <span>Analogue scores are calculated as an average of total value scores per population and/or clinical trial.
  </span>
</div> -->

<div>
  <he-project-value-scoring-summary *heFeatureFlag="{ Name: 'value-summary-widget', Page: 'Value' }"></he-project-value-scoring-summary>
</div>

