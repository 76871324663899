import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByIndicationComponent } from './filter-by-indication.component';
import { FilterAutocompleteModule } from '../../../../uikit/filter-autocomplete';
import { FiltersSharedModule } from '../filters-shared.module';

@NgModule({
  declarations: [FilterByIndicationComponent],
  exports: [FilterByIndicationComponent],
  imports: [
    CommonModule,
    FilterAutocompleteModule,
    FiltersSharedModule,
  ],
})
export class FilterByIndicationModule {
}
