<ng-container *ngIf="filtersValueService.brandValues$ | async as brands">
  <ng-container *ngIf="brands.length > 1">
    <ng-container *ngIf="filtersValueService.filterValues$ | async | indicationMatrixFetch | async as msg">
      <ng-container *ngIf="updateIndicationsAllowedFilter(msg)">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<he-filter-autocomplete
  [descriptions]="descriptions"
  [isLinkAvailable]="isLinkAvailable"
  [selectedItems]="selectedItems"
  [loading]="loading"
  [isGroupView]="false"
  [hasSelectedItemText]="true"
  [filteredItems$]="filteredItems$"
  [hasClearIcon]="true"
  (callFilterFunction)="onQueryChange($event)"
  (selectedItemChanges)="selectedItemChanges($event)"
  (clearValue)="clearValueEmit($event)"
  [showListOnEmptyText]="true"
></he-filter-autocomplete>
