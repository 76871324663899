import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { UploadDataComponent } from './components/upload-data/upload-data.component';
import { UploadDataSidebarComponent } from './sub-components/upload-data-sidebar/upload-data-sidebar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UploadDataPageComponent } from './sub-components/upload-data-page/upload-data-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UploadDataDirective } from './directive/upload-data.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UploadWarningComponent } from './sub-components/upload-warning/upload-warning.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteDataPageComponent } from './sub-components/delete-data-page/delete-data-page.component';
import { MatTableModule } from '@angular/material/table';


@NgModule({
  declarations: [
    UploadDataComponent,
    UploadDataSidebarComponent,
    UploadDataPageComponent,
    UploadDataDirective,
    UploadWarningComponent,
    DeleteDataPageComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule
  ]
})
export class UploadDataModule { }
