import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FiltersValueService } from './filters-value.service';
import { FiltersObject } from './interfaces';
import { FilterNameEnum } from './enum';

@Component({
  selector: 'he-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent {

  @Input() hasCountrySaveButton = true;
  @Input() hasCountryResetButton = true;

  public filterNameEnum = FilterNameEnum;

  constructor(
    public filtersValueService: FiltersValueService) { }

  public filtersValueModified(filtersValue: FiltersObject): void {
    this.filtersValueService.filterValues$.next(filtersValue);
  }

  public hasFilter(filterName: FilterNameEnum, filtersValue: FiltersObject): boolean {
    return !!this.filtersValueService.findItemInFilters(filtersValue, filterName).filterValue.length;
  }
}
