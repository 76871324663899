import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DosageInfoComponent } from './dosage-info.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { GridCommonModule } from '../../grid-common';
import { InfoButtonModule } from '../../../uikit/info-widget/info-button';

@NgModule({
  declarations: [
    DosageInfoComponent
  ],
  exports: [
    DosageInfoComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    GridCommonModule,
    InfoButtonModule
  ]
})
export class DosageInfoModule {
}
