import { Pipe, PipeTransform } from '@angular/core';
import { PackDetailsProvider } from './pack-details.provider';
import { titleCaseFormatter, titleCaseFormatterText } from '../../common/string-utils';

@Pipe({
  name: 'packDetailsFormatting'
})
export class PackDetailsFormattingPipe implements PipeTransform {

  constructor(private packDetails: PackDetailsProvider) {
  }

  transform(payload: any, datastoreTabSelectedIndex: number | null | undefined): any {

    return payload?.map((pack: PackDetailInterface) => {
      pack.isDiscontinued = pack.isDiscontinued ? 'Discontinued' : 'Current';
      pack.isHospitalPack = pack.isHospitalPack ? 'Yes' : 'No';
      pack.orphanStatus=pack.orphanStatus ? 'Yes' :'No';
      pack.copaymentRatio=pack.copaymentRatio != null ? pack.copaymentRatio+"%" : pack.copaymentRatio;
      pack.brandInns = pack.brandInns?.join(', ')
      pack.exFactoryPrice = pack.exFactoryPrice?.toFixed(2)
      pack.hospitalPrice = pack.hospitalPrice?.toFixed(2)
      pack.ppExVat = pack.ppExVat?.toFixed(2)
      pack.ppIncVat = pack.ppIncVat?.toFixed(2)
      pack.ppp = pack.ppp?.toFixed(2)
      pack.reimbursementPrice = pack.reimbursementPrice?.toFixed(2)
      pack.reimbursementReferencePrice = pack.reimbursementReferencePrice?.toFixed(2)
      pack.decision=pack.decision !=undefined ? titleCaseFormatterText(pack.decision) : null
      pack.averageWeight = pack.averageWeight ?? ''
      pack.bodySurfaceArea = pack.bodySurfaceArea ?? ''
      return pack;
    });
  }

}
interface PackDetailInterface {
  bodySurfaceArea: any;
  hospitalPrice: any;
  ppExVat: any;
  ppIncVat: any;
  ppp: any;
  reimbursementPrice: any;
  reimbursementReferencePrice: any;
  exFactoryPrice: any;
  isDiscontinued: string;
  isHospitalPack: string;
  brandInns: any;
  orphanStatus: any;
  copaymentRatio:any;
  decision: any;
  averageWeight: any;
}
