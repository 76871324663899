<he-app-header></he-app-header>

<div class="refresh-project-data-card">
  <mat-card-header>
    <div class="heading">
      <span class="heading-title"> Project Price Update </span>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" *ngIf="showSection">
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef class="projectName">
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="nameRow">
          <a
            routerLink="/horizon/project-cot-refresh/{{ element.projectId }}"
            routerLinkActive="router-link-active"
          >
        {{ element.projectName }}
          </a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef class="projectName">
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="nameRow">
          {{ element.projectName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isPriceChanged">
        <mat-header-cell *matHeaderCellDef class="isPriceChanged">
          Price Update Available
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="isPriceChangedRow">
          {{ element.isPriceChanged ? "Yes" : "No" }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        [ngClass]="{ highlight: row?.highlight === true }"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 20]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
  </mat-card-content>
</div>

<div *ngIf="!showSection">
  <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
</div>
