import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AccessPermissions, MessageRef, Message } from './access-permissions';

export class CachedAccessPermissions {
  private cache: Observable<Message> | undefined;

  static create(service: AccessPermissions): CachedAccessPermissions {
    return new CachedAccessPermissions(service);
  }

  constructor(private service: AccessPermissions) {
  }

  run(event: MessageRef): Observable<Message> {
    this.cache = this.cache || this.service.run(event).pipe(
      shareReplay({bufferSize: 1, refCount: true})
    );
    return this.cache;
  }
}
