import { CotHistoryFetchPipe } from './../cot-history/cot-history-fetch.pipe';
import { ExportCsvObject } from './../../top-nav/export-csv.object.service';
import { CotCorridorFetchPipe } from './../cot-corridor/cot-corridor-fetch.pipe';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable, PipeTransform } from '@angular/core';
import { FiltersValueService } from '../filters-value.service';
import { FilterItemValueInterface } from '../interfaces';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
import { BrandsAvailabilityService } from '../brands-availability-service/brands-availability.service';

@Injectable({
  providedIn: 'root'
})
export class CotCacheService {
  cotStore: Map<string, any> = new Map<string, any>()
  initialized = false;
  brands: FilterItemValueInterface[] = []
  filtersValueService: FiltersValueService | undefined;
  destroying$: Subject<void> = new Subject<void>();

  selectedBrandChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  selectedBrand: FilterItemValueInterface | undefined
  getSelectedBrand = () => this.selectedBrand
  selectedBrand$ = this.selectedBrandChanged.pipe(map(() => this.getSelectedBrand()));

  exportCsvObject: ExportCsvObject = new ExportCsvObject()
  exportRowFormatter: (item: any) => any = (item) => item;
  exportCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  transformPipe: PipeTransform | undefined;

  constructor(public sharedService: SharedService,
    public brandsAvailabilityService: BrandsAvailabilityService) {
  }

  resetCotCache() {
    this.cotStore.clear();
    this.selectedBrand = undefined
    this.exportCompleted$.next(true)
  }

  initCotCache(filtersValueService: FiltersValueService, destroying$: Subject<void>, transformPipe: PipeTransform) {
    if (this.initialized)
      return

    this.filtersValueService = filtersValueService;
    this.destroying$ = destroying$;
    this.transformPipe = transformPipe;

    filtersValueService?.filterValues$.pipe(
      takeUntil(destroying$)
    ).subscribe(() => {
      if(this.initialized)
        this.resetCotCache()
      this.initialized = true
    }, undefined, () => {
      this.initialized = false;
      this.resetCotCache()
    })

    this.brandsAvailabilityService?.availableBrandValues$.pipe(
      takeUntil(destroying$)
    ).subscribe((brands) => {
      this.setBrands(brands || []);
    })
  }

  private setBrands(brands: FilterItemValueInterface[]) {
    this.brands = brands;
    if (this.filtersValueService && brands.length > this.filtersValueService.maxBrandsLimit) {
      this.selectedBrand = brands[0];
    }
    else {
      this.resetCotCache();
    }
  }

  public selectBrand(item: FilterItemValueInterface) {
    this.sharedService.cotcorridorItem$.next(false);
    this.selectedBrand = item
    this.selectedBrandChanged.next(!this.selectedBrandChanged.value)
  }

  public selectBrandHistory(item: FilterItemValueInterface) {
    this.sharedService.cotcorridorHistoryItem$.next(false);
    this.selectedBrand = item
    this.selectedBrandChanged.next(!this.selectedBrandChanged.value)
  }

  public addCotCache(brandName: string, response: any) {
    this.cotStore.set(brandName, response)
  }

  public getCotCache(brandName: string) {
    return this.cotStore.get(brandName)
  }

  public exportCsv() {
    const brands = [...this.cotStore.keys()]
    const missingBrands = this.brands.map((val) => val.name).filter((val) => brands.indexOf(val) === -1)


  }
}

@Injectable({
  providedIn: 'root'
})
export class CotCorridorCacheService extends CotCacheService {
  constructor(public shared:SharedService,
    public brandsAvailabilityService: BrandsAvailabilityService) {
    super(shared, brandsAvailabilityService);
  }

  public exportCsv() {
    if(!this.initialized)
      return

    const brands = [...this.cotStore.keys()]
    const missingBrands = this.brands.map((val) => val.name).filter((val) => brands.indexOf(val) === -1)
    if(missingBrands.length === 0)
      return this.exportCacheToCsv()

    this.exportCompleted$.next(false);

    (this.transformPipe as CotCorridorFetchPipe).transform(this.filtersValueService?.filterValues$.value, false, false, missingBrands).pipe(
      takeUntil(this.destroying$),  finalize(() => this.exportCompleted$.next(true))
    ).subscribe((result) => {
      if(result && result.type !== 'pending')
      {
        this.exportCacheToCsv();
      }
    });
  }

  private exportCacheToCsv() {
    var cachedBrandResponses = this.brands.map((val) => this.cotStore.get(val.name))

    var dataset: any[] = cachedBrandResponses
      .filter((msg) => msg && msg.type === 'done')
      .map((msg) => msg.payload.map(this.exportRowFormatter))
      .reduce((accumulator, value) => accumulator.concat(value), [])

    this.exportCsvObject.registerDataset(dataset);
    this.exportCsvObject.exportToCsv();
  }
}

@Injectable({
  providedIn: 'root'
})
export class CotHistoryCacheService extends CotCacheService {
  constructor(public shared:SharedService,
    public brandsAvailabilityService: BrandsAvailabilityService) {
    super(shared, brandsAvailabilityService);
  }


  public exportCsv() {
    if(!this.initialized)
      return

    const brands = [...this.cotStore.keys()]
    const missingBrands = this.brands.map((val) => val.name).filter((val) => brands.indexOf(val) === -1)
    if(missingBrands.length === 0)
      return this.exportCacheToCsv()

    this.exportCompleted$.next(false);

    (this.transformPipe as CotHistoryFetchPipe).transform(this.filtersValueService?.filterValues$.value, false, false, missingBrands).pipe(
      takeUntil(this.destroying$), finalize(() => this.exportCompleted$.next(true))
    ).subscribe((result) => {
      if(result && result.type !== 'pending')
      {
        this.exportCacheToCsv();
      }
    });
  }

  private exportCacheToCsv() {
    var cachedBrandResponses = this.brands.map((val) => this.cotStore.get(val.name))

    var dataset: any[] = cachedBrandResponses
      .filter((msg) => msg && msg.type === 'done')
      .map((msg) => msg.dataset.map(this.exportRowFormatter))

    var rows: any[] = []
    dataset.forEach((datasetRows: any[]) => {
      datasetRows.forEach((rowGroup: any[]) => {
        rowGroup.forEach(row => {
          rows.push(row)
        });
      });
    })

    this.exportCsvObject.registerDataset(rows);
    this.exportCsvObject.exportToCsv();
  }
}
