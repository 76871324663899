import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ValueFramework } from '../models/ValueFramework';

@Injectable({
  providedIn: 'root',
})
export class ValueFrameworksService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  constructor(private http: HttpClient) {}

  getValueFrameworks(): Promise<ValueFramework[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ValueFramework[]>(
          this.projectAPIUri + '/ValueFramework/ValueFrameworks'
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }
  getValueScenarios(): Promise<[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<[]>(
          this.projectAPIUri + '/ValueFramework/ValueScenarios'
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

}
