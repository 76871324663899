import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'he-project-summary-editor',
  templateUrl: './project-summary-editor.component.html',
  styleUrls: ['./project-summary-editor.component.scss']
})
export class ProjectSummaryEditorComponent {
  @Input() form!: FormGroup;

  getFormControl(formControlName: string): FormControl {
    return this.form.get(formControlName) as FormControl;
  }
}
