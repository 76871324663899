export enum ValueScoringKeyEnum{
    'Not Considered',
    'Limited Importance',
    'Low Importance',
    'Medium Importance',
    'High Importance',
    'Key driver'
}

export enum DomainEnum{
    'Burden',
    'Product characteristics',
    'Trial design',
    'Clinical benefit',
    'Other',
}