import { UserPreference } from '../../core/user-preferences/interfaces';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class UserPreferencesApi {

  constructor(private httpClient: HttpClient) {
  }

  transfer(preferenceTypes: string[]): Observable<UserPreference> {
    const query = preferenceTypes.map(s => 'preferenceTypes=' + s).join('&');

    return this.httpClient.get<UserPreference>(`/userconfig/GetUserPreference?${query}`);
  }
}
