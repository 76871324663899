import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[heDenyFor]'
})
export class DenyDirective {
  @Input() heDenyFor = '';

  constructor(public templateRef: TemplateRef<any>) {
  }
}
