<ng-container *ngIf="sharedService.datastoreOnLoadCompleted$ | async">
  <ng-container *ngIf="filtersValueService.filterValues$  | async | FetchAvailability | async as msg">
    <ng-container [ngSwitch]="msg.type">
      <ng-container>
        <div class="align-brand-icon" *ngIf="brandPreferenceFeatureFlag">
          <ng-container *ngIf="isResetButtonVisible">
            <button mat-icon-button class="he-icon-button he-button-m-size" title="Clear selection"
              (click)="resetFilterClicked()">
              <he-icon [id]="'delete-2'"></he-icon>
            </button>
          </ng-container>
          <he-filter-preset class="preset-buttons he-icon-button he-button-m-size"  [presetKeyName]="FilterBrandPresetTypes.Brand"
            [saveConfirmationText]="saveBrandText" [savePresetTooltip]="TooltipText.MeasuresPresetSaveTooltip"
            [deletePresetTooltip]="TooltipText.MeasuresPresetDeleteTooltip"
              (changefilterValues)="changefilterValues($event)" (removeSelectedBrands)="removeSelectedBrandChips()"
              (selectedItemChanges)="selectedItemChanges($event)">
          </he-filter-preset>
        </div>
      </ng-container>  
      <ng-container *ngSwitchCase="'pending'">
        <he-filter-autocomplete-with-chips [descriptions]="descriptions" [selectedItems]="selectedItems"
          [loading]="loading" [isGroupView]="true" [filteredGroupItems$]="filteredGroupItems$"
          [class.scrollable]="maxBrandsExceeded" (callFilterFunction)="onQueryChange($event)"
          (selectedItemChanges)="selectedItemChanges($event)"
          (deletedItemChanges)="deletedItemChanges($event)"></he-filter-autocomplete-with-chips>
      </ng-container>
      <ng-container *ngSwitchCase="'fail'">
        <he-filter-autocomplete-with-chips [descriptions]="descriptions" [selectedItems]="selectedItems"
          [loading]="loading" [isGroupView]="true" [filteredGroupItems$]="filteredGroupItems$"
          [class.scrollable]="maxBrandsExceeded" (callFilterFunction)="onQueryChange($event)"
          (selectedItemChanges)="selectedItemChanges($event)"
          (deletedItemChanges)="deletedItemChanges($event)"></he-filter-autocomplete-with-chips>
      </ng-container>
      <ng-container *ngSwitchCase="'done'">
        <he-filter-autocomplete-with-chips [descriptions]="descriptions"
          [selectedItems]="selectedItems | brandsAvailabilityFilter: (msg.payload)" [loading]="loading"
          [isGroupView]="true" [filteredGroupItems$]="filteredGroupItems$" [class.scrollable]="maxBrandsExceeded"
          (callFilterFunction)="onQueryChange($event)" (selectedItemChanges)="selectedItemChanges($event)"
          (deletedItemChanges)="deletedItemChanges($event)"
          [isBrandChipEnable]="isBrandChipEnable"></he-filter-autocomplete-with-chips>
      </ng-container>
    </ng-container>
    <p *ngIf="maxBrandsExceeded" class="underFilterMessage">
      <span *ngIf="maxBrandsExceeded">Some visualisations on the platform will be impacted when more than 15 brands are
        in
        the filter. </span>
    </p>
  </ng-container>
</ng-container>
<he-brand-filter-reset-dialog *ngIf="pendingConfirmationToClear" (reject)="onRejectBrandDelete()"
  (confirm)="onConfirmDelete()"></he-brand-filter-reset-dialog>