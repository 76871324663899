import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeatureGuard } from './horizon-project/guard/feature.guard';
import { HorizonProjectDetailsComponent } from './horizon-project/horizon-project-details/horizon-project-details.component';
import { ListHorizonProjectComponent } from './horizon-project/list-horizon-project/list-horizon-project.component';
import { HorizonComponent } from './horizon.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AddScenarioComponent } from './modules/project/components/add-scenario/add-scenario.component';
import { AnalogueSelectionComponent } from './modules/project/components/analogue-selection/analogue-selection.component';
import { CreateNewProjectComponent } from './modules/project/components/create-new-project/create-new-project.component';
import { DeleteProjectComponent } from './modules/project/components/delete-project/delete-project.component';
import { UploadDataComponent } from './modules/upload-data/components/upload-data/upload-data.component';
import { RefreshProjectDataComponent } from './modules/project/components/refresh-project-data/refresh-project-data.component';
import { ProjectCotRefreshDataComponent } from './modules/project/sub-components/project-cot-refresh-data/project-cot-refresh-data.component';
import { PriceAssumptionComponent } from './modules/project/components/price-assumption/price-assumption.component';
import { ComparatorSelectionMainComponent } from './modules/project/components/comparator-selection-main/comparator-selection-main.component';

const horizonRoutes: Routes = [
  {
    path: '',
    component: ListHorizonProjectComponent,
    canActivate: [FeatureGuard],
    data: { feature: { name: 'horizon', page: 'common' } },
    children: [
      {
        path: '',
        redirectTo: 'list-projects',
        pathMatch: 'full',
      },
      {
        path: 'list-projects',
        component: DashboardComponent,
        data: { project: 'Project 1' },
      },
      {
        path: 'project-details/:id',
        component: HorizonProjectDetailsComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'create-new-project',
    component: CreateNewProjectComponent,
  },
  {
    path: 'comparator-selection',
    component: ComparatorSelectionMainComponent,
  },
  {
    path: 'analogue-selection',
    component: AnalogueSelectionComponent,
  },
  {
    path: 'price-assumption',
    component: PriceAssumptionComponent,
  },
  {
    path: 'add-scenario',
    component: AddScenarioComponent,
  },
  {
    path: 'edit-project/:id',
    component: CreateNewProjectComponent,
  },
  {
    path: 'edit-scenario',
    component: AddScenarioComponent,
  },
  {
    path: 'delete-project',
    component: DeleteProjectComponent,
  },
  {
    path: 'upload-data',
    component: UploadDataComponent,
  },{
    path: 'refresh-project-data',
    component: RefreshProjectDataComponent,
  },
  {
    path: 'project-cot-refresh/:projectId',
    component: ProjectCotRefreshDataComponent,
    
  },
];

@NgModule({
  imports: [RouterModule.forChild(horizonRoutes)],
  exports: [RouterModule],
})
export class HorizonRoutingModule {}
