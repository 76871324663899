<div [formGroup]="form" *ngIf="form">
  <div class="mb-1">Project name<span class="required-marker">*</span></div>
  <mat-form-field appearance="outline" placeholder="" class="he-form-field">
    <label>
      <input type="text"
             placeholder="Enter project name"
             matInput
             [formControl]="getFormControl('name')">
    </label>
  </mat-form-field>
  <mat-error class="he-error mb-2" *ngIf="getFormControl('name').invalid && getFormControl('name').touched">
    <he-icon [id]="'suggested-triangle-exclamation-mark'"></he-icon>
    <span>Invalid project name</span></mat-error>
  <div class="mt-1 mb-1">Project description</div>
  <mat-form-field appearance="outline" placeholder="" class="he-form-field">
    <label>
      <textarea
        placeholder="Enter project description"
        matInput
        [formControl]="getFormControl('description')"></textarea>
    </label>
  </mat-form-field>
  <mat-error class="he-error mb-2" *ngIf="getFormControl('description').invalid && getFormControl('description').touched">
    <he-icon [id]="'suggested-triangle-exclamation-mark'"></he-icon>
    <span>Invalid project description</span></mat-error>
</div>
