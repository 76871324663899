import { HtaCacheService } from './hta-cache/hta-cache.service';
import { CotCacheService } from './cot-cache/cot-cache.service';
import { SharedService } from './../../uikit/service/shared.service';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, combineLatest, timer } from 'rxjs';
import { takeUntil, finalize, takeWhile } from 'rxjs/operators';
import { CotCorridorCacheService, CotHistoryCacheService } from './cot-cache';
import { DialogText } from '../common';

@Injectable({
  providedIn: 'root'
})
export class CacheExportService {
  private destroying$: Subject<void> = new Subject<void>();
  exportCount = 0
  maxExportCount = 0

constructor(
  private cotCorridorCacheService: CotCorridorCacheService,
  private cotHistoryCacheService: CotHistoryCacheService,
  private htaCacheService: HtaCacheService,
  private sharedService: SharedService) { }

  initService(destroying$: Subject<void>) {
    this.destroying$ = destroying$;
  }

  exportToCsv(services: (CotCacheService | HtaCacheService)[] | null = null) {

    if(services === null) {
      services = [this.cotCorridorCacheService, this.cotHistoryCacheService, this.htaCacheService]
    }

    this.sharedService.setShowLoadingScreen(true, DialogText.DataExportProgressMessage)

    timer(50).pipe(
      takeUntil(this.destroying$)
    ).subscribe(() => {
      let loadingObservables: BehaviorSubject<boolean>[] = []
      services?.forEach(service => {
        if(service.initialized)
        {
          service.exportCsv();
          loadingObservables.push(service.exportCompleted$)
        }
      });

      this.maxExportCount = loadingObservables.length;
      this.exportCount = 0;

      if(loadingObservables.length > 0) {
        combineLatest(loadingObservables).pipe(
          takeUntil(this.destroying$),
          takeWhile((result: any) => !result.every((value: any) => value === true)),
          finalize(() => this.sharedService.setShowLoadingScreen(false))
        ).subscribe()
      }
      else this.sharedService.setShowLoadingScreen(false)
    });
  }
}
