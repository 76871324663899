import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByCountryComponent } from './filter-by-country.component';
import { IconModule } from '../../helios-icons';
import { FilterAutocompleteWithChipsModule } from '../../filter-autocomplete-with-chips';
import { MatIconModule } from '@angular/material/icon';
import { CountryFilterResetDialogModule } from '../../country-filter-reset-dialog/country-filter-reset-dialog.module';

@NgModule({
  declarations: [FilterByCountryComponent],
  exports: [FilterByCountryComponent],
  imports: [
    CommonModule,
    FilterAutocompleteWithChipsModule,
    IconModule,
    MatIconModule,
    CountryFilterResetDialogModule
  ],
})
export class FilterByCountryModule {
}
