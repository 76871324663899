import { Component, ViewChild } from '@angular/core';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../../../shared/services/header.service';
import { UploadDataService } from '../../services/upload-data.service';
import {
  FormControl,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { AnalogueDeleteDataService } from '../../services/analogue-delete-data.service';
import { ProjectService } from '../../../project/services/project.service';
@Component({
  selector: 'he-delete-data-page',
  templateUrl: './delete-data-page.component.html',
  styleUrls: ['./delete-data-page.component.scss'],
})
export class DeleteDataPageComponent {
  @ViewChild('fileDropRef') public fileDropRef: any;

  uploadMenu = [
    { viewValue: 'COT Data', value: 'cotData' },
    { viewValue: 'Tagging Data', value: 'taggingData' },
    { viewValue: 'Analogue Info Data', value: 'analogueInfoData' },
    { viewValue: 'Value Score Data', value: 'valueScoreData' },
    { viewValue: 'Reference Data', value: 'referenceData' }
  ]
  files: any[] = [];
  fileListData: any = [];
  fileName: string[] = ['xlxs', 'csv'];
  NewFileList: any = [];

  displayErrors: boolean = false;
  uploadingStatus: boolean = false;
  uploadingSuccess: boolean = false;
  currentUploadingIndex: number = 0;
  uploadedFiles: any = [];
  failedFiles: any = [];

  currentTab: string = '';
  currentTabName: any = '';

  countries: any = [];

  displayedColumns: string[] = ['UniqueID'];
  dataSource: any[] = [];

  uploadForm = new FormGroup({
    country: new FormControl('', [Validators.required]),
    framework:new FormControl('',[Validators.required])
  });
  public ValueFrameworks: DeleteDataPageComponent[] = [];
  public ValueFrameworksView: DeleteDataPageComponent[] = [];
  valueFrameworkName: string='';

  constructor(
    private toastNotificationService: ToastNotificationService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private uploadDataService: UploadDataService,
    private analogueDeleteDataService: AnalogueDeleteDataService,
    private projectService:ProjectService
  ) {
  }

  ngOnInit() {
    this.getCurrentTab();
    this.getCountriesData();
    this.getValueFrameworks();
  }


  displayCurrencyFn(data: string): string {
    return data;
  }

  get country(): AbstractControl<any, any> | null {
    return this.uploadForm.get('country');
  }

  getCurrentTab() {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.currentTab = res.data
      this.currentTabName = this.getCurrentTabName(this.currentTab)
      this.initializeVariable()
    })
  }

  initializeVariable() {
    this.files = [];
    this.fileListData = [];
    this.NewFileList = []
    this.displayErrors = false;
    this.uploadingStatus = false;
    this.uploadingSuccess = false;
    this.currentUploadingIndex = 0;
    this.uploadedFiles = [];
    this.failedFiles = []
    this.uploadForm.reset()
    this.uploadDataService.fileListDataLength.next(this.fileListData.length)
  }

  getCurrentTabName(currentTab: any) {
    return this.uploadMenu.find((item) => item.value === currentTab)?.viewValue;
  }

  private async getCountriesData() {
    await this.uploadDataService
      .getCountriesData()
      .then((data: any) => {
        this.countries = data;
      })
      .catch((err) => {
        console.log(err);
        this.toastNotificationService.errorMessage(
          'Unable to fetch Countries Data',
          'info',
          10000
        );
      });
  }


  toggleErrors() {
    this.displayErrors = !this.displayErrors;
  }

  getAccordionStyle() {
    return this.displayErrors ? 'error-visible' : 'error-hidden';
  }

  getUploadingStyle(index: any) {
    if (this.uploadedFiles.includes(index)) {
      return 'uploaded-file';
    } else if (this.checkFailedStatus(index)) {
      return 'failed-file';
    }
    return 'single-file';
  }

  getButtonText(index: any) {
    if (this.currentUploadingIndex === index && this.uploadingStatus) {
      return 'Deleting'
    }
    else if (this.currentUploadingIndex !== index && this.uploadingStatus) {
      return 'Pending Delete'
    }
    return 'Delete'
  }

  checkFailedStatus(index: any) {
    const file = this.failedFiles.find((item: any) => item.id === index)
    if (file) {
      return true
    }
    return false
  }

  getErrorsDesc(index: any) {
    const desc = this.failedFiles.find((item: any) => item.id === index).error;
    return typeof (desc) === 'string' ? desc : desc.message;
  }

  getNotFound(index: any) {
    this.dataSource = [];
    const desc = this.failedFiles.find((item: any) => item.id === index).error?.notFound;
    if (Array.isArray(desc)) {
      desc.forEach(ele => this.dataSource.push({ UniqueID: ele }));
    }
    return desc ? desc : [];
  }

  onFileDropped($event: any) {
    if ($event.length > 1) {
      this.toastNotificationService.errorMessage("Please select one file at a time")
      return
    }
    this.prepareFilesList($event);
  }

  fileBrowseHandler($event?: any) {
    this.prepareFilesList($event.target.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileListData.splice(index, 1);
    this.uploadDataService.fileListDataLength.next(this.fileListData.length)
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   * @param Bytes (Bytes in KB, MB, GB)
   * @param decimals (Decimals point in size)
   */

  prepareFilesList(files: Array<any>) {
    this.fileListData = []
    this.failedFiles = []
    this.uploadedFiles = []
    this.uploadForm.reset()
    const maxFIleSize = 10000000;
    this.uploadDataService.fileDataStatus.next(false);
    for (const item of files) {
      if (this.fileName.includes(item.name.split('.').pop())) {
        if (item.size <= maxFIleSize) {
          this.files.push(item);
          this.fileListData.push(item);
          this.uploadDataService.fileListDataLength.next(this.fileListData.length)
        } else {
          this.toastNotificationService.errorMessage(
            'File size needs to be less than 10MB'
          );
        }
      } else {
        this.toastNotificationService.errorMessage('File type not supported');
      }
    }
    this.fileDropRef.nativeElement.value = '';
  }



  formatBytes(bytes?: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadData(index: any): void {
    switch (this.currentTab) {
      case 'cotData':
        this.deleteCOTData(index)
        break
      case 'taggingData':
        this.deleteTaggingData(index)
        break
      case 'analogueInfoData':
        this.deleteAnalogueInfoData(index)
        break
      case 'valueScoreData':
        this.deleteValueScoreData(index)
        break
      case 'referenceData':
        this.deleteAnalogueHTAInfoData(index)
        break
      default:
        this.toastNotificationService.errorMessage('No Data Type Found')
    }
  }

  deleteCOTData(index: any) {
    if (this.uploadForm.controls.country.invalid) {
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else {
      this.currentUploadingIndex = index
      this.uploadingStatus = true
      if (this.uploadForm.value.country && this.uploadForm.value.framework)
        this.analogueDeleteDataService.DeleteCotData(this.fileListData[0], this.uploadForm.value.country, this.uploadForm.value.framework).then((res: any) => {
          if (res.status === 1) {
            this.toastNotificationService.successMessage('COT Data Deleted Successfully')
            this.afterSuccess(index);
          }
          else {
            const errmsg=res.data.message?res.data.message:res.data;
            this.toastNotificationService.errorMessage('COT Data Deletion Failed: ' +errmsg)
            this.failedFiles.push({
              id: index, error: res.data
            });
            this.afterError();
          }
        }).catch((err) => {
          console.log(err)
          this.toastNotificationService.errorMessage('COT Data Deletion Failed: ' + err.error.data)
          this.failedFiles.push({
            id: index, error: err.error.data
          });
          this.afterError();
        })
    }
  }

  deleteTaggingData(index: any) {
    if (this.uploadForm.controls.country.invalid) {
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else {
      this.currentUploadingIndex = index;
      this.uploadingStatus = true;
      if (this.uploadForm.value.country && this.uploadForm.value.framework)
        this.analogueDeleteDataService.DeleteAnalogueTaggingData(this.fileListData[0], this.uploadForm.value.country, this.uploadForm.value.framework).then((res: any) => {
          if (res.status === 1) {
            this.toastNotificationService.successMessage('Tagging Data Deleted Successfully')
            this.afterSuccess(index);
          }
          else {
            const errmsg=res.data.message?res.data.message:res.data;
            this.toastNotificationService.errorMessage('Tagging Data Deletion Failed: ' + errmsg);
            this.failedFiles.push({
              id: index, error: res.data
            });
            this.afterError();
          }
        }).catch((err) => {
          console.log(err)
          this.toastNotificationService.errorMessage('Tagging Data Deletion Failed: ' + err.error.data);
          this.failedFiles.push({
            id: index, error: err.error.data
          });
          this.afterError();
        })
    }
  }

  deleteAnalogueInfoData(index: any) {

    if (this.uploadForm.controls.country.invalid) {
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else {
      this.currentUploadingIndex = index
      this.uploadingStatus = true
      if (this.uploadForm.value.country && this.uploadForm.value.framework)
        this.analogueDeleteDataService.DeleteAnalogueInfo(this.fileListData[0], this.uploadForm.value.country,this.uploadForm.value.framework).then((res: any) => {
          if (res.status === 1) {
            this.toastNotificationService.successMessage('Analogue Info Data Deleted Successfully')
            this.afterSuccess(index);
          }
          else {
            const errmsg=res.data.message?res.data.message:res.data;
            this.toastNotificationService.errorMessage('Analogue Info Data Deletion Failed: ' + errmsg);
            this.failedFiles.push({
              id: index, error: res.data
            });
            this.afterError();
          }
        }).catch((err) => {
          console.log(err)
          this.toastNotificationService.errorMessage('Analogue Info Data Deletion Failed: ' + err.error.data);
          this.failedFiles.push({
            id: index, error: err.error.data
          });
          this.afterError();
        })
    }
  }


  deleteValueScoreData(index: any) {
    if (this.uploadForm.controls.country.invalid) {
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else {
      this.currentUploadingIndex = index;
      this.uploadingStatus = true;
      if (this.uploadForm.value.country && this.uploadForm.value.framework)
        this.analogueDeleteDataService.DeleteAnalogueValueScore(this.fileListData[0], this.uploadForm.value.country,this.uploadForm.value.framework).then((res: any) => {
          if (res.status === 1) {
            this.toastNotificationService.successMessage('Value Score Data Deleted Successfully')
            this.afterSuccess(index);
          }
          else {
            const errmsg=res.data.message?res.data.message:res.data;
            this.toastNotificationService.errorMessage('Value Score Data Deletion Failed: ' + errmsg);
            this.failedFiles.push({
              id: index, error: res.data
            });
            this.afterError();
          }
        }).catch((err) => {
          console.log(err)
          this.toastNotificationService.errorMessage('Value Score Data Deletion Failed: ' + err.error.data);
          this.failedFiles.push({
            id: index, error: err.error.data
          });
          this.afterError();
        })
    }
  }

  deleteAnalogueHTAInfoData(index: any) {
    if (this.uploadForm.controls.country.invalid) {
      this.toastNotificationService.errorMessage("Please fill all the required fields")
      this.uploadForm.markAllAsTouched()
      return
    }
    else {
      this.currentUploadingIndex = index;
      this.uploadingStatus = true;
      if (this.uploadForm.value.country && this.uploadForm.value.framework)
        this.analogueDeleteDataService.DeleteAnalogueHtaRefrence(this.fileListData[0], this.uploadForm.value.country,this.uploadForm.value.framework).then((res: any) => {
          if (res.status === 1) {
            this.toastNotificationService.successMessage('Reference Data Deleted Successfully');
            this.afterSuccess(index);
          }
          else {
            const errmsg=res.data.message?res.data.message:res.data;
            this.toastNotificationService.errorMessage('Reference Data Deletion Failed: ' + errmsg);
            this.failedFiles.push({
              id: index, error: res.data
            });
            this.afterError();
          }
        }).catch((err) => {
          console.log(err)
          this.toastNotificationService.errorMessage('Reference Data Deletion Failed: ' + err.error.data);
          this.failedFiles.push({
            id: index, error: err.error.data
          });
          this.afterError();
        })
    }
  }

  afterSuccess(index:any) {
    this.uploadingStatus = false;
    this.currentUploadingIndex = 0;
    this.uploadedFiles.push(index);
    this.uploadDataService.fileDataStatus.next(true);
  }

  afterError() {
    this.currentUploadingIndex = 0;
    this.uploadingStatus = false;
    this.getNotFound(1);
    this.displayErrors = true;
    this.uploadDataService.fileDataStatus.next(false);
  }

  private async getValueFrameworks() {
    await this.projectService
      .getValueFrameworks()
      .then((data: any[]) => {
        this.ValueFrameworks = data.filter((option)=> option.isDeleted === false).sort((a,b)=> new Date(b.createdOn).getTime() -  new Date(a.createdOn).getTime());
        this.ValueFrameworks.forEach(res=>{
          if(!this.ValueFrameworksView.some((x)=> x.valueFrameworkName === res.valueFrameworkName)){
            this.ValueFrameworksView.push(res);
          }
        })
      })
      .catch((err) => {
        console.log(err);
        this.toastNotificationService.errorMessage(
          'Unable to fetch Value Frameworks Data',
          'info',
          10000
        );
      });
  }
}
