import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';

import { MenuModule } from '../profile/menu';
import { UniversalSearchModule } from '../universal-search';
import { IconModule } from '../../uikit/helios-icons';

import { LogoModule } from './logo';
import { ShellComponent } from './shell.component';

import { AccessBarrierModule } from '../access-barrier';
import { AccessPermissions } from '../../core/access-permissions';
import { AccessPermissionsApi } from '../../helios-api/access-permissions';
import { HelpMenuModule } from '../help-menu/help-menu.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ShellComponent],
  exports: [ShellComponent],
  imports: [
    CommonModule,
    IconModule,
    LogoModule,
    MatButtonModule,
    MatTabsModule,
    MenuModule,
    RouterModule,
    UniversalSearchModule,
    AccessBarrierModule,
    HelpMenuModule,
    MatTooltipModule
  ],
  providers: [
    AccessPermissionsApi,
    {provide: AccessPermissions, useFactory: AccessPermissions.create, deps: [AccessPermissionsApi]}
  ]
})
export class ShellModule {
}
