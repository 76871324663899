import { FilterItemValueInterface } from '../../app/datastore/interfaces';
import { currenciesName } from './currencies-name';
import { getCurrencySymbol } from '@angular/common';

export function currencyNameFormatter(items: FilterItemValueInterface[]): FilterItemValueInterface[] {
  return items.map((item) => {
    return {
      name: `${currenciesName[item.name] ? currenciesName[item.name] : item.name} (${getCurrencySymbol(item.name, 'narrow')})`,
      type: item.name
    };
  });
}
