export enum testeNameEnum {
    oneOffTherapy='One off therapy',
    population='Paediatric/adult population',
    eligiblePatientNumber='Patient number',
    routeOfAdministration='Route Of Administration',
    mechanismOfAction='Mechanism of action',
    therapyAreas='Therapy Areas',
    lineoftherapy='Line of therapy',
    indications='Indications',
    formulation='Formulation',
    biomarker='Biomarker',
}
export enum CriteriaApiNameEnum {
    oneOffTherapy=0,
    population,
    eligiblePatientNumber,
    routeOfAdministration,
    mechanismOfAction,
    therapyAreas,
    lineoftherapy,
    indications,
    formulation,
    biomarker,
  }
  export enum CriteriaNameEnum {
    'One off therapy'=0,
    'Paediatric/adult population',
    'Patient number',
    'Route Of Administration',
    'Mechanism of action',
    'Therapy Areas',
    'Line of therapy',
    'Indications',
    'Formulation',
    'Biomarker',
  }

  export enum ValueFrameworkNameConstant{
    orphan = 'orphan',
    oncology = 'oncology',
  }

  export enum OncologyCriteriaConstant{
    orphan = 'orphan',
    oneOffTherapy = 'oneOffTherapy',
    population = 'population',
    eligiblePatientNumber = 'eligiblePatientNumber',
    eligiblePatientNumberUSA = 'eligiblePatientNumberUSA',
    routeOfAdministration = 'routeOfAdministration',
    moaCategory = 'moaCategory',
    therapyAreas = 'therapyAreas',
    lineoftherapy = 'lineoftherapy',
    indications = 'indications',
    formulation = 'formulation',
    biomarker = 'biomarker',
    durationoftreatment = 'durationoftreatment',
    tli = 'tli',
    therapyregimen = 'therapyregimen',
    typeofcombination = 'typeofcombination',
    biomarker_genetyping = 'biomarker_genetyping',
    priorreimbursedindication = 'priorreimbursedindication',
  }
  