import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CreateProject, UpdateProject } from '../../../core/projects';
import { CreateProjectApi, UpdateProjectApi } from '../../../helios-api/projects';
import { IconModule } from '../../../uikit/helios-icons';
import { LoadingSpinnerModule } from '../../../uikit/loading-spinner';
import { ProjectSummaryEditorModule } from '../../../uikit/project-summary-editor';
import { RedirectByUrlModule } from '../../../uikit/redirect';

import { NewProjectComponent } from './new-project.component';
import { CreateProjectPipe } from './create.pipe';
import { CreateProjectService, UpdateProjectService } from './create.service';

@NgModule({
  declarations: [NewProjectComponent, CreateProjectPipe],
  exports: [NewProjectComponent],
  imports: [
    CommonModule,
    ProjectSummaryEditorModule,
    ReactiveFormsModule,
    MatButtonModule,
    IconModule,
    LoadingSpinnerModule,
    RedirectByUrlModule
  ],
  providers: [
    CreateProjectApi,
    {provide: CreateProject, useFactory: CreateProject.create, deps: [CreateProjectApi]},
    {provide: CreateProjectService, useExisting: CreateProject},
        UpdateProjectApi,
    {provide: UpdateProject,useFactory: UpdateProject.create, deps: [UpdateProjectApi]},
    {provide: UpdateProjectService, useExisting: UpdateProject},
  ]
})
export class NewProjectModule {
}
