import { Message } from './../message';
import { Observable, asyncScheduler, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';

import { Assert } from '../assert';

import { Gateway } from '../gateway';
import { UserPreference, UserPreferenceInput } from './interfaces';

export class UserPreferences {
  static create(gateway: Gateway): UserPreferences {
    return new UserPreferences(gateway);
  }

  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${ gateway } has no "transfer" method`);
  }

  run(req: UserPreferenceInput): Observable<Message> {
    return of(req).pipe(
      mergeMap(() => {
        return this.gateway.transfer(req.payload).pipe(
          map((response: unknown) => {
            return {
              type: 'done',
              payload: (response as UserPreference[]).map(x => {
                let colInfo = x.name.split('|')
                return {
                  userPreference: {
                    type: x.type,
                    preference: colInfo[0]
                  },
                  idx: Number(colInfo[1])
                }
              }).sort((n1, n2) => n1.idx - n2.idx)
            };
          }),
          catchError((err: any) => {
            return of({
              type: 'fail',
              error: true,
              payload: err
            });
          }),
          startWith({
            type: 'pending',
            payload: {}
          }),
          subscribeOn(asyncScheduler)
        );
      })
    );
  }
}
