import { Injectable } from '@angular/core';


interface ChartApi {
  apiItem: any;
  type: string;
}
@Injectable({
  providedIn: 'root'
})
export class ExportImageService {

  chartApis: ChartApi[] = [];

  registerApi(apiItem: any, itemType: string): void {
   const index = this.chartApis.findIndex((el) => el.type === itemType);
   if (index > -1){
      this.chartApis[index] = {
        apiItem,
        type: itemType,
      };
    }
    else{
      this.chartApis.push({
        apiItem,
        type: itemType,
      });
    }
  }

  saveImage(chartName: any, chartType: any): void {
    this.chartApis.forEach((el) => {
      if (el.type === chartType){
      const base64 = el.apiItem.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff'
      });
      const a = document.createElement('a');
      a.href = base64;
      a.download = chartName;
      a.click();
    }});
  }
}
