import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContextComponent as DatastoreContextComponent, ContextModule } from './context';
import { ShellComponent } from '../shell';
import { DatastoreComponent } from './datastore.component';
import { AuthGuardService } from '../auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        component: DatastoreContextComponent,
        children: [
          {
            path: ':section',
            component: DatastoreComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'general'
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    ContextModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class DatastoreRoutingModule {
}
