import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BrandsAvailiabilityApi {

  constructor(private httpClient: HttpClient) {
  }

  call(req: unknown): Observable<any> {
    return this.httpClient.post<any>('/brand/availability', req);
  }
}
