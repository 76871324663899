<ng-template #loading>
  <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
</ng-template>
<ng-container *ngIf="preferencesRetrieved; else loading">
  <he-data-grid heGridFreezeHeader class="customGrid" (gridReady)="saveGridApi($event)" [pagination]="true"
    [paginationPageSize]="pageSize" [frameworkComponents]="frameworkComponents" [columnDefs]="columnDefs" [sizemode]="sizemode" [rowData]="rowData"
    [gridOptions]="gridOptions" (columnMoved)="onColumnMoved($event)" (dragStopped)="onDragStopped($event)" 
    (gridReadyEvent)="onGridReadyEvent($event)"  (paginatorPageEvent)="onPaginatorPageEvent($event)" (sortChanged)="onSortChanged($event)"   
    ></he-data-grid>
</ng-container>
