import { Observable, asyncScheduler, identity as nodelay, of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';

import { Assert } from '../assert';

import { Gateway } from './gateway';

export interface MessageRef {
  payload: {
    query: string
  };
}

export interface Message {
  type: string;
  payload: unknown;
  error?: boolean;
}

export class UniversalSearch {
  public delay = 512; // milliseconds

  static create(gateway: Gateway): UniversalSearch {
    return new UniversalSearch(gateway);
  }

  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${ gateway } has no "transfer" method`);
  }

  run(event: MessageRef): Observable<Message> {
    const period = this.delay > 0 ? delay(this.delay) : nodelay;

    return of(event).pipe(
      filter(() => event.payload.query.length >= 2),
      period,
      mergeMap(() => {
        return this.gateway.transfer(event.payload).pipe(
          map((payload: unknown) => {
            return {
              type: 'done',
              payload
            };
          }),
          catchError((err: any) => {
            return of({
              type: 'fail',
              error: true,
              payload: err
            });
          }),
          startWith({
            type: 'pending',
            payload: []
          }),
          subscribeOn(asyncScheduler)
        );
      })
    );
  }
}
