<div class="row blackColor" *ngIf="!data.error">
    <div class="col-1">
        <mat-icon class="fileIconClass"> insert_drive_file</mat-icon>
    </div>
    <div class="col-6">
        <span>{{data.filename}}</span>
        <div>
            <small class="mr-2">{{data.fileType }}</small>
            <small>{{ data.fileSize}}</small>
        </div>
    </div>
    <div class="col-5 mt-2">
        <span>{{data.status}}</span>
    </div>
</div>
<div class="row blackColor" *ngIf="data.error">
    <div class="col-12">
        <span>{{data.status}}</span>
    </div>
</div>