import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { AnalogueRegulatoryApprovalFormat } from '../../../../horizon-project/models/AnalogueRegulatoryApprovalFormat';
import { TLI } from '../../../../horizon-project/models/TLI';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import {
  dmy_ymd,
  ToReadableDate,
} from '../../../../horizon-project/utility-functions/DateFormat';
import { Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { MatTableExporterDirective } from 'mat-table-exporter';

export interface Analogue {
  [key: string]: string | number | boolean;
}

const ELEMENT_DATA: Analogue[] = [];

@Component({
  selector: 'he-project-analog-sub-grid',
  templateUrl: './project-analog-sub-grid.component.html',
  styleUrls: ['./project-analog-sub-grid.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ProjectAnalogSubGridComponent implements OnInit, AfterViewInit,OnChanges {
  @Output() recordsCountEvent = new EventEmitter<number>(true);
  @Input()analogueRegulatoryData?: AnalogueRegulatoryApprovalFormat[];

  formattedRegulatoryData: any[] = [];
  countries: string[] = [];
  brands: any[] = [];
  brandObj = {};
  projectId = '';
  TLIData: TLI[] = [];
  currentTli = '';
  projectData: any;
  public showSection = false;

  displayedColumns: string[] = [
    'brandedInnName',
    'initialLaunchDate',
    'indication',
    'macenismOfAction',
  ];

  dataSource = new MatTableDataSource([{}]);
  checked = false;
  disabled = false;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatTableExporterDirective)
  exporter?: MatTableExporterDirective;

  private unsubscriber$ = new Subject<void>();

  constructor(
    private analogueService: AnalogueService,
  ) {}

  ngOnChanges(): void {
    this.formatRegulatoryData();
  }

  ngOnInit(): void {
    this.analogueService.analogueRegulatoryDataLoading.subscribe(data=>{
      this.showSection=!data;
    })
  }

  private initialiseCompData(){
    this.formattedRegulatoryData= [];
    this.countries = [];
    this.brands = [];
    this.brandObj = {};
    this.analogueRegulatoryData=[];
    this.currentTli = '';
    this.checked = false;
    this.disabled = false;
    this.dataSource = new MatTableDataSource([{}]);
    this.showSection = false;

  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.recordsCountEvent.emit(this.dataSource.data.length);
  }

  /**
   * Formats the regulatory service data in the required format and updates the data souce to show in the grid.
   * sorts the data based on initial launch date and selects the least value of initial launch date.
   */
  formatRegulatoryData() {
    this.formattedRegulatoryData = [];
    this.brands = [];
    this.brandObj = {};
    const countries = this.analogueRegulatoryData
      ? [
          ...new Set(
            this.analogueRegulatoryData.map((item) => Capitalise(item.country))
          ),
        ]
      : [];
    this.countries = countries.sort();

    this.analogueRegulatoryData?.forEach((element,index) => {
      let brandObj = {
        brandName: element.brandName,
        tli: element.tli,
        inn : element.inn,
        moa:element.moa
      }
      this.brands.filter((brand:any) => brand.brandName.toLowerCase().trim() === element.brandName.toLowerCase().trim() && 
      brand.tli.toLowerCase().trim() === element.tli.toLowerCase().trim() && 
      brand.inn.toLowerCase().trim() === element.inn.toLowerCase().trim() && 
      brand.moa.toLowerCase().trim() === element.moa.toLowerCase().trim()
      ).length === 0 ? this.brands.push(brandObj) : null;
    })

    this.brands.forEach((element) => {
      const countryWiseData = this.analogueRegulatoryData?.filter(
        (x) => x.brandName.toLowerCase().trim() === element.brandName.toLowerCase().trim() 
        && x.tli.toLowerCase().trim() === element.tli.toLowerCase().trim() &&
         x.inn.toLowerCase().trim() === element.inn.toLowerCase().trim() &&
         x.moa.toLowerCase().trim() === element.moa.toLowerCase().trim()
      );
      const sortCountryWise = countryWiseData?.sort(function(a, b) {
        return (
          new Date(dmy_ymd(a.initialLaunchDate)).getTime() -
          new Date(dmy_ymd(b.initialLaunchDate)).getTime()
        );
      });
      const formatRegData: any = {
        brandedInnName: sortCountryWise
          ? Capitalise(sortCountryWise[0].brandName)
          : '',
        initialLaunchDate: sortCountryWise
          ? ToReadableDate(sortCountryWise[0]?.initialLaunchDate)
          : '',
        indication: sortCountryWise ? Capitalise(sortCountryWise[0]?.tli) : '',
        macenismOfAction: sortCountryWise
          ? Capitalise(sortCountryWise[0]?.moa)
          : '',
        position : sortCountryWise ? sortCountryWise[0]?.sequenceNo : '',
      };
      countries.forEach((element) => {
        element = element.trim();
        formatRegData[Capitalise(element)] = countryWiseData
          ? ToReadableDate(
            countryWiseData.filter(
                (x) => x.country.toLowerCase() === element.toLowerCase()
              )[0]?.regulatoryApprovalDate
            )
          : '';
      });
      this.formattedRegulatoryData.push(formatRegData);
    });
    this.formattedRegulatoryData.sort(function(a: any, b: any) {
      return a.position - b.position;
    });
    this.dataSource = new MatTableDataSource(this.formattedRegulatoryData);
    this.displayedColumns = [
      'brandedInnName',
      'initialLaunchDate',
      'indication',
      'macenismOfAction',
      ...this.countries,
    ];
    this.recordsCountEvent.emit(this.formattedRegulatoryData.length);
    this.showSection = true;
  }

  dwonloadData() {
      this.exporter?.exportTable('csv', {
        fileName: 'Analogue Timeline',
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
