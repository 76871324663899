import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'he-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent  {

  constructor(public snackBarRef: MatSnackBarRef<NotificationsComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) { }


}
