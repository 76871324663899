<div style="display: flex;justify-content: space-between;">
  <h1 mat-dialog-title class="mat-dialog-title">{{data.brandName}}</h1>
    <mat-icon class="hta-close-icon" (click)="onNoClick()">close</mat-icon>
</div>
<div mat-dialog-content>
  <p class="mat-dialog-subtitle">{{data.column}}</p>
  <ul class="mat-dialog-list" >
    <li *ngFor="let hta of data?.htaInfo" > 
      <a [href]="hta.link"
      target="_blank"><img src="/assets/document.png" alt="Document logo" /></a>
      <p>{{hta?.author}}. {{hta?.year}}. {{hta?.title}}</p>
    </li>
  </ul>
  <p>{{data.valueScopeReference}}</p>
</div>
