import { forEach } from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ValueDomainsService } from '../../../../horizon-project/services/value-domains.service';
import { ValueFrameworksService } from '../../../../horizon-project/services/value-frameworks.service';
import { ValueAttributesService } from '../../../../horizon-project/services/value-attributes.service';
import { ValueAttributeScoresService } from '../../../../horizon-project/services/value-attribute-scores.service';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import {
  FormControl,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { AssetTppService } from '../../../asset-tpp/services/project-asset-tpp.service';
import { ValueDomains } from '../../../../horizon-project/models/ValueDomains';
import { ValueFramework } from '../../../../horizon-project/models/ValueFramework';
import { ValueAttributes } from '../../../../horizon-project/models/ValueAttributes';
import { ValueAttributeScore } from '../../../../horizon-project/models/ValueAttributeScore';
import { HeaderService } from '../../../../shared/services/header.service';
import { ProjectService } from '../../services/project.service';
import { UpdateFrameworkService } from '../../../../shared/services/update-framework.service';

@Component({
  selector: 'he-scenario-details',
  templateUrl: './scenario-details.component.html',
  styleUrls: ['./scenario-details.component.scss'],
})
export class ScenarioDetailsComponent implements OnInit, OnDestroy {
  public ValueFrameworks: ValueFramework[] = [];
  public ValueDomains: any = [];
  public ValueAttributes: ValueAttributes[] = [];
  public ValueAttributeScores: ValueAttributeScore[] = [];
  public selectedValueFramework: any = {};
  public Countries: any[] = [];
  public selectedScenarioIndex = 0;
  public SelectedValueAttributeScores: any[] = [];
  public defaultValueDomains: any = [];
  public updatedValueDomains: any = [];
  private unsubscriber$ = new Subject<void>();
  public innName = '';
  public editProjectId = '';
  public editScenarioData: any = [];
  public valueChangeStatus = false;

  public scenarioNameError: any = 'Scenario name is Required';
  public scenarioDetailsError: any = 'Scenario details is Required';
  public scenarioDuplicate: boolean = false;
  public loading = true;
  private currentScenarioName = '';
  private currentProjectId = '';
  public isEditAssetTppScores: boolean = true;

  constructor(
    private valueFrameworkService: ValueFrameworksService,
    private valueAttributesScoreService: ValueAttributeScoresService,
    public projectService: ProjectService,
    private countryFlagService: CountryFlagService,
    private headerservice: HeaderService,
    private updateFrameworkService: UpdateFrameworkService,
  ) {
    this.projectService.GetValueScoreDisplayType().subscribe(res=>{
      this.projectService.isDropDown =  res[0].isDropdown;

    })
  }

  ngOnInit(): void {
    this.getValueFrameworks();

    this.scenarioForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        this.projectService.ScenarioName.next(data.scenarioName);
        if (this.selectedScenarioIndex) {
          this.projectService.ScenarioId.next(this.selectedScenarioIndex);
        }
        // this.projectService.valueChangeStatus.next(true)
        this.updateScenarioInService();
        this.updateScenarioDetails(data);
        if (this.selectedScenarioIndex > 0) {
          this.checkScenarioDuplicate(data.scenarioName, data.scenarioDetails);
        }
      });
    this.projectService.castCreateProjectFormData
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        this.selectedValueFramework = data.framework;
        this.Countries = data.selectedMarkets;
        if (this.selectedValueFramework) {
          this.getValueAttributeScores();
        }
      });
    this.projectService.castEditProjectId
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.editProjectId = data;
        if (this.editProjectId != '') {
          this.getIndex();
        }
      });
    this.projectService.castInnName
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.innName = data;
      });
    this.projectService.castReloadScenario
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        if (data) {
          this.getEditScenarioData();
          this.projectService.reloadScenario.next(false);
        }
      });
    this.verifyPermissions();
    this.projectService.castsetScrollId.pipe(takeUntil(this.unsubscriber$)).subscribe(res=>{
      if(res != 0){
        this.scroll(res)
        this.setAllErrors();
      }
    })
  }

  private verifyPermissions() {
    this.projectService.selectedScenarioIndex
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((index) => {
        const currentScenarioDetails =
          this.projectService.getScenarioDetails(index);
        if (currentScenarioDetails) {
          this.currentScenarioName = currentScenarioDetails.scenarioName;
          this.currentProjectId = this.editProjectId;

          if (this.currentProjectId && this.currentScenarioName) {
            const userProjectPermissions =
              this.headerservice.getUserProjectPermission();

            const currentProjectPermission = userProjectPermissions.find(
              (f) => f.projectId === this.currentProjectId
            );

            if (currentProjectPermission) {
              const currentAssetTppPermission =
                currentProjectPermission.permissions.assetTpp.find(
                  (f) => f.scenario === this.currentScenarioName
                );

              if (currentAssetTppPermission) {
                this.isEditAssetTppScores = currentAssetTppPermission.edit;

                if (!this.isEditAssetTppScores) {
                  this.scenarioForm.controls.scenarioName.disable();
                  this.scenarioForm.controls.scenarioDetails.disable();
                } else {
                  this.scenarioForm.controls.scenarioName.enable();
                  this.scenarioForm.controls.scenarioDetails.enable();
                }
              }
            }
          } else {
            this.isEditAssetTppScores = true;
            this.scenarioForm.controls.scenarioName.enable();
            this.scenarioForm.controls.scenarioDetails.enable();
          }
        } else {
          this.isEditAssetTppScores = true;
          this.scenarioForm.controls.scenarioName.enable();
          this.scenarioForm.controls.scenarioDetails.enable();
        }
      });
  }

  scenarioForm = new FormGroup({
    scenarioName: new FormControl('', [Validators.required]),
    scenarioDetails: new FormControl('', [Validators.maxLength(200)]),
  });

  get scenarioName(): AbstractControl<any, any> | null {
    return this.scenarioForm.get('scenarioName');
  }
  get scenarioDetails(): AbstractControl<any, any> | null {
    return this.scenarioForm.get('scenarioDetails');
  }

  public sendTheNewValue(event: any): void {
    this.projectService.setScenarioNameTouched(
      this.selectedScenarioIndex
    );
    this.projectService.valueChangeStatus.next(true);
    //silrang
    this.projectService.checkScenarioStatus(true,false);
  }

  private async getValueFrameworks() {
    await this.valueFrameworkService
      .getValueFrameworks()
      .then((data: ValueFramework[]) => {
        this.ValueFrameworks = data;
      });
  }

  private async getValueAttributeScores() {
    await this.valueAttributesScoreService
      .getValueAttributeScores(this.selectedValueFramework?.valueFrameworkId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (data: ValueAttributeScore[]) => {
        this.ValueAttributeScores =
          await this.filterIncomingValueAttributeScores(data);

        // tood: mo
        this.getValueDomainsAndAttributes(this.ValueAttributeScores);

        // this.prepareCombinedCountryData();
      });
  }

  private prepareCombinedCountryData() {
    if (this.ValueDomains) {
      this.ValueDomains.forEach((domain: any) => {
        domain.countries.forEach((country: any) => {
          if(country.name != "All"){
            country.valueAttributes.forEach((va: any) => {
              if (
                !domain.allCountries[0].valueAttributes.some(
                  (x: any) => x.combineName === va.combineName
                )
              ) {
                const tempVA = JSON.parse(JSON.stringify(va));
                domain.allCountries[0].valueAttributes.push(tempVA);
              }
            });
            domain.allCountries[0].valueAttributes.sort(
              (a: any, b: any) => a.valueAttributeOrder - b.valueAttributeOrder
            );
          }
        });
      });
    }
  }

  async filterIncomingValueAttributeScores(data: ValueAttributeScore[]) {
    let ndata: ValueAttributeScore[] = [];
    data.sort(this.compare);
    ndata = data.filter(
      (x) =>
        x.country === 'All' ||
        this.Countries.filter(
          (y) => y.toLowerCase().trim() === x.country.toLowerCase()
        ).length > 0
    );
    return ndata;
  }

  public compare = (a: any, b: any) => {
    if (a.country < b.country) {
      return -1;
    }
    if (a.country > b.country) {
      return 1;
    }
    return 0;
  };

  private getValueDomainsAndAttributes(data: any) {
    let type:string='value'
    this.Countries = this.removeDups(this.Countries);
    data.forEach((element: any,index:number) => {
      if (
        this.ValueDomains.filter(
          (valueDomain: any) => valueDomain.name === element.valueDomainName
        ).length === 0
      ) {
        this.ValueDomains.push({
          name: element.valueDomainName,
          countries: [],
          allCountries: [{ name: 'Combined', valueAttributes: [] }],
          completed: false,
          isAllMarketVisible: true,
          valueDomainOrder: element.valueDomainOrder,
          inputType: type === 'value'?'value':'range',

        });
      }

      if (
        this.ValueDomains.filter(
          (valueDomain: any) => valueDomain.name === element.valueDomainName
        )[0].countries.filter(
          (country: any) => country.name === element.country
        ).length === 0 &&
        (element.country === 'All' ||
          this.Countries.filter(
            (x) => x.toLowerCase() === element.country.toLowerCase()
          ).length === 1)
      ) {
        this.ValueDomains.filter(
          (valueDomain: any) => valueDomain.name === element.valueDomainName
        )[0].countries.push({ name: element.country, valueAttributes: [] });
      }

      if (
        this.ValueDomains.filter(
          (valueDomain: any) => valueDomain.name === element.valueDomainName
        )[0]
          .countries.filter(
            (country: any) => country.name === element.country
          )[0]
          .valueAttributes.filter(
            (valueAttribute: any) =>
              valueAttribute.name === element.valueAttributeName
          ).length === 0
      ) {
        let test = false;
        this.ValueDomains.filter(
          (valueDomain: any) => valueDomain.name === element.valueDomainName
        )[0]
          .countries.filter(
            (country: any) => country.name === element.country
          )[0]
          .valueAttributes.push({
            name: element.valueAttributeName,
            scrollId: index + 1,
            displayname: element.valueAttributeName.split(' (')[0],
            // combineName: element.valueAttributeName.excludes('USA')? 'element.valueAttributeName (EU)':element.valueAttributeName,
            combineName: element.valueAttributeName.includes('Patient number')? element.valueAttributeName.includes('USA')?element.valueAttributeName: 'Patient number (per European market)':element.valueAttributeName,
            infoData: element.valueAttributeInfoIconData,
            formType:element.valueAttributeName.includes('Patient number')?this.projectService.isDropDown? 'dropdown':'value' :'dropdown',
            placeholder:element.valueAttributeName.includes('USA')? 'Please input patient number value between 1 to 200,000':'Please input patient number value between 1 to 20,000',
            score: [],
            value:'',
            rangeStart:'',
            rangeEnd:'',
            rangeStartTouched:false,
            rangeEndTouched:false,
            selectedScore: '',
            touched: false,
            error: '',
            starRangeError: '',
            endRangeError: '',
            isValid: true,
            isStartRangeValid: true,
            isEndRangeValid: true,
            mapperId: null,
            valueAttributeOrder: element.valueAttributeOrder,
          });
      }

      if (
        this.ValueDomains.filter(
          (valueDomain: any) => valueDomain.name === element.valueDomainName
        )[0]
          .countries.filter(
            (country: any) => country.name === element.country
          )[0]
          .valueAttributes.filter(
            (valueAttribute: any) =>
              valueAttribute.name === element.valueAttributeName
          )[0]
          .score.filter((score: any) => score === element.score).length === 0
      ) {
        this.ValueDomains.filter(
          (valueDomain: any) => valueDomain.name === element.valueDomainName
        )[0]
          .countries.filter(
            (country: any) => country.name === element.country
          )[0]
          .valueAttributes.filter(
            (valueAttribute: any) =>
              valueAttribute.name === element.valueAttributeName
          )[0]
          .score.push({
            score: element.score,
            scoreDescription: element.scoreDescription,
            scoreId: element.valueAttributeScoreId,
            country: element.country,
          });
      }
    });
    this.sortValueAttributeScores();
    this.prepareDefaultValueAttributeScores();
    //sort by domain
    this.ValueDomains.sort(
      (a: any, b: any) => a.valueDomainOrder - b.valueDomainOrder
    );
    this.prepareCombinedCountryData();
    this.getIndex();
  }

  public sortValueAttributeScores(): void {
    this.ValueDomains.forEach((valueDomain: any) => {
      valueDomain.countries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          valueAttribute.score.sort((a: any, b: any) => a.score - b.score);
        });
      });
      // valueDomain.countries.forEach((country: any) => {
      //   country.valueAttributes.forEach((valueAttribute: any) => {
      //     valueAttribute.score.sort((a: any, b: any) => a.score - b.score);
      //   });
      // });
    });
  }

  public removeDups(names: string[]) {
    let unique: any = {};
    names.forEach(function (i: string) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  getIndex() {
    this.projectService.castselectedScenarioIndex
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        if(data > 0){
        this.selectedScenarioIndex = data;
        const scenarioDetails = this.projectService.getScenarioDetails(
          this.selectedScenarioIndex
        );

        if (scenarioDetails === null) {
          this.scenarioForm.setValue({ scenarioName: '', scenarioDetails: '' });
        }
        const scenarioAttributes = this.projectService.getScenarioAttributes(
          this.selectedScenarioIndex
        );
        if (scenarioAttributes == null) {
          this.updatedValueDomains = JSON.parse(
            JSON.stringify(this.ValueDomains)
          );
          if (this.defaultValueDomains != null) {
            this.sortValueAttributeScores();
            this.updatenewScenarioAttribtues();
            this.prepareDefaultValueAttributeScores();
          }
        } else {
          if(scenarioAttributes.length != 0){
            this.updatedValueDomains = JSON.parse(
              JSON.stringify(scenarioAttributes)
            );
            this.setCurrentValueAttributeScores();
          }
        }
        this.scenarioForm.patchValue(scenarioDetails);
     
        this.getEditScenarioData();
        }

      });
  }

  prepareDefaultValueAttributeScores() {
    this.ValueDomains.forEach((valueDomain: any) => {
      valueDomain.countries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          valueAttribute.selectedScore = '';
          valueAttribute.value = '';
          valueAttribute.rangeStart = '';
          valueAttribute.rangeEnd = '';
          valueAttribute.touched = false;
          valueAttribute.rangeStartTouched=false;
          valueAttribute.rangeEndTouched=false;
        });
      });     
       valueDomain.allCountries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          valueAttribute.selectedScore = '';
          valueAttribute.value = '';
          valueAttribute.rangeStart = '';
          valueAttribute.rangeEnd = '';
          valueAttribute.touched = false;
          valueAttribute.rangeStartTouched=false;
          valueAttribute.rangeEndTouched=false;
        });
      });
    });

    this.defaultValueDomains = JSON.parse(JSON.stringify(this.ValueDomains));
    this.checkFormValid();
  }

  setCurrentValueAttributeScores() {
    this.updatedValueDomains.forEach((valueDomain: any) => {
      valueDomain.countries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          if(valueAttribute.name.includes('Patient number')){
            if(valueDomain.inputType === 'value'){
              this.setAtrributeValue(
                valueAttribute.value,
                country.name,
                valueAttribute.name,
                valueDomain.inputType ,
                valueAttribute.touched,

              );
            }else{
              this.setAtrributeRange(
                valueAttribute.rangeStart,
                valueAttribute.rangeEnd,
                country.name,
                valueAttribute.name,
                valueDomain.inputType,
                valueAttribute.touched,
                valueAttribute.rangeStartTouched,
                valueAttribute.rangeEndTouched,
              );
            }
          }else{
            if (valueAttribute.selectedScore !== '') {
              this.setAtrributeScore(
                valueAttribute.selectedScore,
                country.name,
                valueAttribute.name,
                valueAttribute.touched,
              );
            } else {
              valueAttribute.selectedScore = '';
              this.setAtrributeScore(
                valueAttribute.selectedScore,
                country.name,
                valueAttribute.name,
                valueAttribute.touched
              );
            }
          }
        });
      });
      valueDomain.allCountries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          if(valueAttribute.name.includes('Patient number')){
            if(valueDomain.inputType === 'value'){
              this.setAtrributeValue(
                valueAttribute.value,
                country.name,
                valueAttribute.name,
                valueDomain.inputType,
                valueAttribute.touched,
              );
            }else{
              this.setAtrributeRange(
                valueAttribute.rangeStart,
                valueAttribute.rangeEnd,
                country.name,
                valueAttribute.name,
                valueDomain.inputType,
                valueAttribute.touched,
                valueAttribute.rangeStartTouched,
                valueAttribute.rangeEndTouched,
              );
            }
          }else{
            if (valueAttribute.selectedScore !== '') {
              this.setAtrributeScore(
                valueAttribute.selectedScore,
                country.name,
                valueAttribute.name,
                valueAttribute.touched,
              );
            } else {
              valueAttribute.selectedScore = '';
              this.setAtrributeScore(
                valueAttribute.selectedScore,
                country.name,
                valueAttribute.name,
                valueAttribute.touched,
              );
            }
          }

        });
      });
    });
  }

  setAtrributeScore(score: any, country: string, attribute: string,touched:boolean) {
    this.ValueDomains.forEach((valueDomain: any) => {
      valueDomain.countries.forEach((contry: any) => {
        contry.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.name === attribute && contry.name === country) {
            if (score != null && score != "") {
              valueAttribute.selectedScore = valueAttribute.score.find(
                (x: any) => x.scoreId === score.scoreId
              );
            } else {
              valueAttribute.selectedScore = '';
                valueAttribute.touched = touched;
                valueAttribute.error = 'is required';
            }
          }
        });
      });
      valueDomain.allCountries.forEach((contry: any) => {
        contry.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.name === attribute && contry.name === country) {
            if (score != null) {
              valueAttribute.selectedScore = valueAttribute.score.find(
                (x: any) => x.scoreId === score.scoreId
              );
            } else {
              valueAttribute.selectedScore = '';
              valueAttribute.touched = touched;
              valueAttribute.error = 'is required';

            }
          }
        });
      });
    });
    this.updateScenarioAttribtues();
  }

  setAtrributeValue(value: any, country: string, attribute: string,inputType:any,touched:boolean) {
    this.ValueDomains.forEach((valueDomain: any) => {
      valueDomain.inputType = inputType;
      valueDomain.countries.forEach((contry: any) => {
        contry.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.name === attribute && contry.name === country) {
              valueAttribute.value = value
              valueAttribute.isValid = this.projectService.checkValueValid(valueAttribute.value,valueAttribute.name);
              valueAttribute.touched = valueAttribute.isValid?false:touched;
              valueAttribute.error = this.projectService.setValueError(valueAttribute.value,valueAttribute.name);
          }
        });
      });
      valueDomain.allCountries.forEach((contry: any) => {
        contry.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.name === attribute && contry.name === country) {
              valueAttribute.value = value
              valueAttribute.isValid = this.projectService.checkValueValid(valueAttribute.value,valueAttribute.name);
              valueAttribute.touched = valueAttribute.isValid?true:touched;
              valueAttribute.error = this.projectService.setValueError(valueAttribute.value,valueAttribute.name);
          }
        });
      });
    });
    this.updateScenarioAttribtues();
  }

  setAtrributeRange(rangeStart: any,rangeEnd:any, country: string, attribute: string,inputType:any,touched:boolean, rangeStartTouched:boolean,rangeEndTouched:boolean) {
    this.ValueDomains.forEach((valueDomain: any) => {
      valueDomain.inputType = inputType;
      valueDomain.countries.forEach((contry: any) => {
        contry.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.name === attribute && contry.name === country) {
              valueAttribute.rangeStart = rangeStart
              valueAttribute.rangeEnd = rangeEnd
              valueAttribute.touched = touched;
              valueAttribute.starRangeError = ''
              valueAttribute.rangeEndTouched = rangeEndTouched;    
              valueAttribute.endRangeError = ''
              valueAttribute.rangeStartTouched = rangeStartTouched;
              if(rangeEndTouched){
                valueAttribute.endRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
                valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
              }
            if(rangeStartTouched){
                 valueAttribute.starRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
                valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
              }

          }
        });
      });
      valueDomain.allCountries.forEach((contry: any) => {
        contry.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.name === attribute && contry.name === country) {
              valueAttribute.rangeStart = rangeStart
              valueAttribute.rangeEnd = rangeEnd
              valueAttribute.touched = touched;
              valueAttribute.starRangeError = ''
              valueAttribute.rangeEndTouched = rangeEndTouched;    
              valueAttribute.endRangeError = ''
              valueAttribute.rangeStartTouched = rangeStartTouched;
              if(rangeEndTouched){
                valueAttribute.endRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
                valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
              }
            if(rangeStartTouched){
                 valueAttribute.starRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
                valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
              }
          }
        });
      });
    });
    this.updateScenarioAttribtues();
  }

  getFlagId(country: string) {
    return this.countryFlagService.getFlagId(country);
  }

  updateValueAtrributeScore(
    valueAttributeScoreId: string,
    score: string,
    value: any,
    country: string,
    attribute: string,
    isAllMarketVisible: boolean,
    inputType?:string,
    rangeType?:any,
  ) {
    this.ValueDomains.forEach((valueDomain: any) => {
      if (isAllMarketVisible) {
        valueDomain.countries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            if (valueAttribute.name === attribute && contry.name === country) {
              switch (inputType) {
                case 'dropdown':
                  valueAttribute.selectedScore = valueAttribute.score.find(
                    (x: any) => x.scoreId === valueAttributeScoreId
                  );
                  valueAttribute.error = 'is required'
                  break;
                  case 'value':
                    valueAttribute.value = value.value; 
                    valueAttribute.error = this.projectService.setValueError(value.value,valueAttribute.name)
                    valueAttribute.isValid = this.projectService.checkValueValid(value.value,valueAttribute.name);
                  break;
                  case 'range':
                    if(rangeType ==='start'){
                      valueAttribute.rangeStart = value.value; 
                      valueAttribute.rangeStartTouched = true; 
                      valueAttribute.rangeEndTouched = true; 
                      valueAttribute.starRangeError = this.projectService.setRangeError(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
                      valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
                      // if(valueAttribute.rangeEndTouched){
                        valueAttribute.endRangeError = this.projectService.setRangeError(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
                        valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
                      // }
                    }else if(rangeType ==='end'){
                      valueAttribute.rangeEnd = value.value; 
                      valueAttribute.rangeStartTouched = true; 
                      valueAttribute.rangeEndTouched = true; 
                      valueAttribute.endRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
                      valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
                      // if(valueAttribute.rangeStartTouched){
                        valueAttribute.starRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
                        valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
                      // }
                    }
                  break;
              }
              valueAttribute.touched = true;
            }
          });
        });
      } else {
        valueDomain.allCountries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            if (valueAttribute.combineName === attribute) {
              switch (inputType) {
                case 'dropdown':
                  valueAttribute.selectedScore = valueAttribute.score.find(
                    (x: any) => x.scoreId === valueAttributeScoreId
                  );
                  valueAttribute.error = 'is required'
                  break;
                  case 'value':
                    valueAttribute.value = value.value;
                    valueAttribute.error = this.projectService.setValueError(value.value,valueAttribute.name);
                    valueAttribute.isValid = this.projectService.checkValueValid(value.value,valueAttribute.name);
                  break;
                  case 'range':
                    if(rangeType ==='start'){
                      valueAttribute.rangeStart = value.value; 
                      valueAttribute.rangeStartTouched = true; 
                      valueAttribute.rangeEndTouched = true; 
                      valueAttribute.starRangeError = this.projectService.setRangeError(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
                      valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
                      // if(valueAttribute.rangeEndTouched){
                        valueAttribute.endRangeError = this.projectService.setRangeError(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
                        valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
                      // }
                    }else if(rangeType ==='end'){
                      valueAttribute.rangeEnd = value.value; 
                      valueAttribute.rangeStartTouched = true; 
                      valueAttribute.rangeEndTouched = true; 
                      valueAttribute.endRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
                      valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
                      // if(valueAttribute.rangeStartTouched){
                        valueAttribute.starRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
                        valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
                      // }
                    }
                  break;
              }
              valueAttribute.touched = true;
            }
          });
        });
        valueDomain.countries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            if (valueAttribute.combineName === attribute) {
              switch (inputType) {
                case 'dropdown':
                  valueAttribute.selectedScore = valueAttribute.score.find(
                    (x: any) => x.scoreId === valueAttributeScoreId
                  );
                  valueAttribute.error = 'is required'
                  break;
                  case 'value':
                    valueAttribute.value = value.value;
                    valueAttribute.error = this.projectService.setValueError(value.value,valueAttribute.name);
                    valueAttribute.isValid = this.projectService.checkValueValid(value.value,valueAttribute.name);
                  break;
                  case 'range':
                    if(rangeType ==='start'){
                      valueAttribute.rangeStart = value.value; 
                      valueAttribute.rangeStartTouched = true; 
                      valueAttribute.rangeEndTouched = true; 
                      valueAttribute.starRangeError = this.projectService.setRangeError(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
                      valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
                      // if(valueAttribute.rangeEndTouched){
                        valueAttribute.endRangeError = this.projectService.setRangeError(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
                        valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
                      // }
                    }else if(rangeType ==='end'){
                      valueAttribute.rangeEnd = value.value; 
                      valueAttribute.rangeStartTouched = true; 
                      valueAttribute.rangeEndTouched = true; 
                      valueAttribute.endRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
                      valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
                      // if(valueAttribute.rangeStartTouched){
                        valueAttribute.starRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
                        valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
                      // }
                    }
                  break;
              }
              valueAttribute.touched = true;
            }
          });
        });
      }
    });
    this.projectService.valueChangeStatus.next(true);
    this.updateScenarioAttribtues();
    this.checkScenarioDuplicate(
      this.scenarioForm.value.scenarioName as string,
      this.scenarioForm.value.scenarioDetails as string
    );
    //silrang
    this.projectService.checkScenarioStatus(true,false);
  }



  public checkFormDirty(
    event: boolean,
    country: string,
    attribute: string,
    isAllMarketVisible: boolean
  ) {
    if (!event) {
      this.ValueDomains.forEach((valueDomain: any) => {
        if (isAllMarketVisible) {
          valueDomain.countries.forEach((contry: any) => {
            contry.valueAttributes.forEach((valueAttribute: any) => {
              if (
                valueAttribute.name === attribute &&
                contry.name === country
              ) {
                valueAttribute.touched = true;
              }
            });
          });
        } else {
          valueDomain.allCountries.forEach((contry: any) => {
            contry.valueAttributes.forEach((valueAttribute: any) => {
              if (valueAttribute.name === attribute) {
                valueAttribute.touched = true;
              }
            });
          });
        }
      });
    }
  }

  public checkFormValid(): void {
    let display = true;
    this.ValueDomains.forEach((ValueDomain: any) => {
      display = true;
      ValueDomain.countries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          
          switch (valueAttribute.formType) {
            case 'dropdown':
              if (
                valueAttribute.selectedScore === '' ||
                valueAttribute.selectedScore === undefined
              ) {
                display = false;
              } else {
                if (display != false) {
                  display = true;
                }
              }
              break;
              case 'value':
                if(ValueDomain.inputType ==='value'){
                  if (
                    !this.projectService.checkValueValid(valueAttribute.value,valueAttribute.name)
                  ) {
                    display = false;
                  } else {
                    if (display != false) {
                      display = true;
                    }
                  }
                }else{
                  if (
                    !this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name)
                  ) {
                    display = false;
                  } else if( !this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name)) {
                    display = false;
                  }else{
                    if (display != false) {
                      display = true;
                    }
                  }
                }
 

   
              break;
          }
        });
      });
      ValueDomain.completed = display;
    });

    // this.projectService.checkScenarioStatus(false);
  }

  updateScenarioAttribtues(): void {
    if(this.ValueDomains.length != 0){
      this.projectService.setScenarioAtributes(
        this.selectedScenarioIndex,
        this.ValueDomains
      );
      this.checkFormValid();
    }

  }
  updatenewScenarioAttribtues(): void {

    this.projectService.setScenarioAtributes(
      this.selectedScenarioIndex,
      this.defaultValueDomains
    );
  }

  updateScenarioInService() {
    this.projectService.SelectedScenarioData.splice(
      this.selectedScenarioIndex - 1,
      1,
      {
        scenarioName: this.scenarioForm.get('scenarioName')?.value,
        scenarioDetails: this.scenarioForm.get('scenarioDetail')?.value,
        valueAttributeScores: this.SelectedValueAttributeScores,
      }
    );
  }

  updateScenarioDetails(data: any) {
    this.projectService.updateScenarioDetails(data, this.selectedScenarioIndex);
  }

  // Edit-Scenario Section
  async getEditScenarioData() {
    // const scenarioAttributes = this.projectService.getScenarioAttributes(
    //   this.selectedScenarioIndex
    // );
    this.loading = true;
    if(this.projectService.scenarios[this.selectedScenarioIndex -1].loaded && this.updateFrameworkService.isUpdateFrameWork.value){
      this.loading = false;
    }else{
    const scenarioData = await this.projectService.getEditScenarioData(
      this.editProjectId,
      this.innName,
      this.selectedScenarioIndex
    );
    if (scenarioData?.assetTLIs) {
      scenarioData.assetTLIs[0].valueDomains.forEach((element: any) => {
        if (
          this.editScenarioData.filter(
            (valueDomain: any) => valueDomain.name === element.domainName
          ).length === 0
        ) {
          this.editScenarioData.push({
            name: element.domainName,
            countries: [],
            completed: false,
            isAllMarketVisible: true,
          });
        }

        element.valueAttributes.forEach((valueAttribute: any) => {
          if (
            this.editScenarioData.filter(
              (valueDomain: any) => valueDomain.name === element.domainName
            )[0].countries.length !== 0
          ) {
            if (
              this.ValueDomains.filter(
                (valueDomain: any) => valueDomain.name === element.domainName
              )[0]
                .countries.filter(
                  (country: any) => country.name === valueAttribute.country
                )[0]
                .valueAttributes.filter(
                  (valueAttributeData: any) =>
                    valueAttributeData.name ===
                    valueAttribute.valueAttributeName
                )[0] != undefined
            ) {
              //mapperID
     
              this.ValueDomains.filter(
                (valueDomain: any) => valueDomain.name === element.domainName
              )[0]
                .countries.filter(
                  (country: any) => country.name === valueAttribute.country
                )[0]
                .valueAttributes.filter(
                  (valueAttributeData: any) =>
                    valueAttributeData.name ===
                    valueAttribute.valueAttributeName
                )[0].mapperId = valueAttribute.valueAttributeAssetMapperId;
              //currentScore
              if(valueAttribute.valueAttributeName.includes('Patient number') && !this.projectService.isDropDown){
                if(valueAttribute.score != null){
                  if(valueAttribute.score.includes('-')){
                    const vs_range = valueAttribute.score.split('-');
  
                    this.ValueDomains.filter(
                      (valueDomain: any) => valueDomain.name === element.domainName
                    )[0].inputType = 'range';
                    this.ValueDomains.filter(
                      (valueDomain: any) => valueDomain.name === element.domainName
                    )[0]
                      .countries.filter(
                        (country: any) => country.name === valueAttribute.country
                      )[0]
                      .valueAttributes.filter(
                        (valueAttributeData: any) =>
                          valueAttributeData.name ===
                          valueAttribute.valueAttributeName
                      )[0].rangeStart = vs_range[0];
                      this.ValueDomains.filter(
                        (valueDomain: any) => valueDomain.name === element.domainName
                      )[0]
                        .countries.filter(
                          (country: any) => country.name === valueAttribute.country
                        )[0]
                        .valueAttributes.filter(
                          (valueAttributeData: any) =>
                            valueAttributeData.name ===
                            valueAttribute.valueAttributeName
                        )[0].isStartRangeValid =true;
                      this.ValueDomains.filter(
                        (valueDomain: any) => valueDomain.name === element.domainName
                      )[0]
                        .countries.filter(
                          (country: any) => country.name === valueAttribute.country
                        )[0]
                        .valueAttributes.filter(
                          (valueAttributeData: any) =>
                            valueAttributeData.name ===
                            valueAttribute.valueAttributeName
                        )[0].rangeEnd = vs_range[1];
                        this.ValueDomains.filter(
                          (valueDomain: any) => valueDomain.name === element.domainName
                        )[0]
                          .countries.filter(
                            (country: any) => country.name === valueAttribute.country
                          )[0]
                          .valueAttributes.filter(
                            (valueAttributeData: any) =>
                              valueAttributeData.name ===
                              valueAttribute.valueAttributeName
                          )[0].isEndRangeValid =true;
                        
                  }else{
                    this.ValueDomains.filter(
                      (valueDomain: any) => valueDomain.name === element.domainName
                    )[0]
                      .countries.filter(
                        (country: any) => country.name === valueAttribute.country
                      )[0]
                      .valueAttributes.filter(
                        (valueAttributeData: any) =>
                          valueAttributeData.name ===
                          valueAttribute.valueAttributeName
                      )[0].value = valueAttribute.score;                  
                      this.ValueDomains.filter(
                      (valueDomain: any) => valueDomain.name === element.domainName
                    )[0]
                      .countries.filter(
                        (country: any) => country.name === valueAttribute.country
                      )[0]
                      .valueAttributes.filter(
                        (valueAttributeData: any) =>
                          valueAttributeData.name ===
                          valueAttribute.valueAttributeName
                      )[0].isValid = true;
                  }
                }
              }else{
                this.ValueDomains.filter(
                  (valueDomain: any) => valueDomain.name === element.domainName
                )[0]
                  .countries.filter(
                    (country: any) => country.name === valueAttribute.country
                  )[0]
                  .valueAttributes.filter(
                    (valueAttributeData: any) =>
                      valueAttributeData.name ===
                      valueAttribute.valueAttributeName
                  )[0].selectedScore = this.editScenarioData
                  .filter(
                    (valueDomain: any) => valueDomain.name === element.domainName
                  )[0]
                  .countries.filter(
                    (country: any) => country.name === valueAttribute.country
                  )[0]
                  .valueAttributes.filter(
                    (valueAttributeData: any) =>
                      valueAttributeData.name ===
                      valueAttribute.valueAttributeName
                  )[0]
                  .score.find((x: any) => x.score === valueAttribute.score);
              }

            }
          }
        });
      });
      if (this.editProjectId != '') {
          this.updateValueDomains(this.editScenarioData);
    this.projectService.loadedScenario(this.selectedScenarioIndex)

      }
    } else {
      this.loading = false;

    }
    if (this.editProjectId != '') {
        this.updateValueDomains(this.editScenarioData);
        if(this.updateFrameworkService.isUpdateFrameWork.value){
          this.projectService.navigateError(true)
        }
    }
    }

  }

  updateValueDomains(scenarioData: any) {
    this.ValueDomains = scenarioData;
    this.loading = false;
    if (
      this.projectService.scenarios[this.selectedScenarioIndex - 1].copiedId > 0
    ) {
      this.ValueDomains.forEach((domain: any) => {
        domain.countries.forEach((country: any) => {
          country.valueAttributes.forEach((valueAttribute: any) => {
            valueAttribute.mapperId = '';
          });
        });
      });
    }
    this.projectService.setScenarioAtributes(
      this.selectedScenarioIndex,
      this.ValueDomains
    );
  }

  checkScenarioDuplicate(scenarioName: any, scenarioDetails: any) {
    const specialCharactersRegex = /[?!#%*<>~'']/;

    const hasSpecialCharacters = specialCharactersRegex.test(scenarioName);
    const detailsHasSpecialCharacters = specialCharactersRegex.test(scenarioDetails);
    let touched = this.projectService.getScenarioNameTouched(
      this.selectedScenarioIndex
    );
    if(touched){
      if (!scenarioName) {
        this.scenarioForm.setErrors(null);
        this.scenarioNameError = 'Scenario name is required';
        this.projectService.duplicateScenarioName.next(true);
        this.projectService.isScenariosNamesValid = false;
        this.scenarioForm.controls.scenarioName.markAsDirty();
        this.scenarioForm.controls.scenarioName.markAsTouched();
      } else if (
        this.projectService.scenarios
          .filter((scenario: any) => scenario?.id !== this.selectedScenarioIndex)
          .filter(
            (scenario: any) =>
              scenario?.scenarioDetails?.scenarioName.toLowerCase() ===
              scenarioName.toLowerCase()
          ).length > 0
      ) {
        this.scenarioDuplicate = true;
        this.scenarioNameError = 'Scenario name already exists';
        this.scenarioForm.controls.scenarioName.setErrors({
          'Scenario name already exists': true,
        });
        this.projectService.duplicateScenarioName.next(true);
        this.projectService.isScenariosNamesValid = false;
        this.scenarioForm.controls.scenarioName.markAsDirty();
        this.scenarioForm.controls.scenarioName.markAsTouched();
      } else if (scenarioName === '') {
        this.scenarioForm.controls.scenarioName.setErrors({
          'Scenario name already exists': true,
        });
        this.scenarioNameError = 'Scenario name is required';
        this.projectService.duplicateScenarioName.next(true);
        this.projectService.isScenariosNamesValid = false;
        this.scenarioForm.controls.scenarioName.markAsDirty();
        this.scenarioForm.controls.scenarioName.markAsTouched();
      } else if(hasSpecialCharacters){
        this.scenarioForm.controls.scenarioName.setErrors({
          "Scenario Name cannot contain any of the following characters: ? ! # % *< > ~": true,
        });
        this.scenarioNameError =
        "Scenario Name cannot contain any of the following characters: ? ! # % *< > ~";
        this.projectService.duplicateScenarioName.next(false);
        this.projectService.isScenariosNamesValid = false;
        this.scenarioForm.controls.scenarioName.markAsDirty();
        this.scenarioForm.controls.scenarioName.markAsTouched();
      }else if ((scenarioName.trim()).length <= 4) {
        
        this.scenarioForm.controls.scenarioName.setErrors({
          'Scenario Name must be at least 5 characters long': true,
        });
        this.scenarioNameError =
          'Scenario Name must be at least 5 characters long';
        this.projectService.duplicateScenarioName.next(false);
        this.projectService.isScenariosNamesValid = false;
        this.scenarioForm.controls.scenarioName.markAsDirty();
        this.scenarioForm.controls.scenarioName.markAsTouched();
      } else {
        this.scenarioForm.setErrors(null);
        this.scenarioNameError = 'Scenario name is required';
        this.projectService.duplicateScenarioName.next(false);
        this.projectService.isScenariosNamesValid = true;
        this.scenarioForm.controls.scenarioName.markAsDirty();
        this.scenarioForm.controls.scenarioName.markAsTouched();
      }
    }else{
      this.scenarioForm.controls.scenarioName.markAsPristine();
      this.scenarioForm.controls.scenarioName.markAsUntouched();
    }

    // if (!scenarioDetails) {
    //   this.scenarioForm.controls.scenarioDetails.setErrors({
    //     'Scenario details is required': true,
    //   });
    //   this.scenarioDetailsError = 'Scenario details is required';
    //   this.projectService.duplicateScenarioName.next(true);
    //   this.projectService.isScenariosNamesValid = false;
    // } else if (scenarioDetails === '') {
    //   this.scenarioForm.controls.scenarioDetails.setErrors({
    //     'Scenario details is required': true,
    //   });
    //   this.scenarioDetailsError = 'Scenario details is required';
    //   this.projectService.duplicateScenarioName.next(true);
    //   this.projectService.isScenariosNamesValid = false;
    // } else
    if(detailsHasSpecialCharacters){
      this.scenarioForm.controls.scenarioDetails.setErrors({
        "Scenario details cannot contain any of the following characters: ? ! # % *< > ~": true,
      });
      this.scenarioDetailsError =
      "Scenario details cannot contain any of the following characters: ? ! # % *< > ~";
      this.projectService.duplicateScenarioName.next(false);
      this.projectService.isScenariosNamesValid = false;
    }else if ((scenarioDetails.trim()).length > 200) {
      this.scenarioForm.controls.scenarioDetails.setErrors({
        'Scenario details cannot exceed 200 characters': true,
      });
      this.scenarioDetailsError =
        'Scenario details cannot exceed 200 characters';
      this.projectService.duplicateScenarioName.next(false);
      this.projectService.isScenariosNamesValid = false;
    } 
    // else if((scenarioDetails.trim()).length < 5){
    //   if((scenarioDetails.trim()) != ''){
    //     this.scenarioForm.controls.scenarioDetails.setErrors({
    //       'Scenario details must be at least 5 characters long': true,
    //     });
    //     this.scenarioDetailsError =
    //       'Scenario details must be at least 5 characters long';
    //     this.projectService.duplicateScenarioName.next(false);
    //     this.projectService.isScenariosNamesValid = false;
    //   }
    // }
    // if(this.currentProjectId === ''){
    this.projectService.scenarios.filter(
      (scenario: any) => scenario?.id === this.selectedScenarioIndex
    )[0].scenarioDetails.scenarioName = scenarioName;
    // }
  }


  scroll(id:number):void{
    if(!this.scenarioForm.valid){
      id = -1
      this.scenarioForm.controls.scenarioName.markAsDirty();
      this.scenarioForm.controls.scenarioName.markAsTouched();
      this.checkScenarioDuplicate(this.scenarioForm.controls.scenarioName.value,this.scenarioForm.controls.scenarioDetails.value)
    }
    setTimeout(() => {
      const itemtoScollTo = document.getElementById(JSON.stringify(id));
      itemtoScollTo?.scrollIntoView(true);
    }, 1000);

  }


  public setAllErrors():void{
    let inputType = 'value'
    this.ValueDomains.forEach((valueDomain: any) => {
      // if (isAllMarketVisible) {
        inputType = valueDomain.inputType
        valueDomain.countries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            // if (valueAttribute.name === attribute && contry.name === country) {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if(valueAttribute.selectedScore === '' ||
                  valueAttribute.selectedScore === undefined){
                    valueAttribute.error = 'is required';
                    valueAttribute.selectedScore = '';
                  }
                  break;
                  case 'value':
                    // valueAttribute.value = value.value; 
                    // if

                    if(valueDomain.inputType === 'value'){
                      valueAttribute.error = this.projectService.setValueError(valueAttribute.value,valueAttribute.name)
                      valueAttribute.isValid = this.projectService.checkValueValid(valueAttribute.value,valueAttribute.name);
                    }else{
                      valueAttribute.range
                      valueAttribute.starRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
                      valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
                      valueAttribute.rangeStartTouched = true;
                      valueAttribute.rangeEndTouched = true;
                        valueAttribute.endRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
                        valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
                    }

                  break;
              }
              valueAttribute.touched = true;
            // }
          });
        });
        valueDomain.allCountries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            // if (valueAttribute.name === attribute && contry.name === country) {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if(valueAttribute.selectedScore === '' ||
                  valueAttribute.selectedScore === undefined){
                    valueAttribute.error = 'is required';
                    valueAttribute.selectedScore = '';
                  }
                  break;
                  case 'value':
                    // valueAttribute.value = value.value; 
                    // if
                    if(inputType === 'value'){
                      valueAttribute.error = this.projectService.setValueError(valueAttribute.value,valueAttribute.name)
                      valueAttribute.isValid = this.projectService.checkValueValid(valueAttribute.value,valueAttribute.name);
                    }else{
                      valueAttribute.starRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
                      valueAttribute.isStartRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'start',valueAttribute.name);
                        valueAttribute.endRangeError = this.projectService.setRangeError(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
                        valueAttribute.isEndRangeValid = this.projectService.checkRangeValid(valueAttribute.rangeStart,valueAttribute.rangeEnd,'end',valueAttribute.name);
                        valueAttribute.rangeStartTouched = true;
                        valueAttribute.rangeEndTouched = true;
                    }

                  break;
              }
              valueAttribute.touched = true;
            // }
          });
        });
      // } else {
      //   valueDomain.allCountries.forEach((contry: any) => {
      //     contry.valueAttributes.forEach((valueAttribute: any) => {
      //       if (valueAttribute.combineName === attribute) {
      //         switch (inputType) {
      //           case 'dropdown':
      //             valueAttribute.selectedScore = valueAttribute.score.find(
      //               (x: any) => x.score === score
      //             );
      //             valueAttribute.error = 'is required'
      //             break;
      //             case 'value':
      //               valueAttribute.value = value.value;
      //               valueAttribute.error = this.setValueError(value.value,valueAttribute.name);
      //               valueAttribute.isValid = this.checkValueValid(value.value,valueAttribute.name);
      //             break;
      //             case 'range':
      //               if(rangeType ==='start'){
      //                 valueAttribute.rangeStart = value.value; 
      //                 valueAttribute.starRangeError = this.setRangeError(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
      //                 valueAttribute.isStartRangeValid = this.checkRangeValid(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
      //                 // if(valueAttribute.rangeEnd !== ''){
      //                   valueAttribute.endRangeError = this.setRangeError(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
      //                   valueAttribute.isEndRangeValid = this.checkRangeValid(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
      //                 // }
      //               }else if(rangeType ==='end'){
      //                 valueAttribute.rangeEnd = value.value; 
      //                 valueAttribute.endRangeError = this.setRangeError(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
      //                 valueAttribute.isEndRangeValid = this.checkRangeValid(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
      //                 // if(valueAttribute.rangeStart !== ''){
      //                   valueAttribute.starRangeError = this.setRangeError(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
      //                   valueAttribute.isStartRangeValid = this.checkRangeValid(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
      //                 // }

      //               }
      //             break;
      //         }

      //         valueAttribute.touched = true;
      //       }
      //     });
      //   });

      //   valueDomain.countries.forEach((contry: any) => {
      //     contry.valueAttributes.forEach((valueAttribute: any) => {
      //       if (valueAttribute.combineName === attribute) {

      //         switch (inputType) {
      //           case 'dropdown':
      //             valueAttribute.selectedScore = valueAttribute.score.find(
      //               (x: any) => x.score === score
      //             );
      //             valueAttribute.error = 'is required'

      //             break;
      //             case 'value':
      //               valueAttribute.value = value.value;
      //               valueAttribute.error = this.setValueError(value.value,valueAttribute.name);
      //               valueAttribute.isValid = this.checkValueValid(value.value,valueAttribute.name);

      //             break;
      //             case 'range':
      //               if(rangeType ==='start'){
      //                 valueAttribute.rangeStart = value.value; 
      //                 valueAttribute.starRangeError = this.setRangeError(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
      //                 valueAttribute.isStartRangeValid = this.checkRangeValid(value.value,valueAttribute.rangeEnd,rangeType,valueAttribute.name);
      //                 // if(valueAttribute.rangeEnd !== ''){
      //                   valueAttribute.endRangeError = this.setRangeError(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
      //                   valueAttribute.isEndRangeValid = this.checkRangeValid(value.value,valueAttribute.rangeEnd,'end',valueAttribute.name);
      //                 // }
      //               }else if(rangeType ==='end'){
      //                 valueAttribute.rangeEnd = value.value; 
      //                 valueAttribute.endRangeError = this.setRangeError(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
      //                 valueAttribute.isEndRangeValid = this.checkRangeValid(valueAttribute.rangeStart,value.value,rangeType,valueAttribute.name);
      //                 // if(valueAttribute.rangeStart !== ''){
      //                   valueAttribute.starRangeError = this.setRangeError(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
      //                   valueAttribute.isStartRangeValid = this.checkRangeValid(valueAttribute.rangeStart,value.value,'start',valueAttribute.name);
      //                 // }

      //               }
      //             break;
      //         }
      //         valueAttribute.touched = true;
      //       }
      //     });
      //   });
      // }
    }); 
  }
  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
