// export interface gridViewObject= GridViewCustomColumn[];

export interface GridViewCustomColumn {
    header: string;
    field: string;
    index: number;
    isSelected: boolean | null;
    isDefault: boolean;
    isVisible: boolean;
    activatedByHeader?: string;
}

export const gridViewCustomColumnPnR: GridViewCustomColumn[] = [
    { header: 'Country', field: 'Country', index: 1, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Brand name', field: 'Brand', index: 2, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Pack description', field: 'PackDescription', index: 3, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Availability Status', field: 'availabilityStatus', index: 2, isSelected: null, isDefault: false, isVisible: true },
    { header: 'INN', field: 'BrandInns', index: 4, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Company', field: 'Company', index: 5, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Marketing Authority', field: 'MarketingAuthority', index: 6, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Launch Date', field: 'LaunchDate', index: 7, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Brand Category', field: 'BrandCategory', index: 8, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Hospital Pack', field: 'IsHospitalPack', index: 9, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Combination Molecule', field: 'CombinationMolecule', index: 10, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Product lifecycle', field: 'IsDiscontinued', index: 12, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Formulation', field: 'Formulation', index: 13, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Strength', field: 'Strength', index: 14, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Strength Unit', field: 'StrengthUnit', index: 15, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Fill Size', field: 'FillSize', index: 16, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Fill Unit', field: 'FillUnit', index: 17, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack Size', field: 'PackSize', index: 18, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack Unit', field: 'PackUnit', index: 19, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Currency', field: 'Currency', index: 20, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Ex Factory', field: 'ExFactoryPrice', index: 21, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Ex Factory Source Type', field: 'ExfactoryColorCode', index: 22, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Hospital', field: 'HospitalPrice', index: 23, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Hospital Source Type', field: 'HospitalColorCode', index: 24, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Pharmacy Purchase Price', field: 'Ppp', index: 25, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pharmacy Purchase Price Source Type', field: 'PppColorCode', index: 26, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Public Price Excluding Vat', field: 'PpExVat', index: 27, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Public Price Excluding Vat Source Type', field: 'PpExVatColorCode', index: 28, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Public Price Including Vat', field: 'PpIncVat', index: 29, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Public Price Including Vat Source Type', field: 'PpIncVatColorCode', index: 30, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Reimbursement Price', field: 'ReimbursementPrice', index: 31, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursement Classification (Formulary Status)', field: 'ReimbursedClassification', index: 32, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursement Reference Price', field: 'ReimbursementReferencePrice', index: 33, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Source', field: 'PackSource', index: 34, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Source Update Date', field: 'SourceUpdateDate', index: 35, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Country ISO Code', field: 'CountryISOCode', index: 36, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack Note', field: 'PackNote', index: 37, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Region', field: 'Region', index: 38, isSelected: null, isDefault: false, isVisible: true },
    { header: 'MOA', field: 'Moa', index: 39, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursement Approval Date', field: 'ReimbursementApprovalDate', index: 42, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Level Of Reimbursement', field: 'LevelOfReimbursement', index: 43, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Copayment Ratio', field: 'CopaymentRatio', index: 44, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Copayment Price', field: 'CopaymentPrice', index: 46, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursed Annotations', field: 'ReimbursedAnnotations', index: 47, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Launch Price', field: 'LaunchPrice', index: 48, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Therapy Class', field: 'TherapyClass', index: 53, isSelected: null, isDefault: false, isVisible: true },
    { header: 'WHOATC', field: 'Whoatc', index: 54, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Orphan Status', field: 'OrphanStatus', index: 55, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack ID', field: 'PackId', index: 59, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net Ex Factory', field: 'ExfactoryLessRebate', index: 60, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net Hospital Price', field: 'HospitalPriceLessRebate', index: 61, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net PPP', field: 'PppLessRebate', index: 62, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net PP Ex Vat', field: 'PpExVatLessRebate', index: 63, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net PP Inc Vat', field: 'PpIncVatLessRebate', index: 64, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pulse Comments', field: 'PulseComments', index: 65, isSelected: null, isDefault: false, isVisible: false },

];

export const gridViewCustomColumnPnRHistory: GridViewCustomColumn[] = [
    { header: 'Country', field: 'Country', index: 1, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Brand name', field: 'Brand', index: 2, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Pack description', field: 'PackDescription', index: 3, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Availability Status', field: 'availabilityStatus', index: 2, isSelected: null, isDefault: false, isVisible: true },
    { header: 'INN', field: 'BrandInns', index: 4, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Company', field: 'Company', index: 5, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Marketing Authority', field: 'MarketingAuthority', index: 6, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Launch Date', field: 'LaunchDate', index: 7, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Brand Category', field: 'BrandCategory', index: 8, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Hospital Pack', field: 'IsHospitalPack', index: 9, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Combination Molecule', field: 'CombinationMolecule', index: 10, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Product lifecycle', field: 'IsDiscontinued', index: 12, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Formulation', field: 'Formulation', index: 13, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Strength', field: 'Strength', index: 14, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Strength Unit', field: 'StrengthUnit', index: 15, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Fill Size', field: 'FillSize', index: 16, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Fill Unit', field: 'FillUnit', index: 17, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack Size', field: 'PackSize', index: 18, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack Unit', field: 'PackUnit', index: 19, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Currency', field: 'Currency', index: 20, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Ex Factory', field: 'ExFactoryPrice', index: 21, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Ex Factory Source Type', field: 'ExfactoryColorCode', index: 22, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Hospital', field: 'HospitalPrice', index: 23, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Hospital Source Type', field: 'HospitalColorCode', index: 24, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Pharmacy Purchase Price', field: 'Ppp', index: 25, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pharmacy Purchase Price Source Type', field: 'PppColorCode', index: 26, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Public Price Excluding Vat', field: 'PpExVat', index: 27, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Public Price Excluding Vat Source Type', field: 'PpExVatColorCode', index: 28, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Public Price Including Vat', field: 'PpIncVat', index: 29, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Public Price Including Vat Source Type', field: 'PpIncVatColorCode', index: 30, isSelected: null, isDefault: false, isVisible: false },
    { header: 'Reimbursement Price', field: 'ReimbursementPrice', index: 31, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursement Classification (Formulary Status)', field: 'ReimbursedClassification', index: 32, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursement Reference Price', field: 'ReimbursementReferencePrice', index: 33, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Source', field: 'PackSource', index: 34, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Source Update Date', field: 'SourceUpdateDate', index: 35, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Country ISO Code', field: 'CountryISOCode', index: 36, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack Note', field: 'PackNote', index: 37, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Region', field: 'Region', index: 38, isSelected: null, isDefault: false, isVisible: true },
    { header: 'MOA', field: 'Moa', index: 39, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Price Start Date', field: 'PriceStartDate', index: 40, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Price End Date', field: 'PriceEndDate', index: 41, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Reimbursement Approval Date', field: 'ReimbursementApprovalDate', index: 42, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Level Of Reimbursement', field: 'LevelOfReimbursement', index: 43, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Copayment Ratio', field: 'CopaymentRatio', index: 44, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Copayment Price', field: 'CopaymentPrice', index: 46, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursed Annotations', field: 'ReimbursedAnnotations', index: 47, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Launch Price', field: 'LaunchPrice', index: 48, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Therapy Class', field: 'TherapyClass', index: 55, isSelected: null, isDefault: false, isVisible: true },
    { header: 'WHOATC', field: 'Whoatc', index: 56, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Orphan Status', field: 'OrphanStatus', index: 57, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack ID', field: 'PackId', index: 61, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net Ex Factory', field: 'ExfactoryLessRebate', index: 62, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net Hospital Price', field: 'HospitalPriceLessRebate', index: 63, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net PPP', field: 'PppLessRebate', index: 64, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net PP Ex Vat', field: 'PpExVatLessRebate', index: 65, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Visible Net PP Inc Vat', field: 'PpIncVatLessRebate', index: 66, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pulse Comments', field: 'PulseComments', index: 67, isSelected: null, isDefault: false, isVisible: false },
];

export const gridViewCustomColumnCoT: GridViewCustomColumn[] = [
    { header: 'Country', field: 'Country', index: 1, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Brand name', field: 'Brand', index: 2, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Pack description', field: 'PackDescription', index: 3, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Top Level Indication', field: 'TopLevelIndication', index: 4, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Regulatory Indication', field: 'RegulatoryIndication', index: 7, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Indication Approval Date', field: 'IndicationApprovalDate', index: 8, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Availability Status', field: 'availabilityStatus', index: 2, isSelected: null, isDefault: false, isVisible: true },
    { header: 'INN', field: 'BrandInns', index: 5, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Company', field: 'Company', index: 6, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Currency', field: 'Currency', index: 9, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost per Pack ', field: 'PerPack', index: 10, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost per Unit ', field: 'PerUnit', index: 11, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost per MG ', field: 'PerMG', index: 12, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost per ML ', field: 'PerML', index: 13, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Dosing', field: 'Dosing', index: 14, isSelected: false, isDefault: false, isVisible: true },
    { header: 'Loading Dosing', field: 'ProductDosingLoading', index: 15, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Maintenance Dosing', field: 'ProductDosingMaintenance', index: 16, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Loading Dosing Frequency', field: 'FrequencyLoading', index: 17, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Loading Dosing Frequency Unit', field: 'FrequencyLoadingUnit', index: 18, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Maintenance Dosing Frequency', field: 'FrequencyMaintenance', index: 19, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Maintenance Dosing Frequency Unit', field: 'FrequencyMaintenanceUnit', index: 20, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Loading Dosing Duration', field: 'DurationLoading', index: 21, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Loading Dosing Duration Unit', field: 'DurationLoadingUnit', index: 22, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Maintenance Dosing Duration', field: 'DurationMaintenance', index: 23, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Maintenance Dosing Duration Unit', field: 'DurationMaintenanceUnit', index: 24, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Body Surface Area', field: 'BodySurfaceArea', index: 25, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Average Weight', field: 'AverageWeight', index: 26, isSelected: false, isDefault: false, isVisible: false, activatedByHeader: 'Dosing' },
    { header: 'Cost of treatment per Day ', field: 'PerDay', index: 27, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of treatment per Month  ', field: 'PerMonth', index: 28, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of treatment per Annum  ', field: 'PerAnnum', index: 29, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of Treatment Per Duration of Treatment', field: 'PerDurationOfTreatment', index: 30, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of Treatment Per Day Including Wastage', field: 'PerDayIncludingWastage', index: 31, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of Treatment Per Month Including Wastage', field: 'PerMonthIncludingWastage', index: 32, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of Treatment Per Annum Including Wastage', field: 'PerAnnumIncludingWastage', index: 33, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of Treatment Per Duration of Treatment Incl wastage', field: 'PerDurationOfTreatmentIncludingWastage', index: 34, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Cost of Treatment Per Cycle', field: 'PerCycle', index: 35, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pulse Comments', field: 'PulseComments', index: 36, isSelected: null, isDefault: false, isVisible: false },
];

export const gridViewCustomColumnHTA: GridViewCustomColumn[] = [
    { header: 'Country', field: 'Country', index: 1, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Brand name', field: 'Brand', index: 2, isSelected: null, isDefault: true, isVisible: true },
    { header: 'Top Level Indication', field: 'TopLevelIndication', index: 3, isSelected: null, isDefault: true, isVisible: true },
    { header: 'INN', field: 'BrandInns', index: 4, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Pack description', field: 'PackDescription', index: 5, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Company', field: 'Company', index: 6, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Indication Approval Date', field: 'IndicationApprovalDate', index: 7, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Line of Therapy', field: 'lineOfTherapy', index: 8, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Indication HTA', field: 'IndicationHta', index: 9, isSelected: null, isDefault: false, isVisible: true },
    { header: 'National HTA Body', field: 'NationalHtaBody', index: 10, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Decision', field: 'Decision', index: 11, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Assessment Publication Date', field: 'AssessmentPublicationDate', index: 12, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Clinical Reason', field: 'ClinicalReason', index: 13, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Economical Reason', field: 'EconomicalReason', index: 14, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Standardised Decision', field: 'StandardisedDecision', index: 15, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Utilisation Restrictions', field: 'UtilisationRestrictions', index: 16, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Comparator Drug HTA', field: 'ComparatorDrugHta', index: 17, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Reimbursement Classification (Formulary Status)', field: 'ReimbursedClassification', index: 18, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Regulatory Indication', field: 'RegulatoryIndication', index: 19, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Estimated Patient Number', field: 'EstimatedPatientNumber', index: 20, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Link To The Original Document', field: 'LinkToTheOriginalDocument', index: 21, isSelected: null, isDefault: false, isVisible: true },
    { header: 'Commercial Agreement', field: 'CommercialAgreement', index: 22, isSelected: null, isDefault: false, isVisible: true }

];
