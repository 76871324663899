export function chartCicleColorDecider(reimbursedClassification: string | null) {
  if (reimbursedClassification) {
    switch (reimbursedClassification.toUpperCase().trim()) {
      case 'NOT RECOMMENDED':
        return '#D84B44';
      case 'REIMBURSED WITH RESTRICTIONS':
        return '#DE9B37';
      case 'REIMBURSED':
        return '#4AA47A';
      default:
        return '#cccccc';
    }
  }
  return '#cccccc';
}
