<he-app-header></he-app-header>
<div class="project-cot-data-card">
  <mat-card-header>
    <div class="heading">
      <span class="heading-title">
        Project : {{ projectName }} Price Details
      </span>
    </div>
  </mat-card-header>

  <mat-card-content>
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef class="brand">
          Brand
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="brandRow">
          {{ element.brand }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef class="country">
          Country
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="countryRow">
          {{ element.country }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tli">
        <mat-header-cell *matHeaderCellDef class="tli"> TLI </mat-header-cell>
        <mat-cell *matCellDef="let element" class="tliRow">
          {{ element.tli }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inn">
        <mat-header-cell *matHeaderCellDef class="inn"> INN </mat-header-cell>
        <mat-cell *matCellDef="let element" class="innRow">
          {{ element.inn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isLaunchPriceChange">
        <mat-header-cell *matHeaderCellDef class="isLaunchPriceChange">
          Launch Price
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="isLaunchPriceChangeRow"
          matTooltip="Old : {{ element?.oldPrice?.launchPrice }} | New : {{
            element?.newPrice?.launchPrice
          }}"
        >
          {{ element.isLaunchPriceChange ? "Yes" : "No" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isReimbursedPriceChange">
        <mat-header-cell *matHeaderCellDef class="isReimbursedPriceChange">
          Reimbursed Price
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="isReimbursedPriceChangeRow"
          matTooltip="Old : {{ element?.oldPrice?.reimbursedPrice }} | New : {{
            element?.newPrice?.reimbursedPrice
          }}"
        >
          {{ element.isReimbursedPriceChange ? "Yes" : "No" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isCurrentPriceChange">
        <mat-header-cell *matHeaderCellDef class="isCurrentPriceChange">
          Current Price
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="isCurrentPriceChangeRow"
          matTooltip="Old : {{ element?.oldPrice?.currentPrice }} | New : {{
            element?.newPrice?.currentPrice
          }}"
        >
          {{ element.isCurrentPriceChange ? "Yes" : "No" }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        [ngClass]="{ highlight: row?.highlight === true }"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
    <mat-divider></mat-divider>
    <mat-paginator
      #paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
    <mat-divider></mat-divider>
    <mat-card-footer>
      <div class="example-button-row">
        <button
          mat-raised-button
          color="primary"
          (click)="updatePrice()"
          [disabled]="!isProjectPriceChanged"
        >
          Update Price
        </button>
        <button mat-raised-button color="warn" (click)="cancelTask()">
          Cancel
        </button>
      </div>
    </mat-card-footer>
  </mat-card-content>
</div>
