import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerRangeComponent } from './datepicker-range.component';
import { DatepickerModule } from '../datepicker';

@NgModule({
  declarations: [DatepickerRangeComponent],
  exports: [DatepickerRangeComponent],
  imports: [
    CommonModule,
    DatepickerModule,
  ]
})
export class DatepickerRangeModule {
}
