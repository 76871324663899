
<div class="summary-value-scoring-grid">
<mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter">
  <!-- Branded INN Name Column -->
  <ng-container matColumnDef="brandedInnName">
    <mat-header-cell *matHeaderCellDef> Brand Name </mat-header-cell>
    <mat-cell *matCellDef="let element;let i = index" [ngClass]="{ highlight: i < analoguesData.length }">
     {{ element.brandedInnName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="inn">
    <mat-header-cell *matHeaderCellDef> INN </mat-header-cell>
    <mat-cell *matCellDef="let element;let i = index" [ngClass]="{ highlight: i < analoguesData.length }">
     {{ element.inn }}
    </mat-cell>
  </ng-container>
  <!-- dynamic countries -->
  <ng-container *ngFor="let country of countries" [matColumnDef]="country">
    <mat-header-cell *matHeaderCellDef>
      <he-country-cell [country]="country"></he-country-cell
    ></mat-header-cell>
    <mat-cell *matCellDef="let element;let i = index" [ngClass]="{ highlight: i < analoguesData.length }">
      {{ element[country] }}
    </mat-cell>
  </ng-container>  
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    [ngClass]="{ highlight: row?.highlight === true }"
    *matRowDef="let row; columns: displayedColumns"
  ></mat-row>
</mat-table>
</div>