import { DialogText, TooltipText } from './../../../common/constants';
import { Subject, takeUntil, timer, take, distinctUntilChanged, skip } from 'rxjs';
import {
  FilterItemValueInterface,
  FilterNameEnum,
  FiltersObject,
} from '../../interfaces';
import { FilterPresetService } from './filter-preset.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FiltersValueService } from '../../filters-value.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { FilterPresetDefaults, FilterPresetKeyNames } from './constants';
import { FilterPreset } from './interfaces';
import { FilterBrandPresetTypes, FilterPresetTypes, FilterStatuses } from './enums';
import { MatSelect } from '@angular/material/select';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

interface IFilterPresetValidatorCollection {
  [key: string]: IFilterPresetValidator;
}

interface IFilterPresetValidator {
  [key: string]: any
}


@Component({
  selector: 'he-filter-preset',
  templateUrl: './filter-preset.component.html',
  styleUrls: ['./filter-preset.component.scss'],
})
export class FilterPresetComponent implements OnInit, OnDestroy {
  @Input() presetKeyName!: string;
  @Input() public saveConfirmationText: string =
    DialogText.DefaultPresetSaveMessage;
  @Input() public deleteConfirmationText: string =
    DialogText.DefaultPresetDeleteMessage;
  @Input() public savePresetTooltip: string =
    TooltipText.DefaultPresetSaveTooltip;
  @Input() public deletePresetTooltip: string =
    TooltipText.DefaultPresetDeleteTooltip;
  @Input() hasSaveButton = true;
  @Input() hasDeleteButton = true;

 
  @Output() filtersValueModified: EventEmitter<FiltersObject> =
    new EventEmitter();
    @Output() changefilterValues: EventEmitter<FiltersObject> =
    new EventEmitter();
    @Output() removeSelectedBrands: EventEmitter<FiltersObject> =
    new EventEmitter();   
    @Output() selectedItemChanges: EventEmitter<FilterItemValueInterface> = new EventEmitter(); 

  @ViewChild('presetMenuTrigger') presetMenuTrigger!: MatMenuTrigger;
  @ViewChild('presetDropdown') presetDropdown!: MatSelect;

  items: FilterItemValueInterface[] = [];
  public selectedPreset: FilterItemValueInterface | undefined;
  public selectedPresetToDelete: FilterItemValueInterface | undefined;
  defaultPresets: FilterPreset[] = [];
  presets: FilterPreset[] = [];
  presetKeyNames: string[] = [];
  isSettingIconVisible=true;
  pendingConfirmationToClear = false;
  showSaveButton = false;
  showDeleteButton = false;
  isDefaultFilterSelected = false;
  isDeleteConfirmation = false;
  confirmationMessage: string = '';

  dropdownOpen: boolean = false;
  selectedDropdownValue: string = '';

  private readonly destroying$: Subject<void> = new Subject<void>();
  FilterStatuses = FilterStatuses
  FilterBrandPresetTypes=FilterBrandPresetTypes;
  FilterPresetTypes=FilterPresetTypes;
 
  constructor(
    private filterPresetService: FilterPresetService,
    public filtersValueService: FiltersValueService,
    public sharedService:SharedService,
    public router:Router,
    public route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.defaultPresets = FilterPresetDefaults[this.presetKeyName] ?? [];
    this.presetKeyNames = FilterPresetKeyNames[this.presetKeyName] ?? [];
    this.subscribeFilterPresets();
    this.subscribeFilterValues();
    
    
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  subscribeFilterValues() {
    this.filtersValueService.filterValues$
      .pipe(takeUntil(this.destroying$),
       )
      .subscribe((filterObj:FiltersObject) => {
        let isFilterUnchanged = true;
        if(this.selectedPreset)
        {
          isFilterUnchanged = this.isPresetEqualFilter(filterObj, this.selectedPreset.type, this.presetKeyNames)
        }

        this.showSaveButton = !isFilterUnchanged;
      });
  }

  private isPresetEqualFilter(filterObject: FiltersObject, preset: FiltersObject, presetKeyNames: string[]) {
    if(!preset)
      return false;

    const equalFilters = presetKeyNames.filter((p) => {
      let filterValue = filterObject.find(
        (item) => item.filterName === p
      )?.filterValue;

      let defaultFilterValue = preset.find(
        (item) => item.filterName === p
      )?.filterValue[0];

      return (
        filterValue?.filter(
          (f) => f.type !== defaultFilterValue?.type || f.name !== defaultFilterValue?.name
        ).length === 0
      );
    });

    return equalFilters.length === presetKeyNames.length;
  }

  private isDefaultFilterName(filterName: string): boolean {
    return this.defaultPresets.filter((f) => f.name === filterName).length > 0;
  }

  public onDropdownValueChange() {
    timer(1).pipe(takeUntil(this.destroying$)).subscribe(() => {
      this.itemSelectEvent(this.items.find(i => i.name === this.selectedDropdownValue))
      if(this.presetKeyName===FilterBrandPresetTypes.Brand ) 
      { 
       this.changefilterValues.emit(this.filterValues);
      }
      this.presetMenuTrigger.closeMenu();
    })

    this.sharedService.showBrandPrefSaveIcon$.next(false);
    this.sharedService.isBrandPreferenceValChanged$.next(false);
  }

  public dropdownOpenedChange($event: any) {
    if(!$event)
      this.presetMenuTrigger.closeMenu();
  }

  private checkDefaultFilterSelected(name: string = '') {
    if(this.presetKeyName!=FilterBrandPresetTypes.Brand )
    this.isDefaultFilterSelected =
      this.defaultPresets.length > 0 && this.isDefaultFilterName(name);
  }

  savePreset(name: string) {
    var preset;
    if(this.presetKeyName!=FilterBrandPresetTypes.Brand)
    {
        preset = this.filtersValueService.filterValues$.value.filter((f) =>
          this.presetKeyNames.includes(f.filterName)
        );
    }
    else
    {
       preset = this.filtersValueService.filterValues$.value.filter((f) =>
          f.filterName=='brands'
        );
    }


    this.presets = [
      {
        name,
        preset: preset,
      },
      ...this.presets.filter((f) => f.name !== name),
    ];

    this.selectedPreset = {
      name,
      type: preset,
    };
    this.selectedDropdownValue = name;

    this.checkDefaultFilterSelected(name);
    this.showSaveButton = false;
    this.sharedService.showBrandPrefSaveIcon$.next(false);
    this.sharedService.isBrandPreferenceValChanged$.next(false);
    this.savePresets();
  }


  deletePreset(name: string) {
    this.presets = [...this.presets.filter((f) => f.name !== name)];

    this.savePresets();
  }

  selectNextPreset() {
    const nextPreset = this.defaultPresets.length > 0
      ? {
        name: this.defaultPresets[0].name,
        type: this.defaultPresets[0].preset,
      }
      : this.presets.length > 0
        ? {
          name: this.presets[0].name,
          type: this.presets[0].preset,
        }
        : undefined;

    this.itemSelectEvent(nextPreset);
  }

  savePresets() {
    this.filterPresetService.savePreset(this.presetKeyName, this.presets);
  }

  openSavePresetDialog() {
    this.pendingConfirmationToClear = true;
  }

  openDeletePresetDialog(event: Event, item: FilterItemValueInterface) {
    event.stopPropagation();
    this.selectedPresetToDelete = item;
    this.isDeleteConfirmation = true;
    this.pendingConfirmationToClear = true;
  }

  public onRejectDialog(): void {
    this.resetDialog();
  }

  public onConfirmDialog(presetName: string): void {
    if (this.isDeleteConfirmation) {
      if (this.selectedPresetToDelete?.name === presetName &&  this.presetKeyName!=FilterBrandPresetTypes.Brand)
      {
        this.selectNextPreset();
        this.presetDropdown.close();
      }
      else if(this.selectedPresetToDelete?.name === presetName &&  this.presetKeyName==FilterBrandPresetTypes.Brand && !this.sharedService.isBrandPreferenceValChanged$.value)
      {
         this.removeSelectedBrands.emit();
      }
      else if(this.selectedPresetToDelete?.name === presetName &&  this.presetKeyName==FilterBrandPresetTypes.Brand && this.sharedService.isBrandPreferenceValChanged$.value)
      {
        this.sharedService.selectedBrandPreferenceVal$.next('');
      }
      this.deletePreset(this.selectedPresetToDelete?.name);
      this.resetDialog();

      if(this.items.length <= 1) {
        this.presetDropdown.close();
      }
    } else {
      if (this.validateSaveDialog(presetName)) {
        this.sharedService.selectedBrandPreferenceVal$.next(presetName);
        this.savePreset(presetName);
        this.resetDialog();
      }
    }
  }

  validateSaveDialog(presetName: string): boolean {
    presetName = presetName.trim();
    if (this.presets.length - this.defaultPresets.length > 100) {
      this.confirmationMessage = 'Limit of 100 saved preferences reached';
      return false;
    }
    if (presetName.length <= 0) {
      this.confirmationMessage = 'Name cannot be empty';
      return false;
    }
    if (presetName.length > 50) {
      this.confirmationMessage = 'Name cannot be longer than 50 characters';
      return false;
    }
    if (this.isDefaultFilterName(presetName)) {
      this.confirmationMessage = `Name cannot be '${presetName}'`;
      return false;
    }

    return true;
  }

  private resetDialog() {
    this.selectedPresetToDelete = undefined;
    this.confirmationMessage = '';
    this.isDeleteConfirmation = false;
    this.pendingConfirmationToClear = false;
  }

  subscribeFilterPresets() {
    this.filterPresetService.filterPresets
      .pipe(takeUntil(this.destroying$))
      .subscribe((filterPresetCollection) => {
        if (this.filterPresetService.userPreferencesRetrieved) {
          const presets = filterPresetCollection.filter(
            (f) => f.type === this.presetKeyName
          );
          if (presets) {
            this.presets = presets[0].presets;
          }
             this.setItems();

             if(this.presetKeyName === FilterBrandPresetTypes.Brand && this.sharedService.IsBrandPrefSelectedInMainSearch$.value && this.sharedService.selectedSearchValue$.value!='')
                {
                  this.sharedService.IsBrandPrefSelectedInMainSearch$.next(false);
                  this.selectItem(this.sharedService.selectedSearchValue$.value);
                }
        }
      });
  }

  setItems() {
    this.items = [
      ...this.defaultPresets.map(p => this.convertPresetToDropdownItem(p, FilterStatuses.Default)),
      ...this.presets.map(p => this.convertPresetToDropdownItem(p)),
    ];
    if (this.selectedPreset && this.selectedPreset.type) {
      this.checkDefaultFilterSelected(this.selectedPreset.name);
    } else if (this.items.length > 0  &&  this.presetKeyName!=FilterBrandPresetTypes.Brand) {
      this.itemSelectEvent(this.items[0]);
    } else {
      this.itemSelectEvent();
    }
  }
  
  selectItem(test:string):void{
   this.selectedDropdownValue = test;
   this.sharedService.selectedBrandPreferenceVal$.next(test)
   this.sharedService.showBrandPrefSaveIcon$.next(false);
   this.sharedService.isBrandPreferenceValChanged$.next(false);
   this.onDropdownValueChange();
  }

  convertPresetToDropdownItem(preset: FilterPreset, status: string | undefined = undefined) {
    return {
      name: preset.name,
      type: preset.preset,
      status
    };
  }

  filterValues:any[]=[];
  itemSelectEvent(
    item: FilterItemValueInterface | undefined = undefined , emitVal? : boolean
  ): void {
    const preset = item?.type;
    if (preset) {
      this.selectedPreset = item;
      this.filterValues = this.filtersValueService.filterValues$.value;
      this.selectedDropdownValue = item.name;
      preset.forEach((p: any) => {
        this.filterValues = this.filtersValueService.updateItemInFilters(
          p.filterName,
          p.filterValue,
          this.filterValues
        );
      });  
     
      if(this.presetKeyName!=FilterBrandPresetTypes.Brand )
      {
        this.filtersValueModified.emit(this.filterValues);
         this.checkDefaultFilterSelected(item.name);
      }
      else{
        this.changefilterValues.emit(this.filterValues);

        let brandsObj: FilterItemValueInterface[] =this.filtersValueService.findItemInFilters(this.filterValues, FilterNameEnum.brands)?.filterValue || [];
         if(brandsObj.length==1)
        {
          this.sharedService.showBrandPrefSaveIcon$.next(false);
          this.selectedItemChanges.emit(brandsObj[0]);
        }

      }
    } else {
      if(this.presetKeyName!=FilterBrandPresetTypes.Brand )
      {
      this.selectedPreset = {
        name: '',
        type: null,
      };
      this.checkDefaultFilterSelected();
    }
    }
  }

  restUrl(brandCount: number, filterItemValue: FilterItemValueInterface) {
    if (!(filterItemValue)) return;
    if (brandCount == 1)
     {
      const stateObj = filterItemValue;
      const params = {
        s: stateObj.name,
        t: stateObj.type,
        id: stateObj.id,
        ts: (new Date()).getTime(),
      };
      this.router.navigate([window.location.pathname], {
        queryParams: params,
        replaceUrl: true
      });
    }
  }

}
