import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityFilter } from '../../../core/entity-filter';
import { AddUserPreference } from '../../../core/add-user-preference';
import { map, tap } from 'rxjs/operators';
import { FilterByCountryApi, AddUserPreferenceApi } from '../../../helios-api/filters';
import { FilterAutocompleteDescriptionInterface } from '../../filter-autocomplete-with-chips/interfaces';
import { FilterItemValueInterface, FiltersObject } from '../interfaces';
import { FilterNameEnum } from '../enum';
import { FiltersValueService } from '../filters-value.service';

@Component({
  selector: 'he-filter-by-country',
  templateUrl: './filter-by-country.component.html',
  styleUrls: ['./filter-by-country.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterByCountryApi,
    {
      provide: EntityFilter,
      useFactory: (gateway: FilterByCountryApi) => {
        return new EntityFilter(gateway);
      },
      deps: [FilterByCountryApi]
    },
    AddUserPreferenceApi,
    {
      provide: AddUserPreference,
      useFactory: (gateway: AddUserPreferenceApi) => {
        return new AddUserPreference(gateway);
      },
      deps: [AddUserPreferenceApi]
    }
  ]
})
export class FilterByCountryComponent {
  @Input() filtersValue!: FiltersObject;
  @Input() hasSaveButton = true;
  @Input() hasResetButton = false;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  public descriptions: FilterAutocompleteDescriptionInterface = {
    title: 'Countries and regions',
    emptyState: 'No results found',
    placeholder: 'Start typing to select',
    errorMessage: 'At least one country should be selected to display information'
  };
  public filteredItems$: Observable<FilterItemValueInterface[]> | undefined;
  public loading = false;
  public showSaveButton = false;
  pendingConfirmationToClear = false;

  constructor(
    private entityFilter: EntityFilter,
    public filtersValueService: FiltersValueService,
    private addUserPreference: AddUserPreference
  ) {}

  get selectedItems(): FilterItemValueInterface[] {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.countries).filterValue || [];
  }

  get isSaveButtonVisible(): boolean {
    return this.hasSaveButton && this.showSaveButton;
  }

  get isResetButtonVisible(): boolean {
    return this.hasResetButton && !!this.selectedItems.length;
  }

  public onRejectClear(): void {
    this.pendingConfirmationToClear = false;
  }
  
  public onConfirmClear(): void {
    this.resetFilter();
    this.pendingConfirmationToClear = false;
  }

  public selectedItemChanges(country: any): void {
    let countries: any;
    if (country.hasOwnProperty('abbr')){
      countries = country.countryList;
    }else {
      countries = [country];
    }

    let filterItemValue: FilterItemValueInterface[] = this.filtersValueService.findItemInFilters(this.filtersValue,
      FilterNameEnum.countries)?.filterValue || [];

    const filteredCountries = countries.filter((countryObj: any) =>
      !filterItemValue.some(item => (item.id === countryObj.id)));

    if (filteredCountries.length > 0) {
      this.showSaveButton = true;
      filterItemValue = [...filterItemValue, ...filteredCountries];
      const nextValue: FiltersObject = this.filtersValueService.updateItemInFilters(FilterNameEnum.countries, filterItemValue);
      this.filtersValueModified.emit(nextValue);
    }
  }

  public deletedItemChanges(country: FilterItemValueInterface): void {
    this.showSaveButton = true;
    this.filtersValueModified.emit(this.filtersValueService.deletedItemInFilters(country, FilterNameEnum.countries, this.filtersValue));
  }

  public saveAsDefault(selectedItem: any): void {
    this.addUserPreference.run({
      payload: selectedItem
    }).toPromise();
    this.showSaveButton = false;
  }

  resetFilterClicked(): any {
    this.pendingConfirmationToClear = true;
  }

  resetFilter(): any {
    this.filtersValueModified.emit(
      this.filtersValueService.updateItemInFilters(FilterNameEnum.countries, [])
    );
    this.showSaveButton = false;
  }

  public onQueryChange(value: string): void {
    this.loading = value.trim().length >= 2;
    this.filteredItems$ = this.entityFilter.run({
      payload: {
        query: value
      }
    }).pipe(
      tap(() => this.loading = false),
      map((items: any[]): any[] => {
        let results: any[] = [];
        const withAbbr: any[] = [];
        const withoutAbbr: any[] = items.filter((item) => item.abbreviation === null);

        for (const item of items) {
          if (item.abbreviation !== null){
            if (withAbbr[item.abbreviation] === undefined) {
              withAbbr[item.abbreviation] = {
                abbr: item.abbreviation,
                countryList: []
              };
            }

            withAbbr[item.abbreviation].countryList.push(item);
          }
        }

        results = withoutAbbr;
        for (const item in withAbbr) {
          if (Object.prototype.hasOwnProperty.call(withAbbr, item)) {
            results.push(withAbbr[item]);
          }
        }
        return results;
      })
    );
  }
}
