import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterMeasuresAverageWeightComponent } from './filter-measures-average-weight.component';
import { FilterSliderModule } from 'projects/helios-gui/src/uikit/filter-slider/filter-slider.module';


@NgModule({
  declarations: [FilterMeasuresAverageWeightComponent],
  exports:[FilterMeasuresAverageWeightComponent],
  imports: [
    CommonModule,
    FilterSliderModule
  ]
})
export class FilterMeasuresAverageWeightModule { }
