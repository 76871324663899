<div *ngIf="!showSection">
  <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
</div>
<div *ngIf="showSection" class="analogue-timeline">
  <div class="timeline-option">
    <mat-form-field appearance="fill" style="width: 155px">
      <mat-select
        [value]="selectedOption"
        (selectionChange)="changeOption($event.value)"
      >
        <mat-option *ngFor="let option of timelineOptions" [value]="option">{{
          option
        }}</mat-option>
      </mat-select>
      <img
        matPrefix
        *ngIf="viewMode === 'Country'"
        src="assets/flags/small/{{countryFlagService.getFlagId( selectedOption) }}.svg"
        width="22px"
      />
    </mat-form-field>
  </div>
  <div class="timeline">
    <div class="timeline-list" *ngFor="let analogue of TimelineList">
      <div class="analogue-timeline-details analogue-top">
        <div>
          <p class="title">{{ analogue.name }}</p>
          <p class="sub-title" *ngIf="viewMode === 'Country'">
            {{ analogue.approvalName }} Approval:
          </p>
          <p class="timeline-value">{{ analogue.approval }}</p>
          <p class="sub-title" *ngIf="viewMode === 'Country'">
            Reimbursement decision:
          </p>
          <p class="timeline-value" *ngIf="viewMode === 'Country'">
            {{ analogue.reimburse }}
          </p>
        </div>
      </div>
      <div class="analogue-pointer pointer-top"></div>
      <div class="timeline-points">
        <div class="timeline-line"></div>
        <div class="circle-border" *ngIf="viewMode === 'Country'">
          <div
            class="timeline-circle lower-opacity"
            [ngStyle]="{ 'background-color': analogue.color }"
          ></div>
        </div>
        <div class="circle-border" *ngIf="viewMode === 'Analogue'">
          <div
            class="timeline-circle time-line-icon"
            *ngIf="viewMode === 'Analogue'"
          >
            <img src="{{ analogue.img }}" height="17px" />
          </div>
        </div>
        <div class="timeline-line line-end"></div>
      </div>
      <div class="analogue-pointer pointer-bottom"></div>
      <div class="analogue-timeline-details analogue-bottom">
        <p class="title">{{ analogue.name }}</p>
        <p class="sub-title" *ngIf="viewMode === 'Country'">
          {{ analogue.approvalName }} Approval:
        </p>
        <p class="timeline-value">{{ analogue.approval }}</p>
        <p class="sub-title" *ngIf="viewMode === 'Country'">
          Reimbursement decision:
        </p>
        <p class="timeline-value" *ngIf="viewMode === 'Country'">
          {{ analogue.reimburse }}
        </p>
      </div>
    </div>
  </div>
</div>
