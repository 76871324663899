import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { UserProfile } from './profile/menu/user.profile';
import { PlatformUserAuthorizationDataService } from './platform-user-authorization/platform-user-authorization-data.service';
import { FeatureFlagService } from './feature-flag/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class MemberAuthGuard implements CanActivate {
 
  private readonly destroying$ = new Subject<void>();

  constructor(
    private currentProfile: UserProfile,
    private platformUserAuthService: PlatformUserAuthorizationDataService,
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const loggedInUserEmail = this.currentProfile.getEmail();
    let featureFlag:boolean=false;
    return this.featureFlagService
    .get2fapulseFlag(this.destroying$)
    .then((flagValue: boolean) => {
      featureFlag = flagValue;
      if(featureFlag){
        return this.platformUserAuthService.isAuthorizationValid(loggedInUserEmail);
      }else{
        return true
      }
    });
 
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
