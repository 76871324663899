import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FilterByAtcApi {
    constructor(private httpClient: HttpClient) {
    }

    filter(req: unknown): Observable<unknown> {
        return this.httpClient.post<unknown>('/filter/brands/forAtc', req);
    }
}
