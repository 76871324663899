import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

enum PrevalenceLevel {
  Lowest = '0 - 100',
  Low =  '0 - 1000',
  Medium = '1 - 10000',
  High = '1 - 100000'
}

@Component({
  selector: 'he-dot-cell',
  templateUrl: './dot-cell.component.html',
  styleUrls: ['./dot-cell.component.scss']
})
export class DotCellComponent implements ICellRendererAngularComp {
  palette: string[] = ['green', 'yellow', 'red'];
  public prevalenceLevel: PrevalenceLevel | any;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.prevalenceLevel = params.value;
  }

  public setColor(level: PrevalenceLevel): string {
    switch (level) {
      case PrevalenceLevel.Medium:
        return this.palette[1];
      case PrevalenceLevel.High:
        return this.palette[2];
      default:
        return this.palette[0];
    }
  }
}
