import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { AuthenticationResult, AuthError } from '@azure/msal-common';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SessionEnums } from '../../common';
import { SessionStoreBase } from '../../common/session-store-service';
import { PlatformUserAuthorizationDataService } from '../../platform-user-authorization/platform-user-authorization-data.service';

interface IdTokenClaims extends AuthenticationResult {
  idTokenClaims: {
    acr?: string
  };
}

@Component({
  selector: 'he-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  isIframe = false;
  loggedIn = false;
  username = '';
  private readonly destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private session: SessionStoreBase,
    private platformUserAuthService : PlatformUserAuthorizationDataService
  ) { }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) => {

        const payload: IdTokenClaims = result.payload as AuthenticationResult;

        // We need to reject id tokens that were not issued with the default sign-in policy.
        // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
        // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview

        if (payload.idTokenClaims?.acr === environment.b2cPolicies.names.forgotPassword) {
          window.alert('Password has been reset successfully. \nPlease sign-in with your new password.');
          return this.authService.logout();
        } else if (payload.idTokenClaims.acr === environment.b2cPolicies.names.editProfile) {
          window.alert('Profile has been updated successfully. \nPlease sign-in again.');
          return this.authService.logout();
        }

        this.checkAccount();
        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error && result.error.message) {
          // Check for cancel password reset error
          if (result.error.message.includes('AADB2C90091')) {
            const loginRequest: RedirectRequest = {
              scopes: [...environment.apiConfig.scopes]
            };
            this.login(loginRequest);
          }
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          else if (result.error.message.includes('AADB2C90118')) {

            // login request with reset authority
            const resetPasswordFlowRequest = {
              scopes: ['openid'],
              authority: environment.b2cPolicies.authorities.forgotPassword.authority,
            };

            this.login(resetPasswordFlowRequest);
          }
        }
      });
  }

  checkAccount(): void {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    if (this.loggedIn) {
      this.username = this.authService.instance.getAllAccounts()[0].name || '';
    }
    else {
      this.username = '';
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe(() => this.checkAccount());
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe(() => this.checkAccount());
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }

  }

  logout(): void {
    this.platformUserAuthService.removePlatformUserAuthCodeFromSession();
    this.session.removeSession(SessionEnums.brands);
    this.session.removeSession(SessionEnums.indications);
    this.session.removeSession(SessionEnums.indicationsAllowed);
    this.authService.logout();
  }

  editProfile(): void {
    const editProfileFlowRequest = {
      scopes: ['openid'],
      authority: environment.b2cPolicies.authorities.editProfile.authority,
    };

    this.login(editProfileFlowRequest);
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  getUserInitials(): string {
    return this.username.split(/\s+/g).map((word) => word[0]).join('').toUpperCase();
  }
}
