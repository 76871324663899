import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'he-css-utilities',
  templateUrl: './css-utilities.component.html',
  styleUrls: ['./css-utilities.component.scss']
})
export class CssUtilitiesComponent {
}
