import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';

import { UniversalSearchApi } from '../../helios-api/universal-search/universal-search.api';
import { UniversalSearch } from '../../core/universal-search';
import { LoadingSpinnerModule } from '../../uikit/loading-spinner';
import { IconModule } from '../../uikit/helios-icons';
import { HighlightTextModule } from '../../uikit/highlight-text';

import { UniversalSearchComponent } from './universal-search.component';
import { UniversalSearchContext } from './universal-search.context';
import { UniversalSearchService } from './universal-search.service';
import { GroupByTypePipe } from './group-by-type.pipe';
import { CommandModule } from '../../uikit/command'

@NgModule({
  declarations: [
    UniversalSearchComponent,
    GroupByTypePipe
  ],
  exports: [
    UniversalSearchComponent
  ],
  imports: [
    CommonModule,
    HighlightTextModule,
    IconModule,
    LoadingSpinnerModule,
    MatAutocompleteModule,
    MatOptionModule,
    RouterModule,
    CommandModule
  ],
  providers: [
    UniversalSearchApi,
    {provide: UniversalSearchContext, useExisting: UniversalSearchContext},
    {provide: UniversalSearchService, useExisting: UniversalSearch},
    {provide: UniversalSearch, useFactory: UniversalSearch.create, deps: [UniversalSearchApi]}
  ]
})
export class UniversalSearchModule {
}
