import { Injectable } from '@angular/core';
import { Feature } from '../models/Feature';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  data: Feature[] = [];

  constructor(private http: HttpClient) {
  }

  getFeatureFlagData(): Promise<Feature[]> {
    return new Promise((resolve, reject) => {
      if (this.data.length !== 0){
        resolve(this.data);
      }
      else{
        this.http.get<Feature[]>(`${this.projectAPIUri}/Master/GetFeatureFlags`, {
          // this is added to avoid caching of this api
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0'
          },
        }).subscribe((FeatureFlagdata: Feature[]) => {
          this.data = FeatureFlagdata;
          resolve(FeatureFlagdata);
        }, (error) => {
          reject(error);
        });
      }

    });
  }
}
