import { Component, OnInit, ViewChild } from '@angular/core';
import { PriceInclusionComponent } from '../price-inclusion/price-inclusion.component';
import { OverallPriceAssumptionComponent } from '../overall-price-assumption/overall-price-assumption.component';
import { ProjectService } from '../../services/project.service';
import { Subject, take, takeUntil } from 'rxjs';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { NetPriceService } from '../../services/net-price.service';
import {
  NetPriceInterface,
  OverallNetPriceInterface,
} from '../../models/netprice.interface';
import { CurrencyConversionService } from '../../../../horizon-project/services/currency-conversion.service';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { UpdateFrameworkService } from '../../../../shared/services/update-framework.service';

@Component({
  selector: 'he-net-price-assumption',
  templateUrl: './net-price-assumption.component.html',
  styleUrls: ['./net-price-assumption.component.scss'],
})
export class NetPriceAssumptionComponent implements OnInit {
  @ViewChild(PriceInclusionComponent)
  priceInclusionComponent?: PriceInclusionComponent;
  @ViewChild(OverallPriceAssumptionComponent)
  overallPriceAssumptionComponent?: OverallPriceAssumptionComponent;

  public firstPanelOpen = true;
  public secondPanelOpen = false;
  public loading = false;
  public infoData:any[]=[]
  public ProjectNetPrice: NetPriceInterface = {
    IsNetPriceIncluded: false,
    isAnnualCOTInlcuded: false,
    OverallNetPrice: [],
    AnalogueNetPrice: [],
    AnaglueChanged: false,
  };
  public selectedRoute:string='Analgoues'

  private unsubscriber$ = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private countryFlagService: CountryFlagService,
    private netPriceService: NetPriceService,
    private currencyConversionService: CurrencyConversionService,
    private toastNotificationService: ToastNotificationService,
    private updateFrameworkService: UpdateFrameworkService,
  ) {}
  ngOnInit():void{
    this.selectedRoute = this.projectService.createProjectFormData.value.comparator === 'true'?'comparator':'analogue',
    this.getInfo()
    this.ProjectNetPrice = this.netPriceService.ProjectNetPrice;
    this.ProjectNetPrice.OverallNetPrice.sort((a:any,b:any)=> a.country.localeCompare(b.country))
    // this.getCreateProjectDetails();
    this.checkError();
  }

  public checkError():void{
    this.netPriceService.castnetPriceChange.pipe(takeUntil(this.unsubscriber$))
 .subscribe(res=>{
      this.netPriceService.setToastError(this.selectedRoute);
    })
  }

  public getCreateProjectDetails(): void {
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .pipe(takeUntil(this.unsubscriber$))
 .subscribe( (data) => {
         data?.selectedMarkets?.forEach(async (market: string) => {
          if(!this.ProjectNetPrice.OverallNetPrice.some((x:OverallNetPriceInterface) => x.country === market)){
            
            let currency = await this.currencyConversionService.getCountryCurrencyData(
              market
             )
             currency = this.currencyConversionService.convertSymbol(currency.symbol)
            this.ProjectNetPrice.OverallNetPrice.push({
              country:market,
             id:this.countryFlagService.getFlagId(market),
             NetPriceDiscount:(0).toFixed(2),
             info:this.infoData.filter((y:any)=> y.country === market)[0].description,
             currency:currency
            });
            this.ProjectNetPrice.OverallNetPrice.sort((a:any,b:any)=> a.country.localeCompare(b.country))

          }else{
            this.ProjectNetPrice.OverallNetPrice.filter((y:any)=> y.country === market)[0].info = this.infoData.filter((y:any)=> y.country === market)[0].description
          }
        });

        let newPrices = JSON.parse(
          JSON.stringify(this.ProjectNetPrice.OverallNetPrice)
        );

        newPrices.forEach((market: OverallNetPriceInterface,index:number) => {
          if(!data?.selectedMarkets.some((x:any) => x === market.country)){
            let newIndex = this.ProjectNetPrice.OverallNetPrice.findIndex((x:any)=>x.Country === market.country)
            this.ProjectNetPrice.OverallNetPrice.splice(newIndex,1)
          }
        })
    // this.setToastError();

        // this.ProjectNetPrice.OverallNetPrice.forEach(res=>{
        //   res.info = this.infoData.filter((y:any)=> y.country === res.country)[0].description
        // })
      });
      // this.ProjectNetPrice.OverallNetPrice.forEach(async market=>{
      //   let currency = await this.currencyConversionService.getCountryCurrencyData(
      //     market.country
      //    )
      //    currency = this.currencyConversionService.convertSymbol(currency.symbol)
      //    market.currency = currency;
      // })
    // this.setPrice(false)

  }

  public getInfo(){
    this.netPriceService.getCountryNetPriceInfo().pipe(takeUntil(this.unsubscriber$))
 .subscribe(res=>{
       this.infoData = res.data;
      this.getCreateProjectDetails()

    })
  }

  public reset(): void {
    // this.priceInclusionComponent?.reset()
    this.ProjectNetPrice.IsNetPriceIncluded = false;
    this.setPrice(!this.ProjectNetPrice.AnaglueChanged);
  }

  public resetOverall(): void {
    // this.overallPriceAssumptionComponent?.reset()
    this.ProjectNetPrice.OverallNetPrice.forEach((market: any) => {
      market.NetPriceDiscount = '';
    });
    this.setPrice(!this.ProjectNetPrice.AnaglueChanged);
  }
  public resetAnalogue(resetAnalgoues: Boolean): void {
    this.ProjectNetPrice.AnalogueNetPrice.forEach((x) => {
      this.ProjectNetPrice.OverallNetPrice.forEach((y) => {
        if (x[y.country].NetPriceDiscount != 'NA') {
          x[y.country].NetPriceDiscount = y.NetPriceDiscount;
          if (resetAnalgoues) {
            x[y.country].AnalogueCOT = '';
          }
        }
      });
    });
    this.netPriceChanges();
  }

  public updatePriceInclusion(value: boolean): void {
    this.ProjectNetPrice.IsNetPriceIncluded = value;
    this.secondPanelOpen = value;
    this.setPrice(false);
    // this.setToastError()
  }

  public setPrice(resetAnalgoues: boolean): void {
    this.ProjectNetPrice.AnaglueChanged = false;
    this.netPriceChanges();
    this.resetAnalogue(resetAnalgoues);
  }

  public selectToggle(value: boolean): void {
    this.ProjectNetPrice.isAnnualCOTInlcuded = value;
    this.netPriceChanges();
    // this.setToastError();
  }



  public netPriceChanges():void{
    this.netPriceService.ProjectNetPrice = this.ProjectNetPrice;
    this.netPriceService.netPriceChange.next(this.ProjectNetPrice);
    this.netPriceService.valueChanged = true;
    this.projectService.valueChangeStatus.next(true);
    this.projectService.netPriceChanged = true
    this.projectService.newValuechanged.next(true)
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
