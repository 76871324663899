import { Observable } from 'rxjs';

export interface MessageRef {
  payload: unknown;
}

export interface Message {
  type: string;
  payload: any;
  error?: boolean;
}

export abstract class CreateProjectService {
  abstract run(event: any): Observable<Message>;
}

export abstract class UpdateProjectService {
  abstract run(event: any): Observable<Message>;
}

