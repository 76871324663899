import { asyncScheduler, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, startWith, subscribeOn } from 'rxjs/operators';
import { Assert } from '../assert';
import { Gateway } from '../gateway';
import { PackDetailsInput, PackDetailsOutput, PackDetailsOutputMessage } from './interfaces';
export class PackDetails {
  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${gateway} has no "transfer" method`);
  }

  static create(gateway: Gateway): PackDetails {
    return new PackDetails(gateway);
  }

  run(event: PackDetailsInput): Observable<PackDetailsOutputMessage> {
    return this.gateway.transfer(event.payload).pipe(
      map((response:PackDetailsOutput ) => {
        return {
          type: 'done',
          payload: response.result,
          totalRows: response.totalRows
        };
      })as OperatorFunction<unknown, { type: string; payload: unknown; totalRows: number | undefined; }>,
      catchError((err) => {
        return of({
          type: 'fail',
          error: true,
          payload: err
        });
      }),
      startWith({
        type: 'pending',
        payload: undefined
      }),
      subscribeOn(asyncScheduler)
    );
  }
}
