<mat-nav-list fxFlex *ngIf="sideBarOpen">
    <div class="flex-container" fxLayout="row" fxLayoutAlign="flex-start center">
        <h3 class="sidebar-title">Manage Analogue Data</h3>
        <button mat-icon-button (click)="toggleSideBar()">
            <mat-icon>chevron_left</mat-icon>
          </button>
    </div>
    <mat-divider></mat-divider>

    <div id="project-list-container" *ngFor="let menu of uploadMenu">
    <a mat-list-item [ngClass]="currentRoute === menu.value ? 'list-item-active':'asset-link'"
      (click)="fileListWarning(menu.value)">
            <div>
                <span class="project-title">{{menu.viewValue}}</span>
            </div>
        </a>
        <mat-divider></mat-divider>
    </div>
</mat-nav-list>
<mat-nav-list fxFlex *ngIf="!sideBarOpen">
  <div style="width: fit-content;" fxLayout="row" fxLayoutAlign="flex-start center">
      <button mat-icon-button (click)="toggleSideBar()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </mat-nav-list>