<he-filter-slider
SliderTitle="Body Surface Area"
SliderUnit="m^2"
[sliderValue]="sliderValue"
[SliderStep]="SliderStep"
[SliderMinValue]="SliderMinValue"
[SliderMaxValue]="SliderMaxValue"
[DecimalDigit]="DecimalDigit"
(ProvideSliderValue)="updateSliderValue($event)"
></he-filter-slider>
