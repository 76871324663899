import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'he-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() progress = 0;

  
  

}
