
export interface ScopesDetailsInput {
  payload: ScopeDetailType[];
}

export interface ScopesDetailsOutputMessage {
  type: string;
  payload: unknown;
  error?: boolean;
}

export interface ScopesDetailsResponse {
  countries: ScopeDetail[];
  brands: ScopeDetail[];
  inns: ScopeDetail[];
  atcs: ScopeDetail[];
  indics: ScopeDetail[];
  tlis: ScopeDetail[];
  currencies: ScopeDetail[];
}

export interface ScopeDetail {
  id: number;
  name: string;
}

export enum ScopeDetailType {
  Inn = 0,
  Country = 1,
  BrandName = 2,
  Indications = 3,
  TopLevelIndications = 4,
  Moa = 5,
  Atc = 6
}
