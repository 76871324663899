import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RobotoFontComponent } from './roboto-font.component';

@NgModule({
  declarations: [RobotoFontComponent],
  exports: [RobotoFontComponent],
  imports: [
    CommonModule
  ]
})
export class RobotoFontModule { }
