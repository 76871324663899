import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[heInfoButton]'
})
export class InfoButtonDirective {

  constructor(public template: TemplateRef<any>) { }

}
