import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterCompareWithComponent } from './filter-compare-with.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [FilterCompareWithComponent],
  exports: [FilterCompareWithComponent],
  imports: [
    CommonModule,
    MatCheckboxModule
  ]
})
export class FilterCompareWithModule {
}
