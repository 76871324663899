<he-filter-autocomplete [descriptions]="descriptions"
                        [selectedItems]="selectedItems"
                        [filteredItems$]="filteredItems$"
                        [filteredGroupItems$]="filteredGroupItems$"
                        [loading]="loading"
                        [isGroupView]="isGroupView"
                        [showErrorIfEmpty]="showErrorIfEmpty"
                        [disabled]="disabled"
                        [disabledTooltip]="disabledTooltip"
                        (selectedItemChanges)="selectedItemChanges.emit($event)"
                        (callFilterFunction)="callFilterFunction.emit($event)"
></he-filter-autocomplete>
<he-chips-list [selectedItems]="selectedItems"
[disabled]="disabled"
               [disabledTooltip]="disabledTooltip"
               (deletedItemChanges)="deletedItemChanges.emit($event)" [isBrandChipEnable]="isBrandChipEnable"
                             ></he-chips-list>
