import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { SetOfFiltersInterface } from '../../../core/datastore-common';
import { Observable, Subject, mergeMap, of } from 'rxjs';
import { FiltersValueService } from '../filters-value.service';
import { FiltersObject } from '../interfaces';
import { Message, PackDetailsProvider } from './pack-details.provider';
import { CountryAmnogService } from 'projects/helios-gui/src/uikit/country-amnog/country-amnog-service';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';

@Pipe({
  name: 'packDetails'
})
export class PackDetailsPipe implements PipeTransform, OnDestroy {
  private readonly destroying$: Subject<void> = new Subject<void>();

  constructor(private packDetails: PackDetailsProvider,
    private featureFlagService: FeatureFlagService,
    private filtersValueService: FiltersValueService,
    private countryAmnogService: CountryAmnogService,
    private sharedService: SharedService) {
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  transform(event: FiltersObject | any, showHistorical: boolean): Observable<Message> {
    const payload: SetOfFiltersInterface = this.filtersValueService.getSetOfFilters(event);
    payload.historical = showHistorical;
    if (this.filtersValueService.hasRequiredValuesPackDetails(payload)) {
      return this.packDetails.run({ payload }).pipe(
        mergeMap(async (response) => {
          if (response.type === "done") {            
            if(payload.pageNumber > 0){
              this.sharedService.gridmodetotRecortCount$.next(response.totalRows as any);
            } 
          }
          return response;
        }));
    }
    else {
      return of({ type: 'fail', payload: [] });
    }
  }
}
