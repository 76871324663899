<he-app-header></he-app-header>
<div class="delete-project-card" >
<mat-card class="example-card">
    <mat-card-header>
      <div class="heading">
        <span class="heading-title">Delete Project</span>
      </div>
    </mat-card-header>
    <mat-card-content>
        <div class="delete-project-table table-responsive">
          <mat-table #table [dataSource]="dataSource">
            <!-- <ng-container matColumnDef="no">
                <mat-header-cell *matHeaderCellDef class="no">
                    No
                </mat-header-cell>
                <mat-cell *matCellDef="let element;let i=index" class="no">
                  {{ i+1 }}
                </mat-cell>
              </ng-container> -->
            <ng-container matColumnDef="projectId">
              <mat-header-cell *matHeaderCellDef class="projectId">
                Project Id
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="projectIdRow">
                {{ element.id }}
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef class="name">
                Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="nameRow">
                {{ element.name }}
              </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="createdBy">
              <mat-header-cell *matHeaderCellDef class="createdBy">
                Created By
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="createdByRow">
                {{ element.createdBy }}
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="ownerEmail">
              <mat-header-cell *matHeaderCellDef class="ownerEmail">
                Owner Email
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="ownerEmailRow">
                {{ element.ownerEmail }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="actions">
                  Actions
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="actionsRow">
                    <button mat-icon-button color="warn" class="deleteProjectBtn" (click)="openModal(template,element.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
              </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row
              [ngClass]="{ highlight: row?.highlight === true }"
              *matRowDef="let row; columns: displayedColumns"
            ></mat-row>
          </mat-table>
          <mat-paginator #paginator
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20]"
          [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </mat-card-content>
  </mat-card>
</div>

<ng-template #template>
    <div class="modal-body text-center">
      <h2>Are you sure you want to delete this project ?</h2>
      <button type="button" class="btn confirm-btn" (click)="confirm()" >Yes</button>
      <button type="button" class="btn decline-btn" (click)="decline()" >No</button>
    </div>
</ng-template>
