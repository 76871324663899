import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ListHorizonProjectComponent } from './list-horizon-project/list-horizon-project.component';
import { HorizonProjectDetailsComponent } from './horizon-project-details/horizon-project-details.component';
import { SharedModule } from '../shared/shared.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProjectDetailsTabComponent } from '../modules/dashboard/project-details-tab/project-details-tab.component';
import { DashboardService } from '../modules/dashboard.service';
import { DashboardComponent } from '../modules/dashboard/dashboard.component';
import { CountryFlagModule } from 'projects/helios-gui/src/uikit/country-flag/country-flag.module';
import { AssetTppModule } from '../modules/asset-tpp/asset-tpp.module';
import { ProjectFilesComponent } from '../modules/dashboard/project-details-tab/project-files-section/project-files.component';
import { AnalogueModule } from '../modules/analogue/analogue.module';
import { ValueScoringModule } from '../modules/valuescoring/value-scoring.module';
import { ForecastModule } from '../modules/forecast/forecast.module';
import { ProjectAnaloguesSectionComponent } from '../modules/dashboard/project-details-tab/project-analogues-section/project-analogues-section.component';
import { ProjectModule } from '../modules/project/project.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingSpinnerModule } from 'projects/helios-gui/src/uikit/loading-spinner';
import { SummaryModule } from '../modules/summary/summary.module';
import { UploadDataModule } from '../modules/upload-data/upload-data.module';
import { CapitalizeFirstPipe } from '../modules/project/pipes/capitalizeFirst.pipe';

@NgModule({
  declarations: [
    ListHorizonProjectComponent,
    HorizonProjectDetailsComponent,
    DashboardComponent,
    ProjectDetailsTabComponent,
    ProjectFilesComponent,
    ProjectAnaloguesSectionComponent,
  ],
  exports: [ListHorizonProjectComponent, HorizonProjectDetailsComponent],
  imports: [
    CommonModule,
    CountryFlagModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    FlexLayoutModule,
    MatCardModule,
    MatTabsModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatTableModule,
    MatGridListModule,
    MatSnackBarModule,
    MatIconModule,
    AssetTppModule,
    AnalogueModule,
    ValueScoringModule,
    ForecastModule,
    ProjectModule,
    LoadingSpinnerModule,
    SummaryModule,
    UploadDataModule
  ],
  providers: [DashboardService,CapitalizeFirstPipe],
})
export class HorizonProjectModule {}
