import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterProductParamsOrphanStatusComponent } from './filter-product-params-orphan-status.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterProductParamsOrphanStatusComponent],
  exports: [FilterProductParamsOrphanStatusComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
})
export class FilterProductParamsOrphanStatusModule {
}
