import { Pipe, PipeTransform } from '@angular/core';
import { FiltersValueService } from '../../filters-value.service';
import { FilterNameEnum } from '../../interfaces';
import { BrandsAvailabilityService } from '../../brands-availability-service';

@Pipe({
  name: 'brandsAvailabilityFilter'
})
export class BrandsAvailabilityFilterPipe implements PipeTransform {
  constructor(public filtersValueService: FiltersValueService, public brandsAvailabilityService: BrandsAvailabilityService) { }

  transform(brands: any[], availability: any): any {
    let brandFiltersValue = this.filtersValueService.findItemInFilters(this.filtersValueService.filterValues$.getValue(), FilterNameEnum.brands)?.filterValue;
    let brandsAvailability: any = [];
    const brandsWithStatus = brands.map(b => {
      const availabilityItem = availability.find((i: { brandedName: any; }) => i.brandedName === b.name);
      if (availabilityItem) {
        b.status = availabilityItem.availableIn.length === 0 ? 'void' : '';

        if (b.status === "") {
          brandsAvailability.push(b);
        }

        let brandFilter = brandFiltersValue?.find((bf) => bf.name == b.name);
        if (brandFilter) {
          brandFilter.status = b.status;
        }
      }
      return b;
    });
    this.brandsAvailabilityService.availableBrandValues$.next(brandsAvailability);
    return brandsWithStatus;
  }
}
