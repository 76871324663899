<h2 class="text-colour" style="    text-align: center;" mat-dialog-title>Updated framework changes</h2>
<mat-dialog-content  style="    text-align: center;">
  The framework has been updated since your last visit to further improve<br> value-based price forecasting.<br>Do you wish to create a copy of your existing project with the updated changes?
</mat-dialog-content>
<mat-dialog-actions class="align-c">
  <button class="yes-btn" mat-button mat-dialog-close (click)="edit(true)">Yes</button>
  <button class="yes-btn" mat-button mat-dialog-close (click)="edit(false)">No</button>
</mat-dialog-actions>



