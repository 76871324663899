import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AnalogueTableInterface,
  ComporatorTableInterface,
  CriteriaApiInterFace,
  CriteriaInterface,
  criteriaPayloadInterface,
  multiValueList,
} from '../../models/criteria.interface';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { ProjectService } from '../../services/project.service';
import { Subject, take, takeUntil } from 'rxjs';
import { MasterService } from '../../../../horizon-project/services/master.service';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { MatTable } from '@angular/material/table';
import { ComporatorHeatMapComponent } from '../comporator-heat-map/comporator-heat-map.component';
import { AnalogueMasterData } from '../../../../horizon-project/models/AnalogueMaseterData';
import { TitleCasePipe } from '@angular/common';
import { Sort } from '@angular/material/sort';
import { NetPriceService } from '../../services/net-price.service';

@Component({
  selector: 'he-comporator-selection',
  templateUrl: './comporator-selection.component.html',
  styleUrls: ['./comporator-selection.component.scss'],
})
export class ComporatorSelectionComponent implements OnInit {
  @ViewChild(MatTable) public table!: MatTable<any>;
  @ViewChild(ComporatorHeatMapComponent)
  public comporatorHeatMapComponent!: ComporatorHeatMapComponent;

  public mustHave: CriteriaInterface[] = [];
  public niceToHave: CriteriaInterface[] = [];
  public Unselected: CriteriaInterface[] = [];
  public defaultCriteria: CriteriaInterface[] = [];
  public dataSource: ComporatorTableInterface[] = [];
  public comparators: any[] = [];
  public sortedComparators: any[] = [];
  public comparatorsCountData: any = [];
  public minMarket: number = 1;

  public firstPanelOpen = true;
  public secondPanelOpen = false;
  public thirdPanelOpen = false;
  public loading = false;
  private readonly PATIENT_NUMBER_MARKET_AREA = {
    Europe: 'EUROPE',
    UnitedStates: 'UNITED STATES',
  };
  public sort: any;
  public tli: any = {};
  comparatorsColumn: string[] = [
    'brandName',
    'inn',
    'HTAIndication',
    'mechanismOfAction',
    'remove',
  ];
  public criteriaList: {
    apititle: string;
    inputType: string;
    title: string;
  }[] = [
    {
      apititle: 'biomarkerGenetyping',
      inputType: '8',
      title: 'Biomarker/gene-typing',
    },
    {
      apititle: 'lineOfTherapy',
      inputType: '7',
      title: 'Line of therapy',
    },
    {
      apititle: 'population',
      inputType: '6',
      title: 'Population',
    },
  ];
  public countryFilter: string = '';
  public selectedMarket: any = [];
  public newSelectedMarket: any = [];
  public MasterAnalogueData: AnalogueMasterData[] = [];

  private unsubscriber$ = new Subject<void>();
  constructor(
    public criteriaSelectionService: CriteriaSelectionService,
    private projectService: ProjectService,
    private masterService: MasterService,
    private titleCasePipe: TitleCasePipe,
    private analogueService: AnalogueService,
    private toastNotificationService: ToastNotificationService,
    private netPriceService: NetPriceService,
  ) {}

  ngOnInit(): void {
    this.getMasterAnalogues();
    this.GetCategoryCriteria();
    this.getTli();
    this.criteriaSelectionService.castSavedSelectedComparators
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        const filteredData = res.filter(
          (element: any, index: any, array: any) => {
            return (
              array.findIndex(
                (prevElement: any) =>
                  prevElement.name === element.name &&
                  prevElement.HTAIndication === element.HTAIndication 
                  // &&
                  // prevElement.biomarkerGenetyping ===
                  //   element.biomarkerGenetyping
              ) === index
            );
          }
        );
        this.comparators = res;
      });
    this.getCreateProjectDetails();
    this.sortedComparators = this.projectService.selectedComparators;
    this.getComparatorCountData();
  }

  public getMasterAnalogues(): void {
    const TLi = '';
    const MOA = '';
    this.criteriaSelectionService
      .getAnalogueMaster(TLi, MOA)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.MasterAnalogueData = res;
       
      });
  }

  public getComparatorCountData(): void {
    this.criteriaSelectionService.brandCountData.subscribe((res) => {
      this.comparatorsCountData = res;
      this.checkComparatorCount();
    });
  }

  public checkComparatorCount(): void {
    const isBrandCountValid = this.comparatorsCountData.every(
      (data: any) => data.brandCount >= this.minMarket
    );
    if (!isBrandCountValid) {
      this.criteriaSelectionService.sortedComparators.next(true);
      this.criteriaSelectionService.isComparatorValid = false;
      this.projectService.newValuechanged.next(true);
    } else {
      this.criteriaSelectionService.isComparatorValid = true;
      this.projectService.newValuechanged.next(true);
    }
  }

  public getTli(): void {
    this.analogueService.getAnalogueMasterTLIsOb(this.projectService.createProjectFormData.value.framework
      .valueFrameworkName).subscribe((res) => {
      this.tli = {
        infoData: '',
        inputType: '0',
        name: 'tli',
        selectedValueList: [],
        selectedValue: '',
        title: 'Top level Indication',
        valueList: res
          .sort((a: any, b: any) => a.localeCompare(b))
          .map((x) => this.titleCasePipe.transform(x)),
      };
    });
  }

  public getCreateProjectDetails(): void {
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .subscribe((data) => {
        data?.selectedMarkets?.forEach((market: any) => {
          this.selectedMarket.push(market);
          this.newSelectedMarket.push({ country: market, analogues: [] });
        });
        this.newSelectedMarket.sort((a: any, b: any) =>
          a.country.localeCompare(b.country)
        );
      });
    this.setComparatorsNewView();
  }

  public GetCategoryCriteria(): void {
    this.criteriaSelectionService.GetCategoryCriteria().subscribe((res) => {
      let titles = Object.keys(res);
      titles.forEach((title) => {
        this.setCriteriaList(title, res);
      });
      this.criteriaSelectionService.castCriteriaLComparatorist
        .pipe(take(1))
        .subscribe((res) => {
          if (res !== null) {
            this.tli = res.tli;
            this.mustHave = res.mustHave.filter(
              (m: CriteriaInterface) =>
                this.defaultCriteria.filter((x) => x.title === m.title).length >
                0
            );
            this.niceToHave = res.niceToHave.filter(
              (m: CriteriaInterface) =>
                this.defaultCriteria.filter((x) => x.title === m.title).length >
                0
            );
            let defaultUnselected = JSON.parse(
              JSON.stringify(
                this.defaultCriteria.filter(
                  (x) =>
                    this.mustHave.filter((m) => m.title === x.title).length ==
                      0 &&
                    this.niceToHave.filter((n) => n.title === x.title).length ==
                      0
                )
              )
            );
            this.Unselected = defaultUnselected;
            this.getSavedComparator();
            this.criteriaSelectionService.castopenedComparatorTabindext
              .pipe(take(1))
              .subscribe((res) => {
                // this.panelOpenState = res;
                this.secondPanelOpen = res;
              });
            this.refreshDataOnMarketChange();
          } else {
            this.Unselected = JSON.parse(JSON.stringify(this.defaultCriteria));
          }
        });

      // this.Unselected = defaultUnselected;
    });
  }

  public getComparatorsList(comparators: any[]): void {
    this.comparators = comparators;
    this.criteriaSelectionService.savedSelectedComparators.next(comparators);
    // this.table.renderRows();
    this.updatComparatorInService();
    this.setComparatorsNewView();
  }

  private updatComparatorInService(): void {
    this.projectService.selectedComparators = [];
    this.sortedComparators.map((analogue: any, index: number) => {
      let nAnalogue = {
        brandName: analogue.brandName,
        indication: analogue.indication,
        Country: analogue.Country,
        mechanismOfAction: analogue.mechanismOfAction,
        HTAIndication: analogue.HTAIndication,
        sequenceNo: index + 1,
        inn: analogue.inn,
        therapyArea: analogue.therapyArea,
      };
      this.projectService.selectedComparators.push(nAnalogue);
    });
    this.checkComparatorCount();
    this.criteriaSelectionService.savedSelectedComparators.next(
      this.comparators
    );
    this.setAnaloguesForNetPriceAssumptions();
  }

  public setComparatorsNewView(): void {
    this.newSelectedMarket.forEach((x: any) => {
      this.comparators.forEach((y) => {
        if (
          !x.analogues.some(
            (z: any) =>
              z.name === y.name &&
              z.HTAIndication === y.HTAIndication
          )
        ) {
          if (
            y.Country.some(
              (a: any) => a.name.toLowerCase() === x.country.toLowerCase()
            )
          ) {
            x.analogues.push(y);
          }
        }
      });
      x.analogues.sort((a: any, b: any) => a.name.localeCompare(b.name));
    });
  }

  private getSavedComparator(): void {
    this.criteriaSelectionService.castSavedComparatorTableData
      .pipe(take(1))
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  private setCriteriaList(title: string, results: CriteriaApiInterFace): void {
    if (title != 'analogueCriteriaInfoIconDTO') {
      this.defaultCriteria.push({
        title: this.criteriaList.find(
          (res) => res.apititle.toLowerCase() === title.toLowerCase()
        )?.title as string,
        name: this.titleCaseWord(title),
        infoData: results.analogueCriteriaInfoIconDTO.find(
          (res) => res.criteria.toLowerCase() === title.toLowerCase()
        )?.description,
        inputType: this.criteriaList.find(
          (res) => res.apititle.toLowerCase() === title.toLowerCase()
        )?.inputType as string,
        valueList: this.setValueList(
          title,
          results,
          this.PATIENT_NUMBER_MARKET_AREA.Europe
        ),
        multiValueList: this.setMultiValueList(
          title,
          results,
          this.PATIENT_NUMBER_MARKET_AREA.Europe
        ),
        selectedValueList: [],
      });
    }
  }

  private setMultiValueList(
    title: string,
    results: CriteriaApiInterFace,
    marketArea: string
  ): multiValueList[] {
    let values: multiValueList[] = [];

    let list = Object.entries(results);
    list.forEach((element) => {
      if (element[0] === title) {
        values = element[1].map((x:string)=>{
          return{
            name:x,
            selected:false
          }
        })
      }
    });

    return values;
  }

  private setValueList(
    title: string,
    results: CriteriaApiInterFace,
    marketArea: string
  ): any {
    let values: any[] = [];
    if (title === 'population') {
      let list = Object.entries(results);
      list.forEach((element) => {
        if (element[0] === title) {
          values = ['Adult only', 'Paediatric only', 'Adult & Paediatric'];
        }
      });
    }
    else{

    let list = Object.entries(results);
    list.forEach((element) => {
      if (element[0] === title) {
        values = element[1];
      }
    });
  }

    return values;
  }

  public titleCaseWord(word: string): string {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1);
  }

  public drop(event: CdkDragDrop<CriteriaInterface[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public valueChange(): void {
    let saveComparatorCriteria = {
      tli: this.tli,
      mustHave: this.mustHave,
      niceToHave: this.niceToHave,
      Unselected: this.Unselected,
    };
    this.criteriaSelectionService.criteriaComparatorList.next(
      saveComparatorCriteria
    );
  }

  public reset(): void {
    this.mustHave = [];
    this.niceToHave = [];
    this.tli.selectedValue = '';
    this.Unselected = JSON.parse(JSON.stringify(this.defaultCriteria));
    this.valueChange();
  }

  public FindComparator(): void {
    if (this.checkFormValid()) {
      this.secondPanelOpen = true;
      this.firstPanelOpen = false;
      this.comporatorHeatMapComponent.searchTable.patchValue('')
      this.getAnalogue();
    }
  }

  public checkFormValid(): boolean {
    let state = true;
    if (this.tli.selectedValue === '') {
      state = false;
      this.toastNotificationService.errorMessage(
        'Please select the Must Have Criteria to continue'
      );
    } else if (!this.checkMustHaveValues()) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Selected Must Have Criteria value cannot be empty'
      );
    } else if (!this.checNicetoHaveValues()) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Selected Nice to Have Criteria value cannot be empty'
      );
    }
    return state;
  }

  public checkMustHaveValues(): boolean {
    let state = true;
    this.mustHave.forEach((element) => {
      switch (element.inputType) {
        case '1':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '2':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '3':
          if (element?.selectedValueList?.length === 0) {
            state = false;
          }
          break;
        case '4':
          element?.multiInputList?.forEach((therapyarea) => {
            if (
              therapyarea.selectedTherapyValue === undefined ||
              therapyarea.selectedIndicationValue.length === 0
            ) {
              state = false;
            }
          });
          break;
      }
    });
    return state;
  }

  public checNicetoHaveValues(): boolean {
    let state = true;
    this.niceToHave.forEach((element) => {
      switch (element.inputType) {
        case '1':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '2':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '3':
          if (element?.selectedValueList?.length === 0) {
            state = false;
          }
          break;
        case '4':
          element?.multiInputList?.forEach((therapyarea) => {
            if (
              therapyarea.selectedTherapyValue === undefined ||
              therapyarea.selectedIndicationValue.length === 0
            ) {
              state = false;
            }
          });
          break;
      }
    });
    return state;
  }

  public getAnalogue(): void {
    this.loading = true;
    this.dataSource = [];
    let body = this.setPayLoad();
    this.criteriaSelectionService
      .getComparator(body)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res) => {
          this.dataSource = res;
          this.loading = false;
          this.criteriaSelectionService.savedComparatorTableData.next(
            this.dataSource
          );
          this.checkDataSource();
          this.checkComparator();
          this.comporatorHeatMapComponent.getComparatorColumn(res,true);
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  public checkDataSource(): void {
    this.dataSource.forEach((analogue) => {
      analogue.toggle.toggle = this.comparators.some(
        (res) =>
          res.name === analogue.Brand &&
          res.HTAIndication === analogue.HTAIndication.name 
          // &&
          // res.biomarkerGenetyping === analogue.BiomarkerGenetyping.name
      );
      analogue.toggle.disable = this.comparators.some(
        (res) =>
          res.name === analogue.Brand &&
          res.HTAIndication !== analogue.HTAIndication.name && this.checkselectedAnalogueMarket(analogue,res)
          // &&
          // res.biomarkerGenetyping === analogue.BiomarkerGenetyping.name
      );
      this.comparators.forEach((x) => {
        if (
          x.name === analogue.Brand &&
          x.HTAIndication === analogue.HTAIndication.name && this.checkselectedAnalogueMarket(analogue,x)
          // &&
          // x.biomarkerGenetyping === analogue.BiomarkerGenetyping.name
        ) {
          analogue.Country.forEach((y:any)=>{
            if(!x.Country.some((a:any)=> a.name === y.name)){
              x.Country.push(y)
            }
          })
        }
      });
    });
  }

  public checkComparator(): void {
    this.comparators.forEach((comparators) => {
      comparators.available = this.dataSource.some(
        (res) =>
          res.Brand === comparators.name &&
          res.HTAIndication.name === comparators.HTAIndication
      );
    });
    this.getComparatorsList(this.comparators);
  }

  public setPayLoad(): any {
    let body: criteriaPayloadInterface = {
      Countries: this.capitalizeArrayValues(this.selectedMarket),
      ValueFrameworkName:
        this.projectService.createProjectFormData.value.framework
          .valueFrameworkName,
    };

    this.setPayLoadValues('Must have', this.tli, body);
    if (this.mustHave.length !== 0) {
      this.mustHave.forEach((criteria) => {
        this.setPayLoadValues('Must have', criteria, body);
      });
    }
    if (this.niceToHave.length !== 0) {
      this.niceToHave.forEach((criteria) => {
        this.setPayLoadValues('Nice to have', criteria, body);
      });
    }
    this.netPriceService.heatMapPayload = body;
    return body;
  }

  public setPayLoadValues(
    criteriaType: string,
    criteria: CriteriaInterface,
    body: criteriaPayloadInterface
  ): any {
    switch (criteria.inputType) {
      case '0':
        body[criteria.name] = {
          Type: criteriaType,
          Data: [criteria.selectedValue],
        };
        break;
      case '1':
        body[criteria.name] = {
          Type: criteriaType,
          Data: [criteria.selectedValue],
        };
        break;
      case '2': {
        if (
          criteria.name === 'EligiblePatientNumber' ||
          criteria.name === 'EligiblePatientNumberUSA'
        ) {
          const marketArea =
            criteria.name === 'EligiblePatientNumberUSA'
              ? this.PATIENT_NUMBER_MARKET_AREA.UnitedStates
              : this.PATIENT_NUMBER_MARKET_AREA.Europe;

          if (!body['EligiblePatientNumber']) {
            body['EligiblePatientNumber'] = [];
          }

          body['EligiblePatientNumber'].push({
            Type: criteriaType,
            Data: [criteria.selectedValue],
            MarketArea: marketArea,
          });
        } else {
          body[criteria.name] = {
            Type: criteriaType,
            Data: [criteria.selectedValue],
          };
        }

        break;
      }
      case '3':
        body[criteria.name] = {
          Type: criteriaType,
          Data: criteria.selectedValueList,
        };
        break;
      case '4':
        let therapyLIst: any[] = [];
        let indicationLIst: any[] = [];
        criteria.multiInputList?.forEach((res) => {
          therapyLIst.push(res.selectedTherapyValue);
          res.selectedIndicationValue.forEach((indication) => {
            indicationLIst.push(indication);
          });
        });
        body['TherapyArea'] = {
          Type: criteriaType,
          Data: therapyLIst,
        };
        body['IndicationCategory'] = {
          Type: criteriaType,
          Data: indicationLIst,
        };
        break;
        case '6':
          body[criteria.name] = {
            Type: criteriaType,
            Data: criteria.selectedValueList,
          };
          break;

        case '7':
          body[criteria.name] = {
            Type: criteriaType,
            Data: criteria.selectedValueList,
          };
          break;
      case '8':
          body[criteria.name] = {
            Type: criteriaType,
            Data: criteria.selectedValueList,
          };
          break;
      }
    return body;
  }

  private capitalizeArrayValues(arr: string[]): string[] {
    return arr.map((value) => value.toUpperCase());
  }

  public addAnalogues(): void {
    this.projectService.newValuechanged.next(true);
    this.criteriaSelectionService.isComparatorValid = true;
    this.sortedComparators = this.projectService.selectedComparators
      ? this.projectService.selectedComparators
      : [];
    this.comparators.forEach((comparators) => {
      let sortedRecords = this.sortedComparators.filter(
        (ele) =>
          comparators.HTAIndication.toLowerCase().trim() ===
            ele.HTAIndication.toLowerCase().trim() &&
            comparators.inn.toLowerCase().trim() == ele.inn.toLowerCase().trim()
      );
      if (sortedRecords.length == 0) {
        let allMoaData = this.MasterAnalogueData.filter(
          (res) =>
            res.brandName.trim().toLowerCase() ===
          comparators.name.trim().toLowerCase() &&
            res.inn.trim().toLowerCase() === comparators.inn.trim().toLowerCase()
        );
        allMoaData.map((moaData, index) => {
          const analogueWithMoa = {
            brandName: comparators.name,
            indication: comparators.indication,
            Country: comparators.Country,
            HTAIndication: comparators.HTAIndication,
            mechanismOfAction: moaData.mechanismOfAction,
            therapyArea: moaData.therapyArea,
            inn: moaData.inn,
            sequenceNo: comparators.sequenceNo + index + 1,
          };
          if(!this.sortedComparators.some(x=>x.brandName === analogueWithMoa.brandName && x.HTAIndication === analogueWithMoa.HTAIndication && x.indication === analogueWithMoa.indication && this.checkselectedAnalogueMarket(x,analogueWithMoa))){
            this.sortedComparators.push(analogueWithMoa);
          }
        });
      }
    });
    this.updatComparatorInService();
    this.secondPanelOpen = false;
    this.thirdPanelOpen = true;
  }

  public checkselectedAnalogueMarket(analogue1:any,analogue2:any):boolean{
    let valid = false;
    analogue1.Country.forEach((element:any) => {
      if(analogue2.Country.some((x:any)=> x.name === element.name)){
        valid = true;
      }
    });
    return valid;

  }

  public removeAnalogue(analogue: any): void {
    const index = this.comparators.findIndex(
      (res) =>
        res.HTAIndication.toLowerCase().trim() ===
          analogue.HTAIndication.toLowerCase().trim()
    );
    const removalElements = this.sortedComparators.filter(
      (res) =>
        res.HTAIndication.trim().toLowerCase() ===
          analogue.HTAIndication.trim().toLowerCase() 
    );
    if (index >= 0) {
      const dataSourceElements = this.dataSource.filter(
        (res) =>  this.checkAnaloues2(analogue,res)

      );
      if (dataSourceElements.length !== 0) {
        dataSourceElements.forEach((res) => {
          res.toggle.toggle = false;
          res.toggle.disable = false;
        });
      }
      this.comparators.splice(index, 1);
    }
    this.comporatorHeatMapComponent.updateMarketsBrandCount();

    if (removalElements.length >= 0) {
      this.sortedComparators = this.sortedComparators.filter(
        (res) =>
          res.HTAIndication.trim().toLowerCase() !==
            analogue.HTAIndication.trim().toLowerCase() 
          //   &&
          // res.Biomarkergenetyping.trim().toLowerCase() !==
          //   analogue.Biomarkergenetyping.trim().toLowerCase()
      );
      // this.table.renderRows();
      this.updatComparatorInService();
    }
    this.newSelectedMarket.forEach((x: any) => {
      let index = x.analogues.findIndex(
        (y: any) =>
          y.name === analogue.name &&
          y.HTAIndication === analogue.HTAIndication
      );
      if (index >= 0) {
        x.analogues.splice(index, 1);
      }
    });
  }
  public selectCountry(country: string): void {
    if (this.countryFilter != country) {
      this.countryFilter = country;
    } else {
      this.countryFilter = '';
    } 
    this.comporatorHeatMapComponent.searchTableData(this.countryFilter)
  }

  public checkAnaloues(
    analogue: any,
    selectedAnalogue: any
  ): boolean {
    let state: boolean = false;
      state =
        selectedAnalogue.Brand.toLowerCase().trim() ===
          analogue.name.toLowerCase().trim() &&
        selectedAnalogue.INN.toLowerCase().trim() ===
          analogue.inn.toLowerCase().trim() &&
        selectedAnalogue.HTAIndication.name.toLowerCase().trim() ===
          analogue.HTAIndication.toLowerCase().trim() && this.checkselectedAnalogueMarket(analogue,selectedAnalogue);
    return state;
  }

  public checkAnaloues2(
    analogue: any,
    selectedAnalogue: any
  ): boolean {
    let state: boolean = false;
      state =
        selectedAnalogue.Brand.toLowerCase().trim() ===
          analogue.name.toLowerCase().trim() &&
        selectedAnalogue.INN.toLowerCase().trim() ===
          analogue.inn.toLowerCase().trim() &&
 this.checkselectedAnalogueMarket(analogue,selectedAnalogue);
    return state;
  }

  moveUp(index: any) {
    if (index > 0) {
      const tmp = this.sortedComparators[index - 1];
      this.sortedComparators[index - 1] = this.sortedComparators[index];
      this.sortedComparators[index] = tmp;
      this.table.renderRows();
      this.updatComparatorInService();
    }
  }

  moveDown(index: any) {
    if (index < this.sortedComparators.length - 1) {
      const tmp = this.sortedComparators[index + 1];
      this.sortedComparators[index + 1] = this.sortedComparators[index];
      this.sortedComparators[index] = tmp;
      this.table.renderRows();
      this.updatComparatorInService();
    }
  }

  dropSorting(event: CdkDragDrop<string[]>) {
    const previousIndex = this.sortedComparators.findIndex(
      (row: any) => row === event.item.data
    );
    moveItemInArray(this.sortedComparators, previousIndex, event.currentIndex);
    this.table.renderRows();
    this.updatComparatorInService();
  }

  public sortData(sort: Sort): void {
    this.sort = sort;
    const data = this.sortedComparators.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedComparators = data;
      return;
    }

    this.sortedComparators = data.sort((a, b) => {
      const isAsc = sort.direction !== 'asc';
      switch (sort.active) {
        case 'brandName':
          return compare(a.brandName, b.brandName, isAsc);
        case 'inn':
          return compare(a.inn, b.inn, isAsc);
        case 'HTAIndication':
          return compare(a.HTAIndication, b.HTAIndication, isAsc);
        case 'therapyArea':
          return compare(a.therapyArea, b.therapyArea, isAsc);

        default:
          return 0;
      }
    });
    this.updatComparatorInService();
  }

  public refreshDataOnMarketChange() {
    this.FindComparator();
  }

  removeSorting(index: number) {
    const analogueRows = this.sortedComparators.filter(
      (res) =>
        res.HTAIndication.toLowerCase().trim() ===
          this.sortedComparators[index].HTAIndication.toLowerCase().trim()
    );
    if (analogueRows.length <= 1) {
      const removalElements = this.dataSource.filter(
        (res) =>
          res.HTAIndication.name.toLowerCase().trim() ===
            this.sortedComparators[index].HTAIndication.toLowerCase().trim()
      );
      const removaldisabled = this.dataSource.filter(
        (res) =>
          res.Brand === this.sortedComparators[index].brandName &&
          res.HTAIndication.name.toLowerCase().trim() !==
            this.sortedComparators[index].HTAIndication.toLowerCase().trim() && this.checkselectedAnalogueMarket(this.sortedComparators[index],res)
      );
      if (removalElements.length !== 0) {
        removalElements.forEach((res) => {
          res.toggle.toggle = false;
          res.toggle.disable = false;
          this.comporatorHeatMapComponent.updateMarketsBrandCount();
        });
      }     
       if (removaldisabled.length !== 0) {
        removaldisabled.forEach((res) => {
          res.toggle.toggle = false;
          res.toggle.disable = false;
        });
      }
    }
    if (analogueRows.length <= 1) {
      this.comparators = this.comparators.filter(
        (res) =>
          res.HTAIndication.toLowerCase().trim() !==
            this.sortedComparators[index].HTAIndication.toLowerCase().trim()
      );
      this.newSelectedMarket.forEach((x:any)=>{
        x.analogues = x.analogues.filter(
          (res:any) =>
            res.HTAIndication.toLowerCase().trim() !==
              this.sortedComparators[index].HTAIndication.toLowerCase().trim() 
        );
      })
    }
    this.sortedComparators.splice(index, 1);
    this.table.renderRows();
    this.setAnaloguesForNetPriceAssumptions();
    this.updatComparatorInService();
    this.setComparatorsNewView();
  }

  public setAnaloguesForNetPriceAssumptions() {
    let netAnalogues: any[] = [];
    let i = 0;
    //test sorting
    // this.datasoucre
    this.sortedComparators
      .forEach((x) => {
        let index = netAnalogues.findIndex(
          (y) =>
            x.brandName === y.Brand &&
            x.inn === y.INN &&
            x.HTAIndication === y.HTAIndication
        );
        if (index <= -1) {
          netAnalogues.push({
            Brand: x.brandName,
            INN: x.inn,
            TLI: x.indication,
            HTAIndication: x.HTAIndication,
            markets:  this.setMarketsForAnalogueNetprice(x),
            sqNo:i,
          });
          i++;
        } else {
          // netAnalogues[index].markets = [...x.Market,...netAnalogues[index].markets]
        }
      });
    this.netPriceService.selectedAnalogueNetprice = netAnalogues;
    this.projectService.newValuechanged.next(true)
  }

  setMarketsForAnalogueNetprice(data:any):any[]{
    let markets:any[]=[]; 
    this.dataSource.filter(x=> x.toggle && x.Brand === data.brandName && x.INN === data.inn && x.HTAIndication.name === data.HTAIndication ).forEach(res=>{
      markets = [...markets,...res.Country]
    })
    return markets;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.criteriaSelectionService.openedComparatorTabindex.next(
      this.secondPanelOpen
    );
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
