  <div class="w-100 he-search-control he-autocomplete-anchor" matAutocompleteOrigin #anchor="matAutocompleteOrigin">
    <div class="row no-gutters align-items-center p-1">
      <div class="col-auto p-1">
        <he-icon id="search" class="d-block he-additional-text he-search-icon"></he-icon>
      </div>
      <div class="col p-1">
        <input
          type="text"
          class="w-100 he-pure-control"
          placeholder="{{placeholderText$ | async}}"
          #trigger="matAutocompleteTrigger"
          #symbols
          (input)="onQueryChange($event)"
          [matAutocomplete]="suggestionList"
          [matAutocompleteConnectedTo]="anchor"
          [matAutocompletePosition]="'below'"
          (click)="onResize()"
        />
      </div>
    </div>
  </div>



  <mat-autocomplete class="he-shadow-soft he-autocomplete-list" #suggestionList="matAutocomplete" [displayWith]="pickName" (optionSelected)="onSuggestion($event)">
  <ng-container *ngIf="suggestion$ | async as msg">
    <ng-container [ngSwitch]="msg.type">

      <ng-container *ngSwitchCase="'pending'">
        <div class="overflow-hidden">
          <div class="m-n2">
            <he-loading-spinner [diameter]="20"></he-loading-spinner>
          </div>
        </div>
        <!-- mat-autocomplete requires at least one option -->
        <mat-option [disabled]="true" class="d-none"></mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="'fail'">
        ERROR
      </ng-container>

      <ng-container *ngSwitchCase="'done'">
        <he-command (asyncFire)="onSuggestionsLoad()"></he-command>
        <ng-container *ngIf="msg.payload.length < 1">
          <div class="px-3">
            <div class="he-text-line he-option he-additional-text">
              <span>No results found</span>
            </div>
          </div>
          <!-- mat-autocomplete requires at least one option -->
          <mat-option [disabled]="true" class="d-none"></mat-option>
        </ng-container>

        <ng-container *ngIf="msg.payload.length > 0">
          <ng-container *ngFor="let group of msg.payload | groupByType">
            <div class="px-3">
              <div class="he-text-line he-option he-additional-text text-uppercase">
                <span>{{ labels[group.type] || group.type }}</span>
              </div>
            </div>

            <mat-option class="he-option he-option-hover" [value]="suggestion" *ngFor="let suggestion of group.children">
              <div class="he-standard-line">
                <he-icon id="search" class="mr-2 he-search-icon"></he-icon>
                <he-highlight-text [symbols]="symbols.value" [text]="suggestion.name"></he-highlight-text>
              </div>
            </mat-option>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        UNKNOWN STATE
      </ng-container>

    </ng-container>
  </ng-container>
  </mat-autocomplete>
