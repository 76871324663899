import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, finalize, of, share, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { Md5 } from 'ts-md5';
import { IApiCache } from './apiCache';

@Injectable()
export class HeliosDomainInterceptor implements HttpInterceptor {
  private apiCaches: any = []; // Client side API cache

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqBody = (req.method == "POST" ? req.body : req.urlWithParams);
    const cacheKey = Md5.hashStr(req.url + "|" + (reqBody ? JSON.stringify(reqBody) : reqBody));

    if (this.apiCaches[cacheKey] == undefined) {
      this.apiCaches[cacheKey] = ({ cache: null, cachedObservable: null } as IApiCache);
    }
    let apiCache: IApiCache = this.apiCaches[cacheKey];

    let observable: Observable<any>;

    if (apiCache.cache) {
      observable = of(apiCache.cache);
    } else if (apiCache.cachedObservable) {
      observable = apiCache.cachedObservable;
    } else {
      let target: HttpRequest<any> = req;
      if (!(req.url.startsWith('http://') || req.url.startsWith('https://'))) {
        target = req.clone({ url: environment.gatewayApiEndpoint + req.url });
      }

      apiCache.cachedObservable = next.handle(target)
        .pipe(
          // // Do this when we have to do client side cache of response and get the cached value
          // tap(evt => {
          //   if (evt instanceof HttpResponse) {
          //     apiCache.cache = evt.body; // Cache the response event body
          //   }
          // }),
          share(), // "share()" will handle to prevent duplicate HTTP requests (makes it sharable among multiple subscribers)
          finalize(() => {
            // // setting it to "null" as we don’t want any other subscriber. New subscribers will just get the cached value from now on.
            // apiCache.cachedObservable = null;
            // Instead of removing cachedObservable, we are removing complete cache value from array as we don't have to cache the responses, we just have to prevent multiple HTTP requests
            delete this.apiCaches[cacheKey];
          })
        );
      observable = apiCache.cachedObservable;
    }

    return observable;
  }
}
