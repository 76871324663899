import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Params, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ScenarioWarningComponent } from '../scenario-warning/scenario-warning.component';
import { Subject, takeUntil, take } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { ProjectDraftService } from '../../services/project-draft.service';
import { FeatureFlagService } from '../../../../horizon-project/services/feature-flag.service';
import { NetPriceService } from '../../services/net-price.service';
import { UpdateFrameworkService } from '../../../../shared/services/update-framework.service';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'he-project-sidebar',
  templateUrl: './project-sidebar.component.html',
  styleUrls: ['./project-sidebar.component.scss'],
})
export class ProjectSidebarComponent implements OnInit, OnDestroy {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @Input() public sideBarOpen = true;
  public scenarios: any = [];
  public scenarioName: any = '';
  public selectedScenarioIndex: any = '';
  public scenarioId: any = '';
  public addScenarioDisabled: any = true;
  public projectData: any = {};
  public createProjectDisabled: any = true;
  public draftProjectDisabled: any = true;
  public valueChangeStatus: any = false;
  public editProjectId: any = '';
  public currentName: any = '';
  private unsubscriber$ = new Subject<void>();
  public isDraftProject: boolean = false;
  public analogueIsValid: boolean = false;
  public netPriceValid: boolean = false;
  public enableComparator: boolean = false;
  public enableAnalagoue: boolean = false;
  public ErrorDetails:string=''
  public ErrorDetails2:string=''
  public errorComparator:string=''
  public ErrorAnalogues:string=''
  public ErrorNetprice:string=''

  public isSaveAsDraftFeature: boolean = false;
  public minMarket:number = 2;
  constructor(
    public projectService: ProjectService,
    private router: Router,
    public dialog: MatDialog,
    public criteriaSelectionService: CriteriaSelectionService,
    private toastNotificationService: ToastNotificationService,
    public projectDraftService: ProjectDraftService,
    public featureFlagService: FeatureFlagService,
    private netPriceService: NetPriceService,
    private updateFrameworkService: UpdateFrameworkService,
    // private snackBarRef: MatSnackBarRef<ErrorMessageComponent>
  ) {}

  ngOnInit(): void {
    this.checkFeatureFlag();
    this.projectService.castCreateProjectFormData
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        this.projectData = data;
      });
    this.projectService.castValueChangeStatus
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.valueChangeStatus = res;
      });
    this.projectService.castEditProjectId
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        this.editProjectId = data;
      });
      this.projectService.castnewValuechanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.checkFormValid(res, false);
        this.setErrorTooltip()
      });
    this.projectService.castselectedScenarioIndex
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.selectedScenarioIndex = res;
        if (this.selectedScenarioIndex > 0) {
          this.currentName =
            this.projectService.scenarios[this.selectedScenarioIndex - 1]?.scenarioDetails;
        }
      });
    //this redirects the function for redirecting
    this.projectService.castscenarioList
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.scenarios = res;
        if (res.length != 0) {
          if (this.editProjectId != '') {
            if (this.selectedScenarioIndex == 0) {
              let link =
                this.editProjectId != ''
                  ? '/horizon/edit-scenario'
                  : '/horizon/add-scenario';
              const queryParams: Params = { scenario: this.scenarios[0].id };
              this.goToScenario(1, false);
              this.router.navigate([link], { queryParams: queryParams });
            }
          }
        }
      });


  }

  checkFeatureFlag():void{
    this.featureFlagService.getFeatureFlagData().then((data) => {
      const saveAsDraftFeature = data.find(
        (x) => x.name === 'project-save-as-draft' && x.page === 'common'
      );
      let newHeatmap = data.find(
        (x) => x.name === 'new-analogue-heatMap' && x.page === 'analogue-selection'
      )?.isActive as boolean;
      this.projectService.minMarket = this.projectService.createProjectFormData.value.comparator === 'true'?1:5;
      if (saveAsDraftFeature?.isActive) {
        this.isSaveAsDraftFeature = true;
        this.checkDraftProject();
      } else {
        this.isSaveAsDraftFeature = false;
      }
    });
  }

  toggleSideBar(): void {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  public setErrorTooltip():any{
    this.checkAnalogueValid()
    this.checkNetPrice()
    if(!this.projectService.isdetailsValid ){
      this.ErrorDetails ="Please complete the Project Details section to proceed to the next step";
      this.ErrorDetails2 ="Please complete the Project Details section to proceed to the next step";
      this.ErrorAnalogues ="Please complete the Project Details and "+ (this.projectService.createProjectFormData.value.comparator === 'true'?"Comparator":"Analogue")+" Selection section to proceed to the next step";
      this.ErrorNetprice ="Please complete the Project Details, "+ (this.projectService.createProjectFormData.value.comparator === 'true'?"Comparator":"Analogue")+" Selection and Net Price Assumptions section to proceed to the next step";
      this.analogueIsValid = false;
      this.netPriceValid = false;
      if(this.netPriceService.netErrorActive){
        this.netPriceService.dismissNetError();
      }
    }else if(!this.analogueIsValid){
      if(this.enableComparator){
        this.ErrorDetails ="Comparator Selection route has been selected in Project Details";
        this.ErrorDetails2 ="";
      }else{
        this.ErrorDetails ="";
        this.ErrorDetails2 ="Analogue Selection route has been selected in Project Details";
      }
      // this.ErrorDetails = '';
      this.ErrorAnalogues = "Please complete the "+ (this.projectService.createProjectFormData.value.comparator === 'true'?"Comparator":"Analogue")+" Selection section to proceed  to the next step";
      this.ErrorNetprice = "Please complete the "+ (this.projectService.createProjectFormData.value.comparator === 'true'?"Comparator":"Analogue")+" Selection and Net Price Assumptions section to proceed to the next step";
      if(this.netPriceService.netErrorActive){
        this.netPriceService.dismissNetError();
      }
      this.netPriceValid = false;
    }else if(!this.netPriceValid){
      this.ErrorDetails = '';
      this.ErrorDetails2 = '';
      this.ErrorAnalogues = '';
      this.ErrorNetprice = "Please complete the Net Price Assumptions section to proceed";
      if(!this.netPriceService.netErrorActive){
        let route = this.projectService.createProjectFormData.value.comparator === 'true'?'comparator':'analogue';
      this.netPriceService.setToastError(route);
      }
    }else{
      this.ErrorDetails = '';
      this.ErrorDetails2 = '';
      this.ErrorAnalogues = '';
      this.ErrorNetprice = "";
    }

  }
  public checkFormValid(check1: boolean, check2: boolean): void {
    this.comparatorValid();
    if(this.projectService.isdetailsValid && this.checkAnalogueValid() && this.checkNetPrice() && this.projectService.scenarios.length != 0 && this.projectService.isScenariosNamesValid){
      if(this.editProjectId === ''){
        this.createProjectDisabled = false;
      }else{
        if(check1){
          if(this.updateFrameworkService.isUpdateFrameWork.value){
            if(this.checkScenario()){
            this.createProjectDisabled = false;
            }else{
            this.createProjectDisabled = true;
            }
          }else{
            this.createProjectDisabled = false;
          }
        }else if(this.projectService.netPriceChanged){
        this.createProjectDisabled = false;
        }
      }
    }else{
      this.createProjectDisabled = true;
    }
    if(this.projectService.isdetailsValid && this.checkAnalogueValid()){
      this.draftProjectDisabled = false;
    }else{
      this.draftProjectDisabled = true;
    }
    if(this.updateFrameworkService.isUpdateFrameWork.value){
      if(this.checkScenario() && this.projectService.isScenariosValid && !this.netPriceService.netErrorActive){
        this.toastNotificationService.dissmiss()
        this.updateFrameworkService.isUpdateFrameWorkComplete.next(true);
      }}
  }

  public checkScenario():boolean{
    let valid = true;
    this.projectService.scenarios.forEach(res=>{
      if(res.ValueAttributeScores === null){
        valid = false;
      }
    })
    return valid;
  }

  public checkAnalogueValid():boolean{
    let state:boolean=false;
    if(this.editProjectId === ''){
      let brandcounts = JSON.parse(JSON.stringify(this.criteriaSelectionService.brandCountData.value));
      if(brandcounts.length > 0){
        brandcounts.forEach((countryData:any) => {
          countryData.brandCount = this.netPriceService.selectedAnalogueNetprice.filter(brandData=>{
            return brandData.markets.filter((m:any)=>m.name.toLowerCase().trim() === countryData.country.toLowerCase().trim()).length>0
          }).length
        });
        state = brandcounts.every(
          (data: any) => data.brandCount >= this.projectService.minMarket
        );
      }
      
    }else{
      state=true;
    }
    this.analogueIsValid = state;
    return state;
  }

  public checkNetPrice():boolean{
    let valid = true;
    valid = this.netPriceService.checkNetPrice()
    this.netPriceValid = valid;
    return valid;
  }

  addScenario() {
    if (this.checkAnalogueValid() && this.checkNetPrice()) {
      this.projectService.addScenario();
      this.projectService.scenarioStatus.next(true);
      this.projectService.isScenariosNamesValid = false;
      //silrang
      this.projectService.checkScenarioStatus(true,false)
    }
  }

  public getScenario(): void {
    this.scenarios = this.projectService.getScenario();
  }

  public clearScenario(): void {
    if (this.valueChangeStatus) {
      this.dialog
        .open(ScenarioWarningComponent, {
          width: '270px',
          data: {
            message:
              'Please fill the incomplete fields required to create the project',
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe((res) => {
          if (res) {
            this.exit();
          }
        });
    } else {
      this.exit();
    }
  }

  public exit(): void {
    const queryParams: Params = { project: this.editProjectId };
    this.projectService.clearScenario();
    this.projectService.scenarioStatus.next(true);
    this.projectService.editProjectId.next('');
    this.projectService.innName.next('');
    this.projectService.selectedScenarioIndex.next(-1);
    this.projectService.valueChangeStatus.next(false);
    this.projectService.validateProjectForm.next(false);
    this.projectService.isDraftProjectFetched.next(false);
    this.projectService.isDraftAnalogueFetched.next(false);
    this.router.navigate(['/horizon/list-projects'], {
      queryParams: queryParams,
    });
  }

  public goToDetails(link: string, index: number): void {
    //if needed in edit project for later
    if(this.checkNavigationValid(index)){
      if (this.editProjectId != ''  && !this.updateFrameworkService.isUpdateFrameWork.value) {
        if(!link.includes('analogue-selection')){
          if (this.valueChangeStatus) {
            if (this.selectedScenarioIndex > 0) {
              this.dialog
              .open(ScenarioWarningComponent, {
                width: '270px',
                data: {
                  message: 'Please save the changes to include this in the project',
                },
              })
              .afterClosed()
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe((res) => {
                if (res) {
                  this.projectService.valueChangeStatus.next(false);
                  this.projectService.updateScenarioDetails(
                    this.currentName,
                    this.selectedScenarioIndex
                  );
                this.projectService.newValuechanged.next(false)
                  this.navigateToDetails(link, index);
   
                }
              });
            }else{
              this.navigateToDetails(link, index);
            }
  
          } else {
            this.navigateToDetails(link, index);
          }
        }
      } else {
        this.navigateToDetails(link, index);
      }
    }
  }

  public checkNavigationValid(index:number):any{
    let allow = false;
    switch (index) {
      case 0:
        if(this.projectService.isdetailsValid && this.enableAnalagoue){
          allow = true;
        }
        break;
        case -2:
        if(this.analogueIsValid){
          allow = true;
        }
        break;
        case -3:
          if(this.projectService.isdetailsValid && this.enableComparator){
            allow = true;
          }
          break;
      default:
        allow = true;
        break;
    }
    return allow;
  }

  public navigateToDetails(link: string, index: number): void {
    this.projectService.selectedScenarioIndex.next(index);
    this.router.navigate([link]);
  }

  deleteScenario(index: any): void {
    if(this.netPriceValid){
      this.projectService.deleteScenario(index);
      if (index < 2) {
        this.goToDetails('/horizon/create-new-project', -1);
        this.projectService.scenarioStatus.next(true);
      } else {
        if(this.projectService.ScenarioId.value != index - 1){
          this.navigateScenario(index - 1, true);
        }
      }
      //silrang
      this.projectService.checkScenarioStatus(true,false)
    }

  }

  public goToScenario(scenarioid: any, check: boolean): void {
    if(this.projectService.isdetailsValid && this.analogueIsValid && this.netPriceValid){
      if (this.editProjectId != '' && !this.updateFrameworkService.isUpdateFrameWork.value) {
        if (this.valueChangeStatus) {
          if (this.selectedScenarioIndex > 0) {
            this.dialog
            .open(ScenarioWarningComponent, {
              width: '270px',
              data: {
                message: 'Please save the changes to include this in the project',
              },
            })
            .afterClosed()
            .pipe(takeUntil(this.unsubscriber$))
            .subscribe((res) => {
              if (res) {
                this.projectService.updateScenarioDetails(
                  this.currentName,
                  this.selectedScenarioIndex
                );
                this.projectService.newValuechanged.next(false)
                this.navigateScenario(scenarioid, check);
              }
            });
          }else{
          this.navigateScenario(scenarioid, check);
          }
  
        } else {
          this.navigateScenario(scenarioid, check);
        }
      } else {
        if (this.projectService.projectFormStatus.value) {
          this.navigateScenario(scenarioid, check);
        } else {
          this.projectService.validateProjectForm.next(true);
          this.toastNotificationService.errorMessage(
            'Please fill the required fields to create the project',
            'info',
            10000
          );
        }
      }
    }
  }

  public navigateScenario(scenarioid: any, check: boolean): void {
    this.projectService.selectedScenarioIndex.next(scenarioid);
    if (check && this.editProjectId !== '') {
      this.projectService.valueChangeStatus.next(false);
      //silrang
      this.projectService.checkScenarioStatus(false,false);
      this.projectService.duplicateScenarioName.next(true);
    }
    const queryParams: Params = { scenario: scenarioid };
    let link =
      this.editProjectId != ''
        ? '/horizon/edit-scenario'
        : '/horizon/add-scenario';
    this.router.navigate([link], { queryParams: queryParams });
  }
  createProject(): void {
    this.projectService.createProject(false);
    this.projectService.createProjectClicked = true;
  }
  editProject(): void {
    if(this.updateFrameworkService.isUpdateFrameWork.value){
      this.projectService.createProject(true,this.editProjectId);
      this.projectService.createProjectClicked = true;
    }else{
      this.projectService.editProject(this.editProjectId, true);
    }
  }
  saveAsDraft() {
    this.projectDraftService.saveAsDraft();
  }
  checkDraftProject() {
    this.projectDraftService.checkDraftProjectExist().then((res: any) => {
      if (res.data) {
        this.isDraftProject = res.data;
        //silrang
        this.projectService.checkScenarioStatus(false,false);
      }
    });
  }
  deleteDraftProject() {
    this.projectDraftService.deleteDraftProject();
  }

  comparatorValid():void{
    if(this.editProjectId === ''){
      if(this.projectService.isdetailsValid){
        if(this.projectService.createProjectFormData.value.comparator === 'true'){
          this.enableComparator = true;
          this.enableAnalagoue = false;

        }else{
          this.enableComparator = false;
          this.enableAnalagoue = true;
        }

      }else{
        this.enableComparator = false;
        this.enableAnalagoue = false;
      }
    }

  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
