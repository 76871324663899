import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShadesLightThemeComponent } from './shades-light-theme.component';

@NgModule({
  declarations: [ShadesLightThemeComponent],
  exports: [ShadesLightThemeComponent],
  imports: [
    CommonModule
  ]
})
export class ShadesLightThemeModule { }
