import { Component, Input } from '@angular/core';

@Component({
  selector: 'he-highlight-text',
  templateUrl: './highlight-text.component.html',
  styleUrls: ['./highlight-text.component.scss']
})
export class HighlightTextComponent {
  @Input() text = '';
  @Input() symbols = '';
}
