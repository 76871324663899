import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { ToastNotificationService } from './toast-notification.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateFrameworkService {
  public isDropDown: boolean = false;
  public frameWorkErrorActive: boolean = false;
  public isUpdateFrameWork = new BehaviorSubject<boolean>(false);
  public isUpdateFrameWorkComplete = new BehaviorSubject<boolean>(true);
  public castIsUpdateFrameWork = this.isUpdateFrameWork.asObservable();
  public updateFrameWorkId = new BehaviorSubject<string>('');
  public castUpdateFrameWorkId = this.isUpdateFrameWork.asObservable();
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  constructor(private http: HttpClient,private toastNotificationService:ToastNotificationService) { }

  updateFrameWorkLog(projectId:string,state:boolean,newProjectId?:string):void{
    let body = {
      projectId:projectId,
      decision: state,
      updated_ValueProjectId: state?newProjectId:null,
    }
    this.http
    .post<any>(this.projectAPIUri +'/ProjectFrameworkUpgradeLogs/CreateProjectFrameworkUpgradeLogs', body)
    .subscribe(
      (data) => {})
  }

  GetValueScoreDisplayType(id:any): Observable<any> {
    return this.http.get(
      `${this.projectAPIUri}/ValueScoreDisplayType/GetValueScoreDisplayType?valueFrameworkId=${id}`
    );
  }

  public setUpdateError():void{
    if(!this.isUpdateFrameWorkComplete.value){
      this.toastNotificationService.errorMessageNew(
        'Please score the updated attributes within the scenario tabs to save your project',
        'info',
        10000
      )
      this.frameWorkErrorActive = true;
    }
  }

}
