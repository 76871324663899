import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterMeasuresPeriodComponent } from './filter-measures-period.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterMeasuresPeriodComponent],
  exports: [FilterMeasuresPeriodComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
})
export class FilterMeasuresPeriodModule {
}
