import { Observable, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';

import { Assert } from '../assert';

import { Gateway } from './gateway';
import { FilterResponseInterface } from './interfaces';

export class EntityFilterPost {
  constructor(private gateway: Gateway) {
    Assert.hasMethod(gateway, 'filter', `Injected gateway ${gateway} has no "filter" method`);
  }

  run(event: FilterResponseInterface): Observable<any> {
    return of(event).pipe(
      debounceTime(1000),
      mergeMap(() => {
        return this.gateway.filter(event);
      })
    );
  }
}
