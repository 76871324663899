import { Component, Input } from '@angular/core';

enum MarketPotencial {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

@Component({
  selector: 'he-color-cell',
  templateUrl: './color-cell.component.html',
  styleUrls: ['./color-cell.component.scss']
})
export class ColorCellComponent {
  @Input() text: any;
  palette: string[] = ['green', 'yellow', 'red'];
  constructor() {}

  public setColor(level: MarketPotencial): string {
    switch (level) {
      case MarketPotencial.Low:
        return this.palette[2];
      case MarketPotencial.Medium:
        return this.palette[1];
      default:
        return this.palette[0];
    }
  }
}
