import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPresetDialogComponent } from './filter-preset-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { DialogModule } from '../dialog';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    FilterPresetDialogComponent
  ],
  exports: [
    FilterPresetDialogComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    MatButtonModule,
    MatInputModule
  ]
})
export class FilterPresetDialogModule { }
