export interface CriteriaInterface {
  title: string;
  infoData:string;
  name: string;
  inputType: string;
  valueList?: string[];
  selectedValue?: string;
  selectedValueList: string[];
  multiInputList?: multiInputListInterface[];
  minValue?: string;
  maxValue?: string;
  multiValueList?: multiValueList[];
}

export interface multiValueList {
  name:string,
  selected:boolean,
}



export interface multiInputListInterface {
  therapyValueList: any[];
  selectedTherapyValue: string;
  indicationValueList: any[];
  selectedIndicationValue: string[];
  selectAll: boolean;

}

export interface CriteriaApiInterFace {
  initialLaunchDate: string[];
  orphan: string[];
  oneOffTherapy: string[];
  population: string[];
  eligiblePatientNumber: patientNumberCriteriaApiInterFace[];
  routeOfAdministration: string[];
  mechanismOfAction: string[];
  therapyAreas: therapyAreasInterface[];
  infoIconData: any[];
  analogueCriteriaInfoIconDTO: any[];
  tli:string[];
  // indications:indicationsInterface[]
}

export interface patientNumberCriteriaApiInterFace {
  marketArea: string;
  patientNumber: string[];
}

export interface therapyAreasInterface {
  therapyArea: string;
  indications: string[];
}
export interface indicationsInterface {
  name: string;
  valueList?: string[];
}
// export interface tagOptionsInterface{
//     tagName:string,
//     tagOptions:therapyAreasInterface[],
// }

export interface savedCriteriaInterface {
  minlaunchDate: number;
  maxlaunchDate: number;
  mustHave: CriteriaInterface[];
  niceToHave: CriteriaInterface[];
  Unselected: CriteriaInterface[];
}

export interface AnalogueTableInterface {
  toggle: {toggle:boolean;disable:boolean;};
  Orphan: { name: string; color: string ;order:number};
  Brand: string;
  INN: string;
  Country: {name:string;id:string}[];
  Oneofftherapy: { name: string; color: string ;order:number};
  Population: { name: string; color: string ;order:number};
  Eligiblepatientnumber: { name: string; color: string ;order:number};
  EligiblePatientNumber: { name: string; color: string ;order:number};
  Indication: { name: string; color: string };
  Routeofadministration: { name: string; color: string ;order:number};
  RouteOfAdministration: { name: string; color: string ;order:number};
  Mechanismofaction: { name: string; color: string ;order:number};
  TherapyArea: { name: string; color: string };
  IndicationCategory: { name: string; color: string;order:number };
  HTAIndication: { name: string; color: string;order:number };
  BiomarkerGenetyping: { name: string; color: string;order:number };
  lineOffTherapy: { name: string; color: string;order:number };
  InitialLaunchDate: { name: string; color: string ;order:number};
  MoaCategory: { name: string; color: string;order:number };
  PriorReimbursedIndications: { name: string; color: string };
  TherapyRegimen: { name: string; color: string;order:number  };
  Tli: { name: string; color: string;order:number };
  TypeOfCombination: { name: string; color: string ;order:number};
  DurationOfTreatment: { name: string; color: string;order:number };

}

export interface criteriaPayloadInterface {
  [key: string]: any;
}
export interface AnalogueListInterface {
  name: string;
  indication: string;
  HTAIndication: string;
  mechanismOfAction: string;
  inn:string,
  sequenceNo: number;
  available: boolean;
  Country: any[];
}

export enum LegendEnum {
  '#FFFFFF4d',
  '#4AA47A4d',
  '#DE9B374d',
  '#d84b444d',
}

export interface ComporatorListInterface {
  name: string;
  HTAIndication: string;
  biomarkerGenetyping: string;
  lineOfTherapy:string,
  indication:string,
  inn:string,
  sequenceNo: number;
  available: boolean;
  Country: any[];
}

export interface ComporatorTableInterface {
  toggle: {toggle:boolean;disable:boolean;};
  Orphan: { name: string; color: string };
  Brand: string;
  INN: string;
  Country: string[];
  //Marketavailability: { name: string; color: string };
  HTAIndication: { name: string; color: string };
  DurationOfTreatment: { name: string; color: string };
  BiomarkerGenetyping: { name: string; color: string;order:number };
  Population: { name: string; color: string;order:number };
  EligiblePatientNumber: { name: string; color: string };
  lineOffTherapy: { name: string; color: string;order:number };
  //Indication: { name: string; color: string };
  RouteOfAdministration: { name: string; color: string };
  Mechanismofaction: { name: string; color: string };
  TherapyArea: { name: string; color: string };
  InitialLaunchDate: { name: string; color: string };
  MoaCategory: { name: string; color: string };
  PriorReimbursedIndications: { name: string; color: string };
  TherapyRegimen: { name: string; color: string };
  Tli: { name: string; color: string };
  TypeOfCombination: { name: string; color: string };
  //IndicationCategory: { name: string; color: string };
}
