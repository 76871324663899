<div class="analogue-card expand-criteria">
  <mat-accordion>
    <mat-expansion-panel
      [expanded]="firstPanelOpen"
      (opened)="firstPanelOpen = true"
      (closed)="firstPanelOpen = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Analogue Selection Criteria </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <p>
          Based on the criteria below, arranged in three levels of importance, a
          list of suggested analogues will be generated and their relevancy will
          be shown in a heat map. You will be able to edit the criteria and
          generate additional analogue matches, if required.
        </p>
        <p class="sub-title" style="color: #4aa47a; margin-top: 24px">
          Must Have Criteria
        </p>
      </div>
      <div class="d-flex d-align-c d-j-c launch-date margin-padding">
        <div class="title d-flex d-align-c">
          <p  style="margin: 0;">Initial launch date</p>
          <div>
            <mat-icon
            #tooltip="matTooltip"
            matTooltip="{{ dateInfoData }}"
            matTooltipClass="horizon-tooltip"
            class="horizon-tooltip"
  
            >info</mat-icon>
          </div>

        </div>
        <div class="range">
          <mat-slider [min]="mindate" [max]="maxdate" discrete>
            <input
              [value]="minlaunchDate"
              [(ngModel)]="minlaunchDate"
              (valueChange)="valueChange()"
              matSliderStartThumb
              id="minLaunchDate"
            />
            <input
              [value]="maxlaunchDate"
              [(ngModel)]="maxlaunchDate"
              (valueChange)="valueChange()"
              matSliderEndThumb
              id="maxLaunchDate"
            />
          </mat-slider>
        </div>
        <div
          class="d-flex d-align-c d-j-start input-range"
          [formGroup]="launchDateForm"
        >
          <p style="margin: 0;">From</p>
          <mat-form-field
            appearance="fill"
            class="horizon-field-03"
            id="minLaunchDate-field"
          >
            <input
              matInput
              type="number"
              [max]="maxlaunchDate !== null ? maxlaunchDate : maxdate"
              [min]="mindate"
              (input)="valueChange()"
              [(ngModel)]="minlaunchDate"
              required
              formControlName="minlaunchDate"
            />
            <mat-error
              style="height: 0; margin-top: -22px"
              *ngIf="minlaunchDate === null"
              >Required</mat-error
            >
          </mat-form-field>
          <p  style="margin: 0;">To</p>
          <mat-form-field
            appearance="fill"
            class="horizon-field-03"
            id="maxLaunchDate-field"
          >
            <input
              matInput
              type="number"
              [max]="maxdate"
              [min]="minlaunchDate !== null ? minlaunchDate : mindate"
              (input)="valueChange()"
              [(ngModel)]="maxlaunchDate"
              required
              formControlName="maxlaunchDate"
            />
            <mat-error
              style="height: 0; margin-top: -22px"
              *ngIf="maxlaunchDate === null"
              >Required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="border-btm">
        <div class="example-container">
          <div
            cdkDropList
            #mustHaveList="cdkDropList"
            [cdkDropListData]="mustHave"
            [cdkDropListConnectedTo]="[UnselectedList, niceToHaveList]"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            id="must-have-criteria-div"
          >
            <div *ngIf="mustHave.length === 0" class="empty-box">
              <p>Drag a must have criteria here</p>
            </div>
            <div class="example-box" *ngFor="let item of mustHave" cdkDrag>
              <div class="d-flex d-align-c left-box">
                <mat-icon class="criteria-icon">menu</mat-icon>
                <p>{{ item.title }}</p>
                <div>
                  <mat-icon
                  #tooltip="matTooltip"
                  matTooltip="{{ item.infoData }}"
                  matTooltipClass="horizon-tooltip"
                  class="horizon-tooltip"
                  >info</mat-icon>
                </div>
              </div>
              <div class="right-box">
                <he-criteria-input
                  [inputdata]="item"
                  (onValueChange)="valueChange()"
                ></he-criteria-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-btm2">
        <p class="sub-title" style="color: #de9b37">Nice To Have Criteria</p>
      </div>
      <div class="border-btm">
        <div class="example-container">
          <div
            cdkDropList
            #niceToHaveList="cdkDropList"
            [cdkDropListData]="niceToHave"
            [cdkDropListConnectedTo]="[mustHaveList, UnselectedList]"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            id="nice-to-have-criteria-div"
          >
            <div *ngIf="niceToHave.length === 0" class="empty-box">
              <p>Drag a nice to have criteria here</p>
            </div>
            <div class="example-box" *ngFor="let item of niceToHave" cdkDrag>
              <div class="d-flex d-align-c left-box">
                <mat-icon class="criteria-icon">menu</mat-icon>
                <p>{{ item.title }}</p>
                <div>
                  <mat-icon
                  #tooltip="matTooltip"
                  matTooltip="{{ item.infoData }}"
                  matTooltipClass="horizon-tooltip"
                  class="horizon-tooltip"
                  >info</mat-icon>
                </div>
              </div>
              <div class="right-box">
                <he-criteria-input
                  [inputdata]="item"
                  (onValueChange)="valueChange()"
                ></he-criteria-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-btm2">
        <p class="sub-title" style="color: #374050; opacity: 50%">
          Unselected Criteria
        </p>
      </div>
      <div class="example-container">
        <div
          cdkDropList
          #UnselectedList="cdkDropList"
          [cdkDropListData]="Unselected"
          [cdkDropListConnectedTo]="[mustHaveList, niceToHaveList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)"
          id="unselected-criteria-div"
        >
          <div *ngIf="Unselected.length === 0" class="empty-box">
            <p>Drag a Unselected criteria here</p>
          </div>
          <div class="example-box" *ngFor="let item of Unselected" cdkDrag>
            <div class="d-flex d-align-c left-box">
              <mat-icon class="criteria-icon">menu</mat-icon>
              <p>{{ item.title }}</p>
              <div>
                <mat-icon
                #tooltip="matTooltip"
                matTooltip="{{ item.infoData }}"
                matTooltipClass="horizon-tooltip"
                class="horizon-tooltip"
                >info</mat-icon>
              </div>
            </div>
            <div class="right-box">
              <he-criteria-input
                [inputdata]="item"
                (onValueChange)="valueChange()"
              ></he-criteria-input>
            </div>
          </div>
        </div>
      </div>
      <div class="criteria-footer">
        <div class="footer-action">
          <button
            mat-button
            class="btn-02"
            (click)="reset()"
            id="reset-analogues-btn"
          >
            Reset
          </button>
          <button
            mat-button
            class="btn-01"
            [disabled]="
              mustHave.length === 0 && mustHave[0]?.selectedValue !== null
            "
            (click)="Findanalogues()"
            id="find-analogues-btn"
          >
            Find analogues
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="secondPanelOpen"
      (opened)="secondPanelOpen = true"
      (closed)="secondPanelOpen = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> ANALOGUE HEAT MAP AND SELECTION </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <ng-container *ngIf="!newHeatmap"    
>
          <div class="d-flex d-j-between">
            <div class="chip-list" *ngIf="analogues.length === 0">
              <p class="non-selected">None selected</p>
            </div>
            <div class="chip-list" *ngIf="analogues.length !== 0">
              <mat-chip
                *ngFor="let analogue of analogues"
                (removed)="removeAnalogue(analogue)"
                [ngClass]="{ unavailable: !analogue.available }"
              >
                {{ analogue.name }}
                <button matChipRemove>
                  <mat-icon>close</mat-icon>
                </button>
              </mat-chip>
            </div>
            <button
            mat-button
            class="btn-01"
            [disabled]="!criteriaSelectionService.isAnaloguesValid"
            (click)="addAnalogues()"
            id="add-analogues-btn"
          >
            Add analogues
          </button>
          </div>
        </ng-container>
        <ng-container *ngIf="newHeatmap">
          <div class="d-flex d-j-between">
            <div><p *ngIf="!criteriaSelectionService.isAnaloguesValid">At least 5 analogues are required per market</p></div>
            <button
              mat-button
              class="btn-01"
              [disabled]="!criteriaSelectionService.isAnaloguesValid"
              (click)="addAnalogues()"
              id="add-analogues-btn"
            >
              Add analogues
            </button>
          </div>
          <div style="margin-bottom: 20px;">
            <div *ngFor="let market of newSelectedMarket" class="country-chip"> 
                <mat-chip style="width:144px" class="not-selected" [ngClass]="{ selected:  market.country === countryFilter}" (click)="selectCountry(market.country)">
                  <he-country-cell country="{{ market.country }}" ></he-country-cell>
                  </mat-chip>
                <div class="analogue-list">
                  <ng-container *ngIf="market.analogues.length != 0">
                    <div *ngFor="let analogue of market.analogues">
                      <mat-chip  [ngClass]="{ unavailable: !analogue.available }" (removed)="removeAnalogue(analogue)">{{analogue.name}}
                        <button matChipRemove>
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-chip>
  
                    </div>
                  </ng-container>
                  <ng-container *ngIf="market.analogues.length == 0">
                    <div>
                      <mat-chip> None selected</mat-chip>
                    </div>
                  </ng-container>
                </div>
            </div>
          </div>
        </ng-container>
        <p>
         Selected analogues are displayed above and will be used in this project for analysis
        </p> 
        <p *ngIf="ValueFrameworkName === 'Oncology'">
          Please note that only one HTA indication may be selected per brand
         </p>
        <p class="sub-title" style="margin-top: 24px">
          Analogues Heat Map
          <span style="font-weight: 100"
            >(Please select the relevant analogues for your project):</span
          >
        </p>
      </div>
      <he-analoguei-heat-map
        [loading]="loading"
        [selectedAnalogue]="analogues"
        [sortedAnalogues]="sortedAnalogues"
        (emitAnalogueList)="getAnalogueList($event)"
        (refreshAnalogue)="getAnalogue()"
        (emitRemovedAnalogues)="removeAnalogue($event)"
        (removeCountryFilter)="selectCountry($event)"
      ></he-analoguei-heat-map>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="thirdPanelOpen"
      (opened)="thirdPanelOpen = true"
      (closed)="thirdPanelOpen = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Analogue sorting </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sub-header" style="margin-bottom: 14px">
        <p>
          The order of the analogues selected here will be displayed in all
          pages.
        </p>
      </div>
      <div
        class="new-project-card"
        *heFeatureFlag="{
          Name: 'add-analogues-form',
          Page: 'create-new-project'
        }"
      >
        <mat-table
          #table
          matSort
          (matSortChange)="sortData($event)"
          [dataSource]="sortedAnalogues"
          cdkDropList
          [cdkDropListData]="sortedAnalogues"
          (cdkDropListDropped)="dropSorting($event)"
          id="analogue-sorting-table"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="name"
              class="heading-color"
            >
              Brand Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              <div style="display: grid; margin-right: 5px" class="movebtn">
                <button
                  mat-icon-button
                  (click)="moveUp(i)"
                  [disabled]="i < 1"
                  [ngClass]="i < 1 ? 'disabled' : ''"
                >
                  <mat-icon class="arrow-icons">expand_less</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="moveDown(i)"
                  [disabled]="i === sortedAnalogues.length - 1"
                  [ngClass]="
                    i === sortedAnalogues.length - 1 && i > 0 ? 'disabled' : ''
                  "
                >
                  <mat-icon class="arrow-icons">expand_more</mat-icon>
                </button>
              </div>
              {{ element.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="indication" *ngIf="ValueFrameworkName === 'Orphan'">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="indication"
              class="heading-color"
            >
              Indication
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.indication }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mechanismOfAction" >
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="mechanismOfAction"
              class="heading-color"
            >
              Mechanism of Action</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              {{ element.mechanismOfAction }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="therapyArea">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="therapyArea"
              class="heading-color"
            >
              Therapy Area</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              [matTooltip]="element.therapyArea"
              matTooltipClass="horizon-tooltip"
            >
              <div class="horizon-text-ellipsis">{{ element.therapyArea }}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="inn" *ngIf="ValueFrameworkName === 'Oncology'">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="inn"
              class="heading-color"
            >
            INN</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              [matTooltip]="element.inn"
              matTooltipClass="horizon-tooltip"
            >
              <div class="horizon-text-ellipsis">{{ element.inn }}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="HTAIndication" *ngIf="ValueFrameworkName === 'Oncology'">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="HTAIndication"
              class="heading-color"
            >
            HTA Indication</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              [matTooltip]="element.HTAIndication"
              matTooltipClass="horizon-tooltip"
            >
              <div class="horizon-text-ellipsis">{{ element.HTAIndication }}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="remove">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <button class="removeCell" (click)="RemoveAnalogue(i)">
                Remove
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="ValueFrameworkName === 'Orphan'?analogueColumns:oncoAnalogueColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: ValueFrameworkName=== 'Orphan'?analogueColumns:oncoAnalogueColumns; let i = index"
            cdkDrag
            [cdkDragData]="row"
          ></mat-row>
        </mat-table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
