import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function noSpecialCharactersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    
    if (!value) {
      return null;
    }

    const specialCharactersRegex = /[?!#%*<>~'']/;

    const hasSpecialCharacters = specialCharactersRegex.test(value);

    return hasSpecialCharacters ? { noSpecialCharacters: true } : null;
  };
}
