import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'he-lato-font',
  templateUrl: './lato-font.component.html',
  styleUrls: ['./lato-font.component.scss']
})
export class LatoFontComponent {
}
