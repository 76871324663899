import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ErrorMessageComponent } from '../../modules/project/sub-components/error-message/error-message.component';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {

  constructor(private _snackBar: MatSnackBar) { }

  public errorMessage(message:string,icon?:string,duration:number=4000):void{
    if(icon == null){
      icon = 'info'
    }
    this._snackBar.openFromComponent(ErrorMessageComponent,{
      data:{message:message,icon:icon},
      duration:duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass:'horizon-error'
    });

  }

  public TwoFAerrorMessage(message:string,icon?:string,duration:number=4000):void{
    if(icon == null){
      icon = 'info'
    }
    this._snackBar.openFromComponent(ErrorMessageComponent,{
      data:{message:message,icon:icon},
      duration:duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass:'horizon-error-2FA'
    });

  }

  public successMessage(message:string,icon?:string):void{
    if(icon == null){
      icon = 'check_circle'
    }
    this._snackBar.openFromComponent(ErrorMessageComponent,{
      data:{message:message,icon:icon},
      duration:4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass:'horizon-success'
    });
  }

  public TwoFAsuccessMessage(message:string,icon?:string):void{
    if(icon == null){
      icon = 'check_circle'
    }
    this._snackBar.openFromComponent(ErrorMessageComponent,{
      data:{message:message,icon:icon},
      duration:3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass:'horizon-success-2FA'
    });
  }

  public errorMessageNew(message:string,icon?:string,duration:number=4000):void{
    if(icon == null){
      icon = 'info'
    }
    this._snackBar.openFromComponent(ErrorMessageComponent,{
      data:{message:message,icon:icon},
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass:'horizon-error'
    });

  }

  public informationMessage(message:string,icon?:string):void{
    if(icon == null){
      icon = 'check_circle'
    }
    this._snackBar.openFromComponent(ErrorMessageComponent,{
      data:{message:message,icon:icon},
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass:'horizon-success'
    });
  }

  public dissmiss():void{
    this._snackBar.dismiss()
  }
}
