import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingSpinnerComponent } from './loading-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalSpinnerService {

  constructor(public dialog: MatDialog,) { }

  public loadingSpinner(state:boolean): void {
    if(state){
      this.dialog.open(LoadingSpinnerComponent, {
        width: '270px',
        panelClass: 'gloabl-spinner-dialog',
        disableClose:true
      });
    }else{
      this.dialog.closeAll()
    }
  }
}
