import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ProjectDetailsComponent } from './sub-components/project-details/project-details.component';
import { ProjectSidebarComponent } from './sub-components/project-sidebar/project-sidebar.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ProjectTopbarComponent } from './sub-components/project-topbar/project-topbar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { DndDirective } from './sub-components/project-details/dnd.directive';
import { ProgressBarComponent } from './sub-components/progress-bar/progress-bar.component';
import { AddScenarioComponent } from './components/add-scenario/add-scenario.component';
import { ScenarioDetailsComponent } from './sub-components/scenario-details/scenario-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CollaboratorComponent } from './sub-components/collaborator/collaborator.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationsComponent } from './sub-components/notifications/notifications.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScenarioWarningComponent } from './sub-components/scenario-warning/scenario-warning.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AnalogueSelectionCriteriaComponent } from './sub-components/analogue-selection-criteria/analogue-selection-criteria.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { CriteriaInputComponent } from './sub-components/criteria-input/criteria-input.component';
import { LoadingSpinnerModule } from 'projects/helios-gui/src/uikit/loading-spinner';
import { SearchBarPipe } from './pipes/search-bar.pipe';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CreateNewProjectComponent } from './components/create-new-project/create-new-project.component';
import { AnalogueSelectionComponent } from './components/analogue-selection/analogue-selection.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { AnalogueiHeatMapComponent } from './sub-components/analoguei-heat-map/analoguei-heat-map.component';
import { ErrorMessageComponent } from './sub-components/error-message/error-message.component';
import { DeleteProjectComponent } from './components/delete-project/delete-project.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AnalogueHelperDialogComponent } from './sub-components/analogur-helper-dialog/analogue-helper-dialog/analogue-helper-dialog.component';
import { RefreshProjectDataComponent } from './components/refresh-project-data/refresh-project-data.component';
import { ProjectCotRefreshDataComponent } from './sub-components/project-cot-refresh-data/project-cot-refresh-data.component';
import { PriceAssumptionComponent } from './components/price-assumption/price-assumption.component';
import { PriceInclusionComponent } from './sub-components/price-inclusion/price-inclusion.component';
import { NetPriceAssumptionComponent } from './sub-components/net-price-assumption/net-price-assumption.component';
import { OverallPriceAssumptionComponent } from './sub-components/overall-price-assumption/overall-price-assumption.component';
import { SpecificPriceAssumptionComponent } from './sub-components/specific-price-assumption/specific-price-assumption.component';
import { SpecificInputComponent } from './sub-components/specific-input/specific-input.component';
import { AutoTrimInputDirective } from './directives/auto-trim-input.directive';
import { ComporatorSelectionComponent } from './sub-components/comporator-selection/comporator-selection.component';
import { ComparatorSelectionMainComponent } from './components/comparator-selection-main/comparator-selection-main.component';
import { ComporatorHeatMapComponent } from './sub-components/comporator-heat-map/comporator-heat-map.component';
import { CapitalizeFirstPipe } from './pipes/capitalizeFirst.pipe';

@NgModule({
  declarations: [
    CreateNewProjectComponent,
    ProjectDetailsComponent,
    ProjectSidebarComponent,
    ProjectTopbarComponent,
    DndDirective,
    ProgressBarComponent,
    AddScenarioComponent,
    ScenarioDetailsComponent,
    SearchBarPipe,
    CapitalizeFirstPipe,
    CollaboratorComponent,
    NotificationsComponent,
    ScenarioWarningComponent,
    AnalogueSelectionComponent,
    AnalogueSelectionCriteriaComponent,
    CriteriaInputComponent,
    AnalogueiHeatMapComponent,
    ErrorMessageComponent,
    DeleteProjectComponent,
    AnalogueHelperDialogComponent,
    RefreshProjectDataComponent,
    ProjectCotRefreshDataComponent,
    PriceAssumptionComponent,
    PriceInclusionComponent,
    NetPriceAssumptionComponent,
    OverallPriceAssumptionComponent,
    SpecificPriceAssumptionComponent,
    SpecificInputComponent,
    AutoTrimInputDirective,
    ComporatorSelectionComponent,
    ComparatorSelectionMainComponent,
    ComporatorHeatMapComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatListModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    DragDropModule,
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSliderModule,
    LoadingSpinnerModule,
    MatSlideToggleModule,
    MatSortModule,ModalModule.forRoot(),
  ],
  providers:[TitleCasePipe,CapitalizeFirstPipe]
})
export class ProjectModule {}
