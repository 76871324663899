import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { UpdateFrameworkService } from '../../shared/services/update-framework.service';

export interface ValueAttributeScore {
  valueAttributeScoreId: string;
  valueDomainName: string;
  valueAttributeName: string;
  score: string;
  scoreDescription: string;
}
export interface ValueAttribute {
  valueAttributeName: string;
  valueDomainName: string;
  valueScore: string;
}

@Injectable({
  providedIn: 'root',
})
export class ValueAttributeScoresService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  constructor(private http: HttpClient,private updateFrameworkService:UpdateFrameworkService) {}
  getValueAttributeScores(valueFrameworkId: string): Observable<any[]> {
    let id = this.updateFrameworkService.isUpdateFrameWork.value?this.updateFrameworkService.updateFrameWorkId.value:valueFrameworkId;
    return this.http.get<any>(
      this.projectAPIUri +
        `/ValueFramework/ValueAttributeScores?valueFrameworkId=${id}`
    );
  }

  getValueAttributeBaseCaseScores(
    projectId: string,
    country: string
  ): Observable<any> {
    const requestData = {
      projectId,
      country,
    };
    return this.http.post<any>(
      this.projectAPIUri + `/ValueAttributeScore/AssetValueScores`,
      requestData
    );
  }
}
