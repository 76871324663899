import { Subject, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatastoreContext } from './datastore';
import { UniversalSearchContext } from './universal-search';
import { MobileDrawer } from './layout/drawer';
import { NavigationStart, Router } from '@angular/router';
import { UserProfile } from './profile/menu/user.profile';

export let browserRefresh = false;

declare let pendo: any;

@Component({
  selector: 'he-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    DatastoreContext,
    { provide: UniversalSearchContext, useExisting: DatastoreContext },
    { provide: MobileDrawer, useValue: new Subject() },
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Helios';
  subscription: Subscription;

  constructor(private router: Router,
    private currentProfile: UserProfile) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit() {
    const userProperties = {
      username: this.currentProfile.getUserName(),
      email: this.currentProfile.getEmail(),
      domain: 'undefined'
    };

    try {
      userProperties.domain = userProperties.email.split('@')[1].split('.')[0]
    } catch { }

    // this.$gaService.set('user_properties', userProperties);

    pendo.initialize({
      visitor: {
        id: userProperties.email,
        name: userProperties.username
      },
      account: {
        id: userProperties.domain
      }
    });

    localStorage.setItem("user_name",this.currentProfile.getUserName())
    localStorage.setItem("user_email",this.currentProfile.getEmail())
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
