import { FilterNameEnum } from 'projects/helios-gui/src/app/datastore/interfaces';
import { FilterPresetTypes } from './enums';
import { IFilterPresetKeyNames, IFilterPresetDefaults } from './interfaces';
import { FilterDefaultsCOTAdult } from '../../constants';

export const FilterPresetKeyNames: IFilterPresetKeyNames = {
  [FilterPresetTypes.Measures]: [
    'calcPer',
    'cotType',
    'cotLevel',
    'includeWastage',
    'priceCategory',
    'priceType',
    'currency',
    'averageBodyWeight',
    'bodySurfaceArea',
  ],
};

export const FilterPresetDefaults: IFilterPresetDefaults = {
  [FilterPresetTypes.Measures]: [
    {
      name: 'Pulse Default',
      preset: [
        {
          filterName: FilterNameEnum.calcPer,
          filterValue: [
            {
              name: 'Month',
              type: 'month',
            },
          ],
        },
        {
          filterName: FilterNameEnum.cotType,
          filterValue: [
            {
              name: 'Adult',
              type: 'Adult',
            },
          ],
        },
        {
          filterName: FilterNameEnum.includeWastage,
          filterValue: [
            {
              name: 'Include wastage',
              type: true,
            },
          ],
        },
        {
          filterName: FilterNameEnum.priceCategory,
          filterValue: [
            {
              name: 'List',
              type: 'List',
            },
          ],
        },
        {
          filterName: FilterNameEnum.priceType,
          filterValue: [
            {
              name: 'Price Hierarchy',
              type: 'hierarchy',
            },
          ],
        },
        {
          filterName: FilterNameEnum.cotLevel,
          filterValue: [
            {
              name: 'Lowest',
              type: 'Lowest',
            },
          ],
        },
        {
          filterName: FilterNameEnum.currency,
          filterValue: [
            {
              name: 'Euro (€)',
              type: 'EUR',
            },
          ],
        },
        {
          filterName: FilterNameEnum.averageBodyWeight,
          filterValue: [
            {
              name: FilterDefaultsCOTAdult.averageBodyWeight,
            },
          ],
        },
        {
          filterName: FilterNameEnum.bodySurfaceArea,
          filterValue: [
            {
              name: FilterDefaultsCOTAdult.bodySurfaceArea,
            },
          ],
        },
      ],
    },
  ],
};
