import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from '../profile/menu/user.profile';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ErrorPageText, ErrorStatusCodes } from '../common';

@Component({
  selector: 'he-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent implements OnInit, OnDestroy {
  private readonly destroying$: Subject<void> = new Subject<void>();
  public message: string = '';
  public errorType: string = '';

  constructor(
    private currentProfile: UserProfile,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  ngOnInit(): void {
    this.route.url
      .pipe(takeUntil(this.destroying$))
      .subscribe((url: UrlSegment[]) => {
        this.setErrorMessage(url);
      });
  }

  setErrorMessage(url: UrlSegment[]) {
    let errorMessage = ErrorPageText.ConnectivityIssueMessage;
    if (url?.length) {
      switch (url[0]?.path) {
        case 'error':
          if (url.length > 1) {
            this.errorType = url[1]?.path;

            switch (this.errorType) {
              case ErrorStatusCodes.ServiceUnavailable:
                errorMessage = ErrorPageText.ConnectivityIssueMessage;
                break;
              case ErrorStatusCodes.InsufficientPermissions:
                errorMessage = ErrorPageText.InsufficientPermissionsMessage;
                break;
            }
          }
          break;
      }
    }

    this.message = errorMessage;
  }

  logout(): void {
    this.currentProfile.logout();
  }
}
