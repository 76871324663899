<div class="chart-container" #dataToExport>
  <div class="chart-options">
    <div>
      <div>
        <span class="title">{{prieceLabel}}</span>
      </div>
      <div class="horizon-field">
        <mat-form-field>
          <span class="dropdown-title">Price type</span>
          <mat-select 
            name="priceType" 
            [(ngModel)]="selectedPriceType" 
            (selectionChange)="priceTypeChange($event.value)"
            class="custom-dropdown">
            
            <mat-select-trigger>
              <div class="custom-trigger-content">
                {{ getSelectedViewValue() || 'Select a Price Type' }}
                <mat-icon>arrow_drop_down</mat-icon>
              </div>
            </mat-select-trigger>
        
            <ng-container *ngFor="let priceType of priceTypes">
              <mat-option
                *ngIf="priceType.visible"
                [value]="priceType.value"
                [disabled]="priceType.disabled">
                {{ priceType.viewValue }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <a class="download-div" (click)="downloadAsPdf()"
          ><img
            src="/assets/dwonload-purple.svg"
            class="dwonload-icon"
            alt="Export Data"
            href="#"
        /></a>
        <mat-icon
        class="infodata"
        #tooltip="matTooltip"
        matTooltip="{{ info }}"
        [matTooltipPosition]="'after'"
        matTooltipClass="horizon-tooltip"
        >info</mat-icon
      >
      </div>
      <div class="chart-subtitle">
        <span class="subtitle"
          >{{selectedRoute === 'Analogues'?'ANALOGUE':'COMPARATOR'}}
          <span class="reset-button">
            <mat-icon (click)="resetGraph()">restart_alt</mat-icon>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div
    echarts
    [options]="chartOption"
    [merge]="updateChartOption"
    class="demo-chart"
    (chartInit)="onChartInit($event)"
    (chartLegendSelectChanged)="onLegendSelectChanged($event)"
  ></div>
  <div class="keys">
    <div>
      <span class="title">Please note:</span>
    </div>
    <div>
      <span> For combination therapies, the annual treatment cost includes costs of all therapy components.
      </span>
    </div>
  </div>
  <div class="note" *heFeatureFlag="{ Name: 'div-bayer-note', Page: 'common' }">
    Note - A 5-year time horizon was applied to calculate COT using loading
    (Year 1) and maintenance doses (Year 2-5)
  </div>
</div>
<div id="download">
  <img #canvas />
  <a #downloadLink></a>
</div>
