import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NetPriceService } from '../../services/net-price.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'he-specific-input',
  templateUrl: './specific-input.component.html',
  styleUrls: ['./specific-input.component.scss']
})
export class SpecificInputComponent implements OnInit, OnChanges {
  @Input() readonly: any;
  @Input() isAnnualCOTInlcuded: boolean=false;
  @Input() value: any;
  @Input() currency: any;
  @Output() public updatePrice = new EventEmitter<any>();
  public error =''
  public errorPer =''
  public percentage = new FormControl('', [
    Validators.min(0),
    Validators.max(100),
  ]);
  public cot = new FormControl('', [
    Validators.min(1),
  ]);

  constructor(private netPriceService: ProjectService){}

  ngOnChanges(changes: SimpleChanges): void {
    this.setError();
  }

  ngOnInit(): void {
    this.netPriceService.castValueChangeStatus.subscribe(res=>{
      // if(this.isAnnualCOTInlcuded){
        
      // }else{
        this.percentage.patchValue(this.value.NetPriceDiscount)
      // }
      this.setError();
      })
  }


  public changePrice(): void {
    if(this.isAnnualCOTInlcuded){
      this.value.AnalogueCOT = this.value.AnalogueCOT;
    }else{
      this.value.NetPriceDiscount = this.value.NetPriceDiscount != null ?this.value.NetPriceDiscount.toFixed(2):this.value.NetPriceDiscount;
    }
    this.setError();
  this.updatePrice.emit(this.value);
}

public setError():void{
  if(this.isAnnualCOTInlcuded){
    if(this.value.AnalogueCOT < 0){
      this.error ='Enter a positive value'
      this.errorPer = '';
    }else if(this.value.AnalogueCOT > 0){
      this.error = '';
      this.errorPer = '';
    }else{
      this.errorPer = '';
      this.error = '';
    }
  }else{
    if(this.value.NetPriceDiscount < 0){
      this.errorPer ='Enter a positive value'
      this.error = '';
    }else if(this.value.NetPriceDiscount > 100){
      this.errorPer = 'Enter a value less than 100%';
      this.error = '';
    }else{
      this.errorPer = '';
      this.error = '';
    }
  }


}
}
