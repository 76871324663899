import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { HelpMenuComponent } from './help-menu.component';
import { IconModule } from '../../uikit/helios-icons';

@NgModule({
    declarations: [HelpMenuComponent],
    exports: [HelpMenuComponent],
    imports: [
        CommonModule,
        IconModule,
        MatButtonModule,
        MatMenuModule
    ]
})
export class HelpMenuModule {
}