import { Component, Inject , OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'he-upload-warning',
  templateUrl: './upload-warning.component.html',
  styleUrls: ['./upload-warning.component.scss']
})
export class UploadWarningComponent {
  public message: string = '';
  constructor(public dialogRef: MatDialogRef<UploadWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
      this.message=this.data.message
    }

  public onClick(state: boolean): void {
    this.dialogRef.close(state);
  }
}
