<div class="card-he project-details-card project-details-tab">
  <div class="card-left" style="z-index: 999999">
    <mat-card class="mat-sub-card">
      <span class="heading">PROJECT DETAILS</span>
      <mat-card-content>
        <ul>
          <li>
            <div class="title">Project name</div>
            <div class="value">
              {{ project }}
              <div *ngIf="isPriceChange" >
                <a
                  routerLink="/horizon/project-cot-refresh/{{ projectId }}"
                  routerLinkActive="router-link-active"
                  *heFeatureFlag="{ Name: 'new-price-alert', Page: 'project-details' }"
                >
                  New Price Available</a
                >
              </div>
              <div *ngIf="isFrameWork" >
                <a
                  (click)="updateFrameWorkPopUp()"
                  *heFeatureFlag="{ Name: 'update-framework', Page: 'dashboard' }"
                >
                Updated Framework Available
                </a
                >
              </div>
            </div>
          </li>
          <li>
            <div class="title">Top Level Indication</div>
            <div class="value">{{ tli }}</div>
          </li>
          <li>
            <div class="title">Framework</div>
            <div class="value">{{ frameworkName }}</div>
          </li>
          <li>
            <div class="title">Project Owner:</div>
            <div class="inline-centered-items">
              <div class="tooltip-he">
                <div class="mat-icon-div">
                  <mat-icon
                    class="he-ava-btn he-btn-themed"
                    mat-card-avatar
                    mat-mini-fab
                  >
                    <span class="he-ava-text">{{
                      projectOwnerName | nameInitials | uppercase
                    }}</span>
                  </mat-icon>
                </div>
                <div class="tooltiptext">
                  <div class="tooltiptext-owner flex-center">
                    <div>
                      <mat-icon
                        class="he-ava-btn he-btn-themed tooltiptext-icon"
                        style="font-size: 50px"
                        mat-card-avatar
                        mat-mini-fab
                      >
                        <span class="he-ava-text">{{
                          projectOwnerName | nameInitials | uppercase
                        }}</span>
                      </mat-icon>
                    </div>
                    <div
                      class="value owner-value tooltiptext-owner-name"
                      style="flex: 1"
                    >
                      {{ projectOwnerName }}
                    </div>
                  </div>
                  <div class="tooltip-contact">
                    <span>Contact</span><br />
                    <span>{{ projectOwnerEmail }}</span>
                  </div>
                </div>
              </div>
              <span
                class="value owner-value"
                style="
                  margin-left: 10px;
                  inline-size: 84%;
                  overflow-wrap: break-word;
                "
                >{{ projectOwnerName }}</span
              >
            </div>
          </li>
          <li>
            <div class="title">Collaborators:</div>
            <ng-container *ngIf="collaborators && collaborators.length > 0; else nocollaborators">
            <div id="collaborators-container" class="collaborator-list">
              <div
                class="tooltip-he"
                *ngFor="let collaborator of collaborators; let i = index"
              >
                <mat-icon
                  mat-card-avatar
                  class="he-ava-btn he-btn-themed"
                  aria-label="Project collaborators"
                  mat-mini-fab
                >
                  <span class="he-ava-text">{{
                    collaborator.fullName | nameInitials | uppercase
                  }}</span>
                </mat-icon>

                <div
                  class="tooltiptext"
                  [ngClass]="{
                    'middle-item-tooltip': i === 2 || i === 3,
                    'right-item-tooltip': i === 4 || i === 5
                  }"
                >
                  <div class="tooltiptext-owner flex-center">
                    <div>
                      <mat-icon
                        class="he-ava-btn he-btn-themed"
                        style="font-size: 50px"
                        mat-card-avatar
                        mat-mini-fab
                      >
                        <span class="he-ava-text">{{
                          collaborator.fullName | nameInitials | uppercase
                        }}</span>
                      </mat-icon>
                    </div>
                    <div
                      class="value owner-value tooltiptext-owner-name"
                      style="flex: 1"
                    >
                      {{ collaborator.fullName }}
                    </div>
                  </div>
                  <div class="tooltip-contact">
                    <span>Contact</span><br />
                    <span>{{ collaborator.email }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #nocollaborators>
            NA
           </ng-template>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="card-right">
    <div class="card-right-top-align-project-details">
      <div>
        <span class="title">Project details</span>
        <div class="value project-description">
          <ul>
            <ng-container *ngIf="projectDescription && projectDescription.length > 0; else noDescription">
              <li *ngFor="let item of projectDescription" class="project-details-list">
                {{ item }}
              </li>
            </ng-container>
            <ng-template #noDescription>
             NA
            </ng-template>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-right-top-align-scenario">
      <div class="tile-container">
        <span class="title">Available Scenarios</span>
        <ul class="project-details-scenarios scenario-list">
          <li class="scenario" *ngFor="let item of scenarios; let i = index">
            <span class="scenario-name">Scenario {{ alpha[i] }}:</span>
            <span class="scenario-value">{{ item.scenarioName }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="">
      <div class="tile-container">
        <span class="title">Markets</span>
        <div class="project-details-row" id="markets-container">
          <div
            *ngFor="let market of markets"
            class="pill-button inline-centered-items"
          >
            <he-country-cell
              country="{{ market.countryName }}"
            ></he-country-cell>
          </div>
        </div>
        <ul class="project-details-row" style="margin-top: 15px">
          <li>
            <div>
              <span class="title">Created On</span>
              <span class="gap-left created-modified-date">{{
                createdOn | date : "MMM d, y"
              }}</span>
            </div>
          </li>
          <li>
            <div class="inline-centered-items">
              <span class="title by">By:</span>
              <mat-icon
                mat-card-avatar
                class="he-ava-btn he-btn-themed"
                aria-label="Project collaborators"
                mat-mini-fab
              >
                <span class="he-ava-text">{{
                  createdBy | nameInitials | uppercase
                }}</span>
              </mat-icon>
              <span class="value owner-value created-by">{{ createdBy }}</span>
            </div>
          </li>
          <li>
            <div>
              <span class="title">Modified On</span>
              <span class="gap-left created-modified-date">{{
                modifiedOn | date : "MMM d, y"
              }}</span>
            </div>
          </li>
          <li>
            <div class="inline-centered-items">
              <span class="title by">By:</span>
              <mat-icon
                mat-card-avatar
                class="he-ava-btn he-btn-themed"
                aria-label="Project collaborators"
                mat-mini-fab
              >
                <span class="he-ava-text">{{
                  modifiedBy | nameInitials | uppercase
                }}</span>
              </mat-icon>
              <span class="value owner-value created-by">{{ modifiedBy }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div></div>
    </div>
  </div>
</div>
