<div class="table-container net-price-table" *ngIf="!loading">
  <table mat-table [dataSource]="dataSource" class="test">
    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
  
    <!-- Name Column -->
    <ng-container matColumnDef="Brand" >
      <th mat-header-cell *matHeaderCellDef class="mat-column-brandName">Brand</th>
      <td mat-cell *matCellDef="let element"  class="mat-column-brandName">{{ element.Brand }}</td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="INN" >
      <th mat-header-cell *matHeaderCellDef  class="mat-column-brandName">INN</th>
      <td mat-cell *matCellDef="let element"  class="mat-column-brandName">{{ element.INN }}</td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="TLI" >
      <th mat-header-cell *matHeaderCellDef  class="mat-column-brandName">TLI</th>
      <td mat-cell *matCellDef="let element"  class="mat-column-brandName">{{ element.TLI }}</td>
    </ng-container>

    <ng-container matColumnDef="HTAIndication"  *ngIf="projectService.selectedValueFramework.valueFrameworkName === 'Oncology'">
      <th mat-header-cell *matHeaderCellDef class="mat-column-indicationHTA-header" >HTA Indication</th>
      <td mat-cell *matCellDef="let element" class="mat-column-indicationHTA">{{ element.HTAIndication || 'NA' }}</td>
    </ng-container>
    
    <ng-container
      *ngFor="let country of ProjectNetPrice.OverallNetPrice"
      [matColumnDef]="country.country"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="mat-column-price"
      >
        <div style="display: flex;  align-items: center">
          <he-country-cell [country]="country.country"></he-country-cell>
          <mat-icon
            #tooltip="matTooltip"
            matTooltip="{{ country.info }}"
            matTooltipClass="horizon-tooltip"
            >info</mat-icon
          >
        </div>
      </th>
      <td mat-cell class="mat-column-price" *matCellDef="let element">
        <he-specific-input
          [readonly]="ProjectNetPrice.IsNetPriceIncluded"
          [isAnnualCOTInlcuded]="ProjectNetPrice.isAnnualCOTInlcuded"
          [value]="element[country.country]"
          [currency]="country.currency"
          (updatePrice)="updatePrice($event, element, country.country, true)"
        ></he-specific-input>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="10">
        <he-loading-spinner
          *ngIf="loading"
          [diameter]="70"
          [height]="'350px'"
        ></he-loading-spinner>
        <ng-container *ngIf="!loading">
          <div class="d-flex d-align-c d-j-c no-data">
            No Analogues has been selected
          </div>
        </ng-container>
      </td>
    </tr>
  </table>
</div>
<div class="table-container net-price-table" *ngIf="loading">
  <he-loading-spinner
  *ngIf="loading"
  [diameter]="70"
  [height]="'350px'"
></he-loading-spinner>
</div>