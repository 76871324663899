import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterMeasuresCurrencyComponent } from './filter-measures-currency.component';
import { SelectModule } from '../../../../uikit/select';
import { IconModule } from '../../../../uikit/helios-icons';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [FilterMeasuresCurrencyComponent],
  exports: [FilterMeasuresCurrencyComponent],
  imports: [
    CommonModule,
    SelectModule,
    IconModule,
    MatCheckboxModule
  ],
})
export class FilterMeasuresCurrencyModule {
}
