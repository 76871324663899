<div  class="forecast-summary-card" [ngStyle]="{'display': !loading ? 'block' : 'none'}">
  <div class="summary-analogues">
    <div class="horizon-field">
      <mat-form-field class="pull-right">
        <mat-label>Price type</mat-label>
        <mat-select
          name="priceType"
          (selectionChange)="onPriceTypeChange()"
          [(ngModel)]="selectedPriceType"
        >
        <ng-container *ngFor="let priceType of priceTypes">
          <mat-option
          *ngIf="priceType.visible"
          [value]="priceType.viewValue"
          [disabled]="priceType.disabled"
        >
          {{ priceType.viewValue }}
        </mat-option>
        </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <p>
      Forecast annual cost of treatment was generated using a value-price
      analysis considering the following analogues:
    </p>

    <div class="summary-analogue-title">
      <span class="summary-analogue-subtitle"
        >ANALOGUE
        <span class="reset-button">
          <mat-icon>restart_alt</mat-icon>
        </span>
      </span>
      <div class="analogue-summary-list" *ngFor="let analogue of analogues">
        <div
          class="analogue-color-circle"
          [ngStyle]="{ background: analogue.color }"
        ></div>
        <p>{{ analogue.name }}</p>
      </div>
    </div> -->
  </div>
  <div  class="summary-scenarios" [ngClass]="{'summary-scroll':countriesInfoData.length > 5}">
    <div #myTestDiv>
      <div style="height: 400px;" echarts [options]="option" [merge]="option" class="demo-chart"></div>
      <div class="country-lables">
        <div class="country-label" *ngFor="let country of countriesInfoData">
          <img src="assets/flags/small/{{ country?.flagId }}.svg" height="17px" />
          <p>{{ country.country | titlecase }}</p>
          <mat-icon
          #tooltip="matTooltip"
          matTooltip="{{ country.info }}"
          matTooltipClass="horizon-tooltip"
          >info</mat-icon
        >
        </div>
      </div>
      <div class="summary-legends">
        <div class="summary-legend" *ngFor="let scenario of scenarioList">
          <div
            style="height: 10px; width: 25px"
            [ngStyle]="{ background: scenario.color }"
          ></div>
          {{ scenario.name }}
        </div>
      </div>
    </div>
  </div>

</div>
<div *ngIf="loading">
  <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
</div>
