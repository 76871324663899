import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'he-horizon-project-details',
  templateUrl: './horizon-project-details.component.html',
  styleUrls: ['./horizon-project-details.component.scss']
})
export class HorizonProjectDetailsComponent {

  constructor() { }


}
