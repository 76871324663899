import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [LoadingSpinnerComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
  ],
  exports: [
    LoadingSpinnerComponent
  ]
  
})
export class LoadingSpinnerModule {
}
