<he-app-header (toggleSideBarForMe)="sideBarToggler()"></he-app-header>

<mat-drawer-container [ngClass]="sideBarOpen ? 'open' : 'close-he'">
    <mat-drawer mode="side" [opened]="true">
        <he-upload-data-sidebar (toggleSideBarForMe)="sideBarToggler()"
            [sideBarOpen]="sideBarOpen"></he-upload-data-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <mat-tab-group animationDuration="0ms" class="upload-data-tab-group">
            <mat-tab label="Upload Data">
                <he-upload-data-page></he-upload-data-page>
            </mat-tab>
            <mat-tab label="Delete Data">
                <he-delete-data-page></he-delete-data-page>
            </mat-tab>
        </mat-tab-group>
        <!-- <he-upload-data-page></he-upload-data-page> -->
    </mat-drawer-content>
</mat-drawer-container>