<ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [domLayout]="domLayout" [rowData]="rowData"
    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="pagination"
    [paginationPageSize]="paginationPageSize" overlayLoadingTemplate="loadingTemplate" [tooltipShowDelay]="0"
    [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
    (componentStateChanged)="onComponentStateChanged($event)" [frameworkComponents]="frameworkComponents"
    (selectionChanged)="onSelectionChanged($event)" (columnMoved)="onColumnMoved($event)"
    (dragStopped)="onDragStopped($event)" (sortChanged)="onSortChanged($event)"
    [suppressPaginationPanel]="!this.sharedService.chartmodeflag$.value">
</ag-grid-angular>


<mat-paginator class="mat-paginator" *ngIf="!this.sharedService.chartmodeflag$.value" [pageSize]="50"
    [length]="this.sharedService.gridmodetotRecortCount$.value" (page)="onPaginatorPageEvent($event)"
    [pageIndex]="this.sharedService.gridmodePageNumber$.value-1" [showFirstLastButtons]="recordDetails">
</mat-paginator>
