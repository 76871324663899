import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'he-icon-pack',
  templateUrl: './icon-pack.component.svg',
  styleUrls: ['./icon-pack.component.scss']
})
export class IconPackComponent {
}
