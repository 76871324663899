import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalogueListInterface } from '../../models/criteria.interface';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'he-price-inclusion',
  templateUrl: './price-inclusion.component.html',
  styleUrls: ['./price-inclusion.component.scss']
})
export class PriceInclusionComponent {
  @Input() public IsNetPriceIncluded=false;
  @Output() public updatePriceInclusion =
    new EventEmitter<boolean>();

  // public reset():void{
  //   this.IsNetPriceIncluded=false;
  //   this.updatePriceInclusion.emit(this.IsNetPriceIncluded)
  // }
  constructor(
    private projectService: ProjectService,
  ) {}

  public selectToggle(value: boolean): void {
    this.projectService.valueChangeStatus.next(true);
    this.projectService.netPriceChanged = true
    this.projectService.newValuechanged.next(true)

    this.IsNetPriceIncluded = value;
    this.updatePriceInclusion.emit(this.IsNetPriceIncluded)
  }


}
