import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ProjectInfo } from '../../../core/project-details/project';

import { EmptyValidator } from './empty.validator';

@Component({
  selector: 'he-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {
  @Input() project: ProjectInfo | undefined;
  @Input() header: string="Create new project";
  @Input() buttonText: string="Create";
  @Output() confirm = new EventEmitter<ProjectInfo>();
  @Output() projectCreated = new EventEmitter<ProjectInfo>();
  @Output() reject = new EventEmitter<ProjectInfo>();

  form!: FormGroup;
  submittedForm: any;

  ngOnInit(): void {
    this.form = this.createForm();
  }

  createForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(this.project?.name, [
        Validators.required,
        Validators.minLength(1),
        EmptyValidator
      ]),
      id: new FormControl(this.project?.id),
      description: new FormControl(this.project?.description),
      type: new FormControl(this.project?.type),
      content: new FormControl(this.project?.content),
    });
  }

  onReject(event: any): void {
    event.preventDefault();
    this.reject.emit(this.project);
  }

  onSubmit(): void {
    this.confirm.emit(this.project);
    this.submittedForm = this.form.value;
  }

  projectCreatedEvent(state:boolean):void{
    if(state){
      this.projectCreated.emit(this.project);
      this.submittedForm = this.form.value
    }
  }
}
