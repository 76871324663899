import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterProductParamsParallelImportComponent } from './filter-product-params-parallel-import.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterProductParamsParallelImportComponent],
  exports: [FilterProductParamsParallelImportComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
})
export class FilterProductParamsParallelImportModule {
}
