import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  Collaborator,
  MarketArea,
} from '../../../horizon-project/services/horizon-project-details.service';
import { MasterService } from '../../../horizon-project/services/master.service';
import { DataRefreshService } from '../../project/services/data-refresh.service';
import { ColorSetService } from '../../../shared/services/colorSet.service';
import { formatDate } from '@angular/common';
import { UpdateFrameworkService } from '../../../shared/services/update-framework.service';
import { ProjectService } from '../../project/services/project.service';
import { FeatureFlagService } from '../../../horizon-project/services/feature-flag.service';
import { UpdateFrameworkComponent } from '../../../shared/components/update-framework/update-framework.component';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from '../../../shared/services/header.service';
import { frameworkEnums } from 'projects/helios-gui/src/app/common';
@Component({
  selector: 'he-project-details-tab',
  templateUrl: './project-details-tab.component.html',
  styleUrls: ['./project-details-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDetailsTabComponent implements OnDestroy {
  public projectId = '';

  public project = 'Some Project';
  public projectDetails: any = {};
  public newFrameWorkId = '';
  public projectDescription: any[] = [];
  // public projectDescription = '';
  public tli = '';
  public markets: MarketArea[] = [];
  public projectOwnerName = '';
  public projectOwnerEmail = '';
  public createdBy = '';
  public createdOn = new Date();
  public modifiedBy = '';
  public modifiedOn = new Date();
  public collaborators: Collaborator[] = [];
  updateFramework: boolean = false;
  public frameworkName:string='';
  public isPriceChange: boolean = false;
  public isFrameWork: boolean = false;

  description: any = [];

  scenarios: any = [];

  alpha: any = [];
  private unsubscriber$ = new Subject<void>();

  constructor(
    activatedRouter: ActivatedRoute,
    private masterService: MasterService,
    private dataRefreshService: DataRefreshService,
    private colorSetService: ColorSetService,
    public updateFrameworkService: UpdateFrameworkService,
    private createprojectService: ProjectService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
    public dialog: MatDialog,
    public headerService: HeaderService,

  ) {
    this.featureFlagService.getFeatureFlagData().then((data) => {
      this.updateFramework = data.find(
        (x) => x.name === 'update-framework' && x.page === 'dashboard'
      )?.isActive as boolean;
    });
    activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.isFrameWork = false;
        this.projectId = p.project;
        if (this.projectId) {
          this.masterService.getProjectDetails(this.projectId).then((data) => {
           this.headerService.IsOncologyFrameworkSelected=data.valueFramework ==  frameworkEnums.oncology ? true :false ;
           this.headerService.ComparatorOrAnalog.next(data.isComparatorSelection==true ? 'Comparators' : 'Analogues');
            const userProjectPermissions =
              this.headerService.getUserProjectPermission();
            this.projectDescription = [];
            this.project = data.name;
            this.headerService.projectName.next(data.name);
            this.projectDetails = data;
            this.colorSetService.setAnalogueColor(data.brandTLIAndCountrys);

            if (data.description.includes('<br/>')) {
              data.description.split('<br/>').forEach((item) => {
                this.projectDescription.push(item);
              });
            } else {
              if (data.description!='')
              {
              this.projectDescription.push(data.description);
              }
            }

            this.markets = data.marketAreas;
            this.markets.sort((a, b) =>
              a.countryName.localeCompare(b.countryName)
            );
            this.projectOwnerName = data.projectOwnerName;
            this.projectOwnerEmail = data.ownerEmail;
            this.createdBy = data.createdBy;
            this.createdOn = data.createdOn;
            this.modifiedBy = data.modifiedBy;
            this.modifiedOn = data.modifiedOn;
            this.collaborators = data.collaborators;
            this.scenarios = data.projectScenarios
              .map((item: any) => {
                return {
                  scenarioId: item.scenarioId,
                  scenarioName: item.scenarioName,
                  sequence: item.sequence,
                };
              })
              .sort((a: any, b: any) => a.sequence - b.sequence);
            this.alpha = [];

            for (let i = 0; i < this.scenarios.length; i++) {
              this.alpha.push(String.fromCharCode(65 + i));
            }

            const { assetTLIAndCountrys } = data;
            if (
              Array.isArray(assetTLIAndCountrys) &&
              assetTLIAndCountrys.length > 0
            ) {
              this.tli = assetTLIAndCountrys[0].topLevelIndication;
            } else {
              this.tli = '';
            }
            if (this.updateFramework) {
              if (this.projectDetails.projectFrameworkUpgradeLogsDTO.length === 0) {
                if (this.projectDetails.updatedFrameworkDTO != null) {
                  this.updateFrameWorkPopUp();
                }
              }

            }

            if(data.valueFramework)
            {
                this.frameworkName=data.valueFramework;
            }

            if (data.updatedFrameworkDTO != null) {
              if (formatDate(data.modifiedOn, 'yyyy-MM-dd', 'en_US') < formatDate(data.updatedFrameworkDTO.framework_Updateddate, 'yyyy-MM-dd', 'en_US')) {
                this.newFrameWorkId = data.updatedFrameworkDTO.updated_ValueFrameworkId;
                this.isFrameWork = true

                if (data.projectFrameworkUpgradeLogsDTO[0].decision) {
                  if (userProjectPermissions) {
                    const currentProjectPermission = userProjectPermissions.find(
                      (f) => f.projectId === this.projectId
                    );

                    if (currentProjectPermission) {
                      if (currentProjectPermission.permissions.project.edit) {
                        this.isFrameWork = false;

                      }
                    }
                  }

                }
              }
            }
            this.getPriceUpdate();
          });
        }
      });
  }

  getTooltipText(collaborator: Collaborator): string {
    return `Name: ${collaborator.fullName}
    Email: ${collaborator.email}
    Company: ${collaborator.company}
    Permissions: Read only
    `;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public updateFrameWorkPopUp(): void {
    if (formatDate(this.projectDetails.modifiedOn, 'yyyy-MM-dd', 'en_US') < formatDate(this.projectDetails.updatedFrameworkDTO.framework_Updateddate, 'yyyy-MM-dd', 'en_US')) {
      this.dialog.open(UpdateFrameworkComponent, {
        width: '600px',
        panelClass: 'framework-dialog',
        backdropClass: 'framework-dialog-backdrop',
      }).afterClosed().subscribe(res => {
        if (res === true) {
          this.updateFrameworkService.updateFrameWorkLog(this.projectDetails.id, false)
          this.updateFrameworkService.isUpdateFrameWork.next(true);
          this.updateFrameworkService.isUpdateFrameWorkComplete.next(false);
          this.updateFrameworkService.updateFrameWorkId.next(this.projectDetails.updatedFrameworkDTO.updated_ValueFrameworkId);
          this.createprojectService.editProjectId.next(this.projectDetails.id);
          let link = "/horizon/edit-project/" + this.projectDetails.id
          this.router.navigate([link]);
        } else if (res === false) {
          this.updateFrameworkService.updateFrameWorkLog(this.projectDetails.id, false)
        }
      });
    }

  }
  private async getPriceUpdate() {
    await this.dataRefreshService
      .getProjectPriceUpdate(this.projectId)
      .then((projectData) => {
        if (projectData) {
          this.isPriceChange = projectData.data.filter(
            (f: any) => f.projectId === this.projectId
          )[0].isPriceChanged;
        }
      })
      .catch((error) => {
        console.log(error.error.data);
      });
  }

}
