import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

import { AccessBarrierModule } from '../access-barrier';
import { AccessPermissions } from '../../core/access-permissions';
import { AccessPermissionsApi } from '../../helios-api/access-permissions';
import { IconModule } from '../../uikit/helios-icons';
import { MenuModule } from '../profile/menu';
import { UniversalSearchModule } from '../universal-search';

// import { LogoModule } from '../home/logo';
import { HorizonComponent } from './horizon.component';
import { ScopeSummaryModule } from '../home/scope-summary';
import { RouterModule } from '@angular/router';
import { PlatformAccessApi } from '../../helios-api/platform-access/platform-access.api';
import { PlatformAccess } from '../../core/platform-access';
import { HorizonProjectModule } from './horizon-project/horizon-project.module';
import { HorizonRoutingModule } from './routing.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataLoadInterceptor } from './interceptor/dataload-interceptor.interceptor';
import { StoreModule } from '@ngrx/store';
import { horizonReducer } from './shared/store/reducer/horizon.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HorizonEffectsService } from './shared/store/effects/horizon.effects';

@NgModule({
  declarations: [HorizonComponent],
  imports: [
    AccessBarrierModule,
    CommonModule,
    RouterModule,
    IconModule,
    // LogoModule,
    MatCardModule,
    HorizonRoutingModule,
    MenuModule,
    UniversalSearchModule,
    ScopeSummaryModule,
    HorizonProjectModule,
    MatTableExporterModule,
    StoreModule.forFeature(
      'horizon',horizonReducer,
    ),
    EffectsModule.forFeature(
      [HorizonEffectsService]
    ),
  ],
  providers: [
    PlatformAccessApi,
    {
      provide: PlatformAccess,
      useFactory: PlatformAccess.create,
      deps: [PlatformAccessApi],
    },
    AccessPermissionsApi,
    {
      provide: AccessPermissions,
      useFactory: AccessPermissions.create,
      deps: [AccessPermissionsApi],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataLoadInterceptor,
      multi: true,
    },
  ],
})
export class HorizonModule {}
