import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryFlagService } from './country-flag.service';
import { CountryFlagCellComponent } from './country-flag-cell/country-flag-cell.component';
import { CountryFlagComponent } from './country-flag/country-flag.component';


@NgModule({
  declarations: [
    CountryFlagComponent,
    CountryFlagCellComponent
  ],
  exports: [
    CountryFlagComponent,
    CountryFlagCellComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    CountryFlagService
  ],
})
export class CountryFlagModule {
}
