import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CountryAmnogService {
    cellRenderer(params: any) {
        if (params.data.countryHoverTitle)
            return '<abbr class="country-cell" title="' + params.data.countryHoverTitle.replace(/[^A-Z-]/gi, " ").trim() + '">' + params.value + '</abbr>';
        return params.value;
    }

    getCountriesAvailableForPostAMNOG(details: any[]): string[] {
        let countriesAvailableForPostAMNOG: string[] = [];
        for (let cdNo = 0; cdNo < details.length; cdNo++) {
            let detail = details[cdNo];
            if (detail.countryHoverTitle && countriesAvailableForPostAMNOG.indexOf(detail.country) == -1) {
                countriesAvailableForPostAMNOG.push(detail.country);
            }
        }
        return countriesAvailableForPostAMNOG;
    }

    filterOutDataAsPerPostAMNOG(isGermanyPostAMNOGFlag: boolean, details: any[]): any {
        // Remove the AMNOG data if we have feature flag as false
        if (!isGermanyPostAMNOGFlag) {
            // As after each splice the index will reset, so have to loop in reveres order for working properly
            for (let cdNo = (details.length - 1); cdNo > 0; cdNo--) {
                let detail = details[cdNo];
                if (detail.countryHoverTitle) {
                    let dIndex = details.indexOf(detail);
                    details.splice(dIndex, 1);
                }
            }
        }
    }
}