import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Feature } from '../../horizon-project/models/Feature';
import { FeatureFlagService } from '../../horizon-project/services/feature-flag.service';

@Directive({
  selector: '[heFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {

  @Input('heFeatureFlag')
  public feature: any;

  public featureFlagData: Feature[] = [];

  constructor(
    private tpl: TemplateRef<any>,
    private vcf: ViewContainerRef,
    private featureFlagService: FeatureFlagService
  ) {

  }

  ngOnInit(): void {
    const feature = { Name: '', IsActive: false };
    this.getFlagValue().then((flag) => {
      if (flag) {
        this.vcf.createEmbeddedView(this.tpl);
      }
    });
  }

  private async getFlagValue() {
    const flagdata = await this.featureFlagService.getFeatureFlagData();
    const flag = flagdata.find(x => x.name === this.feature.Name && x.page === this.feature.Page)?.isActive;
    return flag;
  }

}
