export function Capitalise(data: string): string {
    let capitalised = '';
    if (data == null || data == undefined) {
        return "";
    }
    // data=data.toLowerCase();
    data.split(' ').forEach((element) => {
        if (element.charAt(0) === '(') {
            capitalised += element + ' ';
        }
        else {
            capitalised += element.charAt(0).toUpperCase() + element.slice(1).toLowerCase() + ' ';
        }
    });
    return capitalised.trim();
}


const Roman = [
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
    'XI',
    'XII',
    'XIII',
    'XIV',
    'XV',
    'XVI',
    'XVII',
    'XVIII',
    'XIX',
    'XX'
]
/**
 * captalises first letter leaving the roman as capital rest small
 * @param data 
 * @returns 
 */
export function CapitalFirstLetter(data: string): string {
    let capitalised = '';
    if (data == null || data == undefined) {
        return "";
    }
    // data=data.toLowerCase();
    data.trim().split(' ').forEach((element,index) => {
        if(Roman.filter(x=>x== element.trim()).length>0){
            capitalised += element + ' ';
        }else{
            if (index===0) {
                capitalised += element.charAt(0).toUpperCase() + element.slice(1).toLowerCase() + ' ';
            }
            else {                
                capitalised += element.toLowerCase() + ' ';
            }            
        }        
    });
    return capitalised.trim();
}