import { Injectable } from '@angular/core';
import { ScopeDetailType, ScopesDetails, ScopesDetailsResponse } from '../../core/scopes-details';

@Injectable()
export class ScopesDetailsService {

  private userScopesDetails!: ScopesDetailsResponse;
  private readonly scopeDetailTypes = [
    ScopeDetailType.Country
  ];

  constructor(private getScopesDetails: ScopesDetails) {
    this.initUserScopesDetails();
  }

  private async initUserScopesDetails(): Promise<void> {
    const scopesDetails = await this.getScopesDetails.run({payload: this.scopeDetailTypes}).toPromise();
    this.userScopesDetails = scopesDetails?.payload as ScopesDetailsResponse;
  }

  getUserScopesDetails(): ScopesDetailsResponse {
    return this.userScopesDetails;
  }
}
