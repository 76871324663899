import { Observable, of, zip } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from '../../uikit/service/shared.service';
import { RequestTypeEnum } from '../../uikit/filters';

@Injectable()
export class PackDetailsApi {
  constructor(private httpClient: HttpClient,private sharedService:SharedService) {
  }

  transfer(req: any): Observable<any> {
    const pendingObj: any = {
      type: 'pending',
      payload: undefined
    }

    const exportSub = this.sharedService.datastoreExportAll$;
    const tabSub = this.sharedService.datastoreTabSelectedIndex$;
    const excSub = this.sharedService.datastoreExcelSheet$;

    const pnrCols = this.sharedService.datastoreSelectedColumnPnR$;
    const cotCols = this.sharedService.datastoreSelectedColumnCOT$;
    const htaCols = this.sharedService.datastoreSelectedColumnHTA$;

    let result: Observable<any> = of(pendingObj);

    zip([exportSub, tabSub, excSub, pnrCols, cotCols, htaCols])
      .subscribe(([isExportAll, tabNo, excNo, pnrSelCols, cotSelCols, htaSelCols]) => {
        var apiFor = (isExportAll ? excNo : tabNo);

        if (apiFor == Number(RequestTypeEnum.PnR)) {
          req.selectedColumns = pnrSelCols.filter(x=> x !== 'availabilityStatus').join(',');
          result = this.httpClient.post<any>('/pack/details', req);
        }
        else if (apiFor == Number(RequestTypeEnum.CoT)) {
          req.selectedColumns = cotSelCols.filter(x=> x !== 'availabilityStatus').join(',');
          result = this.httpClient.post<any>('/IndicationCoT/details', req);
        }
        else if (apiFor == Number(RequestTypeEnum.HTA)) {
          req.selectedColumns = htaSelCols.join(',');
          result = this.httpClient.post<any>('/HTA/details', req);
        }

        return result;
      });
    
    return result;
  }
}