import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UserProfile } from './user.profile';
import { SessionEnums } from '../../common';
import { SessionStoreBase } from '../../common/session-store-service';
import { PlatformUserAuthorizationDataService } from '../../platform-user-authorization/platform-user-authorization-data.service';

@Component({
  selector: 'he-profile-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  constructor(
    private currentProfile: UserProfile,
    private session: SessionStoreBase,
    private platformUserAuthService: PlatformUserAuthorizationDataService
  ) {}

  getProfileName(): string {
    return this.currentProfile.getUserName();
  }

  logout(): void {
    this.session.removeSession(SessionEnums.brands);
    this.session.removeSession(SessionEnums.indications);
    this.session.removeSession(SessionEnums.indicationsAllowed);
    const authCode =
      this.platformUserAuthService.getPlatformUserAuthCodeFromSession();

    if (authCode) {
      this.platformUserAuthService
        .deleteAuthorizationCode(authCode)
        .then((data) => {
          //console.log(data);
          this.platformUserAuthService.removePlatformUserAuthCodeFromSession();
          this.currentProfile.logout();
        })
        .catch((err) => {
          this.currentProfile.logout();
        });
    } else {
      this.currentProfile.logout();
    }
  }
}
