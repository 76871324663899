import { Component } from '@angular/core';

@Component({
  selector: 'he-price-assumption',
  templateUrl: './price-assumption.component.html',
  styleUrls: ['./price-assumption.component.scss']
})
export class PriceAssumptionComponent {
  sideBarOpen = true;

  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }
  
}
