import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[heAllowFor]'
})
export class AllowDirective {
  @Input() heAllowFor = '';

  constructor(public templateRef: TemplateRef<any>) {
  }
}
