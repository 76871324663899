<mat-toolbar class="bgToolbar">
  <mat-toolbar-row class="header-toolbar-row">
    <mat-divider vertical></mat-divider>
    <ng-container *ngFor="let i of platformPermission">
      <div class="header-logo-div pulse-header" *ngIf="i.platform ==='Pulse'">
        <a href="/home">
          <div class="pulse-logo-div">
            <!-- <img src="assets/logos/pulse.png" class="pulse-logo" height="20" width="66.66" /> -->
            <h6>pulse</h6><p class="trademark-logo">&trade;</p>
          </div>
        </a>
      </div>
      <mat-divider vertical></mat-divider>
      <div class="header-logo-div horizon-header" *ngIf="i.platform ==='Horizon'">
        <a href="/horizon">
          <!-- <button mat-icon-button>
            <img src="assets/logos/horizon.png" class="horizon-logo" height="30" width="100" />
          </button> -->
          <div class="horizon-logo-div">
            <!-- <img src="assets/logos/pulse.png" class="pulse-logo" height="20" width="66.66" /> -->
            <h6>horizon</h6>
          </div>
        </a>
      </div>
    </ng-container>
    <mat-divider vertical></mat-divider>
    <!-- <div>
      <button mat-icon-button>
         <mat-icon>language</mat-icon 
      </button>
    </div>
    <mat-divider vertical></mat-divider> -->

    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul class="header-right-list" fxLayout="row" fxLayoutGap="20px">
        <li>
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>person_outline</mat-icon>
            <!-- <span>{{ getProfileName() | nameInitials | uppercase }}</span> -->
            <span>{{ getProfileName() }}</span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              Logout
            </button>
          </mat-menu>
        </li>
        <li *ngIf="iteam">
          <he-help-menu title="Help"></he-help-menu>
        </li>

        <li>
          <div fxFlex fxLayoutAlign="flex-end" class="header-right-logo-div">
            <img src="/assets/gpi.png" alt="Logo" class="logo" />
          </div>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>