import { Observable, finalize, of, share, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiCahe, IFeatureFlag } from '../../app/feature-flag/feature-flag-interfaces';

@Injectable()
export class FeatureFlagApi {
    public readonly listUrl: string = '/FeatureFlag/list';
    public readonly listByProductUrl: string = '/FeatureFlag/listByProduct';

    private apiCaches: any = []; // Client side url based API cache

    constructor(private httpClient: HttpClient) {
    }

    list(productName: string = ""): Observable<IFeatureFlag[]> {
        const url = (productName && productName.length > 0 ? this.listByProductUrl + '?productName=' + productName : this.listUrl);

        if (this.apiCaches[url] == undefined) {
            this.apiCaches[url] = ({ cache: null, cachedObservable: null } as IApiCahe);
        }
        let apiCache: IApiCahe = this.apiCaches[url];

        let observable: Observable<any>;

        if (apiCache.cache) {
            observable = of(apiCache.cache);
        } else if (apiCache.cachedObservable) {
            observable = apiCache.cachedObservable;
        } else {
            apiCache.cachedObservable = this.httpClient.get<IFeatureFlag[]>(url, {
                // this is added to avoid caching of this api
                headers: {
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    Pragma: 'no-cache',
                    Expires: '0'
                }
            }).pipe(
                tap(res => apiCache.cache = res),
                share(), // "share()" will handle to prevent duplicate HTTP requests (makes it sharable among multiple subscribers)
                finalize(() => apiCache.cachedObservable = null) // setting it to "null" as we don’t want any other subscriber. New subscribers will just get the cached value from now on.
            );
            observable = apiCache.cachedObservable;
        }

        return observable;
    }
}