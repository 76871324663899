import { Component, Input, OnInit } from '@angular/core';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';

@Component({
  selector: 'he-country-cell',
  templateUrl: './country-cell.component.html',
  styleUrls: ['./country-cell.component.scss']
})
export class CountryCellComponent implements OnInit {
  @Input() country: string | any;
  @Input() showName: boolean | any=true;

  public flagId = '';

  constructor(private countryFlagService: CountryFlagService) { }

  ngOnInit(): void {
    this.flagId = this.countryFlagService.getFlagId(this.country);
  }

}
