import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../../interfaces';
import { FiltersValueService } from '../../filters-value.service';
import { measuresCalculateParametersItems } from '../../../project-details/analog-filters/constants';

@Component({
  selector: 'he-filter-measures-calculate-parameters',
  templateUrl: './filter-measures-calculate-parameters.component.html',
  styleUrls: ['./filter-measures-calculate-parameters.component.scss']
})
export class FilterMeasuresCalculateParametersComponent {
  @Input() filtersValue!: FiltersObject;
  @Input() disabled = false;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  items: FilterItemValueInterface[] = measuresCalculateParametersItems;
  title = 'Calculate parameters';

  constructor(private filtersValueService: FiltersValueService) {
  }

  get selectedItem(): FilterItemValueInterface {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.includeWastage).filterValue[0] || this.items[0];
  }

  itemSelectEvent(item: FilterItemValueInterface): void {
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.includeWastage, [item]));
  }
}
