import { SharedService } from './../../uikit/service/shared.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { CachedAccessPermissions, Message } from '../../core/access-permissions';
import { UniversalSearchService } from './universal-search.service';
import { UniversalSearchContext } from './universal-search.context';
import { AccessPagesEnum, PlaceholderText, ResponseStatus, RoutesEnum, SessionEnums } from '../common';
import { CountryResolver } from '../common/country-resolver';
import { PermissionsResponse } from '../../core/access-permissions';
import { BehaviorSubject, Observable, Subject, fromEvent, interval, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionStoreBase } from '../common/session-store-service';
import { browserRefresh } from '../app.component';
import { FilterBrandPresetTypes } from '../datastore/filters/filter-preset';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'he-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.scss']
})
export class UniversalSearchComponent implements OnInit, OnDestroy {
  suggestion$: Observable<any> | undefined;
  placeholderText$ = new BehaviorSubject<string>('');
  private readonly destroying$: Subject<void> = new Subject<void>();

  labels: { [key: string]: string } = {
    brandpreference:'Brand Basket',
    inn: 'Active ingredient',
    brand: 'Brand name',
    country: 'Country',
    indication: 'Indication',
    moa: 'Mechanism of action',
    whoatc: 'WHOATC Code'
  };
  routeState: { [k: string]: any; } | undefined;
  autocompleteElMaxHeight: string = '320px';

  constructor(
    private context: UniversalSearchContext,
    private unisearch: UniversalSearchService,
    private router: Router,
    private route: ActivatedRoute,
    private countryResolver: CountryResolver,
    private accessPermissions: CachedAccessPermissions,
    private sharedService: SharedService,
    private session: SessionStoreBase
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.routeState = this.router.getCurrentNavigation()?.extras.state;
    }
  }

  ngOnInit(): void {
    if (this.routeState == null && history.state != null) {
      this.routeState = history.state;
    }

    if (this.route && this.route.queryParams) {
      this.route?.queryParams.subscribe(params => {
        if (params.s && params.t && params.id) {
          let ref = {
            name: params.s,
            id: params.id,
            type: params.t
          };

          const brands = this.routeState?.brands ?? params.brands;
          if (brands) {
            ref = { ...ref, ...{ SelectedBrandIds: brands } };
          }
          const countries = this.routeState?.countries;
          if (countries) {
            ref = { ...ref, ...{ SelectedCountries: countries } };
          }
          const discontinued = this.routeState?.discontinued;
          if (discontinued !== undefined) {
            ref = { ...ref, ...{ Discontinued: discontinued } };
          }

          const insightsProject = this.routeState?.insightsProject;
          if (insightsProject !== undefined) {
            ref = { ...ref, ...{ InsightsProject: insightsProject } };
          }

          if (!browserRefresh) {
            this.session.setSession(SessionEnums.brands, []);
          }
          
          this.suggestion$ = new Subject();
          this.context.saveReference(ref);
        }
      });
    }
    this.fetchPermissions();
    this.sharedService.setShowLoadingScreen(false)
    this.subscribeWindowResize();
    this.subscribeAutocompleteHeightInterval();
  }

  subscribeAutocompleteHeightInterval() {
    interval(100).pipe(takeUntil(this.destroying$)).subscribe(() => {
      const autocompleteEl = document.querySelector('.he-autocomplete-list') as HTMLElement;
      const appHeaderEl = document.querySelector('he-app-header') as HTMLElement;

      if (autocompleteEl) {
        autocompleteEl.style.maxHeight = this.autocompleteElMaxHeight
        if (appHeaderEl)
          appHeaderEl.style.zIndex = '1001'
      }
      else if (appHeaderEl)
        appHeaderEl.style.zIndex = ''
    })
  }

  fetchPermissions(): any {
    let accessList: PermissionsResponse[] = [];
    const arryList: string[] = [];

    this.accessPermissions.run({ payload: null }).pipe(
      takeUntil(this.destroying$)
    ).subscribe((res: Message) => {
      if (res.type === ResponseStatus.done) {
        accessList = res.payload as [];
        accessList.forEach((element: PermissionsResponse) => {
          arryList.push(element.code);
        });

        if (arryList.includes(AccessPagesEnum.moa) && arryList.includes(AccessPagesEnum.profiles)) {
          this.placeholderText$.next(PlaceholderText.MoaAndCountryProfile);
        } else if (arryList.includes(AccessPagesEnum.moa)) {
          this.placeholderText$.next(PlaceholderText.Moa);
        } else if (arryList.includes(AccessPagesEnum.profiles)) {
          this.placeholderText$.next(PlaceholderText.CountryProfile);
        } else {
          this.placeholderText$.next(PlaceholderText.Default);
        }
      }
    });
  }

  subscribeWindowResize(): void {
    fromEvent(window, 'resize').pipe(
      takeUntil(this.destroying$)
    ).subscribe(($event: Event) => {
      this.onResize();
    });
  }

  onResize(): void {
    timer(0).pipe(takeUntil(this.destroying$)).subscribe(() => {
      const autocompleteEl = document.querySelector('.he-autocomplete-list') as HTMLElement;
      const anchorEl = document.querySelector('.he-autocomplete-anchor') as HTMLElement;
      const tilesEl = document.querySelector('.he-home-tiles') as HTMLElement;
      if (autocompleteEl && anchorEl && tilesEl) {
        const anchorElRect = anchorEl.getBoundingClientRect();
        const tilesElRect = tilesEl.getBoundingClientRect();

        this.autocompleteElMaxHeight = (tilesElRect.top - anchorElRect.top + tilesElRect.height - anchorElRect.height - 8) + "px"
        autocompleteEl.style.maxHeight = this.autocompleteElMaxHeight
      }

    })
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  onQueryChange(event: any): void {
    this.suggestion$ = this.unisearch.run({
      payload: { query: event.target.value }
    });
  }

  onSuggestionsLoad(): void {
    this.onResize();
  }

  onSuggestion(event: MatAutocompleteSelectedEvent): void {
    this.resetRouteState();

    this.suggestion$ = new Subject();
    const params = {
      s: event.option.value?.name,
      t: event.option.value?.type,
      id: event.option.value?.id
    };
    this.context.saveReference(event.option.value);

    if (event.option.value?.type === 'country') {
      this.router.navigate(['/', RoutesEnum.AtlasPage, this.countryResolver.encode(event.option.value?.name)], { replaceUrl: true });
    } else if (event.option.value?.type === 'indication') {
      this.router.navigate(['/', RoutesEnum.Indications, event.option.value?.name], { replaceUrl: true });
    } else if (event.option.value?.type === FilterBrandPresetTypes.Brand ) {
        this.router.navigate(['/', 'insights'], {
        queryParams: params,
        replaceUrl: true
      });

    }
    
    else {

      if (params.t === "brand") {
        this.session.setSession(SessionEnums.brands, [event.option.value]);
      }

      this.router.navigate(['/', 'insights'], {
        queryParams: params,
        replaceUrl: true
      });
    }
  }

  resetRouteState() {
    this.routeState?.countries && (this.routeState.countries = undefined);
    this.routeState?.brands && (this.routeState.brands = undefined);
    this.routeState?.discontinued && (this.routeState.discontinued = undefined);
    this.routeState?.insightsProject && (this.routeState.insightsProject = undefined);
  }

  pickName(value: any): string {
    return value?.name || '';
  }
}
