<ng-container *ngFor="let filter of selectedItems">
  <div class="pt-3 pl-3 pr-3 pb-0 east-slot">
    <div class="mb-2">{{ filter.name }}</div>

    <he-chips-list
      [hasRemoveIcon]="false"
      [selectedItems]="filter.filterOptions || []"
      [changeStatusOnClick]="true"
      (itemUpdated)="itemUpdated()"
    ></he-chips-list>
  </div>
</ng-container>
