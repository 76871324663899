import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpParams } from '@angular/common/http';
import { SharedService } from '../../uikit/service/shared.service';

@Injectable()


export class GridViewCustomColumnAPIService
{
    constructor(private httpClient:HttpClient, private sharedService:SharedService){}

    //update slected columns
    getGridViewCustomDetails( req:any[]):Observable<any>{ 
        return this.httpClient.post<unknown>('/UserConfig/AddUserPreference', req);
    }

    //fetch default columns
    getGridViewCustomDefault(req:string):Observable<any>{ 
        let request=new HttpParams().set('gridViewType', req)
        return this.httpClient.get<unknown>('/IndicationCoT/defaultColumns', {params:request});
    }

}