<ng-container *ngIf="currencyItems$ | async as currencyItems">
  <ng-container *ngIf="showSaveButton">
    <button mat-icon-button class="he-icon-button he-button-m-size" title="Save selection as default" (click)="saveAsDefault(selectedItem)">
      <he-icon [id]="'editor-action-save'"></he-icon>
    </button>
  </ng-container>
<he-select [items]="currencyItems"
           [disabled]="!!(isLocalCurrencySelected)"
           [selectedItem]="selectedItem"
           (itemSelect)="itemSelectEvent($event)"
           [label]="title"></he-select>
</ng-container>
<div class="mt-3" *ngIf="showLocalCurrencyCheckBox">
  <mat-checkbox class="he-checkbox" [checked]="!!(isLocalCurrencySelected)" (change)="onLocalCurrencySelectedChange()">Show prices in local currency
  </mat-checkbox>
</div>
