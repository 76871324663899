import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
import { map , catchError, finalize} from 'rxjs/operators';
import { ProjectLoadService } from '../horizon-project/services/project-load.service';


@Injectable()
export class DataLoadInterceptor implements HttpInterceptor {

  constructor(private projectLoadService:ProjectLoadService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // to ignore the api call for uplaod file to enable navigation
    const URL = "Projects/UploadMultipleFiles"
    if(!req.url.includes(URL)){
      this.projectLoadService.apiCalls++;
      this.projectLoadService.setLoading(true);
    }
    return next.handle(req).pipe(
      finalize(() => {
        if(!req.url.includes(URL)){
          this.projectLoadService.apiCalls--;
        }
        if(this.projectLoadService.apiCalls==0)
        this.projectLoadService.setLoading(false);
      }),
    );
  }
}
