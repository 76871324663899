<footer>
  <div class="row no-gutters flex-nowrap align-items-center">
    <span>
      All content on GPI pulse™ is proprietary and confidential information of Global Pricing Innovations (GPI).
      It is not intended to be distributed to any third party without the written consent of Global Pricing Innovations.
      Information presented are not guarantees of future performance and undue reliance should not be placed on them.
      These forward-looking statements are, by their nature, subject to significant risks and uncertainties,
      which may cause actual performance and financial results in future periods to differ materially to those proposed by GPI.
    <span class="exchangeRates">
      Exchange rates derived from Open Exchange Rates. Find our more and view rates by
      <a mat-tab-link target="_blank" [routerLink]="['/exchange-rates']">clicking here.</a>
    </span>
  </span>

  </div>
</footer>
