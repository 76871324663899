<div class="chart-container"  #dataToExport>
  <div class="chart-options">
    <div>
      <div>
        <span class="title">Value Assessment</span>
        <div><a class="download-div" (click)="downloadAsPdf()"><img src="/assets/dwonload-purple.svg"
              class="dwonload-icon" alt="Export Data" href="#" /></a></div>
      </div>
    </div>
  </div>
  <div *ngIf="!showSection">
    <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
  </div>
  <div *ngIf="showSection" echarts [options]="chartOption" (chartInit)="saveChartApi($event)" class="demo-chart"></div>
  <span class="key">Key:</span>
</div>
<div id="download">
  <img #canvas>
  <a #downloadLink></a>
</div>