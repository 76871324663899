import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  Output,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicScoreInput } from '../../../../horizon-project/models/DynamicValueScore';
import {
  ValueScore,
  ValueScoreAssetInput,
  ValueScoreInput,
} from '../../../../horizon-project/models/ValueScoreInput';
import { AssetDynamicDataService } from '../../../../horizon-project/services/asset-dynamic-data.service';
import { HeaderService } from '../../../../shared/services/header.service';
import * as dbData from '../../db.json';
import { ValueScoringService } from '../../services/value-scoring.service';
import { ProjectValueScoringMainGridComponent } from '../../component-pieces/project-value-scoring-main-grid/project-value-scoring-main-grid.component';
import {
  CapitalFirstLetter,
  Capitalise,
} from '../../../../horizon-project/utility-functions/StringFormatter';
import { ProjectValueScoringSubGridComponent } from '../../component-pieces/project-value-scoring-sub-grid/project-value-scoring-sub-grid.component';
import { ExportImageService } from '../../../../shared/services/export-image.service';
import { ECharts } from 'echarts';
import html2canvas from 'html2canvas';
import { Subject, take, takeUntil } from 'rxjs';
import { capitalize } from 'lodash';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';

@Component({
  selector: 'he-project-value-scoring',
  templateUrl: './project-value-scoring.component.html',
  styleUrls: ['./project-value-scoring.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectValueScoringComponent implements OnInit {
  @ViewChild(ProjectValueScoringMainGridComponent)
  CSVDwonload?: ProjectValueScoringMainGridComponent;
  @Output() chartInstance = new EventEmitter<any>();

  data = (dbData as any).default;
  mainGridRecordCount = 0;
  subGridRecordCount = 0;
  filterOptions: string[] = ['Show scores only', 'Show scores and values'];
  selectedOption = 0;
  chartData: any[] = [];
  allData: any[] = [];
  extraColumns: string[] = [];
  colorIndexList: number[] = [];
  selectedCountry = 'France';
  selectedIndex = 0;
  isGridClicked = true;
  isChartClicked = false;
  public showSection = false;
  projectId = '';
  scenario = '';
  public filteredDatacolumn: any[] = [];
  private unsubscriber$ = new Subject<void>();
  country: any = 'Germany';

  constructor(
    private headerService: HeaderService,
    private activatedRouter: ActivatedRoute,
    private valueScoringService: ValueScoringService,
    private assetDynamicDataService: AssetDynamicDataService,
    private toastNotificationService: ToastNotificationService
  ) {}

  ngOnInit(): void {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.initialiseCompData();
        this.projectId = p.project;
        this.selectedCountry = '';
        this.scenario = '';
        if (this.projectId) {
          this.allData = [];
        }
      });

    this.headerService.onCountryChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((country) => {
        this.selectedCountry = country;

        this.showSection = false;
        this.fetchData(true).then(() => {
          this.CheckScore();
        });
      });

    this.headerService.onTabCliked
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((tabName) => {
        this.headerService.setEnableCurrenct(true, tabName);

        this.headerService.castvalueScoreChanged
          .pipe(take(1))
          .subscribe((status) => {
            if (status) {
              if (
                tabName === 'Value' ||
                tabName === 'Forecast' ||
                tabName === 'Summary'
              ) {
                this.CheckScore();
              }
            }
          });
      });

    this.headerService.onScenarioChange
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.scenario = data;
        if (this.scenario) {
          this.showSection = false;

          if (this.allData.length > 0) {
            this.fetchData(true);
          } else {
            this.fetchData(false);
          }
        }
      });
  }

  private initialiseCompData() {
    this.data = (dbData as any).default;
    this.mainGridRecordCount = 0;
    this.subGridRecordCount = 0;
    this.selectedOption = 0;
    this.chartData = [];
    this.allData = [];
    this.extraColumns = [];
    this.colorIndexList = [];
    this.selectedCountry = 'France';
    this.selectedIndex = 0;
    this.isGridClicked = true;
    this.isChartClicked = false;
    this.showSection = false;
    this.projectId = '';
    this.scenario = '';
    this.filteredDatacolumn = [];
  }

  async CheckScore() {
    const updatedAssetScore =
      this.assetDynamicDataService.getUpdatedAssetData();

    if (updatedAssetScore.length > 0) {
      const countries = [...new Set(updatedAssetScore.map((f) => f.Country))];
      const dynamicScoreInput: ValueScoreInput[] = [];

      countries.forEach((c) => {
        const scoreInput: ValueScoreInput = {
          Country: c,
          ProjectId: this.projectId,
          AssetData: [],
        };

        const countryAssetData = updatedAssetScore.filter(
          (f) => f.Country.toLowerCase() === c.toLowerCase()
        );

        // if only one asset is present
        const assetData: ValueScoreAssetInput = {
          Id: countryAssetData[0].AssetId,
          Name: countryAssetData[0].Name,
          ValueScores: [],
        };
        countryAssetData.forEach((d) => {
          const assetScore: ValueScore = {
            ValueDomainId: d.ValueDomainId,
            ValueAttributeId: d.ValueAttributeId,
            Score: !!d.Score ? d.Score.toString() : '',
            Value: d.Value,
          };
          assetData.ValueScores.push(assetScore);
        });
        scoreInput.AssetData.push(assetData);

        dynamicScoreInput.push(scoreInput);
      });

      // call default asset tpp service

      await this.valueScoringService
        .getDynamicTotalValueScores(dynamicScoreInput)
        .then((vScoreData: any) => {
          const country = this.selectedCountry;
          const countryAssetScore = vScoreData.filter(
            (f: any) => f.country === country
          )[0];

          if (this.data) {
            const assetIndex = this.data.assetTLIs.findIndex(
              (f: any) => f.id === countryAssetScore.assetTLIs[0].id
            );

            if (assetIndex > -1) {
              this.data.assetTLIs[assetIndex] = JSON.parse(
                JSON.stringify(countryAssetScore.assetTLIs[0])
              );
              // this.refreshGrid();
              this.initGrid();
              this.notifyValueScoreChange(vScoreData);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.toastNotificationService.errorMessage(
            'Unable to fetch total value scoring data',
            'info',
            10000
          );
        });
    }
  }

  private notifyValueScoreChange(scoreData: ValueScoreInput[]): void {
    const scores: any = [];
    scoreData.forEach((f: any) => {
      // for each country
      const country = f.country;

      f.assetTLIs.forEach((s: any) => {
        const totalValueScore = s.totalValueScore;
        const totalValueScorePercent = s.totalValueScorePercent;
        const assetId = s.id;
        const assetName = s.brandName;
        scores.push({
          country,
          totalValueScore,
          totalValueScorePercent,
          assetId,
          assetName,
        });
      });
    });
    const dynamicScoreInput: DynamicScoreInput = {
      UpdatedCountryScores: scores,
      UpdatedValueScores: scoreData,
    };

    this.headerService.setValueScoredata(dynamicScoreInput);
    this.headerService.valueScoreChanged.next(false);
  }

  public mainGridCountChanged(count: number): void {}

  public subGridCountChanged(count: number): void {
    this.subGridRecordCount = count;
  }

  async fetchData(refresh: boolean = false) {
    if (this.projectId && this.selectedCountry && this.scenario) {
      await this.valueScoringService
        .getValueScoringData(
          this.projectId,
          this.selectedCountry,
          this.scenario
        )
        .then(
          (dbData) => {
            this.data = dbData;
            // if (refresh === true) {
            //   this.refreshGrid();
            // } else {
            //   this.initGrid();
            // }

            this.initGrid();
          },
          (error) => {
            console.log(error);
            this.toastNotificationService.errorMessage(
              'Unable to Value Scoring Data',
              'info',
              10000
            );
          }
        );
    }
  }

  public refreshGrid(): void {
    this.chartData = [];
    this.colorIndexList = [];
    this.extraColumns = [];
    this.filteredDatacolumn = [];
    for (let i = 0; i < this.allData.length; i++) {
      const item = this.allData[i];
      const dbItem = this.data.assetTLIs[i];
      // debugger
      if (dbItem) {
        const chartDataItem: any = {
          brandedInnName:
            item.position > 0
              ? capitalize(item.brandedInnName)
              : item.brandedInnName,
          inn: item.position > 0 ? CapitalFirstLetter(item.inn) : item.inn,
          totalValueScore: Math.round(dbItem.totalValueScore.toFixed(2)),
          htaLink: dbItem.htaLink,
          position: item.position,
        };

        for (let i = 0; i < dbItem.valueDomains.length; i++) {
          const dbItemValueDomain = dbItem.valueDomains[i];
          if (dbItemValueDomain.domainName === 'Burden') {
            chartDataItem['burdonDomainValueScore'] = Math.round(
              dbItemValueDomain.domainValueScore.toFixed(2)
            );
          }
          if (dbItemValueDomain.domainName === 'Product characteristics') {
            chartDataItem['productDomainValueScore'] = Math.round(
              dbItemValueDomain.domainValueScore.toFixed(2)
            );
          }
          if (dbItemValueDomain.domainName === 'Trial design') {
            chartDataItem['trialDomainValueScore'] = Math.round(
              dbItemValueDomain.domainValueScore.toFixed(2)
            );
          }
          if (dbItemValueDomain.domainName === 'Clinical benefit') {
            chartDataItem['clinicalDomainValueScore'] = Math.round(
              dbItemValueDomain.domainValueScore.toFixed(2)
            );
          }
          if (dbItemValueDomain.domainName === 'Other') {
            chartDataItem['otherDomainValueScore'] = Math.round(
              dbItemValueDomain.domainValueScore.toFixed(2)
            );
          }
        }

        const additionalColumns = [];
        for (let j = 0; j < dbItem.valueDomains.length; j++) {
          const valueDomain = dbItem.valueDomains[j];
          const valueScoreData: any = {
            domainName: valueDomain.domainName,
            columns: [],
          };
          valueScoreData['columns'] = [];
          for (let k = 0; k < valueDomain.valueAttributes.length; k++) {
            const valueScore = valueDomain.valueAttributes[k];
            valueScoreData.columns.push({
              name: valueScore.valueAttributeName,
              score: valueScore.score,
              value: valueScore.value,
              country: valueScore.country,
              weight: valueScore.weight,
              htaInfo: valueScore.htaInfo,
              valueScopeReference: valueScore.valueScopeReference,
            });
          }
          additionalColumns.push(valueScoreData);
        }
        item.valueScoreData = [...additionalColumns];

        const domainNames = [
          '',
          'Burden',
          'Product characteristics',
          'Trial design',
          'Clinical benefit',
          'Other',
        ];

        const filteredData = item.valueScoreData.filter(
          (element: any) =>
            element.domainName === domainNames[this.selectedIndex]
        );

        if (filteredData.length > 0) {
          for (let j = 0; j < filteredData[0].columns.length; j++) {
            const valueScoreItem = filteredData[0].columns[j];
            if (
              this.selectedCountry !== '' &&
              (this.selectedCountry.toLowerCase() ===
                valueScoreItem.country.toLowerCase() ||
                valueScoreItem.country === 'All')
            ) {
              if (
                !this.extraColumns.some(
                  (element) => element == valueScoreItem.name
                )
              ) {
                this.extraColumns.push(valueScoreItem.name);
              }
              // if (i === 0) {
              this.colorIndexList.push(valueScoreItem.weight);
              // }
            }
          }
        }
        // else {
        //   this.extraColumns = [];
        //   this.colorIndexList = [];
        // }
        const filteredDataDownload: any[] = [];
        item.valueScoreData.forEach((valueScoreData: any) => {
          if (
            this.filteredDatacolumn[valueScoreData.domainName]?.length ==
            undefined
          ) {
            this.filteredDatacolumn[valueScoreData.domainName] =
              valueScoreData.columns;
          }
          valueScoreData.columns.forEach((column: any) => {
            filteredDataDownload.push(column);
          });
        });

        if (filteredDataDownload.length > 0) {
          for (let j = 0; j < filteredDataDownload.length; j++) {
            const valueScoreItem = filteredDataDownload[j];
            if (
              this.selectedCountry !== '' &&
              (this.selectedCountry.toLowerCase() ===
                valueScoreItem.country.toLowerCase() ||
                valueScoreItem.country === 'All')
            ) {
              chartDataItem[valueScoreItem.name] = {
                value: valueScoreItem.value,
                score: valueScoreItem.score,
                weight: valueScoreItem.weight,
                htaInfo: valueScoreItem.htaInfo,
                valueScopeReference: valueScoreItem.valueScopeReference,
              };
            }
          }
        }
        this.chartData.push(chartDataItem);
        // getting modified
      }
      if (this.chartData !== undefined) {
        this.showSection = true;
      }
    }
  }

  public mainGridSelectedIndexChanged(index: number): void {
    this.selectedIndex = index;
    // called after column click
    //this.refreshGrid();
    this.initGrid();
  }

  public initGrid(): void {
    this.chartData = [];
    this.allData = [];
    this.colorIndexList = [];
    if (this.data?.assetTLIs) {
      if (Array.isArray(this.data.assetTLIs)) {
        this.mainGridRecordCount = this.data.assetTLIs.length;
        for (let i = 0; i < this.data.assetTLIs.length; i++) {
          const item = this.data.assetTLIs[i];
          const position = this.data.assetTLIs[i].sequenceNo;

          let burdonDomainValueScore = '';
          let productDomainValueScore = '';
          let trialDomainValueScore = '';
          let clinicalDomainValueScore = '';
          let otherDomainValueScore = '';
          const extraColumns = [];
          for (let j = 0; j < item.valueDomains.length; j++) {
            const valueDomain = item.valueDomains[j];
            if (valueDomain.domainName === 'Burden') {
              burdonDomainValueScore = String(
                Math.round(valueDomain.domainValueScore.toFixed(2))
              );
            }
            if (valueDomain.domainName === 'Product characteristics') {
              productDomainValueScore = String(
                Math.round(valueDomain.domainValueScore.toFixed(2))
              );
            }
            if (valueDomain.domainName === 'Trial design') {
              trialDomainValueScore = String(
                Math.round(valueDomain.domainValueScore.toFixed(2))
              );
            }
            if (valueDomain.domainName === 'Clinical benefit') {
              clinicalDomainValueScore = String(
                Math.round(valueDomain.domainValueScore.toFixed(2))
              );
            }
            if (valueDomain.domainName === 'Other') {
              otherDomainValueScore = String(
                Math.round(valueDomain.domainValueScore.toFixed(2))
              );
            }

            const valueScoreData: any = {
              domainName: valueDomain.domainName,
              columns: [],
            };
            valueScoreData['columns'] = [];
            for (let k = 0; k < valueDomain.valueAttributes.length; k++) {
              const valueScore = valueDomain.valueAttributes[k];
              valueScoreData.columns.push({
                name: valueScore.valueAttributeName,
                score: valueScore.score,
                value: valueScore.value,
                country: valueScore.country,
                weight: valueScore.weight,
              });
            }
            extraColumns.push(valueScoreData);
          }

          this.allData.push({
            brandedInnName: item.brandName,
            inn: item.innName,
            totalValueScore: Math.round(item.totalValueScore),
            burdonDomainValueScore,
            productDomainValueScore,
            trialDomainValueScore,
            clinicalDomainValueScore,
            otherDomainValueScore,
            htaLink: item.htaLink,
            valueScoreData: [...extraColumns],
            position,
          });
          this.refreshGrid();
        }

        this.chartData = JSON.parse(JSON.stringify(this.chartData));
        if (this.chartData !== undefined) {
          this.showSection = true;
        }
      }
    }
  }

  public downloadFile(): void {
    this.CSVDwonload?.downloadData();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
