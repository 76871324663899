<ng-container *ngIf="filtersValueService.filterValues$ | async as filtersValue">
  <ng-container *ngIf="hasFilter(filterNameEnum.dynamicIndications, filtersValue)">
    <he-dynamic-filters
      [filtersValue]="filtersValue"
      (filtersValueModified)="filtersValueModified($event)"></he-dynamic-filters>
  </ng-container>

  <div class="pt-3 pl-3 pr-3 pb-0 east-slot">
    <he-filter-by-country
      [filtersValue]="filtersValue"
      [hasSaveButton]="hasCountrySaveButton"
      [hasResetButton]="hasCountryResetButton"
      (filtersValueModified)="filtersValueModified($event)"></he-filter-by-country>
  </div>
</ng-container>
