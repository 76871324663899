import { formatDate } from '@angular/common';


export enum Formatter {
  DateFormat,
  TextFormat,
  NumberFormat,
  PreFormat,
  CustomFormatForNonHTA,
}

export function setFormat(data: any, formatter: Formatter): string {
  switch (formatter) {
    case Formatter.DateFormat:
      return formatDateCustom(data);
    case Formatter.TextFormat:
      return formatText(data);
    case Formatter.NumberFormat:
      return formatNumber(data);
    case Formatter.CustomFormatForNonHTA:
      return customFormatForNonHTA(data);
  }
  return data;
}
export function setDateFormat(data: any): string {
  const dt = data.value;

  // Empty Date as "dash" ('-')
  if (dt && typeof (dt) === "string" && dt === "-") {
    return dt;
  }

  // Date is already formatted
  if (dt && typeof (dt) === "string" && dt.indexOf("/") > 0) {
    return dt;
  }

  return formatDateCustom(dt);
}
export function formatDateCustom(date: string): string {
  return date && Date.parse(date) ? formatDate(date, 'dd/MM/yyyy', 'en_GB') : date || '-';
}

export function formatText(text: string | undefined): string {
  return !text ? '-' : text;
}

export function formatNumber(data: number | string | undefined): string {
  return data && data !== '-' ? (+data).toFixed(2) : '-';
}

export function customFormatForNonHTA(text: string | undefined): string {
  return !text ? 'No relevant HTA assessments' : text;
}
