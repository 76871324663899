import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, take, tap } from 'rxjs/operators';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UploadDataService {

  constructor(private http:HttpClient) { }
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  readonly pulseAPIUri:string = environment.gatewayApiEndpoint

  public fileListDataLength = new BehaviorSubject<any>(0);
  public castfileListDataLength = this.fileListDataLength.asObservable();

  public fileDataStatus= new BehaviorSubject<boolean>(false);
  public castfileDataStatus = this.fileDataStatus.asObservable();

  getCountriesData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<any[]>(
          this.projectAPIUri + '/Master/GetCountries'
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  getCurrencyData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(this.pulseAPIUri+'/userconfig/GetCurrenciesDetails').subscribe({
        next:(data:any)=>{
          resolve(data);
        },
        error:(error)=>{
          reject(error);
        }
      });
    });
  }

  uploadCOTData(params:any,fileData:any): Promise<any> {
    const formData = new FormData()
    formData.append('file',fileData[0])
    return new Promise((resolve, reject) => {
      this.http.post(`${this.projectAPIUri}/AnalogueTool/UploadCOTData?country=${params.country}&currencyCode=${params.currency}&frameworkName=${params.framework.valueFrameworkName}`,
      formData).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  uploadTaggingData(params:any,fileData:any):Promise<any>{
    const formData = new FormData()
    formData.append('file',fileData[0])
    return new Promise((resolve, reject) => {
      this.http.post(`${this.projectAPIUri}/AnalogueTool/UploadTaggingData?country=${params.country}&frameworkName=${params.framework.valueFrameworkName}`,
      formData).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  uploadAnalogueInfoData(params:any,fileData:any):Promise<any>{
    const formData = new FormData()
    formData.append('file',fileData[0])
    return new Promise((resolve, reject) => {
      this.http.post(`${this.projectAPIUri}/AnalogueTool/UploadAnalogueInfo?country=${params.country}&frameworkName=${params.framework.valueFrameworkName}`,
      formData).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }
  
  uploadValueScoreData(params:any,fileData:any):Promise<any>{
    const formData = new FormData()
    formData.append('file',fileData[0])
    return new Promise((resolve, reject) => {
      this.http.post(`${this.projectAPIUri}/AnalogueTool/UploadValueScoreData?country=${params.country}&frameworkName=${params.framework.valueFrameworkName}`,
      formData).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  uploadAnalogueHTAInfoData(params:any,fileData:any):Promise<any>{
    const formData = new FormData()
    formData.append('file',fileData[0])
    return new Promise((resolve, reject) => {
      this.http.post(`${this.projectAPIUri}/AnalogueTool/UploadAnalogueHTAInfo?country=${params.country}&frameworkName=${params.framework.valueFrameworkName}`,
      formData).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  


}
