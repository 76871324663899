
export interface UserPreferenceInput {
  payload: string[];
}

export interface UserPreference {
  type: string;
  name: string;
}

export enum UserPreferenceTypes {
  ColumnOrder = '_columnorder',
  FilterPreset = 'filterpreset',
}
