import { Subject } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MobileDrawer } from '../layout/drawer';
import { ProjectPanels } from '../project-details/layout/project-panels';
import { CachedAccessPermissions, PermissionsResponse } from '../../core/access-permissions';
import { AccessBarrierService } from '../access-barrier';
import { AccessPagesEnum, RoutesEnum } from '../common';

@Component({
  selector: 'he-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  providers: [
    {provide: MobileDrawer, useValue: new Subject()},
    ProjectPanels,
    {provide: AccessBarrierService, useExisting: CachedAccessPermissions}
  ]
})
export class ShellComponent implements OnInit {
  drawerPanel = {
    visibility: false
  };

  accessBarrierService: AccessBarrierService | undefined;

  public accessPagesEnum = AccessPagesEnum;
  public routesEnum = RoutesEnum;
  permissionList: PermissionsResponse[] = [];
  tabDisabledTooltip = 'Please contact support@globalpricing.com for more information';

  irpAllowed: boolean = false;
  projectsAllowed: boolean = false;
  searchAllowed: boolean = false;

  constructor(
    @Inject(MobileDrawer) private drawer: Subject<any>,
    private projectPanels: ProjectPanels,
    private accessPermissions: CachedAccessPermissions
  ) {
    // enable typecheck
    this.accessBarrierService = accessPermissions;
  }
  ngOnInit(): void {
    this.checkPermissions();
  }


  async checkPermissions() {
    const accessibleRoute = await this.accessPermissions
    .run({ payload: null })
    .toPromise();
    this.permissionList = accessibleRoute?.payload as [];
    this.irpAllowed = this.isPermissionActive(this.accessPagesEnum.irp)
    this.projectsAllowed = this.isPermissionActive(this.accessPagesEnum.projects)
    this.searchAllowed = this.isPermissionActive(this.accessPagesEnum.mainSearchBar)
  }

  isPermissionActive(data:string): boolean {
    return !!this.permissionList.find(
      (item) => item.code.toLowerCase() === data.toLowerCase()
    );
  }

  onToggleDrawer(): void {
    this.drawerPanel = {
      visibility: !this.drawerPanel.visibility
    };
    this.drawer.next(this.drawerPanel);

    this.projectPanels.onToggleWest();
  }

  onDisablePanelAnimation(): void {
    this.projectPanels.onDisableAnimation();
  }

  onEnablePanelAnimation(): void {
    this.projectPanels.onEnableAnimation();
  }

}
