import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UploadWarningComponent } from '../upload-warning/upload-warning.component';
import { Subject, takeUntil } from 'rxjs';
import { UploadDataPageComponent } from '../upload-data-page/upload-data-page.component';
import { UploadDataService } from '../../services/upload-data.service';


@Component({
  selector: 'he-upload-data-sidebar',
  templateUrl: './upload-data-sidebar.component.html',
  styleUrls: ['./upload-data-sidebar.component.scss'],
})
export class UploadDataSidebarComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @Input() public sideBarOpen = true;

  @ViewChild(UploadDataPageComponent) uploadDataPage?: UploadDataPageComponent;

  public currentRoute = '';
  uploadMenu = [
    { viewValue: 'COT Data', value: 'cotData' },
    { viewValue: 'Tagging Data', value: 'taggingData' },
    { viewValue: 'Analogue Info Data', value: 'analogueInfoData' },
    { viewValue: 'Value Score Data', value: 'valueScoreData' },
    { viewValue: 'Reference Data', value: 'referenceData' },
  ];
  private unsubscriber$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public uploadDataService: UploadDataService
  ) {}

  ngOnInit() {
    const queryParams = { data: this.uploadMenu[0].value };
    this.activatedRoute.queryParams.subscribe((res) => {
      this.currentRoute = res.data;
      if (res.data === undefined || res.data === '') {
        this.router.navigate([], {
          queryParams,
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  toggleSideBar(): void {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  fileListWarning(data: any) {
      if(this.uploadDataService.fileListDataLength.value>0 && !this.uploadDataService.fileDataStatus.value&& this.currentRoute !== data){
        this.dialog
        .open(UploadWarningComponent, {
          data: {
            message: `Selected file has not been uploaded`,
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe((res) => {
          if (res) {
            this.router.navigateByUrl(`/horizon/upload-data?data=${data}`);
          }
          else{
            return
          }
        });
      }
      else{
        this.router.navigateByUrl(`/horizon/upload-data?data=${data}`);
      }
  }

  checkRoutes(menuName: any) {
    if (this.router.url.includes(menuName)) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
