<ng-container *ngIf="context.ref$ | async | contextConfig | async as msg">

  <ng-container [ngSwitch]="msg.type">
    <ng-container *ngSwitchCase="'pending'">
      <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
    </ng-container>
    <ng-container *ngSwitchCase="'fail'">
      <div class="p-4">
        <h1>
          <ng-container [ngSwitch]="msg.payload.status">
            <ng-container *ngSwitchCase="0">
              {{ errorPageText.ConnectivityIssueMessage }}
            </ng-container>
            <ng-container *ngSwitchCase="403">
              {{ errorPageText.ConnectivityIssueMessage }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ errorPageText.InternalErrorMessage }}
            </ng-container>
          </ng-container>
        </h1>
        <p class="he-secondary-text pt-2">Please contact <a href="mailto:support@globalpricing.com"
            tabindex="-1">support@globalpricing.com</a> for more information</p>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'done'">
      <he-command (fire)="onExtendedContext(msg.payload)"></he-command>
      <router-outlet></router-outlet>
    </ng-container>
    <ng-container *ngSwitchDefault>
      UNKNOWN STATE
    </ng-container>
  </ng-container>

</ng-container>
