import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { GridViewCustomAPIService } from './gridviewcustom-api.service';
import { CustomColumnListOutput } from './interfaces';

@Pipe({
  name: 'fetchGridViewCustom'
})
export class FetchGridViewCustomPipe implements PipeTransform {

  constructor(private service: GridViewCustomAPIService) {
  }
  transform(gridviewType: string | null): Observable<CustomColumnListOutput> {
    return this.service.getGridViewCustomDetails(gridviewType ?? "");
  }
}
