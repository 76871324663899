import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralInfoGridComponent } from './general-info-grid.component';
import { GridCommonModule } from '../../../../uikit/grid-common';


@NgModule({
  declarations: [GeneralInfoGridComponent],
  imports: [
    CommonModule,
    GridCommonModule,
  ],
  exports: [GeneralInfoGridComponent]
})
export class GeneralInfoGridModule { }
