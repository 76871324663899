import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestTypeEnum } from '../../uikit/filters';
import { SharedService } from '../../uikit/service/shared.service';

@Injectable()
export class GridViewCustomizationApi {
  constructor(private httpClient: HttpClient, private sharedService: SharedService) {
  }

  transfer(req: unknown): Observable<unknown> {
    // if(this.sharedService.datastoreTabSelectedIndex$.value==Number(RequestTypeEnum.PnR))    
      return this.httpClient.post<unknown>('/IndicationCoT/selectedColumns', req);
  }
}
