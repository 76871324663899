import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './filters.component';
import { FilterByBrandModule } from './filter-by-brand';
import { FilterCompareWithModule } from './filter-compare-with';
import { FilterByCountryModule } from './filter-by-country';
import { FilterByIndicationModule } from './filter-by-indication';
import { MatExpansionModule } from '@angular/material/expansion';
import { FilterMeasuresPeriodModule } from './filter-measures-period';
import { FilterMeasuresCalculateParametersModule } from './filter-measures-calculate-parameters';
import { FilterMeasuresPriceCategoryModule } from './filter-measures-price-category';
import { FilterMeasuresPriceTypeModule } from './filter-measures-price-type';
import { FilterProductParamsOrphanStatusModule } from './filter-product-params-orphan-status';
import { FilterProductParamsBrandedStatusModule } from './filter-product-params-branded-status';
import { FilterProductParamsDiscontinuedModule } from './filter-product-params-discontinued';
import { FilterProductParamsParallelImportModule } from './filter-product-params-parallel-import';
import { FilterProductParamsReimbursementModule } from './filter-product-params-reimbursement';
import { DatepickerRangeModule } from '../../../uikit/datepicker-range';
import { InputFieldRangeModule } from '../../../uikit/input-field-range';
import { FilterMeasuresCurrencyModule } from './filter-measures-currency';
import { FilterMeasuresShowCOTModule } from './filter-measures-show-cot';
import { FilterCotLevelModule } from './filter-cot-level';
import { FilterMeasuresAverageWeightModule } from './filter-by-average-weight/filter-measures-average-weight.module';
import { FilterBodySurfaceAreaModule } from './filter-body-surface-area/filter-body-surface-area.module';
import { FilterPresetModule } from './filter-preset';
import { FeatureFlagModule } from '../../feature-flag/feature-flag.module';

@NgModule({
  declarations: [FiltersComponent],
  exports: [FiltersComponent],
    imports: [
        CommonModule,
        FilterCompareWithModule,
        FilterByCountryModule,
        FilterByBrandModule,
        FilterByIndicationModule,
        MatExpansionModule,
        FilterMeasuresPeriodModule,
        FilterMeasuresCalculateParametersModule,
        FilterMeasuresShowCOTModule,
        FilterMeasuresPriceCategoryModule,
        FilterMeasuresPriceTypeModule,
        FilterProductParamsOrphanStatusModule,
        FilterProductParamsBrandedStatusModule,
        FilterProductParamsDiscontinuedModule,
        FilterProductParamsParallelImportModule,
        FilterProductParamsReimbursementModule,
        DatepickerRangeModule,
        InputFieldRangeModule,
        FilterMeasuresCurrencyModule,
        FilterCotLevelModule,
        FilterMeasuresAverageWeightModule,
        FilterBodySurfaceAreaModule,
        FilterPresetModule,
        FeatureFlagModule
    ]
})
export class DatastoreFiltersModule {
}
