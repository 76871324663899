<mat-card class="cot-calculator">
  <mat-card-content>
    <he-info-widget [showDiv]="false">
      <ng-container *heInfoTitle>{{
        "Cost of Treatment Calculator"
      }}</ng-container>
    </he-info-widget>
    <div class="option-select">
      <div class="selected-option">
        <mat-form-field appearance="outline" class="he-form-field">
          <input
            type="text"
            placeholder="Select Country"
            aria-label="Number"
            matInput
            #countryInput
            [formControl]="countryControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            [hideSingleSelectionIndicator]="false"
            #auto="matAutocomplete"
            (optionSelected)="selected($event.option.value)"
            (opened)="filterCountries()"
          >
            <mat-option
              style="min-height: 32px !important"
              *ngFor="let option of filteredCountryOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="chip-list">
          <he-cot-chip-list
            [selectedItems]="selectedCountries"
            (deletedItemChanges)="removeSeleted($event)"
          ></he-cot-chip-list>
        </div>
      </div>
      <div class="selected-option">
        <mat-form-field appearance="outline" class="he-form-field">
          <input
            type="text"
            placeholder="Select Drugs/Brands"
            aria-label="Number"
            matInput
            #brandInput
            [formControl]="brandControl"
            [matAutocomplete]="brandauto"
          />
          <mat-autocomplete
            [hideSingleSelectionIndicator]="false"
            #brandauto="matAutocomplete"
            (optionSelected)="selectBrands($event.option.value)"
            (opened)="filterBrands()"
          >
            <mat-option
              style="min-height: 32px !important"
              *ngFor="let option of filteredBrandOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="chip-list">
          <he-cot-chip-list
            [selectedItems]="selectedBrands"
            (deletedItemChanges)="removeSeletedBrand($event)"
          ></he-cot-chip-list>
        </div>
      </div>

    </div>


    <div class="calculator-grid">
      <he-grid
        [columnDefs]="columnDefs"
        [frameworkComponents]="frameworkComponents"
        [rowData]="dataSource"
        [suppressColumnVirtualisation]="true"
        [pagination]="false"
        [paginationEnable]="false"
        (gridReady)="saveGridApi($event)"
      ></he-grid>
    </div>
    <div class="calculator-footer">
      <button class="he-button-blue-green" (click)="close()">Cancel</button>
      <div>
        <button
          class="he-button-blue-green"
          [disabled]="!edited"
          [ngClass]="{ 'he-button-blue-green-disabled': !edited }"
          (click)="preview()"
        >
          Preview
        </button>
        <button
          class="he-button-blue-green"
          [disabled]="!edited"
          [ngClass]="{ 'he-button-blue-green-disabled': !edited }"
          (click)="saveCot()"
        >
          Save as COT
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
