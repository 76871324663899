<ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
<div class="d-flex">
  <div class="mr-1 w-100">
    <mat-form-field appearance="outline" placeholder="" class="he-form-field">
      <label>
        <input matInput
               type="number"
               placeholder="{{ descriptionsStart.placeholder }}"
               pattern="[0-9.,]+"
               [min]="0"
               [max]="maxStartValue"
               [formControl]="rangeControlStart"
               (ngModelChange)="startValueModified($event)"
        >
      </label>
    </mat-form-field>
    <mat-error class="he-error" *ngIf="rangeControlStart.errors">
      <he-icon [id]="'suggested-triangle-exclamation-mark'"></he-icon>
      <span>{{ descriptionsStart.errorMessage }}</span>
    </mat-error>
  </div>
  <div class="ml-1 w-100">
    <mat-form-field appearance="outline" placeholder="" class="he-form-field">
      <label>
        <input matInput
               type="number"
               placeholder="{{ descriptionsEnd.placeholder }}"
               pattern="[0-9.,]+"
               [min]="minEndValue"
               [formControl]="rangeControlEnd"
               (ngModelChange)="endValueModified($event)"
        >
      </label>
    </mat-form-field>
    <mat-error class="he-error" *ngIf="rangeControlEnd.errors">
      <he-icon [id]="'suggested-triangle-exclamation-mark'"></he-icon>
      <span>{{ descriptionsEnd.errorMessage }}</span>
    </mat-error>
  </div>
</div>
