import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../../interfaces';
import { FiltersValueService } from '../../filters-value.service';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AddUserPreference } from '../../../../core/add-user-preference';
import { AddUserPreferenceApi } from '../../../../helios-api/filters';
import { FilterByCurrency } from '../../../../core/filter-by-currency';
import { FilterByCurrencyApi } from '../../../../helios-api/filters/filter-by-currency/filter-by-currency.api';
import { currencyNameFormatter } from '../../../../core/filter-by-currency/currency.formatter';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';

@Component({
  selector: 'he-filter-measures-currency',
  templateUrl: './filter-measures-currency.component.html',
  styleUrls: ['./filter-measures-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterByCurrencyApi,
    {
      provide: FilterByCurrency,
      useFactory: (gateway: FilterByCurrencyApi) => {
        return new FilterByCurrency(gateway);
      },
      deps: [FilterByCurrencyApi]
    },
    AddUserPreferenceApi,
    {
      provide: AddUserPreference,
      useFactory: (gateway: AddUserPreferenceApi) => {
        return new AddUserPreference(gateway);
      },
      deps: [AddUserPreferenceApi]
    }
  ]
})
export class FilterMeasuresCurrencyComponent implements OnInit {

  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  currencyItems$: Observable<FilterItemValueInterface[]> = of([]);
  title = 'Currency';
  showSaveButton = false;
  showErrorMessage = false;
  showLocalCurrencyCheckBox = false;
  private readonly destroying$: Subject<void> = new Subject<void>();


  constructor(public filtersValueService: FiltersValueService,
              private filterByCurrency: FilterByCurrency,
              private addUserPreference: AddUserPreference,
              public sharedService: SharedService) {
  }

  get selectedItem(): FilterItemValueInterface {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.currency).filterValue[0];
  }

  get isLocalCurrencySelected(): boolean {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.localCurrency).filterValue[0]?.name;
  }

  ngOnInit(): void {
    this.currencyItems$ = this.filterByCurrency.run({payload: {query: ''}}).pipe(
      map((items: FilterItemValueInterface[]) => currencyNameFormatter(items)));

    this.subscribeToModeSelected();
  }

  subscribeToModeSelected(): void {
    this.sharedService?.gridModeValue$.subscribe(mode => {
        if (mode === 'table'){
          this.showLocalCurrencyCheckBox = true;
        }else{
          this.showLocalCurrencyCheckBox = false;
          this.filtersValueModified.emit(
            this.filtersValueService.updateItemInFilters(FilterNameEnum.localCurrency, [{name: false}])
          );
        }
      } );
  }

  onLocalCurrencySelectedChange(): void {
    this.filtersValueModified.emit(
      this.filtersValueService.updateItemInFilters(FilterNameEnum.localCurrency, [{name: !this.isLocalCurrencySelected}])
    );
  }

  saveAsDefault(selectedItem: any): void {
    const updatedItem = [];
    const updatedObj = {
      name: selectedItem.type,
      type: 'currency'
    };
    updatedItem.push(updatedObj);

    this.addUserPreference.run({
      payload: updatedItem
    }).toPromise().then(
      (res: any) => {
        if (res.type === 'done'){
          this.showSaveButton = false;
        }
      }
    );
  }

  itemSelectEvent(item: FilterItemValueInterface): void {
    if (this.selectedItem.name === item.type){
    this.showSaveButton = false;
    }else{
      this.showSaveButton = true;
    }
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.currency, [item]));
  }
}
