import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InfoChartDirective } from './info-chart.directive';
import { InfoDescriptionDirective } from './info-description.directive';
import { InfoTableDirective } from './info-table.directive';
import { InfoTitleDirective } from './info-title.directive';
import { InfoPanelDirective } from './info-panel.directive';
import { InfoActionsDirective } from './info-actions.directive';
import { TawkChatService } from '../../app/chat/tawk-chat.service';
import { InfoGroupByDirective } from './info-group-by.directive';
import { InfoButtonDirective } from './info-button.directive';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'he-info-widget',
  templateUrl: './info-widget.component.html',
  styleUrls: ['./info-widget.component.scss']
})
export class InfoWidgetComponent implements OnInit, OnDestroy {
  @Input() supportedModes: 'table' | 'chart' | 'combined' = 'combined';
  @Input() chartModeDisabled$: Observable<boolean | null> | undefined;
  @Input() showDiv: any;
  @Input() chartModeTooltip: string | null = null;
  @Input() showLegend: boolean = false;
  @Output() exportMode = new EventEmitter();
  @ContentChild(InfoChartDirective, { static: false })
  public chart: InfoChartDirective | undefined;

  @ContentChild(InfoDescriptionDirective, { static: false })
  public description: InfoDescriptionDirective | undefined;

  @ContentChild(InfoTableDirective, { static: false })
  public table: InfoTableDirective | undefined;

  @ContentChild(InfoTitleDirective, { static: false })
  public title: InfoTitleDirective | undefined;

  @ContentChild(InfoPanelDirective, { static: false })
  public panel: InfoPanelDirective | undefined;

  @ContentChild(InfoActionsDirective, {static: false})
  public actions: InfoActionsDirective | undefined;

  @ContentChild(InfoGroupByDirective, {static: false})
  public group: InfoActionsDirective | undefined;

  @ContentChild(InfoButtonDirective, {static: false})
  public infoButton: InfoButtonDirective | undefined;

  public modes = {
    chart: 'chart',
    table: 'table',
    combined: 'combined'
  };
  public mode = 'chart';
  public isChartModeDisabled = false;

  private readonly destroying$: Subject<void> = new Subject<void>();

  constructor(private tawkService: TawkChatService) {
  }

  ngOnInit(): void {
    if (this.supportedModes !== this.modes.combined) {
      this.mode = this.supportedModes;
    }
    this.exportMode.emit(this.mode);

    this.subscribeDisabledMode();
  }

  subscribeDisabledMode(): void {
    this.chartModeDisabled$?.pipe(
      takeUntil(this.destroying$)
    ).subscribe((chartModeDisabled) => {
      this.isChartModeDisabled = chartModeDisabled ?? false;

      if (this.isChartModeDisabled && this.mode === 'chart') {
        this.onUseTable();
      }
      else {
        this.onUseChart();
      }
    });
  }

  onUseChart(): void {
    if (!this.isChartModeDisabled)
    {
      this.mode = this.modes.chart;
      this.exportMode.emit(this.mode);
    }
  }

  onUseTable(): void {
    this.mode = this.modes.table;
    this.exportMode.emit(this.mode);
  }

  hasToggler(): boolean {
    if (this.showDiv){
      return this.supportedModes === this.modes.combined;
    }else{
      return false;
    }
  }

  onAskQuestion(): void {
    this.tawkService.ActivateChat();
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
