import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';

declare var Tawk_API: any;

@Injectable()
export class TawkChatService {

  private loaded = false;
  private renderer: Renderer2;

  constructor(
    private authService: MsalService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ActivateChat(): void {
    this.setChatVisibility(true);
    this.expandChatWindow(true);

  }

  SetVisitor(visitor: any): void {
    Tawk_API.visitor = visitor;
  }

  load(): void {
    if (this.loaded) {
      return;
    }

    const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    const tawkUrl = environment.tawkUrl;
    const userName: string = this.authService.instance.getAllAccounts().length &&
                             this.authService.instance.getAllAccounts()[0].name?.replace(/[^a-zA-Z ]/g, '') || '';
    const userEmail: string = this.authService.instance.getAllAccounts().length && this.authService.instance.getAllAccounts()[0].username || '';

    s.text = `var Tawk_API = Tawk_API || {};
    Tawk_API.visitor = {
      name : '${userName}',
      email : '${userEmail}'
      };
    Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = '${tawkUrl}';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();`;
    this.renderer.appendChild(this.document.body, s);
    Tawk_API.onLoad = this.loadedEvent.bind(this);
    Tawk_API.onChatMinimized = this.minimizedEvent.bind(this);
  }

  private loadedEvent(): void {
    this.loaded = true;
    this.setChatVisibility(false);
  }

  private minimizedEvent(): void {
    this.setChatVisibility(false);
  }

  private expandChatWindow(show: boolean = false): void {
    show ? Tawk_API.maximize() : Tawk_API.minimize();
  }

  private setChatVisibility(show: boolean = false): void {
    show ? Tawk_API.showWidget() : Tawk_API.hideWidget();
  }
}

