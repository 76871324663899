import { Injectable } from '@angular/core';
import { PriceColorCodes } from 'projects/helios-gui/src/app/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegendService {
  calculatedPriceToggleValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  estimatedPriceToggleValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  toggleCalculatedPrice(): void {
    this.calculatedPriceToggleValue$.next(!this.calculatedPriceToggleValue$.value);
  }

  toggleEstimatedPrice(): void {
    this.estimatedPriceToggleValue$.next(!this.estimatedPriceToggleValue$.value);
  }

  shouldShowPrice(priceColorCode: string, showCalculated?: boolean, showEstimated?: boolean) {
    if (showCalculated === undefined) {
      showCalculated = this.calculatedPriceToggleValue$.value;
    }
    if (showEstimated === undefined) {
      showEstimated = this.estimatedPriceToggleValue$.value;
    }

    return priceColorCode == PriceColorCodes.Published || showCalculated && priceColorCode == PriceColorCodes.Calculated || showEstimated && priceColorCode == PriceColorCodes.Estimated;
  }

}
