import { Component } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { CotCalculatorService } from '../services/cot-calculator.service';

@Component({
  selector: 'he-cot-grid-dropdown',
  templateUrl: './cot-grid-dropdown.component.html',
  styleUrls: ['./cot-grid-dropdown.component.scss'],
})
export class CotGridDropdownComponent {
  toppingList: any[] = [
    { name: 'Year', id: 'Year' },
    { name: 'Month', id: 'Month' },
    { name: 'Week', id: 'Week' },
    { name: 'Day', id: 'Day' },
  ];
  packDescription: any[] = [];
  regIndication: any[] = [];
  public dropDownItems: any = [];
  // toppingList: any[] = [{name:'Year'}, {name:'Month'}, {name: 'Week'}, {name: 'Day'}];
  public params: any;

  selectedItem: any = 'Year';

  constructor(
    private titleCasePipe: TitleCasePipe,
    private cotCalculatorService: CotCalculatorService,) {}
  agInit(params: any): void {
    this.params = params;
    this.setDropdownList();
  }

  public setDropdownList(): void {
    switch (this.params.colDef.field) {
      case 'packDescription':
        this.dropDownItems = this.params.data.packDescriptionList.map(
          (x: any) => {
            return {
              name: x.packDescription,
              id: x.packID,
            };
          }
        );
        break;
      case 'regulatoryIndication':
        this.dropDownItems = this.params.data.regulatoryIndicationList.map(
          (x: any) => {
            return {
              name: x.regulatoryIndication,
              id: x.regulatoryIndicationID,
            };
          }
        );
        break;
      case 'duration':
        this.dropDownItems = this.toppingList;

        break;
    }
  }

  itemSelectEvent(event: any): void {
    switch (this.params.colDef.field) {
      case 'packDescription':
        this.changePack(event);
        break;
      case 'regulatoryIndication':
        let data = this.params.data.regulatoryIndicationList.filter(
          (x: any) => x.regulatoryIndicationID === event
        )[0];
      this.changeIndication(data)
        break;
      case 'duration':
        // this.dropDownItems = this.toppingList;

        break;
    }
    this.checkEditedValue();
  }

  changePack(packData: any): void {
    let data = this.params.data.packDescriptionList.filter(
      (x: any) => x.packID === packData
    )[0];
    this.params.data.packPrice = data.perPack.toFixed(2);
      this.params.data.pricePerUnit = {
        defaultValue: data.perUnit.toFixed(2),
        initialValue: data.perUnit.toFixed(2),
        currentValue: data.perUnit.toFixed(2),
      };
      this.params.data.pricePerMg = {
        defaultValue: data.perMg.toFixed(2),
        initialValue: data.perMg.toFixed(2),
        currentValue: data.perMg.toFixed(2),
      };
    this.params.regulatoryIndicationList = data.regulatoryIndicationList;
    this.changeIndication(data.regulatoryIndicationList[0]);
  }

  public checkEditedValue(): void {
    let editList = [
      'packDescription',
      'regulatoryIndication',
      'duration',
      'averageBodySurfaceArea',
      'averageBodyWeight',
      'maintainanceDose',
      'pricePerMg',
      'pricePerUnit',
      'loadingDose',
      'pricePerUnit',
      'loadingDoseFrequency',
      'loadingDoseDuration',
      'maintainanceDose',
      'maintainanceDoseFreaquency',
      'maintainanceDoseDuration',
      'averageBodyWeight',
      'averageBodySurfaceArea',
    ];
    let editLength = 0;
    editList.forEach((res) => {
      if (
        (this.params.data[res].defaultValue?.id ? this.params.data[res].defaultValue?.id : this.params.data[res].defaultValue) !=
        (this.params.data[res].currentValue?.id ? this.params.data[res].currentValue?.id : this.params.data[res].currentValue)

      ) {
        editLength++
      }

    });
    if(editLength >0){
      this.params.data.edited = true;
      this.params.data.reEdited = true;
      this.cotCalculatorService.countryBrandata.next(this.params.data);
    }else{
      this.params.data.edited = false;
      this.params.data.reEdited = false;
      this.cotCalculatorService.countryBrandata.next(this.params.data);
    }
  }

  changeIndication(indication: any): void {
    this.params.data.duration= {
      defaultValue:{
        name:this.titleCasePipe.transform(indication.unit) ,
        id:this.titleCasePipe.transform(indication.unit) ,
      } ,
      currentValue:{
        name:this.titleCasePipe.transform(indication.unit) ,
        id:this.titleCasePipe.transform(indication.unit) ,
      } ,
    },
    this.params.data.loadingDose = {
      defaultValue: indication.productDosingLoading!==null? indication.productDosingLoading.toFixed(2):null,
      initialValue: indication.productDosingLoading!==null? indication.productDosingLoading.toFixed(2):null,
      currentValue: indication.productDosingLoading!==null? indication.productDosingLoading.toFixed(2):null,
    };
      this.params.data.loadingDoseFrequency = {
        defaultValue: indication.frequencyLoading!==null? indication.frequencyLoading.toFixed(2):null,
        initialValue: indication.frequencyLoading!==null? indication.frequencyLoading.toFixed(2):null,
        currentValue: indication.frequencyLoading!==null? indication.frequencyLoading.toFixed(2):null,
      };
      this.params.data.loadingDoseDuration = {
        defaultValue: indication.durationLoading!==null? indication.durationLoading.toFixed(2):null,
        initialValue: indication.durationLoading!==null? indication.durationLoading.toFixed(2):null,
        currentValue: indication.durationLoading!==null? indication.durationLoading.toFixed(2):null,
      };
      this.params.data.maintainanceDose = {
        defaultValue: indication.productDosingMaintenance!==null? indication.productDosingMaintenance.toFixed(2):null,
        initialValue: indication.productDosingMaintenance!==null? indication.productDosingMaintenance.toFixed(2):null,
        currentValue: indication.productDosingMaintenance!==null? indication.productDosingMaintenance.toFixed(2):null,
      };
      this.params.data.maintainanceDoseFreaquency = {
        defaultValue: indication.frequencyMaintenance!==null? indication.frequencyMaintenance.toFixed(2):null,
        initialValue: indication.frequencyMaintenance!==null? indication.frequencyMaintenance.toFixed(2):null,
        currentValue: indication.frequencyMaintenance!==null? indication.frequencyMaintenance.toFixed(2):null,
      };
      this.params.data.maintainanceDoseDuration = {
        defaultValue: indication.durationMaintenance!==null? indication.durationMaintenance.toFixed(2):null,
        initialValue: indication.durationMaintenance!==null? indication.durationMaintenance.toFixed(2):null,
        currentValue: indication.durationMaintenance!==null? indication.durationMaintenance.toFixed(2):null,
      };
      this.params.data.averageBodyWeight = {
        defaultValue: indication.averageWeight!==null? indication.averageWeight.toFixed(2):null,
        initialValue: indication.averageWeight!==null? indication.averageWeight.toFixed(2):null,
        currentValue: indication.averageWeight!==null? indication.averageWeight.toFixed(2):null,
      };
      this.params.data.averageBodySurfaceArea = {
        defaultValue: indication.bodySurfaceArea!==null? indication.bodySurfaceArea.toFixed(2):null,
        initialValue: indication.bodySurfaceArea!==null? indication.bodySurfaceArea.toFixed(2):null,
        currentValue: indication.bodySurfaceArea!==null? indication.bodySurfaceArea.toFixed(2):null,
      };
      this.params.api.refreshCells();
  }
}
