import { NgModule } from "@angular/core";
import { FeatureFlagDirective } from "./feature-flag.directive";
import { FeatureFlagService } from "./feature-flag.service";
import { FeatureFlagApi } from "../../helios-api/feature-flag/feature-flag-api";

@NgModule({
    declarations: [FeatureFlagDirective],
    exports: [FeatureFlagDirective],
    providers: [FeatureFlagService, FeatureFlagApi]
})

export class FeatureFlagModule {
}
