<div>
    <div class="markets">
        <div class="country">
            <div class="flag">
                <img #tooltip="matTooltip"
                matTooltip="{{market.country}}" class="" src='{{ "assets/flags/" + market.id + ".svg" }}' width="22px">
            </div>
            <div class="name">
                <p>{{market.country}}</p>
            </div>
            <mat-icon
            *heFeatureFlag="{ Name: 'asset-tpp-info', Page: 'Asset-Tpp' }"
            #tooltip="matTooltip"
            matTooltip="{{ market.info }}"
            matTooltipClass="horizon-tooltip"
            >info</mat-icon>
        </div>
        <div class="per-input" style="flex-direction: column;position: relative;">
            <div style="display: flex;">
                <mat-form-field class="mat-form-field" [ngClass]="{ 'disable-Input':!readOnly }" appearance="fill">
                    <input matInput [readonly]="!readOnly" heOnlyNumber placeholder="0.00" type="number" min="0" max="100" step="0.10" [formControl]="percentage" [(ngModel)]="market.NetPriceDiscount" [value]="market.NetPriceDiscount" (change)="changePrice()">
                  <!-- <mat-error>Value cannot be more than 100 or less than 0</mat-error> -->
                  </mat-form-field>
                  <p> %</p>
            </div>
            <mat-error style="top: 42px;width: 152px; position: absolute;">{{error}}</mat-error>

        </div>
    </div>
</div>