import { Injectable } from '@angular/core';
import * as Countries from 'country-list';


@Injectable()
export class CountryFlagService {
  public getFlagId(country: string): string {
    switch (country.toLowerCase()) {
      case 'turkey':
        return 'tr';
      case 'united kingdom':
        return 'gb';
      case 'england':
        return 'gb-eng';
      case 'czech republic':
        return 'cz';
      case 'iran':
        return 'ir';
      case 'macedonia':
        return 'mk';
      case 'moldova':
        return 'md';
      case 'russia':
        return 'ru';
      case 'south korea':
        return 'kr';
      case 'taiwan':
        return 'tw';
      case 'uae':
        return 'ae';
      case 'united states':
      case 'usa':
        return 'us';
      case 'vietnam':
        return 'vn';
      case 'canada ontario':
      case 'canada federal':
      case 'canada quebec':
      case 'canada-ontario':
        return 'ca';
      case 'scotland':
        return 'gb-sct';
      case 'usd':
        return 'usd';
      case 'eur':
        return 'eur';
      case 'local':
        return 'local';
    }
    return Countries.getCode(country)?.toLowerCase() || '';
  }

}
