import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CurrencyConversionService {
  uri = environment.gatewayApiEndpoint; //pulse baseurl
  uriH = environment.gatewayApiEndpointHorizon; //pulse baseurl

  currencyData: any[] = [];
  countryCurrencyMapper: any[] = [];
  selectedCurrency: BehaviorSubject<string> = new BehaviorSubject<string>(
    'USD'
  );
  selectedCurrencySymbol: string = '$';
  defaultCurrency = 'USD';
  defaultCurrencySymbol = '$';

  constructor(private http: HttpClient, private currencyPipe: CurrencyPipe) {
    this.loadCurrencyConversionData();
  }

  SetDefaultCurrency(currencyCode: string = 'USD') {
    this.defaultCurrency = currencyCode;
  }

  SetDefaultCurrencySymbol(currencySymbol: string = '$') {
    this.defaultCurrencySymbol = currencySymbol;
  }

  GetSelectedCurrency() {
    return this.selectedCurrency;
  }

  async SetSelectedCurrency(Currency: string) {
    const data = this.currencyPipe.transform(10, Currency);
    this.selectedCurrencySymbol = data ? data?.charAt(0) : '';
    this.selectedCurrency.next(Currency);
  }

  GetSelectedCurrencySymbol() {
    return this.selectedCurrencySymbol;
  }

  GetDefaultCurrency() {
    return this.defaultCurrency;
  }

  GetDefaultCurrencySymbol() {
    return this.defaultCurrencySymbol;
  }

  convertSymbol(currency:any):string{
    let newSymbol:string=""
    const hexCountrySymbol = currency.toUpperCase();

    if (hexCountrySymbol.includes('&#X')) {
      const decimalCurrCode = parseInt(
        hexCountrySymbol.replace('&#X', '').trim(),
        16
      );

      newSymbol = String.fromCharCode(decimalCurrCode);
    } else {
      newSymbol = String.fromCharCode(
        currency.replace('&#', '')
      );
    }
    return newSymbol;
  }

  /**
   * loads the currency rates for converting the values.
   */
  loadCurrencyConversionData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (this.currencyData.length > 0) {
        resolve(this.currencyData);
      } else {
        //this.http.get(this.uri + '/userconfig/GetCurrenciesDetails')
        this.http
          .get(this.uriH + '/Master/GetCurrencyExchangeRates')
          .subscribe({
            next: (data: any) => {
              this.currencyData = data;
              resolve(this.currencyData);
            },
            error: (error) => {
              reject(error);
            },
          });
      }
    });
  }

  /**
   * loads the country currency mapper data for getting the currency names the values.
   */
  loadCountryCurrencyMapper(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (this.countryCurrencyMapper.length > 0) {
        resolve(this.countryCurrencyMapper);
      } else {
        this.http
          .get(this.uriH + '/Master/GetCountryCurrencyMapping')
          .subscribe({
            next: (data: any) => {
              this.countryCurrencyMapper = data;
              resolve(this.countryCurrencyMapper);
            },
            error: (error) => {
              reject(error);
            },
          });
      }
    });
  }

  async getCountryCurrencyData(Country: string): Promise<any> {
    let currencyData = {};
    await this.loadCountryCurrencyMapper().then((data) => {
      let filterdata = data.filter(
        (x) => x.country.toLowerCase().trim() === Country.toLowerCase().trim()
      );
      if (filterdata.length > 0) currencyData = filterdata[0];
    });
    return currencyData;
  }

  /**
   * converts the us dollar($) value to specific currency values.
   * returns -1 if wrong data is supplied or the api is not responding.
   * @param usdValue is the USD value
   * @param currency is the currency you want to get value in eg EUR,INR
   */
  async getConvertedValue(usdValue: number, currency: string): Promise<number> {
    let converted: number = -1;
    if (!usdValue || !currency) {
      return -1;
    }
    if (currency === 'USD') return usdValue;

    await this.loadCurrencyConversionData().then((data) => {
      let currencyRateObject = data.filter((x) => x.currency == currency);
      if (currencyRateObject.length > 0) {
        const rate = currencyRateObject[0].rate;
        converted = usdValue / rate;
      }
    });
    return converted;
  }

  /**
   * converts the specif currency value to  usd($) values.
   * returns -1 if wrong data is supplied or the api is not responding.
   * @param CurrentValue is the value in @param currency
   * @param currency is the currency you want to supply value in eg EUR,INR
   */

  async getUsdValue(CurrentValue: number, currency: string): Promise<number> {
    let converted: number = -1;
    if (!CurrentValue || CurrentValue < 0 || !currency) {
      return -1;
    }
    if (currency === 'USD') return CurrentValue;

    await this.loadCurrencyConversionData().then((data) => {
      let currencyRateObject = data.filter((x) => x.currency == currency);
      if (currencyRateObject.length > 0) {
        const rate = currencyRateObject[0].rate;
        converted = CurrentValue * rate;
      }
    });
    return converted;
  }

  /**
   * converts the specif currency value to other currency
   * returns -1 if wrong data is supplied or the api is not responding.
   * @param value is the value in @param currentCurrency
   * @param currentCurrency is the currency you want to supply value in eg EUR,INR
   * @param convertedCurrency is the currency you want to convert value to eg EUR,INR
   */
  async convertCurrency(
    value: number,
    currentCurrency: string,
    convertedCurrency: string
  ): Promise<number> {
    let converted: number = -1;
    if (!value || value < 0 || !currentCurrency || !convertedCurrency) {
      return -1;
    }
    let usdValue = await this.getUsdValue(value, currentCurrency);
    converted = await this.getConvertedValue(usdValue, convertedCurrency);
    return converted;
  }
}
