import { Component, OnInit } from '@angular/core';
import { ValueScoringService } from '../../services/value-scoring.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil, pipe, take } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { saveAs } from 'file-saver';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { HeaderService } from '../../../../shared/services/header.service';
import { ProjectService } from '../../../project/services/project.service';
import { ValueFrameworkNameConstant } from '../../../project/enums/criteria.enum';
import { CapitalizeFirstPipe } from '../../../project/pipes/capitalizeFirst.pipe';

@Component({
  selector: 'he-project-value-scoring-summary',
  templateUrl: './project-value-scoring-summary.component.html',
  styleUrls: ['./project-value-scoring-summary.component.scss'],
})
export class ProjectValueScoringSummaryComponent implements OnInit {
  private unsubscriber$ = new Subject<void>();
  projectId = '';
  public valueSummaryRes: any = [];
  public valueSummaryData: any = [];
  valueSummaryHeaderData: any = [
    { key: 'brandName', value: 'Brand Name' },
    { key: 'assessmentDate', value: 'Assessment Date' },
    { key: 'population', value: 'Population' },
  ];
  activeAccordion: any = [0];
  public IsOncologyFrameworkSelected: boolean = false;

  constructor(
    private valueScoringService: ValueScoringService,
    private activatedRouter: ActivatedRoute,
    private papa: Papa,
    private countryFlagService: CountryFlagService,
    private headerService: HeaderService,
    private capitalizeFirstPipe: CapitalizeFirstPipe,
  ) {}

  ngOnInit() {
    
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.projectId = p.project;
        this.IsOncologyFrameworkSelected = this.headerService.IsOncologyFrameworkSelected;
        // if (this.projectId) {
        //   this.headerService.onCountryChanged
        //     .pipe(take(1))
        //     .subscribe((country) => {
        //       this.initialiseCompData();
        //       // this.getValueSummaryData(this.projectId, country);
        //     });
        // }
      });
      this.headerService.onCountryChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((country) => {
        if (this.projectId) {
          this.IsOncologyFrameworkSelected = this.headerService.IsOncologyFrameworkSelected;
        this.initialiseCompData();
        this.getValueSummaryData(this.projectId, country);
        }
      });
  }

  private initialiseCompData() {
    this.valueSummaryRes = [];
    this.valueSummaryData = [];
    if(this.IsOncologyFrameworkSelected == true){
      this.valueSummaryHeaderData = [
        { key: 'brandName', value: 'Brand Name' },
        { key: 'assessmentDate', value: 'Assessment Date' },
        { key: 'indicationHTA', value: 'HTA indication' },
        { key: 'population', value: 'Population' },
      ];
    }
    else{
      this.valueSummaryHeaderData = [
        { key: 'brandName', value: 'Brand Name' },
        { key: 'assessmentDate', value: 'Assessment Date' },
        { key: 'population', value: 'Population' },
      ];
    }
  }

  // getValueSummaryData(projectId:any) {
  //   this.valueScoringService.getValueSummary(projectId).then((res:any) => {
  //     this.valueSummaryRes = res.data;
  //     console.log(this.valueSummaryRes)
  //     this.valueSummaryRes.forEach((element:any) => {
  //       if(this.valueSummaryData.filter((x:any) => x.countryName === element.countryName).length === 0){
  //         this.valueSummaryData.push({
  //           countryName: element.countryName,
  //           valueSummaryBrands:[],
  //         })
  //       }

  //       element.valueSummaries[0].htaEvidences.forEach((e:any) => {
  //         if(this.valueSummaryHeaderData.filter((x:any) => x === e.evidenceType).length === 0){
  //           this.valueSummaryHeaderData.push({
  //             key: e.evidenceType,
  //             value: e.evidenceType
  //           })
  //         }
  //       })

  //       element.valueSummaries.forEach((e:any) => {
  //         if(this.valueSummaryData.filter((x:any) => x.countryName === element.countryName)[0].valueSummaryBrands.filter((y:any) => y.brandName === e.brandName).length === 0){
  //           this.valueSummaryData.filter((x:any) => x.countryName === element.countryName)[0].valueSummaryBrands.push({
  //             brandName: e.brandName,
  //             valueSummaryData:[],
  //             sequence: e.sequenceNo
  //           })
  //         }

  //         this.valueSummaryData.filter((x:any) => x.countryName === element.countryName)[0].
  //         valueSummaryBrands.filter((y:any) => y.brandName === e.brandName)[0].valueSummaryData.push({
  //           brandName: e.brandName,
  //           assessmentDate: e.assessmentDate,
  //           population: e.population,
  //         })

  //         e.htaEvidences.forEach((f:any) => {
  //           this.valueSummaryData.filter((x:any) => x.countryName === element.countryName)[0].
  //           valueSummaryBrands.filter((y:any) => y.brandName === e.brandName)[0].valueSummaryData.
  //           filter((z:any) => z.brandName === e.brandName && z.population === e.population)[0][f.evidenceType] = f.evidenceValue
  //         })

  //       })

  //     })
  //     console.log(this.valueSummaryData)
  //     console.log(this.valueSummaryHeaderData)
  //   });
  // }

  getValueSummaryData(projectId: any, country: string) {
    if (country.toLocaleLowerCase() !== 'united states') {
      this.valueScoringService
        .getValueSummary(projectId, country)
        .then((res: any) => {
          this.valueSummaryRes = res.data;
          this.valueSummaryRes.forEach((element: any) => {
            if (
              this.valueSummaryData.filter(
                (x: any) => x.countryName === element.countryName
              ).length === 0
            ) {
              this.valueSummaryData.push({
                countryName: element.countryName,
                flagId: this.countryFlagService.getFlagId(element.countryName),
                valueSummary: [],
              });
            }

            if (element.valueSummaries[0].htaEvidences) {
              element.valueSummaries[0].htaEvidences.forEach((e: any) => {
                if (
                  this.valueSummaryHeaderData.filter(
                    (x: any) => x === e.evidenceType
                  ).length === 0
                ) {
                  if (e.evidenceType) {
                    this.valueSummaryHeaderData.push({
                      key: e.evidenceType,
                      value: e.evidenceType,
                    });
                  }
                }
              });
            }
            
            if(this.IsOncologyFrameworkSelected == true){

            element.valueSummaries.forEach((e: any) => {
              this.valueSummaryData
                .filter((x: any) => x.countryName === element.countryName)[0]
                .valueSummary.push({
                  brandName: e.brandName ? e.brandName : 'NA',
                  assessmentDate: e.assessmentDate ? e.assessmentDate : 'NA',
                  indicationHTA: e.indicationHTA ? this.capitalizeFirstPipe.transform(e.indicationHTA) : 'NA',
                  population: e.population ? e.population : 'NA',
                  sequence: e.sequenceNo,
                });

              e.htaEvidences.forEach((f: any) => {
                this.valueSummaryData
                  .filter((x: any) => x.countryName === element.countryName)[0]
                  .valueSummary.filter(
                    (z: any) =>
                      z.brandName === e.brandName &&
                      z.population === e.population
                  )
                  .forEach((y: any) => {
                    y[f.evidenceType] = f.evidenceValue
                      ?this.capitalizeFirstPipe.transform( f.evidenceValue)
                      : 'NA';
                  });
              });
            });
          }
          else{
            element.valueSummaries.forEach((e: any) => {
              this.valueSummaryData
                .filter((x: any) => x.countryName === element.countryName)[0]
                .valueSummary.push({
                  brandName: e.brandName ? e.brandName : 'NA',
                  assessmentDate: e.assessmentDate ? e.assessmentDate : 'NA',
                  population: e.population ? e.population : 'NA',
                  sequence: e.sequenceNo,
                });

              e.htaEvidences.forEach((f: any) => {
                this.valueSummaryData
                  .filter((x: any) => x.countryName === element.countryName)[0]
                  .valueSummary.filter(
                    (z: any) =>
                      z.brandName === e.brandName &&
                      z.population === e.population
                  )
                  .forEach((y: any) => {
                    y[f.evidenceType] = f.evidenceValue
                      ? f.evidenceValue
                      : 'NA';
                  });
              });
            });
          }
          });
          this.valueSummaryData.sort(
            (a: any, b: any) =>
              a.valueSummary[0].sequence - b.valueSummary[0].sequence
          );
        });
    }
  }

  get keys() {
    return this.valueSummaryHeaderData.map((x: any) => x.key);
  }

  downloadFile(country: any) {
    const dataArray = this.valueSummaryData
      .filter((x: any) => {
        return x.countryName === country;
      })[0]
      .valueSummary.map((x: any) => {
        const row: any = {};
        this.valueSummaryHeaderData.forEach((y: any) => {
          const headerKey = y.key;
          const headerValue = y.value; // Get the header value from the array
          row[`${headerValue}`] = x[headerKey];
        });
        return row;
      });
    const headerRow = this.valueSummaryHeaderData.map((x: any) => `${x.value}`);
    const csv = this.papa.unparse({
      fields: headerRow,
      data: dataArray,
    });
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, `${country}_Value_Scoring_Summary.csv`);
  }

  toggleAccordian(index: any) {
    if (this.activeAccordion.includes(index)) {
      this.activeAccordion = this.activeAccordion.filter(
        (x: any) => x !== index
      );
    } else {
      this.activeAccordion.push(index);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
