import { Action } from '@ngrx/store';
import { type } from 'os';

export enum HorizonActionTypes {
  LoadingProjects = '[Horizon] Loading Projects',
  LoadProjects = '[Horizon] Load Projects',
  LoadProjectsSuccess = '[Horizon] Loading Projects success',
  ResetProjects = '[Horizon] Reset Projects',
}
export class LoadingProjects implements Action {
  readonly type = HorizonActionTypes.LoadingProjects;
  constructor(public payload: boolean) {}
}
export class LoadProjects implements Action {
  readonly type = HorizonActionTypes.LoadProjects;
  constructor() {}
}

export class LoadProjectsSuccess implements Action {
  readonly type = HorizonActionTypes.LoadProjectsSuccess;
  constructor(public payload: string[]) {}
}

export class ResetProjects implements Action {
  readonly type = HorizonActionTypes.ResetProjects;
  constructor() {}
}

export type HorizonAction =
  | LoadingProjects
  | LoadProjects
  | LoadProjectsSuccess
  | ResetProjects;
