<div class="accordian-header" style="padding: 0 24px">
  <div class="accordian-header-left"></div>
  <div class="accordian-header-right">
    <div class="accordian-header-right-data" *ngFor="let scenario of scenariosList">
      <h5>{{ scenario.scenarioName }}</h5>
    </div>
  </div>
</div>
<!-- <mat-accordion class="summary-tab-accordian" multi="true">
  <mat-expansion-panel
    *ngFor="let valueDomain of filteredValueDomains; index as i"
    (click)="toggleAccordion(i)"
    [expanded]="checkAccordion(i)"
  >
    <mat-expansion-panel-header [ngStyle]="{ background: ColorCodes[i] }">
      <div class="col-md-6">
        <mat-panel-title>
          <mat-icon [ngClass]="checkAccordion(i) ? 'opened' : 'closed'"
            >play_arrow</mat-icon
          >
          {{ valueDomain.name }}
        </mat-panel-title>
      </div>
      <div class="col-md-6">
        <mat-panel-description>
          <div
            class="col-md-4" style="text-align: center;"
            *ngFor="
              let difference of valueDomain.difference;
              index as diffIndex
            "
          >
            <ng-container *ngIf="diffIndex == 0"> - - </ng-container>
            <ng-container *ngIf="diffIndex != 0">
              <ng-container *ngIf="difference.difference === 0">
                No Differences from
                {{ valueDomain.difference[0].scenarioName }}
              </ng-container>
              <ng-container *ngIf="difference.difference !== 0">
                {{ difference.difference }} attribute Differences
              </ng-container>
            </ng-container>
          </div>

        </mat-panel-description>
      </div>
    </mat-expansion-panel-header>
    <div
      class="row summary-description"
      *ngFor="let value of valueDomain.values"
    >
      <div class="row col-md-6">
        <div class="col-md-1">
          <img
            *ngIf="value.country !== 'All'"
            style="float: right"
            src="assets/{{ value.country | lowercase }}.svg"
          />
        </div>
        <div class="col-md-11">
          <h3 class="summary-attributes">
            {{ value.name }}
            {{ value.country === "All" ? "" : "(" + value.country + ")" }}
          </h3>
        </div>
      </div>
      <div class="row col-md-6">
        <div class="col-md-4" *ngFor="let score of value.scenarios">
          <h3
            *ngIf="scoreMode === '1'"
            class="summary-value"
            matTooltipClass="horizon-tooltip"
            matTooltip="{{ score.description }}"
          >
            {{ score.score }}
          </h3>
          <h3 *ngIf="scoreMode === '2'" class="summary-value">
            {{ score.score }} : {{ score.description }}
          </h3>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<br/><br/> -->

<!-- custom accordian -->
<div>
  <div class="accordian-header-tab" *ngFor="let valueDomain of filteredValueDomains; index as i">
    <div
      class="accordian-header-data"
      (click)="openAccordian(i)"
      [ngStyle]="{ background: ColorCodes[i] }"
    >
      <div class="accordian-header-title">
        <mat-icon [ngClass]="activeAccordianList.includes(i) ? 'opened' : 'closed'"
          >play_arrow</mat-icon
        >
        {{ valueDomain.name }}
      </div>
      <div class="accordian-header-differences">
        <div
          class=""
          style="text-align: center; min-width: 192px"
          *ngFor="let difference of valueDomain.difference; index as diffIndex"
        >
          <ng-container *ngIf="diffIndex == 0"> - - </ng-container>
          <ng-container *ngIf="diffIndex != 0">
            <ng-container *ngIf="difference.difference === 0">
              No differences from
              {{ valueDomain.difference[0].scenarioName }}
            </ng-container>
            <ng-container *ngIf="difference.difference !== 0 && difference.difference < 2">
              {{ difference.difference }} Attribute Difference
            </ng-container>
            <ng-container *ngIf="difference.difference !== 0 && difference.difference > 1">
              {{ difference.difference }} Attribute Differences
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <mat-card class="accordian-desc-card" [ngClass]="activeAccordianList.includes(i) ? 'accordian-active' : 'accordian-desc'">
    <div
    *ngFor="let value of valueDomain.values"> 
        <div class="accordian-desc-data">
          <div class="accordian-desc-title">
            <div class="">
              <img
                *ngIf="value.country !== 'All'"
                style="float: right"
                src="assets/flags/small/{{ value.flagId }}.svg"
                width="22px"
              />
            </div>
            <div class="">
              <h3 class="summary-attributes">
                {{ value.name }}
                {{ value.country === "All" ? "" : value.name.includes('Patient number')?'': "(" + value.country + ")" }}
              </h3>
            </div>
          </div>
          <div class="accordian-desc-scores">
            <div class="desc-scores-values" *ngFor="let score of value.scenarios">
              <h3
                *ngIf="scoreMode === '1'"
                class="summary-value"
                matTooltipClass="horizon-tooltip"
                matTooltip="{{ score.description }}"
              >
                {{ score.score }}
              </h3>
              <h3 *ngIf="scoreMode === '2'" class="summary-value">
                {{ score.score }} : {{ score.description }}
              </h3>
            </div>
          </div>
        </div>
    </div>
  </mat-card>
  </div>
</div>
