import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPresetComponent } from './filter-preset.component';
import { SelectModule } from 'projects/helios-gui/src/uikit/select';
import { FilterPresetService } from './filter-preset.service';
import { IconModule } from 'projects/helios-gui/src/uikit/helios-icons';
import { FilterPresetDialogModule } from 'projects/helios-gui/src/uikit/filter-preset-dialog/filter-preset-dialog.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [FilterPresetComponent],
  exports: [FilterPresetComponent],
  imports: [
    CommonModule,
    SelectModule,
    IconModule,
    FilterPresetDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
  ],
  providers: [FilterPresetService]
})
export class FilterPresetModule { }
