import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

type PaletteColor = 'green' | 'yellow' | 'red' | 'grey';

@Component({
  selector: 'he-dot-cell-decision',
  templateUrl: './dot-cell-decision.component.html',
  styleUrls: ['./dot-cell-decision.component.scss']
})
export class DotCellDecisionComponent implements ICellRendererAngularComp {

  public decision: string | any;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.decision = params.value;
  }

  public setColor(decision: string): PaletteColor {
    const comparableDecision = this.decision.toUpperCase();
    if (comparableDecision.indexOf('NOT RECOMMENDED') !== -1
    || comparableDecision.indexOf('NOT REIMBURSED (FORMULARY STATUS)') !== -1) {
      return 'red';
    }
    else if (comparableDecision.indexOf('WITH RESTRICTION') !== -1
      || comparableDecision.indexOf('WITHIN CDF') !== -1
      || comparableDecision.indexOf('(RESTRICTED)') !== -1) {
      return 'yellow';
    }
    else if (comparableDecision.indexOf('RECOMMENDED') !== -1
    || comparableDecision.indexOf('REIMBURSABLE') !== -1
    || comparableDecision.indexOf('REIMBURSED (FORMULARY STATUS)') !== -1) {
      return 'green';
    }
    else {
      return 'red';
    }
  }
}
