import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SharedService } from '../service/shared.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'he-chips-list',
  templateUrl: './chips-list.component.html',
  styleUrls: ['./chips-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsListComponent implements OnInit ,OnDestroy{
  @Input() selectedItems: any[] = [];
  @Input() hasRemoveIcon = true;
  @Input() changeStatusOnClick = false;
  @Input() disabled: boolean | null = null;
  @Input() disabledTooltip: string | undefined;
  @Input() isBrandChipEnable!:boolean;
  @Output() deletedItemChanges: EventEmitter<any> = new EventEmitter();
  @Output() itemUpdated: EventEmitter<void> = new EventEmitter();
  selectedChipIndex: number = -1;
  private readonly destroying$: Subject<void> = new Subject<void>();
  
  constructor(public sharedService:SharedService,
    private changeDetectorRef: ChangeDetectorRef
    ) {
  }

ngOnInit(): void {
  this.sharedService.brandFilterSelectedBrand$.pipe(
    takeUntil(this.destroying$),
  ).subscribe(brandname=>{
    if(brandname=='')
        this.selectedChipIndex=-1;
        this.changeDetectorRef.detectChanges()
        }
  );
}

  remove(item: any): void {
    this.deletedItemChanges.emit(item);
    this.sharedService.brandFilterSelectedBrand$.next('');
  }

  public changeStatus(item: any, index:number): void {  
   
    if(this.isBrandChipEnable && (this.sharedService.activeSectionName$.value=='general' || this.sharedService.activeSectionName$.value==''))
    {
      this.selectedChipIndex = index;
      if(item.name===this.sharedService.brandFilterSelectedBrand$.value)
      {
        this.sharedService.updateSelectedBrand('');
        this.selectedChipIndex=-1;
      }
      else
      {
        this.sharedService.updateSelectedBrand(item.name);
      }
    }

    if (this.changeStatusOnClick) {
      item.status = !item.status;
      this.itemUpdated.next();
    }
  }
  ngOnDestroy(): void {

    this.destroying$.next(undefined);

    this.destroying$.complete();

  }
}
