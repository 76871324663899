import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'he-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputFieldComponent {
}
