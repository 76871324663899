import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CotGridDropdownComponent } from '../cot-grid-dropdown/cot-grid-dropdown.component';
import { CotGridInputComponent } from '../cot-grid-input/cot-grid-input.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CotCalculatorService } from '../services/cot-calculator.service';
import { FiltersValueService } from '../../filters-value.service';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { CotCorridorFetchPipe } from '../../cot-corridor/cot-corridor-fetch.pipe';
import {
  cotApiInterface,
  cotCalculatorGridInterface,
  cotDataInterface,
  cotIdListInterface,
  countryBrandInterface,
} from '../models/cot-calculator.interface';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'he-cot-calculator',
  templateUrl: './cot-calculator.component.html',
  styleUrls: ['./cot-calculator.component.scss'],
})
export class CotCalculatorComponent {
  @ViewChild('countryInput')
  countryInput!: ElementRef<HTMLInputElement>;
  @ViewChild('brandInput')
  brandInput!: ElementRef<HTMLInputElement>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  columnDefs: ColDef[] = [
    {
      headerName: 'Country',
      field: 'countryName',
      width: 200,
      minWidth: 200,
      suppressSizeToFit: true,
      pinned: 'left',
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Brand',
      field: 'brandName',
      width: 150,
      minWidth: 150,
      suppressSizeToFit: true,
      pinned: 'left',
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Pack Description',
      field: 'packDescription',
      cellRenderer: 'CotGridDropdownComponent',
      width: 284,
      minWidth: 284,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Pack Price',
      field: 'packPrice',
      width: 100,
      minWidth: 100,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      cellStyle: {
        textAlign: 'end',
      },
      sortable: false,
      autoHeight: true,
    },

    {
      headerName: 'Price Per Unit',
      field: 'pricePerUnit',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 140,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Price Per Mg',
      field: 'pricePerMg',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 140,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Regulatory Indication',
      cellRenderer: 'CotGridDropdownComponent',

      field: 'regulatoryIndication',
      width: 250,
      minWidth: 400,
      maxWidth: 500,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Frequency & Duration Unit',
      cellRenderer: 'CotGridDropdownComponent',

      field: 'duration',
      width: 150,
      minWidth: 120,
      maxWidth: 120,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Loading Dose',
      field: 'loadingDose',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,

      width: 100,
      minWidth: 100,
      maxWidth: 140,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Loading Dose Frequency',
      field: 'loadingDoseFrequency',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,

      width: 100,
      minWidth: 120,
      maxWidth: 140,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Loading Dose Duration',
      field: 'loadingDoseDuration',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 140,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Maintainance Dose',
      field: 'maintainanceDose',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,

      width: 100,
      minWidth: 120,
      maxWidth: 140,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Maintainance Dose Freaquency',
      field: 'maintainanceDoseFreaquency',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,

      width: 150,
      minWidth: 150,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Maintainance Dose Duration',
      field: 'maintainanceDoseDuration',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,

      width: 150,
      minWidth: 150,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Average Body Weight',
      field: 'averageBodyWeight',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,

      width: 150,
      minWidth: 150,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
    {
      headerName: 'Average Body Surface Area',
      field: 'averageBodySurfaceArea',
      cellRenderer: 'CotGridInputComponent',
      cellEditor: 'CotGridInputComponent',
      editable: true,
      singleClickEdit: true,
      width: 150,
      minWidth: 150,
      suppressSizeToFit: true,
      headerClass: 'tableHeader',
      sortable: false,
      autoHeight: true,
    },
  ];
  dataSource: cotCalculatorGridInterface[] = [];
  countries: countryBrandInterface[] = [];
  brands: countryBrandInterface[] = [];
  edited: boolean = false;
  public frameworkComponents = {
    CotGridDropdownComponent: CotGridDropdownComponent,
    CotGridInputComponent: CotGridInputComponent,
  };
  public selectedCountries: countryBrandInterface[] = [];
  public selectedBrands: countryBrandInterface[] = [];
  public selectedCotIdList: number[] = [];

  public countryControl = new FormControl('');
  public brandControl = new FormControl('');
  filteredCountryOptions: Observable<countryBrandInterface[]> | undefined;
  filteredBrandOptions: Observable<countryBrandInterface[]> | undefined;

  private unsubscriber$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CotCalculatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: cotDataInterface,
    private cotCalculatorService: CotCalculatorService,
    private titleCasePipe: TitleCasePipe,
    private cotCorridorFetchPipe: CotCorridorFetchPipe,
    public filters: FiltersValueService
  ) {}

  ngOnInit(): void {
    //to get the available cot id list
    // this.cotCalculatorService.castCotIdList.pipe(takeUntil(this.unsubscriber$)).subscribe((res) => {
    //   this.cotIdList = res;
    // });
    //to get the selected countries if any to display in the chiplist
    // this.selectedCountries = JSON.parse(
    //   JSON.stringify(this.data.dataSource.selectedCountries)
    // );
    this.selectedCountries = this.setSelectedCountryFromEditlist();
    //to get the selected brands if any to display in the chiplist
    // this.selectedBrands = JSON.parse(
    //   JSON.stringify(this.data.dataSource.selectedBrands)
    // );
    this.selectedBrands = this.setSelectedBrandFromEditlist();
    // list of countires that are availble to select from dropdown
    this.countries = JSON.parse(
      JSON.stringify(
        this.data.countries
          .filter((x: countryBrandInterface) =>
            this.data.cotIdList.some(
              (y) =>
                y.country === x.name &&
                !this.selectedCountries.some((y) => y.name === x.name)
            )
          )
          .sort((a: countryBrandInterface, b: countryBrandInterface) =>
            a?.name.localeCompare(b.name)
          )
      )
    );
    // list of brands that are availble to select from dropdown
    this.brands = JSON.parse(
      JSON.stringify(
        this.data.brands
          //used for cot id
          .filter((x: countryBrandInterface) =>
            this.data.cotIdList.some(
              (y: cotIdListInterface) =>
                y.brandName === x.name &&
                !this.selectedBrands.some(
                  (y: countryBrandInterface) => y.name === x.name
                )
            )
          )
          .sort((a: countryBrandInterface, b: countryBrandInterface) =>
            a?.name.localeCompare(b.name)
          )
      )
    );
    //to check if the countries or brands that are already selected are availble in the filter or not
    this.check();

    //filter countries to display in the dropdown used for auto-complete search
    this.filterCountries();

    //filter brands to display in the dropdown used for auto-complete search
    this.filterBrands();

    //check if there is already data in the grid or not
    if (this.data.dataSource.data.length != 0) {
      this.dataSource = JSON.parse(JSON.stringify(this.data.dataSource.data));
    } else {
      //in case if the selected brand is removed from the filter
      if (this.selectedBrands.length === 0) {
        this.selectBrands(this.brands[0]);
      }
      //in case if the selected country is removed from the filter
      if (this.selectedCountries.length === 0) {
        this.selected(this.countries[0]);
      }
    }
    // might be of use
    this.cotCalculatorService.castCountryBrandata
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        if (res != null) {
          this.dataSource.filter((x) => x.brandID === res.id)[0] = res;
          let dataSource = this.dataSource.filter((res) => res.edited === true);
          if (dataSource.length != 0) {
            this.edited = true;
          } else {
            this.edited = false;
          }
          this.cotCalculatorService.dataSource.next(this.dataSource);
        }
      });
  }

  public setSelectedCountryFromEditlist(): countryBrandInterface[] {
    let selectedCountries: countryBrandInterface[] = [];
    if (this.data.editedList.length > 0) {
      selectedCountries = [
        ...this.data.dataSource.selectedCountries,
        ...this.data.countries.filter(
          (x: countryBrandInterface) =>
            this.data.editedList.some((y) => y.countryName === x.name) &&
            !this.data.dataSource.selectedCountries.some(
              (y) => y.name === x.name
            )
        ),
      ];
    }
    return selectedCountries;
  }
  public setSelectedBrandFromEditlist(): countryBrandInterface[] {
    let selectedbrands: countryBrandInterface[] = [];
    if (this.data.editedList.length > 0) {
      selectedbrands = [
        ...this.data.dataSource.selectedBrands,
        ...this.data.brands.filter(
          (x: countryBrandInterface) =>
            this.data.editedList.some((y) => y.brandName === x.name) &&
            !this.data.dataSource.selectedBrands.some((y) => y.name === x.name)
        ),
      ];
    }
    return selectedbrands;
  }

  public check(): void {
    this.selectedCountries = this.selectedCountries.filter(
      (option: countryBrandInterface) =>
        this.data.countries.some(
          (y: countryBrandInterface) => y.name === option.name
        )
    );
    //      might be used for cotid
    this.selectedBrands = this.selectedBrands.filter(
      (option: countryBrandInterface) =>
        this.data.cotIdList.some(
          (y: cotIdListInterface) => y.brandName === option.name
        )
    );
    this.data.dataSource.data = JSON.parse(
      JSON.stringify(
        this.data.dataSource.data.filter(
          (option: cotCalculatorGridInterface) =>
            this.selectedCountries.some(
              (y: countryBrandInterface) => y.name === option.countryName
            ) &&
            this.selectedBrands.some(
              (y: countryBrandInterface) => y.name === option.brandName
            )
        )
      )
    );
    this.setSelectedId();
  }

  public filterCountries(): void {
    this.filteredCountryOptions = this.countryControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCountry(value || ''))
    );
  }

  public filterBrands(): void {
    this.filteredBrandOptions = this.brandControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterBrands(value || ''))
    );
  }

  private _filterCountry(value: string): countryBrandInterface[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) &&
        !this.selectedCountries.some((y) => y.name === option.name)
    );
  }

  private _filterBrands(value: string): countryBrandInterface[] {
    const filterValue = value.toLowerCase();
    return this.brands.filter(
      (option: countryBrandInterface) =>
        option.name.toLowerCase().includes(filterValue) &&
        !this.selectedBrands.some((y) => y.name === option.name)
    );
  }

  selected(event: countryBrandInterface): void {
    if (this.countries.some((x) => x.name === event.name)) {
      this.selectedCountries.push(event);
      this.remove(event);
    }
    this.countryControl.setValue(null);
    this.setSelectedId();
  }

  selectBrands(event: countryBrandInterface): void {
    if (this.brands.some((x) => x.name === event.name)) {
      this.selectedBrands.push(event);
      this.removeBrand(event);
    }
    this.brandControl.setValue(null);
    this.setSelectedId();
  }

  remove(country: countryBrandInterface): void {
    const index = this.countries.indexOf(country);
    if (index >= 0) {
      this.countries.splice(index, 1);
    }
  }

  removeBrand(brand: countryBrandInterface): void {
    const index = this.brands.indexOf(brand);
    if (index >= 0) {
      this.brands.splice(index, 1);
    }
    // this.onValueChange.emit();
  }

  removeSeleted(event: countryBrandInterface) {
    this.countries.push(event);
    this.countries.sort((a: countryBrandInterface, b: countryBrandInterface) =>
      a?.name.localeCompare(b.name)
    );
    const index = this.selectedCountries.findIndex(
      (x: countryBrandInterface) => x.name === event.name
    );
    if (index >= 0) {
      this.selectedCountries.splice(index, 1);
      this.dataSource = this.dataSource.filter(
        (x) => x.countryName !== event.name
      );
    }
  }

  removeSeletedBrand(event: countryBrandInterface) {
    this.brands.push(event);
    this.brands.sort((a: countryBrandInterface, b: countryBrandInterface) =>
      a?.name.localeCompare(b.name)
    );
    const index = this.selectedBrands.indexOf(event);
    if (index >= 0) {
      this.selectedBrands.splice(index, 1);
      this.dataSource = this.dataSource.filter(
        (x) => x.brandName !== event.name
      );
    }
  }

  setSelectedId(): void {
    if (
      this.selectedBrands.length !== 0 &&
      this.selectedCountries.length !== 0
    ) {
      //used for cot id

      this.selectedCotIdList = this.data.cotIdList
        .filter(
          (x: cotIdListInterface) =>
            this.selectedBrands.some((y) => y.name === x.brandName) &&
            this.selectedCountries.some((y) => y.name === x.country)
        )
        .map((x) => x.cotId);
      this.getCotDetails();
    }
  }

  getCotDetails(): void {
    let body = {
      costOfTreatmentId: this.selectedCotIdList,
      brandId: this.selectedBrands.map((res) => res.id),
      countryID: this.selectedCountries.map((res) => res.id),
      tliId: JSON.parse(JSON.stringify(this.data.tli.map((res) => res.id))),
      currency: this.data.currency[0].type,
      priceTypeValue: this.data.priceType[0].type,
    };
    this.cotCalculatorService
      .getCotDetails(body)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res: cotApiInterface[]) => {
        this.dataSource = [
          ...this.dataSource,
          ...res
            .filter(
              (data: cotApiInterface) =>
                !this.dataSource.some(
                  (X) =>
                    X.brandID === data.brandID &&
                    X.countryName === data.countryName
                )
            )
            .map((data: cotApiInterface) => {
              let defultPackList = this.setDefaultPack(data.packList)
              let regulatoryIndicationList = this.mapList(
                defultPackList.regulatoryIndicationList
              )
             let defaultIndicaition = this.setDefaultIndication(regulatoryIndicationList)
              return {
                edited: false,
                reEdited: false,
                brandID: data.brandID,
                brandName: data.brandName,
                countryID: data.countryID,
                countryName: data.countryName,
                costOfTreatmentId: data.costOfTreatmentId,
                topLevelIndication: data.topLevelIndication,
                topLevelIndicationId: data.topLevelIndicationId,
                packDescriptionList: this.mapList(data.packList),
                packDescription: {
                  defaultValue: {
                    name: defultPackList.packDescription,
                    id: defultPackList.packID,
                  },
                  currentValue: {
                    name: defultPackList.packDescription,
                    id: defultPackList.packID,
                  },
                },
                packPrice: defultPackList.perPack.toFixed(2),
                regulatoryIndicationList: regulatoryIndicationList,
                regulatoryIndication: {
                  defaultValue: {
                    name: defaultIndicaition
                      .regulatoryIndication,
                    id: defaultIndicaition
                      .regulatoryIndicationID,
                  },
                  currentValue: {
                    name: defaultIndicaition
                      .regulatoryIndication,
                    id: defaultIndicaition
                      .regulatoryIndicationID,
                  },
                },
                pricePerUnit: {
                  defaultValue: defultPackList.perUnit.toFixed(2),
                  initialValue: defultPackList.perUnit.toFixed(2),
                  currentValue: defultPackList.perUnit.toFixed(2),
                },
                pricePerMg: {
                  defaultValue: defultPackList.perMg.toFixed(2),
                  initialValue: defultPackList.perMg.toFixed(2),
                  currentValue: defultPackList.perMg.toFixed(2),
                },
                duration: {
                  defaultValue: {
                    name: this.titleCasePipe.transform(
                      defaultIndicaition.unit
                    ),
                    id: this.titleCasePipe.transform(
                      defaultIndicaition.unit
                    ),
                  },
                  currentValue: {
                    name: this.titleCasePipe.transform(
                      defaultIndicaition.unit
                    ),
                    id: this.titleCasePipe.transform(
                      defaultIndicaition.unit
                    ),
                  },
                },
                loadingDose: {
                  defaultValue:
                    defaultIndicaition
                      .productDosingLoading !== null
                      ? defaultIndicaition.productDosingLoading.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .productDosingLoading !== null
                      ? defaultIndicaition.productDosingLoading.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .productDosingLoading !== null
                      ? defaultIndicaition.productDosingLoading.toFixed(
                          2
                        )
                      : null,
                },
                loadingDoseFrequency: {
                  defaultValue:
                    defaultIndicaition
                      .frequencyLoading !== null
                      ? defaultIndicaition.frequencyLoading.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .frequencyLoading !== null
                      ? defaultIndicaition.frequencyLoading.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .frequencyLoading !== null
                      ? defaultIndicaition.frequencyLoading.toFixed(
                          2
                        )
                      : null,
                },
                loadingDoseDuration: {
                  defaultValue:
                    defaultIndicaition
                      .durationLoading !== null
                      ? defaultIndicaition.durationLoading.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .durationLoading !== null
                      ? defaultIndicaition.durationLoading.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .durationLoading !== null
                      ? defaultIndicaition.durationLoading.toFixed(
                          2
                        )
                      : null,
                },
                maintainanceDose: {
                  defaultValue:
                    defaultIndicaition
                      .productDosingMaintenance !== null
                      ? defaultIndicaition.productDosingMaintenance.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .productDosingMaintenance !== null
                      ? defaultIndicaition.productDosingMaintenance.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .productDosingMaintenance !== null
                      ? defaultIndicaition.productDosingMaintenance.toFixed(
                          2
                        )
                      : null,
                },
                maintainanceDoseFreaquency: {
                  defaultValue:
                    defaultIndicaition
                      .frequencyMaintenance !== null
                      ? defaultIndicaition.frequencyMaintenance.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .frequencyMaintenance !== null
                      ? defaultIndicaition.frequencyMaintenance.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .frequencyMaintenance !== null
                      ? defaultIndicaition.frequencyMaintenance.toFixed(
                          2
                        )
                      : null,
                },
                maintainanceDoseDuration: {
                  defaultValue:
                    defaultIndicaition
                      .durationMaintenance !== null
                      ? defaultIndicaition.durationMaintenance.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .durationMaintenance !== null
                      ? defaultIndicaition.durationMaintenance.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .durationMaintenance !== null
                      ? defaultIndicaition.durationMaintenance.toFixed(
                          2
                        )
                      : null,
                },
                averageBodyWeight: {
                  defaultValue:
                    defaultIndicaition
                      .averageWeight !== null
                      ? defaultIndicaition.averageWeight.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .averageWeight !== null
                      ? defaultIndicaition.averageWeight.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .averageWeight !== null
                      ? defaultIndicaition.averageWeight.toFixed(
                          2
                        )
                      : null,
                },
                averageBodySurfaceArea: {
                  defaultValue:
                    defaultIndicaition
                      .bodySurfaceArea !== null
                      ? defaultIndicaition.bodySurfaceArea.toFixed(
                          2
                        )
                      : null,
                  currentValue:
                    defaultIndicaition
                      .bodySurfaceArea !== null
                      ? defaultIndicaition.bodySurfaceArea.toFixed(
                          2
                        )
                      : null,
                  initialValue:
                    defaultIndicaition
                      .bodySurfaceArea !== null
                      ? defaultIndicaition.bodySurfaceArea.toFixed(
                          2
                        )
                      : null,
                },
              };
            }),
        ];
        this.setEditedData();
      });
  }

  public setDefaultPack(packList:any):any{
    let defaultPack:any;
    packList.forEach((pack:any) => {
      if(pack.regulatoryIndicationList.some((x:any)=> x.costOfTreatmentId > 0)){
        defaultPack = pack
      }
    });
    if(defaultPack === undefined){
      defaultPack = packList[0]
    }
    return defaultPack;
  }

  public setDefaultIndication(regulatoryIndicationList:any):any{
    let regulatoryIndication:any;
    if(regulatoryIndicationList.some((x:any)=> x.costOfTreatmentId > 0)){
      regulatoryIndication = regulatoryIndicationList.filter((x:any)=> x.costOfTreatmentId > 0)[0]
    }else{
      regulatoryIndication = regulatoryIndicationList[0]
    }
    return regulatoryIndication;
  }

  setEditedData(): void {
    const ignoreKeys = [
      'edited',
      'reEdited',
      'brandID',
      'brandName',
      'countryID',
      'countryName',
      'costOfTreatmentId',
      'duration',
      'topLevelIndication',
      'topLevelIndicationId',
      'packDescriptionList',
      'regulatoryIndicationList',
      'packPrice',
      'packDescription',
      'regulatoryIndication',
    ];
    this.dataSource.forEach((brand: any) => {
      if (!brand.reEdited) {
        brand.edited = true;
        let editedBrand: cotApiInterface = this.data.editedList.filter(
          (x) => x.brandID === brand.brandID && x.countryID === brand.countryID
        )[0];
        if (editedBrand !== undefined) {
          brand.packDescription.currentValue = {
            name: editedBrand.packList[0].packDescription,
            id: editedBrand.packList[0].packID,
          };

          brand.regulatoryIndication.currentValue = {
            name: editedBrand.packList[0].regulatoryIndicationList[0]
              .regulatoryIndication,
            id: editedBrand.packList[0].regulatoryIndicationList[0]
              .regulatoryIndicationID,
          };
          (brand.pricePerUnit.currentValue = editedBrand.packList[0].perUnit),
            (brand.pricePerMg.currentValue = editedBrand.packList[0].perMg),
            (brand.duration.currentValue = {
              name: this.titleCasePipe.transform(
                editedBrand.packList[0].regulatoryIndicationList[0].unit
              ),
              id: this.titleCasePipe.transform(
                editedBrand.packList[0].regulatoryIndicationList[0].unit
              ),
            });
          brand.loadingDose.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .productDosingLoading !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0].productDosingLoading.toFixed(
                  2
                )
              : null;

          brand.loadingDoseFrequency.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .frequencyLoading !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0].frequencyLoading.toFixed(
                  2
                )
              : null;
          brand.loadingDoseDuration.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .durationLoading !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0].durationLoading.toFixed(
                  2
                )
              : null;

          brand.maintainanceDose.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .productDosingMaintenance !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0]
                  .productDosingMaintenance
              : null;

          brand.maintainanceDoseFreaquency.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .frequencyMaintenance !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0]
                  .frequencyMaintenance
              : null;

          brand.maintainanceDoseDuration.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .durationMaintenance !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0]
                  .durationMaintenance
              : null;

          brand.averageBodyWeight.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .averageWeight !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0]
                  .averageWeight
              : null;

          brand.averageBodySurfaceArea.currentValue =
            editedBrand.packList[0].regulatoryIndicationList[0]
              .bodySurfaceArea !== null
              ? editedBrand.packList[0].regulatoryIndicationList[0]
                  .bodySurfaceArea
              : null;
        }
      } else {
        if (
          brand.packDescription.currentValue.id !==
          brand.packDescription.defaultValue.id
        ) {
          brand.packDescription.defaultValue = {
            name: '',
            id: '',
          };
        }
        if (
          brand.regulatoryIndication.currentValue.id !==
          brand.regulatoryIndication.defaultValue.id
        ) {
          brand.regulatoryIndication.defaultValue = {
            name: '',
            id: '',
          };
        }
        let keys = Object.keys(brand);
        keys.forEach((element) => {
          if (!ignoreKeys.some((x) => x === element)) {
            if (brand[element].currentValue !== brand[element].defaultValue) {
              brand[element].initialValue = '';
            }
          }
        });
      }
    });
  }

  private mapList(list: any[]): any[] {
    return list.map((res: any, index: number) => {
      res['selected'] = index === 0 ? true : false;
      return res;
    });
  }

  public saveGridApi(event: any): void {}

  public preview(): void {
    let data = {
      preview: true,
      save: false,
      data: this.dataSource,
      selectedCountries: this.selectedCountries,
      selectedBrands: this.selectedBrands,
    };
    this.dialogRef.close(data);
  }

  public saveCot(): void {
    let data = {
      preview: false,
      save: true,
      data: this.dataSource,
      selectedCountries: this.selectedCountries,
      selectedBrands: this.selectedBrands,
    };
    this.dialogRef.close(data);
  }

  public close(): void {
    this.cotCalculatorService.countryBrandata.next(null);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
