import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HorizonState, initialHorizonState } from '../models/state.model';
import { HorizonAction, HorizonActionTypes } from '../actions/horizon.action';

const selectGetHorizonState = createFeatureSelector<HorizonState>('horizon');

export const selectGetLoadProjectList = createSelector(
  selectGetHorizonState,
  (state) => state.loadingProjects
);
export const selectGetProjectList = createSelector(selectGetHorizonState, (state) => state.projects);

export function horizonReducer(
  state: HorizonState = initialHorizonState,
  action: HorizonAction
): HorizonState {
  switch (action.type) {
    case HorizonActionTypes.LoadingProjects: {
      return {
        ...state,
        loadingProjects: action.payload,
      };
    }
    case HorizonActionTypes.LoadProjects: {
      return {
        ...state,
        projects: state.projects,
      };
    }
    case HorizonActionTypes.LoadProjectsSuccess: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case HorizonActionTypes.ResetProjects: {
      return {
        ...state,
        projects: [],
      };
    }
  }
}
