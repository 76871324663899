import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ScopeSummaryApi {

  constructor(private httpClient: HttpClient) {
  }

  transfer(): Observable<string> {
    return this.httpClient.get<string>('/userconfig/getscopesummary');
  }
}
