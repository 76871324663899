import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AccessLevel } from '../../../../horizon-project/models/AccessLevel';
import { Collaborator } from '../../../../horizon-project/models/Collaborator';
import { MasterService } from '../../../../horizon-project/services/master.service';
import { UserService } from '../../../../horizon-project/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';


@Component({
  selector: 'he-collaborator',
  templateUrl: './collaborator.component.html',
  styleUrls: ['./collaborator.component.scss'],
})
export class CollaboratorComponent implements OnInit, OnDestroy {
  allusers: any[] = [];
  accessLevels: AccessLevel[] = [];
  defaultAccessLevel: AccessLevel = { id: '', name: '' };
  public selectedCollaborator: Collaborator[] = [];
  //silsrang merge
  // CollaboratorsControl = new FormControl({});
  CollaboratorsControl = new FormControl('');
  filteredCollaborators: Observable<any[]> = new Observable<any[]>();
  collaboratorValid = false;
  public editProjectId: any = '';


  collaboratorColumns = ['name', 'email', 'accessLevel', 'action'];
  @ViewChild(MatTable) table!: MatTable<any>;
  private unsubscriber$ = new Subject<void>();

  constructor(private userService: UserService,
    private masterService: MasterService,
    private createProjectService: ProjectService,
    private activatedRouter: ActivatedRoute,
    private toastNotificationService: ToastNotificationService
  ) {

  }

  ngOnInit(): void {
    this.createProjectService.projectCollaboratorsDraftDataAvailable.subscribe((data)=>{
      if(data){
        this.getCreateCollaboratorDetails();
        this.table.renderRows();
      }     
    })
    this.userService
      .getAllUsers()
      .then((res) => {
        this.allusers = res;
      }).then(() => {
        this.getCreateCollaboratorDetails();
      })
      .catch((err) => {
        console.log(err);
        this.toastNotificationService.errorMessage(
          'Unable to fetch collaborators data',
          'info',
          10000
        );
      });
    this.filteredCollaborators = this.CollaboratorsControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this._filterUsers(value || '', this.allusers)})
    );

    this.masterService.getAccessLevels().then((res) => {
      this.accessLevels = res;
    })
      .then(() => {
        this.defaultAccessLevel = this.accessLevels.filter(x => x.name === 'Read only')[0];
      })
      .catch((err) => {
        console.log(err);
        this.toastNotificationService.errorMessage(
          'Unable to fetch access level data',
          'info',
          10000
        );
      });

    this.createProjectService.castEditProjectId.pipe(takeUntil(this.unsubscriber$)).subscribe((data: any) => {
      this.editProjectId = data;
    });
    this.getCollaboratorDetails();
  }

  private _filterUsers(value: any, array: any[]): any[] {
    let filtertext = '';
    if (typeof value === 'string') {
      filtertext = value.toLowerCase();
    }
    return array.filter(
      (option: any) =>
        option.email.toLowerCase().includes(filtertext) ||
        option.fullName.toLowerCase().includes(filtertext)
    );
  }

  UpdateCollaborator() {
    const collaborator: any = this.allusers.filter(x=>x.email === this.CollaboratorsControl.value)[0];
    if(collaborator!= null){
      this.CollaboratorsControl.setValue('');
      if (
        this.selectedCollaborator.filter((ele) => ele.id === collaborator.id)
          .length <= 0
      ) {
        this.selectedCollaborator.push({
          ...collaborator,
          accessLevel: this.defaultAccessLevel.id,
        });
      }
      this.checkCollaborator();
      this.UpdateCollaboratorInService();
      this.table.renderRows();
    }

  }

  checkCollaborator() {
    if (this.selectedCollaborator.length > 0) {
      this.collaboratorValid = true;
    } else {
      this.collaboratorValid = false;
    }
  }

  RemoveCollaborator(index: number) {
    this.selectedCollaborator.splice(index, 1);
    this.checkCollaborator();
    this.UpdateCollaboratorInService();
    this.table.renderRows();
  }

  UpdateCollaboratorInService() {
    this.createProjectService.ProjectCollaborators = [];
    this.selectedCollaborator.forEach((collaborator) => {
      this.createProjectService.ProjectCollaborators.push(
        { UserID: collaborator.id, AccessLevelId: collaborator.accessLevel }
      );
    });
  }

  getCollaboratorDetails() {
    if (this.editProjectId) {
      this.masterService
        .getProjectDetails(this.editProjectId).then((data: any) => {
          data.collaborators.forEach((collaborator: any) => {
            const caceessLevel = this.accessLevels.filter(x => x.name === collaborator.accessLevel)[0].id;
            const c: Collaborator =
            {
              id: collaborator.id,
              accessLevel: caceessLevel,
              firstName: collaborator.firstName,
              lastName: collaborator.lastName,
              email: collaborator.email,
              fullName: collaborator.fullName,
              company: '',
            };
            this.selectedCollaborator.push(c);
          });
          this.table.renderRows();
        });
      this.UpdateCollaboratorInService();
    }

  }

  getCreateCollaboratorDetails() {
    if (this.createProjectService.ProjectCollaborators.length > 0) {
      this.createProjectService.ProjectCollaborators.forEach((collaborator: any) => {
        let user = this.allusers.filter(x => x.id === collaborator.UserID)[0];
        if(this.selectedCollaborator.filter((x:any)=>x.id===user.id).length===0 && user)
        this.selectedCollaborator.push({
          ...user,
          accessLevel: collaborator.AccessLevelId,
        });
      });
      this.checkCollaborator();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }


}
