import { Pipe, PipeTransform } from '@angular/core';
import {
  Message,
  CreateProjectService,
  UpdateProjectService,
} from './create.service';
import { EMPTY, Observable } from 'rxjs';

@Pipe({
  name: 'createProjectPipe',
})
export class CreateProjectPipe implements PipeTransform {
  constructor(
    private CreateService: CreateProjectService,
    private UpdateService: UpdateProjectService
  ) {}

  transform(input: any): Observable<Message> {
    if (input.id === null) {
      return !input ? EMPTY : this.CreateService.run({ payload: input });
    } else {
      let content = JSON.parse(input.content);
      input.content = content;
      return !input ? EMPTY : this.UpdateService.run({ payload: input });
    }
  }
}
