import { asyncScheduler, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';
import { Assert } from '../assert';
import { BrandsAvailiabilityOutput } from './interfaces';
import { Gateway } from './gateway';

export class BrandsAvailiability {
  static create(gateway: Gateway): BrandsAvailiability {
    return new BrandsAvailiability(gateway);
  }

  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'call', `Injected gateway ${gateway} has no "call" method`);
  }

  run(event: {payload: unknown}): Observable<BrandsAvailiabilityOutput> {
    return of(event).pipe(
      mergeMap(() => {
        return this.gateway.call(event.payload).pipe(
          map((response) => {
            return {
              type: 'done',
              payload: response
            };
          }),
          catchError((err) => {
            return of({
              type: 'fail',
              error: true,
              payload: err
            });
          }),
          startWith({
            type: 'pending',
            payload: undefined
          }),
          subscribeOn(asyncScheduler)
        );
      })
    );
  }
}
