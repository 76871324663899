import { Component, Input } from '@angular/core';

enum ReimbursementLevel {
  Reimbursed = 'reimbProducts',
  ReimbursedWithRestrictions = 'reimbIncRestrictions',
  NotReimbursed = 'notReimbursed'
}

@Component({
  selector: 'he-reimbursement-cell',
  templateUrl: './reimbursement-cell.component.html',
  styleUrls: ['./reimbursement-cell.component.scss']
})
export class ReimbursementCellComponent {
  @Input() text: any;
  @Input() fieldName: any;
  palette: string[] = ['green', 'yellow', 'red'];
  constructor() { }


  public setColor(level: ReimbursementLevel): string {
    switch (level) {
      case ReimbursementLevel.Reimbursed:
        return this.palette[0];
      case ReimbursementLevel.ReimbursedWithRestrictions:
        return this.palette[1];
      default:
        return this.palette[2];
    }
  }
}
