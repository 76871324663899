import { Pipe, PipeTransform } from '@angular/core';
import { FilterItemValueInterface } from '../interfaces';


@Pipe({
  name: 'FetchOnlyavailableBrands'
})
export class FetchOnlyavailableBrandsPipe implements PipeTransform {

  transform(brands: FilterItemValueInterface[] | null, availability: any): FilterItemValueInterface[] | null {
    if (!brands) {
      return brands;
    }
    return brands.filter(brand => {
      const availabilityItem = availability.find((i: { brandedName: any; }) => i.brandedName === brand.name);
      if (availabilityItem) {
        return availabilityItem.availableIn.length > 0;
      }
      return false
    });
  }
}
