import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridStylesComponent } from './grid-styles.component';

@NgModule({
  declarations: [GridStylesComponent],
  exports: [GridStylesComponent],
  imports: [
    CommonModule
  ]
})
export class GridStylesModule { }
