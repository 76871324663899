import { LoadingOverlayModule } from './../../uikit/loading-overlay/loading-overlay.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
  imports: [
    CommonModule,
    LoadingOverlayModule,
    CdkScrollableModule
  ]
})
export class LayoutModule { }
