export class DataStoreBase {
  private store: any = {};

  public getStore(propertyName: string): any {
    let state = null;
    if (propertyName) {
      if (this.store.hasOwnProperty(propertyName)) {
        state = this.store[propertyName];
      }
    } else {
      state = this.store;
    }
    return state;
  }

  public setStore(propertyName: string, state: any): void {
    this.store[propertyName] = state;
  }

  public clearStore(): void {
    this.store = null;
  }
}
