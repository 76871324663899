import { LoadingSpinnerModule } from 'projects/helios-gui/src/uikit/loading-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingOverlayComponent } from './loading-overlay.component';
import { DialogModule } from '../dialog';



@NgModule({
  declarations: [LoadingOverlayComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    DialogModule,
  ],
  exports: [
    LoadingOverlayComponent
  ]
})
export class LoadingOverlayModule { }
