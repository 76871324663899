import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataRefreshService } from '../../services/data-refresh.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';

@Component({
  selector: 'he-project-cot-refresh-data',
  templateUrl: './project-cot-refresh-data.component.html',
  styleUrls: ['./project-cot-refresh-data.component.scss'],
})
export class ProjectCotRefreshDataComponent implements OnInit, AfterViewInit {
  private unsubscriber$ = new Subject<void>();

  private projectId: string = '';
  public projectName: string = '';
  private projectData: any;

  public isProjectPriceChanged: boolean = false;
  showSection : boolean = false;
  
  dataSource = new MatTableDataSource([{}]);

  public displayedColumns: string[] = [
    'brand',
    'country',
    'tli',
    'inn',
    'isLaunchPriceChange',
    'isReimbursedPriceChange',
    'isCurrentPriceChange',
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<any>;

  constructor(
    public dataRefreshService: DataRefreshService,
    public activatedRouter: ActivatedRoute,
    public router: Router,
    private toastNotificationService: ToastNotificationService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.activatedRouter.params
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((params) => {
        this.projectId = params['projectId'];
        if (this.projectId) {
          this.getProjectPriceUpdate(this.projectId);
        }
      });
  }

  private async getProjectPriceUpdate(projectId: string) {
    this.showSection = false;
    await this.dataRefreshService
      .getProjectPriceUpdate(projectId)
      .then((resp) => {
        if (resp) {
          this.projectData = resp.data.filter(
            (f: any) => f.projectId === projectId
          )[0];

          this.projectName = this.projectData.projectName;
          this.isProjectPriceChanged = this.projectData.isPriceChanged;
          this.showSection = true;
          this.dataSource = new MatTableDataSource(this.projectData.brands);
          this.dataSource.paginator = this.paginator;
          
        }
      })
      .catch((err) => {
        this.showSection = true;
        if (!Array.isArray(err.error.data)) {
          this.toastNotificationService.errorMessage(err.error.data);
        }
      });
  }
  async updatePrice() {
    if (this.projectId) {
      this.showSection = false;
      await this.dataRefreshService
        .updateProjectPrice(this.projectId)
        .then((data) => {
          this.showSection = true;
          this.toastNotificationService.successMessage(
            'Project prices updated successfully'
          );
          this.getProjectPriceUpdate(this.projectId);
        })
        .catch((err) => {
          this.showSection = true;
          this.toastNotificationService.errorMessage(
            'Error in updating project prices'
          );
        })
        .then(() => {
          this.router.navigate([
            `horizon/project-cot-refresh/${this.projectId}`,
          ]);
        });
    }
  }
  cancelTask() {
    this.router.navigate(['horizon/refresh-project-data']);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
