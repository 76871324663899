import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../services/header.service';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { MasterService } from '../../../horizon-project/services/master.service';
import { StringPropertyComparison } from '../../../horizon-project/utility-functions/SortComparison';
import { Subject, takeUntil } from 'rxjs';
import { ProjectService } from '../../../modules/project/services/project.service';
import { CurrencyConversionService } from '../../../horizon-project/services/currency-conversion.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
import { frameworkEnums } from 'projects/helios-gui/src/app/common';

interface Dropdown {
  value: string;
  viewValue: string;
}

interface ScenarioDropdown {
  value: string;
  viewValue: string;
  sequence?: number;
}

interface CurrencyDropdown {
  value: string;
  viewValue: string;
  currency: string;
  display?: boolean;
}

@Component({
  selector: 'he-app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StickyHeaderComponent implements OnInit, OnDestroy {
  public projectId = '';
  public project = 'Some Project';
  public tli = '';
  public modifiedOn = new Date();

  selectedValue = '';
  selectedCountry = 'Germany';
  selectedCurrency = '$ USD';

  scenarios: ScenarioDropdown[] = [];
  countries: Dropdown[] = [];

  currencies: CurrencyDropdown[] = [
    { value: '€ EUR', viewValue: 'Euro', currency: 'EUR', display: true },
    { value: '$ USD', viewValue: 'US Dollar', currency: 'USD', display: true },
    {
      value: 'Local',
      viewValue: 'Local Currency',
      currency: 'Local',
      display: true,
    },
  ];

  isEditProject: boolean = true;
  private unsubscriber$ = new Subject<void>();
  public scenarioVisible=false;
  public countryVisible=false;
  constructor(
    private activatedRouter: ActivatedRoute,
    private headerService: HeaderService,
    private countryFlagService: CountryFlagService,
    private masterService: MasterService,
    private projectService: ProjectService,
    private currencyConversionService: CurrencyConversionService,
    private sharedService:SharedService
  ) {}

  ngOnInit(): void {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.projectId = p.project;

        if (this.projectId) {
          this.masterService
            .getProjectDetails(this.projectId)
            .then((data) => {
              this.modifiedOn = data.modifiedOn;
              this.project = data.name;
              this.headerService.assetName.next(
                data.assetTLIAndCountrys[0].assetName
              );
              this.headerService.IsOncologyFrameworkSelected = data.valueFramework== frameworkEnums.oncology ? true :false ;
              // TODO: in MVP we are looking at only 1st item in assetTLIAndCountrys collection
              const { assetTLIAndCountrys } = data;
              if (
                Array.isArray(assetTLIAndCountrys) &&
                assetTLIAndCountrys.length > 0
              ) {
                this.tli = assetTLIAndCountrys[0].topLevelIndication;
              } else {
                this.tli = '';
              }

              const { marketAreas } = data;

              if (Array.isArray(marketAreas)) {
                this.countries = [];
                marketAreas.forEach((country) => {
                  this.countries.push({
                    value: country.countryName,
                    viewValue: country.countryName,
                  });
                });
                this.countries.sort((a, b) => a.value.localeCompare(b.value));
                this.headerService.setCountries(
                  this.countries.map((m) => m.value)
                );
                if (this.countries.length > 0) {
                  const selectedCountryIndex = this.countries.findIndex(
                    (f) => f.value === this.selectedCountry
                  );
                  if (selectedCountryIndex > -1) {
                    this.headerService.setSelectedCountry(
                      this.countries[selectedCountryIndex].value
                    );
                  } else {
                    this.headerService.setSelectedCountry(
                      this.countries[0].value
                    );
                  }

                  this.selectedCountry =
                    this.headerService.getSelectedCountry();
                }
              }

              const { projectScenarios } = data;
              if (Array.isArray(projectScenarios)) {
                this.scenarios = [];
                projectScenarios.forEach((scenario: any) => {
                  this.scenarios.push({
                    value: scenario.scenarioName,
                    viewValue: scenario.scenarioName,
                    sequence: scenario.sequence,
                  });
                });
                this.scenarios.sort((a: any, b: any) => {
                  return a.sequence - b.sequence;
                });
                this.headerService.scenarioList.next(this.scenarios);
                this.headerService.setScenario(this.scenarios[0].value);
                this.selectedValue = this.headerService.getScenario();
              }
            })
            .catch((err) => console.log(err))
            .finally(() => {
              const userProjectPermissions =
                this.headerService.getUserProjectPermission();

              if (userProjectPermissions) {
                const currentProjectPermission = userProjectPermissions.find(
                  (f) => f.projectId === this.projectId
                );

                if (currentProjectPermission) {
                  this.isEditProject =
                    currentProjectPermission.permissions.project.edit;
                }
              }
            });
        }
      });
      this.headerService.castenableLocalCurrency.subscribe(res=>{
        this.currencies.filter(res=>res.value === "Local")[0].display = res;
        if(!res && this.selectedCurrency === "Local"){
          this.onMenuSelect(this.currencies[1])
        }
      })
      this.onMenuSelect(this.currencies.filter(res=>res.value ===  this.selectedCurrency)[0])

      this.headerService.onTabCliked
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((tabName) => {
          switch (tabName) {
            case 'Project Details':
              this.scenarioVisible=false;
              this.countryVisible=false;
              break;
              case 'Asset TPP':
                this.scenarioVisible=true;
                this.countryVisible=false;
              break;
              case 'Analogues':
                this.scenarioVisible=false;
                this.countryVisible=false;
              break;
              case 'Value':
                this.scenarioVisible=true;
                this.countryVisible=true;
              break;
              case 'Forecast':
                this.scenarioVisible=true;
                this.countryVisible=true;
              break;
              case 'Summary':
                this.scenarioVisible=false;
                this.countryVisible=false;
              break;
            default:
              this.scenarioVisible=false;
              this.countryVisible=false;
              break;
          }
      });
  }
  onScenerioChange(event: any) {
    this.headerService.setScenario(event.value);
  }
  onCountryChange(event: any) {
    this.sharedService.isTotalValuescoreByCountriesCall$.next(true);
    this.headerService.setSelectedCountry(event.value);
  }
  onMenuSelect(event: any) {
    this.selectedCurrency = event.value;
    this.currencyConversionService.SetSelectedCurrency(event.currency);
  }
  onMenuSelectCurrency(event: any) {
    this.sharedService.isTotalValuescoreByCountriesCall$.next(true);
    this.selectedCurrency = event.value;
    this.currencyConversionService.SetSelectedCurrency(event.currency);
  }
  getFlagId(country: string) {
    return this.countryFlagService.getFlagId(country);
  }
  setProjectId(projectId: string) {
    this.projectService.editProjectId.next(projectId);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
