import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'he-update-framework',
  templateUrl: './update-framework.component.html',
  styleUrls: ['./update-framework.component.scss']
})
export class UpdateFrameworkComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateFrameworkComponent>,
  ) {}

  onNoClick(): void {
  }

  public edit(edit:boolean):void{
    this.dialogRef.close(edit);

  }
}

