import { Component } from '@angular/core';

@Component({
  selector: 'he-comparator-selection-main',
  templateUrl: './comparator-selection-main.component.html',
  styleUrls: ['./comparator-selection-main.component.scss']
})
export class ComparatorSelectionMainComponent {
  public sideBarOpen:boolean = true;
  public firstStep:string = "Choose a Top level indication and filter based on any additional criteria as preferred"
  constructor() { }


  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
