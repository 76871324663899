import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuPanelComponent } from './menu-panel.component';

@NgModule({
  declarations: [MenuPanelComponent],
  exports: [MenuPanelComponent],
  imports: [
    CommonModule
  ]
})
export class MenuPanelModule {
}
