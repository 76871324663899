import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FilterItemValueInterface } from '../../app/datastore/interfaces';

@Component({
  selector: 'he-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnChanges {
  @Input() label: string | undefined;
  @Input() selectedItem: FilterItemValueInterface | undefined;
  @Input() defaultText = 'No options';
  @Input() items: FilterItemValueInterface[] = [];
  @Input() disabled = false;
  @Input() isEditable = false;
  @Output() itemSelect: EventEmitter<FilterItemValueInterface> = new EventEmitter();
  @Output() isOpen: EventEmitter<boolean> = new EventEmitter();
  isOpened = false;
  brandnameTodisplay: string = "";
  constructor() {
  }
  tempItems: FilterItemValueInterface[] = [];


  ngOnChanges(changes: SimpleChanges) {
    this.initItems(changes);
  }

  initItems(changes: SimpleChanges) {
    if (!changes.items) {
      return;
    }

    if (this.tempItems != changes.items.currentValue) {
      this.tempItems = changes.items.currentValue;
    }
    if (!this.selectedItem && this.items.length > 0) {
      this.selectedItem = this.items[0];
    }
    if (this.items.length > 0)
      this.brandnameTodisplay = this.selectedItem?.name;
  }


  openSelect(): void {
    this.isOpened = true;
    this.isOpen.emit(this.isOpened);
  }

  closeSelect(): void {
    this.isOpened = false;
    this.isOpen.emit(this.isOpened);
  }

  selectItem(item: FilterItemValueInterface): void {
    if (this.selectedItem && this.selectedItem.name !== item.name) {
      this.itemSelect.emit(item);
    }
    this.selectedItem = item;
    this.isOpened = false;
    this.brandnameTodisplay = item.name;
  }

  brandnameResult: FilterItemValueInterface[] = [];
  brandnameSearch: string = "";
  onSearchChange(event: any) {
    this.brandnameSearch = event.target.value;
    this.brandnameResult.splice(0);
    this.items.forEach((value) => {
      if (value.name.toLowerCase().includes(this.brandnameSearch.toLowerCase()))
        this.brandnameResult.push(value);
    });
    this.tempItems = this.brandnameResult;
    this.isOpened = true;
  }
}
