<he-app-header (toggleSideBarForMe)="sideBarToggler()"></he-app-header>

<mat-drawer-container [ngClass]="sideBarOpen ? 'open' : 'close-he'">
    <mat-drawer mode="side" [opened]="true">
        <he-project-sidebar (toggleSideBarForMe)="sideBarToggler()" [sideBarOpen]="sideBarOpen" ></he-project-sidebar>
    </mat-drawer>   
    <mat-drawer-content>   
        <he-project-topbar [title]="'Analogue Selection'" [helpVisible]='true' [routeType]="'analogues'" [firstStep]="firstStep"></he-project-topbar>
        <he-analogue-selection-criteria></he-analogue-selection-criteria>
    </mat-drawer-content>
</mat-drawer-container>
