import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

const ASYNCMODE = true;

@Component({
  selector: 'he-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss']
})
export class CommandComponent implements OnDestroy, OnInit {
  @Output() asyncFire = new EventEmitter<undefined>(ASYNCMODE);
  @Output() fire = new EventEmitter<undefined>();
  @Output() stop = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
    this.fire.emit();
    this.asyncFire.emit();
  }

  ngOnDestroy(): void {
    this.stop.emit();
  }

}
