<ng-container *ngIf="isSaveButtonVisible">
  <button mat-icon-button class="he-icon-button he-button-m-size" title="Save selection as default" (click)="saveAsDefault(selectedItems)">
    <he-icon [id]="'editor-action-save'"></he-icon>
  </button>
</ng-container>
<ng-container *ngIf="isResetButtonVisible">
  <button mat-icon-button class="he-icon-button he-button-m-size" title="Clear selection" (click)="resetFilterClicked()">
    <he-icon [id]="'delete-2'"></he-icon>
  </button>
</ng-container>
<he-filter-autocomplete-with-chips
  [descriptions]="descriptions"
  [selectedItems]="selectedItems"
  [loading]="loading"
  [isGroupView]="false"
  [showErrorIfEmpty]="false"
  [filteredItems$]="filteredItems$"
  [class.scrollable]="filtersValueService.maxCountriesExceeded$ | async"
  (callFilterFunction)="onQueryChange($event)"
  (selectedItemChanges)="selectedItemChanges($event)"
  (deletedItemChanges)="deletedItemChanges($event)"
></he-filter-autocomplete-with-chips>

<he-country-filter-reset-dialog *ngIf="pendingConfirmationToClear" (reject)="onRejectClear()" (confirm)="onConfirmClear()"></he-country-filter-reset-dialog>
