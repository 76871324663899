import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'he-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoButtonComponent {
  @Input() infoButtonText!: string;
}
