import { Component } from '@angular/core';

@Component({
  selector: 'he-upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss']
})
export class UploadDataComponent {
  sideBarOpen = true;

  constructor() { }



  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
