<div class="card-he">
  <mat-card class="mat-sub-card">
    <div class="heading">
      Project Files
      <span class="sub-title"> ({{ projectFileNames.length }} found) </span>
    </div>
    <mat-card-content *ngIf="!uploadStatus.uploading">
      <ul>
        <div *ngFor="let file of projectFileNames">
          <li class="box">
            <div class="files-document-logo">
              <img src="/assets/Files-Purple.png" alt="Document logo" />
            </div>
            <div class="file-name">
              {{ file.name }}
              <!-- <span class="file-desc">Word Document. 1 MB</span> -->
            </div>
            <a class="border-style download-icon"
              ><img
                src="/assets/dwonload-purple.png"
                alt="Download File logo"
                (click)="download($event, file.name)"
                href="#"
            /></a>
            <a
              target="_blank"
              class="open-spacing border-style"
              type="button"
              (click)="open(file.name)"
              >Open</a
            >
          </li>
        </div>
      </ul>
    </mat-card-content>
    <ng-container *ngIf="loading || uploadStatus.uploading ">
      <he-loading-spinner
        [diameter]="70"
        [height]="'350px'"
      ></he-loading-spinner>
      <p class="uplaod-status" *ngIf="uploadStatus.uploading">Uploading files Please wait</p>
    </ng-container>
  </mat-card>
</div>
