import { LoadingSpinnerModule } from 'projects/helios-gui/src/uikit/loading-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackDetailsGridComponent } from './pack-details-grid.component';
import { GridCommonModule } from '../../../../uikit/grid-common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomTooltipRendererComponent } from './custom-tooltip-renderer/custom-tooltip-renderer.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [PackDetailsGridComponent, CustomTooltipRendererComponent],
  imports: [
    CommonModule,
    GridCommonModule,
    LoadingSpinnerModule,
    MatTooltipModule,
    AgGridModule.withComponents([
      CustomTooltipRendererComponent
    ]),
  ],
  exports: [PackDetailsGridComponent],
})
export class PackDetailsGridModule {}
