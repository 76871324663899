import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../../interfaces';
import { FiltersValueService } from '../../filters-value.service';
import { reimbursementItems } from '../../../project-details/analog-filters/constants';

@Component({
  selector: 'he-filter-product-params-reimbursement',
  templateUrl: './filter-product-params-reimbursement.component.html',
  styleUrls: ['./filter-product-params-reimbursement.component.scss']
})
export class FilterProductParamsReimbursementComponent {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  items: FilterItemValueInterface[] = reimbursementItems;
  title = 'Reimbursement status';

  constructor(private filtersValueService: FiltersValueService) {
  }

  get selectedItem(): FilterItemValueInterface {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.reimbursementStatus).filterValue[0] ||
      this.items[0];
  }

  itemSelectEvent(item: FilterItemValueInterface): void {
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.reimbursementStatus, [item]));
  }
}
