import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'he-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionPanelComponent {
}
