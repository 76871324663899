import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function maxLengthValidator(charLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    return value.trim().length > charLength ? { isMaxLengthError: true } : null;
  };
}
