import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccessPagesEnum } from 'projects/helios-gui/src/app/common/enums';
import { CachedAccessPermissions, PermissionsResponse } from '../../../core/access-permissions';

@Component({
  selector: 'he-export-csv-button',
  templateUrl: './export-csv-button.component.html',
  styleUrls: ['./export-csv-button.component.scss']
})
export class ExportCsvButtonComponent implements OnInit {
  @Output() export = new EventEmitter();
  @Input() text:string='Export as .csv'
  permission = false;
  private permissionList: PermissionsResponse[] = [];

  constructor(
    private cachedAccessPermissions: CachedAccessPermissions
  ) {}

  ngOnInit(): void {
    this.checkDownloadPermission();
  }

  async checkDownloadPermission(): Promise<void> {
    const accessibleRoute = await this.cachedAccessPermissions.run({payload: null}).toPromise();
    this.permissionList = accessibleRoute?.payload as [];

    const downloads = this.permissionList.find(item => item.code === AccessPagesEnum.downloads);
    if (downloads){
      this.permission = true;
    }
  }

  onExport(): void {
    this.export.emit();
  }
}
