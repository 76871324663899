import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[heClickOutside]'
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {
  }

  @Output() clickOutside = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
    onClick(targetElement: HTMLElement): void {
      if (!this.elementRef.nativeElement.contains(targetElement)) {
        this.clickOutside.emit();
      }
  }
}
