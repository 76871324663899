import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, startWith, map, take } from 'rxjs';
import {
  CriteriaInterface,
  multiInputListInterface,
  multiValueList,
} from '../../models/criteria.interface';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { MatOption } from '@angular/material/core';
import { ProjectService } from '../../services/project.service';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { values } from 'lodash';
@Component({
  selector: 'he-criteria-input',
  templateUrl: './criteria-input.component.html',
  styleUrls: ['./criteria-input.component.scss'],
})
export class CriteriaInputComponent implements OnInit {
  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;

  @Input()
  public inputdata!: CriteriaInterface;
  @Output()
  public onValueChange = new EventEmitter<any>();
  public toppings = new FormControl('');
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public fruitCtrl = new FormControl('');
  public multifruitCtrl = new FormControl('');
  public myControl = new FormControl('');
  public filteredList: Observable<string[]>;
  public multifilteredList: Observable<multiValueList[]>;
  public fruits: string[] = [];
  public allFruits: string[] = [];
  public defaultThearpyList: any[] = [];
  public defaultThearpyList2: any[] = [];
  public multipleSelectedValue: string[] = [];
  public allSelected: boolean = false;
  public toggleFlag: boolean = false;
  public ValueFrameworkName:string = "";
  public multiSearch:string = "";
  public dateInfoData:string='';
  public minSelectedRange = 0;
  public minRange= 0;
  public maxSelectedRange= 50;
  public maxRange= 50;
  public valueRangSelectedForm = new FormGroup({
    minSelectedRange: new FormControl(''),
    maxSelectedRange: new FormControl(''),
  });

  public selectToggle(value: string): void {
    this.inputdata.selectedValue = value;
    this.onValueChange.emit();
  }

  constructor(private criteriaSelectionService: CriteriaSelectionService,private projectService: ProjectService, private toastNotificationService: ToastNotificationService) {
    this.filteredList = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit
          ? this._filter(fruit)
          : (this.inputdata.valueList as string[]).slice()
      )
    );    
    this.multifilteredList = this.multifruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit
          ? this._multifilter(fruit)
          : (this.inputdata.multiValueList as multiValueList[]).slice()
      )
    );
  }

  ngOnInit(): void {
    this.ValueFrameworkName = this.projectService.createProjectFormData.value.framework.valueFrameworkName;
    if (this.inputdata.inputType == '4' || this.inputdata.inputType == '7') {
      this.criteriaSelectionService.castTherapyAreaList
        .pipe(take(1))
        .subscribe((res) => {
          this.defaultThearpyList = res;
          this.defaultThearpyList2 = res;
        });
    }
    if(this.inputdata.title == 'Prior reimbursed indications' && this.ValueFrameworkName == 'Oncology'){
      this.getMinMax(this.inputdata.valueList);
    }
  }

  selectMultipleToggle(value: any): void {
    let isExistFlag = this.inputdata?.selectedValueList?.includes(value?.source?.value,0);
    if(isExistFlag == true){
      this.inputdata.selectedValueList?.splice(this.inputdata.selectedValueList?.indexOf(value?.source?.value),((this.inputdata.selectedValueList?.indexOf(value?.source?.value))+1));
    }
    else{
      if(!(this.inputdata.selectedValueList?.length.toString() == undefined)){
        if(this.inputdata?.selectedValueList?.length <= 1){
          this.inputdata.selectedValueList?.push(value?.source?.value);
        }
        else{
          value.source.checked = false;
          this.toastNotificationService.errorMessage('User can select up to 2 options');
        }
      }
    }
    
    this.onValueChange.emit();
  }
  
  getMinMax(arr:any){
    var minValue = 0;
    var maxValue = 0;
    var initialValue = 0;
    for(var i=0;i<arr.length;i++){
      if(arr[i] < initialValue){
        minValue = arr[i];
      }
      if(arr[i] > initialValue){
        maxValue = arr[i];
      }
    }
    this.maxSelectedRange = maxValue;
    this.minSelectedRange = minValue;
    this.maxRange=maxValue;
  }

  toggleAllSelection(index:number) {
    if (!(this.inputdata.multiInputList as multiInputListInterface[])[index].selectAll) {
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectAll= true;
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectedIndicationValue = (this.inputdata.multiInputList as multiInputListInterface[])[index].indicationValueList.map((x:any)=> x.name)
    // //   this.searchUserForm.controls.userType
    // //     .patchValue([...this.userTypeFilters.map(item => item.key), 0]);
    } else {
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectAll = false;
      (this.inputdata.multiInputList as multiInputListInterface[])[index].selectedIndicationValue = [];
    }
  }

  toggleValueAllSelection() {
    if(this.inputdata.selectedValueList?.length==this.inputdata.valueList?.length){
      this.inputdata.selectedValueList = [];
    }
    else{
      if(this.inputdata.valueList != undefined){
      this.inputdata.selectedValueList=this.inputdata.valueList;
      }
    }
    this.onValueChange.emit();
  }

  removeAll() {
      this.inputdata.selectedValueList = [];
      (this.inputdata.multiValueList as multiValueList[]).forEach(element => {
        element.selected = false;
      });
    this.onValueChange.emit();
  }

  toggleValuesWithSearchAllSelectionMulti() {
      (this.inputdata.multiValueList as multiValueList[]).filter(x=> x.name.toLowerCase().includes(this.multiSearch.toLowerCase() as string)).forEach(x=> {
       if(!this.inputdata.selectedValueList.some(y=> y.toLowerCase() === x.name.toLowerCase())){
        this.inputdata.selectedValueList.push(x.name)
       }
      });
      (this.inputdata.multiValueList as multiValueList[]).filter(x=> x.name.toLowerCase().includes(this.multiSearch.toLowerCase() as string)).forEach(x=>{
        x.selected = true;
      });
      this.multiSearch = '';
      this.multifruitCtrl.setValue('')
      this.fruitInput.nativeElement.value = '';
    this.onValueChange.emit();
  }

  toggleValuesWithSearchAllSelection() {
    if(this.inputdata.selectedValueList?.values==this.inputdata.valueList?.values){
      this.inputdata.selectedValueList=this.inputdata.valueList;
    }
    else{
      this.inputdata.selectedValueList=[];
    }
    this.onValueChange.emit();
  }


  remove(fruit: string): void {
    const index = (this.inputdata.selectedValueList as string[]).indexOf(fruit);
    if (index >= 0) {
      (this.inputdata.selectedValueList as string[]).splice(index, 1);
    }
    this.onValueChange.emit();
  }

  removeMulti(fruit: string): void {
    const index = (this.inputdata.selectedValueList as string[]).indexOf(fruit);
    if (index >= 0) {
      (this.inputdata.selectedValueList as string[]).splice(index, 1);
      ((this.inputdata.multiValueList as multiValueList[]).find(x=>x.name === fruit)as multiValueList).selected = false;
      this.multifilteredList.subscribe(res=>{
        (res.find(x=>x.name === fruit)as multiValueList).selected = false;
      })
    }
    this.onValueChange.emit();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (
      (this.inputdata.selectedValueList as string[]).some(
        (x) => x === event.option.viewValue
      )
    ) {
      this.remove(event.option.viewValue);
    } else {
      (this.inputdata.selectedValueList as string[]).push(
        event.option.viewValue
      );
    }
    

    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.onValueChange.emit();
  }

  multiselected(event: any,name:string): void {
    if (
      (this.inputdata.selectedValueList as string[]).some(
        (x) => x === name
      )
    ) {
      this.removeMulti(name);
    } else {
      ((this.inputdata.multiValueList as multiValueList[]).find(x=>x.name === name) as multiValueList).selected= true;
      (this.inputdata.selectedValueList as string[]).push(
        name
      );
      this.multifilteredList.subscribe(res=>{
        (res.find(x=>x.name === name)as multiValueList).selected = true;
      })
    }
    

    // this.fruitInput.nativeElement.value = '';
    // this.multifruitCtrl.setValue(null);
    this.onValueChange.emit();
  }

  selectedMultipleValue(event: MatAutocompleteSelectedEvent): void {
    if (
      (this.inputdata.selectedValueList as string[]).some(
        (x) => x === event.option.viewValue
      )
    ) {
      this.remove(event.option.viewValue);
    } else {
      (this.inputdata.selectedValueList as string[]).push(
        event.option.viewValue
      );
    }

    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.onValueChange.emit();
  }
  private _multifilter(value: string): multiValueList[] {
    const filterValue = value.toLowerCase();
    this.multiSearch = value;
    return (this.inputdata.multiValueList as multiValueList[]).filter((fruit) =>
      fruit.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return (this.inputdata.valueList as string[]).filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  public selectMultiValue(index: number, value: string) {
    (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].selectedTherapyValue = value;
    let valueListIndex = (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].therapyValueList.findIndex((res) => res.name === value);
    (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].indicationValueList = (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].therapyValueList[valueListIndex].valueList;
    this.removeFromList();
    this.onValueChange.emit();
  }

  public removeFromList(): void {
    this.inputdata.multiInputList?.forEach((therapyArea1, newIndex1) => {
      this.defaultThearpyList2 = JSON.parse(
        JSON.stringify(this.defaultThearpyList)
      );
      this.inputdata.multiInputList?.forEach((therapyAreaNew, newIndex2) => {
        if (newIndex1 !== newIndex2) {
          if (therapyAreaNew.selectedTherapyValue !== '') {
            let newRemoveIndex = this.defaultThearpyList2.findIndex(
              (res) => res.name === therapyAreaNew.selectedTherapyValue
            );
            this.defaultThearpyList2.splice(newRemoveIndex, 1);
          }
        }
      });
      (this.inputdata.multiInputList as multiInputListInterface[])[
        newIndex1
      ].therapyValueList = JSON.parse(JSON.stringify(this.defaultThearpyList2));
    });
  }

  public selectIndicationMultiValue(index: number, value: string[]) {
    (this.inputdata.multiInputList as multiInputListInterface[])[
      index
    ].selectedIndicationValue = value;
    this.onValueChange.emit();
  }

  public selectionOfMultiValue(value: string[]) {
    this.inputdata.selectedValueList = value.filter(x=> x!= '0');;
    this.onValueChange.emit();
  }

  public addNew(): void {
    this.inputdata.multiInputList?.push({
      therapyValueList: this.inputdata.multiInputList[0].therapyValueList,
      selectedTherapyValue: '',
      indicationValueList: [],
      selectedIndicationValue: [],
      selectAll: false,
    });
    this.removeFromList();
  }

  public removeInput(index:number):void{
    this.inputdata.multiInputList?.splice(index,1)
    this.removeFromList();
  }

  public valueChanged(): void {
    let savedCriteria = {
      minSelectedRange: this.minSelectedRange,
      maxSelectedRange: this.maxSelectedRange,
    };
    this.inputdata.minValue = this.minSelectedRange.toString();
    this.inputdata.maxValue = this.maxSelectedRange.toString();
    this.onValueChange.emit();
  }
}
