import { Pipe, PipeTransform } from '@angular/core';

interface Suggestion {
  id: unknown;
  type: string;
  name: string;
}

interface SuggestionGroup {
  type: string;
  children: Suggestion[];
}

@Pipe({
  name: 'groupByType'
})
export class GroupByTypePipe implements PipeTransform {

  transform(suggestions: Suggestion[]): SuggestionGroup[] {
    const cache: { [key: string]: SuggestionGroup } = {};

    suggestions.forEach((suggestion) => {
      const g = cache[suggestion.type] || {
        type: suggestion.type,
        children: []
      };

      g.children.push(suggestion);

      cache[suggestion.type] = g;
    });

    return Object.values(cache);
  }
}
