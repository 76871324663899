export interface ValidationError {
  children?: ValidationError[];
  message: string;
  property: string;
  value?: string;
}

export abstract class Scheme {
  abstract validate(input: unknown): ValidationError[];
}
