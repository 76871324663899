import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ReimbursementHtaApi {
  constructor(private httpClient: HttpClient) {
  }

  transfer(query: any): Observable<any> {
    return this.httpClient.post<any>('/reimbursement/details', query);
  }
}
