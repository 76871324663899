export enum ProjectTypes {
  Unknown,
  OpportunityAssessment,
  MarketAccessStrategy,
  Insights,
  MaxValue,
}

export const ProjectTypeLabels = {
  [ProjectTypes[ProjectTypes.Unknown]]: 'Unknown',
  [ProjectTypes[ProjectTypes.OpportunityAssessment]]: 'Opportunity Assessment',
  [ProjectTypes[ProjectTypes.MarketAccessStrategy]]: 'Market Access Strategy',
  [ProjectTypes[ProjectTypes.Insights]]: 'Cost of treatment',
  [ProjectTypes[ProjectTypes.MaxValue]]: 'Max Value',
};

export interface ProjectRef {
  id?: string | number;
}

export interface ProjectSummary {
  description: string;
  name: string;
}

export interface ProjectInfo extends ProjectSummary,ProjectRef {
  content?: ProjectContent;
  type: ProjectTypes;
}

export interface Project extends ProjectRef, ProjectInfo {
}

export interface ProjectContent {
  analogFilters?: any;
  analogs?: any[];
  analysisFilters?: any;
  measuresFilters?: any;
  filters?: any;
}
