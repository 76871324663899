import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/helios-gui/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalogueDeleteDataService {

  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  constructor(private http:HttpClient) { }

 
  DeleteAnalogueInfo(fileData:any,country:string, frameworkName:string){
    const formData = new FormData()
    formData.append('file',fileData)
    return new Promise((resolve,reject)=>{
      this.http.delete(`${this.projectAPIUri}/AnalogueTool/DeleteAnalogueInfo?country=${country}&frameworkName=${frameworkName}`,{body: formData}).subscribe(
       {
        next:(res)=>{ resolve(res); },
        error:(error)=>{ reject(error);}
       }
      )
    });
  }

  DeleteCotData(fileData:any,country:string, frameworkName:string ){
    const formData = new FormData();
    formData.append('file',fileData);
    return new Promise((resolve,reject)=>{
      this.http.delete(`${this.projectAPIUri}/AnalogueTool/DeleteAnalogueCOT?country=${country}&frameworkName=${frameworkName}`,{body: formData}).subscribe(
       {
        next:(res)=>{ resolve(res); },
        error:(error)=>{ reject(error);}
       }
      )
    });
  }

  DeleteAnalogueValueScore(fileData:any,country:string, frameworkName:string ){
    const formData = new FormData();
    formData.append('file',fileData);
    return new Promise((resolve,reject)=>{
      this.http.delete(`${this.projectAPIUri}/AnalogueTool/DeleteAnalogueValueScore?country=${country}&frameworkName=${frameworkName}`,{body: formData}).subscribe(
       {
        next:(res)=>{ resolve(res); },
        error:(error)=>{ reject(error);}
       }
      )
    });
  }

  DeleteAnalogueHtaRefrence(fileData:any,country:string, frameworkName:string ){
    const formData = new FormData();
    formData.append('file',fileData);
    return new Promise((resolve,reject)=>{
      this.http.delete(`${this.projectAPIUri}/AnalogueTool/DeleteAnalogueHTAInfo?country=${country}&frameworkName=${frameworkName}`,{body: formData}).subscribe(
       {
        next:(res)=>{ resolve(res); },
        error:(error)=>{ reject(error);}
       }
      )
    });
  }

  DeleteAnalogueTaggingData(fileData:any,country:string, frameworkName:string){
    const formData = new FormData();
    formData.append('file',fileData);
    return new Promise((resolve,reject)=>{
      this.http.delete(`${this.projectAPIUri}/AnalogueTool/DeleteAnalogueTaggingData?country=${country}&frameworkName=${frameworkName}`,{body: formData}).subscribe(
       {
        next:(res)=>{ resolve(res); },
        error:(error)=>{ reject(error);}
       }
      )
    });
  } 

}
