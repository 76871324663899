import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScopeSummaryApi } from 'projects/helios-gui/src/helios-api/scope-summary/scope-summary';
import { ScopeSummary } from 'projects/helios-gui/src/core/scope-summary/scope-summary';
import { Observable, Subject } from 'rxjs';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';


@Component({
  selector: 'he-scope-summary',
  templateUrl: './scope-summary.component.html',
  styleUrls: ['./scope-summary.component.scss'],
  providers: [ScopeSummaryApi,
    { provide: ScopeSummary, useFactory: ScopeSummary.create, deps: [ScopeSummaryApi] },]
})

export class ScopeSummaryComponent implements OnInit, OnDestroy {

  summary: Observable<any> | undefined;

  landingPageVersion: number = 1;
  private readonly destroying$: Subject<void> = new Subject<void>();

  constructor(private getScopeSummary: ScopeSummary,
    private featureFlagService: FeatureFlagService) { }

  ngOnInit(): void {
    this.checkPulseLandingPageVersionFeatureFlag();
    this.summary = this.getScopeSummary.run();
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  checkPulseLandingPageVersionFeatureFlag() {
    // To eliminate the multiple API call
    this.featureFlagService.getPulseLandingPageV2Flag(this.destroying$).then((flagValue: boolean) => {
      this.landingPageVersion = (flagValue ? 2 : 1);
    });
  }
}
