import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataRefreshService } from '../../services/data-refresh.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';

@Component({
  selector: 'he-refresh-project-data',
  templateUrl: './refresh-project-data.component.html',
  styleUrls: ['./refresh-project-data.component.scss'],
})
export class RefreshProjectDataComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource([{}]);
  projectRefreshData: any = [];
  showSection : boolean = false;
  
  public displayedColumns: string[] = [
    'projectId',
    'projectName',
    'isPriceChanged',
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<any>;

  constructor(
    public dataRefreshService: DataRefreshService,
    private toastNotificationService: ToastNotificationService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    //this.showSection = false;
  }

  ngOnInit(): void {
    this.getAllProjectPriceUpdate();
  }

  private async getAllProjectPriceUpdate() {
    this.showSection = false;
    await this.dataRefreshService
      .getAllProjectPriceUpdates()
      .then((projectUpdate) => {
        this.showSection = true;
        this.projectRefreshData = projectUpdate.data;
        this.dataSource = new MatTableDataSource(this.projectRefreshData);
        this.dataSource.paginator = this.paginator;
        
      })
      .catch((err) => {
        if (!Array.isArray(err.error.data)) {
          this.toastNotificationService.errorMessage(err.error.data);
          this.showSection = true;
        }
      });
  }
}
