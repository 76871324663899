  <button mat-icon-button
    class="mat-focus-indicator he-icon-button he-button-m-size mat-icon-button mat-button-base infoButton"
    [matMenuTriggerFor]="tooltip"
    title="More information">
  <span class="mat-button-wrapper">
    <he-icon [id]="'suggested-circle-info'"></he-icon>
  </span>
</button>

<mat-menu #tooltip="matMenu" xPosition="before" class="he-menu-panel">
  <div class="infoText">{{infoButtonText}}</div>
</mat-menu>
