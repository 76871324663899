import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ProjectSummaryEditorComponent } from './project-summary-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IconModule } from '../helios-icons';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ProjectSummaryEditorComponent],
  exports: [ProjectSummaryEditorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    IconModule,
    MatInputModule
  ]
})
export class ProjectSummaryEditorModule {
}
