import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GridSystemModule } from '../../uikit/grid-system';
import { CssUtilitiesModule } from '../../uikit/css-utilities';
import { GridStylesModule } from '../../uikit/grid-styles';
import { MatThemeCoreModule, ShadesLightThemeModule } from '../../uikit/mat-theme';
import { RobotoFontModule } from '../../uikit/roboto-font';
import { IconPackModule } from '../../uikit/helios-icons';
import { MaterialIconsModule } from '../../uikit/material-icons';
import { NormalizedStylesModule } from '../../uikit/normalized-styles';
import { LatoFontModule } from '../../uikit/lato-font';

import { BasisComponent } from './basis.component';
import { ButtonsModule } from '../../uikit/buttons';
import { MenuPanelModule } from '../../uikit/menu-panel';
import { ExpansionPanelModule } from '../../uikit/expansion-panel';
import { CheckboxAndRadioButtonModule } from '../../uikit/checkbox-and-radio-button';
import { InputFieldModule } from '../../uikit/input-field';

@NgModule({
  declarations: [BasisComponent],
  exports: [BasisComponent],
  imports: [
    CommonModule,
    GridSystemModule,
    CssUtilitiesModule,
    GridStylesModule,
    MatThemeCoreModule,
    ShadesLightThemeModule,
    IconPackModule,
    MaterialIconsModule,
    NormalizedStylesModule,
    RobotoFontModule,
    LatoFontModule,
    ButtonsModule,
    MenuPanelModule,
    ExpansionPanelModule,
    CheckboxAndRadioButtonModule,
    InputFieldModule
  ]
})
export class BasisModule {
}
