import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'he-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterContentInit, OnDestroy {
  @Output() terminate = new EventEmitter<undefined>();

  @Input() disableClose = false
  @Input() dialogHeight:string ="";
  @Input() dialogWidth = "";


  @ViewChild('content', { static: true }) content!: TemplateRef<unknown>;

  private ref?: MatDialogRef<unknown>;
  private sub?: Subscription;

  constructor(private dialog: MatDialog) {
  }

  ngAfterContentInit(): void {
    this.ref = this.dialog.open(this.content, {
      autoFocus: true,
      restoreFocus: false,
      disableClose: this.disableClose,
      height: this.dialogHeight,
      width: this.dialogWidth,
    });

    this.sub = this.ref.afterClosed().subscribe(this.terminate);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.ref?.close();
  }
}
