import { Component, Input, TemplateRef } from '@angular/core';
import { DatepickerFDescriptionInterface } from '../datepicker';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'he-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent {
  descriptionsStart: DatepickerFDescriptionInterface = {
    placeholder: 'Start',
    errorMessage: 'Invalid start date'
  };
  descriptionsEnd: DatepickerFDescriptionInterface = {
    placeholder: 'End',
    errorMessage: 'Invalid end date'
  };
  maxStartDate: Date | any;
  minEndDate: Date | any;

  @Input() titleTemplate!: TemplateRef<any>;

  private rangeControlStartP!: FormControl;
  private rangeControlEndP!: FormControl;

  get rangeControlStart(): FormControl {
    return this.rangeControlStartP;
  }

  @Input() set rangeControlStart(control: FormControl) {
    this.rangeControlStartP = control;
    this.minEndDate = control.value;
  }

  get rangeControlEnd(): FormControl {
    return this.rangeControlEndP;
  }

  @Input() set rangeControlEnd(control: FormControl) {
    this.rangeControlEndP = control;
    this.maxStartDate = control.value;
  }

  dateInputStartModified(event: MatDatepickerInputEvent<Date | string>): void {
    this.minEndDate = event.value;
  }

  dateInputEndModified(event: MatDatepickerInputEvent<Date | string>): void {
    this.maxStartDate = event.value;
  }
}
