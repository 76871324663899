<ng-container *ngIf="summary | async as msg">
  <ng-container *ngIf="msg.payload">
    <ng-container *ngIf="(landingPageVersion === 1)">
      <div class="scope-toolbar">
        <span>My scope</span>
      </div>
      <mat-card class="p-4 he-shadow-soft" [innerHTML]="msg.payload">
        <mat-card-content></mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="(landingPageVersion === 2)">
      <ng-container *ngIf="msg.payload | scopeSummarySplit as payload">
        <mat-expansion-panel expanded="false" class="he-shadow-soft">
          <mat-expansion-panel-header>
            <mat-panel-title class="scope-toolbar py-4">
              My scope
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <div *ngIf="payload.therapyAreasHtml" class="col-sm-4 d-flex flex-column"
              [innerHTML]="payload.therapyAreasHtml"></div>
            <div class="col d-flex flex-column" [innerHTML]="payload.scopeSummaryHtml"></div>
          </div>

        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
