<div class="card-he tab" #dataToExport>
  <div class="card-wrapper main-grid-wrapper value-score-table">
    <mat-card class="example-card">
      <mat-card-header class="header-row" style="background: white">
        <div>
          <div class="heading value-scoring-heading">
            <span class="heading-title">VALUE ASSESMENT SUMMARY</span>
          </div>
        </div>
        <div class="toggle-button-group">
          <mat-button-toggle-group #group="matButtonToggleGroup"  *heFeatureFlag="{ Name: 'value-summary-grid-toggle', Page: 'Summary' }">
            <mat-button-toggle
              [ngClass]="isGridClicked ? 'toggle-btn-active' : 'toggle-btn'"
              (click)="gridToggle('grid')"
            >
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              [ngClass]="isChartClicked ? 'toggle-btn-active' : 'toggle-btn'"
              (click)="gridToggle('chart')"
            >
              <mat-icon>pie_chart</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div  class="download-div"><a (click)="dwonloadFileCtry()"
          ><img
            src="/assets/dwonload-purple.svg" class="dwonload-icon"
            alt="Export Data"
            href="#"
        /></a></div>
      </mat-card-header>
      <mat-card-header
        class="button-centered-align"
        style="background: white"
        [ngClass]="isChartClicked ? 'grid-display-hide' : 'grid-display-show'"
        
      >
        <mat-button-toggle-group
          [value]="viewMode"
          name="fontStyle"
          aria-label="Font Style"
          (change)="changeView($event.value)"
          
        >
          <mat-button-toggle value="Percentage" *heFeatureFlag="{ Name: 'value-summary-view-toggle', Page: 'Summary'}">Percentage</mat-button-toggle>
          <mat-button-toggle value="AbsoluteValue"
            >Absolute Value</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card-header>
      <div *ngIf="loading">
        <he-loading-spinner
          [diameter]="70"
          [height]="'350px'"
        ></he-loading-spinner>
      </div>
      <div style="background: white" *ngIf="!loading">
        <mat-card-content
          style="padding: 0 16px !important"
          class="socring-table hide-scroll-content"
        >
          <he-summary-value-assesment
            [ngClass]="
              !isChartClicked ? 'grid-display-hide' : 'grid-display-show'
            "
            [analoguesData]="analoguesData"
            [SummaryFormattedData]="SummaryFormattedData"
          ></he-summary-value-assesment>
          <he-project-value-scoring-sub-grid
            [ngClass]="
              !isGridClicked ? 'grid-display-hide' : 'grid-display-show'
            "
            (recordsCountEvent)="subGridCountChanged($event)"
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumns"
            [analoguesData]="analoguesData"
            [countries]="countries"
          ></he-project-value-scoring-sub-grid>
        </mat-card-content>
      </div>
    </mat-card>
    <div id="download">
      <img #canvas>
      <a #downloadLink></a>
    </div>
  </div>
</div>
<div *ngIf="ScenarioDataList.length>1">
<div class="card-he tab" *heFeatureFlag="{ Name: 'asset-tpp-summary', Page: 'Summary' }" >
  <div class="card-wrapper main-grid-wrapper asset-tpp-summary-tab">
    <mat-card
      class="example-card asset-tpp-summary-card"
    >
      <mat-card-header class="header-row">
        <div>
          <div class="heading value-scoring-heading">
            <span class="heading-title">TPP SCENARIOS VALUE SCORING COMPARISON</span>
          </div>
          <div class="summary-sub-heading">
            <p>The following table shows the value attributes for which there are differences in score between TPP scenarios</p>
          </div>
        </div>
        <div class="row pull-right">
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon class="cog-icon">settings</mat-icon>
          </button>
          <mat-menu #appMenu="matMenu" class="cog-menu">            
            <mat-radio-group aria-label="Select an option" class="cog-menu-items" [(ngModel)]="defaultScoreMode">
              <mat-radio-button value='1'>Show scores only</mat-radio-button>
              <mat-radio-button value='2'>Show scores + description</mat-radio-button>
            </mat-radio-group>
          </mat-menu>
          <div class="download-div"><a (click)="dwonloadAssetTPPSummary()"
            ><img
              src="/assets/dwonload-purple.svg" class="dwonload-icon"
              alt="Export Data"
              href="#"
          /></a></div>
        </div>
      </mat-card-header>   
      <mat-card-content style="padding:16px 16px 16px 16px !important" class="asset-tpp-summary-desc">      
        <he-summary-asset-tpp [scoreMode]="defaultScoreMode" ></he-summary-asset-tpp>
      </mat-card-content>
    </mat-card>
  </div>
</div>
</div>
<!-- development in progress -->
<div class="card-he tab"  *heFeatureFlag="{ Name: 'cot-forecast-summary', Page: 'Summary' }">
  <div class="card-wrapper main-grid-wrapper value-score-table">
    <mat-card class="example-card">
      <mat-card-header class="header-row" style="background: white">
        <div>
          <div class="heading value-scoring-heading">
            <span class="heading-title" >
              {{ this.headerService.ComparatorOrAnalog.value !='Comparators' ? 'COST OF TREATMENT FORECAST SUMMARY' : 'COST OF TREATMENT SUMMARY' }}
            </span>
          </div>
        </div>
        <div class="download-div-value-scoring" ><a (click)="downloadFile()"
          ><img
            src="/assets/dwonload-purple.svg" class="dwonload-icon"
            alt="Export Data"
            href="#"
        /></a></div>
      </mat-card-header>
      <div style="background: white" >
        <mat-card-content
          style="padding: 0 16px !important"
          class="socring-table hide-scroll-content"
        >
        <he-summary-cot-forecast [alpha]="alpha"[data]="data"></he-summary-cot-forecast>
        </mat-card-content>
      </div>
    </mat-card>
    <div id="download">
      <img #canvas>
      <a #downloadLink></a>
    </div>
  </div>
</div>

<div *ngIf="ScenarioDataList.length>1 && this.headerService.ComparatorOrAnalog.value!='Comparators'">
<div class="card-he tab" *heFeatureFlag="{ Name: 'cot-comparison-summary', Page: 'Summary' }" >
  <div class="card-wrapper main-grid-wrapper value-score-table">
    <mat-card class="example-card">
      <mat-card-header class="header-row" style="background: white">
        <div>
          <div class="heading value-scoring-heading">
            <span class="heading-title"> 
              {{ this.headerService.ComparatorOrAnalog.value !='Comparators' ? 'FORECAST COST OF TREATMENT SCENARIO COMPARISON': 'COST OF TREATMENT SCENARIO COMPARISON'}}</span>
          </div>
        </div>
      </mat-card-header>
      <!-- <div *ngIf="loading">
        <he-loading-spinner
          [diameter]="70"
          [height]="'350px'"
        ></he-loading-spinner>
      </div> -->
      <div style="background: white" >
        <mat-card-content
          style="padding: 0 16px !important"
          class="socring-table hide-scroll-content"
        >
        <he-summary-cot-comparison [alpha]="alpha"[data]="data"></he-summary-cot-comparison>
        </mat-card-content>
      </div>
    </mat-card>
    <div id="download">
      <img #canvas>
      <a #downloadLink></a>
    </div>
  </div>
</div>
</div>
