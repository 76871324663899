import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterProductParamsBrandedStatusComponent } from './filter-product-params-branded-status.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterProductParamsBrandedStatusComponent],
  exports: [FilterProductParamsBrandedStatusComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
})
export class FilterProductParamsBrandedStatusModule {
}
