<div class="summary-value-assement">
  <ng-container>
    <div
    class="summary-value-assement-summary"
    *ngFor="let brand of analoguesData"
  >
    <div class="summary-value-assement-summary-lable">
      <p class="analogues">{{ brand.brandedInnName }}<br>{{ brand.scenarioName }}</p>
    </div>
    <div class="summary-value-assement-summary-data">
      <div class="summary-line"></div>
      <ng-container *ngFor="let country of brand.country">
        <img
        *ngIf="country.secondaryPercentage !=='NA'"
        src="assets/flags/small/{{ countryFlagService.getFlagId(country.name)}}.svg"
        class="summary-country"
        [ngStyle]="{ 'left': country.secondaryPercentage + '%','z-index':country.zIndex }"
        matTooltip="Value score percentage:{{country.percentage}}" matTooltipClass="horizon-tooltip-02"
      />
      </ng-container>

    </div>
  </div>
  <div
    class="summary-value-assement-summary"
    *ngFor="let brand of SummaryFormattedData"
  >
    <div class="summary-value-assement-summary-lable">
      <p>{{ brand.brandedInnName }}</p>
    </div>
    <div class="summary-value-assement-summary-data">
      <div class="summary-line"></div>
      <ng-container *ngFor="let country of brand.country">
        <img
        *ngIf="country.secondaryPercentage !=='NA'"
        src="assets/flags/small/{{ countryFlagService.getFlagId(country.name)}}.svg"
        class="summary-country"
        [ngStyle]="{ 'left': country.secondaryPercentage + '%','z-index':country.zIndex}"
        matTooltip="Value score percentage: {{country.percentage}}" matTooltipClass="horizon-tooltip-02"
      />
      </ng-container>

    </div>
  </div>
  <div class="summary-value-assement-summary">
    <div class="summary-value-assement-summary-labels">
      <div
        *ngFor="let percentage of percentages"
        class="summary-labels"
        [ngStyle]="{ left: percentage }"
      >
        {{ percentage }}
      </div>
    </div>
  </div>
  <div class="summary-value-assement-summary summary-sub-title">
    <div>
      <p>Value Score Percentage</p>
    </div>
  </div>
  </ng-container>
</div>
