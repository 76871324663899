import { Injectable } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { FilterItemValueInterface } from '../../uikit/filters';
import { CachedAccessPermissions, PermissionsResponse } from '../../core/access-permissions';
import { AccessBarrierService } from '../access-barrier/access-barrier.service';
import { AccessPagesEnum } from '../common/enums';
import { SharedService } from '../../uikit/service/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ExportCsv {
  private gridApis: GridApiItem[] = [];
  private permissionList: PermissionsResponse[] = [];
  accessBarrierService: AccessBarrierService | undefined;
  notesPreLaunch: string="Approved availability status refers to a Regulatory Approved But Commercially Not Available Product.";

  constructor(
    public accessPermissions: CachedAccessPermissions,
    private sharedService:SharedService
  ) {
    this.accessBarrierService = accessPermissions;
  }

  registerApi(
    ev: any,
    brandNames: FilterItemValueInterface[],
    itemName: string, irpMatrixRefVal: boolean = false
  ): void {
    const brandName =
      itemName && brandNames ? this.getCsvName(itemName, brandNames, irpMatrixRefVal) : '';
    if (ev) {
      this.gridApis.push({
        apiItem: ev,
        name: brandName,
      });
    }
  }

  unRegisterApi(apiItem: any): void {
    const target = apiItem ? apiItem : {};
    this.gridApis = this.gridApis.filter((el) => {
      return el.apiItem !== target;
    });
  }

saveAllCSV(signpostingVal?: string, irpMatrixCountryVal?: string, IsPriceIRPMatrix?: boolean): void { 
  for (let i = 0; i < this.gridApis.length; i++) {
    // Access each item in the array
    const gridApiItem = this.gridApis[i];
    this.saveCsv(signpostingVal, irpMatrixCountryVal, IsPriceIRPMatrix, gridApiItem)
  }
}

  modifiedCsvContent: any;
  //lastItem: any = [];
  saveCsv(signpostingVal?: string, irpMatrixCountryVal?: string, IsPriceIRPMatrix?: boolean, lastItem?:any): void {
   if(!lastItem) 
      lastItem = this.gridApis.slice(-1)[0]; //consider only last call if it is not from export all 

    if(lastItem)
    {
    const params = {
      fileName: lastItem.name,
      processCellCallback: this.processCellCallback,
    };

    // Function to unwrap and clean cell values
    const unwrapCell = (cell: any) => {
      return cell.replace(/(\r\n|\n|\r)/gm, '') // Remove line breaks
        .replace(/€/g, '\u20AC'); // Replace € symbol with its Unicode equivalent (if needed)
    };


    //display signposting over the excel at last position
    if (signpostingVal != null && signpostingVal !== '') {
      const csvContent = lastItem.apiItem.getDataAsCsv();
      this.modifiedCsvContent = csvContent + '\r\n' + ','; // Add an empty row      
      const unwrappedText = signpostingVal.replace(/(\r\n|\n|\r)/gm, ''); // Remove line breaks
      this.modifiedCsvContent += '\r\n' + '"' + unwrappedText.replace(/"/g, '""').replace(/€/g, '\u20AC') + '"';


      // Encode the CSV content as UTF-16
      const encodedCsvContent = '\uFEFF' + this.modifiedCsvContent; // Prepend the Byte Order Mark (BOM) character


      // Download the modified CSV file
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-16le,' + encodeURIComponent(encodedCsvContent);
      link.download = lastItem.name + '.csv';
      link.click();

    }
    else if (irpMatrixCountryVal != null && irpMatrixCountryVal !== '') {
      const countryRow = `${irpMatrixCountryVal}- IRP Reference rule`;

      const csvContent = lastItem.apiItem.getDataAsCsv().slice(6);
      const rows = csvContent.split('\n"'); // Split CSV content into rows
      this.modifiedCsvContent = countryRow + '\r\n';

      rows.forEach((row: string, rowIndex: number) => {
        const columns = row.split('","'); // Split each row into columns

        columns.forEach((cell: string, columnIndex: number) => {
          const unwrappedCell = unwrapCell(cell);

          if (columnIndex > 0) {
            this.modifiedCsvContent += ',';
            this.modifiedCsvContent += `"${unwrappedCell}`;
          }
          else {
            // Wrap the cell within double quotes
            this.modifiedCsvContent += `"${unwrappedCell}"`;
          }
        });

        this.modifiedCsvContent += '\r\n'; // Add a new line after each row
      });

      // Encode the CSV content as UTF-16
      const encodedCsvContent = '\uFEFF' + this.modifiedCsvContent;

      // Download the modified CSV file
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-16le,' + encodeURIComponent(encodedCsvContent);
      if (IsPriceIRPMatrix) {
        link.download = lastItem.name;
      }
      else {
        link.download = lastItem.name + `${irpMatrixCountryVal}.csv`;
      }
      link.click();
    }
    else {
      lastItem.apiItem.exportDataAsCsv(params);
    }
  }
  }

  processCellCallback(params: any) {
    if (params.column.colId === 'country') {
      return (
        params.value +
        (params.node.data.countryHoverTitle
          ? params.node.data.countryHoverTitle
          : '')
      );
    }
  
    return params.value;
  
  }

  csvData: any[] = [];

  worksheetname: string = '';
  isdownload: boolean = false;
  saveSingleCSV(
    dataset: any[],
    selectedTab: number,
    pnrSignPostVal: string,
    pnrSignPostValGermany: string,
    htaSignPostValChina: string,
    htaSignPostVal: string,
    pnrReimbColumnExist: boolean,
    htaReimbColumnExist: boolean,
    isGermanyExist: boolean,
    isChinaExist: boolean
  ) {
    if (selectedTab == 0) this.worksheetname = 'Price & Reimbursement';
    else if (selectedTab == 1)
      this.worksheetname = 'Indication & Cost of Treatment';
    else if (selectedTab == 2) this.worksheetname = 'HTA';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.worksheetname);

    if (dataset.length > 0) worksheet.addRow(Object.keys(dataset[0]));
    dataset.forEach((record: any) => {
      worksheet.addRow(Object.values(record));
    });
    
    worksheet.addRow([]);
    if (selectedTab == 0) {
      if (pnrSignPostVal && pnrReimbColumnExist) {
        if (pnrReimbColumnExist) worksheet.addRow([pnrSignPostVal]);
      }
      if (isGermanyExist) worksheet.addRow([pnrSignPostValGermany]);
    } else if (selectedTab == 2) {
      if (htaSignPostVal && htaReimbColumnExist) {
        worksheet.addRow([htaSignPostVal]);
      }
      if (isChinaExist) worksheet.addRow([htaSignPostValChina]);
    }

    if (selectedTab != 2) {
      const hasNullOrEmptyPackDescription = dataset.some(item => {
        return item["Pack description"] === null || (item["Pack description"] && item["Pack description"].trim() === '');
      });
      if (hasNullOrEmptyPackDescription) {
        worksheet.addRow([this.notesPreLaunch]);
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, this.worksheetname + '.xlsx');
    });
  }

  async saveAllCsv(
    datasetPnR: any[],
    datasetCoT: any[],
    datasetHTA: any[],
    pnrSignPostVal: string,
    pnrSignPostValGermany: string,
    htaSignPostValChina: string,
    htaSignPostVal: string,
    pnrReimbColumnExist: boolean,
    htaReimbColumnExist: boolean,
    isGermanyExist: boolean,
    isChinaExist: boolean
  ) {
    const accessibleRoute = await this.accessPermissions
      .run({ payload: null })
      .toPromise();
    this.permissionList = accessibleRoute?.payload as [];
    let workbook = new Workbook();

    //add header ,worksheet,data
    if (this.isPermissionActive(AccessPagesEnum.gridViewPnR)) {
      let worksheetPNR = workbook.addWorksheet('Price & Reimbursement');
      if (datasetPnR.length > 0) worksheetPNR.addRow(Object.keys(datasetPnR[0]));
      datasetPnR.forEach((record: any) => {
        worksheetPNR.addRow(Object.values(record));
      });
      if (pnrReimbColumnExist || isGermanyExist) {
        worksheetPNR.addRow([]);
        if (pnrReimbColumnExist) worksheetPNR.addRow([pnrSignPostVal]);
        if (isGermanyExist) worksheetPNR.addRow([pnrSignPostValGermany]);
      }

      const hasNullOrEmptyPackDescription = datasetPnR.some(item => {
        return item["Pack description"] === null || (item["Pack description"] && item["Pack description"].trim() === '');
      });
      if (hasNullOrEmptyPackDescription) {
        worksheetPNR.addRow([this.notesPreLaunch]);
      }
    }
    if (this.isPermissionActive(AccessPagesEnum.gridViewCOT)) {
      let worksheetCOT = workbook.addWorksheet('Indication & Cost of Treatment');
      if (datasetCoT.length > 0) worksheetCOT.addRow(Object.keys(datasetCoT[0]));

      datasetCoT.forEach((record: any) => {
        worksheetCOT.addRow(Object.values(record));
      });
      
      worksheetCOT.addRow([]);
      const hasNullOrEmptyPackDescription = datasetCoT.some(item => {
        return item["Pack description"] === null || (item["Pack description"] && item["Pack description"].trim() === '');
      });
      if (hasNullOrEmptyPackDescription) {
        worksheetCOT.addRow([this.notesPreLaunch]);
      }

    }
    if (this.isPermissionActive(AccessPagesEnum.gridViewHTA)) {
      let worksheetHTA = workbook.addWorksheet('HTA');
      if (datasetHTA.length > 0) worksheetHTA.addRow(Object.keys(datasetHTA[0]));

      datasetHTA.forEach((record: any) => {
        worksheetHTA.addRow(Object.values(record));
      });
      if (htaSignPostVal && htaReimbColumnExist) {
        worksheetHTA.addRow([]);
        worksheetHTA.addRow([htaSignPostVal]);
      }
      if (isChinaExist) worksheetHTA.addRow([htaSignPostValChina]);
    }

    //generate and save excel file
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'Pulse_insight.xlsx');
    });
  }

  isPermissionActive(data: string): boolean {
    return !!this.permissionList.find(
      (item) => item.code.toLowerCase() === data.toLowerCase()
    );
  }

  private getCsvName(
    itemName: string,
    brandNames: FilterItemValueInterface[], irpMatrixRefVal: boolean = false
  ): string {
    const brand =
      brandNames.length > 1
        ? brandNames.length
        : brandNames.map((el) => el.name);
    if (irpMatrixRefVal)
      return `${itemName.replace(/\s/g, '_').toLowerCase()}_`;
    else
      return `${itemName.replace(/\s/g, '_').toLowerCase()}_${brand.toString().toLowerCase()}.csv`
  }

  public newCsvImport(name: string, rowData: any[], brands: any[], details: boolean, featureFlag: boolean, featureFlagLaunchDate: boolean): void {
    let str = '';
    let line1 = 'Country,Availability Details,';
    let line2 = '';
    rowData.forEach((analogue: any, index: number) => {
      line2 += analogue.colName + ',Availability Status,';
      brands.forEach((brand) => {
        if (index == 0) {
          line1 += brand.brandedName + ',';
        }
        if (featureFlag) {
          line2 +=
            analogue[brand.brandedName.toLowerCase()].availableStatus + ',';
        } else {
          line2 +=
            analogue[brand.brandedName.toLowerCase()] + ','
        }

      });
      line2 += '\r\n';
      if (details) {
        let line3 = ',Initial Indication Approval Date,';
        let line4 = ',Launch Date,';
        let line5 = ',Reimbursement Status,';
        brands.forEach((brand) => {
          line3 += '"' + analogue[brand.brandedName.toLowerCase()].approvalDate + '",';
          line4 += '"' + analogue[brand.brandedName.toLowerCase()].launchDate + '",';
          line5 +=
            analogue[brand.brandedName.toLowerCase()].reimbursementStatus + ',';
        });

        line2 += line3 + '\r\n' + (featureFlagLaunchDate ? line4 + '\r\n'  : '') + line5 + '\r\n';
      }
    });
    str += line1 + '\r\n';
    str += line2;
    str += '\r\n' +
      'Available - Regulatory Approved & Commercially Available Product.' +
      '\r\n' +
      'Approved- Regulatory Approved But Commercially Not Available Product.';
    const blob = new Blob(['\ufeff' + str], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', name + brands.length + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}

interface GridApiItem {
  apiItem: GridApi;
  name: string;
}

