import { FeatureFlagService } from './../../feature-flag/feature-flag.service';
import { SharedService } from './../../../uikit/service/shared.service';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterIncludeExcludeEnum, FilterItemInterface, FilterItemValueInterface, FilterMeasuresCOTTypeEnum, FilterMeasuresPeriodEnum, FilterNameEnum, FiltersObject, ISearchType } from '../interfaces';
import { DatastoreContext } from '../datastore.context';
import { FiltersValueService } from '../filters-value.service';
import { getCurrencySymbol } from '@angular/common';
import { currenciesName } from 'projects/helios-gui/src/core/filter-by-currency';
import {
  CachedAccessPermissions,
  PermissionsResponse,
} from '../../../core/access-permissions';
import { AccessPagesEnum, SessionEnums } from '../../common/enums';
import { browserRefresh } from '../../app.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject, takeUntil } from 'rxjs';
import { DialogText, TooltipText } from '../../common';
import { AVERAGE_BODY_WEIGHT, COT_LEVEL, BODY_SURFACE_AREA } from '../../feature-flag/feature-flag-constants';
import { GridViewTypeEnum } from 'projects/helios-gui/src/uikit/filters';
import { FilterPresetTypes } from './filter-preset';
import { FilterDefaultsCOTPaediatric, FilterDefaultsCOTAdult } from '../constants';

@Component({
  selector: 'he-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() hasCountrySaveButton = true;
  @Input() hasCountryResetButton = true;

  private readonly destroying$: Subject<void> = new Subject<void>();

  @ViewChild('measuresMatExpansionPanel') measuresPanel?: MatExpansionPanel;

  private result: PermissionsResponse[] = [];
  showPriceCategoryFilter = false;
  measuresPanelExpanded = true;
  measuresPresetFeatureFlag: boolean = false;
  DialogText = DialogText;
  TooltipText = TooltipText;
  FilterPresetTypes = FilterPresetTypes;

  AVERAGE_BODY_WEIGHT=AVERAGE_BODY_WEIGHT;
  BODY_SURFACE_AREA=BODY_SURFACE_AREA;
  COT_LEVEL=COT_LEVEL;

  constructor(
    public filtersValueService: FiltersValueService,
    private dsContext: DatastoreContext,
    private cachedAccessPermissions: CachedAccessPermissions,
    private sharedService: SharedService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngAfterViewInit(): void {
    this.checkActiveSectionName(this.sharedService.activeSectionName$.value);
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  ngOnInit(): void {
    this.subscribeActiveSectionName();
    this.subscribeActiveGridViewTabName();
    this.subscribeFeatureFlags();

    this.dsContext.details$.subscribe((value: any) => {
      let searchEntityType: ISearchType = value.source;
      searchEntityType.id = "" + searchEntityType.id
      if (value && value.config) {
        let startFiltersObject: FiltersObject = [
          ...this.filtersValueService.filterValues$.getValue().filter(
            (filtersItem) =>
              filtersItem.filterName !== FilterNameEnum.brands &&
              filtersItem.filterName !== FilterNameEnum.countries &&
              filtersItem.filterName !== FilterNameEnum.indications &&
              filtersItem.filterName !== FilterNameEnum.indicationsAllowed &&
              filtersItem.filterName !== FilterNameEnum.currency &&
              filtersItem.filterName !== FilterNameEnum.discontinued &&
              filtersItem.filterName !== FilterNameEnum.cotCalculator &&
              filtersItem.filterName !== FilterNameEnum.corridorCOTIds
            //&& filtersItem.filterName !== FilterNameEnum.routeOfAdministartion
          ),
        ];

        let updatedCurrency = value.config.filter(
          (configItem: any) => configItem.type === 'currency'
        );
        updatedCurrency = updatedCurrency.map((item: any) => {
          return {
            name: `${
              currenciesName[item.name] ? currenciesName[item.name] : item.name
            } (${getCurrencySymbol(item.name, 'narrow')})`,
            type: item.name,
          };
        });
        const projectContent =
          value.source?.InsightsProject?.content &&
          JSON.parse(value.source.InsightsProject.content);

        let dbBrands: FilterItemValueInterface[] = this.filtersValueService.findItemInFilters(projectContent?.filters, FilterNameEnum.brands).filterValue;
        // let dbBrands: FilterItemValueInterface[] = value.config.filter((configItem: any) => configItem.type === 'brand');

        let sessionBrands = this.filtersValueService.getProperty(SessionEnums.brands);
        if (dbBrands.length > 0 && !browserRefresh) {
          this.filtersValueService.setBrandsProperty(dbBrands);
        } else if (dbBrands.length > 0) {
          this.filtersValueService.setBrandsProperty(dbBrands);
        } else if (!sessionBrands || sessionBrands && sessionBrands.length == 0) {
          dbBrands = value.config.filter(
            (configItem: any) => configItem.type === 'brand'
          );
          this.filtersValueService.setBrandsProperty(dbBrands);
        }

        if (sessionBrands && sessionBrands.length) {
          dbBrands = sessionBrands;
        }

        let indications: FilterItemValueInterface[] = []
        if(browserRefresh)
        {
          indications = this.filtersValueService.getProperty(SessionEnums.indications);
        }
        else
        {
          indications = value.config.filter(
            (configItem: any) => configItem.type === 'indication'
          );
          this.filtersValueService.setIndidactionsProperty(indications)
        }


        let indicationsAllowed: FilterItemValueInterface[] = []
        if(browserRefresh)
        {
          indicationsAllowed = this.filtersValueService.getProperty(SessionEnums.indicationsAllowed);
          startFiltersObject.push(
            {
              filterName: FilterNameEnum.indicationsAllowed,
              filterValue: indicationsAllowed,
            })
        }

        let CotIds: any[] = this.filtersValueService.cotIdList.value.map(
          (x: any) => x.cotId
        );
        const nextValue: FiltersObject = [
          ...startFiltersObject,
          {
            filterName: FilterNameEnum.brands,
            filterValue: dbBrands,
          },
          {
            filterName: FilterNameEnum.countries,
            filterValue: projectContent?.filters
              ? this.filtersValueService.findItemInFilters(
                  projectContent?.filters,
                  FilterNameEnum.countries
                ).filterValue
              : value.config.filter(
                  (configItem: any) => configItem.type === 'country'
                ),
          },
          {
            filterName: FilterNameEnum.indications,
            filterValue: indications,
          },
          // {
          //   filterName: FilterNameEnum.indicationsAllowed,
          //   filterValue: value.config.filter((configItem: any) => configItem.type === 'indication')
          // },
          {
            filterName: FilterNameEnum.currency,
            filterValue: updatedCurrency,
          },
          {
            filterName: FilterNameEnum.discontinued,
            filterValue: [
              value.source?.Discontinued === FilterIncludeExcludeEnum.include
                ? {
                    name: 'Include discontinued',
                    type: FilterIncludeExcludeEnum.include,
                  }
                : {
                    name: 'Exclude discontinued',
                    type: FilterIncludeExcludeEnum.exclude,
                  },
            ],
          },
          {
            filterName: FilterNameEnum.searchType,
            filterValue: [{ name: 'Search type', type: searchEntityType }],
          },
          {
            filterName: FilterNameEnum.cotCalculator,
            filterValue: [
              {
                name: 'cotCalculator',
                type: projectContent?.filters
                  ? this.filtersValueService.findItemInFilters(
                      projectContent?.filters,
                      FilterNameEnum.cotCalculator
                    ).filterValue[0].type
                  : [],
              },
            ],
          },
          {
            filterName: FilterNameEnum.corridorCOTIds,
            filterValue: CotIds,
          },
        ];
        this.filtersValueService.filterValues$.next(nextValue);
      }


      this.checkPermissions();
    });
  }

  subscribeFeatureFlags() {
    this.featureFlagService.getMeasuresFiltersPresetFlag(this.destroying$).then((flagValue: boolean) => {
      this.measuresPresetFeatureFlag = flagValue;
      this.checkActiveSectionName(this.sharedService.activeSectionName$.value);
    });
  }

  private subscribeActiveSectionName() {
    this.sharedService.activeSectionName$
      .pipe(takeUntil(this.destroying$))
      .subscribe((section: string) => {
        this.checkActiveSectionName(section);
      });
  }

  private checkActiveSectionName(section: string) {
    if (this.sharedService.gridModeValue$.value === 'chart') {
      switch (section) {
        case 'general':
        case 'reimbursement':
        case 'journey':
          if(this.measuresPresetFeatureFlag)
          {
            this.measuresPanelExpanded = false;
            this.measuresPanel?.close();
          }
          break;

        case 'cot':
        case 'price':
          if(this.measuresPresetFeatureFlag)
          {
            this.measuresPanelExpanded = true;
            this.measuresPanel?.open();
          }
          break;
      }
    }
  }

  private subscribeActiveGridViewTabName() {
    this.sharedService.datastoreTabName$
      .pipe(takeUntil(this.destroying$))
      .subscribe((section: string) => {
        this.checkActiveGridViewTabName(section);
      });
    this.checkActiveGridViewTabName(this.sharedService.datastoreTabName$.value);
  }

  private checkActiveGridViewTabName(section: string) {
    if (this.sharedService.gridModeValue$.value === 'table') {
      switch (section) {
        case GridViewTypeEnum.packprice:
        case GridViewTypeEnum.hta:
          if(this.measuresPresetFeatureFlag)
          {
            this.measuresPanelExpanded = false;
            this.measuresPanel?.close();
          }
          break;

        case GridViewTypeEnum.indicationcot:
          if(this.measuresPresetFeatureFlag)
          {
            this.measuresPanelExpanded = true;
            this.measuresPanel?.open();
          }
          break;
      }
    }
  }

  filtersValueModified(filtersValue: FiltersObject, checkChangeValue: boolean = false): void {
    this.filtersValueService.filterValues$.next(!checkChangeValue ? filtersValue : this.checkFilterValueChanges(filtersValue));
  }

  checkFilterValueChanges(filtersValue: FiltersObject) {
    if(this.hasFilterValueChanged(FilterNameEnum.cotType, filtersValue)) {
      switch (this.filtersValueService.findItemInFilters(filtersValue, FilterNameEnum.cotType)?.filterValue[0]?.name){
        case FilterMeasuresCOTTypeEnum.paediatric:
          if(this.measuresPresetFeatureFlag)
          {
            filtersValue = this.filtersValueService.updateItemInFilters(FilterNameEnum.bodySurfaceArea, [{name:FilterDefaultsCOTPaediatric.bodySurfaceArea}], filtersValue);
            filtersValue = this.filtersValueService.updateItemInFilters(FilterNameEnum.averageBodyWeight, [{name:FilterDefaultsCOTPaediatric.averageBodyWeight}], filtersValue);
          }
          break;
        case FilterMeasuresCOTTypeEnum.adult:
          if(this.measuresPresetFeatureFlag)
          {
            filtersValue = this.filtersValueService.updateItemInFilters(FilterNameEnum.bodySurfaceArea, [{name:FilterDefaultsCOTAdult.bodySurfaceArea}], filtersValue);
            filtersValue = this.filtersValueService.updateItemInFilters(FilterNameEnum.averageBodyWeight, [{name:FilterDefaultsCOTAdult.averageBodyWeight}], filtersValue);
          }
          break;
      }

    }

    return filtersValue;
  }

  hasFilterValueChanged(filterName: FilterNameEnum, filtersValue: FiltersObject, oldFiltersValue: FiltersObject | undefined = undefined, compareType: boolean = false) {
    if (!oldFiltersValue) {
      oldFiltersValue = this.filtersValueService.filterValues$.value;
    }

    function selectValue(item: FilterItemInterface) {
      return compareType
        ? item.filterValue[0]?.type
        : item.filterValue[0]?.name;
    }
    return (
      selectValue(
        this.filtersValueService.findItemInFilters(filtersValue, filterName)
      ) !==
      selectValue(
        this.filtersValueService.findItemInFilters(oldFiltersValue, filterName)
      )
    );
  }

  hasOneBrand(filtersValue: FiltersObject): boolean {
    return (
      this.filtersValueService.findItemInFilters(
        filtersValue,
        FilterNameEnum.brands
      ).filterValue.length === 1
    );
  }

  isDisabledCalculateParameters(filtersValue: FiltersObject): boolean {
    const filterTypeValue: string = this.filtersValueService.findItemInFilters(
      filtersValue,
      FilterNameEnum.calcPer
    ).filterValue[0].type;
    return !(
      filterTypeValue === FilterMeasuresPeriodEnum.annum ||
      filterTypeValue === FilterMeasuresPeriodEnum.month ||
      filterTypeValue === FilterMeasuresPeriodEnum.day
    );
  }

  checkPermissions(): void {
    this.cachedAccessPermissions.run({ payload: null }).subscribe((data) => {
      this.result = data.payload as [];
      this.showPriceCategoryFilter = this.result.find(
        (val) => val.code === AccessPagesEnum.priceCategoryFilter
      )
        ? true
        : false;
    });
  }
}
