import { Observable, asyncScheduler, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';

import { Assert } from '../assert';

import { Gateway } from '../gateway';

export interface MessageRef {
  payload: unknown;
}

export interface Message {
  type: string;
  payload: unknown;
  error?: boolean;
}

export interface PermissionsResponse {
  code: string;
}

export class AccessPermissions {
  static create(gateway: Gateway): AccessPermissions {
    return new AccessPermissions(gateway);
  }

  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${ gateway } has no "transfer" method`);
  }

  run(event: MessageRef): Observable<Message> {
    return of(event).pipe(
      mergeMap(() => {
        return this.gateway.transfer(event.payload).pipe(
          map((response: unknown) => {
            return {
              type: 'done',
              payload: response
            };
          }),
          catchError((err: any) => {
            return of({
              type: 'fail',
              error: true,
              payload: err
            });
          }),
          startWith({
            type: 'pending',
            payload: []
          }),
          subscribeOn(asyncScheduler)
        );
      })
    );
  }
}
