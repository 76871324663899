import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchConfigurationInput } from '../../core/search-configuration/interfaces';

@Injectable()
export class SearchConfigurationApi {
  constructor(private httpClient: HttpClient) {
  }

  get_config(query: SearchConfigurationInput): Observable<any> {
    return this.httpClient.post<any>('/search/configuration', query);
  }
}
