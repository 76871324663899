import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import {
  DomainResponse,
  ValueDomainsService,
} from '../../../horizon-project/services/value-domains.service';
import {
  ValueAttributeScore,
  ValueAttribute,
  ValueAttributeScoresService,
} from '../../../horizon-project/services/value-attribute-scores.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AssetValueScores } from 'projects/helios-gui/src/app/common/interfaces';
import { HorizonProjectDetailsService } from '../../../horizon-project/services/horizon-project-details.service';
import { HeaderService } from '../../../shared/services/header.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'he-project-asset-tpp',
  templateUrl: './project-asset-tpp.component.html',
  styleUrls: ['./project-asset-tpp.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectAssetTppComponent implements OnInit {
  public valueDomains: DomainResponse[] = [];
  public valueAttributeScores: ValueAttributeScore[] = [];
  public valueAttributes: ValueAttribute[] = [];
  public assetValueScores: AssetValueScores = {
    assetTLIs: [],
    projectId: '',
  };
  public showSection = false;
  public country = '';
  public scenario = '';

  private Valueobj: any;
  private arr: any[] = [];
  projectId: any = '';
  attributesSubscription: Subscription = new Subscription();
  dataSubscription: Subscription = new Subscription();

  constructor(
    private activatedRouter: ActivatedRoute,
    private valueDomainsService: ValueDomainsService,
    private valueAttributeScoresService: ValueAttributeScoresService,
    private projectDetailsService: HorizonProjectDetailsService,
    private headerService: HeaderService
  ) {}
  ngOnInit(): void {
    this.country = this.headerService.getSelectedCountry();

    this.valueDomainsService.getValueDomain().subscribe((data) => {
      this.valueDomains = data;
    });

    this.projectDetailsService.scenario.subscribe((data) => {
      this.scenario = data;
    });

    this.headerService.onCountryChanged.subscribe((country) => {
      this.country = country;
      if (this.dataSubscription) {
        this.attributesSubscription.unsubscribe();
        this.dataSubscription.unsubscribe();
      }
      this.refreshData();
    });

    this.refreshData();
  }

  ageAtDiagnosisChanged(event: any): void {}

  scoreChanged(score: any, valueAttributeName: any): void {
    //  console.log(score + ' ' + valueAttributeName);
  }

  ArrangeCatagory(list: any[]): any {
    const arr2: any = [];
    list.forEach(function(v) {
      const obj: any = {
        valueDomainName: v.valueDomainName,
        valueAttributeName: v.valueAttributeName,
      };
      arr2.push(obj);
    });
    return arr2;
  }

  ArrangeScoreCategory(valueDomainName: string, list: any[]): any {
    const arr2: any = [];
    list.forEach(function(v) {
      const obj: any = {
        country: v.country,
        score: v.score,
        value: v.value,
        valueAttributeName: v.valueAttributeName,
        valueDomainName,
      };
      arr2.push(obj);
    });
    return arr2;
  }

  deduplicate(list: any[]): any {
    if (list.length > 0) {
      const result: any = [];
      for (let x = 0; x < list.length; x++) {
        if (result.indexOf(list[x]) === -1) {
          result.push(list[x]);
        }
      }
      return result;
    }
  }

  groupBy(list: any[], property: string): any {
    const map = new Map();
    list.forEach(function(item) {
      const key = property;
      if (!map.has(key)) {
        map.set(key, [item[key]]);
      } else {
        map.get(key).push(item[key]);
      }
    });
    return map;
  }

  refreshData() {
    this.attributesSubscription = this.valueAttributeScoresService
      .getValueAttributeScores('67eeccc2-19ff-3254-6b32-9fb8334e19e5')
      .subscribe((data) => {
        this.valueAttributeScores = data;
        const unique: any[] = this.ArrangeCatagory(data);

        const Key = 'valueAttributeName';

        const distinctValues: any = [
          ...new Map(unique.map((item) => [item[Key], item])).values(),
        ];
        this.valueAttributes = distinctValues;

      });

    this.dataSubscription = this.valueAttributeScoresService
      .getValueAttributeBaseCaseScores(
        'b6f15875-ba92-4f23-bda7-30e446d40ba2',
        this.country
      )
      .subscribe((data) => {
        this.assetValueScores = data;

        if (this.valueAttributes != null) {
          this.valueAttributes.forEach((domain) => {
            const newData: any = [];

            this.assetValueScores.assetTLIs.forEach((item) => {
              newData.push(
                ...item.valueDomains.filter(
                  (a: { domainName: string }) =>
                    a.domainName === domain.valueDomainName
                )
              );
            });

            if (newData === undefined) {
              return;
            }
            newData.forEach((element: any) => {
              const valueAttributeMappedData = this.ArrangeScoreCategory(
                element.domainName,
                element.valueAttributes
              );
              const Key = 'valueAttributeName';

              const distinctValuesScores: any = [
                ...new Map(
                  valueAttributeMappedData.map((item: { [x: string]: any }) => [
                    item[Key],
                    item,
                  ])
                ).values(),
              ];
              const list: any = distinctValuesScores.filter(
                (a: { valueAttributeName: string; valueDomainName: string }) =>
                  a.valueAttributeName === domain.valueAttributeName &&
                  a.valueDomainName === domain.valueDomainName
              )[0];
              if (list !== undefined && list.score !== undefined) {
                domain.valueScore = list.score;
              }
            });
          });
          this.showSection = true;
        }
      });
  }
}
