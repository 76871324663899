import { Observable, of, asyncScheduler } from 'rxjs';
import { catchError, map, startWith, subscribeOn } from 'rxjs/operators';

import { Assert } from '../assert';
import { Gateway } from '../gateway';

export interface MessageRef {
  payload: unknown;
}

export interface Message {
  type: string;
  payload: unknown;
  error?: boolean;
}

export class ScopeSummary {
  constructor(private gateway: Gateway) {
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${gateway} has no "transfer" method`);
  }

  static create(gateway: Gateway): ScopeSummary {
    return new ScopeSummary(gateway);
  }

  run(): Observable<Message> {
    return this.gateway.transfer(undefined).pipe(
      map((response) => {
        return {
          type: 'done',
          payload: response
        };
      }),
      catchError((err) => {
        return of({
          type: 'fail',
          error: true,
          payload: err
        });
      }),
      startWith({
        type: 'pending',
        payload: undefined
      }),
      subscribeOn(asyncScheduler)
    );
  }
}
