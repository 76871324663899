import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'he-filter-preset-dialog',
  templateUrl: './filter-preset-dialog.component.html',
  styleUrls: ['./filter-preset-dialog.component.scss']
})
export class FilterPresetDialogComponent implements OnInit {

  @Output() confirm: EventEmitter<string> = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Input() text!: string;
  @Input() confirmButtonText: string = 'Save';
  @Input() rejectButtonText: string = 'Cancel';
  @Input() showInput: boolean = false;
  @Input() inputText: string = '';
  presetName: string = ''

  constructor() { }

  ngOnInit(): void {
    this.presetName = this.inputText;
  }

  onKey(event: any) {
    this.presetName = event.target.value;
  }

  onReject(): void {
    this.reject.emit();
  }

  onConfirm(): void {
    this.confirm.emit(this.presetName);
  }

}
