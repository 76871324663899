import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeliosDomainInterceptor } from './domain.interceptor';
import { GlobalErrorHandler } from './error/GlobalErrorHandler';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler, },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: HeliosDomainInterceptor },
  ]
})
export class HeliosApiModule { }
