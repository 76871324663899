import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ScopeSummaryComponent } from './scope-summary.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScopeSummarySplitPipe } from './scope-summary-split.pipe';

@NgModule({
  declarations: [ScopeSummaryComponent, ScopeSummarySplitPipe],
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
  ],
  exports: [ScopeSummaryComponent]
})
export class ScopeSummaryModule {
}
