<h1 mat-dialog-title class="mat-dialog-title">Steps to choose the {{data.routeType}} :</h1>
<div mat-dialog-content>
  <ol class="analogues-helper-list">
    <li>{{data.firstStep}}</li>
    <li>Click on the "Find  {{data.routeType | titlecase}}" button</li>
    <li>Choose the {{data.routeType }} you want to use from the table</li>
    <li>Click on the "Add {{data.routeType | titlecase}}" button</li>
    <li>Select the order in which you want the {{data.routeType}} to be available in the project</li>
  </ol>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>
