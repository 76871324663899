import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'he-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonsComponent {
}
