import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/helios-gui/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetTppService {
  public dynamicValuesScores: any[] = [];

  // requestBody = {
  //   projectId: 'b6f15875-ba92-4f23-bda7-30e446d40ba2',
  //   innName: 'Asset X',
  // };

  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  constructor(private http: HttpClient) {}

  getAssetTppData(requestBody: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.projectAPIUri}/ValueAttributeScore/ValueAttributeScoresByAssetTli`,
          requestBody
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }
}
