<ng-container *ngIf="filtersValueService.filterValues$ | async as filtersValue">
  <div class="pt-3 pl-3 pr-3 pb-0 east-slot">
    <he-filter-by-brand [filtersValue]="filtersValue"
      (filtersValueModified)="filtersValueModified($event)"></he-filter-by-brand>
  </div>
  <div class="pt-3 pl-3 pr-3 pb-0 east-slot" *ngIf="hasOneBrand(filtersValue)">
    <he-filter-compare-with [filtersValue]="filtersValue"
      (filtersValueModified)="filtersValueModified($event)"></he-filter-compare-with>
  </div>
  <div class="pt-3 pl-3 pr-3 pb-0 east-slot">
    <he-filter-by-indication [filtersValue]="filtersValue"
      (filtersValueModified)="filtersValueModified($event)"></he-filter-by-indication>
  </div>
  <div class="pt-3 pl-3 pr-3 pb-0 east-slot">
    <he-filter-by-country [filtersValue]="filtersValue" [hasSaveButton]="hasCountrySaveButton"
      [hasResetButton]="hasCountryResetButton"
      (filtersValueModified)="filtersValueModified($event)"></he-filter-by-country>
  </div>
  <div class="pt-3 pb-0">
    <mat-expansion-panel #measuresMatExpansionPanel [expanded]="measuresPanelExpanded" class="he-expansion-panel-filter">
      <mat-expansion-panel-header>
        <mat-panel-title class="he-additional-text-color text-uppercase font-weight-normal">
          Measures
        </mat-panel-title>
      </mat-expansion-panel-header>
      <he-filter-preset *ngIf="measuresPresetFeatureFlag"
        [presetKeyName]="FilterPresetTypes.Measures"
        [saveConfirmationText]="DialogText.MeasuresPresetSaveMessage"
        [savePresetTooltip]="TooltipText.MeasuresPresetSaveTooltip"
        [deletePresetTooltip]="TooltipText.MeasuresPresetDeleteTooltip"
        (filtersValueModified)="filtersValueModified($event)" >
      </he-filter-preset>
      <div class="mb-2" *hePulseFeatureFlag="COT_LEVEL">
        <he-filter-cot-level [filtersValue]="filtersValue" (filtersValueModified)="filtersValueModified($event)">
        </he-filter-cot-level>
      </div>
      <div class="mb-2">
        <he-filter-measures-period [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-measures-period>
      </div>
      <div class="mb-2">
        <he-filter-measures-show-cot [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event, true)"></he-filter-measures-show-cot>
      </div>
      <div class="mb-2">
        <he-filter-measures-calculate-parameters [disabled]="isDisabledCalculateParameters(filtersValue)"
          [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-measures-calculate-parameters>
      </div>
      <div class="mb-2" *hePulseFeatureFlag="AVERAGE_BODY_WEIGHT">
        <he-filter-measures-average-weight
        [filtersValue]="filtersValue"
        (filtersValueModified)="filtersValueModified($event)"></he-filter-measures-average-weight>
      </div>
      <div class="mb-2" *hePulseFeatureFlag="BODY_SURFACE_AREA">
        <he-filter-body-surface-area
        [filtersValue]="filtersValue"
        (filtersValueModified)="filtersValueModified($event)"></he-filter-body-surface-area>
      </div>
      <div *ngIf="showPriceCategoryFilter" class="mb-2">
        <he-filter-measures-price-category [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-measures-price-category>
      </div>
      <div class="mb-2">
        <he-filter-measures-price-type [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-measures-price-type>
      </div>
      <div class="mb-2">
        <he-filter-measures-currency [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-measures-currency>
      </div>
    </mat-expansion-panel>
  </div>
  <div>
    <mat-expansion-panel expanded="true" class="he-expansion-panel-filter">
      <mat-expansion-panel-header>
        <mat-panel-title class="he-additional-text-color text-uppercase font-weight-normal">
          Product parameters
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mb-2">
        <he-filter-product-params-orphan-status [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-product-params-orphan-status>
      </div>
      <div class="mb-2">
        <he-filter-product-params-branded-status [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-product-params-branded-status>
      </div>
      <div class="mb-2">
        <he-filter-product-params-discontinued [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-product-params-discontinued>
      </div>
      <!-- <div class="mb-2">
        <he-filter-product-params-parallel-import [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-product-params-parallel-import>
      </div> -->
      <div class="mb-2">
        <he-filter-product-params-reimbursement [filtersValue]="filtersValue"
          (filtersValueModified)="filtersValueModified($event)"></he-filter-product-params-reimbursement>
      </div>
    </mat-expansion-panel>
  </div>
</ng-container>
