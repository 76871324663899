import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scopeSummarySplit'
})
export class ScopeSummarySplitPipe implements PipeTransform {

  transform(value: string): any {
    if(!value)
      return value

    let result = {
      therapyAreasHtml: '',
      scopeSummaryHtml: '',
    }

    const headings = {
      therapyAreas: 'Therapy Areas',
      countries: 'Countries'
    }

    const underlineTag = '<u>'
    const underlineCloseTag = '</u>'
    let paragraphs = value.replace(' , ', ', ').split(underlineTag).map(p =>
      p && p.indexOf(underlineCloseTag) !== -1 ? (underlineTag + p) : p)

    for (const paragraph of paragraphs) {
      if(paragraph && paragraph.indexOf(headings.therapyAreas) !== -1) {
        result.therapyAreasHtml = paragraph.replaceAll(/(\w)\/(\w)/g, '$1/<wbr />$2')
      }
      else result.scopeSummaryHtml += paragraph
    }

    return result

  }

}
