import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FiltersValueService } from '../../filters-value.service';
import {
  FilterItemValueInterface,
  FilterNameEnum,
  FiltersObject,
} from '../../interfaces';
import { Subject, takeUntil } from 'rxjs';
import { FilterDefaultsCOTAdult } from '../../constants';

@Component({
  selector: 'he-filter-measures-average-weight',
  templateUrl: './filter-measures-average-weight.component.html',
  styleUrls: ['./filter-measures-average-weight.component.scss'],
})
export class FilterMeasuresAverageWeightComponent implements OnDestroy {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> =
    new EventEmitter();

  sliderValue: number = 0;
  SliderStep: number = 1;
  SliderMinValue: number = 1;
  SliderMaxValue: number = 100;

  private readonly destroying$: Subject<void> = new Subject<void>();

  constructor(public filtersValueService: FiltersValueService) {
    this.subscribeFilters();
  }

  subscribeFilters() {
    return this.filtersValueService.filterValues$
      .pipe(takeUntil(this.destroying$))
      .subscribe((data) => {
        this.sliderValue =
          this.filtersValueService.findItemInFilters(
            data,
            FilterNameEnum.averageBodyWeight
          )?.filterValue[0]?.name ?? FilterDefaultsCOTAdult.averageBodyWeight;
      });
  }

  updateSliderValue(value: number): void {
    this.sliderValue = value;
    this.itemSelectEvent({ name: value });
  }

  itemSelectEvent(item: FilterItemValueInterface): void {
    this.filtersValueModified.emit(
      this.filtersValueService.updateItemInFilters(
        FilterNameEnum.averageBodyWeight,
        [item]
      )
    );
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

}
