<div
  id="sticky-header"
  class="flex-container sticky-header"
>
  <div
    value="project"
    class="text-multiline project-details project-title-top-banner"
    >
    <div style="width: 100%;">
      <p [matTooltip]="project.length > 30?project:''">{{ project }}</p>
      <span class="light-text last-edit">
        Last edit: {{ modifiedOn | date : "dd/MM/yyyy" }}</span
      >
    </div>

  </div>
  <div
    value="tli"
    style=""
    class="field-label text-multiline"
  >
    <div style="text-align: left;width: 100%;">
      <div><span class="light-text tli-font">Top Level indication</span></div>
      <!-- <div> -->
  <p class="tli-font" [matTooltip]="tli.length > 30?tli:''"> {{ tli }} </p>
      <!-- </div> -->
    </div>
  </div>
  <div style="" *ngIf="scenarioVisible" class="scenarios text-multiline horizon-field" >
    <mat-form-field style="width: 100%;"  [matTooltip]="selectedValue.length > 22? selectedValue :''">
      <mat-label>Scenario</mat-label>
      <mat-select
        name="scenario"
        [(value)]="selectedValue"
        (selectionChange)="onScenerioChange($event)"
      >
        <mat-option *ngFor="let scenario of scenarios" [value]="scenario.value">
          {{ scenario.viewValue }}
          <!-- <span style="color: #0F699C;">{{ scenario.viewValue.split(":")[0]}}</span>
          <span style="font-weight: 500;">:</span>  
          <span>{{ scenario.viewValue.split(":")[1]}}</span>  -->
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <p>
      Selected Value: {{selectedValue}}
    </p> -->
  </div>
  <div style="" *ngIf="countryVisible" class="countries text-multiline horizon-field">
    <mat-form-field style="width: 100%;">
      <mat-label>Country</mat-label>
      <mat-select
        name="countries"
        [(value)]="selectedCountry"
        (selectionChange)="onCountryChange($event)"
      >
        <mat-option *ngFor="let country of countries" [value]="country.value">
          <he-country-cell country="{{ country.viewValue }}"></he-country-cell>
          <!-- {{ country.viewValue }} -->
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <p>
      Selected Value: {{selectedCountry}}
    </p> -->
  </div>

  <div style="width: 20%" class="download" *heFeatureFlag="{Name:'nav-download-btn',Page:'common'}">
    <button
      mat-icon-button
      class="he-icon-button"
      title="Export visible elements"
    >
      <he-icon [id]="'download-element'"></he-icon>
    </button>
  </div>

  <div style="background-color: unset;" class="currency-change" *heFeatureFlag="{Name:'currency-btn',Page:'common'}">
    <button mat-button [matMenuTriggerFor]="menu" class="currencyBtn">
      {{ selectedCurrency }}
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let currency of currencies" >

      <button
        mat-menu-item
        *ngIf="currency.display"
        (click)="onMenuSelectCurrency(currency)" class="currencyMenu"
      >
        <!-- <span><he-country-cell country="{{ currency.country }}"></he-country-cell></span> -->
        <span><img
          class="flag"
          src="{{
            'assets/flags/' + getFlagId(currency.currency) + '.svg'
          }}"
          width="25px"
        /></span>
        <span class="currencyValue">{{ currency.value }}</span>
        <span class="currencyViewValue">{{ currency.viewValue }}</span>

      </button>
    </ng-container>

    </mat-menu>
  </div>

  <div style="" class="edit" *ngIf="isEditProject">
    <a
    routerLinkActive="list-item-active"
    routerLink="/horizon/edit-project/{{ projectId }}"
    id="edit-project-btn"
  >
    <button mat-button class="horizon-mat-button-filled" (click)="setProjectId(projectId)" >Edit Project</button>
  </a>
  </div>
</div>
