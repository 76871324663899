import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { HeaderService } from '../../../../shared/services/header.service';
import { ValueScoringService } from '../../services/value-scoring.service';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { ECharts } from 'echarts';
import { Subject, takeUntil } from 'rxjs';

export interface ValueScoring {
  [key: string]: string | number | boolean;
}

@Component({
  selector: 'he-project-value-scoring-sub-grid',
  templateUrl: './project-value-scoring-sub-grid.component.html',
  styleUrls: ['./project-value-scoring-sub-grid.component.scss'],
  // encapsulation: ViewEncapsulation.,
})
export class ProjectValueScoringSubGridComponent {
  @ViewChild(MatTableExporterDirective)
  exporter?: MatTableExporterDirective;
  @ViewChild(MatPaginator)
  @Output()
  chartInstance = new EventEmitter<any>();
  @Output() recordsCountEvent = new EventEmitter<number>(true);
  @Input() public viewMode = 'AbsoluteValue';
  @Input() public dataSource = new MatTableDataSource([{}]);
  @Input() public displayedColumns: string[] = ['brandedInnName'];
  @Input() public countries: string[] = [];
  @Input() public analoguesData: any[] = [];

  private unsubscriber$ = new Subject<void>();

  constructor() {}

  downloadData() {
    this.exporter?.exportTable('csv', {
      fileName: 'Total Value Scoring By Countries Grid',
    });
  }
}
