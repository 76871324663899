import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterProductParamsReimbursementComponent } from './filter-product-params-reimbursement.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterProductParamsReimbursementComponent],
  exports: [FilterProductParamsReimbursementComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
})
export class FilterProductParamsReimbursementModule {
}
