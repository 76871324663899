import { Injectable } from '@angular/core';

@Injectable()
export class ProjectPanels {
  animation = {
    enabled: false
  };

  west = {
    visibility: true
  };

  middleWest = {
    visibility: true
  };

  middle = {
    visibility: true
  };

  east = {
    visibility: false
  };

  onToggleWest(): void {
    this.west.visibility = !this.west.visibility;
  }

  onToggleMiddleWest(): void {
    this.middleWest.visibility = !this.middleWest.visibility;
  }

  onToggleEast(): void {
    this.east.visibility = !this.east.visibility;
  }

  onDisableAnimation(): void {
    this.animation.enabled = false;
  }

  onEnableAnimation(): void {
    this.animation.enabled = true;
  }
}
