<div *ngIf="value.NetPriceDiscount !== 'NA'">
  <div class="per-analogue-input" style="flex-direction: column;" *ngIf="!isAnnualCOTInlcuded">
    <div style="display: flex;">
      <mat-form-field
      class="mat-form-field"
      appearance="fill"
      style="width: 77px;height: 38px;"
      [ngClass]="{ 'disable-Input': !readonly }"
    >
      <input
        matInput
        [readonly]="!readonly"
        placeholder="0.00"
        type="number"
        min="0"
        max="100"
        step="0.10"
        heOnlyNumber
        [formControl]="percentage"
        [(ngModel)]="value.NetPriceDiscount"
        [value]="value.NetPriceDiscount"
        (change)="changePrice()"
      />
    </mat-form-field>
      <p> %</p>
    </div>
    <mat-error style="width: 152px;">
      {{errorPer}}
    </mat-error>

  </div>
  <div class="per-analogue-input" style="flex-direction: column;" *ngIf="isAnnualCOTInlcuded">
    <div style="display: flex;">
      <p > {{currency}}</p>
      <mat-form-field
      class="mat-form-field"
      appearance="fill"
      style="width: 77px;height: 38px;"
      [ngClass]="{ 'disable-Input': !readonly }"
    >
      <input
        matInput
        [readonly]="!readonly"
        placeholder="0"
        type="number"
        min="1"
        heOnlyNumber
        [formControl]="cot"
        [(ngModel)]="value.AnalogueCOT"
        [value]="value.AnalogueCOT"
        (keyup)="changePrice()"
      />
    </mat-form-field>
    </div>
  <mat-error style="margin-left: 23px;width: 123px;">
    {{error}}
  </mat-error>
  </div>
</div>
<div *ngIf="value.NetPriceDiscount === 'NA'"     [ngClass]="isAnnualCOTInlcuded ? 'cot' : 'dicount'">
  <p>NA</p>
</div>

