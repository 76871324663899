import { asyncScheduler, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';

import { Assert } from '../assert';
import { CoTHistoryInputInterface, CoTHistoryOutputMessage } from './interfaces';

import { Gateway } from './gateway';

export class CoTHistory {
  constructor(private gateway: Gateway) {
    Assert.hasMethod(gateway, 'call', `Injected gateway ${gateway} has no "call" method`);
  }

  run(event: CoTHistoryInputInterface): Observable<CoTHistoryOutputMessage> {
    return of(event).pipe(
      mergeMap(() => {
        return this.gateway.call(event.payload).pipe(
          map((response) => {
            return {
              type: 'done',
              dataset: response
            };
          }),
          catchError((err) => {
            return of({
              type: 'fail',
              error: true,
              dataset: err
            });
          }),
          startWith({
            type: 'pending',
            dataset: undefined
          }),
          subscribeOn(asyncScheduler)
        );
      })
    );
  }
}
