import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightTextComponent } from './highlight-text.component';
import { SplitPipe } from './split.pipe';

@NgModule({
  declarations: [HighlightTextComponent, SplitPipe],
  exports: [HighlightTextComponent],
  imports: [
    CommonModule,
  ]
})
export class HighlightTextModule {
}
