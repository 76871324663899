import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[heInfoPanel]'
})
export class InfoPanelDirective {

  constructor(public template: TemplateRef<any>) {
  }

}
