<div class="analogue-card expand-criteria">
  <mat-accordion class="headers-align" multi>
    <mat-expansion-panel [expanded]="firstPanelOpen">
      <mat-expansion-panel-header>
        <mat-panel-title> NET PRICE INCLUSION </mat-panel-title>
        <mat-panel-description>
          <!-- <mat-icon>account_circle</mat-icon> -->
          <div class="reset-btn">
            <button mat-icon-button (click)="$event.stopPropagation(); reset()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <p> 
          Select if net prices should be included in the project. If excluded,
          then these will not be displayed.
        </p>
      </div>
      <div class="d-flex d-align-c margin-padding">
        <he-price-inclusion
          [IsNetPriceIncluded]="ProjectNetPrice.IsNetPriceIncluded"
          (updatePriceInclusion)="updatePriceInclusion($event)"
        ></he-price-inclusion>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="ProjectNetPrice.IsNetPriceIncluded">
      <mat-expansion-panel-header>
        <mat-panel-title> OVERALL NET PRICE ASSUMPTIONS </mat-panel-title>
        <mat-panel-description>
          <!-- <mat-icon>account_circle</mat-icon> -->
          <div class="reset-btn">
            <button
              mat-icon-button
              (click)="$event.stopPropagation(); resetOverall()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <p>
          Enter overall net price discounts that will be applied per market.
        </p>
      </div>
      <div class="d-flex margin-padding flex-cloumn">
        <div *ngFor="let market of ProjectNetPrice.OverallNetPrice">
          <he-overall-price-assumption
            [market]="market"
            [OverallNetPrice]="ProjectNetPrice.OverallNetPrice"
            [readOnly]="ProjectNetPrice.IsNetPriceIncluded && !ProjectNetPrice.isAnnualCOTInlcuded"
            (updatePrice)="setPrice(false)"
          ></he-overall-price-assumption>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="ProjectNetPrice.IsNetPriceIncluded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{selectedRoute}}-SPECIFIC NET PRICE ASSUMPTIONS
        </mat-panel-title>
        <mat-panel-description>
          <!-- <mat-icon>account_circle</mat-icon> -->
          <div class="reset-btn">
            <button
              mat-icon-button
              (click)="$event.stopPropagation(); resetAnalogue(true)"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <p>
          Enter specific assumptions per {{selectedRoute}} that will be applied. Select
          to enter these as percentage discounts from list price or as an annual
          cost of treatment. 
        </p>
        <p>Note: these assumptions will override the overall
          market assumptions previously entered.</p>
      </div>
      <div *ngIf="ProjectNetPrice.IsNetPriceIncluded">
        <div class="custom-toogle d-flex d-j-c">
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="ProjectNetPrice.isAnnualCOTInlcuded">
              <mat-button-toggle [value]="false" (click)="selectToggle(false)">Discount</mat-button-toggle>
              <mat-button-toggle [value]="true" (click)="selectToggle(true)">Annual COT</mat-button-toggle>
            </mat-button-toggle-group>
      </div>
      
        <div class="d-flex margin-padding flex-cloumn">
          <he-specific-price-assumption [ProjectNetPrice]="ProjectNetPrice" (emitUpdatePrice)="netPriceChanges()"></he-specific-price-assumption>
        </div>
      </div>
      <div class="sub-header">
  
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
