<div class="row no-gutters flex-column flex-nowrap h-100">
  <div class="col flex-grow-0 layer-z2 p-4">
    <div class="container">
      <div class="row no-gutters flex-nowrap align-items-center">
        <h1>{{ message }}</h1>
      </div>
      <div class="row no-gutters flex-nowrap align-items-center">
        <h3>Please contact <a href="mailto:support@globalpricing.com" tabindex="-1">support@globalpricing.com</a> for
          more information</h3>
      </div>
      <div class="row no-gutters flex-nowrap align-items-center pt-1">
        <button mat-button class="horizon-mat-button-filled m-0" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>

</div>
