import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IIrpReferencedModel } from '../../app/common';

@Injectable()
export class IrpMapApi {
  constructor(private httpClient: HttpClient) {
  }

  transfer(req: unknown): Observable<IIrpReferencedModel[]> {
    if (!Array.isArray(req)) {
      throw new Error('IrpMapApi expects Array as an input. Instead got: ' + typeof req);
    }
    const query = req.join(',');
    return this.httpClient.get<IIrpReferencedModel[]>('/irp/refs?countries=' + query);
  }
}
