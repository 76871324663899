import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FilterItemValueInterface,
  FilterNameEnum,
  FiltersObject,
} from '../../interfaces';
import { Subject, takeUntil } from 'rxjs';
import { FiltersValueService } from '../../filters-value.service';
import { FilterDefaultsCOTAdult } from '../../constants';

@Component({
  selector: 'he-filter-body-surface-area',
  templateUrl: './filter-body-surface-area.component.html',
  styleUrls: ['./filter-body-surface-area.component.scss'],
})
export class FilterBodySurfaceAreaComponent implements OnDestroy {

  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> =
    new EventEmitter();

  sliderValue: number = 0;
  SliderStep: number = 0.01;
  SliderMinValue: number = 0.1;
  SliderMaxValue: number = 2.0;
  DecimalDigit: number = 2;

  private readonly destroying$: Subject<void> = new Subject<void>();

  constructor(public filtersValueService: FiltersValueService) {
    this.subscribeFilters();
  }

  subscribeFilters() {
    return this.filtersValueService.filterValues$
      .pipe(takeUntil(this.destroying$))
      .subscribe((data) => {
        this.sliderValue =
          this.filtersValueService.findItemInFilters(
            data,
            FilterNameEnum.bodySurfaceArea
          )?.filterValue[0]?.name ?? FilterDefaultsCOTAdult.bodySurfaceArea;
      });
  }

  updateSliderValue(value: number): void {
    this.sliderValue = value;
    this.itemSelectEvent({ name: Math.round(value * 100) / 100 });
  }

  itemSelectEvent(item: FilterItemValueInterface): void {
    this.filtersValueModified.emit(
      this.filtersValueService.updateItemInFilters(
        FilterNameEnum.bodySurfaceArea,
        [item]
      )
    );
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }


}
