import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatThemeCoreComponent } from './mat-theme-core.component';

@NgModule({
  declarations: [MatThemeCoreComponent],
  exports: [MatThemeCoreComponent],
  imports: [
    CommonModule
  ]
})
export class MatThemeCoreModule { }
