<button class="he-ava-btn he-btn-themed" aria-label="Help" mat-mini-fab [matMenuTriggerFor]="helpmenu">
    <he-icon title="Help" id="suggested-circle-question-mark"></he-icon>
</button>

<mat-menu #helpmenu="matMenu" class="no-min-height he-menu-panel">
    <button mat-menu-item class="he-btn-menu" (click)="gotoKnowledgeBase('https://info.globalpricing.com/_hcms/mem/login?utm_medium=pulse2.0&utm_content=0&utm_source=pulse2.0')">
        <he-icon id="database"></he-icon>
        <span>Knowledge Base</span>
    </button>
    <button mat-menu-item class="he-btn-menu" (click)="gotoFaq('/faq')">
        <he-icon id="suggested-circle-question-mark"></he-icon>
        <span>FAQs</span>
    </button>
</mat-menu>