import { Observable, of, throwError, asyncScheduler } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';
import { Assert } from '../assert';
import { Gateway } from './gateway';
import { PackPriceInputInterface , PackPriceOutputMessage  } from './interfaces';


export class PackPrice {
  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${gateway} has no "call" method`);
  }

  static create(gateway: Gateway): PackPrice {
    return new PackPrice(gateway);
  }

  run(event: PackPriceInputInterface): Observable<PackPriceOutputMessage> {
        return this.gateway.transfer(event.payload).pipe(
          map((response) => {
            return {
              type: 'done',
              payload: response
            };
          }),
          catchError((err) => {
            return of({
              type: 'fail',
              error: true,
              payload: err
            });
          }),
          startWith({
            type: 'pending',
            payload: undefined
          }),
          subscribeOn(asyncScheduler)
        );
  }
}
