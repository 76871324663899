import { LoadingSpinnerModule } from 'projects/helios-gui/src/uikit/loading-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridCommonComponent } from './grid-common.component';
import { AgGridModule } from 'ag-grid-angular';
import { DataGridComponent } from './data-grid.component';
import { DotCellComponent } from './dot-cell/dot-cell.component';
import { DotCellDecisionComponent } from './dot-cell-decision/dot-cell-decision.component';
import { ColorCellComponent } from './color-cell/color-cell.component';
import { ReimbursementCellComponent } from './reimbursement-cell/reimbursement-cell.component';
import { LinkCellComponent } from './link-cell/link-cell.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { LegendComponent } from './legend/legend.component';
import { GridFreezeHeaderDirective } from './grid-freeze-header/grid-freeze-header.directive';
import { MatButtonModule } from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  declarations: [GridCommonComponent, DataGridComponent, DotCellComponent, DotCellDecisionComponent,
    ColorCellComponent, ReimbursementCellComponent, LinkCellComponent, CustomTooltipComponent,
    LegendComponent,
    GridFreezeHeaderDirective],
  exports: [GridCommonComponent, DataGridComponent, ReimbursementCellComponent, DotCellComponent,
    DotCellDecisionComponent, ColorCellComponent, LinkCellComponent, CustomTooltipComponent,
    LegendComponent, GridFreezeHeaderDirective],
  imports: [
    CommonModule,
    MatButtonModule,
    LoadingSpinnerModule,
    // Since v28 the AgGridModule no longer needs the .withComponents() method as all user applications will now be using Ivy. The method used to handle registering custom components as EntryComponents but that is no longer required with Ivy and was deprecated by Angular.
    AgGridModule,
    MatPaginatorModule,
  ]
})
export class GridCommonModule { }
