import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectLoadService {

  public apiCalls:number=0;
  loading:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);

  setLoading(state:boolean):void{
    this.loading.next(state);
  }

  getLoading():BehaviorSubject<boolean>{
    return this.loading;
  }

}
