import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RedirectByUrlComponent } from './redirect-by-url.component';

@NgModule({
  declarations: [RedirectByUrlComponent],
  exports: [RedirectByUrlComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class RedirectByUrlModule {
}
