import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { HeaderService } from '../../../../shared/services/header.service';
import { ProjectService } from '../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { AnalogueHelperDialogComponent } from '../analogur-helper-dialog/analogue-helper-dialog/analogue-helper-dialog.component';
import { UpdateFrameworkService } from '../../../../shared/services/update-framework.service';

@Component({
  selector: 'he-project-topbar', 
  templateUrl: './project-topbar.component.html',
  styleUrls: ['./project-topbar.component.scss'],
})
export class ProjectTopbarComponent implements OnInit,OnDestroy {
  @Input() public title:string = '';
  @Input() public routeType:string = '';
  @Input() public firstStep:string = '';
  @Input() public helpVisible:boolean = false;

  public url: any = '';
  private unsubscriber$ = new Subject<void>();
  public updatedValueDomains: any = [];
  public selectedScenarioIndex = 0;
  public scenarios: any = [];
  public createProjectDisabled: any = true;
  public editProjectId: any = '';
  public valueChangeStatus: any = false;
  public isSaveScenarioChanges: boolean = true;
  public isCloneScenario: boolean = true;
  private currentScenarioName = '';

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private headerservice: HeaderService,
    public dialog: MatDialog,
    public updateFrameworkService: UpdateFrameworkService,
  ) {
    this.activatedRoute.url.pipe(takeUntil(this.unsubscriber$)).subscribe(activeUrl => {
      this.url = window.location.pathname;
    });
  }
  ngOnInit(): void {
    this.isScenarioRoute();
    this.projectService.castValueChangeStatus
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.valueChangeStatus = res;
      });
    this.projectService.castScenarioStatus.pipe(takeUntil(this.unsubscriber$)).subscribe((data: any) => {
      this.projectService.castDuplicateScenarioName.pipe(take(1)).subscribe((value: any) => {
        this.checkFormValid(value,data);
      })

    });
    this.projectService.castDuplicateScenarioName.pipe(takeUntil(this.unsubscriber$)).subscribe((data: any) => {
      this.projectService.castScenarioStatus.pipe(take(1)).subscribe((value: any) => {
        this.checkFormValid(value,data);
      })
    });
    this.CheckUserPermission();


    this.projectService.castselectedScenarioIndex
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.selectedScenarioIndex = res;
      });

  }

  private CheckUserPermission() {
    this.projectService.castScenarioName
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((name) => {
        this.currentScenarioName = name;
      });

    this.projectService.editProjectId
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((id) => {
        if (id) {
          this.editProjectId = id
          const userProjectPermissions =
            this.headerservice.getUserProjectPermission();

          const currentProjectPermission = userProjectPermissions.find(
            (f) => f.projectId === id
          );

          if (currentProjectPermission) {

            const currentScenarioPermission =
              currentProjectPermission.permissions.scenarios.find(
                (f) => f.name === this.currentScenarioName
              );

            if (currentScenarioPermission) {
              this.isSaveScenarioChanges = currentScenarioPermission.save;
              this.isCloneScenario = currentScenarioPermission.clone;
            }
          }
        }
      });
  }

  public checkFormValid(check1:boolean,check2:boolean):void{
    if (check1 == true) {
      this.createProjectDisabled = check1;
    }
    else {
      if (!this.valueChangeStatus) {
        this.createProjectDisabled = true;
      }else{
        this.createProjectDisabled = check2;
      }
    }
  }


  isScenarioRoute() {
    return (
      this.url.includes('/add-scenario') || this.url.includes('/edit-scenario')
    );
  }
  isAnalogueRoute() {
    return (
      this.url.includes('/analogue-selection')
    );
  }
  
  openDialog(): void {
    this.dialog.open(AnalogueHelperDialogComponent, {
      width: '454px',
      data: {routeType:this.routeType,firstStep:this.firstStep },
      panelClass: 'analogue-helper-dialog',
      backdropClass: 'analogue-helper-backdrop',
    });
  }

  isEditScenarioRoute() {
    return this.url.includes('/edit-scenario');
  }
  isCreateScenarioRoute() {
    return this.url.includes('/add-scenario');
  }

  copyScenario() {
    this.projectService.copyScenario();
    this.projectService.scenarioStatus.next(true);
    this.projectService.castselectedScenarioIndex
      .pipe(take(1))
      .subscribe((data) => {
        this.selectedScenarioIndex = data;
        const scenarioAttributes = this.projectService.getScenarioAttributes(
          this.selectedScenarioIndex
        );
        this.updatedValueDomains = JSON.parse(
          JSON.stringify(scenarioAttributes)
        );
        this.setCurrentValueAttributeScores();
      });
    this.projectService.scenarioStatus.next(false);
    this.projectService.isScenariosNamesValid = false;
    //silrang
    this.projectService.checkScenarioStatus(true,false);

  }

  setCurrentValueAttributeScores() {
    this.updatedValueDomains.forEach((valueDomain: any) => {
      valueDomain.countries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.selectedScore !== '') {
            this.setAtrributeScore(
              valueAttribute.selectedScore,
              country.name,
              valueAttribute.name,
              false
            );
          } else {
            valueAttribute.selectedScore = '';
            this.setAtrributeScore(
              valueAttribute.selectedScore,
              country.name,
              valueAttribute.name,
              false
            );
          }
        });
      });
      valueDomain.allCountries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          if (valueAttribute.selectedScore !== '') {
            this.setAtrributeScore(
              valueAttribute.selectedScore,
              country.name,
              valueAttribute.name,
              true
            );
          } else {
            valueAttribute.selectedScore = '';
            this.setAtrributeScore(
              valueAttribute.selectedScore,
              country.name,
              valueAttribute.name,
              true
            );
          }
        });
      });
    });
    this.updateScenarioAttribtues();
  }

  setAtrributeScore(score: any, country: string, attribute: string,allmarket:boolean) {
    this.updatedValueDomains.forEach((valueDomain: any) => {
      if(!allmarket){
        valueDomain.countries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            if (valueAttribute.name === attribute && contry.name === country) {
              if (score != null  && score != "") {
                valueAttribute.selectedScore = JSON.parse(JSON.stringify(valueAttribute.score.find(
                  (x: any) => x.score === score.score
                )));
              } else {
                valueAttribute.selectedScore = '';
              }
            }
          });
        });
      }else{
        valueDomain.allCountries.forEach((contry: any) => {
          contry.valueAttributes.forEach((valueAttribute: any) => {
            if (valueAttribute.name === attribute && contry.name === country) {
              if (score != null && score != "") {
                valueAttribute.selectedScore = JSON.parse(JSON.stringify(valueAttribute.score.find(
                  (x: any) => x.score === score.score
                )));
              } else {
                valueAttribute.selectedScore = '';
              }
            }
          });
        });
      }


    });
  }

  updateScenarioAttribtues(): void {
    this.scenarios = this.projectService.getScenario();
    this.projectService.setScenarioAtributes(
      this.scenarios.length,
      this.updatedValueDomains
    );
    this.projectService.selectedScenarioIndex.next(this.scenarios.length);
    const queryParams: Params = { scenario: this.scenarios.length };
    let link = this.editProjectId != '' ? '/horizon/edit-scenario' : '/horizon/add-scenario'
    this.router.navigate([link], { queryParams: queryParams })
  }

  editProject(): void {
    this.projectService.editProject(this.editProjectId, false);
  }

  resetEditScenario(): void {
    if (this.projectService.scenarios[this.selectedScenarioIndex - 1].copiedId > 0) {
      this.projectService.scenarios[this.selectedScenarioIndex - 1].ValueAttributeScores =
        this.projectService.scenarios[this.projectService.scenarios[this.selectedScenarioIndex - 1].copiedId - 1].ValueAttributeScores;
    } else {
      if (!this.projectService.scenarios[this.selectedScenarioIndex - 1].newScenario &&
        this.projectService.scenarios[this.selectedScenarioIndex - 1].copiedId < 0) {
        this.projectService.scenarios[this.selectedScenarioIndex - 1].ValueAttributeScores =
          this.projectService.scenarios[this.selectedScenarioIndex - 1].ValueAttributeScores;
      } else {
        const scenarioAttributes = this.projectService.getScenarioAttributes(
          this.selectedScenarioIndex
        );
        this.updatedValueDomains = JSON.parse(
          JSON.stringify(scenarioAttributes)
        );
        this.resetEmptyValueAttributeScores()
      }
    }
    this.projectService.selectedScenarioIndex.next(this.selectedScenarioIndex);
    this.projectService.valueChangeStatus.next(false);
  }

  public resetEmptyValueAttributeScores(): void {
    this.updatedValueDomains.forEach((valueDomain: any) => {
      valueDomain.countries.forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          valueAttribute.selectedScore = '';
          this.setAtrributeScore(
            valueAttribute.selectedScore,
            country.name,
            valueAttribute.name,
            false
          );
        });
      });      
      valueDomain.allCountries
      .forEach((country: any) => {
        country.valueAttributes.forEach((valueAttribute: any) => {
          valueAttribute.selectedScore = '';
          this.setAtrributeScore(
            valueAttribute.selectedScore,
            country.name,
            valueAttribute.name,
            true
          );
        });
      });
    });
    this.projectService.setScenarioAtributes(
      this.selectedScenarioIndex,
      this.updatedValueDomains
    );
  }
  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
