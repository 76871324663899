import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterAutocompleteDescriptionInterface, FilteredGroupItemsInterface } from './interfaces';
import { FilterItemValueInterface } from '../../app/datastore/interfaces';

@Component({
  selector: 'he-filter-autocomplete-with-chips',
  templateUrl: './filter-autocomplete-with-chips.component.html',
  styleUrls: ['./filter-autocomplete-with-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterAutocompleteWithChipsComponent {
  @Input() descriptions: FilterAutocompleteDescriptionInterface | undefined;
  @Input() selectedItems: FilterItemValueInterface[] = [];
  @Input() filteredItems$: Observable<FilterItemValueInterface[]> | undefined;
  @Input() filteredGroupItems$: Observable<FilteredGroupItemsInterface[]> | undefined;
  @Input() loading: boolean | undefined;
  @Input() showErrorIfEmpty = true;
  @Input() isGroupView!: boolean;
  @Input() disabled: boolean | null = null;
  @Input() disabledTooltip: string | undefined;
  @Input() isBrandChipEnable!:boolean;
  @Output() selectedItemChanges: EventEmitter<FilterItemValueInterface> = new EventEmitter();
  @Output() deletedItemChanges: EventEmitter<FilterItemValueInterface> = new EventEmitter();
  @Output() callFilterFunction: EventEmitter<string> = new EventEmitter();
 
  constructor() {
  }
}
