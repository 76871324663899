import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import {  GridApi, GridOptions } from 'ag-grid-community';
import { GridSizeModeInterface } from 'projects/helios-gui/src/uikit/grid-common/grid-interfaces';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'he-general-info-grid',
  templateUrl: './general-info-grid.component.html',
  styleUrls: ['./general-info-grid.component.scss']
})
export class GeneralInfoGridComponent implements OnInit,OnDestroy  {

  private readonly destroying$: Subject<void> = new Subject<void>();
  @Input() dataset: any;
  @Output() gridReady = new EventEmitter();
  selectedbrandname: string = '';
  gridOptions: GridOptions = {
    debounceVerticalScrollbar: true,
    animateRows: false,
    suppressAnimationFrame: true,
  };

  rowData: any[] = [];
  mappedColumns = [
    { headerName: 'INN', field: 'innName' },
    { headerName: 'Company', field: 'company' },
    { headerName: 'Initial launch date', field: 'initLaunchDate' },
    { headerName: 'Brand category', field: 'brandCategory' },
    { headerName: 'Therapy class', field: 'therapyClass' },
    { headerName: 'WHOATC', field: 'whoatc' },
    { headerName: 'Mechanism of action', field: 'moa' },
  ];

  columnDefs: any[] = [];
  sizemode: GridSizeModeInterface = 'autofit';
  pinnedColumn:string='';

  constructor(private sharedService:SharedService) { }

  ngOnInit(): void {
    this.sharedService.brandFilterSelectedBrand$.pipe(
      takeUntil(this.destroying$),
    ).subscribe(brand => {
      this.initialLogic(brand);
    });

     // Subscribe to the gridReady event
  this.gridReady.pipe(
    takeUntil(this.destroying$),
  ).subscribe(() => {
       const gridApi: GridApi = this.gridOptions.api!;
            const columnApi = this.gridOptions.columnApi!;

            //uinpin existing column
            if(this.pinnedColumn)
            {
            const brandedNameColumnIndexUnpin = this.columnDefs.findIndex(
              (columnDef) => columnDef.headerName === this.pinnedColumn
            );
            if (brandedNameColumnIndexUnpin !== -1) {
              const gridApi: GridApi = this.gridOptions.api!;
              const columnApi = this.gridOptions.columnApi!;
              const brandedNameColumn = this.columnDefs[brandedNameColumnIndexUnpin];
              brandedNameColumn.pinned = 'none';                
             }
            }
            //
              const brandedNameColumnIndex = this.columnDefs.findIndex(
                (columnDef) => columnDef.headerName === this.selectedbrandname
              );
              if (brandedNameColumnIndex !== -1) {
                const gridApi: GridApi = this.gridOptions.api!;
                const columnApi = this.gridOptions.columnApi!;
                const brandedNameColumn = this.columnDefs[brandedNameColumnIndex];
                brandedNameColumn.pinned = 'left';                
               }
               this.pinnedColumn=this.selectedbrandname;
  });
  }

  initialLogic(brand:string)
  {
    this.selectedbrandname=brand;
    if (this.dataset) {
      if (this.dataset.length === 1) {
        this.sizemode = 'autofit';
      } else {
        this.sizemode = 'none';
      }

      this.columnDefs = [
        {
          headerName: 'Branded name',
          field: 'colName',
          width: 160,
          suppressSizeToFit: true,
          pinned: 'left',
          cellStyle: { color: '#92A1B9' },
          headerClass: 'tableHeader',
          sortable: false,
        },
        ...this.dataset.map((brand: any) => {
          const brandName = brand['brandedName'];
          const isHighlighted = brandName === this.selectedbrandname;

          return {
            headerName: brandName,
            field: brandName.toLowerCase().replace('.', ''),
            suppressSizeToFit: true,
            width: 320,
            wrapText: true,
            autoHeight: true,
            sortable: false,
            cellStyle: isHighlighted ? { color: 'black', backgroundColor: '#e0e4eb' } : { color: 'black' },
          };
        })
      ];

      this.rowData = this.mappedColumns.map((col: any) => {
        const colName = col.field;
        const colTitle = col.headerName;
        const brandValues: { [key: string]: string } = {
          colName: colTitle,
        };

        this.dataset.forEach((brand: { [x: string]: string; brandedName: string }) => {
          brandValues[brand.brandedName.toLowerCase().replace('.', '')] = colName === 'initLaunchDate'
            ? brand[colName] === null
              ? ''
              : formatDate(brand[colName], 'dd/MM/yyyy', 'en_GB')
            : brand[colName];
        });
        return brandValues;
      });
      this.gridReady.emit();
    }
    
  }
  saveGridApi(ev: GridApi): void {
    this.gridReady.emit(ev);
  }

  ngOnDestroy(): void {

    this.destroying$.next(undefined);

    this.destroying$.complete();

  }
}
