import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'he-grid-styles',
  templateUrl: './grid-styles.component.html',
  styleUrls: ['./grid-styles.component.scss']
})
export class GridStylesComponent {
}
