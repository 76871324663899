<div *ngIf="!showCotgridSection">
  <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
</div>
<div *ngIf="showCotgridSection" class="table-responsive">
  <table mat-table
    [dataSource]="dataSource"
    matTableExporter
    #exporter="matTableExporter"
  >
    <!-- Branded INN Name Column -->
    <ng-container matColumnDef="brandedInnName" sticky>
      <th mat-header-cell *matHeaderCellDef class="brandedInnName">
        Brand Name
      </th>
     <td mat-cell *matCellDef="let element" class="brandedInnNameRow">
        {{ element.brandedInnName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inn">
      <th mat-header-cell *matHeaderCellDef class="colInn" >
        INN
      </th>
     <td mat-cell *matCellDef="let element" class="rowInnName" 
     [matTooltip]="element.inn"
     matTooltipClass="horizon-tooltip">
        <div class="horizon-text-ellipsis">{{ element.inn }}</div>
      </td>
    </ng-container>

    <!-- indicationHTA -->
     <ng-container matColumnDef="indicationHTA" *ngIf="this.headerService.IsOncologyFrameworkSelected">
      <th mat-header-cell *matHeaderCellDef  style="flex-grow: 2" class="colGapLeft"  >
        HTA Indication
      </th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.indicationHTA" matTooltipClass="horizon-tooltip" style="flex-grow: 2;" class="rowGapLeftNone indicationHTAEllipsis" >
          <div>{{ element.indicationHTA }}</div>
      </td>
      </ng-container>
      
 <!-- indicationHTA -->

    <!-- Initial Launch Date Column -->
    <ng-container matColumnDef="initialLaunchDate" >
      <th mat-header-cell *matHeaderCellDef class="colGapLeft">
        Initial Launch Date
      </th>
     <td mat-cell *matCellDef="let element" class="rowGapLeftNone">
        {{
          element.initialLaunchDate === null
            ? "NA"
            :element.initialLaunchDate
        }}
      </td>
    </ng-container>

    <!-- Indication Column -->
    <ng-container matColumnDef="indication" sticky>
     <th mat-header-cell *matHeaderCellDef> Indication </th>
     <td mat-cell *matCellDef="let element" class="rowGapLeftNone">
        {{ element.indication }}
      </td>
    </ng-container>

    <!-- Macenism of Action Column -->
    <ng-container matColumnDef="macenismOfAction">
     <th mat-header-cell
        *matHeaderCellDef
        style="flex-grow: 2"
        class="colGapLeft"
      >
        Mechanism of Action
      </th>
     <td mat-cell
        *matCellDef="let element"
        style="flex-grow: 2;"
        class="rowGapLeftNone"
        [matTooltip]="element.macenismOfAction"
        matTooltipClass="horizon-tooltip"
      >
      <div class="horizon-text-ellipsis">{{ element.macenismOfAction }}</div>
        
      </td>
    </ng-container>
    <!-- for dynamic markets -->
    <ng-container *ngFor="let country of countriesInfoData" [matColumnDef]="country.country">
     <th mat-header-cell *matHeaderCellDef  class="country-column country-header"
        ><he-country-cell [country]="country.country"></he-country-cell>
        <mat-icon #tooltip="matTooltip"
        matTooltip="{{country.infoIconData}}" matTooltipClass="horizon-tooltip">info</mat-icon>
      </th>
     <td mat-cell class="country-column"
        *matCellDef="let element"
        [class.na-currency]="element[country.country] === '0'"
      >
        {{
          element[country.country] <= 0
            ? "NA"
            : (element[country.country] | currency : country.currency :true:"1.0-0")
        }}
        <span
          [style.backgroundColor]="element[country.country + 'Color']"
          *ngIf="element[country.country] > '0'"
          style="
            width: 12px;
            height: 12px;
            border-radius: 12px;
            margin-left: 5px;
          "
        ></span>
      </td>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      [ngClass]="{ highlight: row?.highlight === true }"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </table>
</div>
