import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
import { Component } from '@angular/core';

@Component({
  selector: 'he-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {

  constructor(
    public sharedService: SharedService
  ) { }


}
