<he-loading-overlay *ngIf="showLoadingOverlay"></he-loading-overlay>

<!-- middle zone: charts, tables -->
<div class="he-viewport untouchable he-layer-z1">
  <div class="container he-h-100">
    <div class="row no-gutters he-h-100">
      <div class="col he-h-100 animated"
           [ngClass]="getExpandedMiddleClasses()">
        <div class="he-viewport touchable">

          <div class="row no-gutters he-h-100 flex-column flex-nowrap">
            <div class="col flex-grow-0">
              <ng-content select=".middle-toolbar-slot"></ng-content>
            </div>

            <div class="col flex-fill">
              <div class="he-viewport he-viewport-middle-slot" (scroll)="onContainerScroll($event)" cdkScrollable>
                <ng-content select=".middle-slot"></ng-content>
                <ng-content></ng-content>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- west zone: navigation -->
<!-- <div class="he-viewport he-backdrop d-md-none"></div> -->
<div *ngIf="hasLeftPanel" class="he-viewport untouchable he-layer-z1">
  <div class="container he-h-100">
    <div class="row no-gutters he-h-100 mx-n2 mx-md-0 he-mw-west he-mw-md-west">
      <div class="col he-h-100 clip-frame col-md-3 dec-lg-2 col-xl-2">
        <div class="he-viewport touchable he-west-panel he-west-panel-md he-move-out-left-md animated"
             [ngClass]="getCollapsedWestClasses()" cdkScrollable>

          <div class="row no-gutters he-h-100 flex-column flex-nowrap p-2 pt-4">
            <div class="col flex-grow-0">
              <div class="px-2">
                <ng-content select=".west-toolbar-slot"></ng-content>
              </div>
            </div>

            <div class="col flex-fill">
              <div class="he-viewport" cdkScrollable>
                <div class="px-2 px-md-0">
                  <ng-content select=".west-slot"></ng-content>

                  <ng-container *ngIf="hasSmallScreen">
                    <ng-container *ngTemplateOutlet="eastToolbarSlot"></ng-container>
                  </ng-container>
                  <ng-container *ngIf="hasSmallScreen">
                    <ng-container *ngTemplateOutlet="eastSlot"></ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- east zone: filters -->
<div class="he-viewport untouchable he-layer-z1 d-none d-md-block">
  <div class="container he-h-100">
    <div class="row no-gutters he-h-100">
      <div class="col he-h-100 clip-frame offset-md-9 col-md-3 offset-dec-lg-8 dec-lg-2 offset-xl-10 col-xl-2">
        <div class="he-viewport touchable he-east-panel animated" [ngClass]="getCollapsedEastClasses()" cdkScrollable>

          <div class="row no-gutters he-h-100 flex-column flex-nowrap">
            <div class="col flex-grow-0">
              <ng-container *ngIf="!hasSmallScreen">
                <ng-container *ngTemplateOutlet="eastToolbarSlot"></ng-container>
              </ng-container>
            </div>

            <div class="col flex-fill">
              <div class="he-viewport" cdkScrollable>
                <ng-container *ngIf="!hasSmallScreen">
                  <ng-container *ngTemplateOutlet="eastSlot"></ng-container>
                </ng-container>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #eastToolbarSlot>
  <ng-content select=".east-toolbar-slot"></ng-content>
</ng-template>

<ng-template #eastSlot>
  <ng-content select=".east-slot"></ng-content>
</ng-template>

