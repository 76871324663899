import { Observable } from 'rxjs';

export interface Message {
  type: string;
  payload: unknown;
  error?: boolean;
  totalRows?: number;
}

export interface MessageRef {
  payload: unknown;
}

export abstract class PackDetailsProvider {
  abstract run(input: MessageRef): Observable<Message>;
}

export abstract class GridViewCustomDetailsProvider {
  abstract run(input: MessageRef): Observable<Message>;
}

