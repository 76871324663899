import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ECharts } from 'echarts';
import { ExportImageService } from '../../../../shared/services/export-image.service';
import { ProjectAnalogueCotGridComponent } from '../../component-pieces/project-analogue-cot-grid/project-analogue-cot-grid.component';
import html2canvas from 'html2canvas';
import { FeatureFlagService } from '../../../../horizon-project/services/feature-flag.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { AnalogueRegulatoryApprovalFormat } from '../../../../horizon-project/models/AnalogueRegulatoryApprovalFormat';
import { ToReadableDate } from '../../../../horizon-project/utility-functions/DateFormat';
import { Dropdown, HeaderService } from '../../../../shared/services/header.service';
import { AngalogueTimelineComponent } from '../../component-pieces/angalogue-timeline/angalogue-timeline.component';
import { ProjectService } from '../../../project/services/project.service';

@Component({
  selector: 'he-project-analogue',
  templateUrl: './project-analogue.component.html',
  styleUrls: [
    './project-analogue.component.scss',
    '../../analogue-shared.scss',
  ],
})
export class ProjectAnalogueComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('dataToExport', { static: false }) public dataToExport:
    | ElementRef
    | any;
  @ViewChild('dataToExport2', { static: false }) public dataToExport2:
    | ElementRef
    | any;
  @ViewChild('dataToExportTimeline', { static: false })
  public dataToExportTimeline: ElementRef | any;
  @ViewChild('downloadLink') downloadLink: ElementRef | any;
  @ViewChild('canvas') canvas: ElementRef | any;
  @ViewChild(ProjectAnalogueCotGridComponent)
  CSVDwonload?: ProjectAnalogueCotGridComponent;
  @ViewChild(AngalogueTimelineComponent, { static: false })
  TimelineComponent?: AngalogueTimelineComponent;
  public selectedOption: string = 'Country';
  priceTypes: Dropdown[] = [];
  selectedValue = '';
  selectedCOTPriceType = 'launchPrice';
  selectedCOTPriceTypeView = 'Launch Price';
  mainRecordCount = 0;
  recordCount = 0;
  isGridClicked = true;
  isChartClicked = false;
  fileType = 'grid';
  chartType = 'Analogue Charts';
  projectId = '';
  public chartType2 = 'ANALOGUE PRICE PER INPUT Charts';
  private unsubscriber$ = new Subject<void>();
  public viewMode = 'Country';
  analogueRegulatoryData?: AnalogueRegulatoryApprovalFormat[];
  analogueTimelineData: any[] = [];
  timelineData: any[] = [];
 public selectedRoute:string='Analgoue'
 private readonly destroying$: Subject<void> = new Subject<void>();
  constructor(
    private exportImageService: ExportImageService,
    private featureFlagService: FeatureFlagService,
    private analogueService: AnalogueService,
    private route: ActivatedRoute,
    public headerservice: HeaderService,
    private projectService:ProjectService
  ) {}

  ngOnInit(): void {
    this.headerservice.ComparatorOrAnalog
    .pipe(takeUntil(this.destroying$))
    .subscribe((data: string) => {
      this.selectedRoute = data;
      if(this.selectedRoute == "Comparators") this.selectedRoute ="Comparator";
      else if(this.selectedRoute == "Analogues") this.selectedRoute ="Analogue";
    });
    this.route.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (f) => {
        await this.initializeCompData();
        this.projectId = f.project;
        if (this.projectId) {
          this.selectedCOTPriceType = 'launchPrice';
          this.selectedCOTPriceTypeView = 'Launch Price';
          this.analogueService
            .getAnalogueRegulatoryData(this.projectId)
            .then((data: AnalogueRegulatoryApprovalFormat[]) => {
              this.analogueRegulatoryData = data;
              this.formatAnalogueTimeline();
            });
            this.priceTypes = this.headerservice.priceTypes;
            this.priceTypes.filter(x=> x.value === 'netPrice')[0].visible = this.headerservice.netPricePermission.isNetPriceAvailable
            this.priceTypes.filter(x=> x.value === 'netPrice')[0].disabled = !this.headerservice.netPricePermission.isNetPriceVisible
            this.priceTypes.sort((a, b) => a.id - b.id);

        }
      });

    this.featureFlagService.getFeatureFlagData().then((data) => {
      if (data) {
        let reimbusedPriceEnabled = data.filter(
          (x) => x.name === 'reimbursed-price' && x.page === 'common'
        )[0]?.isActive;
        this.priceTypes.filter(x=> x.value === 'reimbursedPrice')[0].visible = reimbusedPriceEnabled
        this.priceTypes.sort((a, b) => a.id - b.id);
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.gridToggle('grid', false);
    }, 100);
  }

  mainGridCountChanged(count: number) {
    this.mainRecordCount = count;
  }

  subGridCountChanged(count: number) {
    this.recordCount = count;
  }

  saveInstance(ev: ECharts): void {
    this.exportImageService.registerApi(ev, this.chartType);
  }
  private async initializeCompData() {
    this.selectedValue = '';
    this.selectedCOTPriceType = 'launchPrice';
    this.mainRecordCount = 0;
    this.recordCount = 0;
    this.isGridClicked = true;
    this.isChartClicked = false;
    this.fileType = 'grid';
    this.chartType = 'Analogue Charts';
    this.projectId = '';
    this.chartType2 = 'ANALOGUE PRICE PER INPUT Charts';
    // this.checkCurrency(true);
  }
  // onSaveImg(): void {
  //   this.exportImageService.saveImage(this.chartType+'_'+this.selectedChartPriceType,this.chartType);
  // }

  public formatAnalogueTimeline() {
    this.analogueTimelineData = [];
    this.analogueRegulatoryData?.forEach((data: any) => {
      this.analogueTimelineData.push({
        Country: data.country,
        'Brand Name': data.brandName,
        'Initial Launch Data': ToReadableDate(data.initialLaunchDate),
        'Initial Launch Date': ToReadableDate(data.initialLaunchDate),
        'Reimbursement decision Date':
          ToReadableDate(data.regulatoryApprovalDate) === 'lid Da,te'
            ? 'NA'
            : ToReadableDate(data.regulatoryApprovalDate),
        Indication: data.tli,
        'Mechanism of Action': data.moa,
        [data.country]: ToReadableDate(data.regulatoryApprovalDate),
      });
    });
  }

  public gridToggle(data: string, change: boolean) {
    if (data === 'grid') {
      this.isGridClicked = true;
      this.isChartClicked = false;
      this.fileType = 'grid';
      if (change) {
        this.checkCurrency(true);
      }
    } else if (data === 'chart') {
      this.isChartClicked = true;
      this.isGridClicked = false;
      this.fileType = 'chart';
      if (change) {
        this.checkCurrency(false);
      }
    } else {
      return;
    }
  }

  public checkCurrency(state: boolean): void {
    this.headerservice.setEnableCurrenct(state, 'Analogues', this.fileType);
  }

  dwonloadFile() {
    switch (this.fileType) {
      case 'chart':
        this.downloadAsPdf();
        // this.exportImageService.saveImage(this.chartType + '_' + this.selectedCOTPriceType, this.chartType);
        break;
      case 'grid':
        this.CSVDwonload?.dwonloadData();
        break;
    }
  }

  public downloadAsPdf(): void {
    html2canvas(this.dataToExport.nativeElement).then((canvas) => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download =
        this.chartType + '_' + this.selectedCOTPriceType + '.png';
      this.downloadLink.nativeElement.click();
    });
  }
  public dwonloadTimeLineImage(): void {
    let lenngth: number = this.TimelineComponent?.TimelineList
      ?.length as number;
    if (lenngth >= 9) {
      this.dataToExportTimeline.nativeElement.style.width = 'fit-content';
    }
    html2canvas(this.dataToExportTimeline.nativeElement).then((canvas) => {
      // this.canvas.nativeElement.style.width = 'fit-content'
      this.dataToExportTimeline.nativeElement.style.width = '100%';
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download =
        'Analogue Regulatory Approval and Reimbursement timeline' +
        '_' +
        this.viewMode +
        '.png';
      this.downloadLink.nativeElement.click();
   

    });
  }

  dwonloadTimelineFile() {
    const replacer = (key: any, value: any) => (value === null ? '' : value);
    const header = [
      'Country',
      'Brand Name',
      'Initial Launch Date',
      'Reimbursement decision Date',
      'Indication',
      'Mechanism of Action',
    ];
    let csv = [];
    let newtimeline: any[] = [];

    switch (this.viewMode) {
      case 'Country':
        this.analogueTimelineData
          .filter((x) => x.Country === this.selectedOption)
          .forEach((x) => {
            if (
              !newtimeline.some(
                (y) =>
                  y['Brand Name']?.toLowerCase().trim() ===
                  x['Brand Name'].toLowerCase().trim()
              )
            ) {
              newtimeline.push(x);
            }
          });
        csv = newtimeline
          .filter((x) => x.Country === this.selectedOption)
          .map((row) =>
            header
              .map((fieldName) => {
                return JSON.stringify(row[fieldName], replacer);
              })
              .join(',')
          );
        break;
      case 'Analogue':
        this.analogueTimelineData
          .filter((x) => x['Brand Name'] === this.selectedOption)
          .forEach((x) => {
            if (
              !newtimeline.some(
                (y) =>
                  y['Country']?.toLowerCase().trim() ===
                  x['Country'].toLowerCase().trim()
              )
            ) {
              newtimeline.push(x);
            }
          });
        csv = newtimeline
          .filter((x) => x['Brand Name'] === this.selectedOption)
          .map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(',')
          );
        break;
      default:
        csv = this.analogueTimelineData
          .filter((x) => x.Country === this.selectedOption)
          .map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(',')
          );
        break;
    }
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'Analogue Regulatory Approval and Reimbursement timeline.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public changeOption(option: string): void {
    this.selectedOption = option;
  }

  dwonloadFilePricePerUnit() {
    html2canvas(this.dataToExport2.nativeElement).then((canvas) => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.chartType2 + '.png';
      this.downloadLink.nativeElement.click();
    });
  }

  public changeView(event: string): void {
    this.viewMode = event;
  }

  changePrice(event: any): void {
    this.selectedCOTPriceTypeView = event;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
