import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[heInfoTitle]'
})
export class InfoTitleDirective {

  constructor(public template: TemplateRef<any>) { }

}
