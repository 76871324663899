import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { CountryFlagService } from '../country-flag.service';

@Component({
  selector: 'he-country-flag-cell',
  templateUrl: './country-flag-cell.component.html',
  styleUrls: ['./country-flag-cell.component.scss']
})
export class CountryFlagCellComponent implements OnInit, IHeaderAngularComp {

  public country: string | any;

  public flagId = '';

  constructor(
    private countryFlagService: CountryFlagService
  ) {
  }

  refresh(): boolean {
    return false;
  }

  agInit(params: IHeaderParams): void {
    this.country = params.displayName;
  }

  public ngOnInit(): void {
    this.flagId = this.countryFlagService.getFlagId(this.country);
  }
}
