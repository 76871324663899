import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { GridViewCustomColumnAPIService } from './gridviewcustomcolumn.service';
import { GridViewCustomDetailsResponse } from '../../core/gridview-customization';
import { GridViewCustomColumn } from './gridViewCustomColumnList';
//Update selected columns in database
@Pipe({
  name: 'fetchGridViewColumnsCustom'
})
export class FetchGridViewColumnsCustomPipe implements PipeTransform {

  constructor(private service: GridViewCustomColumnAPIService) {
  } 
  
  transform(columnNames: GridViewCustomColumn[] , tabname:string): Observable<any> {
    var rows:any[] = []    
    columnNames.forEach((rowGroup: GridViewCustomColumn) =>
    {
      if(rowGroup.isSelected===true) {
          rows.push({'Type':tabname, 'Name':rowGroup.field})
      }
    })
    return this.service.getGridViewCustomDetails(rows);
 }  

  transformdefault(tabname:string): Observable<GridViewCustomDetailsResponse>{
    return this.service.getGridViewCustomDefault(tabname);
  }
}
