import { Component, EventEmitter, Input, OnInit, Output,OnDestroy } from '@angular/core';
import { ECharts } from 'echarts';
import { FiltersValueService } from 'projects/helios-gui/src/app/datastore/filters-value.service';
import { FiltersObject, FilterNameEnum } from '../../../app/datastore/interfaces';
import { SharedService } from '../../service/shared.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'he-corridor-chart',
  templateUrl: './corridor-chart.component.html',
  styleUrls: ['./corridor-chart.component.scss']
})
export class CorridorChartComponent implements OnInit, OnDestroy{
  @Input() chartData?: any;
  @Input() countries: any;
  @Input() resData: any;
  @Input() brands: any;
  @Input() filterValue: any;

  @Output() showDiv = new EventEmitter<boolean>(false);
  countryList: any;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  @Output() countryListVal:EventEmitter<any>=new EventEmitter();
  private readonly destroying$: Subject<void> = new Subject<void>();

  @Output() chartInstance = new EventEmitter<any>();
  @Output() legendSelection = new EventEmitter<any>();
  // selectedLegends: { [key: string]: boolean } | undefined;

  constructor(private filtersValueService: FiltersValueService, private sharedService:SharedService) {
  }

  
  ngOnInit(): void {
    this.filtersValueService.updateCOtlist(this.resData);
    this.checkAvailability();
    this.checkCalcValue();

    this.filtersValueService.brandValues$.pipe(
      takeUntil(this.destroying$),
    ).subscribe(brandVal => {
      this.sharedService.COTCorBrandList$.next(brandVal);
    });
    
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  checkCalcValue(): void{
    if (!this.resData) return;
    const calcPer = this.resData[0]?.calcPer;
    const calcPerItem = {
        name: calcPer.charAt(0).toUpperCase() + calcPer.slice(1),
        type: calcPer
    };
    const calVal = this.filterValue.find((item: any) => item.filterName === 'calcPer');
    calVal.filterValue[0] = calcPerItem;
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.calcPer, [calcPerItem]));

    const onLoadItem = {name: true};
    const onLoadVal = this.filterValue.find((item: any) => item.filterName === 'onLoad');
    onLoadVal.filterValue[0] = onLoadItem;
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.onLoad, [onLoadItem]));
  }

  checkAvailability(): void{
    if (this.resData){
      this.countryList = this.countries.filter((country: any) => {
        return !this.resData.some((value: any) => country === value.country);
      });

      if (this.countryList.length >= 1){
        this.countryList = this.countryList.map((country: any) => ' ' + country);
        this.countryList.sort();
        this.showDiv.emit(true);
      }

      this.countryListVal.emit(this.countryList);
    }
  }

  saveChartApi(ev: ECharts): void {
    this.chartInstance.emit(ev);
  }

  chartLegendSelectChanged(event: { name: string, selected: { [key: string]: boolean } } | any): void {
    // this.selectedLegends = event.selected;
    this.legendSelection.emit(event.selected);
  }
}
