import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { HorizonProjectFileService } from '../../../../horizon-project/services/horizon-project-file.service';
import { MasterService } from '../../../../horizon-project/services/master.service';
import { ProjectService } from '../../../project/services/project.service';
import { ProjectLoadService } from '../../../../horizon-project/services/project-load.service';

@Component({
  selector: 'he-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectFilesComponent implements OnInit, OnDestroy {
  public projectId = '';
  public projectFileNames: any[] = [];
  public loading = false;
  public uploadStatus = {
    uploading: false,
    uploadStatus: false,
  };
  public fileCount: any;
  private unsubscriber$ = new Subject<void>();

  constructor(
    private activatedRouter: ActivatedRoute,
    private projectFileService: HorizonProjectFileService,
    private masterService: MasterService,
    private projectService: ProjectService,
    private projectLoadService: ProjectLoadService
  ) {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.uploadStatus.uploading = false;
        this.projectId = p.project;
        if (this.projectId) {
          this.projectFileNames = [];
          this.projectService.castfileUploadStatus
            .pipe(take(1))
            .subscribe((res) => {
              if (res.projectId === this.projectId && res.uploading) {
                this.uploadStatus = JSON.parse(JSON.stringify(res));
              } else {
                this.uploadStatus.uploading = false;
                this.getFiles();
              }
            });
        }
      });
  }

  ngOnInit(): void {
    this.projectService.castfileUploadStatus
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.uploadStatus = JSON.parse(JSON.stringify(res));
        this.loading = this.uploadStatus.uploading;
        if (this.uploadStatus.uploadStatus) {
          this.getFiles();
        }
      });
  }

  public getFiles(): void {
    this.projectFileNames = [];
    this.loading = true;
    this.masterService.getFileListServie(this.projectId).then((data) => {
      this.projectFileNames = data;
      this.loading = false;
    });
  }

  download(event: any, fileName: string): void {
    this.projectFileService
      .getProjectFile(this.projectId, fileName)
      .subscribe((response) => {
        const blob: Blob = response.body as Blob;
        const downloadFileAnchorElement = event.target.parentNode;
        if (downloadFileAnchorElement) {
          downloadFileAnchorElement.download = fileName;
          downloadFileAnchorElement.href = window.URL.createObjectURL(blob);
          downloadFileAnchorElement.click();
        }
      });
  }

  open(fileName: string): void {
    this.projectFileService
      .getProjectFile(this.projectId, fileName)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((response) => {
        const blob: Blob = response.body as Blob;
        let file = new Blob([blob], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        if (fileName.includes('pdf')) {
          window.open(fileURL);
        } else {
          var a = document.createElement('a');
          a.href = fileURL;
          a.download = fileName;
          a.click();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
