import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ProjectAssetTppComponent } from './project-asset-tpp/project-asset-tpp.component';
import { LoadingSpinnerModule } from '../../../../uikit/loading-spinner';
import { ProjectAssetTppNewComponent } from './components/project-asset-tpp-new/project-asset-tpp-new.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ProjectAssetTppComponent, ProjectAssetTppNewComponent],
  exports: [ProjectAssetTppComponent, ProjectAssetTppNewComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    LoadingSpinnerModule,
  ],
})
export class AssetTppModule {}
