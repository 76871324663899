import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  users: any[] = [];

  constructor(private http: HttpClient) { }

  getAllUsers(): Promise<any[]>{
    return new Promise<any[]>((resolve, reject) => {
      if (this.users.length <= 0){
        this.http.get(`${this.projectAPIUri}/User/GetAllUsers`).subscribe((response: any) => {
          this.users = response;
          resolve(this.users);
        }, (err) => {
          reject(err);
        });
      }else{
        resolve(this.users);
      }
    });
  }
}
