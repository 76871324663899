import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { IconModule } from '../../../uikit/helios-icons';

import { MenuComponent } from './menu.component';
import { InitialsPipe } from './initials.pipe';
import { UserProfile } from './user.profile';

@NgModule({
  declarations: [MenuComponent, InitialsPipe],
  exports: [MenuComponent],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatMenuModule
  ],
  providers: [UserProfile]
})
export class MenuModule {
}
