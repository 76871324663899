import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { BrandsAvailiabilityProvider, Message } from '../../brands-availiability/brands-availiability.provider';
import { FiltersValueService } from '../../filters-value.service';
import { SetOfFiltersInterface } from '../../../../core/datastore-common';
import { FiltersObject } from '../../interfaces';

@Pipe({
  name: 'FetchAvailability'
})
export class FilterByBrandFetchPipe implements PipeTransform {

  constructor(
    private brandsAvailabilityProvider: BrandsAvailiabilityProvider,
    private filtersValueService: FiltersValueService,
    ) {
  }

  transform(event: FiltersObject | any): Observable<Message> {
    const payload: SetOfFiltersInterface = this.filtersValueService.getSetOfFilters(event);
    return this.hasRequiredValue(payload) ? this.brandsAvailabilityProvider.run({ payload }) : of({ type: 'fail', payload: [] });
  }

  hasRequiredValue(payload: SetOfFiltersInterface): boolean {
    return !!(payload.brands.length);
  }
}
