import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LatoFontComponent } from './lato-font.component';

@NgModule({
  declarations: [LatoFontComponent],
  exports: [LatoFontComponent],
  imports: [
    CommonModule
  ]
})
export class LatoFontModule { }
