import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterItemValueInterface, FiltersObject } from '../interfaces';
import { FiltersValueService } from '../filters-value.service';
import { FilterNameEnum } from '../enum';

@Component({
  selector: 'he-dynamic-filters',
  templateUrl: './dynamic-filters.component.html',
  styleUrls: ['./dynamic-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFiltersComponent {
  @Input() filtersValue!: FiltersObject;

  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();

  constructor(
    private filtersValueService: FiltersValueService
  ) {}

  get selectedItems(): FilterItemValueInterface[] {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.dynamicIndications).filterValue || [];
  }

  public itemUpdated(): void {
    this.filtersValueModified.emit(this.filtersValue);
  }
}
