import { Observable } from 'rxjs';

export interface Message {
  type: string;
  payload: unknown;
  error?: boolean;
}

export interface MessageRef {
  payload: unknown;
}

export abstract class ReimbursementHtaProvider {
  abstract run(input: MessageRef): Observable<Message>;
}
