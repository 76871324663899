import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { SharedService } from '../../uikit/service/shared.service';
import { MobileDrawer } from './drawer';

interface SlidePanel {
  visibility: boolean;
}

@Component({
  selector: 'he-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
  @Input() rightEdgePanel: SlidePanel | undefined;
  @Input() leftEdgePanel: SlidePanel | null | undefined;
  @Input() hasLeftPanel = true;
  @Input() mode: string | undefined;
  @Input() showLoadingOverlay: boolean | undefined | null = false;

  mediaQueryList = window.matchMedia('(max-width: 899px)');
  hasSmallScreen = false;

  constructor(
    @Inject(MobileDrawer) private drawer: any,
    public sharedService: SharedService
  ) {
    this.onMediaChange = this.onMediaChange.bind(this);
    this.onMediaChange(this.mediaQueryList);
    this.mediaQueryList.addEventListener('change', this.onMediaChange);
  }

  onContainerScroll($event: Event): void {
    this.sharedService.datastoreMiddleViewportScroll$.next($event);
  }

  ngOnDestroy(): void {
    this.mediaQueryList.removeEventListener('change', this.onMediaChange);
  }

  onMediaChange(event: any): void {
    this.hasSmallScreen = event.matches;
  }

  getExpandedMiddleClasses(): string[] {
    if (this.mode === 'chart') {
      return this.rightEdgePanel?.visibility ? ['he-right-panel-visible', 'offset-md-3', 'offset-dec-lg-2', 'dec-lg-6', 'offset-xl-2', 'col-xl-8'] : ['he-right-panel-hidden', 'offset-md-3', 'offset-dec-lg-2', 'offset-xl-2', 'dec-lg-8', 'col-xl-10'];
    } else {
      return this.rightEdgePanel?.visibility ? ['he-right-panel-visible', 'dec-lg-8', 'col-xl-10'] : ['he-right-panel-hidden', 'dec-lg-12', 'col-xl-12'];
    }
  }

  getCollapsedEastClasses(): string[] {
    return this.rightEdgePanel?.visibility ? [] : ['move-out-right'];
  }

  getCollapsedWestClasses(): string[] {
    return this.leftEdgePanel?.visibility ? [] : ['move-out-left'];
  }
}
