import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(words: string): string {
    return words.split(/\s+/g).map((word) => word[0]).join('');
  }

}
