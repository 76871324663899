import { Component, EventEmitter, Input, Output, ViewChild,ElementRef ,Renderer2 } from '@angular/core';
import {
  ColumnApi,
  ColumnMovedEvent,
  ComponentStateChangedEvent,
  DragStoppedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { emptyFormatter } from './empty.formatter';
import { GridSizeModeInterface } from './grid-interfaces';
import { SharedService } from '../service/shared.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'he-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent {
  @Input() sizemode: GridSizeModeInterface;
  @Input() columnDefs: any;
  @Input() rowData: any;
  @Input() paginationPageSize: number | undefined;
  @Input() pagination = false;
  @Input() gridOptions: GridOptions = {};
  @Input() domLayout: 'normal' | 'autoHeight' | 'print' | undefined = 'normal';
  @Input() defaultColDef = {
    resizable: true,
    sortable: true,
    valueFormatter: emptyFormatter,
  };
  @Output() gridReady = new EventEmitter<GridApi>();
  @Output() gridReadyEvent = new EventEmitter<GridReadyEvent>();
  @Output() selectionChanged = new EventEmitter<GridApi>();
  @Output() componentStateChanged =    new EventEmitter<ComponentStateChangedEvent>();
  @Output() columnMoved = new EventEmitter<ColumnMovedEvent>();
  @Output() dragStopped = new EventEmitter<DragStoppedEvent>();
  @Output() paginatorPageEvent = new EventEmitter<PageEvent>();
  @Output() sortChanged = new EventEmitter<SortChangedEvent>();
  @Input() frameworkComponents: any;
  getRowClass: any;
  sortedData: any[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('eSortAsc', { read: ElementRef }) eSortAsc!: ElementRef;
  @ViewChild('eSortDesc', { read: ElementRef }) eSortDesc!: ElementRef;
  
  private gridApi: GridApi | undefined;
  private gridColumnApi: ColumnApi | undefined;

  constructor(public sharedService: SharedService,
    private renderer: Renderer2,
    private elementRef: ElementRef) {
    this.sizemode = 'autosize';
  }

  sizeToFit(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  autoSizeAll(skipHeader: boolean): void {
    const allColumnIds: any[] = [];
    if (this.gridColumnApi) {
      const columns = this.gridColumnApi.getAllColumns();
      if (columns) {
        columns.forEach((column: any) => {
          allColumnIds.push(column.colId);
        });
      }
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.sizemode === 'autofit') {
      this.sizeToFit();
    } else if (this.sizemode === 'autosize') {
      this.autoSizeAll(false);
    }
    this.gridReady.emit(params.api);
    this.gridReadyEvent.emit(params);
  

    if(this.sharedService.gridmodesortType$.value!=0)
    {
         this.findColumnIndexByHeaderValue(this.sharedService.gridmodesortColumnText$.value);
      
        if(this.sharedService.gridmodesortType$.value==1)
        {
          this.enableAsc(this.sharedService.gridmodesortColumnText$.value);
        }
        else  if(this.sharedService.gridmodesortType$.value==2)
        {
        this.enableDesc(this.sharedService.gridmodesortColumnText$.value);
        }
  }
  }


  
  enableAsc(columnName: string) {
    const columnHeaders = this.elementRef.nativeElement.querySelectorAll('.ag-header-cell-text');
   columnHeaders.forEach((header:any) => {
        if (header.textContent === this.sharedService.gridmodesortColumnText$.value) {
          const sortDesceElement = header.parentElement.querySelector('[ref="eSortDesc"]');
          if (sortDesceElement) {
            this.renderer.addClass(sortDesceElement, 'ag-hidden');
            this.renderer.removeStyle(sortDesceElement, 'display');
            this.renderer.removeStyle(sortDesceElement, 'position');
            this.renderer.removeStyle(sortDesceElement, 'content');
            this.renderer.removeStyle(sortDesceElement, 'border-left');
            this.renderer.removeStyle(sortDesceElement, 'border-right');
            this.renderer.removeStyle(sortDesceElement, 'border-top');   
        
            const sortAsceElement = header.parentElement.querySelector('[ref="eSortAsc"]');
            this.renderer.removeClass(sortAsceElement, 'ag-hidden');
            this.renderer.setStyle(sortAsceElement, 'display', 'block');
            this.renderer.setStyle(sortAsceElement, 'position', 'relative');
            this.renderer.setStyle(sortAsceElement, 'content', '');
            this.renderer.setStyle(sortAsceElement, 'border-left', '5px solid transparent');
            this.renderer.setStyle(sortAsceElement, 'border-right', '5px solid transparent');

          }
          }
        });
  }

  enableDesc(columnName: string) {
    const columnHeaders = this.elementRef.nativeElement.querySelectorAll('.ag-header-cell-text');
   columnHeaders.forEach((header:any) => {
        if (header.textContent === this.sharedService.gridmodesortColumnText$.value) {
          const sortAsceElement = header.parentElement.querySelector('[ref="eSortAsc"]');
          if (sortAsceElement) {          
            this.renderer.addClass(sortAsceElement, 'ag-hidden');
            this.renderer.removeStyle(sortAsceElement, 'display');
            this.renderer.removeStyle(sortAsceElement, 'position');
            this.renderer.removeStyle(sortAsceElement, 'content');
            this.renderer.removeStyle(sortAsceElement, 'border-left');
            this.renderer.removeStyle(sortAsceElement, 'border-right');
            this.renderer.removeStyle(sortAsceElement, 'border-top');   
        

            const sortDesceElement = header.parentElement.querySelector('[ref="eSortDesc"]');
            this.renderer.removeClass(sortDesceElement, 'ag-hidden');
            this.renderer.setStyle(sortDesceElement, 'display', 'block');
            this.renderer.setStyle(sortDesceElement, 'position', 'relative');
            this.renderer.setStyle(sortDesceElement, 'content', '');
            this.renderer.setStyle(sortDesceElement, 'border-left', '5px solid transparent');
            this.renderer.setStyle(sortDesceElement, 'border-right', '5px solid transparent');
          }
          }
        });
      }
  
  findColumnIndexByHeaderValue(headerValue: string) {
    const allColumns= this.gridOptions?.columnApi?.getAllDisplayedColumns();
    const colIndex = allColumns?.findIndex((col) => col.getColDef().headerName === headerValue);
    this.scrollToColumnByIndex(Number(colIndex));
  }

  scrollToColumnByIndex(colIndex: number) {
    const column = this.gridColumnApi?.getAllDisplayedColumns()[colIndex];
    if (column) {
      this.gridOptions?.api?.ensureColumnVisible(column);
    } else {
      console.log('Column not found or not visible.');
    }
  }

  onDragStopped(event: DragStoppedEvent) {
    this.dragStopped.emit(event);
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    this.selectionChanged.emit(event.api);
  }

  onComponentStateChanged(event: ComponentStateChangedEvent): void {
    this.componentStateChanged.emit(event);
  }
  onColumnMoved(event: ColumnMovedEvent): void {
    this.columnMoved.emit(event);
  }

  headerName:any='';
  public onSortChanged(event: SortChangedEvent) {
    const sortedColumns = event.columnApi?.getAllColumns()?.filter((column) => column.getSort());

    if (sortedColumns) {
      const sortedColumn = sortedColumns[0]; 
      this.headerName = sortedColumn.getColDef().headerName?? null;
    }
    const columnAPI = event.columnApi;
    const gridAPI = event.api;
    const sortModel = gridAPI.getSortModel();
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      // 
      if(this.sharedService.gridmodesortColumn$.value===sortedColumn.colId!)
      {
        if(this.sharedService.gridmodesortType$.value==0)
        {
          this.sharedService.gridmodesortType$.next(1);
        }
        else  if(this.sharedService.gridmodesortType$.value==1)
        {
         this.sharedService.gridmodesortType$.next(2);
        }
        else 
          this.sharedService.gridmodesortType$.next(0);
      }
      else
      {
          this.sharedService.gridmodesortType$.next(1);
      }
      this.sharedService.gridmodesortColumn$.next(sortedColumn.colId!);
      this.sharedService.gridmodesortColumnText$.next(this.headerName)
    }
   
    this.sortChanged.emit(event);
  }

  
  
  currentIndex: number = 0;
  recordDetails: string = '';
  onPaginatorPageEvent(event: PageEvent) {
    this.currentIndex = event.pageIndex;         
    this.sharedService.gridmodePageNumber$.next(this.currentIndex + 1);
    const startIndex = this.currentIndex * 50 + 1;
    const endIndex = ((this.currentIndex + 1) * 50)<=  this.sharedService.gridmodetotRecortCount$.value? ( (this.currentIndex + 1) * 50) : this.sharedService.gridmodetotRecortCount$.value;
    const totalItems = this.sharedService.gridmodetotRecortCount$.value;
    this.recordDetails = `${startIndex} - ${endIndex} of ${totalItems}`;  
    this.paginatorPageEvent.emit(event);
  }
}
