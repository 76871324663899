import { Component, Input, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DatepickerFDescriptionInterface } from '../datepicker';

@Component({
  selector: 'he-input-field-range',
  templateUrl: './input-field-range.component.html',
  styleUrls: ['./input-field-range.component.scss']
})
export class InputFieldRangeComponent {
  descriptionsStart: DatepickerFDescriptionInterface = {
    placeholder: 'From',
    errorMessage: 'Invalid From value'
  };
  descriptionsEnd: DatepickerFDescriptionInterface = {
    placeholder: 'To',
    errorMessage: 'Invalid To value'
  };
  maxStartValue: string | number = '';
  minEndValue: string | number = '';

  @Input() titleTemplate!: TemplateRef<any>;
  private rangeControlStartP!: FormControl;
  private rangeControlEndP!: FormControl;

  get rangeControlStart(): FormControl {
    return this.rangeControlStartP;
  }

  @Input() set rangeControlStart(control: FormControl) {
    this.rangeControlStartP = control;
    this.minEndValue = control.value;
    if (this.rangeControlEnd) {
      this.rangeControlEnd.setValidators([Validators.min(control.value || 0)]);
    }
  }

  get rangeControlEnd(): FormControl {
    return this.rangeControlEndP;
  }

  @Input() set rangeControlEnd(control: FormControl) {
    this.rangeControlEndP = control;
    this.maxStartValue = control.value;
    if (this.rangeControlStart) {
      this.rangeControlStart.setValidators(
        [Validators.min(0),
        Validators.max(control.value || Infinity)]);
    }
  }

  startValueModified(event: any): void {
    this.minEndValue = event;
    this.rangeControlEnd.setValidators(
      [Validators.min(event)]);
    // this.updateValidity();
  }

  endValueModified(event: any): void {
    this.maxStartValue = event;
    this.rangeControlStart.setValidators(
      [Validators.min(0),
      Validators.max(event || Infinity)]);
    // this.updateValidity();
  }

  updateValidity(): void {
    this.rangeControlStart.markAsTouched();
    this.rangeControlStart.updateValueAndValidity();
    this.rangeControlEnd.markAsTouched();
    this.rangeControlEnd.updateValueAndValidity();
  }
}
