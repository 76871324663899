import { CotCorridorCacheService } from '../../../app/datastore/cot-cache/cot-cache.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { AnalysisMeasuresFilterInterface } from '../../../app/project-details/analysis-measures/interfaces';
import { getCurrencyAndPeriod } from '../currency-period';
import { Formatter, setFormat } from '../../grid-common/grid-common.formatter';
import { FilterItemValueInterface } from '../../filters';
import { titleCaseFormatterText } from 'projects/helios-gui/src/app/common/string-utils';
import { FiltersValueService } from 'projects/helios-gui/src/app/datastore';

@Component({
  selector: 'he-corridor-grid',
  templateUrl: './corridor-grid.component.html',
  styleUrls: ['./corridor-grid.component.scss']
})
export class CorridorGridComponent implements OnInit, OnChanges {
  @Input() dataset: any;
  @Input() measures: Partial<AnalysisMeasuresFilterInterface> | any;
  @Input() selectedBrand: FilterItemValueInterface | undefined | null;
  @Output() gridReady = new EventEmitter();
  gridApi: GridApi | undefined

  rowData: any[] = [];
  columnDefs: any[] = [];

  constructor(private cotCorridorCacheService: CotCorridorCacheService,private filtersValueService:FiltersValueService) {
  }

  ngOnChanges(): void {
    //might need in cot calculator
    this.filtersValueService.updateCOtlist(this.dataset)
    this.setRowData();
    this.gridApi?.setRowData(this.rowData);
  }

  ngOnInit(): void {
    this.columnDefs = [
      { headerName: 'Country', field: 'country' },
      { headerName: 'Brand name', field: 'brandName' },
      { headerName: 'Reimbursement Classification (Formulary Status)', field: 'reimbursement' },
      { headerName: 'Pack', field: 'packDescription' },
      { headerName: 'Pack Price', field: 'packPrice' },
      { headerName: `Cost of treatment ${getCurrencyAndPeriod(this.measures)}`, field: 'cot' },
      { headerName: 'Dosage Information', field: 'dosageInformation' },
    ];
    this.setRowData();
  }

  private setRowData() {
    if (this.dataset) {
      this.rowData = this.dataset?.map(this.formatRow);
      this.cotCorridorCacheService.exportCsvObject.registerHeaders(this.columnDefs.map((col) => col.headerName));
      this.cotCorridorCacheService.exportRowFormatter = this.formatRow
    }
  }

  formatRow = (item: any) => {
    const packPriceFormatted=setFormat(item.packPrice, Formatter.NumberFormat);
    const packPriceConverted=Number.parseFloat(packPriceFormatted);

    const cotFormatted=setFormat(item.cot, Formatter.NumberFormat);
    const cotConverted=Number.parseFloat(cotFormatted);
    return {
      country: item.country,
      brandName: item.brandName,
      reimbursement: item.reimbursement,
      packDescription: titleCaseFormatterText(item.packDescription),
      packPrice: isNaN(packPriceConverted)?packPriceFormatted:packPriceConverted,
      cot:isNaN(cotConverted)?cotFormatted:cotConverted,
      dosageInformation: item.dosageAssumption,
    };
  };

  saveGridApi(ev: GridApi): void {
    this.gridApi = ev;
    this.gridReady.emit(ev);
  }
}
