<ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
<div class="d-flex">
  <div class="mr-1">
    <he-datepicker
      [filterControl]="rangeControlStart"
      [maxDate]="maxStartDate"
      [descriptions]="descriptionsStart"
      (dateInput)="dateInputStartModified($event)"
    ></he-datepicker>
  </div>
  <div class="ml-1">
    <he-datepicker
      [filterControl]="rangeControlEnd"
      [minDate]="minEndDate"
      [descriptions]="descriptionsEnd"
      (dateInput)="dateInputEndModified($event)"
    ></he-datepicker>
  </div>
</div>
