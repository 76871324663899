import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SetOfFiltersInterface } from '../../core/datastore-common';

@Injectable()
export class CoTHistoryApi {

  constructor(private httpClient: HttpClient) {
  }

  call(query: SetOfFiltersInterface): Observable<any> {
    return this.httpClient.post<any>('/cot/history', query);
  }

}
