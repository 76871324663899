<mat-form-field appearance="outline" placeholder="" class="he-form-field he-datepicker">
  <label>
    <input matInput
           [matDatepicker]="picker"
           [min]="minDate"
           [max]="maxDate"
           (dateChange)="dateChange.emit($event)"
           (dateInput)="dateInput.emit($event)"
           [formControl]="filterControl"
           placeholder="{{ descriptions?.placeholder }}">
  </label>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <he-icon matDatepickerToggleIcon [id]="'calendar'" [size]="18"></he-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<mat-error class="he-error" *ngIf="filterControl.errors">
  <he-icon [id]="'suggested-triangle-exclamation-mark'"></he-icon>
  <span>{{ descriptions?.errorMessage }}</span>
</mat-error>
