import { CotCorridorCacheService } from './../cot-cache/cot-cache.service';
import { from, Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { CoTCorridor } from '../../../core/cot-corridor';
import { FiltersObject } from '../interfaces';
import { FiltersValueService } from '../filters-value.service';
import { Message } from '../../../core/cot-corridor/interfaces';
import { SetOfFiltersInterface } from '../../../core/datastore-common';
import { map } from 'rxjs/operators';
import { BrandsAvailabilityService } from '../brands-availability-service';

@Pipe({
  name: 'cotCorridorFetch'
})
export class CotCorridorFetchPipe implements PipeTransform {

  constructor(private coTCorridor: CoTCorridor, private filtersValueService: FiltersValueService, private cotCacheService: CotCorridorCacheService, private brandsAvailabilityService: BrandsAvailabilityService) {
  }

  transform(event: FiltersObject | any, onLoadVal: boolean, selectedBrandChanged: boolean | null, selectedBrands: string[] | null = null,cotCalculator:boolean=false): Observable<Message> {

    const payload: SetOfFiltersInterface = this.filtersValueService.getSetOfFilters(event);
    let brands: string[] | undefined = selectedBrands ?? (this.cotCacheService.selectedBrand?.name ? [this.cotCacheService.selectedBrand?.name] : undefined);

    if(payload.brands.length > this.filtersValueService.maxBrandsLimit) {
      if(!cotCalculator){
        if (!brands) {
          let availableBrands = this.brandsAvailabilityService.availableBrandValues$.value;
          if(availableBrands){
            brands = [availableBrands[0].name]
            }
          }
        else if(brands?.length === 1) {
          const cachedResponse: Message = this.cotCacheService.getCotCache(brands[0])
          if(cachedResponse)
            return from([cachedResponse])
        }
      }

    }

    if (payload.onLoad){
      payload.onLoad = false;
    }else{
      payload.onLoad = onLoadVal;
    }

    if(brands) {
      payload.brands = brands;
      payload.comparatorDrug = false;
    }

    if(!this.filtersValueService.hasRequiredValue(payload))
      return of({ type: 'fail', payload: [] })

    const request = this.coTCorridor.run({ payload })
    if(brands) {
      return request.pipe(map((response) => {
        if(response.type !== 'pending')
        {
          if(brands?.length === 1)
            this.cotCacheService.addCotCache(brands[0], response)
          else if (brands){
            brands.map(brand => {
              var rows = (response.payload as any[])?.filter((val) => val.brandName === brand)
              if(rows?.length > 0) {
                this.cotCacheService.addCotCache(brand, {
                  type: 'done',
                  payload: rows,
                  error: ''
                })
              }
              else this.cotCacheService.addCotCache(brand, {
                type: 'fail',
                payload: [],
                error: ''
              })
            });
          }
        }

        return response;
      }))
    }

    return request;
  }
}
