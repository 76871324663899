import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByBrandComponent } from './filter-by-brand.component';
import { FilterAutocompleteWithChipsModule } from '../../../../uikit/filter-autocomplete-with-chips';
import { FilterByBrandFetchPipe } from './fetch.pipe';
import { BrandsAvailabilityFilterPipe } from './check-availability.pipe';
import { IconModule } from 'projects/helios-gui/src/uikit/helios-icons';
import { BrandFilterResetDialogModule } from 'projects/helios-gui/src/uikit/brand-filter-reset-dialog/brand-filter-reset-dialog.module';
import { FilterPresetDialogModule } from 'projects/helios-gui/src/uikit/filter-preset-dialog/filter-preset-dialog.module';
import { FilterPresetModule } from '../filter-preset';

@NgModule({
  declarations: [FilterByBrandComponent, FilterByBrandFetchPipe, BrandsAvailabilityFilterPipe],
  exports: [FilterByBrandComponent],
  imports: [
    CommonModule,
    FilterAutocompleteWithChipsModule,
    IconModule,
    BrandFilterResetDialogModule,
    FilterPresetDialogModule,
    FilterPresetModule
  ],
})
export class FilterByBrandModule {
}
