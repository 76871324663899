import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'projects/helios-gui/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleteProjectService {

  constructor(
    private http: HttpClient
  ) { }

  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  deleteProject(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete<any>(this.projectAPIUri + `/Projects/DeleteProject?projectId=${projectId}`).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  deleteContainer(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete<any>(this.projectAPIUri + `/Projects/DeleteContainer?projectId=${projectId}`).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  getProjectFiles(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.projectAPIUri + `/Projects/Get?projectId=${projectId}`).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }
}
