import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { debounceTime, map, BehaviorSubject, tap, Subscription, Subject } from 'rxjs';
import { FiltersValueService } from '../filters-value.service';
import { FetchOnlyavailableBrandsPipe } from './fetch-OnlyAvailable-brands.pipe';
import { FilterItemValueInterface, FilterNameEnum } from '../interfaces';
import { FilterByBrandFetchPipe } from './fetch-availability.pipe';

@Injectable({
  providedIn: 'root'
})
export class BrandsAvailabilityService {
  brands: FilterItemValueInterface[] = [];
  fetchSubscription: Subscription | null = null;
  filtersSubscription: Subscription | undefined;
  filtersValueService: FiltersValueService | null = null;
  availableBrandValues$ = new BehaviorSubject<FilterItemValueInterface[] | null>(null);
  private destroying$: Subject<void> = new Subject<void>();

  constructor(private filterByBrandFetchPipe: FilterByBrandFetchPipe,
    private fetchOnlyavailableBrandsPipe: FetchOnlyavailableBrandsPipe) {
     }

  initService(filters: FiltersValueService, destroying$: Subject<void>) {
    // Nothing proceding ahead as now it has been taken care in "brandsAvailabilityFilter" pipe
    return; 
    this.filtersValueService = filters;
    this.destroying$ = destroying$;
    this.subscribeFilterValues()
  }

  subscribeFilterValues() {
    if(this.filtersSubscription && !this.filtersSubscription.closed)
      this.filtersSubscription.unsubscribe();
    if(this.fetchSubscription && !this.fetchSubscription.closed)
      this.fetchSubscription.unsubscribe();

    this.filtersSubscription = this.filtersValueService?.filterValues$.pipe(
      takeUntil(this.destroying$),
      debounceTime(1000),
      tap((filterValues) => {
        if(this.fetchSubscription && !this.fetchSubscription.closed)
          this.fetchSubscription.unsubscribe();

        this.brands = this.filtersValueService?.findItemInFilters(filterValues, FilterNameEnum.brands)?.filterValue ?? [];
        this.fetchSubscription = this.filterByBrandFetchPipe.transform(filterValues).pipe(
          takeUntil(this.destroying$),
          map((brandsAvailability: any) => {
            switch(brandsAvailability?.type)
            {
              case "pending":
              case "fail":
                return null
            }

            return this.fetchOnlyavailableBrandsPipe.transform(this.brands, brandsAvailability?.payload);

          })).subscribe((brandsAvailability: any) => {
            this.availableBrandValues$.next(brandsAvailability)
          })
        }
      )
    ).subscribe()
  }


}
