import { Component } from '@angular/core';
import { ICellRendererAngularComp, ITooltipAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'he-custom-tooltip-renderer',
  templateUrl: './custom-tooltip-renderer.component.html',
  styleUrls: ['./custom-tooltip-renderer.component.scss']
})
export class CustomTooltipRendererComponent implements  ICellRendererAngularComp {
  public params!:any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }
}
