import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorridorChartComponent } from './corridor-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { CorridorChartPipe } from './corridor-chart.pipe';

@NgModule({
  declarations: [CorridorChartComponent, CorridorChartPipe],
  exports: [CorridorChartComponent, CorridorChartPipe],
  providers: [],
  imports: [
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ]
})
export class CorridorChartModule {
}
