<he-dialog (terminate)="onReject()">
    <div class="text-center">
        <p class="he-secondary-text p-4">{{ confirmationText }}</p>
        <ng-content></ng-content>
        <div>
            <div class="p-2 d-inline-block">
                <button class="he-theme-button he-lg-button" mat-button (click)="onConfirm()">
                    <span class="font-weight-bold">{{ 'Yes' }}</span>
                </button>
            </div>
            <div class="p-2 d-inline-block">
                <button class="he-standard-button he-lg-button" mat-button (click)="onReject()">
                    <span class="font-weight-bold">{{ 'Cancel' }}</span>
                </button>
            </div>
        </div>
    </div>
</he-dialog>
