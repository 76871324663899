import { Component, Input, Output,EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'he-filter-slider',
  templateUrl: './filter-slider.component.html',
  styleUrls: ['./filter-slider.component.scss']
})
export class FilterSliderComponent implements OnDestroy{

  @Input() SliderTitle:string= "";
  @Input() SliderUnit:string= "";
  @Input() sliderValue:number=0;
  @Input() SliderStep:number= 0;
  @Input() SliderMinValue:number= 0;
  @Input() SliderMaxValue:number= 0;
  @Input() DecimalDigit?:number= 0;
  @Output() ProvideSliderValue:EventEmitter<number>=new EventEmitter();
  debouncer: Subject<number> = new Subject<number>();

  subscription:Subscription;

  constructor(){
    this.subscription=this.debouncer.pipe(debounceTime(1000)).subscribe((x:number)=>{this.ProvideSliderValue.emit(x);});
  }


  GetChangeData($event:any):void{
    this.updateSliderValue(Number.parseFloat($event.target.value));
  }

  IncreseSliderValue():void{
    if(this.sliderValue<this.SliderMaxValue)
    {
      const sum=this.sliderValue+this.SliderStep;
      this.updateSliderValue(sum);
    }
  }

  DecreseSliderValue():void{
    if(this.sliderValue>this.SliderMinValue){
      const sub=this.sliderValue-this.SliderStep;
      this.updateSliderValue(sub);
    }
  }

  updateSliderValue(value:number):void{
    if(value<=this.SliderMinValue){
      value=this.SliderMinValue;
    }
    if(value>=this.SliderMaxValue){
      value=this.SliderMaxValue;
    }
    this.sliderValue=value;
    this.debouncer.next(this.sliderValue);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
