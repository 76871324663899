import { LoadingSpinnerModule } from './../../../uikit/loading-spinner/loading-spinner.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommandModule } from '../../../uikit/command';
import { SearchConfiguration } from '../../../core/search-configuration';
import { SearchConfigurationApi } from '../../../helios-api/search-configuration';

import { DatastoreContext } from '../datastore.context';
import { ContextComponent } from './context.component';
import { ConfigPipe, ConfigProvider } from './config.pipe';

@NgModule({
  declarations: [ContextComponent, ConfigPipe],
  exports: [ContextComponent],
  imports: [
    CommonModule,
    CommandModule,
    RouterModule,
    LoadingSpinnerModule
  ],
  providers: [
    DatastoreContext,
    SearchConfigurationApi,
    {provide: ConfigProvider, useFactory: SearchConfiguration.create, deps: [SearchConfigurationApi]},
  ]
})
export class ContextModule {
}
