<he-app-header (toggleSideBarForMe)="sideBarToggler()"></he-app-header>

<mat-drawer-container [ngClass]="sideBarOpen ? 'open' : 'close-he'">
    <mat-drawer mode="side" [opened]="true">
        <he-app-sidebar  (toggleSideBarForMe)="sideBarToggler()" [sideBarOpen]="sideBarOpen"></he-app-sidebar>
    </mat-drawer>    
    <!-- <mat-drawer *ngIf="!sideBarOpen" mode="side" [opened]="!sideBarOpen">
        <button mat-icon-button (click)="sideBarToggler()">
            <mat-icon>chevron_right</mat-icon>
          </button>
    </mat-drawer> -->
    <mat-drawer-content>   
        <ng-container *ngIf="!noProjects">
            <he-app-sticky-header></he-app-sticky-header>
            <router-outlet></router-outlet>
        </ng-container> 
        <ng-container *ngIf="noProjects">
            <div class="noProject">
                <h2>No projects available</h2>
                <p>Please create a project to continue</p>
            </div>
        </ng-container>
    </mat-drawer-content>
</mat-drawer-container>

