import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShadesDarkThemeComponent } from './shades-dark-theme.component';

@NgModule({
  declarations: [ShadesDarkThemeComponent],
  exports: [ShadesDarkThemeComponent],
  imports: [
    CommonModule
  ]
})
export class ShadesDarkThemeModule { }
