import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
  declarations: [SelectComponent, ClickOutsideDirective],
  exports: [SelectComponent, ClickOutsideDirective],
  imports: [
    CommonModule,
  ]
})
export class SelectModule {
}
