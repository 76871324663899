import { Component } from '@angular/core';

@Component({
  selector: 'he-analogue-selection',
  templateUrl: './analogue-selection.component.html',
  styleUrls: ['./analogue-selection.component.scss']
})
export class AnalogueSelectionComponent {
  public sideBarOpen:boolean = true;
  public firstStep:string = "Choose the criteria you want to use to select the analogues"
  constructor() { }


  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
