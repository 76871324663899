import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ProjectService } from '../../services/project.service';
import {
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'he-overall-price-assumption',
  templateUrl: './overall-price-assumption.component.html',
  styleUrls: ['./overall-price-assumption.component.scss'],
})
export class OverallPriceAssumptionComponent implements OnInit, OnChanges {
  @Input() market: any;
  @Input() readOnly: any;
  @Input() OverallNetPrice: any;
  @Output() public updatePrice = new EventEmitter<void>();
  public percentage = new FormControl('', [
    Validators.min(0),
    Validators.max(100),
  ]);
  public percentages!: FormArray;
  public error: string = '';
  constructor(private netPriceService: ProjectService) {}

  ngOnInit(): void {
    this.netPriceService.castValueChangeStatus.subscribe((res) => {
      this.setError();
    });
  }
  ngOnChanges(): void {
    if (!this.readOnly) {
      this.percentage.clearAsyncValidators();
      this.percentage.clearValidators();
      this.percentage.updateValueAndValidity();
      this.error = '';
    } else {
      this.percentage.addValidators([Validators.min(0), Validators.max(100)]);
      this.percentage.updateValueAndValidity();
      this.setError();
    }
  }

  public changePrice(): void {
    if (!this.readOnly) {
      this.market.NetPriceDiscount = '0.00';
    } else {
      this.market.NetPriceDiscount = this.market.NetPriceDiscount != null ?this.market.NetPriceDiscount.toFixed(2):this.market.NetPriceDiscount;    }
    this.setError();
    if (this.market.NetPriceDiscount >= 0) {
      this.updatePrice.emit();
    }
  }

  public setError(): void {
    if (this.market.NetPriceDiscount < 0) {
      this.error = 'Enter a positive value';
    } else if (this.market.NetPriceDiscount > 100) {
      this.error = 'Enter a value less than 100%';
    } else {
      this.error = '';
    }
  }
  
}
