import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import { CotCalculatorComponent } from './cot-calculator/cot-calculator.component';
import { InfoWidgetModule } from 'projects/helios-gui/src/uikit/info-widget/info-widget.module';
import { MatCardModule } from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import { GridCommonModule } from 'projects/helios-gui/src/uikit/grid-common';
import { CotGridDropdownComponent } from './cot-grid-dropdown/cot-grid-dropdown.component';
import { CotGridInputComponent } from './cot-grid-input/cot-grid-input.component';
import { AgGridModule } from 'ag-grid-angular';
import { SelectModule } from 'projects/helios-gui/src/uikit/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { CotChipListComponent } from './cot-chip-list/cot-chip-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from 'projects/helios-gui/src/uikit/helios-icons';
import { CotCorridorFetchPipe } from '../cot-corridor/cot-corridor-fetch.pipe';

@NgModule({
  declarations: [
    CotCalculatorComponent,
    CotGridDropdownComponent,
    CotGridInputComponent,
    CotChipListComponent
  ],
  imports: [
    CommonModule,
    InfoWidgetModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    GridCommonModule,
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    IconModule,
    AgGridModule.withComponents([
      CotGridDropdownComponent,
      CotGridInputComponent
    ]),
  ],
  exports:[
    CotCalculatorComponent,
    
  ],
  providers:[
    TitleCasePipe,
    CotCorridorFetchPipe,
    AsyncPipe,
  ]
})
export class CotCalculatorModule { }
