import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { AccessBarrierService, Message } from './access-barrier.service';

@Pipe({
  name: 'fetchAccessConfig'
})
export class FetchPipe implements PipeTransform {

  constructor(private service: AccessBarrierService) {
  }

  transform(input?: unknown): Observable<Message> {
    return this.service.run({payload: input});
  }

}
