import { Observable, of } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';

import { Assert } from '../assert';

import { Gateway } from './gateway';

import { ProjectAnalogCountriesLimitList } from '../../app/project-details/analog-filters/constants';

export interface EntityFilterEvent {
  payload?: { query: any };
}

export class EntityFilter {
  public readonly countrieLimitList = ProjectAnalogCountriesLimitList.map((value: string) => { return value.toUpperCase(); });

  constructor(private gateway: Gateway) {
    Assert.hasMethod(gateway, 'filter', `Injected gateway ${gateway} has no "filter" method`);
  }

  run(event: EntityFilterEvent): Observable<any> {
    return of(event).pipe(
      filter(() => event.payload && event.payload.query && event.payload.query.trim().length >= 2),
      debounceTime(1000),
      mergeMap(() => {
        return this.gateway.filter(event.payload);
      })
    );
  }
}
