<div class="topbar">
  <h4 class="topbar-text">{{title}}</h4>
  <button mat-icon-button (click)="openDialog()" *ngIf="helpVisible">
    <mat-icon color="primary">help</mat-icon>
    </button>
  <div class="topbar-buttons" *ngIf="isScenarioRoute()">
    <button
      mat-raised-button
      color="primary"
      class="topbar-button"
      *ngIf="isCloneScenario"
      (click)="copyScenario()"
    >
      New Scenario by Copy
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="resetEditScenario()"
      *ngIf="selectedScenarioIndex > 1 && isCreateScenarioRoute()"
      class="reset-btn"
      [disabled]="!valueChangeStatus"
      [ngClass]="!valueChangeStatus ? 'reset-btn-disabled' : 'reset-btn'"
    >
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="resetEditScenario()"
      *ngIf="isEditScenarioRoute()"
      class="reset-btn"
      [disabled]="!valueChangeStatus"
      [ngClass]="!valueChangeStatus ? 'reset-btn-disabled' : 'reset-btn'"
    >
      Reset
    </button>
    <button
      mat-raised-button
      *ngIf="isSaveScenarioChanges && isEditScenarioRoute() && !this.updateFrameworkService.isUpdateFrameWork.value"
      [disabled]="createProjectDisabled"
      (click)="editProject()"
      color="primary"
      class="topbar-button save"
    >
      Save Scenario
    </button>
  </div>
</div>
