import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconPackComponent } from './icon-pack.component';

@NgModule({
  declarations: [IconPackComponent],
  exports: [IconPackComponent],
  imports: [
    CommonModule
  ]
})
export class IconPackModule {
}
