<div
  *ngFor="let domain of valueDomains"
  class="card tab project-asset-tpp-tab"
  id="project-asset-tpp-tab"
>
  <ng-container *ngIf="domain.projectValueTypeName === 'Core'">
    <div class="card-wrapper" id="tpp-card">
      <mat-card class="example-card">
        <mat-card-header>
          <span class="heading"
            >{{ domain.valueDomainName }}
            <!-- <mat-icon>info_outline</mat-icon> -->
          </span>
        </mat-card-header>
        <div *ngIf="!showSection">
          <he-loading-spinner
            [diameter]="70"
            [height]="'350px'"
          ></he-loading-spinner>
        </div>
        <div *ngIf="showSection">
          <mat-card-content>
            <ul class="asset-tpp-list-container repeat-after-3 burden-illness">
              <ng-container *ngFor="let valueScore of valueAttributes">
                <li
                  *ngIf="domain.valueDomainName === valueScore.valueDomainName"
                  class="asset-tpp-list"
                >
                  <span class="asset-list-name age-input">{{
                    valueScore.valueAttributeName
                  }}</span>
                  <ng-container
                    *ngIf="valueScore.valueAttributeName === 'Age at diagnosis'"
                  >
                    <input
                      matInput
                      type="number"
                      pattern="/^-?[0-9][^\.]*$/"
                      (change)="ageAtDiagnosisChanged($event)"
                      class="number-input"
                      value="55"
                    />
                    <span class="years-text">years</span>
                  </ng-container>
                  <mat-button-toggle-group
                    name="ageAtDiagnosis"
                    class="asset-list-value"
                    [value]="valueScore.valueScore"
                  >
                    <ng-container *ngFor="let value of valueAttributeScores">
                      <mat-button-toggle
                        *ngIf="
                          value.valueAttributeName ===
                          valueScore.valueAttributeName
                        "
                        [value]="
                          value.score === 'NULL'
                            ? valueScore.valueScore
                            : value.score
                        "
                        (change)="
                          scoreChanged(
                            value.score,
                            valueScore.valueAttributeName
                          )
                        "
                        >{{
                          value.score === "NULL"
                            ? valueScore.valueScore
                            : value.score
                        }}</mat-button-toggle
                      >
                    </ng-container>
                  </mat-button-toggle-group>
                  <div class="tooltip-asset">
                    <mat-icon class="gap-10">info_outline</mat-icon>
                    <div class="tooltiptext-asset">
                      <div class="tooltiptext-asset-owner flex-center">
                        <span>1 - Age 60+</span><br />
                        <span>2 - Age 30-60</span><br />
                        <span>3 - Age 15-30</span><br />
                        <span>4 - Age 15</span><br />
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </ng-container>
</div>
