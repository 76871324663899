<div class="row no-gutters flex-column flex-nowrap h-100">
  <div class="col flex-grow-0 layer-z2">
    <div class="shell-toolbar he-shadow-soft">
      <div class="container">
        <div class="row no-gutters flex-nowrap align-items-center">

          <div class="col-auto">
            <button class="he-icon-button he-button-m-size d-md-none" mat-icon-button (click)="onToggleDrawer()"
              (focus)="onEnablePanelAnimation()" (blur)="onDisablePanelAnimation()">
              <he-icon id="menu"></he-icon>
            </button>
          </div>

          <div class="col-auto">
            <div class="px-2 py-3 d-none d-md-block">
              <a [routerLink]="['/home']"><he-logo></he-logo></a>
            </div>
          </div>
          <div class="col-auto">
            <div class="px-2 py-3 d-none d-md-block">
              <nav mat-tab-nav-bar class="border-0 my-n3" [tabPanel]="tabPanel">
                <a class="he-h72 px-2 no-min-width" mat-tab-link [active]="home.isActive" [routerLink]="['/home']"
                  routerLinkActive #home="routerLinkActive">
                  <span>Home</span>
                </a>
                <a class="he-h72 px-2 no-min-width" mat-tab-link [active]="irpMatrix.isActive"
                  [matTooltip]="!irpAllowed ? tabDisabledTooltip : ''"
                  matTooltipClass="he-tooltip"
                  [routerLink]="!irpAllowed ? null : ['/', routesEnum.AtlasPage, routesEnum.AtlasIrpMatrix]" routerLinkActive
                  #irpMatrix="routerLinkActive" [disabled]="!irpAllowed">
                  <span>IRP Matrix</span>
                </a>
                <a class="he-h72 px-2 no-min-width" mat-tab-link [active]="projects.isActive"
                  [matTooltip]="!projectsAllowed ? tabDisabledTooltip : ''"
                  matTooltipClass="he-tooltip"
                  [routerLink]="!projectsAllowed ? null : ['/projects']" routerLinkActive #projects="routerLinkActive" [disabled]="!projectsAllowed">
                  <span>Projects</span>
                </a>
              </nav>
              <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
            </div>
          </div>


          <div class="col offset-md-1">
            <div class="px-2 py-3">
              <he-universal-search *ngIf="searchAllowed"></he-universal-search>
            </div>
          </div>

          <div class="col-auto d-none d-md-block offset-md-2">
            <div class="px-1 py-3">
              <he-help-menu title="Help"></he-help-menu>
            </div>
          </div>

          <div class="col-auto d-none d-md-block">
            <div class="px-2 py-3">
              <he-profile-menu title="User options"></he-profile-menu>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="col flex-fill">
    <div class="he-viewport">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
