import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxAndRadioButtonComponent } from './checkbox-and-radio-button.component';

@NgModule({
  declarations: [CheckboxAndRadioButtonComponent],
  exports: [CheckboxAndRadioButtonComponent],
  imports: [
    CommonModule
  ]
})
export class CheckboxAndRadioButtonModule {
}
