<div>
  <div
  echarts
  [options]="chartOption"
  [merge]="updateChartOption" 
  class="demo-chart"
  id="cotChart"
  (chartInit)="saveChartApi($event)"
></div>
</div>
<div class="country-lables">
  <div class="country-label"*ngFor="let country of countriesInfoData">
    <img src="assets/flags/small/{{ country.flagId}}.svg" width="20px"/>
    <p>{{country.country | titlecase}}</p>
    <mat-icon #tooltip="matTooltip"
    matTooltip="{{country.infoIconData}}" matTooltipClass="horizon-tooltip">info</mat-icon>
  </div>
</div>

