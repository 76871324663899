import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldRangeComponent } from './input-field-range.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IconModule } from '../helios-icons';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [InputFieldRangeComponent],
  exports: [InputFieldRangeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    IconModule,
  ]
})
export class InputFieldRangeModule {
}
