import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Gateway } from '../../core/universal-search';

@Injectable()
export class UniversalSearchApi implements Gateway {
  constructor(private httpClient: HttpClient) {
  }

  transfer(req: any): Observable<unknown> {
    const params = { q: req?.query };
    return this.httpClient.get('/search', { params });
  }
}
