import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterMeasuresPriceCategoryComponent } from './filter-measures-price-category.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterMeasuresPriceCategoryComponent],
  exports: [FilterMeasuresPriceCategoryComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
})
export class FilterMeasuresPriceCategoryModule {
}
