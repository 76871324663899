import { asyncScheduler, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';

import { Assert } from '../assert';
import { SearchConfigurationInput, SearchConfigurationOutput } from './interfaces';

import { Gateway } from './gateway';

export class SearchConfiguration {
  static create(gateway: Gateway): SearchConfiguration {
    return new SearchConfiguration(gateway);
  }

  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'get_config', `Injected gateway ${gateway} has no "get_config" method`);
  }

  run(event: SearchConfigurationInput): Observable<any> {
    return of(event).pipe(
      mergeMap(() => {
        return this.gateway.get_config(event.payload).pipe(
          map((config: SearchConfigurationOutput) => {
            return {
              type: 'done',
              payload: {
                config,
                source: event.payload
              }
            };
          }),
          catchError<any, any>((err: any) => {
            return of({
              type: 'fail',
              error: true,
              payload: err
            });
          }),
          startWith({
            type: 'pending',
            payload: null
          }),
          subscribeOn(asyncScheduler)
        );
      })
    );
  }
}
