import { Observable } from 'rxjs';

interface MessageRef {
  payload: unknown;
}

interface Message {
  error?: boolean;
  payload: unknown;
  type: string;
}

export abstract class UniversalSearchService {
  abstract run(input: MessageRef): Observable<Message>;
}
