export function StringPropertyComparison(propertyName:string):any {

    return function compare( a:any, b:any) {
        if ( a[propertyName] < b[propertyName] ){
          return -1;
        }
        if ( a[propertyName]> b[propertyName]){
          return 1;
        }
        return 0;
    }
  }