import { Observable, asyncScheduler, of, lastValueFrom, Subject } from 'rxjs';
import { catchError, map, startWith, subscribeOn, takeUntil } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { IFeature, IFeatureFlag, IFeatureMessage, IProductWiseFeatureFlag } from './feature-flag-interfaces';
import { FeatureFlagApi } from '../../helios-api/feature-flag/feature-flag-api';
import { GERMANY_POST_AMNOG, GRID_MODE_EXPANSION, PRODUCT_SELECTOR_V2, PULSE_LANDING_PAGE_V2, ROUTING_TO_GRID_MODE, SOC_INDEX, AVAILABILITY_MAP, MEASURES_FILTER_PRESETS, PULSE_2FA_FLAG,BRAND_FILTER_PRESETS, AVAILABILITY_MAP_LAUNCHDATE } from './feature-flag-constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private productWiseData: any = [];
  private readonly refreshInHrs: number = 24;

  constructor(private featureFlagApi: FeatureFlagApi) {
  }

  private async getFeatureFlagList(destroying$: Subject<void>, productName: string = ""): Promise<IFeatureMessage> {
    const product = productName;
    let result: Observable<IFeatureMessage>;

    // Check is 'productWiseData' assigned any value
    if (this.productWiseData) {
      let productWiseFeatureFlag: IProductWiseFeatureFlag = this.productWiseData[product];
      // Check is 'productWiseData' having value for called 'product'
      if (productWiseFeatureFlag && productWiseFeatureFlag.featureList?.length > 0) {
        // Check is 'productWiseData' data of called 'product' cache has been expired or not
        if (this.getTimeDiffInHrs(productWiseFeatureFlag.lastFetchedTime) < this.refreshInHrs) {
          result = of({
            type: 'done',
            dataset: this.productWiseData[product]
          });
          return await lastValueFrom(result);
        }
      }
    }

    result = this.featureFlagApi.list(product).pipe(
      takeUntil(destroying$),
      map((response: IFeatureFlag[]) => {
        let productFeature: IProductWiseFeatureFlag = { featureList: response, lastFetchedTime: new Date() };
        this.productWiseData[product] = productFeature;
        return {
          type: 'done',
          dataset: productFeature.featureList,
          error: false,
          errorMessage: "",
        };
      }),
      catchError((err) => {
        this.productWiseData[product] = [];
        return of({
          type: 'fail',
          dataset: undefined,
          error: true,
          errorMessage: err
        });
      }),
      startWith({
        type: 'pending',
        dataset: undefined,
        error: false,
        errorMessage: "",
      }),
      subscribeOn(asyncScheduler)
    );

    return await lastValueFrom(result);
  }

  async getFeatureFlag(destroying$: Subject<void>, feature: IFeature | undefined, productName: string = ""): Promise<IFeatureFlag | undefined> {
    let result: IFeatureFlag | undefined = undefined;

    // Return if "feature" object is not passed
    if (feature == undefined) {
      return result;
    }

    // Return if "name" is not passed
    if (feature && !(typeof feature.name !== 'undefined' && feature.name)) {
      return result;
    }

    // Return if "page" is not passed
    if (feature && !(typeof feature.page !== 'undefined' && feature.page)) {
      return result;
    }

    const featureFlagLists = await this.getFeatureFlagList(destroying$, productName);

    if (featureFlagLists.type != 'done') {
      return result;
    }

    for (let product in this.productWiseData) {
      let productWiseFeatureFlag: IProductWiseFeatureFlag = this.productWiseData[product];
      let featureFlags: IFeatureFlag[] = productWiseFeatureFlag.featureList;

      if (featureFlags.length == 0) {
        continue;
      }

      for (let rNo = 0; rNo < featureFlags.length; rNo++) {
        if (featureFlags[rNo].name == feature.name && featureFlags[rNo].page == feature.page) {
          result = featureFlags[rNo];
          return result;
        }
      }
    }

    return result;
  }

  async getFlagValue(destroying$: Subject<void>, feature: IFeature | undefined, productName: string = ""): Promise<boolean> {
    const featureFlag: IFeatureFlag | undefined = await this.getFeatureFlag(destroying$, feature, productName);
    return (featureFlag != undefined ? featureFlag.isActive : false);
  }

  async get2fapulseFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, PULSE_2FA_FLAG, productName);
  }


  async getPulseLandingPageV2Flag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, PULSE_LANDING_PAGE_V2, productName);
  }

  async getGridModeExpansionFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, GRID_MODE_EXPANSION, productName);
  }

  async getRoutingToGridModeFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, ROUTING_TO_GRID_MODE, productName);
  }

  async getGermanyPostAMNOGFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, GERMANY_POST_AMNOG, productName);
  }

  async getSoCIndexFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, SOC_INDEX, productName);
  }

  async getMeasuresFiltersPresetFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, MEASURES_FILTER_PRESETS, productName);
  }

  async getBrandFiltersPresetFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, BRAND_FILTER_PRESETS, productName);
  }

  async getProductSelectorV2Flag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, PRODUCT_SELECTOR_V2, productName);
  }

  private getTimeDiffInHrs(lastFetchedTime: Date): number {
    let curTime = new Date();
    let milliSeconds: number = (curTime.getTime() - lastFetchedTime.getTime());
    // let diffInSec = (milliSeconds / 1000);
    // let diffInMin = (milliSeconds / 1000 / 60);
    let diffInHr = Math.floor(milliSeconds / 1000 / 60 / 60);
    return diffInHr;
  }

  async getAvalabilityMapFeatureFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, AVAILABILITY_MAP, productName);
  }

  async getAvalabilityMapLaunchDateFeatureFlag(destroying$: Subject<void>, productName: string = ""): Promise<boolean> {
    return await this.getFlagValue(destroying$, AVAILABILITY_MAP_LAUNCHDATE, productName);
  }
}
