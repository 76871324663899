import { SessionEnums } from "./enums";

export class SessionStoreBase {
    public setSession(propertyName: SessionEnums, propertyValue: any): string {
        let result: string = "";
        if (propertyValue === null || propertyValue === undefined) return result;
        result = JSON.stringify(propertyValue);
        sessionStorage.setItem(propertyName, result);
        return result;
    }

    getSession(propertyName: SessionEnums): string | null {
        return sessionStorage.getItem(propertyName);
    }

    removeSession(propertyName: SessionEnums) {
        sessionStorage.removeItem(propertyName);
    }
}