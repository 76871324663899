import { Component,Inject, ViewEncapsulation } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'he-analogue-helper-dialog',
  templateUrl: './analogue-helper-dialog.component.html',
  styleUrls: ['./analogue-helper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnalogueHelperDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AnalogueHelperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
