import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../../interfaces';
import { FiltersValueService } from '../../filters-value.service';
import { orphanStatusItems } from '../../../project-details/analog-filters/constants';

@Component({
  selector: 'he-filter-product-params-orphan-status',
  templateUrl: './filter-product-params-orphan-status.component.html',
  styleUrls: ['./filter-product-params-orphan-status.component.scss']
})
export class FilterProductParamsOrphanStatusComponent {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  items: FilterItemValueInterface[] = orphanStatusItems;
  title = 'Orphan status';

  constructor(private filtersValueService: FiltersValueService) {
  }

  get selectedItem(): FilterItemValueInterface {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.orphanStatus).filterValue[0] || this.items[0];
  }

  itemSelectEvent(item: FilterItemValueInterface): void {
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.orphanStatus, [item]));
  }
}
