import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cotLevelItems } from '../../../project-details/analog-filters/constants';
import { FiltersValueService } from '../../filters-value.service';
import { FiltersObject, FilterItemValueInterface, FilterNameEnum } from '../../interfaces';

@Component({
  selector: 'he-filter-cot-level',
  templateUrl: './filter-cot-level.component.html',
  styleUrls: ['./filter-cot-level.component.scss']
})
export class FilterCotLevelComponent {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  items: FilterItemValueInterface[] = cotLevelItems;
  title = 'COT Level';

  constructor(private filtersValueService: FiltersValueService) {
  }

  get selectedItem(): FilterItemValueInterface {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.cotLevel).filterValue[0] || this.items[2];
  }

  itemSelectEvent(item: FilterItemValueInterface): void {
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.cotLevel, [item]));
  }
}
