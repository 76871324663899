
function _ddMMyyyyDateToNum(date: string): number | null {
    try {
        const dateInfo = date.split('/');

        if (dateInfo.length < 3) {
            return null;
        }

        return ((parseInt(dateInfo[2]) * 10000) + (parseInt(dateInfo[1]) * 100) + parseInt(dateInfo[0]));
    }
    catch {
        return null;
    }
}

export function dateComparator(date1: string, date2: string): number {
    const date1Number = date1 === '-'?null:_ddMMyyyyDateToNum(date1);
    const date2Number = date2 === '-'?null:_ddMMyyyyDateToNum(date2);

    if (date1Number == null && date2Number == null) {
        return 0;
    }

    if (date1Number == null) {
        return -1;
    } else if (date2Number == null) {
        return 1;
    }

    // console.log("date1: %s, date2: %s, date1Number %d, date2Number: %d, diff: %d", date1, date2, date1Number, date2Number, (date1Number - date2Number));
    return date1Number - date2Number;
}

export function numberComparator(numb1: string, numb2: string) {
    const date1Number = numb1 === '-'?0:numb1 === ' '?0:JSON.parse(numb1);
    const date2Number = numb2 === '-'?0:numb2 === '-'?0:JSON.parse(numb2);
    return date1Number - date2Number;
  }
  