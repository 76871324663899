import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'he-redirect-by-url',
  templateUrl: './redirect-by-url.component.html',
  styleUrls: ['./redirect-by-url.component.scss']
})
export class RedirectByUrlComponent implements OnInit {
  @Input() url = '';

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.router.navigateByUrl(this.url, {
      skipLocationChange: false
    });
  }

}
