import { Component, Input, OnInit } from '@angular/core';
import { InfoButtonText } from '../../../app/common/constants';

@Component({
  selector: 'he-dosage-info',
  templateUrl: './dosage-info.component.html',
  styleUrls: ['./dosage-info.component.scss']
})
export class DosageInfoComponent implements OnInit {
  @Input() dataset: any;
  public infoButtonText!: string;

  columnDefs = [
    {headerName: 'Brand name', field: 'brandName'},
    {headerName: 'Country', field: 'country'},
    {headerName: 'Dosage information', field: 'dosageAssumption'},
  ];

  ngOnInit(): void {
    this.infoButtonText = InfoButtonText.DosageInfo;
  }
}
