import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class IrpDetailsApi {
  constructor(private httpClient: HttpClient) {
  }

  transfer(req: unknown): Observable<unknown> {
    return this.httpClient.get<unknown>('/irp/details?country=' + req);
  }
}
