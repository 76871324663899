import { BehaviorSubject, timer } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralInfoAsyncLoadService {

  maxElements = 3
  currentIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  elementNames: string[] = [];

  constructor() { }

  public canElementLoad(elementName: string, index: number | null) {
    let elIdx = this.elementNames.indexOf(elementName)
    if (elIdx === -1)
    {
      elIdx = this.elementNames.push(elementName) - 1
    }
    return elIdx <= (index ?? 0);
  }

  public onElementLoad() {
    timer(10).subscribe(() => {
      this.currentIndex$.next(this.currentIndex$.value + 1)
    })
  }

  public reset() {
    this.elementNames = [];
    this.currentIndex$.next(0)
  }
}
