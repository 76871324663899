import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

export interface DomainResponse {
  valueDomainId: string;
  valueDomainName: string;
  projectValueTypeId: string;
  projectValueTypeName: string;
}


@Injectable({
  providedIn: 'root'
})
export class ValueDomainsService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  constructor(private http: HttpClient) { }

  getValueDomain(): Observable<any[]>{
    return this.http.get<any[]>(this.projectAPIUri + '/ValueFramework/ValueDomains');
  }
}
