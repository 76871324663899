import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[heBlockDecimal]'
})
export class BlockDecimalDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
      const allowedKeys = ['.'];
      if (allowedKeys.includes(event.key)) {
          event.preventDefault();
      }
  }
}
