import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'he-checkbox-and-radio-button',
  templateUrl: './checkbox-and-radio-button.component.html',
  styleUrls: ['./checkbox-and-radio-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxAndRadioButtonComponent {
}
