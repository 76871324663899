import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ProjectSummaryComponent } from './components/project-summary/project-summary.component';
import { MatCardModule } from '@angular/material/card';
import { LoadingSpinnerModule } from '../../../../uikit/loading-spinner';
import { SummaryValueAssesmentComponent } from './component-pieces/summary-value-assesment/summary-value-assesment.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SummaryAssetTppComponent } from './component-pieces/summary-asset-tpp/summary-asset-tpp.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ProjectValueScoringSubGridComponent } from '../valuescoring/component-pieces/project-value-scoring-sub-grid/project-value-scoring-sub-grid.component';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../../shared/shared.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { SummaryCotForecastComponent } from './component-pieces/summary-cot-forecast/summary-cot-forecast.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { SummaryCotComparisonComponent } from './component-pieces/summary-cot-comparison/summary-cot-comparison.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    ProjectSummaryComponent,
    SummaryValueAssesmentComponent,
    SummaryAssetTppComponent,
    ProjectValueScoringSubGridComponent,
    SummaryCotForecastComponent,
    SummaryCotComparisonComponent,
  ],
  exports: [ProjectSummaryComponent],
  imports: [
    CommonModule,
    MatCardModule,
    LoadingSpinnerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTableModule,
    SharedModule,
    MatTableExporterModule,
    MatMenuModule,
    MatButtonModule,
    MatRadioModule,
    FormsModule,
    MatSelectModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ],
  providers:[CurrencyPipe]
})
export class SummaryModule {}
