<div class="row pl-3 pr-3 pl-sm-5 pr-sm-5">
  <div class="col-12 col-sm-12 border-right-sm col-xl-8 d-xl-flex flex-xl-wrap">
    <div class="mt-3 mr-3">
      <button mat-button class="p-2 enabled" disabled>
        <div class="dot-blue">
          <abbr title="Price sourced directly from the source">Published Price</abbr>
        </div>
      </button>      
    </div>
    <div class="mt-3 mr-3">
      <button mat-button class="p-2" (click)="legendService.toggleCalculatedPrice()" [class.mat-button-disabled]="!(legendService.calculatedPriceToggleValue$ | async)">
        <div class="dot-green">
          <abbr title="Price calculated using regulated margins">Calculated Price</abbr>
        </div>
      </button>
    </div>

    <div class="mt-3 mr-3">
      <button mat-button class="p-2" (click)="legendService.toggleEstimatedPrice()" [class.mat-button-disabled]="!(legendService.estimatedPriceToggleValue$ | async)">
        <div class="dot-yellow">
          <abbr title="Price calculated using estimations recommended by local experts">Estimated Price</abbr>
        </div>
      </button>
    </div>
  </div>
</div>
