<he-app-header (toggleSideBarForMe)="sideBarToggler()"></he-app-header>

<mat-drawer-container [ngClass]="sideBarOpen ? 'open' : 'close-he'">
    <mat-drawer mode="side" [opened]="true">
        <he-project-sidebar (toggleSideBarForMe)="sideBarToggler()" [sideBarOpen]="sideBarOpen"></he-project-sidebar>
    </mat-drawer>   
    <mat-drawer-content>   
        <he-project-topbar [title]="'Comparator Selection'" [helpVisible]='true'  [routeType]="'comparators'"  [firstStep]="firstStep"></he-project-topbar>
        <he-comporator-selection></he-comporator-selection>
    </mat-drawer-content>
</mat-drawer-container>
