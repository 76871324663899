import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'he-create-new-project',
  templateUrl: './create-new-project.component.html',
  styleUrls: ['./create-new-project.component.scss']
})
export class CreateNewProjectComponent {
  sideBarOpen = true;

  constructor() { }

  
  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }

}
