import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterByIndicationApi } from '../../../../helios-api/filters';
import { EntityFilter } from '../../../../core/entity-filter';
import { EMPTY, Observable, of } from 'rxjs';
import { FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../../interfaces';
import { FiltersValueService } from '../../filters-value.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { FilterAutocompleteDescriptionInterface } from '../../../../uikit/filter-autocomplete-with-chips/interfaces';
import { SharedService } from '../../../../uikit/service/shared.service';
import { Message } from '../../indication-matrix/indication-matrix.provider';
import { ResponseStatus } from '../../../common';

@Component({
  selector: 'he-filter-by-indication',
  templateUrl: './filter-by-indication.component.html',
  styleUrls: ['./filter-by-indication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterByIndicationApi,
    {
      provide: EntityFilter,
      useFactory: (gateway: FilterByIndicationApi) => {
        return new EntityFilter(gateway);
      },
      deps: [FilterByIndicationApi]
    }
  ]
})
export class FilterByIndicationComponent implements OnInit {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();
  descriptions: FilterAutocompleteDescriptionInterface = {
    title: 'Indications',
    emptyState: 'No results found',
    placeholder: 'Start typing to select',
    errorMessage: 'At least one indication should be selected to display information. To view data without a selected indication please switch to Grid mode'
  };
  filteredItems$: Observable<FilterItemValueInterface[]> | undefined;
  isLinkAvailable = true;
  loading = false;
  private selectedItemsValueBackUp: FilterItemValueInterface[] = [];
  clearValueEmitVal=false;
  constructor(
    private entityFilter: EntityFilter
    , public filtersValueService: FiltersValueService
    , private sharedService: SharedService) {
  }

  ngOnInit(): void {
    let girdModeSub$ = this.sharedService?.gridModeValue$.subscribe((mode: string) => {
      if (this.sharedService.isInsightCompanySearchTableMode) {
        this.selectedItemsValueBackUp = this.selectedItems;
        this.filtersValueService.refreshItemsInFilters(FilterNameEnum.indications, []);
      }
      else if (this.sharedService.isInsightCompanySearchChartMode && this.selectedItemsValueBackUp.length) {
        if (this.selectedItems.length == 0) {
          this.filtersValueService.refreshItemsInFilters(FilterNameEnum.indications, this.selectedItemsValueBackUp);
        }
        this.selectedItemsValueBackUp = [];
        girdModeSub$.unsubscribe();
      }
    });
  }
  get selectedItems(): FilterItemValueInterface[] {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.indications).filterValue || [];
  }

  getAllowedItems(): FilterItemValueInterface[] {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.indicationsAllowed).filterValue || [];
  }

  selectedItemChanges(indication: FilterItemValueInterface): void {
    this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.indications, [indication]));
  }

  clearValueEmit(isViaEvent: boolean): void {
    this.clearValueEmitVal=true;
    let nextValue: FiltersObject = this.filtersValueService.updateItemInFilters(FilterNameEnum.indications, []);
    this.filtersValueService.indicationFiltersUpdated = true;
    this.filtersValueModified.emit(nextValue);
  }

  onQueryChange(value: string): void {
    this.clearValueEmitVal=false;
    value = value.trim().toLowerCase();
    const isScopeLimited: boolean = this.getAllowedItems().length > 1;
    if (isScopeLimited) {
      const filteredValues = [...this.getAllowedItems()].sort((a: FilterItemValueInterface, b: FilterItemValueInterface) => a.name.localeCompare(b.name))
        .filter(item => (item.name?.toLowerCase().startsWith(value) || item.name?.toLowerCase().indexOf(' ' + value) >= 0));
      this.filteredItems$ = of(filteredValues).pipe(map(x => x));
    } else {
      this.loading = value.length >= 2;
      this.filteredItems$ = this.entityFilter.run({
        payload: {
          query: value
        }
      }).pipe(
        tap(() => this.loading = false)
      );
    }
  }

  public updateIndicationsAllowedFilter(apiOutput: Message): boolean {
    this.filtersValueService.indicationAllowedValues$ = EMPTY;
    let newFilterValue: FilterItemValueInterface[] = [];

    if (apiOutput.type == ResponseStatus.done) {
      const apiOutputPayLoad: any[] = (apiOutput.payload as any[]);
      if (apiOutputPayLoad?.length) {
        apiOutputPayLoad.map((brand: any) => {
          return brand.indications.map((indication: any) => {
            if (newFilterValue.filter(f => f.name === indication).length === 0) {
              let filterItem: FilterItemValueInterface = { name: indication };
              newFilterValue.push(filterItem);
            }
          });
        });
      }    
    }

    const nextValue: FiltersObject = this.filtersValueService.updateIndicationsAllowedItemInFilters(newFilterValue, this.clearValueEmitVal);
    this.filtersValueService.indicationAllowedValues$ = of(newFilterValue).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    
    return true;
  }
}
