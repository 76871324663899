<he-info-button class="DosageInfoButton" [infoButtonText]="infoButtonText"></he-info-button>
<ng-container *ngIf="dataset && dataset.length > 0">
  <mat-expansion-panel togglePosition="before" class="he-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>Dosage information</mat-panel-title>
    </mat-expansion-panel-header>
    <he-grid [columnDefs]="columnDefs" [rowData]="dataset">
    </he-grid>
  </mat-expansion-panel>
</ng-container>

<ng-container *ngIf="dataset && dataset.length < 1">
  <mat-expansion-panel togglePosition="before" class="he-expansion-panel" [disabled]="true" [expanded]="false" [hideToggle]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>Dosage information</mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</ng-container>
