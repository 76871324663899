<he-info-widget [supportedModes]="'table'">
  <div *heInfoButton>
    <he-info-button [infoButtonText]="infoButtonText"></he-info-button>
  </div>
  <ng-container *heInfoActions>
    <he-export-csv-button
      (export)="onSave()">
    </he-export-csv-button>
  </ng-container>

  <ng-template #loading>
    <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
  </ng-template>
  <div *heInfoTitle>{{ 'General Information' }}</div>
  <ng-container *ngIf="filters.filterValues$ | async | fetch | async as msg">
    <div *heInfoTable>
      <ng-container [ngSwitch]="msg.type">
        <ng-container *ngSwitchCase="'pending'">
          <ng-template [ngTemplateOutlet]="loading"></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'fail'">
          <he-general-info-grid
            *ngIf="filters.brandValues$ | async as brandNames"
            (gridReady)="saveApi($event, brandNames, msg)"
            [dataset]="[]"></he-general-info-grid>
        </ng-container>
        <ng-container *ngSwitchCase="'done'">
          <ng-container *ngIf="asyncLoadService.canElementLoad('general', asyncLoadService.currentIndex$ | async); else loading">
            <he-general-info-grid
              *ngIf="filters.brandValues$ | async as brandNames"
              (gridReady)="saveApi($event, brandNames, msg)"
              [dataset]="msg.payload"
            ></he-general-info-grid>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          UNKNOWN STATE
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</he-info-widget>
