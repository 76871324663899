<div class="he-frame create-new-project-wrap">
  <form class="d-flex flex-column h-100" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="title-wrap">{{header}}</div>
      <button tabindex="-1" mat-icon-button class="he-icon-button he-button" (click)="onReject($event)">
        <he-icon [id]="'suggested-symbol-x'"></he-icon>
      </button>
    </div>
    <he-project-summary-editor [form]="form"></he-project-summary-editor>

    <ng-container *ngIf="submittedForm | createProjectPipe | async as msg">
      <ng-container [ngSwitch]="msg.type">
        <ng-container *ngSwitchCase="'pending'">
          <div class="he-viewport he-white-backdrop he-layer-z1"></div>
          <div class="he-viewport he-layer-z1">
            <he-loading-spinner [diameter]="32" [height]="'100%'"></he-loading-spinner>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'fail'">
          <p>{{'An error occurred'}}</p>
        </ng-container>
        <ng-container *ngSwitchCase="'done'">
          <he-redirect-by-url *ngIf="submittedForm.type !== 3" url="{{ '/project/' + msg.payload.id }}"></he-redirect-by-url>
          <div  *ngIf="projectCreatedEvent(submittedForm.type === 3)" ></div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <p>UNKNOWN STATE</p>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="d-flex justify-content-between w-100 mt-3">
      <button mat-flat-button class="he-button he-button-grey he-lg-button" (click)="onReject($event)">Cancel</button>
      <button mat-flat-button color="primary" class="he-button ml-2 he-lg-button" type="submit"
              [disabled]="form.invalid">{{buttonText}}
      </button>
    </div>
  </form>
</div>

