import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[heAutoTrimInput]',
})
export class AutoTrimInputDirective {
  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    event.target.value = event.target.value.trim();    
  }
}
