import { Component, Input, OnChanges } from '@angular/core';
import { CountryFlagService } from '../country-flag.service';

@Component({
  selector: 'he-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent implements OnChanges {

  @Input() country = '';

  public flagId = '';

  constructor(
    private countryFlagService: CountryFlagService
  ) {}

  public ngOnChanges(): void {
    this.flagId = this.countryFlagService.getFlagId(this.country);
  }
}
