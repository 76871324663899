export enum FilterPresetTypes {
  Measures = 'measures',
}

export enum FilterStatuses {
  Default = 'default',
}


export enum FilterBrandPresetTypes {
Brand='brandpreference',
}
