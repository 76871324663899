import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PlatformAccess } from '../../core/platform-access';
import { ErrorStatusCodes } from '../common';

@Injectable()
export class PlatformGuardService implements CanActivate {

  constructor(
    private router: Router,
    private platformAccess: PlatformAccess,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const platformResponse = await this.platformAccess.run().toPromise();
    if(platformResponse?.error)
      return this.createErrorUrlTree(ErrorStatusCodes.ServiceUnavailable);

    const configuredPlatforms = platformResponse?.payload as {platform: string}[];

    const routePlatforms = route.data['platforms'];

    if(!routePlatforms?.length || !configuredPlatforms?.length)
      return this.createErrorUrlTree()

    const hasAccess = configuredPlatforms
      .filter((cp) => routePlatforms
        .find((rp: string) => rp.toLocaleLowerCase() === cp.platform.toLocaleLowerCase())).length > 0;

    if(!hasAccess) {
      if(configuredPlatforms.filter(cp => cp.platform.toLocaleLowerCase() === 'pulse').length > 0)
        return this.createPulseUrlTree();
      else return this.createHorizonUrlTree();
    }

    return hasAccess ? true : this.createErrorUrlTree();
  }


  private createErrorUrlTree(errorPagePath: string = ErrorStatusCodes.InsufficientPermissions): boolean | UrlTree | PromiseLike<boolean | UrlTree> {
    return this.router.createUrlTree(['/', 'error', errorPagePath]);
  }

  private createPulseUrlTree(): boolean | UrlTree | PromiseLike<boolean | UrlTree> {
    return this.router.createUrlTree(['/', 'home']);
  }

  private createHorizonUrlTree(): boolean | UrlTree | PromiseLike<boolean | UrlTree> {
    return this.router.createUrlTree(['/', 'horizon']);
  }
}
