import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'he-cot-chip-list',
  templateUrl: './cot-chip-list.component.html',
  styleUrls: ['./cot-chip-list.component.scss']
})
export class CotChipListComponent {
  @Input() selectedItems: any[] |null = [];
  @Input() hasRemoveIcon = true;
  @Input() changeStatusOnClick = false;
  @Input() disabled: boolean | null = null;
  @Input() disabledTooltip: string | undefined;
  @Input() isBrandChipEnable!:boolean;
  @Output() deletedItemChanges: EventEmitter<any> = new EventEmitter();
  @Output() itemUpdated: EventEmitter<void> = new EventEmitter();
  selectedChipIndex: number = -1;
  private readonly destroying$: Subject<void> = new Subject<void>();
  

  remove(item: any): void {
    this.deletedItemChanges.emit(item);
  }


  ngOnDestroy(): void {

    this.destroying$.next(undefined);

    this.destroying$.complete();

  }
}
