import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFiltersComponent } from './dynamic-filters.component';
import { ChipsListModule } from '../../chips-list';

@NgModule({
  declarations: [DynamicFiltersComponent],
  exports: [DynamicFiltersComponent],
  imports: [
    CommonModule,
    ChipsListModule
  ]
})
export class DynamicFiltersModule {
}
