import { Observable, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';

import { Assert } from '../assert';
import { Gateway } from '../gateway';

export interface FilterByCurrencyEvent {
  payload?: { query: any };
}

export class FilterByCurrency {
  constructor(private gateway: Gateway) {
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${gateway} has no "transfer" method`);
  }

  run(event: FilterByCurrencyEvent): Observable<any> {
    return of(event).pipe(
      debounceTime(1000),
      mergeMap(() => {
        return this.gateway.transfer(event.payload);
      })
    );
  }
}
