import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export interface ProjectFile {
    file: Blob;
}

@Injectable({
    providedIn: 'root'
})

export class HorizonProjectFileService {
    readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

    constructor(private http: HttpClient) {

    }

    getProjectFile(projectId: string, fileName: string) {
        return this.http
            .get(this.projectAPIUri + `/Projects/File/${projectId}/${fileName}`, {observe: 'response', responseType: 'blob'});
    }

   
}

