export function titleCaseFormatter(param: any) {
  if (param.value != 'NA') {
    if (param.value) {
      return param.value
        .toLowerCase()
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    else
      return null;
  }
  else
  return 'NA';
}

export function titleCaseFormatterText(param: any) {
      if(param)
      {
          if (param.toUpperCase() != 'NA') {
            if (param) {
              return param
                .toLowerCase()
                .split(' ')
                .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            }
            else
              return null;
          }
          else
            return 'NA';
    }
}
export function FormatAndGetListOfSocComparators(inputVal: String)
{
 return  inputVal.replace(/\([^()]*\)/g, '') // Remove text between ( )
  .split(';') // Split by ';'
  .map((item:any) => item.trim()); //trim extra spaces
}
