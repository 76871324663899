export enum marketCountries {
  All = 'All',
  EU4 = 'EU4',
  France = 'France',
  Germany='Germany',
  Italy='Italy',
  Spain='Spain',
  UK='United Kingdom',
  US='United States'
}

export enum marketAllCountries {
  France = 'France',
  Germany='Germany',
  Italy='Italy',
  Spain='Spain',
  UK='United Kingdom',
  US='United States'
}


export enum marketEU4Countries {
  France = 'France',
  Germany='Germany',
  Italy='Italy',
  Spain='Spain'
}