<ng-container *ngIf="(presetKeyName===FilterPresetTypes.Measures  && items.length > 1)">
  <button mat-icon-button #presetMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="presetMenu"
    class="he-icon-button he-button-m-size">
    <he-icon [id]="'settings'"></he-icon>
  </button>

  <mat-menu #presetMenu="matMenu" xPosition="before" class="he-menu-panel he-menu-panel-filter"
    [class.he-menu-panel-open]="dropdownOpen" style="overflow: hidden;">
    <div >
      <mat-select #presetDropdown hideSingleSelectionIndicator [title]="selectedDropdownValue"
        (click)="$event.stopPropagation()" (openedChange)="dropdownOpenedChange($event)"
        (ngModelChange)="onDropdownValueChange()" [(ngModel)]="selectedDropdownValue" class="he-mat-select"
        panelClass="he-mat-select-panel-filter">        
        <mat-option class="he-mat-option" *ngFor="let item of items" [value]="item.name" [title]="item.name">
          <div>
            {{item.name}}
          </div>
          <button *ngIf="this.hasDeleteButton && item.status !== FilterStatuses.Default" mat-icon-button
            class="he-icon-button he-button-m-size" [title]="deletePresetTooltip"
            (click)="openDeletePresetDialog($event, item)">
            <he-icon [id]="'delete-2'"></he-icon>
          </button>
        </mat-option>     
      </mat-select>
    </div>
  </mat-menu>

  <ng-container *ngIf="this.hasSaveButton && this.showSaveButton">
    <button mat-icon-button class="he-icon-button he-button-m-size" [title]="savePresetTooltip"
      (click)="openSavePresetDialog()">
      <he-icon [id]="'editor-action-save'"></he-icon>
    </button>
  </ng-container>
</ng-container>

<ng-container *ngIf="(presetKeyName===FilterBrandPresetTypes.Brand) ">
  <button *ngIf=" items.length > 0" mat-icon-button class="he-icon-button he-button-m-size"
    #presetMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="presetMenuBrand" [class.he-menu-panel-open]="dropdownOpen">
    <he-icon [id]="'settings'"></he-icon>
  </button>
  
  <mat-menu #presetMenuBrand="matMenu" xPosition="before" class="he-panel-panel he-menu-panel-filter">
    <div class="he-scrollable-menu">
    <button mat-menu-item class=" brand-saved he-mat-option brand-button" *ngFor="let item of items" [value]="item.name"
      [title]="item.name"  (click)="selectItem(item.name)">
      <div class="brand-cont">
        <div class="brand-saved-text"
          [ngClass]="item.name === this.sharedService.selectedBrandPreferenceVal$.value ? 'selected' : ''">
          {{item.name}}
        </div>
        <button *ngIf="this.hasDeleteButton && item.status !== FilterStatuses.Default" mat-icon-button
          class="he-icon-button he-button-m-size" [title]="deletePresetTooltip"
          (click)="openDeletePresetDialog($event, item)">
          <he-icon [id]="'delete-2'"></he-icon>
        </button>
      </div>
    </button>
   </div>
  </mat-menu>


  <ng-container *ngIf="this.sharedService.showBrandPrefSaveIcon$.value ">
    <button mat-icon-button class="he-icon-button he-button-m-size" [title]="savePresetTooltip"
      (click)="openSavePresetDialog()">
      <he-icon [id]="'editor-action-save'"></he-icon>
    </button>
  </ng-container>
</ng-container>



<he-filter-preset-dialog *ngIf="pendingConfirmationToClear" (reject)="onRejectDialog()"
  (confirm)="onConfirmDialog($event)"
  [text]="!isDeleteConfirmation ? saveConfirmationText : deleteConfirmationText + (' \'' + (isDeleteConfirmation ? selectedPresetToDelete : selectedPreset)?.name + '\'')"
  [confirmButtonText]="!isDeleteConfirmation ? 'Save' : 'Delete'" [showInput]="!isDeleteConfirmation"
  [inputText]="presetKeyName!=FilterBrandPresetTypes.Brand ?(!isDefaultFilterSelected ? (selectedPreset?.name ?? '') : '') : this.sharedService.selectedBrandPreferenceVal$.value">
  {{ confirmationMessage }}
</he-filter-preset-dialog>