import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssetDynamicDataService {

  defaultAssetSore: any[] = [];

  updatedAssetScore: any[] = [];

  constructor() { }

  addToDefaultAssetData(assetScore: any){
    this.defaultAssetSore.push(assetScore);
  }

  getDefaultAssetData(): any[]{
    return this.defaultAssetSore;
  }

  emptyData(){
    this.defaultAssetSore = [];
    this.updatedAssetScore = [];
  }

  copyDefaultToUpdatedAssetScore(){
    this.updatedAssetScore = JSON.parse(JSON.stringify(this.defaultAssetSore));
  }

  addOrUpdateAssetData(domain: any, attribute: any, country: string, assetScore: any){
    const existingScoreIndex = this.updatedAssetScore.findIndex(
      (f) =>
        f.ValueDomainId === domain.id && f.ValueAttributeId === attribute.id
        && f.Country.toLowerCase().trim() === country.toLowerCase().trim()
    );
    if (existingScoreIndex > -1) {
      this.updatedAssetScore[existingScoreIndex] = assetScore;
    } else {
      this.updatedAssetScore.push(assetScore);
    }
  }

  getUpdatedAssetData(): any[]{
    return this.updatedAssetScore;
  }


}
