<div
  echarts
  [options]="chartOption"
  [merge]="updateOptions"
  (chartInit)="saveChartApi($event)"
  class="demo-chart"
></div>
<div style="position:relative">
  <div class="country-lables" style="position:absolute">
    <div class="country-label"*ngFor="let country of CountryData">
      <img src="assets/{{ country | lowercase}}.svg" height="17px" />
      <p>{{country | titlecase}}</p>
    </div>
  </div>
</div>
