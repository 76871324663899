import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { IndicationMatrixProvider, Message } from './indication-matrix.provider';
import { FiltersValueService } from '../filters-value.service';
import { SetOfFiltersInterface } from '../../../core/datastore-common';
import { FiltersObject } from '../interfaces';

@Pipe({
  name: 'indicationMatrixFetch'
})
export class IndicationMatrixFetchPipe implements PipeTransform {

  constructor(
    private indicationMatrixProvider: IndicationMatrixProvider,
    private filtersValueService: FiltersValueService,
  ) {
  }

  transform(event: FiltersObject | any): Observable<Message> {
    const payload: SetOfFiltersInterface = this.filtersValueService.getSetOfFilters(event, true);
    return this.hasRequiredValue(payload) ? this.indicationMatrixProvider.run({ payload }) : of({ type: 'fail', payload: [] });
  }

  hasRequiredValue(payload: SetOfFiltersInterface): boolean {
    return !!(payload.brands.length);
  }
}
