import { Injectable } from '@angular/core';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  AnalogueTableInterface,
  ComporatorTableInterface,
  CriteriaApiInterFace,
  LegendEnum,
} from '../models/criteria.interface';
import { AnalogueMasterData } from '../../../horizon-project/models/AnalogueMaseterData';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { ProjectService } from './project.service';
import { TitleCasePipe } from '@angular/common';
import { CapitalizeFirstPipe } from '../pipes/capitalizeFirst.pipe';

@Injectable({
  providedIn: 'root',
})
export class CriteriaSelectionService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  public criteriaList = new BehaviorSubject<any>(null);
  public castCriteriaList = this.criteriaList.asObservable();

  public criteriaComparatorList = new BehaviorSubject<any>(null);
  public castCriteriaLComparatorist = this.criteriaComparatorList.asObservable();

  public openedTabindex = new BehaviorSubject<boolean>(false);
  public castopenedTabindext = this.openedTabindex.asObservable();

  public openedComparatorTabindex = new BehaviorSubject<boolean>(false);
  public castopenedComparatorTabindext = this.openedComparatorTabindex.asObservable();

  public savedTableData = new BehaviorSubject<any>([]);
  public castSavedTableData = this.savedTableData.asObservable();

  public savedComparatorTableData = new BehaviorSubject<any>([]);
  public castSavedComparatorTableData = this.savedComparatorTableData.asObservable();

  public therapyAreaList = new BehaviorSubject<any>([]);
  public castTherapyAreaList = this.therapyAreaList.asObservable();

  public savedSelectedComparators = new BehaviorSubject<any>([]);
  public castSavedSelectedComparators =
    this.savedSelectedComparators.asObservable();
    
  public savedSelectedAnalogues = new BehaviorSubject<any>([]);
  public castSavedSelectedAnalogues =
    this.savedSelectedAnalogues.asObservable();

    public sortedAnalogues = new BehaviorSubject<any>([]);
    public castsortedAnaloguesStatus =
      this.sortedAnalogues.asObservable(); 
      
      
  public sortedComparators = new BehaviorSubject<any>([]);
  public castsortedComparatorsStatus =
      this.sortedComparators.asObservable();

      
  public brandCountData = new BehaviorSubject<any>([]);
  public castBrandCountData = this.brandCountData.asObservable();
  
  // public comparatorCountData = new BehaviorSubject<any>([]);
  // public castComparatorCountData = this.comparatorCountData.asObservable();

  public isAnaloguesValid:boolean = false;
  public isComparatorValid:boolean = false;

  constructor(private http: HttpClient,private countryFlagService: CountryFlagService,private capitalizeFirstPipe: CapitalizeFirstPipe,) {}

  public cleaSelectionCriteria(): void {
    this.criteriaList.next(null);
    this.criteriaComparatorList.next(null);
    this.openedTabindex.next(false);
    this.openedComparatorTabindex.next(false);
    this.savedTableData.next([]);
    this.savedComparatorTableData.next([]);
    this.brandCountData.next([]);
    this.savedSelectedAnalogues.next([]);
    this.savedSelectedComparators.next([]);
  }

  public getAnalogueSelectionCriteria(framework:string): Observable<CriteriaApiInterFace> {
    return this.http.get<CriteriaApiInterFace>(
      `${this.projectAPIUri}/Master/GetAnalogueSelectionCriteria?FrameworkName=${framework}`
    );
  }
    public GetCategoryCriteria(): Observable<CriteriaApiInterFace> {
    return this.http.get<CriteriaApiInterFace>(
      `${this.projectAPIUri}/Analogue/GetCategoryCriteria?FrameworkName=Oncology`
    );
  }

  public getComparatorDefault(grid:string): Observable<any> {
    return this.http.get<CriteriaApiInterFace>(
      `${this.projectAPIUri}/Analogue/GetCompatorAnalogueSelectedColumns?gridViewType=${grid}`
    );
  }  
  public getComparatorSelected(): Observable<any> {
    return this.http.get<CriteriaApiInterFace>(
      `${this.projectAPIUri}/UserConfig/GetUserPreference?PreferenceTypes=comparatorGrid`
    );
  }

  public setColumns(body: any): Observable<any> {
    const urlString = new URL(
      `${this.projectAPIUri}/UserConfig/AddUserPreference`
    );
    return this.http.post<any>(urlString.href, body);
  }

  public getComparator(body: any): Observable<ComporatorTableInterface[]> {
    const urlString = new URL(
      `${this.projectAPIUri}/Analogue/GetComparatorAnalogueByCriteria`
    );
    return this.http.post<ComporatorTableInterface[]>(urlString.href, body).pipe(
      map((analogue: any | null) => {
        return analogue.map((res: any) => {
          return {
            toggle: {
              toggle:false,
              disable:false
            },
            Brand: res.brand.value,
            Orphan: {
              name: res.orphan.value,
              color: LegendEnum[res.orphan.heatMapCode],
            },
            INN: res.inn.value,
            Country:this.setMarkeIds(res.countries),
            HTAIndication: {
              name: this.capitalizeFirstPipe.transform(res.htaIndication.value) ,
              color: LegendEnum[res.htaIndication.heatMapCode],
            },        
            DurationOfTreatment: {
              name: res.durationOfTreatment.value,
              color: LegendEnum[res.durationOfTreatment.heatMapCode],
            },            
            BiomarkerGenetyping: {
              name: res.biomarkerGenetyping.value,
              color: LegendEnum[res.biomarkerGenetyping.heatMapCode],
              order: res.biomarkerGenetyping.heatMapCode,
            },  
            lineOffTherapy: {
              name: res.lineOffTherapy.value,
              color: LegendEnum[res.lineOffTherapy.heatMapCode],
              order: res.lineOffTherapy.heatMapCode,
            },  
            Population: {
              name: res.population.value,
              color: LegendEnum[res.population.heatMapCode],
              order: res.population.heatMapCode,
            },  
            EligiblePatientNumber: {
              name: res.eligiblePatientNumber.value,
              color: LegendEnum[res.eligiblePatientNumber.heatMapCode],
            },  
            Mechanismofaction: {
              name: res.moaCategory.value,
              color: LegendEnum[res.moaCategory.heatMapCode],
            },      
            InitialLaunchDate: {
              name: res.initialLaunchDate.value,
              color: LegendEnum[res.initialLaunchDate.heatMapCode],
            },       
            MoaCategory: {
              name: res.moaCategory.value,
              color: LegendEnum[res.moaCategory.heatMapCode],
            },          
            PriorReimbursedIndications: {
              name: res.priorReimbursedIndications.value,
              color: LegendEnum[res.priorReimbursedIndications.heatMapCode],
            },            
            RouteOfAdministration: {
              name: res.routeOfAdministration.value,
              color: LegendEnum[res.routeOfAdministration.heatMapCode],
            },       
            TherapyRegimen: {
              name: res.therapyRegimen.value,
              color: LegendEnum[res.therapyRegimen.heatMapCode],
            },           
            Tli: {
              name: res.tli.value,
              color: LegendEnum[res.tli.heatMapCode],
            },            
            TypeOfCombination: {
              name: res.typeOfCombination.value,
              color: LegendEnum[res.typeOfCombination.heatMapCode],
            },
          };
        }).sort((a:any, b:any) => {
          return this.compare(a.Brand, b.Brand,true)
        });
      })
    );
  }

  public getAnalogue(body: any): Observable<AnalogueTableInterface[]> {
    const urlString = new URL(
      `${this.projectAPIUri}/Analogue/GetAnalogueByCriteria`
    );
    return this.http.post<AnalogueTableInterface[]>(urlString.href, body).pipe(
      map((analogue: any | null) => {
        return analogue.map((res: any) => {
          return {
            toggle:  {
              toggle:false,
              disable:false
            },
            Brand: res.brand.value,
            Orphan: {
              name: res.orphan.value,
              color: LegendEnum[res.orphan.heatMapCode],
              order: res.orphan.heatMapCode,
            },
            INN: res.inn.value,
            Country:this.setMarkeIds(res.countries),
            Oneofftherapy: {
              name: res.oneOffTherapy.value,
              color: LegendEnum[res.oneOffTherapy.heatMapCode],
              order: res.oneOffTherapy.heatMapCode,

            },
            Eligiblepatientnumber: {
              name: res.eligiblePatientNumber.value,
              color: LegendEnum[res.eligiblePatientNumber.heatMapCode],
              order: res.eligiblePatientNumber.heatMapCode,

            },
            Population: {
              name: res.population.value,
              color: LegendEnum[res.population.heatMapCode],
              order: res.population.heatMapCode,

            },
            Indication: {
              name: res.tli === null? '':res.tli.value,
              color:res.tli === null? LegendEnum[0]: LegendEnum[res.tli.heatMapCode],
              order:res.tli === null? 0: res.tli.heatMapCode,
            },
            Routeofadministration: {
              name: res.routeOfAdministration.value,
              color: LegendEnum[res.routeOfAdministration.heatMapCode],
              order: res.routeOfAdministration.heatMapCode,

            },
            Mechanismofaction: {
              name: res.moaCategory.value,
              color: LegendEnum[res.moaCategory.heatMapCode],
              order: res.moaCategory.heatMapCode,

            },
            //needs to be changes to HTA
            HTAIndication: {
              name: res.indicationHTA === null? '':this.capitalizeFirstPipe.transform(res.indicationHTA.value) ,
              color:res.indicationHTA === null? LegendEnum[0]: LegendEnum[res.indicationHTA.heatMapCode],
              order:res.indicationHTA === null? 0: res.indicationHTA.heatMapCode,
            },
            lineOffTherapy: {
              name:res.lineOffTherapy === null? '': res.lineOffTherapy.value,
              color: res.lineOffTherapy === null? LegendEnum[0]:LegendEnum[res.lineOffTherapy.heatMapCode],
              order:res.lineOffTherapy === null? 0: res.lineOffTherapy.heatMapCode,
            },  
            BiomarkerGenetyping: {
              name: res.biomarkerGenetyping === null? '':res.biomarkerGenetyping.value,
              color: res.biomarkerGenetyping === null?LegendEnum[0]:LegendEnum[res.biomarkerGenetyping.heatMapCode],
              order: res.biomarkerGenetyping === null?0:res.biomarkerGenetyping.heatMapCode,
            },  
            //needs to be changes to HTA
            InitialLaunchDate: {
              name: res.initialLaunchDate === null? '':res.initialLaunchDate.value,
              color: res.initialLaunchDate === null? LegendEnum[0]:LegendEnum[res.initialLaunchDate.heatMapCode],
              order:res.initialLaunchDate === null? 0: res.initialLaunchDate.heatMapCode,
            },
            DurationOfTreatment: {
              name:  res.durationOfTreatment === null? '':res.durationOfTreatment.value,
              color: res.durationOfTreatment === null? LegendEnum[0]:LegendEnum[res.durationOfTreatment.heatMapCode],
              order:res.durationOfTreatment === null? 0: res.durationOfTreatment.heatMapCode,
            }, 
            EligiblePatientNumber: {
              name: res.eligiblePatientNumber.value,
              color: LegendEnum[res.eligiblePatientNumber.heatMapCode],
              order:res.eligiblePatientNumber.heatMapCode,
            },  
            RouteOfAdministration: {
              name: res.routeOfAdministration.value,
              color: LegendEnum[res.routeOfAdministration.heatMapCode],
              order:res.routeOfAdministration.heatMapCode,
            },   
            MoaCategory: {
              name: res.moaCategory.value,
              color: LegendEnum[res.moaCategory.heatMapCode],
              order: res.moaCategory.heatMapCode,
            },    
            TherapyRegimen: {
              name: res.therapyRegimen === null? '':res.therapyRegimen.value,
              color:res.therapyRegimen === null? LegendEnum[0]: LegendEnum[res.therapyRegimen.heatMapCode],
              order:res.therapyRegimen === null? 0: res.therapyRegimen.heatMapCode,
            },   
            TypeOfCombination: {
              name: res.typeOfCombination === null? '':res.typeOfCombination.value,
              color: res.typeOfCombination === null? LegendEnum[0]:LegendEnum[res.typeOfCombination.heatMapCode],
              order:res.typeOfCombination === null? 0: res.typeOfCombination.heatMapCode,
            },
            PriorReimbursedIndications: {
              name:  res.priorreimbursedindication === null? '':res.priorreimbursedindication.value,
              color:  res.priorreimbursedindication === null? LegendEnum[0]:LegendEnum[res.priorreimbursedindication.heatMapCode],
              order:res.priorreimbursedindication === null? 0: res.priorreimbursedindication.heatMapCode,

            },       
            IndicationCategory: {
              name: res.indicationCategory === null? '':res.indicationCategory.value,
              color:res.indicationCategory === null? LegendEnum[0]: LegendEnum[res.indicationCategory.heatMapCode],
              order:res.indicationCategory === null? 0: res.indicationCategory.heatMapCode,
            },
            // Tli: {
            //   name: res.tli === null? '':res.tli.value,
            //   color:res.tli === null?LegendEnum[0]:LegendEnum[res.tli.heatMapCode],
            //   order:res.tli === null? 0: res.tli.heatMapCode,
            // },          
            //Might needed later
            // TherapyArea: {
            //   name: res.therapyArea.value,
            //   color: LegendEnum[res.therapyArea.heatMapCode],
            // },
            // IndicationCategory: {
            //   name:res.indicationCategory.value ,
            //   color: LegendEnum[res.indicationCategory.heatMapCode],
            // },
          };
        }).sort((a:any, b:any) => {
          return this.compare(a.Brand, b.Brand,true)
        });
      })
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public setMarkeIds(countries:string[]):any[]{
    let ids:any=[]
    countries.forEach(country => {
      ids.push({
        name:country,
        id:this.countryFlagService.getFlagId(country)})
    });
    return ids
  }

  public randomNo():number{
    return Math.floor(Math.random() * 4)
  }

  public getAnalogueMaster(
    TLI: string,
    MOA: string
  ): Observable<AnalogueMasterData[]> {
    const urlString = new URL(
      `${this.projectAPIUri}/Analogue/GetAnalogueMaster?TLI=${TLI}&MOA=${MOA}`
    );
    return this.http.get<AnalogueMasterData[]>(urlString.href);
  }
}
