import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gateway } from '../../core/gateway';

@Injectable()
export class PlatformAccessApi implements Gateway{

  constructor(private httpClient: HttpClient) {
  }

  transfer(): Observable<unknown> {
    return this.httpClient.get<unknown>('/useraccess/platforms');
  }
}
