import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TawkChatService } from './tawk-chat.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [TawkChatService],
  exports: []
})
export class TawkChatModule {
}
