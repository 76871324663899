import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SummaryService } from '../../services/summary.service';
import { Subject, takeUntil } from 'rxjs';
import { saveAs } from 'file-saver';
import { Papa } from 'ngx-papaparse';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
@Component({
  selector: 'he-summary-asset-tpp',
  templateUrl: './summary-asset-tpp.component.html',
  styleUrls: ['./summary-asset-tpp.component.scss'],
})
export class SummaryAssetTppComponent implements OnInit, OnDestroy {
  @Input() scoreMode: any;

  panelOpenState: boolean = false;

  public ValueAttributes: any = [];

  ColorCodes = ['#514890', '#857aaf', '#035d86', '#2891a3', '#7e7e9d'];

  activeAccordianList: any[] = [];
  scenariosList: any[] = [];
  projectId: any;
  public assetTppData: any = [];
  public ValueDomains: any = [];
  public filteredValueDomains: any = [];
  country: any = 'germany';
  private unsubscriber$ = new Subject<void>();


  constructor(
    public activatedRouter: ActivatedRoute,
    public summaryService: SummaryService,
    private papa: Papa,
    private countryFlagService: CountryFlagService
  ) {}

  ngOnInit(): void {
    this.activatedRouter.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(async (params) => {
      await this.initializeCompData()
      this.projectId = params.project;
      if (this.projectId) {
        this.getAssetTPPData(this.projectId);
      }
    });
  }

  private async initializeCompData() {
    this.projectId = '';
    this.assetTppData = [];
    this.ValueDomains = [];
    this.filteredValueDomains = [];
    this.scenariosList = [];
    this.activeAccordianList = [];
  }

  getAssetTPPData(projectId: any) {
    this.summaryService.getAssetScoreSummary(projectId).then((res) => {
      this.assetTppData = res;
      this.assetTppData.data.sort((a: any, b: any) => {
        return a.scenarioSequence - b.scenarioSequence;
      })
      this.assetTppData.data.forEach((element: any, index: any) => {
        if (
          this.scenariosList.filter(
            (x: any) => x.scenarioName === element.scenario
          ).length === 0
        ) {
          this.scenariosList.push({
            id: index,
            scenarioName: element.scenario,
          });
        }
        element.assetScore.assetTLIs[0].valueDomains.forEach(
          (valueDomain: any) => {
            if (
              this.ValueDomains.filter(
                (x: any) => x.name === valueDomain.domainName
              ).length === 0
            ) {
              this.ValueDomains.push({
                name: valueDomain.domainName,
                values: [],
                difference: [],
              });
            }
            valueDomain.valueAttributes.forEach((valueAttribute: any) => {
              if (
                this.ValueDomains.filter(
                  (x: any) => x.name === valueDomain.domainName
                )[0].values.filter(
                  (y: any) => y.name === valueAttribute.valueAttributeName && y.country === valueAttribute.country
                ).length === 0
              ) {
                this.ValueDomains.filter(
                  (x: any) => x.name === valueDomain.domainName
                )[0].values.push({
                  name: valueAttribute.valueAttributeName,
                  country: valueAttribute.country,
                  flagId: this.countryFlagService.getFlagId(valueAttribute.country),
                  scenarios: [],
                });
              }
              if (
                this.ValueDomains.filter(
                  (x: any) => x.name === valueDomain.domainName
                )[0]
                  .values.filter(
                    (y: any) => y.name === valueAttribute.valueAttributeName && y.country === valueAttribute.country
                  )[0]
                  .scenarios.filter(
                    (z: any) => z.scenarioName === element.scenario
                  ).length === 0
              ) {
                this.ValueDomains.filter(
                  (x: any) => x.name === valueDomain.domainName
                )[0]
                  .values.filter(
                    (y: any) => y.name === valueAttribute.valueAttributeName && y.country === valueAttribute.country
                  )[0]
                  .scenarios.push({
                    scenarioName: element.scenario,
                    score: valueAttribute.score,
                    description: valueAttribute.questionScores.filter(
                      (x: any) => x.score === valueAttribute.score
                    )[0].description,
                  });
              }
            });
          }
        );
      });
      this.setDefaultDiff();
      this.arrangeValueDomains(this.ValueDomains);
      this.calculateDifference(this.ValueDomains);
    });
  }

  public setDefaultDiff(): void {
    this.ValueDomains.forEach((ValueDomain: any) => {
      let difference: any[] = [];
      this.scenariosList.forEach((res) => {
        difference.push({
          id: res.id,
          scenarioName: res.scenarioName,
          difference: 0,
        });
      });
      ValueDomain.difference = difference;
    });
  }

  calculateDifference(valueDomain: any) {
    valueDomain.forEach((element: any) => {
      if (
        this.filteredValueDomains.filter((x: any) => x.name === element.name)
          .length === 0
      ) {
        this.filteredValueDomains.push({
          name: element.name,
          values: [],
          difference:element.difference,

        });
      }
      element.values.forEach((value: any) => {
        let status = this.removeMatchingScenariosScore(value.scenarios);
        if (status) {
          if (
            this.filteredValueDomains
              .filter((x: any) => x.name === element.name)[0]
              .values.filter((y: any) => y.name === value.name && y.country === value.country ).length === 0
          ) {
            this.filteredValueDomains
              .filter((x: any) => x.name === element.name)[0]
              .values.push(
                valueDomain
                  .filter((x: any) => x.name === element.name)[0]
                  .values.filter((y: any) => y.name === value.name && y.country === value.country)[0]
              );
          }
        }
      });
    });
    this.countScenarioAttributesScoreDifference(this.filteredValueDomains);
  }

  removeMatchingScenariosScore(scenarios: any): boolean {
    if (scenarios.length <= 1) {
      return false;
    }

    const firstScore = scenarios[0].score;

    for (let i = 1; i < scenarios.length; i++) {
      if (scenarios[i].score !== firstScore) {
        return true;
      }
    }
    return false;
  }

  countScenarioAttributesScoreDifference(valueDomains: any): any {
    valueDomains.forEach((valueDomain: any) => {
      valueDomain.values.forEach((value: any) => {
        for (let i = 1; i <= value.scenarios.length - 1; i++) {
          if (value.scenarios[0].score !== value.scenarios[i].score) {
            valueDomain.difference.forEach((diff:any) => {
              if(diff.scenarioName === value.scenarios[i].scenarioName){
                diff.difference = diff.difference + 1;
              }
            });
          }
        }
      });
    });
  }

  arrangeValueDomains(valueDomains: any) {
    const desiredOrder = [
      'Burden',
      'Product characteristics',
      'Trial design',
      'Clinical benefit',
      'Other',
    ];
    const sortedValuedomains = valueDomains.sort((a: any, b: any) => {
      const indexA = desiredOrder.indexOf(a.name);
      const indexB = desiredOrder.indexOf(b.name);
      return indexA - indexB;
    });
    sortedValuedomains.forEach((element: any) => {
      element.values = this.arrangeAttributes(element.values);
    })
    this.ValueDomains = sortedValuedomains;
  }

  arrangeAttributes(valueAttributes: any) {
    const order = ['All', 'France', 'Germany', 'United Kingdom', 'United States'];

    valueAttributes.sort((a:any, b:any) => {
      if (a.country === b.country) {
        return a.name.localeCompare(b.name);
      } else if (a.country === 'All') {
        return -1;
      } else if (b.country === 'All') {
        return 1;
      } else {
        return order.indexOf(a.country) - order.indexOf(b.country);
      }
    });
  
    return valueAttributes;
  }

  openAccordian(panelName:any){
    if(this.activeAccordianList.includes(panelName)){
      this.activeAccordianList.splice(this.activeAccordianList.indexOf(panelName), 1);
    }
    else{
      this.activeAccordianList.push(panelName);
    }
  }

  exportToCsv(): void {
    const dataArray = this.filteredValueDomains;
    const csvData: any[] = [];
    const headerRow: any[] = ['Domain Name'];
  
    // Get all unique scenario names from the difference array
    const scenarioNames: string[] = Array.from(
      new Set(dataArray.flatMap((item:any) => item.difference.map((diff:any) => diff.scenarioName.trim())))
    );
    headerRow.push(...scenarioNames);
    csvData.push(headerRow);

    // Loop through each item in the array
    for (const item of dataArray) {
      const index = this.filteredValueDomains.indexOf(item);
      // const rowData: any[] = [`<span style="background-color: ${this.ColorCodes[index]}">${item.name}</span>`, ...scenarioNames.map(() => '')];
      const rowData: any[] = [`${item.name}`, ...scenarioNames.map(() => '')];

      let isFirstScenario = true;
      for (const diff of item.difference) {
        const index = scenarioNames.indexOf(diff.scenarioName.trim());
        rowData[index + 1] = `${diff.difference === 0 && diff.scenarioName.trim()===scenarioNames[0]? '--' :diff.difference}${isFirstScenario ? '' : ` differences from ${scenarioNames[0]}`}`;
        isFirstScenario = false;
      }
      csvData.push(rowData);
      if(item.values.length < 1){
        const rowData:any[] =['', ...scenarioNames.map(() => '')];
        csvData.push(rowData);
      }
      else{
      for (const value of item.values) {
        const rowData: any[] = [value.name + (value.name.includes('Patient number')?'':" (" + value.country + ")"), ...scenarioNames.map(() => '')];
        for (const scenario of value.scenarios) {
          const index = scenarioNames.indexOf(scenario.scenarioName.trim());
          rowData[index + 1] = `${this.scoreMode === '2' ? scenario.score + " : " + scenario.description : scenario.score}`;
        }
        csvData.push(rowData);
      }
      // Add an empty row after each domain
      const rowData:any[] =['', ...scenarioNames.map(() => '')];
      csvData.push(rowData);
    }
    }

    const csvString = this.papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'AssetTPPSummary.csv');
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
