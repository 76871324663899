import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialIconsComponent } from './material-icons.component';

@NgModule({
  declarations: [MaterialIconsComponent],
  exports: [MaterialIconsComponent],
  imports: [
    CommonModule
  ]
})
export class MaterialIconsModule { }
