import { Observable, asyncScheduler, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, subscribeOn } from 'rxjs/operators';

import { Assert } from '../assert';

import { Gateway } from '../gateway';
import { ScopesDetailsInput, ScopesDetailsOutputMessage } from './interfaces';

export class ScopesDetails {
  static create(gateway: Gateway): ScopesDetails {
    return new ScopesDetails(gateway);
  }

  constructor(private gateway: Gateway) {
    // Injected values are not verified automatically after compilation.
    Assert.hasMethod(gateway, 'transfer', `Injected gateway ${ gateway } has no "transfer" method`);
  }

  run(req: ScopesDetailsInput): Observable<ScopesDetailsOutputMessage> {
    return of(req).pipe(
      mergeMap(() => {
        return this.gateway.transfer(req.payload).pipe(
          map((response: unknown) => {
            return {
              type: 'done',
              payload: response
            };
          }),
          catchError((err: any) => {
            return of({
              type: 'fail',
              error: true,
              payload: err
            });
          }),
          startWith({
            type: 'pending',
            payload: {}
          }),
          subscribeOn(asyncScheduler)
        );
      })
    );
  }
}
