import { Component , ViewChild , TemplateRef } from '@angular/core';
import { HorizonProjectService } from '../../../../horizon-project/horizon-project.service';
import { OnInit , AfterViewInit} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeleteProjectService } from '../../services/delete-project.service';
import { BehaviorSubject } from 'rxjs';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';


@Component({
  selector: 'he-delete-project',
  templateUrl: './delete-project.component.html',
  styleUrls: ['./delete-project.component.scss']
})
export class DeleteProjectComponent implements OnInit,AfterViewInit {
  modalRef!: BsModalRef;
  dataSource = new MatTableDataSource([{}]);

  @ViewChild(MatPaginator)paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<any>;
  
  public projectData: any[] = [];
  public displayedColumns: string[] = ['projectId', 'name', 'createdBy','ownerEmail','actions'];

  public projectId = new BehaviorSubject<any>('');
  public castProjectId = this.projectId.asObservable();
  
  constructor(private horizonProjectService: HorizonProjectService,
    private modalService: BsModalService,
    private deleteProjectService:DeleteProjectService,
    private toastNotificationService:ToastNotificationService
    ) { }

  ngOnInit(): void {
    this.horizonProjectService.getProjectList().then((data) => {
      this.projectData = data;
      this.dataSource = new MatTableDataSource(this.projectData);
      this.dataSource.paginator = this.paginator;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  message!: string;
  openModal(template: TemplateRef<any>,projectId:any) {
    this.projectId.next(projectId);
    this.modalRef = this.modalService.show(template, {class: 'delete-project-modal'},);
  }

  confirm(): void {
    this.deleteProjectService.deleteProject(this.projectId.value).then((data) => {
      if(data.status == 1){
        this.deleteProjectService.getProjectFiles(this.projectId.value).then((data) => {
          if(data.length > 0){
            this.deleteProjectService.deleteContainer(this.projectId.value).then((data) => {
              if(data.status == 1){
                this.updateProjectsData();
                this.toastNotificationService.successMessage(
                  'Project Deleted successfully'
                );
              }
              else{
                this.toastNotificationService.errorMessage(
                  'Project Deletion Failed'
                );
              }
            })
          }
          else{
            this.updateProjectsData();
            this.toastNotificationService.successMessage(
              'Project Deleted successfully'
            );
          }
        })
      }
      else{
        this.toastNotificationService.errorMessage(
          'Project Deletion Failed'
        );
      }
    }).catch((err) => {
      if(err.error.status == 0 && err.error.data.includes("do not have permissions to delete project")){
        this.toastNotificationService.errorMessage(
          'You do not have permissions to delete this project'
        );
      }
      else{
        this.toastNotificationService.errorMessage(
          'Project Deletion Failed'
        );
      }
    })
    this.modalRef.hide();
  }
 
  decline(): void {
    this.modalRef.hide();
  }

  updateProjectsData(){
    this.horizonProjectService.getProjectList().then((data) => {
      this.projectData = data;
      this.dataSource = new MatTableDataSource(this.projectData);
      this.dataSource.paginator = this.paginator;
    });
  }

}
