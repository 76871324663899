import { Pipe, PipeTransform } from '@angular/core';

export interface Splitted {
  prefix: string;
  match: string;
  suffix: string;
}

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  indexval: number = -1;
  searchExist: boolean = false;
  transform(text: string, symbols: string): Splitted {
    this.indexval = -1;
    this.searchExist = false;

    if (symbols == null) {
      symbols = ' ';
    }

    const char: any = text.charAt(0);
    if (!isNaN(char)) {
      text = char + text.charAt(1).toLowerCase() + text.slice(2);
    }

    //search sting starting of the result
    if (text.toLowerCase().startsWith(symbols.toLowerCase())) {
      this.indexval = 0;
      this.searchExist = true
    }

    if (!this.searchExist) {
      // search string has special character
      const hasSpecialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(symbols);

      if (hasSpecialChars) {
        if (text.toLowerCase().includes(symbols.toLowerCase())) {
          this.indexval = text.toLocaleLowerCase().indexOf(symbols.toLowerCase());
          this.searchExist = true
        }
      }
      //search string in middle word's starting of the result
      else {
        const regex = new RegExp(`(?<!^) \\b${symbols.toLowerCase()}`);
        this.searchExist = regex.test(text.toLowerCase());

        if (this.searchExist) {
          const match = regex.exec(text.toLowerCase());
          this.indexval = Number(match?.index) + 1;
        }
      }
    }

    return this.indexval < 0 ? {
      prefix: text,
      match: '',
      suffix: ''
    } : {
      prefix: text.slice(0, this.indexval),
      match: text.slice(this.indexval, this.indexval + symbols.length),
      suffix: text.slice(this.indexval + symbols.length)
    };
  }
}
