import { Component } from '@angular/core';
import { LegendService } from './legend.service';

@Component({
  selector: 'he-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent {

  constructor(
    public legendService: LegendService
  ) { }

}
