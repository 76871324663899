import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBodySurfaceAreaComponent } from './filter-body-surface-area.component';
import { FilterSliderModule } from 'projects/helios-gui/src/uikit/filter-slider/filter-slider.module';



@NgModule({
  declarations: [
    FilterBodySurfaceAreaComponent
  ],
  exports:[FilterBodySurfaceAreaComponent],
  imports: [
    CommonModule,
    FilterSliderModule
  ]
})
export class FilterBodySurfaceAreaModule { }
