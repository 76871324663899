import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return false;
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const configScopes: string[] = environment.apiConfig.scopes;
  return {
    interactionType: inIframe() ? InteractionType.Popup : InteractionType.Redirect,
    authRequest: {
      scopes: configScopes,
    },
  };
}
