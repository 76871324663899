import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnalogueRegulatoryApprovalFormat } from '../../../../horizon-project/models/AnalogueRegulatoryApprovalFormat';
import {
  ToReadableDate,
  dmy_ymd,
} from '../../../../horizon-project/utility-functions/DateFormat';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { timelineInterface } from '../../models/timeline.interface';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { ColorSetService } from '../../../../shared/services/colorSet.service';

@Component({
  selector: 'he-angalogue-timeline',
  templateUrl: './angalogue-timeline.component.html',
  styleUrls: ['./angalogue-timeline.component.scss'],
})
export class AngalogueTimelineComponent implements OnInit, OnChanges {
  @Output() selectOption = new EventEmitter<string>();
  @Input() public viewMode: string = 'Country';
  @Input() analogueRegulatoryData?: AnalogueRegulatoryApprovalFormat[];

  public selectedOption: string = 'Country';
  public TimelineList?: timelineInterface[] = [];
  public timelineOptions: string[] = [];
  public countryOptions: string[] = [];
  public analogueOptions: string[] = [];
  public showSection = false;

  constructor(
    private analogueService: AnalogueService,
    public countryFlagService: CountryFlagService,
    public colorSetService: ColorSetService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    //if the project is being changed and new analogueRegulatoryData is coming in
    if (Object.keys(changes).some((x) => x === 'analogueRegulatoryData')) {
      this.timelineOptions = [];
      this.countryOptions = [];
      this.analogueOptions = [];
      this.setOption();
      this.changeView();
    }
    //if the view mode is change which is coming from parent component
    if (Object.keys(changes).some((x) => x === 'viewMode')) {
      this.changeView();
    }
  }

  ngOnInit(): void {
    this.analogueService.analogueRegulatoryDataLoading.subscribe((data) => {
      this.showSection = !data;
    });
  }

  // set option for countries and analogue dropdown
  public setOption(): void {
    this.analogueRegulatoryData?.forEach((res) => {
      //null check for usa regulatoryApprovalDate
      // if (res.regulatoryApprovalDate != null) {
      if (
        !this.countryOptions.some(
          (x) => x.toLowerCase() === res.country.toLowerCase()
        )
      ) {
        this.countryOptions.push(res.country);
      }
      if (
        !this.analogueOptions.some(
          (x) => x.toLowerCase() === res.brandName.toLowerCase()
        )
      ) {
        this.analogueOptions.push(res.brandName);
      }
      // }
    });
  }

  // set timeline list for selected analogue
  public setAnalogueData(): void {
    let reimburse: timelineInterface[] = [];
    let newTimeline: timelineInterface[] = [];
    this.analogueRegulatoryData
      ?.filter(
        (x) => x.brandName.toLowerCase() === this.selectedOption.toLowerCase()
      )
      //sort by name just in case they have same initialLaunchDate
      .sort((a, b) => a.country?.localeCompare(b.country))
      //sort by initialLaunchDate to get the oldest initialLaunchDate from the list
      ?.sort(
        (a, b) =>
          new Date(dmy_ymd(a.initialLaunchDate)).getTime() -
          new Date(dmy_ymd(b.initialLaunchDate)).getTime()
      )
      .forEach((x) => {
        // to add oldest initialLaunchDate to the list which will be the EMA/FDA/MRHA approval date
        if (
          !reimburse.some(
            (y) =>
              y.approvalName?.toLowerCase().trim() ===
              x.regulatoryAgency.toLowerCase().trim()
          )
        ) {
          reimburse.push({
            name: x.regulatoryAgency + ' Approval',
            approvalName: x.regulatoryAgency,
            sortDate: x.initialLaunchDate,
            approval: ToReadableDate(x.initialLaunchDate),
            reimburse: ToReadableDate(x.initialLaunchDate),
            color: '',
            img: 'assets/time-line.svg',
            country: x.country,
          });
        }
        if (
          !newTimeline.some(
            (y) =>
              y.country?.toLowerCase().trim() === x.country.toLowerCase().trim()
          )
        ) {
          newTimeline.push({
            name: x.country + ' reimbursement decision',
            sortDate: x.regulatoryApprovalDate,
            approval:
              x.regulatoryApprovalDate !== 'NA' &&
              x.regulatoryApprovalDate !== null
                ? ToReadableDate(x.regulatoryApprovalDate)
                : 'NA',
            reimburse: ToReadableDate(x.initialLaunchDate),
            color: '',
            img:
              'assets/flags/small/' +
              this.countryFlagService.getFlagId(x.country.toLowerCase()) +
              '.svg',
            country: x.country,
          });
        }
      });

    this.TimelineList = [
      ...reimburse,
      ...(newTimeline as Array<timelineInterface>),
    ];
    this.TimelineList = this.TimelineList?.filter(
      (x) => x.approval != 'NA'
    ).sort(
      (a, b) =>
        new Date(dmy_ymd(a.sortDate as string)).getTime() -
        new Date(dmy_ymd(b.sortDate as string)).getTime()
    );
    newTimeline
      ?.filter((x) => x.approval == 'NA')
      .forEach((x) => {
        this.TimelineList?.push(x);
      });
  }

  // set timeline list for selected country
  public setCountryData(): void {
    let newTimeline: timelineInterface[] = [];
    this.analogueRegulatoryData
      ?.filter(
        (x) => x.country.toLowerCase() === this.selectedOption.toLowerCase()
        //null check for usa regulatoryApprovalDate
        // &&
        // x.regulatoryApprovalDate != null
      )
      .sort(
        (a, b) =>
          new Date(dmy_ymd(a.initialLaunchDate)).getTime() -
          new Date(dmy_ymd(b.initialLaunchDate)).getTime()
      )
      .forEach((x) => {
        if (
          !newTimeline.some(
            (y) =>
              y.name?.toLowerCase().trim() === x.brandName.toLowerCase().trim()
          )
        ) {
          newTimeline.push({
            name: x.brandName,
            approvalName: x.regulatoryAgency,
            approval: ToReadableDate(x.initialLaunchDate),
            reimburse:
              x.regulatoryApprovalDate !== 'NA' &&
              x.regulatoryApprovalDate !== null
                ? ToReadableDate(x.regulatoryApprovalDate)
                : 'NA',
            color: this.colorSetService.setColor(x.brandName),
            img: '',
            country: x.country,
          });
        }
      });
    this.TimelineList = newTimeline;
  }

  // display data accourdingly as per the selected view mode which is analogue/country
  public changeView(): void {
    if (this.viewMode == 'Analogue') {
      this.timelineOptions = this.analogueOptions;
      this.selectedOption = this.timelineOptions[0];
      this.setAnalogueData();
    } else {
      this.timelineOptions = this.countryOptions;
      this.selectedOption = this.timelineOptions[0];
      this.setCountryData();
    }
    // Sort country array in ascending order
    this.timelineOptions.sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: 'accent' })
    );

    this.selectOption.emit(this.selectedOption);
  }

  // display data accourdingly as per the selected analogue/country
  public changeOption(option: string): void {
    this.selectedOption = option;
    this.selectOption.emit(this.selectedOption);
    this.TimelineList = [];
    if (this.viewMode == 'Analogue') {
      this.setAnalogueData();
    } else {
      this.setCountryData();
    }
  }
}
