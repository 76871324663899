import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { CurrencyConversionService } from '../../../../horizon-project/services/currency-conversion.service';
import { Subject, from, takeUntil } from 'rxjs';
import { HeaderService } from '../../../../shared/services/header.service';
import { ValueForecastInput } from '../../../../horizon-project/models/ValueForecastInput';
import { ActivatedRoute } from '@angular/router';
import { ForecastService } from '../../../forecast/services/forecast.service';
import { DynamicScoreInput } from '../../../../horizon-project/models/DynamicValueScore';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';

@Component({
  selector: 'he-summary-cot-comparison',
  templateUrl: './summary-cot-comparison.component.html',
  styleUrls: ['./summary-cot-comparison.component.scss'],
})
export class SummaryCotComparisonComponent
  implements OnInit, OnChanges
{
  @ViewChild('myTestDiv', { static: true }) divElementRef!: ElementRef;
  @Input() public alpha: string[] = [];
  @Input() public data: any[] = [];
  private colorList = [
    '#5470c6',
    '#91cc75',
    '#fac858',
    '#ee6666',
    '#73c0de',
    '#3ba272',
    '#fc8452',
  ];
  public selectedPriceType: string = 'Launch Price';
  selectedCurrency = 'USD';
  selectedCurrencySymbol = '';
  loading = true;
  priceTypes: any[] = [];
  public analogues: any[] = [
    {
      name: 'Evrysdi',
      color: '#e0bfd0',
    },
    {
      name: 'Kymriah',
      color: '#e7cfce',
    },
    {
      name: 'Onpattro',
      color: '#ece8bb',
    },
    {
      name: 'Luxturna',
      color: '#e4efc5',
    },
    {
      name: 'Namuscla',
      color: '#cfe7d0',
    },
  ];

  public scenarioList: any[] = [];
  public valueScoreUpdate: boolean = false;
  public vScore!: DynamicScoreInput;
  public scenarios: any[] = [];
  public defaultScenarios: any[] = [];
  public selectedCountry = '';
  public selectedScenario = '';

  public barData: any = {};
  public countriesInfoData: any[] = [];
  public projectId = '';

  option: EChartsOption = {};

  private unsubscriber$ = new Subject<void>();

  constructor(
    private activatedRouter: ActivatedRoute,
    private headerService: HeaderService,
    private forecastService: ForecastService,
    private currencyConversionService: CurrencyConversionService,
    private countryFlagService: CountryFlagService
  ) {}
  ngOnChanges(): void {
    this.setScenariBasedData(this.data);
  }

  ngOnInit(): void {
    this.selectedCurrency = this.currencyConversionService.GetDefaultCurrency();
    this.selectedCurrencySymbol =
      this.currencyConversionService.GetDefaultCurrencySymbol();
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        if (p.project) {
          this.priceTypes = this.headerService.priceTypes;
          this.priceTypes.filter(x=> x.value === 'netPrice')[0].visible = this.headerService.netPricePermission.isNetPriceAvailable
          this.priceTypes.filter(x=> x.value === 'netPrice')[0].disabled = !this.headerService.netPricePermission.isNetPriceVisible
          this.projectId = p.project;
          this.loading = true;
          this.valueScoreUpdate = false;

        }
      });
    this.currencyConversionService
      .GetSelectedCurrency()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((currency: string) => {
        if (currency.toLowerCase().trim() === 'local') {
          this.selectedCurrency =
            this.currencyConversionService.GetDefaultCurrency();
          this.selectedCurrencySymbol =
            this.currencyConversionService.GetDefaultCurrencySymbol();
        } else {
          this.selectedCurrency = currency;
          this.selectedCurrencySymbol =
            this.currencyConversionService.GetSelectedCurrencySymbol();
        }
        if (this.valueScoreUpdate) {
          this.updateScores(this.vScore);
        } else {
          this.scenarios = JSON.parse(JSON.stringify(this.defaultScenarios));
          this.setScenariBasedData(this.data);
        }

        // this.convertCurrency();
      });
    this.headerService.onCountryChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((country) => {
        this.loading = true;
        this.selectedCountry = country.replace('-', ' ');
        this.scenarios = JSON.parse(JSON.stringify(this.defaultScenarios));
        this.PrepareData();
      });
    this.headerService.onScenarioChange
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.loading = true;
        this.valueScoreUpdate = false;
        this.selectedScenario = data;
        this.scenarios = JSON.parse(JSON.stringify(this.defaultScenarios));
        this.PrepareData();
      });
    this.headerService.onValueScoreChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((vScore) => {
        // update asset value score of that country
        if (vScore) {
          this.valueScoreUpdate = true;
          this.vScore = vScore;
          this.updateScores(this.vScore);
        }
      });
  }
  ngAfterViewInit(): void {
    // this.divElementRef.nativeElement.style.visibility = "visible"
    // console.log(this.divElementRef.nativeElement.style.visibility)
  }

  public updateScores(vScore: any): void {
    const countryDataIndex = vScore.UpdatedCountryScores.findIndex(
      (f: any) => f.country === this.selectedCountry
    );
    if (countryDataIndex > -1) {
      const totalValueScore =
        vScore.UpdatedCountryScores[countryDataIndex].totalValueScore;
      const assetName = vScore.UpdatedCountryScores[countryDataIndex].assetName;
      this.data.forEach((element: any, index: number) => {
        if (this.selectedScenario == element.scenario) {
          const ScoreMatrixData = element.forecastSummary.scoreMatrix.filter(
            (f: any) => f.countryName === this.selectedCountry
          );
          if (ScoreMatrixData) {
            const UpdatedScoreMatrixData: any[] = JSON.parse(
              JSON.stringify(ScoreMatrixData)
            );
            UpdatedScoreMatrixData[0].assetData = [];
            UpdatedScoreMatrixData[0].assetData.push({
              Name: assetName,
              TotalValueScore: totalValueScore,
            });
            const newRequestData: ValueForecastInput = {
              ScoreMatrix: [...UpdatedScoreMatrixData],
              ProjectId: this.projectId,
            };
            this.getForecastedData(newRequestData, element.priceType);
          }
        }
      });
    }
  }

  private async getForecastedData(requestData: any, priceTypeName: string) {
    if (requestData != null && requestData.ScoreMatrix.length > 0) {
      if (requestData.ScoreMatrix[0].brandData.length > 0) {
        if (this.projectId) {
          requestData.ProjectId = this.projectId;

          await this.forecastService
            .getForecastData(requestData)
            .then((f) => {
              this.scenarios.forEach((scenario) => {
                if (scenario.scenarioName == this.selectedScenario) {
                  scenario.countries.forEach((country: any) => {
                    if (country.countryName === this.selectedCountry) {
                      country.prices.forEach((price: any) => {
                        if (price.priceTypeName == priceTypeName) {
                          price.price = Math.round(
                            f.scoreMatrix[0].assetData[0].predictedPrice
                          );
                          price.score =
                            f.scoreMatrix[0].assetData[0].totalValueScore;
                        }
                      });
                    }
                  });
                }
              });
            })
            .then((f) => {
              this.PrepareData();
            })
            .catch((err: any) => {
              console.log(err);
            });
        }
      }
    }
  }
  public setScenariBasedData(data: any): void {
    this.loading = true;
    this.scenarios = [];
    this.scenarioList = [];
    data.forEach((res: any) => {
      if (
        !this.scenarios.some(
          (scenario) => scenario.scenarioName === res.scenario
        )
      ) {
        this.scenarios.push({
          scenarioName: res.scenario,
          scenarioIndexName: '',
          countries: [],
          sequenceNo: res.scenarioSequence,
        });
      }
      this.scenarios.forEach((res1, index) => {
        res1.scenarioIndexName = ' Scenario ' + this.alpha[index] + '' + ' : ';
        if (res1.scenarioName === res.scenario) {
          if (res.forecastSummary) {
            res.forecastSummary.scoreMatrix.forEach((country: any) => {
              if (
                !res1.countries.some(
                  (country1: any) =>
                    country1.countryName === country.countryName
                )
              ) {
                res1.countries.push({
                  countryName: country.countryName,
                  info: country.infoIconData,
                  prices: [],
                });
              }
            });
            res1.countries.forEach((country: any) => {
              country.prices.push({
                priceType: res.priceType.replace(/([A-Z])/g, ' $1').trim(),
                priceTypeName: res.priceType,
                info:res.forecastSummary.scoreMatrix.filter((x:any)=>x.countryName === country.countryName)[0].infoIconData,
                price: this.setPrice(
                  res.forecastSummary.scoreMatrix,
                  country.countryName
                ),
                score: this.setScore(
                  res.forecastSummary.scoreMatrix,
                  country.countryName
                ),
              });
            });
          }
        }
      });
    });
    this.scenarios.sort((a, b) => a.sequenceNo - b.sequenceNo);
    this.defaultScenarios = JSON.parse(JSON.stringify(this.scenarios));
    this.PrepareData();
  }

  public setPrice(data: any[], country: string): number {
    let num: number = 0;
    data.forEach((countires) => {
      if (countires.countryName === country) {
        num = Math.round(countires.assetData[0].predictedPrice);
      }
    });
    return num;
  }
  public setScore(data: any[], country: string): number {
    let num: number = 0;
    data.forEach((countires) => {
      if (countires.countryName === country) {
        num = Math.round(countires.assetData[0].totalValueScore);
      }
    });
    return num;
  }

  public onPriceTypeChange(): void {
    this.loading = true;
    this.PrepareData();
    // this.divElementRef.nativeElement.style.visibility = "hidden"
  }
  public async PrepareData(): Promise<void> {
    this.option = {};
    let option: any = {};
    this.barData = {};
    this.setLegend();
    (option.tooltip = {
      trigger: 'axis',
      enterable: true,
      extraCssText: 'max-height:500px; overflow-y:auto;',
      axisPointer: {
        type: 'shadow',
      },
      position: function (point: Array<any>, params: any) {
        const datas = params || [0, 0];
        return [point[0], datas.length > 4 ? '1%' : point[1]];
      },
      formatter(params: any) {
        const datas = params || [0, 0];
        let tooltip: string = '';
        datas.forEach((data: any) => {
          if (data.value?.currencySymbol != undefined) {
            tooltip =
              tooltip +
              data.marker +
              data.seriesName +
              '<br/>' +
              'Total Value Score: ' +
              data.value.score +
              '<br/>' +
              'Forecast COT: ' +
              (data.value.cot <= 0 ? '' : data.value.currencySymbol) +
              (data.value.cot <= 0
                ? 'NA'
                : Math.round(parseFloat(data.value.cot))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) +
              '<br/>' +
              '<br/>';
          }
        });
        return tooltip;
      },
    }),
      (option.yAxis = {
        axisLabel: {
          color: '#92A1B9',
          fontSize: 13,
          formatter: `${this.selectedCurrencySymbol}  {value}`,
        },

        axisLine: { show: true, lineStyle: { color: '#92A1B9' } },
        axisTick: { show: false },
        id: 'price',
        min: 0,
        nameGap: 62,
        nameLocation: 'middle',
        nameTextStyle: { color: '#92A1B9', fontSize: 11 },
        type: 'value',
      }),
      (option.xAxis = {
        type: 'category',
        axisLabel: { color: '#92A1B9', fontSize: 13, interval: 0, rotate: 0 },
        axisLine: { lineStyle: { color: '#92A1B9' } },
        axisTick: { length: 0 },
        data: this.setXAxis(),
        id: 'country',
        min: 0,
        show: false,
      });
    (option.grid = {
      left: '3%',
      right: '4%',
      bottom: '0%',
      containLabel: true,
    }),
      (option.series = []);
    option.dataset = [];
    option.series = await this.setSeries();
    option.dataset = this.setSeriesData();
    setTimeout(() => {
      this.option = option;
      this.loading = false;
      if (this.countriesInfoData.length > 5){
        this.divElementRef.nativeElement.style.width = '1530px';
      }else{
        this.divElementRef.nativeElement.style.width = '';
      }
    }, 1000);
  }

  public setLegend(): void {
    this.scenarioList = [];
    this.scenarios.map((scenario: any, index: number) => {
      this.scenarioList.push({
        name: scenario.scenarioIndexName + scenario.scenarioName,
        color: this.colorList[index],
      });
    });
  }

  public setXAxis(): string[] {
    let xAxis: string[] = [];
    this.countriesInfoData = [];
    if (this.scenarios[0] != undefined) {
      xAxis = this.scenarios[0].countries.map((country: any) => {
        this.countriesInfoData.push({
          country: country.countryName,
          info: country.prices.filter((price:any) => price.priceTypeName === this.selectedPriceType)[0].info,
          flagId: this.countryFlagService.getFlagId(country.countryName),
        });
        return country.countryName;
      });
      xAxis.sort();
      this.countriesInfoData?.sort((a, b) =>
        a.country.localeCompare(b.country)
      );
    }
    return xAxis;
  }

  public async setSeries(): Promise<any> {
    let series: any[] = [];
    await Promise.all(
    this.scenarios.map(async (scenario: any, index: number) => {
      series.push({
        animation: false,
        datasetId: scenario.scenarioIndexName + scenario.scenarioName,
        encode: { x: 'country', y: 'cot' },
        name: scenario.scenarioIndexName + scenario.scenarioName,
        silent: true,
        type: 'custom',
        xAxisId: 'country',
        yAxisId: 'price',
        z: 1000,
        renderItem: this.renderCustomLine,
      });
      await Promise.all(
      scenario.countries.map(async (country: any) => {
        if (!this.barData[country.countryName]) {
          this.barData[country.countryName] = [];
        }
        await Promise.all(
        country.prices.map(async (price: any, index: number) => {
          if (price.priceTypeName === this.selectedPriceType) {
            let newPrice = await this.convertCurrency(price.price)
            this.barData[country.countryName].push(newPrice);
            this.barData[country.countryName].sort((a: any, b: any) => a - b);
          }
        })
        )
      })
      )
    })
    )
    for (let index = 0; index < 2; index++) {
      series.push({
        type: 'bar',
        stack: 'Total',
        itemStyle: {
          borderColor: index == 0 ? 'transparent' : 'black',
          color: index == 0 ? 'transparent' : 'black',
          opacity: 0.14,
        },
        data: this.setBarData(index),
        barWidth: 70,
      });
    }
    return series;
  }

  public setBarData(index: number): any[] {
    let data: any[] = [];
    if (this.scenarios[0] != undefined) {
      this.scenarios[0].countries
        .sort((a: any, b: any) => a.countryName.localeCompare(b.countryName))
        .forEach(async (country: any) => {
          if (index == 0) {
            data.push(
             this.barData[country.countryName][0]
            );
          } else {
            data.push(
              (
                this.barData[country.countryName][
                  this.barData[country.countryName].length - 1
                ]
              ) -
                
                  this.barData[country.countryName][0]
                
            );
          }
        });
    }
    return data;
  }

  public setSeriesData(): any[] {
    let data: any[] = [];
    this.scenarios.forEach((scenario: any) => {
      data.push({
        id: scenario.scenarioIndexName + scenario.scenarioName,
        source: this.setLines(scenario),
      });
    });
    return data;
  }

  public setLines(scenario: any): any[] {
    let data: any[] = [];
    scenario.countries.forEach((country: any) => {
      country.prices.forEach(async (price: any, index: number) => {
        if (price.priceTypeName === this.selectedPriceType) {
          let newprice = await this.convertCurrency(price.price);
          data.push({
            brandName: scenario.name,
            calcPer: 'month',
            cot: newprice <= 0 ? 0 : newprice,
            score: price.score,
            country: country.countryName,
            currencySymbol: this.selectedCurrencySymbol,
            realBrandName: scenario.name,
          });
        }
      });
    });
    return data;
  }

  public renderCustomLine(param: any, api: any): any {
    const point = api.coord([api.value('country'), api.value('cot')]);
    const maxBarWidth = Math.min(72, 72 * (12 / 4));
    const shape = {
      x1: point[0] - maxBarWidth / 2,
      y1: point[1],
      x2: point[0] + maxBarWidth / 2,
      y2: point[1],
    };
    return {
      type: 'line',
      transition: ['shape'],
      shape,
      style: {
        fill: null,
        stroke: api.visual('color'),
        lineWidth: 2,
      },
    };
  }

  public async convertCurrency(price: any) {
    const defaultCurrency = this.currencyConversionService.GetDefaultCurrency();
    let newPrice = Math.round(
      await this.currencyConversionService.convertCurrency(
        price,
        defaultCurrency,
        this.selectedCurrency
      )
    );
    return newPrice;
  }
}
