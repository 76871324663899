import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { GeneralInfoProvider, Message } from './general-info.provider';
import { FiltersValueService } from '../filters-value.service';
import { SetOfFiltersInterface } from '../../../core/datastore-common';
import { FiltersObject } from '../interfaces';

@Pipe({
  name: 'fetch'
})
export class FetchPipe implements PipeTransform {

  constructor(private generalInfo: GeneralInfoProvider, private filtersValueService: FiltersValueService) {
  }

  transform(event: FiltersObject | any): Observable<Message> {
    const payload: SetOfFiltersInterface = this.filtersValueService.getSetOfFilters(event);
    return this.hasRequiredValue(payload) ? this.generalInfo.run({ payload }) : of({ type: 'fail', payload: [] });
  }

  hasRequiredValue(payload: SetOfFiltersInterface): boolean {
    return !!(payload.brands.length);
  }
}
