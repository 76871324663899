import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'he-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {
  @Input() filterControl!: FormControl;
  @Input() descriptions: DatepickerFDescriptionInterface | undefined;
  @Input() minDate: (Date & string) | null | undefined;
  @Input() maxDate: (Date & string) | null | undefined;
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<Date | string>> = new EventEmitter();
  @Output() dateInput: EventEmitter<MatDatepickerInputEvent<Date | string>> = new EventEmitter();
}

export interface DatepickerFDescriptionInterface {
  placeholder: string;
  errorMessage: string;
}
