import { Pipe, PipeTransform } from '@angular/core';
import {
  ChartDataForCotCorridorInterface,
  CorridorChartDataset,
  CorridorChartItemInterface,
  LineChartInternalDataInterface,
  ParamsReimbursementInterface
} from '../interfaces';
import { EChartsOption, GridComponentOption, LegendComponentOption, SeriesOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { ReimbursementEnum } from '../enums';
import { AnalysisMeasuresFilterInterface } from '../../../app/project-details/analysis-measures/interfaces';
import { getCurrencyAndPeriod } from '../currency-period';
import { getFilterValues, getCalcPerValue } from '../../filter-yaxis-label/filter-yaxis-label';

@Pipe({
  name: 'corridorChartPipe'
})
export class CorridorChartPipe implements PipeTransform {
  palette: string[] = ['#00a777', '#e89806', '#e93c3c', '#92A1B9'];
  maxReimLimit = 4;
  measures: any;
  countryList: any;
  countryLabelLimit: number = 8;

  countryLabelLimitExceeded() {
    return this.countryList.length >= 8
  }

  priceLabelLimitExceeded(chartData: CorridorChartItemInterface[]) {
    let maxPrice = Math.max(...chartData.map((o: CorridorChartItemInterface) => o.price));
    return (maxPrice > 10000000);
  }

  static sameString(a: string, b: string): boolean {
    return a.toLowerCase() === b.toLowerCase();
  }

  transform(chartData: ChartDataForCotCorridorInterface[] | undefined,
    measures: Partial<AnalysisMeasuresFilterInterface> | any,
    countries?: any[] | null,
    selectedLegends?: { [key: string]: boolean },
    resData?: any,
    type?:string
    ): EChartsOption {
    this.countryList = countries?.filter((country: any) => {
      return resData.some((value: any) => country === value.country);
    });

    this.measures = measures;
    this.formatData(resData);

    const chartOption: EChartsOption = {
      tooltip: {},
      textStyle: this.setTextStyle(),
      grid: this.setGrid(type),
      xAxis: this.setXAxis(this.countryList || []),
      yAxis: this.setYAxis(measures),
      dataset: [],
      series: this.setSeries(),
      legend: this.setLegend(selectedLegends, chartData?.length),
      color: ['#4e4691', '#00bdae', '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074',
        '#546570', '#c4ccd3'],
    };

    let isPriceLabelLimitExceeded: boolean = false;

    if (chartData && chartData.length) {
      chartData.forEach((chartDataItem: ChartDataForCotCorridorInterface) => {
        // @ts-ignore
        chartOption.dataset.push(this.createDataSetItem(chartDataItem.drugName, chartDataItem.chartData));

        if (!isPriceLabelLimitExceeded && this.priceLabelLimitExceeded(chartDataItem.chartData)) {
          isPriceLabelLimitExceeded = true;
        }

        // @ts-ignore
        chartOption.legend.data?.push({ name: chartDataItem.drugName });

        chartOption.series = [
          // @ts-ignore
          ...chartOption.series,
          // bar
          this.createBarForItem(chartDataItem.drugName, chartDataItem.indication, measures),
        ];

        if (chartDataItem.lineChartData && chartData.length <= 2) {
          // line with arrows
          chartOption.series.push(this.createLineForItem(chartDataItem.drugName, chartDataItem.lineChartData));
        }
        if (!selectedLegends) {
          chartOption.series = [
            ...chartOption.series,
            ...this.createReimbursementBars(chartDataItem.drugName, chartData.length)
          ];
        }
      });

      /*
      if (isPriceLabelLimitExceeded && chartOption.grid) {
        // @ts-ignore
        for (let gridOption: GridComponentOption, rNo = 0; rNo < chartOption.grid.length; rNo++) {
          // @ts-ignore
          gridOption = chartOption.grid[rNo];
          gridOption.containLabel = true;
        }
        // @ts-ignore
        if (chartOption.xAxis && chartOption.xAxis.length >= 1) {
          // @ts-ignore
          chartOption.xAxis[0].axisLabel.rotate = 45;
          chartOption.xAxis[1].axisLabel.rotate = 45;
          // @ts-ignore
          chartOption.xAxis[2].axisLabel.rotate = 45;
        }
      }
      */
    }

    const selection = selectedLegends || {};

    Object.keys(selection)
      .filter((key) => selection[key] !== false)
      .filter((key) => !CorridorChartPipe.sameString(key, 'Not reimbursed'))
      .filter((key) => !CorridorChartPipe.sameString(key, 'Reimbursed'))
      .filter((key) => !CorridorChartPipe.sameString(key, 'Reimbursed with restrictions'))
      .forEach((key) => {
        chartOption.series = [
          // @ts-ignore
          ...chartOption.series,
          ...this.createReimbursementBars(key, chartData?.length)
        ];
      });

    return chartOption;
  }

  formatData(dataOutput?: any): void {
    if (dataOutput !== undefined) {
      const calVal = dataOutput[0]?.calcPer;
      if (calVal && calVal !== 'month') {
        this.measures.calcPer = calVal;
      }
    }
  }

  protected createLineForItem(drugName: string, lineChartData: LineChartInternalDataInterface): SeriesOption {
    if (drugName === 'corridor') {
      return {
        type: 'line',
        name: drugName,
        xAxisId: 'meta',
        yAxisId: 'priceClone',
        z: 100,
        color: '#4E4691',
        showAllSymbol: true,
        tooltip: {
          formatter: (params: any) => params.value[2] ? `${params.seriesName}: ${lineChartData.middleFormatter}` : '',
          backgroundColor: 'rgba(53, 64, 82, 0.8)',
          borderWidth: 0,
          textStyle: {
            color: '#fff',
            fontSize: 10,
            lineHeight: 16
          },
          padding: [2, 5]
        },
        showSymbol: true,
        data: [
          {
            // @ts-ignore
            value: lineChartData.firstValue,
            symbol: 'arrow',
            symbolSize: 6,
            symbolRotate: 180,
            itemStyle: {
              color: '#4E4691',
            }
          },
          {
            // @ts-ignore
            value: lineChartData.middleValue,
            label: {
              formatter: () => lineChartData.middleFormatter,
              show: true,
              position: 'inside',
              padding: 5,
              color: '#fff',
              backgroundColor: '#4E4691',
              borderRadius: 50,
            }
          },
          {
            // @ts-ignore
            value: lineChartData.lastValue,
            symbol: 'arrow',
            symbolSize: 6,
            itemStyle: {
              color: '#4E4691',
            }
          }
        ],
        animation: false,
        lineStyle: { width: 1, type: 'solid', opacity: 1, color: '#4E4691' },
        markLine: {
          data: [{ type: 'max' }, { type: 'min' }],
          lineStyle: { width: 1, type: 'dashed', opacity: 0.5, color: '#4E4691' },
          label: { show: false },
          symbol: 'none',
          silent: true,
          animation: false
        }
      };
    } else {
      return {
        type: 'line',
        name: drugName,
        xAxisId: 'meta',
        yAxisId: 'priceClone',
        z: 100,
        showAllSymbol: true,
        tooltip: {
          formatter: (params: any) => params.value[2] ? `${params.seriesName}: ${lineChartData.middleFormatter}` : '',
          backgroundColor: 'rgba(53, 64, 82, 0.8)',
          borderWidth: 0,
          textStyle: {
            color: '#fff',
            fontSize: 10,
            lineHeight: 16
          },
          padding: [2, 5]
        },
        showSymbol: true,
        data: [
          {
            // @ts-ignore
            value: lineChartData.firstValue,
            symbol: 'arrow',
            symbolSize: 6,
            symbolRotate: 180,
          },
          {
            // @ts-ignore
            value: lineChartData.middleValue,
            label: {
              formatter: () => lineChartData.middleFormatter,
              show: true,
              position: 'inside',
              padding: 5,
              color: '#fff',
              backgroundColor: 'inherit',
              borderRadius: 50,
            }
          },
          {
            // @ts-ignore
            value: lineChartData.lastValue,
            symbol: 'arrow',
            symbolSize: 6,
          }
        ],
        animation: false,
        lineStyle: { width: 1, type: 'solid', opacity: 1 },
        markLine: {
          data: [{ type: 'max' }, { type: 'min' }],
          lineStyle: { width: 1, type: 'dashed', opacity: 0.5 },
          label: { show: false },
          symbol: 'none',
          silent: true,
          animation: false
        }
      };
    }

  }

  private setXAxis(countries: string[]): XAXisComponentOption[] {
    countries.sort();
    return [
      {
        // @ts-ignore
        id: 'country', type: 'category', gridId: 'forPrice', borderColor: '#F2F3F6',
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: this.palette[3]
          }
        },
        axisLabel: {
          color: this.palette[3],
          fontSize: 13,
          interval: 0,
          width: 100,
          // @ts-ignore
          overflow: 'truncate',
          rotate: this.countryLabelLimitExceeded() ? 45 : 0
        },
        data: countries.length ? countries : []
      },
      // @ts-ignore
      { id: 'countryClone', type: 'category', gridId: 'forStatus', show: false, data: countries.length ? countries : [] },
      {
        id: 'meta',
        type: 'category',
        // @ts-ignore
        gridId: 'forMinMax',
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: {
          lineStyle: {
            color: this.palette[3]
          }
        },
      }
    ];
  }

  private setYAxis(measures: any): YAXisComponentOption[] {
    const currency = measures.currency;
    const filters = getFilterValues(measures);

    return [
      {
        // @ts-ignore
        id: 'price', type: 'value', gridId: 'forPrice',
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.palette[3]
          }
        },
        axisLabel: {
          color: this.palette[3],
          fontSize: 13
        },
        name: getCalcPerValue(filters, currency),
        nameLocation: 'middle',
        nameGap: 80,
        nameTextStyle: {
          color: this.palette[3],
          fontSize: 11
        }
      },
      // @ts-ignore
      { id: 'priceClone', type: 'value', gridId: 'forMinMax', axisLabel: { show: false }, axisTick: { show: false } },
      // @ts-ignore
      { id: 'status', type: 'value', gridId: 'forStatus', show: false }
    ];
  }

  private setLegend(selectedLegends?: { [key: string]: boolean }, chartDataLength?: number | undefined): LegendComponentOption {
    if (chartDataLength && chartDataLength < this.maxReimLimit) {
      return {

        show: true,
        type: 'plain',
        bottom: 0,
        selected: selectedLegends ? selectedLegends : {},
        data: [
          { name: this.getParamsReimbursement(ReimbursementEnum.reimbursed).name, icon: 'circle' },
          { name: this.getParamsReimbursement(ReimbursementEnum.reimbursedWithRestrictions).name, icon: 'circle' },
          { name: this.getParamsReimbursement(ReimbursementEnum.notReimbursed).name, icon: 'circle' },
        ],
        textStyle: {
          color: '#354052',
          fontSize: 11,
        }
      };
    } else {
      delete selectedLegends?.['Reimbursed'];
      delete selectedLegends?.['Reimbursed with restrictions'];
      delete selectedLegends?.['Not reimbursed'];

      return {
        show: true,
        type: 'plain',
        bottom: 0,
        selected: selectedLegends ? selectedLegends : {},
        data: [],
        textStyle: {
          color: '#354052',
          fontSize: 11,
        }
      };
    }
  }

  private setGrid(type?:string): GridComponentOption[] {

    const forPriceGrid: GridComponentOption = { id: 'forPrice', top: '15%',left:'10%', right: '5%',containLabel:true };
    const forMinMaxGrid: GridComponentOption = { id: 'forMinMax', top: '15%',right: '5%', left: '85%',containLabel:true  };

    if (this.countryLabelLimitExceeded()) {
      if(type === 'cotCorridor'){
    forPriceGrid.bottom = '10%'
      forMinMaxGrid.bottom = '29.5%'
      }else{

        forPriceGrid.bottom = '14%'
        forMinMaxGrid.bottom = '30%'
      }
  
    }

    return [
      forPriceGrid,
      { id: 'forStatus', top: 0, bottom: '85%', right: '5%',containLabel:true  },
      forMinMaxGrid
    ];
  }

  private setSeries(): any[] {
    return [
      {
        type: 'line',
        data: [],
        color: this.getParamsReimbursement(ReimbursementEnum.reimbursed).color,
        name: this.getParamsReimbursement(ReimbursementEnum.reimbursed).name
      },
      {
        type: 'line',
        data: [],
        color: this.getParamsReimbursement(ReimbursementEnum.reimbursedWithRestrictions).color,
        name: this.getParamsReimbursement(ReimbursementEnum.reimbursedWithRestrictions).name
      },
      {
        type: 'line',
        data: [],
        color: this.getParamsReimbursement(ReimbursementEnum.notReimbursed).color,
        name: this.getParamsReimbursement(ReimbursementEnum.notReimbursed).name
      },
    ];
  }

  private setTextStyle() {
    return {
      color: '#354052',
      fontSize: 13,
    };
  }

  private createDataSetItem(drugName: string, chartData: CorridorChartItemInterface[]): CorridorChartDataset {
    return {
      id: drugName,
      source: chartData.map((item) => {
        delete item.brandName;
        delete item.indication;

        return item;
      }),
    };
  }

  private createBarForItem(drugName: string,
    indication: string,
    measures: Partial<AnalysisMeasuresFilterInterface> | any): SeriesOption {
    return {
      type: 'bar',
      name: drugName,
      // @ts-ignore
      datasetId: drugName,
      encode: { x: 'country', y: 'cot' },
      z: 100,
      barMaxWidth: 100,
      label: {
        show: false, position: 'top',
        formatter: (params: any) => `${params.data.price.toFixed(2)}`,
      },
      tooltip: {
        formatter: (params: any) => {
          return `${params.marker} COT ${params.seriesName} (${params.name}, ${indication}): ${params.data.price.toFixed(2)}${getCurrencyAndPeriod(measures)}`;
        },
        backgroundColor: 'rgba(53, 64, 82, 0.8)',
        borderWidth: 0,
        textStyle: {
          color: '#fff',
          fontSize: 10,
          lineHeight: 16
        },
        padding: [2, 5]
      },
      // markLine: {
      //   data: [{type: 'max'}, {type: 'min'}],
      //   lineStyle: {width: 1, type: 'dashed', opacity: 0.5},
      //   label: {show: false},
      //   symbol: 'none',
      //   silent: true,
      //   animation: false
      // },
    };
  }

  private createReimbursementBars(drugName: string, chartDataLength: any): SeriesOption[] {
    return [
      this.createBarForReimbursement(drugName, ReimbursementEnum.reimbursed, chartDataLength),
      this.createBarForReimbursement(drugName, ReimbursementEnum.reimbursedWithRestrictions, chartDataLength),
      this.createBarForReimbursement(drugName, ReimbursementEnum.notReimbursed, chartDataLength)
    ];
  }

  private createBarForReimbursement(drugName: string, typeOfReimbursement: ReimbursementEnum, chartDataLength: any): SeriesOption {
    let paramsReimbursement: ParamsReimbursementInterface;

    if (chartDataLength < this.maxReimLimit) {
      paramsReimbursement = this.getParamsReimbursement(typeOfReimbursement);
    } else {
      paramsReimbursement = this.getParamsReimbursement(typeOfReimbursement);
      paramsReimbursement.color = 'transparent';
    }

    return {
      type: 'bar',
      name: paramsReimbursement.name,
      // @ts-ignore
      datasetId: drugName,
      encode: { x: 'country', y: paramsReimbursement.type },
      xAxisId: 'countryClone',
      yAxisId: 'status',
      z: 90,
      stack: drugName,
      animation: false,
      itemStyle: { color: 'transparent' },
      label: {
        show: true,
        position: 'inside',
        color: paramsReimbursement.color,
        backgroundColor: paramsReimbursement.color,
        height: 16,
        width: 16,
        borderRadius: 8
      },
      silent: true,
    };
  }

  private getParamsReimbursement(typeOfReimbursement: ReimbursementEnum): ParamsReimbursementInterface {
    switch (typeOfReimbursement) {
      case ReimbursementEnum.reimbursed:
        return { name: 'Reimbursed', color: this.palette[0], type: 'reimbursed' };
      case ReimbursementEnum.reimbursedWithRestrictions:
        return { name: 'Reimbursed with restrictions', color: this.palette[1], type: 'reimbursedWithRestrictions' };
      case ReimbursementEnum.notReimbursed:
        return { name: 'Not reimbursed', color: this.palette[2], type: 'notReimbursed' };
    }
  }
}
