import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { IFeature, IFeatureFlag } from './feature-flag-interfaces';
import { FeatureFlagService } from './feature-flag.service';
import { Subject } from 'rxjs';

@Directive({
  selector: '[hePulseFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit, OnDestroy {

  @Input('hePulseFeatureFlag')
  feature: IFeature | undefined;

  public featureFlagData: IFeatureFlag[] = [];

  private readonly destroying$: Subject<void> = new Subject<void>();

  constructor(
    private tpl: TemplateRef<any>,
    private vcf: ViewContainerRef,
    private featureFlagService: FeatureFlagService
  ) {

  }

  ngOnInit(): void {
    this.featureFlagService.getFlagValue(this.destroying$, this.feature).then((flagValue: boolean) => {
      if (flagValue) {
        this.vcf.createEmbeddedView(this.tpl);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}