import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FilterByMoaApi {
  constructor(private httpClient: HttpClient) {
  }

  transfer(req: any): Observable<unknown> {
    const params = {
      q: req.query
    };
    return this.httpClient.get<unknown>('/filter/moa', {params});
  }
}
