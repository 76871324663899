<div class="drugs" *ngIf="descriptions?.title==='Drugs/Brands'; else other_content">

  <abbr title="INNs and ATC codes can be used to add more brands">{{ descriptions?.title }}</abbr>

</div>
<ng-template #other_content>
  <div class="mb-1">
    {{ descriptions?.title }}
  </div>
</ng-template>
<mat-form-field appearance="outline" placeholder="" class="he-form-field"
  [class.mat-form-field-invalid]="isErrorIfEmptyVisible" [attr.disabled]="disabled"
  matTooltip="{{ disabled ? disabledTooltip : null }}" matTooltipClass="he-tooltip">
  <he-icon [id]="'search'"></he-icon>
  <label>
    <input type="text" placeholder="{{ descriptions?.placeholder }}" matInput #autoCompleteInput (blur)="onBlur()"
      [formControl]="filterControl" [matAutocomplete]="autoComplete" [attr.disabled]="disabled" (focus)="onFocus()"
      (input)="onInput()">
    <he-icon *ngIf="isClearButtonVisible" size="16" matSuffix class="clear-icon" (click)="onClear($event)"
      [id]="'suggested-symbol-x'"></he-icon>
  </label>
  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selected($event)" [class]="'he-autocomplete'"
    (closed)="setSelectedItemText();">
    <!-- Loading -->
    <mat-option disabled *ngIf="loading" class="is-loading">
      <mat-spinner diameter="20"></mat-spinner>
    </mat-option>
    <ng-container *ngIf="isGroupView; else simpleView">
      <ng-container *ngIf="filteredGroupItems$ | async as filteredGroupItems">
        <!-- Empty state -->
        <mat-option disabled *ngIf="filteredGroupItems.length === 0 && !loading">{{ descriptions?.emptyState }}
        </mat-option>

        <ng-container *ngFor="let filteredGroupItem of filteredGroupItems">
          <!-- Actual payload -->
          <mat-optgroup
            *ngIf="filteredGroupItem.items.length && !loading && ((!showListOnEmptyText && filterControl.value) || showListOnEmptyText)"
            [label]="filteredGroupItem.groupName">
            <mat-option *ngFor="let item of filteredGroupItem.items" [value]="item">
              <he-highlight-text [symbols]="filterControl.value" [text]="item.name"></he-highlight-text>
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #simpleView>
      <ng-container *ngIf="filteredItems$ | async as filteredItems">
        <!-- Empty state -->
        <mat-option disabled *ngIf="filteredItems.length === 0 && !loading">{{ descriptions?.emptyState }}</mat-option>
        <!-- Actual payload -->
        <ng-container
          *ngIf="filteredItems.length && !loading && ((!showListOnEmptyText && filterControl.value) || showListOnEmptyText)">
          <mat-option *ngFor="let item of filteredItems" [value]="item">
            <he-highlight-text *ngIf='item.abbr' [symbols]="filterControl.value" [text]="item.abbr"></he-highlight-text>
            <he-highlight-text *ngIf='item.name && item.abbreviation === null' [symbols]="filterControl.value"
              [text]="item.name"></he-highlight-text>
          </mat-option>
        </ng-container>
      </ng-container>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>
<mat-error class="he-error" *ngIf="isErrorIfEmptyVisible">
  <he-icon class="he-icon-medium" [id]="'suggested-triangle-exclamation-mark'"></he-icon>
  <span>{{ descriptions?.errorMessage }}
    <a *ngIf="isLinkAvailable" class="error-link" href="javascript:void(0)" (click)="gridView()">here</a>
  </span>
</mat-error>