import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CssUtilitiesComponent } from './css-utilities.component';

@NgModule({
  declarations: [CssUtilitiesComponent],
  exports: [CssUtilitiesComponent],
  imports: [
    CommonModule
  ]
})
export class CssUtilitiesModule { }
