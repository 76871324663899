import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterProductParamsDiscontinuedComponent } from './filter-product-params-discontinued.component';
import { SelectModule } from '../../../../uikit/select';

@NgModule({
  declarations: [FilterProductParamsDiscontinuedComponent],
  exports: [FilterProductParamsDiscontinuedComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
})
export class FilterProductParamsDiscontinuedModule {
}
