import { BrandsAvailabilityService } from './brands-availability-service/brands-availability.service';
import { LoadingOverlayModule } from './../../uikit/loading-overlay/loading-overlay.module';
import { CotCorridorCacheService, CotHistoryCacheService } from './cot-cache/cot-cache.service';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ShellModule } from '../shell';
import { LayoutModule } from '../layout';
import { AccessBarrierModule } from '../access-barrier';
import { AccessPermissions } from '../../core/access-permissions';
import { AccessPermissionsApi } from '../../helios-api/access-permissions';
import { DatastoreComponent } from './datastore.component';
import { DatastoreRoutingModule } from './routing.module';
import { CoTComparison } from '../../core/cot-comparison';
import { CoTComparisonApi } from '../../helios-api/cot-comparison/cot-comparison.api';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { CoTCorridor } from '../../core/cot-corridor';
import { CoTCorridorApi } from '../../helios-api/cot-corridor/cot-corridor.api';
import { CoTHistory } from '../../core/cot-history';
import { CoTHistoryApi } from '../../helios-api/cot-history/cot-history.api';
import { FormulationAndPackDetails } from '../../core/formulation-and-pack-details';
import { FormulationAndPackDetailsApi } from '../../helios-api/formulation-and-pack-details';
import { FormulationAndPackDetailsProvider } from './formulation-and-pack-details/formulation-and-pack-details.provider';
import { GeneralInfo } from '../../core/general-info';
import { GeneralInfoApi } from '../../helios-api/general-info/general-info.api';
import { GeneralInfoProvider } from './general-info/general-info.provider';
import { IndicationMatrix } from '../../core/indication-matrix';
import { IndicationMatrixApi } from '../../helios-api/indication-matrix/indication-matrix.api';
import { IndicationMatrixProvider } from './indication-matrix/indication-matrix.provider';
import { DatastoreFiltersModule } from './filters';
import { BrandsAvailiability } from '../../core/brands-availiability';
import { BrandsAvailiabilityApi } from '../../helios-api/brands-availiability/brands-availiability.api';
import { BrandsAvailiabilityProvider } from './brands-availiability/brands-availiability.provider';
import { PackPriceProvider } from './pack-price/pack-price.provider';
import { PackPrice } from '../../core/pack-price';
import { PackPriceApi } from '../../helios-api/pack-price/pack-price.api';
import { IconModule } from '../../uikit/helios-icons';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReimbursementMapProvider } from './reimbursement-map/reimbursement-map.provider';
import { ReimbursementStatus } from '../../core/reimbursement-status';
import { ReimbursementTimeApi } from '../../helios-api/reimbursement-time';
import { ReimbursementTimeProvider } from './reimbursement-time/reimbursement-time.provider';
import { ReimbursementStatusApi } from '../../helios-api/reimbursement-status/reimbursement-status.api';
import { ReimbursementTime } from '../../core/reimbursement-time';
import { ReimbursementHta } from '../../core/reimbursement-hta';
import { ReimbursementHtaApi } from '../../helios-api/reimbursement-hta/reimbursement-hta.api';
import { ReimbursementHtaProvider } from './reimbursement-hta/reimbursement-hta.provider';
import { IrpMap } from '../../core/irp-map';
import { IrpMapApi } from '../../helios-api/irp-map/irp-map.api';
import { IrpPriceMapProvider } from './irp-price/irp-price-map.provider';
import { IrpDetails } from '../../core/irp-details';
import { IrpDetailsApi } from '../../helios-api/irp-details/irp-details.api';
import { PackDetails } from '../../core/pack-details';
import { PackDetailsProvider, GridViewCustomDetailsProvider } from './pack-details/pack-details.provider';
import { PackDetailsApi } from '../../helios-api/pack-details/pack-details.api';
import { CorridorChartModule } from '../../uikit/corridor-widget';
import { JourneyDetailsProvider } from './journey-details/journey-details.provider';
import { JourneyDetails } from '../../core/journey-details';
import { JourneyDetailsApi } from '../../helios-api/journey-details/journey-details.api';
import { JourneyHistoryProvider } from './journey-history/journey-history.provider';
import { ExportImage } from './export-image.service';
import { FooterModule } from '../footer/footer.module';
import { CountryFlagModule } from '../../uikit/country-flag/country-flag.module';
import { ScopesDetailsApi } from '../../helios-api/scopes-details/scopes-details.api';
import { ScopesDetails } from '../../core/scopes-details';
import { ScopesDetailsService } from './scopes-details.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfoButtonModule } from '../../uikit/info-widget/info-button';
import { CommandModule } from '../../uikit/command';
import { GridViewCustomizationApi } from '../../helios-api/gridview-customization/gridview-customization.api';
import { GirdViewCustomizationDetails } from '../../core/gridview-customization';
import { GridViewCustomAPIService } from './gridviewcustom-api.service';
import { FetchGridViewCustomPipe } from './gridviewcustom-fetch.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PackDetailsPipe } from './pack-details/pack-details.pipe';
import { PackDetailsGridModule } from './pack-details/grid/pack-details-grid.module';
import { FiltersValueService } from './filters-value.service';
import { SharedService } from '../../uikit/service/shared.service';
import { PackDetailsFormattingPipe } from './pack-details/pack-details-formatting.pipe';
import { FetchOnlyavailableBrandsPipe } from './brands-availability-service';
import { FilterByBrandFetchPipe } from './brands-availability-service';
import { CacheExportService } from './cache-export-service.service';
import { GridViewCustomColumnAPIService } from './gridviewcustomcolumn.service';
import { AddUserPreference } from '../../core/add-user-preference';
import { UserPreferences } from '../../core/user-preferences';
import { AddUserPreferenceApi } from '../../helios-api/filters';
import { UserPreferencesApi } from '../../helios-api/user-preferences/user-preferences.api';
import { FeatureFlagModule } from '../feature-flag/feature-flag.module';
import { LoadingSpinnerModule } from '../../uikit/loading-spinner';
import { ReimbursementComparator } from '../../core/reimbursement-comparator';
import { ReimbursementComparatorProvider } from './reimbursement-comparator/reimbursement-comparator.provider';
import { DialogModule } from '../../uikit/dialog';
import { CotCalculatorModule } from './cot-calculator/cot-calculator.module';
import { CotCalculatorService } from './cot-calculator/services/cot-calculator.service';
import { NewProjectModule } from '../project-catalog/new-project';
import { CreateProject, UpdateProject } from '../../core/projects';
import { CreateProjectApi, UpdateProjectApi } from '../../helios-api/projects';
import { CreateProjectService, UpdateProjectService } from '../project-catalog/new-project/create.service';


@NgModule({
  declarations: [DatastoreComponent, FetchGridViewCustomPipe, FilterByBrandFetchPipe, FetchOnlyavailableBrandsPipe],
  exports: [DatastoreComponent],
  imports: [
    CorridorChartModule,
    CommonModule,
    ShellModule,
    LayoutModule,
    AccessBarrierModule,
    DatastoreRoutingModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    DatastoreFiltersModule,
    IconModule,
    MatButtonToggleModule,
    FooterModule,
    CountryFlagModule,
    MatTooltipModule,
    InfoButtonModule,
    CommandModule,
    LoadingOverlayModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    PackDetailsGridModule,
    FeatureFlagModule,
    LoadingSpinnerModule,
    DialogModule,
    CotCalculatorModule,
    NewProjectModule,
  ],
  providers: [
    DatePipe,
    PackDetailsPipe,
    CoTComparisonApi,
    CoTCorridorApi,
    CoTHistoryApi,
    GeneralInfoApi,
    IndicationMatrixApi,
    BrandsAvailiabilityApi,
    PackPriceApi,
    ReimbursementStatusApi,
    ReimbursementTimeApi,
    ReimbursementHtaApi,
    IrpMapApi,
    IrpDetailsApi,
    FormulationAndPackDetailsApi,
    PackDetailsApi,
    GridViewCustomizationApi,
    JourneyDetailsApi,
    ExportImage,
    AccessPermissionsApi,
    ScopesDetailsApi,
    GridViewCustomAPIService,
    FiltersValueService,
    SharedService,
    PackDetailsFormattingPipe,
    CacheExportService,
    FilterByBrandFetchPipe,
    FetchOnlyavailableBrandsPipe,
    BrandsAvailabilityService,
    CotCorridorCacheService,
    CotHistoryCacheService,
    GridViewCustomColumnAPIService,
    AddUserPreferenceApi,
    CotCalculatorService,
    {
      provide: AddUserPreference,
      useFactory: AddUserPreference.create,
      deps: [AddUserPreferenceApi]
    },
    UserPreferencesApi,
    {
      provide: UserPreferences,
      useFactory: UserPreferences.create,
      deps: [UserPreferencesApi]
    },
    {
      provide: AccessPermissions,
      useFactory: AccessPermissions.create,
      deps: [AccessPermissionsApi]
    },
    {
      provide: ScopesDetails,
      useFactory: ScopesDetails.create,
      deps: [ScopesDetailsApi]
    },
    {
      provide: ScopesDetailsService,
      useFactory: (gateway: ScopesDetails) => {
        return new ScopesDetailsService(gateway);
      },
      deps: [ScopesDetails]
    },
    {
      provide: CoTComparison,
      useFactory: (gateway: CoTComparisonApi) => {
        return new CoTComparison(gateway);
      },
      deps: [CoTComparisonApi]
    },
    {
      provide: CoTCorridor,
      useFactory: (gateway: CoTCorridorApi) => {
        return new CoTCorridor(gateway);
      },
      deps: [CoTCorridorApi]
    },
    {
      provide: CoTHistory,
      useFactory: (gateway: CoTHistoryApi) => {
        return new CoTHistory(gateway);
      },
      deps: [CoTHistoryApi]
    },
    {
      provide: GeneralInfo,
      useFactory: GeneralInfo.create,
      deps: [GeneralInfoApi]
    },
    {
      provide: GeneralInfoProvider,
      useExisting: GeneralInfo
    },
    {
      provide: IndicationMatrix,
      useFactory: IndicationMatrix.create,
      deps: [IndicationMatrixApi]
    },
    {
      provide: IndicationMatrixProvider,
      useExisting: IndicationMatrix
    },
    {
      provide: BrandsAvailiability,
      useFactory: BrandsAvailiability.create,
      deps: [BrandsAvailiabilityApi]
    },
    {
      provide: BrandsAvailiabilityProvider,
      useExisting: BrandsAvailiability
    },
    {
      provide: PackPrice,
      useFactory: PackPrice.create,
      deps: [PackPriceApi]
    },
    {
      provide: PackPriceProvider,
      useExisting: PackPrice
    },
    {
      provide: ReimbursementStatus,
      useFactory: ReimbursementStatus.create,
      deps: [ReimbursementStatusApi]
    },
    {
      provide: ReimbursementMapProvider,
      useExisting: ReimbursementStatus
    },
    {
      provide: ReimbursementTime,
      useFactory: ReimbursementTime.create,
      deps: [ReimbursementTimeApi]
    },
    {
      provide: ReimbursementTimeProvider,
      useExisting: ReimbursementTime
    },
    {
      provide: ReimbursementHta,
      useFactory: ReimbursementHta.create,
      deps: [ReimbursementHtaApi]
    },
    {
      provide: ReimbursementHtaProvider,
      useExisting: ReimbursementHta
    },
    {
      provide: ReimbursementComparator,
      useFactory: ReimbursementComparator.create,
      deps: [ReimbursementHtaApi]
    },
    {
      provide: ReimbursementComparatorProvider,
      useExisting: ReimbursementHta
    },
    {
      provide: IrpMap,
      useFactory: IrpMap.create,
      deps: [IrpMapApi]
    },
    {
      provide: IrpPriceMapProvider,
      useExisting: IrpMap
    },
    {
      provide: IrpDetails,
      useFactory: IrpDetails.create,
      deps: [IrpDetailsApi]
    },
    {
      provide: FormulationAndPackDetails,
      useFactory: FormulationAndPackDetails.create,
      deps: [FormulationAndPackDetailsApi]
    },
    {
      provide: FormulationAndPackDetailsProvider,
      useExisting: FormulationAndPackDetails
    },
    {
      provide: PackDetails,
      useFactory: PackDetails.create,
      deps: [PackDetailsApi]
    },
    {
      provide: PackDetailsProvider,
      useExisting: PackDetails
    },

    {
      provide: GirdViewCustomizationDetails,
      useFactory: GirdViewCustomizationDetails.create,
      deps: [GridViewCustomizationApi]
    },
    {
      provide: GridViewCustomDetailsProvider,
      useExisting: GirdViewCustomizationDetails
    },

    {
      provide: JourneyDetails,
      useFactory: JourneyDetails.create,
      deps: [JourneyDetailsApi]
    }, 
    {
      provide: JourneyDetailsProvider,
      useExisting: JourneyDetails
    },
    {
      provide: JourneyDetails,
      useFactory: JourneyDetails.create,
      deps: [JourneyDetailsApi]
    },
    {
      provide: JourneyHistoryProvider,
      useExisting: JourneyDetails
    },
    CreateProjectApi,
    {provide: CreateProject, useFactory: CreateProject.create, deps: [CreateProjectApi]},
    {provide: CreateProjectService, useExisting: CreateProject},
    UpdateProjectApi,
    {provide: UpdateProject,useFactory: UpdateProject.create, deps: [UpdateProjectApi]},
    {provide: UpdateProjectService, useExisting: UpdateProject},
  ]
})
export class DatastoreModule {
}
