import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Params, Router } from '@angular/router';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { HorizonProjectService } from '../../../horizon-project/horizon-project.service';
import { UserProjectPermission } from '../../../horizon-project/models/UserProjectPermission';
import { MasterService } from '../../../horizon-project/services/master.service';
import { HeaderService } from '../../../shared/services/header.service';
import { AssetTppService } from '../../asset-tpp/services/project-asset-tpp.service';
import { CriteriaSelectionService } from './criteria-selection.service';
import { ToastNotificationService } from '../../../shared/services/toast-notification.service';
import { GlobalSpinnerService } from 'projects/helios-gui/src/uikit/loading-spinner/global-spinner.service';
import { FeatureFlagService } from '../../../horizon-project/services/feature-flag.service';
import { NetPriceService } from './net-price.service';
import { Store } from '@ngrx/store';
import { HorizonState } from '../../../shared/store/models/state.model';
import { ResetProjects } from '../../../shared/store/actions/horizon.action';
import { encode } from 'querystring';
import { UpdateFrameworkService } from '../../../shared/services/update-framework.service';
import { frameworkEnums } from 'projects/helios-gui/src/app/common';

export interface ValueFrameworks {
  valueFrameworkId: string;
  valueFrameworkName: string;
}

export interface Countries {
  id: string;
  name: string;
}

export interface TopLevelIndication {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public scenarios: any[] = [];
  public alpha = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  public Countries = [];
  public selectedValueFramework: any = {};
  public selectedCountry: any = [];
  public minMarket: number = 2;
  public createProjectClicked: boolean = false;
  public isDropDown: boolean = false;
  public comparatorAvailable: boolean = false;
  public IsOncologyFrameworkSelected: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private assetTppService: AssetTppService,
    private masterService: MasterService,
    private horizonProjectService: HorizonProjectService,
    private headerService: HeaderService,
    private criteriaSelectionService: CriteriaSelectionService,
    private toastNotificationService: ToastNotificationService,
    private globalSpinnerService: GlobalSpinnerService,
    private featureFlagService: FeatureFlagService,
    private netPriceService: NetPriceService,
    private store: Store<HorizonState>,
    private updateFrameworkService: UpdateFrameworkService
  ) {}
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  readonly projectAPIUriPulse: string = environment.gatewayApiEndpoint;
  public selectedAnalogues: any[] = [];
  public selectedComparators: any[] = [];
  public valueChangeStatus = new BehaviorSubject<boolean>(false);
  public castValueChangeStatus = this.valueChangeStatus.asObservable();

  public ScenarioName = new BehaviorSubject<string>('');
  public ScenarioId = new BehaviorSubject<any>(0);
  public castScenarioName = this.ScenarioName.asObservable();
  public castScenarioId = this.ScenarioId.asObservable();

  public createProjectFormData = new BehaviorSubject<any>({});
  public castCreateProjectFormData = this.createProjectFormData.asObservable();

  public reloadScenario = new BehaviorSubject<boolean>(false);
  public castReloadScenario = this.reloadScenario.asObservable();

  public setScrollId = new BehaviorSubject<number>(0);
  public castsetScrollId = this.setScrollId.asObservable();

  public selectedScenarioIndex = new BehaviorSubject<any>(-1);
  public castselectedScenarioIndex = this.selectedScenarioIndex.asObservable();
  public SelectedScenarioData: any[] = [];
  public ProjectCollaborators: any[] = [];
  public projectCollaboratorsDraftDataAvailable = new BehaviorSubject<boolean>(
    false
  );

  public scenarioStatus = new BehaviorSubject<any>(true);
  public castScenarioStatus = this.scenarioStatus.asObservable();

  public editProjectId = new BehaviorSubject<any>('');
  public castEditProjectId = this.editProjectId.asObservable();

  public innName = new BehaviorSubject<any>('');
  public castInnName = this.innName.asObservable();

  public scenarioList = new BehaviorSubject<any[]>([]);
  public castscenarioList = this.scenarioList.asObservable();

  public isDraftProjectFetched = new BehaviorSubject<any>(false);
  public castisDraftProjectFetched = this.isDraftProjectFetched.asObservable();

  public isDraftAnalogueFetched = new BehaviorSubject<any>(false);
  public castIsDraftAnalogueFetched =
    this.isDraftAnalogueFetched.asObservable();

  public fileUploadStatus = new BehaviorSubject<any>({
    uploading: false,
    uploadStatus: false,
    projectId: '',
  });
  public castfileUploadStatus = this.fileUploadStatus.asObservable();

  public selectedEditScenarioName = new BehaviorSubject<any>('');
  public castSelectedEditScenarioName =
    this.selectedEditScenarioName.asObservable();

  public duplicateScenarioName = new BehaviorSubject<any>(false);
  public castDuplicateScenarioName = this.duplicateScenarioName.asObservable();

  public newValuechanged = new BehaviorSubject<any>(false);
  public castnewValuechanged = this.newValuechanged.asObservable();

  public uploadFileList = new BehaviorSubject<any>([]);
  public castUploadFileList = this.uploadFileList.asObservable();

  public validateProjectForm = new BehaviorSubject<boolean>(false);
  public castValidateProjectForm = this.validateProjectForm.asObservable();

  public projectFormStatus = new BehaviorSubject<boolean>(false);
  public castProjectFormStatus = this.projectFormStatus.asObservable();

  public isScenario: boolean = false;
  public isProjectLoaded: boolean = false;

  isdetailsValid: boolean = false;
  isScenariosValid: boolean = false;
  isScenariosNamesValid: boolean = false;
  netPriceChanged: boolean = false;

  getValueFrameworks(): Promise<ValueFrameworks[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ValueFrameworks[]>(
          this.projectAPIUri + '/ValueFramework/ValueFrameworks'
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  getValueFrameworksPermission(): Observable<any[]> {
    return this.http.get<any>(
      `${this.projectAPIUri}/UserConfig/GetUserPermissions`
    );
  }
  getFeatureFlags(): Promise<any> {
    const USAFramework = 'united states';
    return new Promise((resolve, reject) => {
      this.featureFlagService
        .getFeatureFlagData()
        .then((data) => {
          resolve(
            data.filter((f: any) => f.name.toLowerCase() === USAFramework)[0]
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCountriesData() {
    return this.http
      .get<any>(this.projectAPIUri + '/Master/GetCountries')
      .pipe(tap((data) => (this.Countries = data)));
  }

  getCountryByFrameworkData(frameworkName: string): Observable<any> {
    return this.http.get<any>(
      `${this.projectAPIUri}/Projects/GetCountryByFrameworkName?frameworkName=${frameworkName}`
    );
  }

  // Scenario-Screen
  copyScenario(): void {
    this.scenarios.push({
      id: this.scenarios.length + 1,
      newScenario: true,
      copiedId: this.selectedScenarioIndex.value,
      scenarioName: 'Scenario ' + this.alpha[this.scenarios.length] + '' + ':',
      scenarioDetails: null,
      ValueAttributeScores: null,
      scenarioNametouched: false,
      loaded: true,
    });
    this.autoAssignScenarioAlphaSequence();
  }

  addScenario(): void {
    this.scenarios.push({
      id: this.scenarios.length + 1,
      newScenario: true,
      copiedId: -1,
      scenarioName: 'Scenario ' + this.alpha[this.scenarios.length] + '' + ':',
      scenarioDetails: null,
      ValueAttributeScores: null,
      scenarioNametouched: false,
    });
    this.autoAssignScenarioAlphaSequence();
  }

  addEditScenario(scenarioData?: any) {
    scenarioData.sort((a: any, b: any) => a.sequence - b.sequence);
    if (this.scenarios.length === 0) {
      scenarioData.forEach((element: any) => {
        this.scenarios.push({
          id: this.scenarios.length + 1,
          newScenario: false,
          copiedId: -1,
          scenarioName:
            'Scenario ' + this.alpha[this.scenarios.length] + '' + ':',
          scenarioId: element.scenarioId,
          scenarioDetails: {
            scenarioName: element.scenarioName,
            scenarioDetails: element.scenarioDetail,
          },
          ValueAttributeScores: null,
          scenarioNametouched: true,
        });
      });
    }
    this.autoAssignScenarioAlphaSequence();
  }

  autoAssignScenarioAlphaSequence() {
    this.scenarios.forEach((element, index) => {
      element.scenarioName = 'Scenario ' + this.alpha[index] + '' + ':';
      element.id = index + 1;
    });
    this.scenarioList.next(this.scenarios);
  }

  getScenario() {
    return this.scenarios;
  }

  clearScenario() {
    this.scenarios = [];
    this.uploadFileList.next([]);
    this.scenarioList.next(this.scenarios);
    this.scenarioStatus.next(true);
    this.valueChangeStatus.next(false);
    this.selectedScenarioIndex.next(-1);
    this.createProjectFormData.next({});
    this.selectedAnalogues = [];
    this.selectedComparators = [];
    this.ProjectCollaborators = [];
    this.editProjectId.next('');
    this.netPriceService.ProjectNetPrice.IsNetPriceIncluded = false;
    this.netPriceService.ProjectNetPrice.isAnnualCOTInlcuded = false;
    this.netPriceService.ProjectNetPrice.OverallNetPrice = [];
    this.netPriceService.selectedAnalogueNetprice = [];
    this.netPriceService.ProjectNetPrice.AnalogueNetPrice = [];
    this.criteriaSelectionService.cleaSelectionCriteria();
    this.isProjectLoaded = false;
    this.isdetailsValid = false;
    this.criteriaSelectionService.isAnaloguesValid = false;
    this.isScenariosValid = false;
    this.isScenariosNamesValid = false;
    this.netPriceChanged = false;
    this.comparatorAvailable = false;
    this.createProjectFormData.next({});
    this.selectedValueFramework = {};
    this.updateFrameworkService.isUpdateFrameWork.next(false);
    this.updateFrameworkService.isUpdateFrameWorkComplete.next(true);
    this.updateFrameworkService.frameWorkErrorActive = false;
    this.setScrollId.next(0);
    this.toastNotificationService.dissmiss();
  }

  updateScenarioDetails(details: any, scenarioIndex: any) {
    if (scenarioIndex > 0) {
      // if (this.scenarios.length === scenarioIndex) {
      this.scenarios[scenarioIndex - 1].scenarioDetails = details;
      // }
    }
  }

  getScenarioDetails(scenarioId: any) {
    if (scenarioId > 0) {
      return this.scenarios[scenarioId - 1]?.scenarioDetails;
    }
  }

  getScenarioNameTouched(scenarioId: any) {
    if (scenarioId > 0) {
      return this.scenarios[scenarioId - 1].scenarioNametouched;
    }
  }

  setScenarioNameTouched(scenarioId: any) {
    if (scenarioId > 0) {
      this.scenarios[scenarioId - 1].scenarioNametouched = true;
    }
  }

  getScenarioAttributes(scenarioId: any) {
    if (scenarioId > 0) {
      return this.scenarios[scenarioId - 1].ValueAttributeScores;
    }
  }

  setScenarioAtributes(scenarioId: any, scenarioAttributes: any): void {
    if (scenarioId > 0) {
      this.scenarios[scenarioId - 1].ValueAttributeScores = JSON.parse(
        JSON.stringify(scenarioAttributes)
      );
    }
  }

  loadedScenario(scenarioId: any): void {
    this.scenarios[scenarioId - 1].loaded = true;
  }

  prepareValueAttributeScore(valueAttributeScores: any): any {
    const valueAttributeScore: any = [];
    valueAttributeScores.forEach((valueAttributesScoresData: any) => {
      valueAttributesScoresData.countries.forEach((country: any) => {
        country.valueAttributes.forEach(
          (valueAttributeCountryScoreData: any) => {
            if (
              valueAttributeCountryScoreData.name.includes('Patient number') &&
              !this.isDropDown
            ) {
              if (valueAttributesScoresData.inputType === 'value') {
                valueAttributeScore.push({
                  ValueAttributeAssetMapperId:
                    valueAttributeCountryScoreData.mapperId,
                  ValueAttributeScoreId: null,
                  Score: valueAttributeCountryScoreData.value,
                  Value: 'NA',
                  Country: country.name,
                });
              } else {
                valueAttributeScore.push({
                  ValueAttributeAssetMapperId:
                    valueAttributeCountryScoreData.mapperId,
                  ValueAttributeScoreId: null,
                  Score:
                    valueAttributeCountryScoreData.rangeStart +
                    '-' +
                    valueAttributeCountryScoreData.rangeEnd,
                  Value: 'NA',
                  Country: country.name,
                });
              }
            } else {
              if (valueAttributeCountryScoreData?.selectedScore !== '') {
                valueAttributeScore.push({
                  ValueAttributeAssetMapperId: this.updateFrameworkService
                    .isUpdateFrameWork.value
                    ? null
                    : valueAttributeCountryScoreData.mapperId,
                  ValueAttributeScoreId:
                    valueAttributeCountryScoreData.selectedScore.scoreId,
                  Score: valueAttributeCountryScoreData.selectedScore.score,
                  Value: 'NA',
                  Country: valueAttributeCountryScoreData.selectedScore.country,
                });
              }
            }
          }
        );
      });
    });
    return valueAttributeScore;
  }

  preparedScenarioData(editProjectStatus: boolean): any[] {
    const scenarioData: any = [];

    this.scenarios.forEach((scenario: any, index) => {
      if (scenario.scenarioDetails.scenarioName) {
        if (scenario.ValueAttributeScores != null) {
          scenarioData.push({
            Name: scenario.scenarioDetails.scenarioName,
            Sequence: scenario.id,
            Details: scenario.scenarioDetails.scenarioDetails,
            ScenarioId: scenario.scenarioId,
            ValueAttributeScores: this.prepareValueAttributeScore(
              scenario.ValueAttributeScores
            ),
          });
        }
        if (scenario.scenarioDetails.scenarioName && editProjectStatus) {
          scenario.newScenario = false;
          scenario.copiedId = -1;
        }
      }
    });
    return scenarioData;
  }

  createProject(IsUpgradeVersion: boolean, projectId?: string): void {
    // needed for new analogue selection
    // let analogue =this.getAnalogues();
    // if (this.selectedAnalogues.length >= 5) {
    this.globalSpinnerService.loadingSpinner(true);
    if (!this.isScenariosValid) {
      this.globalSpinnerService.loadingSpinner(false);
      this.toastNotificationService.errorMessage(
        `Project creation was unsuccessful - Please score all attributes to create a project`,
        'info',
        10000
      );
      this.navigateError(true);
    } else {
      const projectData = {
        ProjectName: this.createProjectFormData.value.projectName,
        ProjectDetails: this.createProjectFormData.value.projectDetails,
        AssetName: this.createProjectFormData.value.assetName,
        TopLevelIndication: this.createProjectFormData.value.topLevelIndication,
        MechanismOfAction: this.createProjectFormData.value.moa,
        Framework:
          this.createProjectFormData.value.framework.valueFrameworkName,
        Markets: this.createProjectFormData.value.selectedMarkets,
        ProjectCollaborators: this.ProjectCollaborators,
        ProjectAnalogues:
          this.createProjectFormData.value.comparator === 'true'
            ? this.selectedComparators
            : this.setAnaloguePayload(),
        ProjectNetPrice: {
          IsNetPriceIncluded:
            this.netPriceService.ProjectNetPrice.IsNetPriceIncluded,
          isAnnualCOTInlcuded:
            this.netPriceService.ProjectNetPrice.isAnnualCOTInlcuded,
          OverallNetPrice: this.setOverAllNetPrice(),
          AnalogueNetPrice: this.setAnalogueNetPrice(),
        },
        ProjectScenarios: this.preparedScenarioData(false),
        IsUpgradeVersion: IsUpgradeVersion,
        ValueFrameworkId: IsUpgradeVersion
          ? this.updateFrameworkService.updateFrameWorkId.value
          : this.createProjectFormData.value.framework.valueFrameworkId,
        ParentProjectId: IsUpgradeVersion ? this.editProjectId.value : null,
        IsComparatorSelection:
          this.createProjectFormData.value.comparator === 'true' ? true : false,
      };
      const message = 'Project created successfully';
      this.http
        .post<any>(this.projectAPIUri + '/Projects/CreateProject', projectData)
        .subscribe(
          (data) => {
            if (data) {
              if (this.uploadFileList.value.length != 0) {
                this.uploadFile(data.projectId);
              }
              if (IsUpgradeVersion) {
                this.updateFrameworkService.updateFrameWorkLog(
                  projectId as string,
                  IsUpgradeVersion,
                  data.projectId
                );
              }
              this.setNetPermission(data.projectId);
              this.redirect(data.projectId, message, true);
            }
          },
          (error) => {
            if (error.error === 'Project with same name already exist.') {
              this.toastNotificationService.errorMessage(
                'Project with same name already exist, please choose another name',
                'info',
                10000
              );
            } else {
              if (error.error?.errors?.ProjectDetails) {
                this.toastNotificationService.errorMessage(
                  `Please complete Project Details field within Project Details to continue`,
                  'info',
                  10000
                );
              } else {
                this.toastNotificationService.errorMessage(
                  `Project creation was unsuccessful - ${error.error}`,
                  'info',
                  10000
                );
              }
            }
            this.globalSpinnerService.loadingSpinner(false);
          }
        );
    }

    // } else {
    //   this.toastNotificationService.errorMessage(
    //     'Please select at least 5 analogues'
    //   );
    // }
  }

  public setAnaloguePayload(): any {
    return this.selectedAnalogues.map((res) => {
      return {
        brandName: res.name,
        HTAIndication: res.HTAIndication,
        indication: res.indication,
        inn: res.inn,
        mechanismOfAction: res.mechanismOfAction,
        Country: res.Country,
        sequenceNo: res.sequenceNo,
        therapyArea: res.therapyArea,
      };
    });
  }

  uploadFile(projectId: any): void {
    let status = {
      uploading: true,
      uploadStatus: false,
      projectId: projectId,
    };
    this.fileUploadStatus.next(status);
    this.masterService
      .fileUploadToBob(projectId, this.uploadFileList.value)
      .subscribe(
        (res) => {
          this.uploadFileList.next([]);
          status.uploading = false;
          status.uploadStatus = true;
          this.fileUploadStatus.next(status);
          this.toastNotificationService.successMessage(
            'File uploaded was successfully'
          );
        },
        (error) => {
          this.toastNotificationService.errorMessage(
            'File upload was unsuccessful',
            'info',
            10000
          );
          setTimeout(() => {
            status.uploading = false;
            this.fileUploadStatus.next(status);
          }, 2000);
        }
      );
  }

  public redirect(
    projectId: any,
    Message: string,
    redirect: boolean,
    checkDirect: boolean = true
  ): void {
    this.globalSpinnerService.loadingSpinner(false);
    if (redirect && checkDirect) {
      this.uploadFileList.next([]);
      this.toastNotificationService.successMessage(Message);
      this.clearScenario();
      this.store.dispatch(new ResetProjects());
      this.router.navigateByUrl(`/horizon/list-projects?project=${projectId}`);
      this.validateProjectForm.next(false);
    } else if (checkDirect) {
      this.uploadFileList.next([]);
      this.scenarioStatus.next(true);
      this.valueChangeStatus.next(false);
      this.addScenarioId(projectId);
      this.toastNotificationService.successMessage(
        'Project changes saved successfully'
      );
      //update project permissions for newly created/edited scenario
      this.reloadProjectPermissions();
    }
  }

  public getAnalogues(): any {
    let analogue: any[] = [];
    this.criteriaSelectionService.castSavedSelectedAnalogues
      .pipe(take(1))
      .subscribe((res: any[]) => {
        analogue = res.map((analogue) => {
          return {
            brandName: analogue.name,
            indication: analogue.indication,
            mechanismOfAction: analogue.mechanismOfAction,
            sequenceNo: analogue.sequenceNo,
          };
        });
      });
    return analogue;
  }

  editProject(editProjectId: string, redirect: boolean): void {
    const message = 'Project changes saved successfully';
    this.globalSpinnerService.loadingSpinner(true);
    let editProjectData = {};
    if (!this.isScenariosValid) {
      this.globalSpinnerService.loadingSpinner(false);
      this.toastNotificationService.errorMessage(
        `Project creation was unsuccessful - Please score all attributes to create a project`,
        'info',
        10000
      );
      this.navigateError(true);
    } else {
      if (redirect) {
        editProjectData = {
          ProjectId: editProjectId,
          ProjectScenarios: this.preparedScenarioData(true),
          ProjectNetPrice: {
            IsNetPriceIncluded:
              this.netPriceService.ProjectNetPrice.IsNetPriceIncluded,
            isAnnualCOTInlcuded:
              this.netPriceService.ProjectNetPrice.isAnnualCOTInlcuded,
            OverallNetPrice: this.setOverAllNetPrice(),
            AnalogueNetPrice: this.setAnalogueNetPrice(),
          },
        };
      } else {
        editProjectData = {
          ProjectId: editProjectId,
          ProjectScenarios: this.preparedScenarioData(true),
        };
      }

      this.http
        .post<any>(
          this.projectAPIUri + '/Projects/EditProjectScenario',
          editProjectData
        )
        .subscribe(
          (data) => {
            if (this.uploadFileList.value.length != 0) {
              this.uploadFile(data.projectId);
            }
            if (!redirect) {
              this.reloadScenario.next(true);
            }
            this.redirect(data.projectId, message, redirect);
          },
          (error) => {
            this.toastNotificationService.errorMessage(
              'Saving Project changes was unsuccessfull, please try again',
              'info',
              10000
            );
            this.globalSpinnerService.loadingSpinner(false);
          }
        );

      if (this.uploadFileList.value.length != 0) {
        this.uploadFile(editProjectId);
        this.redirect(editProjectId, message, redirect);
      }
    }
  }

  addScenarioId(editProjectId: string): void {
    this.masterService.getProjectDetails(editProjectId).then((data: any) => {
      data.projectScenarios.forEach((res: any) => {
        this.scenarios.filter(
          (scenario) =>
            scenario.scenarioDetails.scenarioName === res.scenarioName
        )[0].scenarioId = res.scenarioId;
      });
    });
  }

  checkScenarioStatus(changed: boolean, navigate: boolean): any {
    let count = 0;
    let countAttribute = 0;
    let conti = true;
    if (this.scenarios.length != 0) {
      this.scenarios.forEach((scenario: any) => {
        scenario.ValueAttributeScores?.forEach((ValueAttributeData: any) => {
          ValueAttributeData.countries
            ?.filter((x: any) =>
              this.createProjectFormData.value.selectedMarkets.some(
                (country: any) =>
                  country.toLowerCase() === x.name.toLowerCase() || 'All'
              )
            )
            .forEach((country: any) => {
              country.valueAttributes?.forEach((valueAttributeData: any) => {
                if (conti) {
                  if (valueAttributeData.formType === 'dropdown') {
                    if (
                      valueAttributeData?.selectedScore !== '' &&
                      valueAttributeData?.selectedScore !== undefined
                    ) {
                      count++;
                    }
                  } else {
                    if (ValueAttributeData.inputType === 'value') {
                      if (valueAttributeData?.value !== '') {
                        if (valueAttributeData?.isValid) {
                          count++;
                        }
                      }
                    } else {
                      if (
                        valueAttributeData?.rangeStart !== '' &&
                        valueAttributeData?.rangeEnd !== ''
                      ) {
                        if (
                          valueAttributeData?.isStartRangeValid &&
                          valueAttributeData?.isEndRangeValid
                        ) {
                          count++;
                        }
                      }
                    }
                  }
                  countAttribute++;
                }
              });
            });
        });
      });
      if (count === countAttribute) {
        this.scenarioStatus.next(false);
        this.isScenariosValid = true;
      } else {
        this.isScenariosValid = false;
        this.scenarioStatus.next(true);
      }
      this.autoAssignScenarioAlphaSequence();
      this.newValuechanged.next(changed);
    }
  }

  navigateError(navigate: boolean) {
    let count = 0;
    let countAttribute = 0;
    let conti = true;
    this.scenarios.forEach((scenario: any) => {
      scenario.scenarioNametouched = true;
      if (
        (!scenario.newScenario && scenario.id === this.ScenarioId.value) ||
        (scenario.newScenario && scenario.id !== this.ScenarioId.value)
      ) {
        scenario.ValueAttributeScores?.forEach((ValueAttributeData: any) => {
          ValueAttributeData.countries
            ?.filter((x: any) =>
              this.createProjectFormData.value.selectedMarkets.some(
                (country: any) =>
                  country.toLowerCase() === x.name.toLowerCase() || 'All'
              )
            )
            .forEach((country: any) => {
              country.valueAttributes?.forEach((valueAttributeData: any) => {
                if (conti) {
                  if (valueAttributeData.formType === 'dropdown') {
                    if (
                      valueAttributeData?.selectedScore !== '' &&
                      valueAttributeData?.selectedScore !== undefined
                    ) {
                      count++;
                    } else {
                      if (navigate) {
                        this.scollToError(
                          valueAttributeData.scrollId,
                          scenario
                        );
                      }
                      conti = false;
                    }
                  } else {
                    if (ValueAttributeData.inputType === 'value') {
                      if (valueAttributeData?.value !== '') {
                        if (valueAttributeData?.isValid) {
                          count++;
                        } else {
                          if (navigate) {
                            this.scollToError(
                              valueAttributeData.scrollId,
                              scenario
                            );
                          }
                          conti = false;
                        }
                      } else {
                        if (navigate) {
                          this.scollToError(
                            valueAttributeData.scrollId,
                            scenario
                          );
                        }
                        conti = false;
                      }
                    } else {
                      if (
                        valueAttributeData?.rangeStart !== '' &&
                        valueAttributeData?.rangeEnd !== ''
                      ) {
                        if (
                          valueAttributeData?.isStartRangeValid &&
                          valueAttributeData?.isEndRangeValid
                        ) {
                          count++;
                        } else {
                          if (navigate) {
                            this.scollToError(
                              valueAttributeData.scrollId,
                              scenario
                            );
                          }
                          conti = false;
                        }
                      } else {
                        if (navigate) {
                          this.scollToError(
                            valueAttributeData.scrollId,
                            scenario
                          );
                        }
                        conti = false;
                      }
                    }
                  }
                  countAttribute++;
                }
              });
            });
        });
      } else if (
        scenario.newScenario &&
        scenario.id === this.ScenarioId.value
      ) {
        scenario.ValueAttributeScores?.forEach((ValueAttributeData: any) => {
          ValueAttributeData.countries
            ?.filter((x: any) =>
              this.createProjectFormData.value.selectedMarkets.some(
                (country: any) =>
                  country.toLowerCase() === x.name.toLowerCase() || 'All'
              )
            )
            .forEach((country: any) => {
              country.valueAttributes?.forEach((valueAttributeData: any) => {
                if (conti) {
                  if (valueAttributeData.formType === 'dropdown') {
                    if (
                      valueAttributeData?.selectedScore !== '' &&
                      valueAttributeData?.selectedScore !== undefined
                    ) {
                      count++;
                    } else {
                      if (navigate) {
                        this.scollToError(
                          valueAttributeData.scrollId,
                          scenario
                        );
                      }
                      conti = false;
                    }
                  } else {
                    if (ValueAttributeData.inputType === 'value') {
                      if (valueAttributeData?.value !== '') {
                        if (valueAttributeData?.isValid) {
                          count++;
                        } else {
                          if (navigate) {
                            this.scollToError(
                              valueAttributeData.scrollId,
                              scenario
                            );
                          }
                          conti = false;
                        }
                      } else {
                        if (navigate) {
                          this.scollToError(
                            valueAttributeData.scrollId,
                            scenario
                          );
                        }
                        conti = false;
                      }
                    } else {
                      if (
                        valueAttributeData?.rangeStart !== '' &&
                        valueAttributeData?.rangeEnd !== ''
                      ) {
                        if (
                          valueAttributeData?.isStartRangeValid &&
                          valueAttributeData?.isEndRangeValid
                        ) {
                          count++;
                        } else {
                          if (navigate) {
                            this.scollToError(
                              valueAttributeData.scrollId,
                              scenario
                            );
                          }
                          conti = false;
                        }
                      } else {
                        if (navigate) {
                          this.scollToError(
                            valueAttributeData.scrollId,
                            scenario
                          );
                        }
                        conti = false;
                      }
                    }
                  }
                  countAttribute++;
                }
              });
            });
        });
      }
    });
  }

  public scollToError(id: any, scenario: any): void {
    this.setAllErrorsNew();
    if (this.ScenarioId.value !== scenario.id) {
      this.selectedScenarioIndex.next(scenario.id);
      const queryParams: Params = { scenario: scenario.id };
      let link = '/horizon/add-scenario';
      this.router.navigate([link], { queryParams: queryParams });
    }
    this.setScrollId.next(id);
  }

  public setAllErrors(): void {
    this.scenarios.forEach((scenario: any) => {
      if (scenario?.ValueAttributeScores != null) {
        scenario?.ValueAttributeScores.forEach((valueDomain: any) => {
          // if (isAllMarketVisible) {
          valueDomain.allCountries.forEach((contry: any) => {
            contry.valueAttributes.forEach((valueAttribute: any) => {
              // if (valueAttribute.name === attribute && contry.name === country) {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if (
                    valueAttribute.selectedScore === '' ||
                    valueAttribute.selectedScore === undefined
                  ) {
                    valueAttribute.error = 'is required';
                    valueAttribute.selectedScore = '';
                  }
                  break;
                case 'value':
                  if (valueDomain.inputType === 'value') {
                    valueAttribute.error = this.setValueError(
                      valueAttribute.value,
                      valueAttribute.name
                    );
                    valueAttribute.isValid = this.checkValueValid(
                      valueAttribute.value,
                      valueAttribute.name
                    );
                  } else {
                    valueAttribute.starRangeError = this.setRangeError(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name
                    );
                    valueAttribute.isStartRangeValid = this.checkRangeValid(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name
                    );
                    valueAttribute.endRangeError = this.setRangeError(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name
                    );
                    valueAttribute.isEndRangeValid = this.checkRangeValid(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name
                    );
                  }

                  break;
              }
              valueAttribute.touched = true;
              // }
            });
          });
          valueDomain.countries.forEach((contry: any) => {
            contry.valueAttributes.forEach((valueAttribute: any) => {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if (
                    valueAttribute.selectedScore === '' ||
                    valueAttribute.selectedScore === undefined
                  ) {
                    valueAttribute.error = 'is required';
                    valueAttribute.selectedScore = '';
                  }
                  break;
                case 'value':
                  if (valueDomain.inputType === 'value') {
                    valueAttribute.error = this.setValueError(
                      valueAttribute.value,
                      valueAttribute.name
                    );
                    valueAttribute.isValid = this.checkValueValid(
                      valueAttribute.value,
                      valueAttribute.name
                    );
                  } else {
                    valueAttribute.starRangeError = this.setRangeError(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name
                    );
                    valueAttribute.isStartRangeValid = this.checkRangeValid(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name
                    );
                    valueAttribute.endRangeError = this.setRangeError(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name
                    );
                    valueAttribute.isEndRangeValid = this.checkRangeValid(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name
                    );
                    valueAttribute.rangeStartTouched = true;
                    valueAttribute.rangeEndTouched = true;
                  }

                  break;
              }
              valueAttribute.touched = true;
              // }
            });
          });
        });
      }
    });
  }

  public setAllErrorsNew(): void {
    const valueFramework = this.createProjectFormData.value.framework.valueFrameworkName;
    this.scenarios.forEach((scenario: any) => {
      if (scenario?.ValueAttributeScores != null) {
        scenario?.ValueAttributeScores.forEach((valueDomain: any) => {
          // if (isAllMarketVisible) {
          valueDomain.allCountries.forEach((contry: any) => {
            contry.valueAttributes.forEach((valueAttribute: any) => {
              // if (valueAttribute.name === attribute && contry.name === country) {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if (
                    valueAttribute.selectedScore === '' ||
                    valueAttribute.selectedScore === undefined
                  ) {
                    valueAttribute.error = 'is required';
                    valueAttribute.selectedScore = '';
                  }
                  break;
                case 'value':
                  if (valueDomain.inputType === 'value') {
                    valueAttribute.error = this.setValueErrorNew(
                      valueAttribute.value,
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.isValid = this.checkValueValidNew(
                      valueAttribute.value,
                      valueAttribute.name,
                      valueFramework
                    );
                  } else {
                    valueAttribute.starRangeError = this.setRangeErrorNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.isStartRangeValid = this.checkRangeValidNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.endRangeError = this.setRangeErrorNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.isEndRangeValid = this.checkRangeValidNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name,
                      valueFramework
                    );
                  }

                  break;
              }
              valueAttribute.touched = true;
              // }
            });
          });
          valueDomain.countries.forEach((contry: any) => {
            contry.valueAttributes.forEach((valueAttribute: any) => {
              switch (valueAttribute.formType) {
                case 'dropdown':
                  if (
                    valueAttribute.selectedScore === '' ||
                    valueAttribute.selectedScore === undefined
                  ) {
                    valueAttribute.error = 'is required';
                    valueAttribute.selectedScore = '';
                  }
                  break;
                case 'value':
                  if (valueDomain.inputType === 'value') {
                    valueAttribute.error = this.setValueErrorNew(
                      valueAttribute.value,
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.isValid = this.checkValueValidNew(
                      valueAttribute.value,
                      valueAttribute.name,
                      valueFramework
                    );
                  } else {
                    valueAttribute.starRangeError = this.setRangeErrorNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.isStartRangeValid = this.checkRangeValidNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'start',
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.endRangeError = this.setRangeErrorNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.isEndRangeValid = this.checkRangeValidNew(
                      valueAttribute.rangeStart,
                      valueAttribute.rangeEnd,
                      'end',
                      valueAttribute.name,
                      valueFramework
                    );
                    valueAttribute.rangeStartTouched = true;
                    valueAttribute.rangeEndTouched = true;
                  }

                  break;
              }
              valueAttribute.touched = true;
              // }
            });
          });
        });
      }
    });
  }

  async getEditScenarioData(projectId: any, innName: any, scenarioIndex: any) {
    if (scenarioIndex > 0) {
      const requestBody = {
        projectId,
        innName,
        scenario:
          this.scenarios[scenarioIndex - 1]?.scenarioDetails?.scenarioName,
        isSummaryData: this.isDropDown,
      };
      let respData: any = {};
      if (!this.scenarios[scenarioIndex - 1].newScenario) {
        if (projectId && innName && scenarioIndex) {
          await this.assetTppService
            .getAssetTppData(requestBody)
            .then((data: any) => {
              respData = data;
            });
        }
      }
      return respData;
    }
  }

  getnewEditScenarioData(requestBody: any): Observable<any> {
    return this.http.post(
      `${this.projectAPIUri}/ValueAttributeScore/ValueAttributeScoresByAssetTli`,
      requestBody
    );
  }

  reloadProjectPermissions() {
    this.horizonProjectService.getProjectList().then((data) => {
      if (Array.isArray(data)) {
        const userProjectPermissions = data.map(
          (f) =>
            <UserProjectPermission>{
              projectId: f.id,
              permissions: f.permissions,
            }
        );
        this.headerService.setUserProjectPermission(userProjectPermissions);
      }
    });
  }

  deleteScenario(scenarioIndex: any): void {
    this.scenarios.splice(scenarioIndex - 1, 1);
    this.autoAssignScenarioAlphaSequence();
  }

  setOverAllNetPrice(): any[] {
    let newNetprice: any = [];
    if (this.netPriceService.ProjectNetPrice.IsNetPriceIncluded) {
      newNetprice = this.netPriceService.ProjectNetPrice.OverallNetPrice.map(
        (res: any) => {
          return {
            Country: res.country,
            NetPriceDiscount:
              res.NetPriceDiscount === ''
                ? '0'
                : res.NetPriceDiscount === null
                ? '0'
                : res.NetPriceDiscount,
          };
        }
      );
    }
    return newNetprice;
  }

  setAnalogueNetPrice(): any[] {
    let AnalogueNetPrice: any = [];
    if (this.netPriceService.ProjectNetPrice.IsNetPriceIncluded) {
      this.netPriceService.ProjectNetPrice.AnalogueNetPrice.forEach((res) => {
        this.createProjectFormData.value.selectedMarkets.forEach(
          (element: string) => {
            if (res[element].NetPriceDiscount !== 'NA') {
              AnalogueNetPrice.push({
                Brand: res.Brand,
                INN: res.INN,
                TLI: res.TLI,
                Country: element,
                NetPriceDiscount: this.netPriceService.ProjectNetPrice
                  .isAnnualCOTInlcuded
                  ? ''
                  : res[element].NetPriceDiscount === ''
                  ? '0'
                  : res[element].NetPriceDiscount,
                AnalogueCOT: this.netPriceService.ProjectNetPrice
                  .isAnnualCOTInlcuded
                  ? typeof res[element].AnalogueCOT === 'number'
                    ? JSON.stringify(res[element].AnalogueCOT)
                    : res[element].AnalogueCOT
                  : '',
              });
            }
          }
        );
      });
    }
    return AnalogueNetPrice;
  }

  setNetPermission(id: string): void {
    this.headerService.netPricePermission = {
      isNetPriceAvailable:
        this.netPriceService.ProjectNetPrice.IsNetPriceIncluded,
      isNetPriceVisible:
        this.netPriceService.ProjectNetPrice.IsNetPriceIncluded,
    };
  }

  verifyProjectName(projectName: string): Observable<any> {
    return this.http.get(
      `${this.projectAPIUri}/Projects/VerifyProjectName?projectName=${projectName}`
    );
  }

  GetValueScoreDisplayType(): Observable<any> {
    return this.http.get(
      `${this.projectAPIUri}/ValueScoreDisplayType/GetValueScoreDisplayType?valueFrameworkId=${this.createProjectFormData.value.framework.valueFrameworkId}`
    );
  }

  public setValueError(value: any, attributeNAme: string): string {
    let error: string = '';
    let maxValue = 0;
    if (attributeNAme.includes('USA')) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (value === '') {
      error = 'is required';
    } else if (JSON.parse(value) <= 0) {
      error = 'must be greater than 0';
    } else if (JSON.parse(value) > maxValue) {
      error = 'cannot be more than ' + maxValue;
    }
    return error;
  }

  public setRangeError(
    start: any,
    end: any,
    rangeType: string,
    attributeNAme: string
  ): string {
    let error: string = '';
    let maxValue = 0;
    if (attributeNAme.includes('USA')) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (rangeType === 'start') {
      if (start === '') {
        error = 'must have a range starting point';
      } else if (parseFloat(start) <= 0) {
        error = 'must be greater than 0';
      } else if (parseFloat(start) > maxValue) {
        error = 'must not exceed ' + maxValue;
        // }else if(end===''){
        //   error=  'must have a range endpoint'
      } else if (parseFloat(start) === parseFloat(end)) {
        error = 'cannot have the same starting and end point';
      } else if (parseFloat(start) > parseFloat(end)) {
        error = 'range starting point cannot exceed the range end point';
      }
    } else {
      if (end === '') {
        error = 'must have a range end point';
      } else if (parseFloat(end) <= 0) {
        error = 'must be greater than 0';
      } else if (parseFloat(end) > maxValue) {
        error = 'must not exceed ' + maxValue;
        // } else if(start===''){
        //   error=  'must have a range starting point'
      } else if (parseFloat(start) === parseFloat(end)) {
        error = 'cannot have the same starting and end point';
      } else if (parseFloat(end) < parseFloat(start)) {
        error = 'range end point cannot be less then the range starting point';
      }
    }
    return error;
  }

  public checkRangeValid(
    start: any,
    end: any,
    rangeType: string,
    attributeNAme: string
  ): boolean {
    let error: boolean = true;
    let maxValue = 0;
    if (attributeNAme.includes('USA')) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (rangeType === 'start') {
      if (start === '') {
        error = false;
      } else if (parseFloat(start) <= 0) {
        error = false;
      } else if (parseFloat(start) > maxValue) {
        error = false;
        // } else if(end===''){
        //   error= false;
      } else if (parseFloat(start) === parseFloat(end)) {
        error = false;
      } else if (parseFloat(start) > parseFloat(end)) {
        error = false;
      }
    } else {
      if (end === '') {
        error = false;
      } else if (parseFloat(end) <= 0) {
        error = false;
      } else if (parseFloat(end) > maxValue) {
        error = false;
        // }else if(start===''){
        //   error= false;
      } else if (parseFloat(start) === parseFloat(end)) {
        error = false;
      } else if (parseFloat(end) < parseFloat(start)) {
        error = false;
      }
    }
    return error;
  }

  public setValueErrorNew(value: any, attributeNAme: string, valueFramework: string): string {
    let error: string = '';
    let maxValue = 0;
    if(attributeNAme.includes('USA') && valueFramework == frameworkEnums.oncology){
      maxValue = 1500000
    }else if(valueFramework == frameworkEnums.oncology){
      maxValue = 40000
    }else if (attributeNAme.includes('USA') && valueFramework != frameworkEnums.oncology) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (value === '') {
      error = 'is required';
    } else if (JSON.parse(value) <= 0) {
      error = 'must be greater than 0';
    } else if (JSON.parse(value) > maxValue) {
      error = 'cannot be more than ' + maxValue;
    }
    return error;
  }

  public setRangeErrorNew(
    start: any,
    end: any,
    rangeType: string,
    attributeNAme: string,
    valueFramework: string
  ): string {
    let error: string = '';
    let maxValue = 0;
    if(attributeNAme.includes('USA') && valueFramework == frameworkEnums.oncology){
      maxValue = 1500000
    }else if(valueFramework == frameworkEnums.oncology){
      maxValue = 40000
    }else if (attributeNAme.includes('USA') && valueFramework != frameworkEnums.oncology) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (rangeType === 'start') {
      if (start === '') {
        error = 'must have a range starting point';
      } else if (parseFloat(start) <= 0) {
        error = 'must be greater than 0';
      } else if (parseFloat(start) > maxValue) {
        error = 'must not exceed ' + maxValue;
        // }else if(end===''){
        //   error=  'must have a range endpoint'
      } else if (parseFloat(start) === parseFloat(end)) {
        error = 'cannot have the same starting and end point';
      } else if (parseFloat(start) > parseFloat(end)) {
        error = 'range starting point cannot exceed the range end point';
      }
    } else {
      if (end === '') {
        error = 'must have a range end point';
      } else if (parseFloat(end) <= 0) {
        error = 'must be greater than 0';
      } else if (parseFloat(end) > maxValue) {
        error = 'must not exceed ' + maxValue;
        // } else if(start===''){
        //   error=  'must have a range starting point'
      } else if (parseFloat(start) === parseFloat(end)) {
        error = 'cannot have the same starting and end point';
      } else if (parseFloat(end) < parseFloat(start)) {
        error = 'range end point cannot be less then the range starting point';
      }
    }
    return error;
  }

  public checkRangeValidNew(
    start: any,
    end: any,
    rangeType: string,
    attributeNAme: string,
    valueFramework: string
  ): boolean {
    let error: boolean = true;
    let maxValue = 0;
    if(attributeNAme.includes('USA') && valueFramework == frameworkEnums.oncology){
      maxValue = 1500000
    }else if(valueFramework == frameworkEnums.oncology){
      maxValue = 40000
    }else if (attributeNAme.includes('USA') && valueFramework != frameworkEnums.oncology) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (rangeType === 'start') {
      if (start === '') {
        error = false;
      } else if (parseFloat(start) <= 0) {
        error = false;
      } else if (parseFloat(start) > maxValue) {
        error = false;
        // } else if(end===''){
        //   error= false;
      } else if (parseFloat(start) === parseFloat(end)) {
        error = false;
      } else if (parseFloat(start) > parseFloat(end)) {
        error = false;
      }
    } else {
      if (end === '') {
        error = false;
      } else if (parseFloat(end) <= 0) {
        error = false;
      } else if (parseFloat(end) > maxValue) {
        error = false;
        // }else if(start===''){
        //   error= false;
      } else if (parseFloat(start) === parseFloat(end)) {
        error = false;
      } else if (parseFloat(end) < parseFloat(start)) {
        error = false;
      }
    }
    return error;
  }

  public checkValueValidNew(value: any, attributeNAme: string, valueFramework: string): boolean {
    let error: boolean = true;
    let maxValue = 0;
    if(attributeNAme.includes('USA') && valueFramework == frameworkEnums.oncology){
      maxValue = 1500000
    }else if(valueFramework == frameworkEnums.oncology){
      maxValue = 40000
    }else if (attributeNAme.includes('USA') && valueFramework != frameworkEnums.oncology) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (value === '') {
      error = false;
    } else if (JSON.parse(value) <= 0) {
      error = false;
    } else if (JSON.parse(value) > maxValue) {
      error = false;
    }
    return error;
  }

  public checkValueValid(value: any, attributeNAme: string): boolean {
    let error: boolean = true;
    let maxValue = 0;
    if (attributeNAme.includes('USA')) {
      maxValue = 200000;
    } else {
      maxValue = 20000;
    }
    if (value === '') {
      error = false;
    } else if (JSON.parse(value) <= 0) {
      error = false;
    } else if (JSON.parse(value) > maxValue) {
      error = false;
    }
    return error;
  }
}
