import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AccessLevel } from '../models/AccessLevel';
import { TLI } from '../models/TLI';
import { HorizonProjectDetailsResponse } from './horizon-project-details.service';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;
  constructor(private http: HttpClient) {}

  getTli(): Observable<TLI[]> {
    return this.http.get<TLI[]>(this.projectAPIUri + '/Master/GetTLIs');
  }

  getAccessLevels(): Promise<AccessLevel[]> {
    return new Promise<AccessLevel[]>((resolve, reject) => {
      this.http
        .get<AccessLevel[]>(this.projectAPIUri + '/Master/GetAccessLevels')
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getProjectDetails(projectId: string): Promise<HorizonProjectDetailsResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .get<HorizonProjectDetailsResponse>(
          this.projectAPIUri + '/Projects/project/' + projectId
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }
  getFileListServie(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.projectAPIUri}/Projects/Get?projectId=${projectId}`)
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  downloadFileServie(projectId: string, fName: string) {
    return this.http.get(
      `${this.projectAPIUri}/Projects/File/${projectId}/${fName}`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  fileUploadToBob(projectId: string, requestBody: any): Observable<any> {
    const formData = new FormData();
    requestBody.forEach((file: any) => {
      formData.append('files', file);
    });
    return this.http.post(
      `${this.projectAPIUri}/Projects/UploadMultipleFiles?projectId=${projectId}`,
      formData
    );
  }
}
