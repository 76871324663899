import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HorizonState } from '../../shared/store/models/state.model';
import { Store } from '@ngrx/store';
import { selectGetProjectList } from '../../shared/store/reducer/horizon.reducer';
import { LoadProjects } from '../../shared/store/actions/horizon.action';

@Component({
  selector: 'he-list-horizon-project',
  templateUrl: './list-horizon-project.component.html',
  styleUrls: ['./list-horizon-project.component.scss'],
})
export class ListHorizonProjectComponent implements OnInit, OnDestroy{
  sideBarOpen = true;
  noProjects = false
  private unsubscriber$ = new Subject<void>();

  constructor(private store: Store<HorizonState>,) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadProjects());
    this.store
    .select(selectGetProjectList)
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe((res) => {
      if (res.length != 0) {
        this.noProjects=false;
      }else{
        this.noProjects=true;
      }
    })  
  }
  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }
  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
