import { Component } from '@angular/core';
import { DatastoreContext } from '../datastore.context';
import { ErrorPageText } from '../../common';

@Component({
  selector: 'he-context',
  templateUrl: './context.component.html',
  styleUrls: ['./context.component.scss'],
})
export class ContextComponent {
  query$ = this.context.ref$;
  errorPageText = ErrorPageText;

  constructor(public context: DatastoreContext) {}

  onExtendedContext(event: any): void {
    this.context.saveDetails(event);
  }
}
