import { CommonModule } from '@angular/common';
import { NgModule, OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { GeneralInfoGridModule } from './grid/general-info-grid.module';
import { FetchPipe } from './general-info-fetch.pipe';
import { InfoWidgetModule } from '../../../uikit/info-widget';
import { FiltersValueService } from '../filters-value.service';
import { LoadingSpinnerModule } from '../../../uikit/loading-spinner';
import { GridApi } from 'ag-grid-community';
import { ExportCsv } from '../../top-nav/export-csv.service';
import { FilterItemValueInterface, FilterNameEnum, FiltersObject } from '../interfaces';
import { MultipleCsvExport } from '../../top-nav/multiple-csv-export.service';
import { InfoButtonText } from '../../../app/common/constants';
import { InfoButtonModule } from '../../../uikit/info-widget/info-button';
import { CommandModule } from 'projects/helios-gui/src/uikit/command';
import { GeneralInfoAsyncLoadService } from './general-info-async-load.service';
import { GeneralInfoOutput } from 'projects/helios-gui/src/core/general-info/interfaces';
import { ResponseStatus } from '../../common';
import { EMPTY, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'he-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  providers: [ExportCsv]
})
export class GeneralInfoComponent implements OnInit, OnDestroy {

  private gridApi: GridApi | undefined;
  public infoButtonText!: string;

  constructor(
    public filters: FiltersValueService,
    private exportCsv: ExportCsv,
    private multipleExportCsv: MultipleCsvExport,
    public asyncLoadService: GeneralInfoAsyncLoadService
  ) {
  }

  ngOnInit(): void {
    this.infoButtonText = InfoButtonText.GeneralInfo;
  }

  saveApi(ev: GridApi, brandNames: FilterItemValueInterface[], apiOutput: GeneralInfoOutput): void {
    //moved to indication tab component
    this.updateIndicationsAllowedFilter(apiOutput);
    this.multipleExportCsv.unRegisterApi(this.gridApi);
    this.gridApi = ev;
    this.exportCsv.registerApi(ev, brandNames, 'General Information');
    this.multipleExportCsv.registerApi(ev, brandNames, 'General Information');
    this.asyncLoadService.onElementLoad()
  }

  onSave(): void {
    this.exportCsv.saveCsv();
  }

  ngOnDestroy(): void {
    this.multipleExportCsv.unRegisterApi(this.gridApi);
  }

  private updateIndicationsAllowedFilter(apiOutput: GeneralInfoOutput) {
    if (this.filters.indicationFiltersUpdated) return;

    this.filters.indicationAllowedValues$ = EMPTY;
    let nextValue: FiltersObject = [];
    let newFilterValue: FilterItemValueInterface[] = [];

    if (apiOutput.type == ResponseStatus.done) {
      const apiPayLoad: any[] = (apiOutput.payload as any[]);
      if (apiPayLoad?.length == 1) {
        newFilterValue = apiPayLoad[0].tlis;
      }
    }
    nextValue = this.filters.updateIndicationsAllowedItemInFilters(newFilterValue);
    this.filters.indicationAllowedValues$ = of(newFilterValue).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}

@NgModule({
  declarations: [
    FetchPipe,
    GeneralInfoComponent
  ],
  exports: [GeneralInfoComponent],
  imports: [
    CommonModule,
    GeneralInfoGridModule,
    InfoWidgetModule,
    LoadingSpinnerModule,
    InfoButtonModule,
    CommandModule,
  ],
})
export class GeneralInfoModule {
}
