import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountryResolver implements Resolve<string> {
  constructor() { }

  searchValue = ' ';
  replaceValue = '~';

  encode(path: string): string {
    return path.split(this.searchValue).join(this.replaceValue);
  }

  decode(path: string): string {
    return path.split(this.replaceValue).join(this.searchValue);
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<string> | Promise<string> | string {
    return this.decode(route.paramMap.get('country_encoded') ?? '');
  }
}
